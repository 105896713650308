import React from 'react'

import cssClasses from '../infoTab.module.css'
import { Button, Divider, Grid, IconButton } from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { useSelector } from 'react-redux'
import { companySelector } from 'store/company/company.slice'

const ClientList = props => {
  const { addedItem, setAddedItem, selectedRevenueComputationalType, setEditItem, setAddButtonClicked } = props

  return (
    <div style={{ width: '100%' }}>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p className={cssClasses.drawerListHeading}>{selectedRevenueComputationalType}</p>
        <Button onClick={() => setAddedItem([])} className={cssClasses.transparentAddButton} variant="text">
          {' '}
          <span style={{ marginRight: '10px' }}>+</span>Add
        </Button>
      </div>
      <Divider style={{ marginTop: '10px', color: 'rgba(9, 165, 127, 0.15)' }} />
      <ul style={{ width: '100%', padding: 0, margin: 0, listStyle: 'none' }}>
        {addedItem.map((eachItem, index) => (
          <>
            <li key={index} style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
              {/* <Grid item xs={12}> */}
              <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h1 className={cssClasses.listCardItemh}>My client</h1>
                <IconButton
                  onClick={() => {
                    // setSelectedTeam(selectedTeam)
                    setEditItem(eachItem)
                    // setEmpEditButtonClicked(true)
                    setAddedItem([])
                  }}
                >
                  <MoreHorizIcon />
                </IconButton>
              </Grid>
              <Grid item xs={6}>
                <h1 className={cssClasses.listCardh}>Revenue Computation type</h1>
                <p className={cssClasses.listCardp}>{selectedRevenueComputationalType}</p>
              </Grid>
              <Grid item xs={6}>
                <h1 className={cssClasses.listCardh}>Client name</h1>
                <p className={cssClasses.listCardp}>{eachItem.name}</p>
              </Grid>
              <Grid item xs={6}>
                <h1 className={cssClasses.listCardh}>Growth increment</h1>
                <p className={cssClasses.listCardp}>{eachItem.growthIncrement}</p>
              </Grid>
              <Grid item xs={6}>
                <h1 className={cssClasses.listCardh}>Growth percentage</h1>
                <p className={cssClasses.listCardp}>{eachItem.growthRate}</p>
              </Grid>
              <Grid item xs={6}>
                <h1 className={cssClasses.listCardh}>Start date</h1>
                <p className={cssClasses.listCardp}>{eachItem.startDate}</p>
              </Grid>
              <Grid item xs={6}>
                <h1 className={cssClasses.listCardh}>Salary</h1>
                <p className={cssClasses.listCardp}>{eachItem.startingRevenue}</p>
              </Grid>
              {/* </Grid> */}
            </li>
            {/* {productList
              .find(eachItem => eachItem.id === eachItem.products)
              .map(product => (
                <li key={index} style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                  
                  <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h1 className={cssClasses.listCardItemh}>Product</h1>
                    <IconButton
                      onClick={() => {
                        
                        setEditItem(eachItem)
                        
                        setAddedItem([])
                      }}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={6}>
                    <h1 className={cssClasses.listCardh}>Product name</h1>
                    <p className={cssClasses.listCardp}>{product.name}</p>
                  </Grid>
                  <Grid item xs={6}>
                    <h1 className={cssClasses.listCardh}>Cost (CoGS)</h1>
                    <p className={cssClasses.listCardp}>{product.unitCOGS}</p>
                  </Grid>
                  <Grid item xs={6}>
                    <h1 className={cssClasses.listCardh}>Retai price</h1>
                    <p className={cssClasses.listCardp}>{product.price}</p>
                  </Grid>

                </li>
              ))} */}
          </>
        ))}
      </ul>
      <Divider sx={{ marginTop: '230px', marginBottom: '10px' }} />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button
          // onClick={() => handleCancelClick()}
          className={cssClasses.wizardCancelButton}
          type="button"
        >
          Cancel
        </button>
        <button
          onClick={() => {
            setAddButtonClicked(false)
            setAddedItem([])
          }}
          type="button"
          className={cssClasses.wizardSaveButton}
        >
          Save
        </button>
      </div>
    </div>
  )
}

export default ClientList
