import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

// project imports
import useAuth from 'hooks/useAuth'
import config from 'config'
import { useEffect } from 'react'
import { authSelector } from 'store/auth/auth.slice'
import { useQuery } from 'utils/ui'

// ===========================|| GUEST GUARD ||=========================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */
const GuestGuard = ({ children }) => {
  const { isLoggedIn, isGuestUser } = useSelector(authSelector)
  const navigate = useNavigate()

  const query = useQuery()

  useEffect(() => {
    const accessId = query.get('access')
    const googleAuthToken = query.get('googleAuthToken')
    if (accessId || googleAuthToken) {
      return
    }
    if (isLoggedIn) {
      // Delay before sending to default page (dashboard)
      setTimeout(() => {
        // Commenting out for now, auth routes should not navigate to dashboard but should act as expected
        // navigate(config.defaultPath, { replace: true })
      }, 1000)
    }
  }, [isLoggedIn])

  return children
}

GuestGuard.propTypes = {
  children: PropTypes.node
}

export default GuestGuard
