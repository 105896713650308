import { Dialog, DialogContent, Typography, Box, Button } from '@material-ui/core'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ShuttleImage from 'assets/images/shuttle.png'
import { toggleCompanySetupModal, uiSelector } from 'store/ui/ui.slice'
import { companySelector, hideIntroModal } from 'store/company/company.slice'
import { resetTokenStatus } from 'store/auth/auth.slice'

const IntroModal = () => {
  const dispatch = useDispatch()
  const { companySetupModalVisible } = useSelector(uiSelector)
  const { introModal, incompleteCompanyId } = useSelector(companySelector)
  return (
    <Dialog fullWidth maxWidth="sm" open={introModal && !companySetupModalVisible} aria-labelledby="max-width-dialog-title" sx={{ py: 0 }}>
      <DialogContent>
        <Typography variant="h2" color="primary" align="center">
          Welcome
        </Typography>
        <Typography align="center" sx={{ mt: 2 }}>
          You&lsquo;re about to explore new frontiers for financial modeling. Exciting huh? First we&lsquo;ll collect a bit of information
          about your company, then crunch some numbers for you.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <img src={ShuttleImage} alt="Shuttle" width={128} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            onClick={() => {
              dispatch(hideIntroModal())
              // dispatch(resetTokenStatus())

              dispatch(toggleCompanySetupModal(true))
            }}
            sx={{ marginRight: 2 }}
          >
            {incompleteCompanyId === '' ? 'Start Company Setup' : 'Continue Company Setup'}
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              // dispatch(resetTokenStatus())
              dispatch(hideIntroModal())
            }}
          >
            Cancel
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default IntroModal
