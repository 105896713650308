import React, { useEffect, useState } from 'react'

import {
  Dialog,
  IconButton,
  DialogTitle,
  Typography,
  DialogContent,
  Grid,
  CardContent,
  Button,
  Box,
  Stack,
  CircularProgress
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import CloseIcon from '@material-ui/icons/Close'

import wizardBar from '../../assets/images/wizardBar.png'
import qb from 'assets/images/qbFigma.png'
import xero from 'assets/images/xeroFigma.png'
import wave from 'assets/images/waveFigma.png'
import bench from 'assets/images/benchFigma.png'
import pilot from 'assets/images/pFigma.png'
import fresh from 'assets/images/fFigma.png'
import { authSelector } from 'store/auth/auth.slice'
import { useDispatch, useSelector } from 'react-redux'
import { quickbookSelector, resetToken } from 'store/quickBook/quickbook.slice'
import { createBossInsightAccount } from 'store/quickBook/quickbook.action'
import { BOSSINSIGHT_REDIRECT_URL } from 'utils/API/index'

// @ts-ignore
const useStyles = makeStyles(theme => ({
  muiDialog: {
    '& .MuiDialog-paper': {
      maxHeight: '800px',
      height: 'calc(100% - 64px)',
      width: '50%',
      minWidth: 800,
      borderRadius: '8px'
    }
  },
  gridFillHeight: {
    [theme.breakpoints.up('md')]: {
      height: '100%'
    }
  },
  icon: {
    height: '30px',
    // borderRadius: '30px',
    marginRight: '10px'
  },
  gobutton: {
    backgroundColor: '#00213C',
    borderRadius: '8px',
    padding: '5px',
    width: '14%',
    height: '7%',
    margin: 'auto',
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #A5A5A5',
    boxShadow: 1,
    '&:hover': {
      backgroundColor: '#00213C'
    }
  },
  benchIcon: {
    height: '30px',
    // borderRadius: '10px',
    marginRight: '10px'
  },
  IconButton: {
    width: '260px',
    height: '60px',
    borderRadius: '8px',
    border: '2px solid #A5A5A5',
    boxShadow: '0px 5px 17px 0px #bfbfbf',
    padding: '10px',
    marginBottom: '10px',
    color: '#00213C',
    fontSize: '15px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  buttonText: {
    fontSize: '14px',
    fontWeight: 600,
    letterSpacing: '0em',
    textAlign: 'center',
    color: '#FFF'
  }
}))

const IntegrationModel = props => {
  const { isIntegrationButtonClicked, setIntegrationButtonClicked } = props
  const dispatch = useDispatch()
  const { user } = useSelector(authSelector)
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const { accountDomain, embeddToken, isTokenFound } = useSelector(quickbookSelector)
  const [vendor, setvendor] = useState('')

  useEffect(() => {
    if (isTokenFound) {
      setLoading(false)

      window.location.replace(
        `https://${accountDomain}/dock/pre-auth.php?redirect=${BOSSINSIGHT_REDIRECT_URL}&domain=${accountDomain}&service=${vendor}&embed=${embeddToken}`
      )
      dispatch(resetToken())
    }
  }, [isTokenFound])

  if (loading) {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ height: '100%', background: 'gray', position: 'absolute', left: '0px', zIndex: '22', opacity: '0.4' }}
      >
        <Grid item alignSelf="center" justifySelf="center">
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }

  return (
    <Dialog open={isIntegrationButtonClicked} aria-labelledby="max-width-dialog-title" sx={{ py: 0 }} className={classes.muiDialog}>
      <DialogTitle
        id="max-width-dialog-title"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: `url(${wizardBar})`,
          backgroundSize: '260px,10px',
          backgroundRepeat: 'no-repeat',
          mt: -1.5,
          imageRendering: 'crisp-edges,  -moz-crisp-edges, -o-crisp-edges, -webkit-optimize-contrast',
          minHeight: '84px',
          position: 'relative'
        }}
      >
        <Typography
          sx={{
            // marginLeft: 'auto',
            // marginRight: 'auto',
            fontSize: '25px',
            fontWeight: 600,
            letterSpacing: '0em',
            textAlign: 'center',
            color: '#00213C'
          }}
        >
          Link Your Accounts
        </Typography>
        <IconButton
          aria-label="closeIntegration"
          onClick={() => {
            setIntegrationButtonClicked(false)
          }}
          sx={{
            color: theme => theme.palette.grey[500],
            position: 'absolute',
            right: 15
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ background: '#F0F0F0', p: 0, m: 0 }}>
        <Grid container sx={{ height: '100%', minHeight: '100%', p: 0, m: 0 }}>
          <Grid item xs={12} sm={12} className={classes.gridFillHeight}>
            <CardContent
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100%',
                height: '100%',
                p: 0,
                m: 0,
                mr: 0,
                overflow: 'auto',
                // pl: 1,
                // pr: 1,
                scrollbarWidth: 'thin',
                '&::-webkit-scrollbar': {
                  width: '0.4em'
                },
                '&::-webkit-scrollbar-track': {
                  background: '#f1f1f1'
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#888'
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: '#555'
                },
                paddingBottom: 0
              }}
              style={{ paddingBottom: '0px' }}
            >
              <Grid item md={7} sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                <Typography
                  sx={{
                    textAlign: 'center',
                    fontSize: '15px',
                    fontWeight: 600,
                    letterSpacing: '0em',
                    color: '#00213C',
                    marginBottom: '15px'
                  }}
                >
                  Select Your Accounting Service
                </Typography>
                <Grid
                  sx={{
                    backgroundColor: '#ffffff',
                    borderRadius: '8px',
                    padding: '20px',
                    width: '100%',
                    diaply: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid #A5A5A5',
                    boxShadow: 1
                  }}
                >
                  <Button
                    onClick={() => {
                      setLoading(true)
                      setvendor('quickbooks')
                      dispatch(createBossInsightAccount({ user: user.id }))
                    }}
                    className={classes.IconButton}
                  >
                    {' '}
                    <img className={classes.icon} src={qb} alt="qb" /> QuickBooks Online
                  </Button>
                  <Button
                    onClick={() => {
                      setLoading(true)
                      setvendor('xero')
                      dispatch(createBossInsightAccount({ user: user.id }))
                    }}
                    className={classes.IconButton}
                  >
                    {' '}
                    <img className={classes.icon} src={xero} alt="Xero Acounting" /> Xero
                  </Button>
                  <Button
                    onClick={() => {
                      setLoading(true)
                      setvendor('freshbooks')
                      dispatch(createBossInsightAccount({ user: user.id }))
                    }}
                    className={classes.IconButton}
                  >
                    {' '}
                    <img className={classes.icon} src={fresh} alt="Freshbooks" /> Freshbooks
                  </Button>
                  <Button
                    disabled
                    onClick={() => {
                      setLoading(true)
                      setvendor('wave')
                      dispatch(createBossInsightAccount({ user: user.id }))
                    }}
                    style={{ backgroundColor: '#CDCDCD' }}
                    className={classes.IconButton}
                  >
                    {' '}
                    <img className={classes.icon} src={wave} alt="Wave Accounting" /> Wave
                  </Button>
                  <Button
                    disabled
                    // onClick={() => {
                    //   setLoading(true)
                    //   setvendor('bench')
                    //   dispatch(createBossInsightAccount({ user: user.id }))
                    // }}
                    style={{ backgroundColor: '#CDCDCD' }}
                    className={classes.IconButton}
                  >
                    {' '}
                    <img className={classes.benchIcon} src={bench} alt="Bench Accounting" /> Bench
                  </Button>
                  <Button
                    disabled
                    // onClick={() => {
                    //   setLoading(true)
                    //   setvendor('piolts')
                    //   dispatch(createBossInsightAccount({ user: user.id }))
                    // }}
                    style={{ backgroundColor: '#CDCDCD' }}
                    className={classes.IconButton}
                  >
                    {' '}
                    <img className={classes.icon} src={pilot} alt="Pilot" /> Pilot
                  </Button>
                </Grid>
                <Typography sx={{ textAlign: 'left', marginTop: '10px', fontSize: '13px', fontWeight: 400, color: '#00213C' }}>
                  You will be asked to connect your account and authorize mrgn Financial Instituton to access your financial data. By
                  linking an account you are agreeing to the{' '}
                  <a style={{ color: 'blue' }} target="_blank" rel="noreferrer" href="https://mrgn.ai/terms-of-service/">
                    Terms and Conditions
                  </a>{' '}
                </Typography>
              </Grid>
            </CardContent>
          </Grid>
        </Grid>
      </DialogContent>
      <Button
        onClick={() => {
          setIntegrationButtonClicked(false)
        }}
        // @ts-ignore
        className={classes.gobutton}
      >
        <Typography className={classes.buttonText}>Go Back</Typography>
      </Button>
    </Dialog>
  )
}

export default IntegrationModel
