import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import styles from '../../styles.module.css'
import PropTypes from 'prop-types'
import {
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
  Backdrop,
  CircularProgress,
  InputAdornment,
  Divider
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Formik } from 'formik'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { gridSpacing } from 'store/constant'
import IndustriesList from 'data/IndustriesList'
import { useNavigate } from 'react-router'
import {
  uiSelector,
  setCompanySetupModalScreen,
  updateModalTabVisibility,
  toggleCompanySetupModal,
  setSelectedMenuItem
} from 'store/ui/ui.slice'
import { getCompanyByID, createCompany, updateCompany, getCompaniesByUser, createCompanyFromExcel } from 'store/company/company.actions'
import { clearState, companySelector, resetState, setCurrentlyActiveCompanyId, setXlUplodeDatas } from 'store/company/company.slice'
import { SNACKBAR_OPEN } from 'store/actions'
import { quickbookSelector, resetQuickBookStatus } from 'store/quickBook/quickbook.slice'
import integration2 from 'assets/images/icon2.png'
import integrationGroup from 'assets/images/intgroup1.png'
import intIconFi from 'assets/images/intIconFi.PNG'
import { authSelector, updateSelectedCompany } from 'store/auth/auth.slice'
import { SERVER_URL } from 'utils/API/index'
import cssClasses from './infoTab.module.css'
import IntegrationModel from './IntegrationModel'

const InfoTabView = forwardRef((props, ref) => {
  const { isDemoCompany } = props
  const dispatch = useDispatch()
  const formRef = useRef(null)
  const { companySetupModalScreen } = useSelector(uiSelector)
  const [isFinish, setIsFinish] = useState(false)
  const { createdCompanyId } = useSelector(quickbookSelector)
  const { user } = useSelector(authSelector)
  const [loader, setLoader] = useState(false)
  const [hoverStyles, setHoverStyle] = useState({ display: 'none' })
  const [isIntegrationButtonClicked, setIntegrationButtonClicked] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const {
    incompleteCompanyId,
    isSuccessCompany,
    isSuccess,
    createCompanyError,
    id,
    name,
    email,
    industry,
    bankBalance,
    taxYearEnd,
    revenueModel,
    revenueComputationType,
    selectedCompany,
    currentModelScreen,
    companySetupModalDismissed,
    companies,
    xlUplodeDatas,
    currentlyActiveCompanyId
  } = useSelector(companySelector)

  useImperativeHandle(ref, () => ({
    handleNext(flag) {
      // if (flag) setIsFinish(true)
      if (formRef.current) formRef.current.handleSubmit()

      // dispatch(setSelectedMenuItem('department'))
      // dispatch(setCurrentlyActiveCompanyId(id))
    }
  }))

  const navigate = useNavigate()

  useEffect(() => {
    if (IndustriesList.indexOf(industry) === -1 && industry !== '') {
      IndustriesList.unshift(industry)
    }
  }, [industry])

  useEffect(() => {
    dispatch(updateModalTabVisibility({ revenueModel, revenueComputationType }))
  }, [companySetupModalDismissed, currentModelScreen, dispatch, incompleteCompanyId, revenueComputationType, revenueModel])

  useEffect(() => {
    if (incompleteCompanyId !== '' || id !== '') {
      dispatch(updateSelectedCompany({ companyId: id || incompleteCompanyId }))
    }
  }, [dispatch, id, incompleteCompanyId])

  useEffect(() => {
    if (isSuccessCompany) {
      dispatch(resetQuickBookStatus())
    }
  }, [dispatch, isSuccessCompany])

  useEffect(() => {
    if (createdCompanyId !== '') {
      const data = {
        companyId: createdCompanyId,
        email: user.email
      }
      dispatch(resetQuickBookStatus())
      dispatch(getCompanyByID(data))
    } else if (incompleteCompanyId !== '' || id !== '') {
      if (companies.length === 0) {
        const data = {
          companyId: incompleteCompanyId,
          email: user.email
        }
        dispatch(getCompanyByID(data))
      } else {
        const data = {
          companyId: id,
          email: user.email
        }
        dispatch(getCompanyByID(data))
      }
    }
  }, [companies.length, createdCompanyId, dispatch, id, incompleteCompanyId])

  useEffect(() => {
    if (createCompanyError !== '') {
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: createCompanyError,
        variant: 'alert',
        alertSeverity: 'error'
      })
    }

    return dispatch(clearState())
  }, [dispatch, createCompanyError])

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearState())
      if (isFinish) {
        dispatch(toggleCompanySetupModal(false))
        dispatch(setCompanySetupModalScreen(0))
        dispatch(resetState())
        dispatch(getCompaniesByUser())
      }
    }
  }, [companySetupModalScreen, dispatch, isFinish, isSuccess, navigate, selectedCompany])

  // const getTaxYearEnd = () => {
  //   if (incompleteCompanyId !== '' || id !== '') {
  //     return new Date(taxYearEnd).getMonth() === 11 ? moment('12/31', 'MM/DD').toString() : moment('6/30', 'MM/DD').toString()
  //   }
  //   return ''
  // }

  const initialValues = {
    name,
    // email,
    industry,
    // bankBalance,
    // taxYearEnd: getTaxYearEnd(),
    submit: null
  }

  // const handleChangeNumber = e => {
  //   const value = e.target.value.replace(/\D/g, '')
  //   const maxDigits = 20

  //   if (value.length <= maxDigits) {
  //     setErrorMessage('')
  //     const formattedValue = Number(value).toLocaleString()
  //     formRef.current.setFieldValue('bankBalance', formattedValue)
  //     formRef.current.setFieldError('bankBalance', '')
  //   } else {
  //     setErrorMessage(`Maximum ${maxDigits} digits allowed.`)
  //   }
  // }

  // const formatValues = e => e.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return (
    <div
      style={{
        display: 'flex',
        width: '809px',
        height: '884px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        flexShrink: 0,
        justifyContent: 'start',
        paddingLeft: '50px'
      }}
    >
      <div style={{ marginTop: '70px', width: '100%' }}>
        <p className={cssClasses.stepText}>Step 1</p>
        <h1 className={cssClasses.tabHeading}>Let’s get down to business. </h1>
        <p className={cssClasses.tabDesc}>Tell us some basic information about your company. </p>
        <Divider style={{ color: '#D8D9DB', height: '2px' }} />
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Company Name is required').max(30, 'Maximum 30 characters are allowed '),
            industry: Yup.string().required('Industry is required')
            // bankBalance: Yup.string().required('Bank balance is required')
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            if (isDemoCompany) {
              dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
              return
            }
            try {
              const logArray = []

              if (name !== values.name) {
                const companyChangeLog = {
                  user: user.id,
                  userName: user.name,
                  company: selectedCompany,
                  date: new Date(),
                  tabChanged: 'Company/ Edit Company Details',
                  department: 'N/A',
                  lineItemChanged: `${name} (Company Name Changed)`,
                  originalValue: name,
                  newValue: values.name
                }
                logArray.push(companyChangeLog)
              }
              if (industry !== values.industry) {
                const companyChangeLog = {
                  user: user.id,
                  userName: user.name,
                  company: selectedCompany,
                  date: new Date(),
                  tabChanged: 'Company/ Edit Company Details',
                  department: 'N/A',
                  lineItemChanged: `${name} (Company Industry Changed)`,
                  originalValue: industry,
                  newValue: values.industry
                }
                logArray.push(companyChangeLog)
              }

              // if (parseInt(bankBalance, 10) !== parseInt(values.bankBalance, 10)) {
              //   const companyChangeLog = {
              //     user: user.id,
              //     userName: user.name,
              //     company: selectedCompany,
              //     date: new Date(),
              //     tabChanged: 'Company/ Edit Company Details',
              //     department: 'N/A',
              //     lineItemChanged: `${name} (Company Bank Balance Changed)`,
              //     originalValue: bankBalance,
              //     newValue: values.bankBalance
              //   }
              //   logArray.push(companyChangeLog)
              // }
              const data = {
                name: values.name,
                industry: values.industry,
                bankBalance: 0,
                // bankBalance: values.bankBalance.toString().includes(',')
                //   ? parseInt(values.bankBalance.replace(/,/g, ''), 10)
                //   : values.bankBalance,
                // projectionMonths: 36,
                currentModelScreen: companySetupModalScreen + 1,
                systemLog: logArray
              }

              // if (xlUplodeDatas.length !== 0) {
              //   if (
              //     xlUplodeDatas.departmentList.length === 0 ||
              //     xlUplodeDatas.coaList.length === 0 ||
              //     xlUplodeDatas.employeeList.length === 0 ||
              //     xlUplodeDatas.contractorList.length === 0 ||
              //     xlUplodeDatas.vendorList.length === 0
              //   ) {
              //     dispatch({
              //       type: SNACKBAR_OPEN,
              //       open: true,
              //       message: 'Some required fields are missing',
              //       variant: 'alert',
              //       alertSeverity: 'warning'
              //     })
              //   } else {
              //     dispatch(
              //       createCompanyFromExcel({
              //         name: data.name,
              //         industry: data.industry,
              //         email: data.email,
              //         bankBalance: data.bankBalance,
              //         taxYearEnd: data.taxYearEnd,
              //         projectionMonths: 36,
              //         departmentList: xlUplodeDatas.departmentList,
              //         coaList: xlUplodeDatas.coaList,
              //         employeeList: xlUplodeDatas.employeeList,
              //         contractorList: xlUplodeDatas.contractorList,
              //         vendorList: xlUplodeDatas.vendorList,
              //         productList: xlUplodeDatas.productList,
              //         clientList: xlUplodeDatas.clientList,
              //         revenueComputationType: xlUplodeDatas.revenueComputationType,
              //         revenueModel: xlUplodeDatas.revenueModel
              //       })
              //     )
              //   }
              //   dispatch(setXlUplodeDatas([]))
              // }
              if (id === '') {
                dispatch(createCompany(data))
              } else {
                data.id = id
                dispatch(updateCompany(data))
              }

              dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))

              setStatus({ success: true })
              setSubmitting(false)
            } catch (err) {
              setStatus({ success: false })
              setErrors({ submit: err.message })
              setSubmitting(false)
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setFieldTouched }) => (
            <form noValidate onSubmit={handleSubmit}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'start',
                  justifyContent: 'space-between',
                  width: '100%',
                  marginTop: '30px',
                  gap: '16px'
                }}
              >
                <div style={{ width: '100%' }}>
                  <label className={cssClasses.inputLabel} htmlFor="company_name">
                    Company name <span className={cssClasses.important}>*</span>
                    <input
                      className={cssClasses.inputField}
                      disabled={isDemoCompany}
                      placeholder="Type here"
                      value={values.name}
                      name="name"
                      onBlur={handleBlur}
                      onChange={e => {
                        handleChange(e)
                        if (values.name) setFieldTouched('name', true)
                      }}
                      id="company_name"
                    />
                  </label>

                  {touched.name && errors.name && <FormHelperText error> {errors.name} </FormHelperText>}
                </div>
                <div style={{ width: '100%' }}>
                  <label className={cssClasses.inputLabel} htmlFor="industry-name">
                    Industry <span className={cssClasses.important}>*</span>
                    <select
                      className={cssClasses.inputField}
                      placeholder="Select"
                      id="industry-name"
                      disabled={isDemoCompany}
                      value={values.industry}
                      name="industry"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      <option disabled value="">
                        Select
                      </option>
                      {IndustriesList.map((industry, i) => (
                        <option value={industry} key={`industryOption-${i}`}>
                          {industry}
                        </option>
                      ))}
                    </select>
                  </label>
                  {touched.industry && errors.industry && <FormHelperText error> {errors.industry} </FormHelperText>}
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loader}>
        <Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress color="inherit" />
          <Typography>Hang tight, we&apos;ll be right with you! </Typography>
        </Grid>
      </Backdrop>
    </div>
  )
})

export default InfoTabView
