import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Dialog, DialogContent, DialogTitle, Grid, Typography, CardContent, Box, IconButton, InputAdornment } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '@material-ui/icons/Close'
import { Formik, Form, useFormik } from 'formik'
import * as Yup from 'yup'
import * as moment from 'moment'
import { uiSelector, toggleDebtSetupModal } from 'store/ui/ui.slice'
import { resetState, companySelector, hideIntroModal, setIntroModalOverride, clearState } from 'store/company/company.slice'
import { modelsSelector } from 'store/models/models.slice'
import { useTheme } from '@emotion/react'
import TextField from './Components/TextField'
import DateTimePicker from './Components/DateTimePicker'
import Button from './Components/Button'
import { createCompanyDebt, createCompany } from 'store/company/company.actions'
import { getModelDebtDataByID } from 'store/models/models.actions'

// state.isCompanyDebtSetup = true
// state.debtPrincipleAmount = doc.principleAmount
// state.debtInterest = doc.interestRate
// state.debtDurationMonths = doc.durationMonths
// state.debtReceiptDate = doc.loanReceiptDate
// state.debtPrincipleRepaymentStart = doc.principleRepaymentStart
// state.debtInterestRepaymentStart = doc.interestRepaymentStart
// state.debtUpFrontFees = doc.upFrontFees

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content'
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120
  },
  formControlLabel: {
    marginTop: theme.spacing(1)
  },
  companySetupTab: {
    '& .MuiTabs-flexContainer': {
      borderBottom: 'none'
    },
    '& button': {
      color: theme.palette.mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[600],
      minHeight: '400px',
      minWidth: '100%',
      padding: '12px 16px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      textAlign: 'left',
      justifyContent: 'flex-start'
    },
    '& button.Mui-selected': {
      color: theme.palette.primary.main,
      background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[200]
    },
    '& button > svg': {
      marginBottom: '0px !important',
      marginRight: '10px',
      marginTop: '10px',
      height: '20px',
      width: '20px'
    },
    '& button > div > span': {
      display: 'block'
    },
    '& > div > span': {
      display: 'none'
    }
  },
  cardPanels: {
    borderLeft: '1px solid',
    borderLeftColor: theme.palette.mode === 'dark' ? '#333d5e' : '#eeeeee',
    height: '100%',
    overflowY: 'hidden',
    [theme.breakpoints.up('sm')]: {
      overflowY: 'auto'
    }
  },
  muiDialog: {
    '& .MuiDialog-paper': {
      maxHeight: 'initial',
      height: 'calc(100% - 64px)'
    }
  },
  muiDialogContent: {
    [theme.breakpoints.up('sm')]: {
      overflowY: 'hidden',
      paddingRight: 0
    }
  },
  gridFillHeight: {
    [theme.breakpoints.up('sm')]: {
      height: '100%'
    }
  },
  gridFillHeightOverflowY: {
    [theme.breakpoints.up('sm')]: {
      height: '100%',
      overflowY: 'auto'
    }
  }
}))

const FORM_VALIDATION = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  principleAmount: Yup.number()
    .required('Principal Amount required')
    .positive('Principal Amount must be positive')
    .typeError('Number required'),
  interestRate: Yup.number().required('Interest Rate cannnot be blank').typeError('Number required'),
  durationMonths: Yup.number().required('Duration in Months is required').typeError('Number required'),
  loanReceiptDate: Yup.date().required('This date is required'),
  principleRepaymentStart: Yup.date().required('This date is required'),
  interestRepaymentStart: Yup.date().required('This date is required'),
  upFrontFees: Yup.number().required('Upfront Fees required').typeError('Number required')
})

const DebtSetupModal = () => {
  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { debtSetupModalVisible, companySetupModalVisible } = useSelector(uiSelector)

  const { selectedCompany, filterFrequency, filterBreakdown, filterTimeline } = useSelector(companySelector)

  const { isCompanyDebtSetup, debtPrincipleAmount } = useSelector(companySelector)
  const { debt } = useSelector(modelsSelector)

  const INITIAL_FORM_STATE = {
    title: 'Debt 1',
    principleAmount: 0,
    interestRate: 0,
    durationMonths: 12,
    loanReceiptDate: moment().format('YYYY-MM-DD'),
    principleRepaymentStart: moment().format('YYYY-MM-DD'),
    interestRepaymentStart: moment().format('YYYY-MM-DD'),
    upFrontFees: 500
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      style={{ height: '420px', margin: 'auto' }}
      open={debtSetupModalVisible}
      onClose={(event, reason) => {
        const data = {
          companyId: selectedCompany,
          frequency: filterFrequency,
          breakdown: filterBreakdown,
          timeline: filterTimeline
        }
        if (reason !== 'backdropClick') {
          dispatch(hideIntroModal())
          dispatch(resetState())
          dispatch(toggleDebtSetupModal())
        }
      }}
      aria-labelledby="max-width-dialog-title"
      sx={{ py: 0 }}
      className={classes.muiDialog}
    >
      <DialogTitle id="max-width-dialog-title">
        Update Debt/ Loan Details
        <IconButton
          aria-label="close"
          onClick={() => {
            dispatch(clearState())
            dispatch(toggleDebtSetupModal())
          }}
          sx={{
            position: 'absolute',
            right: 24,
            top: 24,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ py: 0 }} className={classes.muiDialogContent}>
        <Grid container className={classes.gridFillHeight}>
          <Formik
            initialValues={{
              ...INITIAL_FORM_STATE
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={values => {
              const data = { ...values, selectedCompany }
              dispatch(createCompanyDebt(data))
              dispatch(toggleDebtSetupModal())
              // dispatch(clearState())
              // const data2 = {
              //   companyId: selectedCompany,
              //   frequency: 1,
              //   breakdown: 0,
              //   timeline: 1
              // }
              //
              // dispatch(getModelDebtDataByID(data2))
            }}
          >
            <Form>
              <Grid container spacing={5} style={{ paddingLeft: '15px', paddingTop: '10px', paddingRight: '25px' }}>
                <Grid item xs={4}>
                  <TextField
                    name="principleAmount"
                    label="Principal Amount"
                    InputProps={{
                      startAdornment: <span>$</span>,
                      inputProps: {
                        min: 1,
                        type: 'string',
                        allowNegative: 'false'
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="interestRate"
                    label="Interest Rate"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      inputProps: {
                        min: 1,
                        // type: 'number',
                        allownegative: 'false'
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField name="durationMonths" label="Loan Duration in Months" />
                </Grid>
                <Grid item xs={3}>
                  <DateTimePicker name="loanReceiptDate" label="Loan Receipt Date" />
                </Grid>
                <Grid item xs={3}>
                  <DateTimePicker name="principleRepaymentStart" label="Principle Repayment Start" startValue="2017-05-24" />
                </Grid>
                <Grid item xs={3}>
                  <DateTimePicker name="interestRepaymentStart" label="Interest Repayment Start Date" />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    name="upFrontFees"
                    label="Upfront Fees"
                    InputProps={{
                      startAdornment: <span>$</span>,
                      inputProps: {
                        min: 1,
                        type: 'string',
                        allowNegative: 'false'
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={5} />
                <Grid item xs={2}>
                  <Button>Simulate!</Button>
                </Grid>
                <Grid item xs={5} />
              </Grid>
            </Form>
          </Formik>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default DebtSetupModal
