import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import config from 'config'

import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core'

import logoDark from 'assets/images/mrgin-blue-logo.png'
import reqDemoImage from 'assets/images/Request_Demo.png'
import cloudRocket from 'assets/images/cloudRocket.png'
import '../index.css'
import cssClasses from '../../../../ui-component/company-setup-modal/infoTab.module.css'
import { setRegistrationSetupModelScreen, uiSelector } from 'store/ui/ui.slice'
import { companySelector, setCurrentlyActiveCompanyId, setIsNewCompanyRegistered, setSelectedCompany } from 'store/company/company.slice'
import { finishCompanyCreate, getCompaniesByUser, updateCompany } from 'store/company/company.actions'
import { getDashboardData } from 'store/dashboard/dashboard.actions'

import { InlineWidget, useCalendlyEventListener, PopupWidget } from 'react-calendly'
import Calendaly from './Calendaly'
import { MENU_OPEN } from 'store/actions'
import { fromDataAsync } from 'xlsx-populate'

const LaunchPage = () => {
  const formRef = useRef()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { registrationSetupModelScreen } = useSelector(uiSelector)
  const { id, selectedCompany } = useSelector(companySelector)

  const onClickNextButton = () => {
    dispatch(setRegistrationSetupModelScreen(registrationSetupModelScreen + 1))
  }

  const onClickToMrgnLink = () => {
    navigate(config.defaultPath, { replace: true })
    // const data = {
    //   id,
    //   isCongratulationTabFinished: true
    // }
    // dispatch(setCurrentlyActiveCompanyId(id))
    // dispatch(setSelectedCompany(id))
    // dispatch(setIsNewCompanyRegistered(true))
    // dispatch(updateCompany(data))
    // dispatch(getCompaniesByUser())
    // dispatch(getDashboardData({ companyId: id }))
    // const fData = {
    //   companyId: id,
    //   isComplete: true
    // }
    // console.log('FDATA: ', fData)
    // dispatch(finishCompanyCreate(fData))
    // navigate(`/budget?cid=${selectedCompany}`)
    // dispatch({ type: MENU_OPEN, id: 'models' })
  }

  return (
    <Grid container>
      <Grid
        item
        xs={5}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'start',
          minHeight: '100vh',
          backgroundColor: '#F2F7F4'
        }}
      >
        <Grid
          item
          xs={9}
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '79px' }}
        >
          <img src={logoDark} alt="MRGN_logo" style={{ marginBottom: '80px' }} />
          <Typography className="left-contaniner-main-h">
            "Finally, a solution that understands the unique challenges of small businesses. Intelligent Business Planning has given us a
            competitive edge."
          </Typography>{' '}
          <Typography className="founder-name">
            - Bob Ross, <span className="founder-of"> Founder of Happy Trees Inc.</span>
          </Typography>
          <img src={reqDemoImage} alt="reqest_demoImage" style={{ height: 'auto', marginTop: '70px', width: '100%' }} />
          <Typography className="information">The information shown is for illustrative purposes only.</Typography>
        </Grid>
      </Grid>
      <Grid item xs={7} sx={{ display: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            minHeight: '90vh',
            marginTop: '40px'
          }}
        >
          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              width: '320px'
            }}
          >
            {/* App-registraion-launch */}
            <img src={cloudRocket} alt="Cloud-rockets" style={{ marginBottom: '40px' }} />
            <Typography className="right-container-h" style={{ width: '320px', fontWeight: '600px' }}>
              One small step today for your business, one giant leap towards its future.
            </Typography>
            <Typography className="right-container-desc" sx={{ width: '320px' }}>
              Congratulations! You are just a few steps away from completing your registration.
            </Typography>
            <Typography className="right-container-desc" sx={{ width: '320px' }}>
              Please check your email for a confirmation message and additional information from our mrgn team regarding next steps.
            </Typography>
            <Button
              onClick={onClickToMrgnLink}
              variant="text"
              sx={{ fontFamily: 'Raleway', fontSize: '14px', fontWeight: 400, marginBottom: '10px', marginLeft: '-7px' }}
            >
              Go to budget overview <span style={{ color: '#FFFFFF', background: '#29A364' }}> &nbsp; mrgn.ai</span>
            </Button>
            <Divider sx={{ width: '320px', margin: '10px 0 30px 0' }} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LaunchPage
