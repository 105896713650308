import React from 'react'
import ReactDOM from 'react-dom'

// third party
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

// project imports
import { store, persister } from 'store'
import * as serviceWorker from 'serviceWorker'
import App from 'App'

// style + assets
import 'assets/scss/style.scss'
import { GoogleOAuthProvider } from '@react-oauth/google'

// const cliendIdGoogle = '332307069851-h54ab9s7toudpqarquol3ecav7ehfl2f.apps.googleusercontent.com'
// ===========================|| REACT DOM RENDER  ||=========================== //

ReactDOM.render(
  <GoogleOAuthProvider clientId="332307069851-h54ab9s7toudpqarquol3ecav7ehfl2f.apps.googleusercontent.com">
    <Provider store={store}>
      <PersistGate loading={null} persistor={persister}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </GoogleOAuthProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
