import React, { useEffect, useRef, useState } from 'react'

import cssClasses from 'ui-component/company-setup-modal/infoTab.module.css'

import { Formik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { setRegistrationSetupModelScreen, uiSelector } from 'store/ui/ui.slice'
import { companySelector } from 'store/company/company.slice'
import { FormHelperText } from '@material-ui/core'
import { updateCompany } from 'store/company/company.actions'
import NumberFormat from 'react-number-format'
import DoneIcon from '@material-ui/icons/Done'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  inputFocusedDate: {
    '& .MuiInputBase-input , .MuiOutlinedInput-root ': { backgroundColor: '#ffff' },
    border: '1px solid  #d8d8d8',
    borderRadius: '4px',
    color: '#29A364',
    height: '42px'
  },
  inputNonfocusedDate: {
    '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
    '& .MuiInputBase-input , .MuiOutlinedInput-root': { backgroundColor: '#ffff', color: '#B1B3B7' },
    marginTop: '0',
    '& .MuiInputAdornment-root': { display: 'none' }
  }
}))

const DetailBillableAndProduct = props => {
  const classes = useStyles()
  const { currentIndexNo, setCurrentIndex, revenueModelItem, revenueComputationTypeItem, isDemoCompany } = props
  const formRefDetailBill = useRef(null)
  const inputFieldRef = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]
  const dispatch = useDispatch()
  const { registrationSetupModelScreen } = useSelector(uiSelector)
  const { id, name, clientList, productList } = useSelector(companySelector)

  const formRevenueDetailBillInitialValues = {
    _id: '',
    clientName: '',
    unitCOGS: '',
    price: '',
    productName: '',
    startDate: null,
    growthRate: '',
    startingUnits: '',
    submit: null
  }
  const [formInitialValues, setformInitialValues] = useState(formRevenueDetailBillInitialValues)

  useEffect(() => {
    if (clientList.length > 0 && productList.length > 0) {
      const [{ name: clientName, growthRate, startDate, startingUnits }] = clientList
      const [{ name: productName, unitCOGS, price }] = productList
      setformInitialValues(prev => ({
        ...prev,
        clientName,
        unitCOGS: unitCOGS.toLocaleString('en-US'),
        price: price.toLocaleString('en-US'),
        productName,
        startDate: dayjs(startDate),
        growthRate: growthRate.toLocaleString('en-US'),
        startingUnits: startingUnits.toLocaleString('en-US')
      }))
    }
  }, [])
  // const formRevenueDetailBillInitialValues = {
  //   _id: '',
  //   clientName: '',
  //   unitCOGS: '',
  //   price: '',
  //   productName: '',
  //   // products: [],
  //   // growthIncrement: '',
  //   startDate: null,
  //   growthRate: '',
  //   startingUnits: '',
  //   submit: null
  // }

  const handleAddRevenueDetailBillTabInputs = (
    productName,
    // products,
    clientName,
    startDate,
    unitCOGS,
    price,
    startingUnits,
    growthRate
  ) => {
    const prodPayload = {
      name: productName,
      unitCOGS: parseFloat(unitCOGS.replace(/[$,]/g, '')),
      price: parseFloat(price.replace(/[$,]/g, ''))
    }

    const ClientPayload = {
      name: clientName,
      products: [productName],
      growthIncrement: 'Monthly',
      // growthRate: growthRate.toString().includes('%') ? parseFloat(growthRate.replace(/%/g, '')) : growthRate,
      growthRate: parseFloat(growthRate.replace(/[$,]/g, '')),
      startDate: dayjs(startDate).format('YYYY/MM'),
      startingUnits: parseFloat(startingUnits.replace(/[$,]/g, ''))
    }

    if (id !== '') {
      const data = {
        id,
        revenueModel: revenueModelItem,
        revenueComputationType: revenueComputationTypeItem,
        productList: prodPayload,
        clientList: ClientPayload
      }
      dispatch(updateCompany(data))
    }
  }
  useEffect(() => {
    if (inputFieldRef[0].current) {
      inputFieldRef[0]?.current?.focus()
    }
  }, [])
  useEffect(() => {
    inputFieldRef[currentIndexNo - 3]?.current?.focus()
  }, [currentIndexNo])

  const onClickDetailBillNextButton = () => {
    if (inputFieldRef[currentIndexNo - 3].current?.value !== '') {
      if (currentIndexNo === 9) {
        if (formRefDetailBill.current) {
          formRefDetailBill.current?.handleSubmit()
        }
      } else {
        setCurrentIndex(prevState => prevState + 1)
      }
    } else {
      inputFieldRef[currentIndexNo - 3]?.current?.focus()
    }
  }

  const onClickOkButton = fieldName => {
    formRefDetailBill.current.setFieldTouched(fieldName, true)
    if (inputFieldRef[currentIndexNo - 3].current?.value !== '' && !formRefDetailBill.current.errors[fieldName]) {
      if (currentIndexNo === 9) {
        if (formRefDetailBill.current) {
          formRefDetailBill.current?.handleSubmit()
        }
      } else {
        setCurrentIndex(prevState => prevState + 1)
      }
    } else {
      inputFieldRef[currentIndexNo - 3]?.current?.focus()
    }
  }
  const handleKeyPress = (event, index) => {
    if (event.charCode === 13) {
      event.preventDefault()
      // inputFieldRef[index]?.current?.focus()
      onClickOkButton(event.target.name)
    }
  }
  // const onClickDetailBillNextButton = () => {
  //   if (currentIndexNo === 9) {
  //     if (formRefDetailBill.current) {
  //       formRefDetailBill.current?.handleSubmit()
  //     }
  //   } else {
  //     setCurrentIndex(prevState => prevState + 1)
  //   }
  // }
  const currentDate = new Date().setHours(0, 0, 0, 0)
  const getClassByIndex = fieldIndex => {
    const isPreviousIndex = currentIndexNo - 1 === fieldIndex
    const isNextIndex = currentIndexNo + 1 === fieldIndex

    switch (true) {
      case isPreviousIndex:
        return cssClasses.nonFocusedBoxTop
      case isNextIndex:
        return cssClasses.nonFocusedBoxBottom
      default:
        return cssClasses.box
    }
  }

  return (
    <Formik
      initialValues={formInitialValues}
      innerRef={formRefDetailBill}
      enableReinitialize
      validationSchema={Yup.object().shape({
        productName: Yup.string().required(' Product name is required'),
        // products: Yup.array().min(1).required('Products are required'),
        clientName: Yup.string().required(' Client name is required'),
        startDate: Yup.date().required(' Start Date is required').typeError('Please enter a valid date'),
        unitCOGS: Yup.string()
          .required(' Product cost is required')
          .matches(/^(\$?\d{1,3}(,\d{3})*(\.\d+)?|\.\d+)$/, 'Enter a valid Amount'),
        price: Yup.string()
          .required(' Product selling price is required')
          .matches(/^(\$?\d{1,3}(,\d{3})*(\.\d+)?|\.\d+)$/, 'Enter a valid Amount'),
        startingUnits: Yup.string()
          .required(' Invoice amount is required')
          .matches(/^(\$?\d{1,3}(,\d{3})*(\.\d+)?|\.\d+)$/, 'Enter a valid Amount'),
        growthRate: Yup.string().required(' Increment in sales is reqired')
        // .matches(/^\d{1,4}(\.\d+)?%?$/, 'Enter a valid number')
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting, setFieldError, resetForm }) => {
        handleAddRevenueDetailBillTabInputs(
          values.productName,
          // values.products,
          values.clientName,
          values.startDate,
          values.unitCOGS,
          values.price,
          values.startingUnits,
          values.growthRate
        )
        dispatch(setRegistrationSetupModelScreen(registrationSetupModelScreen + 1))
        setStatus({ success: true })
        setSubmitting(false)
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
        <form noValidate onSubmit={handleSubmit}>
          <div>
            {(currentIndexNo === 3 || currentIndexNo - 1 === 3) && (
              <div className={getClassByIndex(3)}>
                <label htmlFor="product_name" className={currentIndexNo === 3 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}>
                  {`3. One of the products that ${name} sells is:`}{' '}
                </label>
                {(currentIndexNo === 3 || currentIndexNo === 4) && (
                  <input
                    ref={ref => {
                      inputFieldRef[0].current = ref
                    }}
                    onKeyPress={event => handleKeyPress(event, 1)}
                    disabled={isDemoCompany}
                    placeholder="Type here..."
                    className={currentIndexNo === 3 ? cssClasses.focusedInputBox : cssClasses.nonFocusedInputBox}
                    value={values.productName}
                    name="productName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    id="product_name"
                  />
                )}

                <div style={{ height: '10px' }}>
                  {touched.productName && errors.productName && <FormHelperText error> {errors.productName} </FormHelperText>}{' '}
                </div>
                {currentIndexNo === 3 && (
                  <div className={cssClasses.okButtonContainer}>
                    <button
                      className={values.productName ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                      type="button"
                      onClick={() => onClickOkButton('productName')}
                    >
                      Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                    </button>
                    <p className={cssClasses.textPress}>
                      press <span className={cssClasses.textEnter}>Enter</span>
                    </p>
                  </div>
                )}
              </div>
            )}

            {(currentIndexNo === 4 || currentIndexNo - 1 === 4) && (
              <div className={getClassByIndex(4)}>
                <label htmlFor="product_unit" className={currentIndexNo === 4 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}>
                  {`4. This is how much it costs us to create a single unit of ${values.productName} `}
                </label>
                {(currentIndexNo === 4 || currentIndexNo === 5) && (
                  <div className={currentIndexNo === 4 ? cssClasses.focusedInputDiv : cssClasses.nonFocusedInputDiv}>
                    {' '}
                    {/* <span className={currentIndexNo === 4 ? cssClasses.focusedInputStaticText : cssClasses.nonFocusedInputStaticText}>
                      $
                    </span> */}
                    <NumberFormat
                      prefix="$"
                      getInputRef={ref => {
                        inputFieldRef[1].current = ref
                      }}
                      onKeyPress={event => handleKeyPress(event, 2)}
                      disabled={isDemoCompany}
                      style={{ marginTop: 0, paddingLeft: '20px' }}
                      placeholder="Type $ here..."
                      className={currentIndexNo === 4 ? cssClasses.focusedInputBox : cssClasses.nonFocusedInputBox}
                      value={values.unitCOGS}
                      name="unitCOGS"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      id="product_unit"
                      thousandSeparator=","
                    />
                  </div>
                )}

                <div style={{ height: '10px' }}>
                  {touched.unitCOGS && errors.unitCOGS && <FormHelperText error> {errors.unitCOGS} </FormHelperText>}{' '}
                </div>
                {currentIndexNo === 4 && (
                  <div className={cssClasses.okButtonContainer}>
                    <button
                      className={values.unitCOGS ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                      type="button"
                      onClick={() => onClickOkButton('unitCOGS')}
                    >
                      Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                    </button>
                    <p className={cssClasses.textPress}>
                      press <span className={cssClasses.textEnter}>Enter</span>
                    </p>
                  </div>
                )}
              </div>
            )}

            {(currentIndexNo === 5 || currentIndexNo - 1 === 5) && (
              <div className={getClassByIndex(5)}>
                <label htmlFor="product_price" className={currentIndexNo === 5 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}>
                  {`5. We sell a single unit of ${values.productName} for `}{' '}
                </label>
                {(currentIndexNo === 5 || currentIndexNo === 6) && (
                  <div className={currentIndexNo === 5 ? cssClasses.focusedInputDiv : cssClasses.nonFocusedInputDiv}>
                    {' '}
                    {/* <span className={currentIndexNo === 5 ? cssClasses.focusedInputStaticText : cssClasses.nonFocusedInputStaticText}>
                      $
                    </span> */}
                    <NumberFormat
                      prefix="$"
                      getInputRef={ref => {
                        inputFieldRef[2].current = ref
                      }}
                      onKeyPress={event => handleKeyPress(event, 3)}
                      disabled={isDemoCompany}
                      style={{ marginTop: '0', paddingLeft: '15px' }}
                      placeholder="Type $ here..."
                      className={currentIndexNo === 5 ? cssClasses.focusedInputBox : cssClasses.nonFocusedInputBox}
                      value={values.price}
                      name="price"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      id="product_price"
                      thousandSeparator=","
                    />
                  </div>
                )}

                <div style={{ height: '10px' }}>
                  {touched.price && errors.price && <FormHelperText error> {errors.price} </FormHelperText>}{' '}
                </div>
                {currentIndexNo === 5 && (
                  <div className={cssClasses.okButtonContainer}>
                    <button
                      className={values.price ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                      type="button"
                      onClick={() => onClickOkButton('price')}
                    >
                      Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                    </button>
                    <p className={cssClasses.textPress}>
                      press <span className={cssClasses.textEnter}>Enter</span>
                    </p>
                  </div>
                )}
              </div>
            )}

            {(currentIndexNo === 6 || currentIndexNo - 1 === 6) && (
              <div className={getClassByIndex(6)}>
                <label htmlFor="client" className={currentIndexNo === 6 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}>
                  {`6. One of our clients that we sell or intend to sell ${values.productName} to is`}{' '}
                </label>
                {(currentIndexNo === 6 || currentIndexNo === 7) && (
                  <input
                    ref={ref => {
                      inputFieldRef[3].current = ref
                    }}
                    onKeyPress={event => handleKeyPress(event, 4)}
                    disabled={isDemoCompany}
                    style={{ backgroundColor: '#ffffff' }}
                    placeholder="Type here..."
                    className={currentIndexNo === 6 ? cssClasses.focusedInputBox : cssClasses.nonFocusedInputBox}
                    value={values.clientName}
                    name="clientName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    id="client"
                  />
                )}
                <div style={{ height: '10px' }}>
                  {touched.clientName && errors.clientName && <FormHelperText error> {errors.clientName} </FormHelperText>}{' '}
                </div>
                {currentIndexNo === 6 && (
                  <div className={cssClasses.okButtonContainer}>
                    <button
                      className={values.clientName ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                      type="button"
                      onClick={() => onClickOkButton('clientName')}
                    >
                      Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                    </button>
                    <p className={cssClasses.textPress}>
                      press <span className={cssClasses.textEnter}>Enter</span>
                    </p>
                  </div>
                )}
              </div>
            )}

            {(currentIndexNo === 7 || currentIndexNo - 1 === 7) && (
              <div className={getClassByIndex(7)}>
                <label htmlFor="startDate" className={currentIndexNo === 7 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}>
                  {`7. We started, or will start, selling  ${values.productName} to ${values.clientName} on`}{' '}
                </label>
                {(currentIndexNo === 7 || currentIndexNo === 8) && (
                  // <input
                  //   ref={ref => {
                  //     inputFieldRef[4].current = ref
                  //   }}
                  //   style={{ fontSize: '26px' }}
                  //   onKeyPress={event => handleKeyPress(event, 5)}
                  //   type="date"
                  //   value={values.startDate}
                  //   id="startDate"
                  //   name="startDate"
                  //   placeholder="Select"
                  //   className={currentIndexNo === 7 ? cssClasses.focusedInputBox : cssClasses.nonFocusedInputBox}
                  //   onChange={event => setFieldValue('startDate', event.target.value)}
                  // />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={['month', 'year']}
                      inputRef={ref => {
                        inputFieldRef[4].current = ref
                      }}
                      name="startDate"
                      value={values.startDate}
                      onBlur={handleBlur}
                      placeholder="Select"
                      slotProps={{
                        textField: {
                          name: 'startDate',
                          id: 'startDate',
                          size: 'small',
                          error: false,
                          onKeyPress: event => {
                            handleKeyPress(event, 5)
                          }
                        }
                      }}
                      format="MM/YYYY"
                      sx={{
                        width: '300px',
                        height: '44px',
                        marginTop: '22px'
                      }}
                      onKeyPress={event => {
                        handleKeyPress(event, 5)
                      }}
                      // className={currentIndexNo === 3 ? cssClasses.selectBox : cssClasses.nonFocusedInputBox}
                      className={currentIndexNo === 7 ? classes.inputFocusedDate : classes.inputNonfocusedDate}
                      onChange={value => {
                        setFieldValue('startDate', value)
                      }}
                    />
                  </LocalizationProvider>
                )}
                <div style={{ height: '10px' }}>
                  {touched.startDate && errors.startDate && <FormHelperText error> {errors.startDate} </FormHelperText>}{' '}
                </div>{' '}
                {currentIndexNo === 7 && (
                  <div className={cssClasses.okButtonContainer}>
                    <button
                      className={values.startDate ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                      type="button"
                      onClick={() => onClickOkButton('startDate')}
                    >
                      Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                    </button>
                    <p className={cssClasses.textPress}>
                      press <span className={cssClasses.textEnter}>Enter</span>
                    </p>
                  </div>
                )}
              </div>
            )}

            {(currentIndexNo === 8 || currentIndexNo - 1 === 8) && (
              <div className={getClassByIndex(8)}>
                <label htmlFor="invoice" className={currentIndexNo === 8 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}>
                  {currentDate > new Date(values.startDate).setHours(0, 0, 0, 0)
                    ? `8. Last month, we invoiced ${values.clientName} this much for ${values.productName}`
                    : `8. We plan to invoice ${values.clientName} this much for ${values.productName} in our first month of servicing them`}
                </label>
                {(currentIndexNo === 8 || currentIndexNo === 9) && (
                  <div className={currentIndexNo === 8 ? cssClasses.focusedInputDiv : cssClasses.nonFocusedInputDiv}>
                    {' '}
                    {/* <span className={currentIndexNo === 8 ? cssClasses.focusedInputStaticText : cssClasses.nonFocusedInputStaticText}>
                      $
                    </span> */}
                    <NumberFormat
                      prefix="$"
                      getInputRef={ref => {
                        inputFieldRef[5].current = ref
                      }}
                      onKeyPress={event => handleKeyPress(event, 6)}
                      disabled={isDemoCompany}
                      style={{ marginTop: 0, paddingLeft: '15px' }}
                      placeholder="Type $ here..."
                      className={currentIndexNo === 8 ? cssClasses.focusedInputBox : cssClasses.nonFocusedInputBox}
                      value={values.startingUnits}
                      name="startingUnits"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      id="invoice"
                      thousandSeparator=","
                    />
                  </div>
                )}

                <div style={{ height: '10px' }}>
                  {touched.startingUnits && errors.startingUnits && <FormHelperText error> {errors.startingUnits} </FormHelperText>}{' '}
                </div>
                {currentIndexNo === 8 && (
                  <div className={cssClasses.okButtonContainer}>
                    <button
                      className={values.startingUnits ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                      type="button"
                      onClick={() => onClickOkButton('startingUnits')}
                    >
                      Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                    </button>
                    <p className={cssClasses.textPress}>
                      press <span className={cssClasses.textEnter}>Enter</span>
                    </p>
                  </div>
                )}
              </div>
            )}

            {(currentIndexNo === 9 || currentIndexNo - 1 === 9) && (
              <div className={currentIndexNo === 9 ? cssClasses.box : cssClasses.nonFocusedBoxBottom}>
                <label htmlFor="growth_Rate" className={currentIndexNo === 9 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}>
                  {`9. We generally expect to increase sales of ${values.productName} to ${values.clientName} each month by `}{' '}
                </label>
                {(currentIndexNo === 9 || currentIndexNo === 10) && (
                  <div className={currentIndexNo === 9 ? cssClasses.focusedInputDiv : cssClasses.nonFocusedInputDiv}>
                    {/* <span
                      className={currentIndexNo === 9 ? cssClasses.focusedInputStaticTextRight : cssClasses.nonFocusedInputStaticTextRight}
                    >
                      %
                    </span> */}
                    <NumberFormat
                      getInputRef={ref => {
                        inputFieldRef[6].current = ref
                      }}
                      onKeyPress={event => handleKeyPress(event, 7)}
                      disabled={isDemoCompany}
                      style={{ marginTop: 0 }}
                      placeholder="Type $ here..."
                      className={currentIndexNo === 9 ? cssClasses.focusedInputBox : cssClasses.nonFocusedInputBox}
                      value={values.growthRate}
                      name="growthRate"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      id="growth_Rate"
                      thousandSeparator=","
                    />
                  </div>
                )}

                <div style={{ height: '10px' }}>
                  {touched.growthRate && errors.growthRate && <FormHelperText error> {errors.growthRate} </FormHelperText>}{' '}
                </div>
                {currentIndexNo === 9 && (
                  <div className={cssClasses.okButtonContainer}>
                    <button
                      className={values.growthRate ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                      type="button"
                      onClick={() => onClickOkButton('growthRate')}
                    >
                      Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                    </button>
                    <p className={cssClasses.textPress}>
                      press <span className={cssClasses.textEnter}>Enter</span>
                    </p>
                  </div>
                )}
              </div>
            )}

            {currentIndexNo > 2 && (
              <button
                type="button"
                className={currentIndexNo === 9 ? cssClasses.formNextButton : cssClasses.disabledFormNextButton}
                onClick={onClickDetailBillNextButton}
              >
                Next
              </button>
            )}
          </div>
        </form>
      )}
    </Formik>
  )
}

export default DetailBillableAndProduct
