import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  CircularProgress
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '@material-ui/icons/Close'

import { uiSelector, toggleBurnRateInitialModel, toggleBurnRateModal } from 'store/ui/ui.slice'
import { resetState, companySelector, clearState } from 'store/company/company.slice'
import { modelsSelector } from 'store/models/models.slice'
import { simulationsSelector } from 'store/simulations/simulations.slice'
import { useTheme } from '@emotion/react'
import { StyledTableRow, getWindowHeight, numberFormat } from 'utils/ui'
import { FormatSize } from '@material-ui/icons'
import wizardBar from '../../assets/images/wizardBar.png'

const useStyles = makeStyles(theme => ({
  muiDialog: {
    '& .MuiDialog-paper': {
      maxHeight: 'initial',
      padding: 0
    }
  },
  muiDialogContent: {
    [theme.breakpoints.up('sm')]: {
      overflowY: 'hidden',
      paddingRight: 0
    }
  },
  gridFillHeight: {
    [theme.breakpoints.up('sm')]: {
      height: '100%'
    }
  },
  tableCell: {
    borderBottomColor: theme.palette.primary.main,
    borderBottomWidth: 'medium',
    background: '#F0F0F0'
  }
}))

const Simulate = () => {
  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { burnRateModalVisible } = useSelector(uiSelector)
  const { burnRate } = useSelector(simulationsSelector)
  const { totalBurn, updatedTotalBurn, simulationLineItems, getSimulationReportLoading } = useSelector(companySelector)
  const { people, vendors } = useSelector(modelsSelector)
  let currentBurn
  if (Number.isNaN(parseFloat(people.currentBurn)) || Number.isNaN(parseFloat(vendors.currentBurn))) {
    currentBurn = 0
  } else {
    currentBurn = parseFloat(people.currentBurn) + parseFloat(vendors.currentBurn)
  }
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      style={{ height: '620px', margin: 'auto' }}
      open={burnRateModalVisible}
      onClose={(event, reason) => {}}
      aria-labelledby="max-width-dialog-title"
      sx={{ py: 0 }}
      className={classes.muiDialog}
    >
      <DialogTitle
        id="max-width-dialog-title"
        sx={{
          width: '100%',
          backgroundImage: `url(${wizardBar})`,
          backgroundSize: '260px,10px',
          backgroundRepeat: 'no-repeat',
          mt: -2,
          padding: '35px 0px 20px 0px',
          imageRendering: 'crisp-edges,  -moz-crisp-edges, -o-crisp-edges, -webkit-optimize-contrast'
        }}
      >
        <Grid container>
          <Grid item sx={{ margin: 'auto' }}>
            Summary
          </Grid>
        </Grid>
        <IconButton
          aria-label="close"
          onClick={() => {
            dispatch(toggleBurnRateModal())
          }}
          sx={{
            position: 'absolute',
            right: 24,
            top: 18,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {!getSimulationReportLoading ? (
        <DialogContent sx={{ pl: 3, m: 0, background: '#F0F0F0' }} className={classes.muiDialogContent}>
          <Grid container>
            <Grid container spacing={3} style={{ textAlign: 'center', paddingLeft: '15px', paddingTop: '20px', paddingRight: '25px' }}>
              <Grid item xs={4}>
                <Typography sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>Current Burn</Typography>
                <Typography sx={{ mt: 2 }}>$ {numberFormat(Math.round(currentBurn))}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>Target Burn</Typography>
                <Typography sx={{ mt: 2 }}>$ {burnRate.targetBurn}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>Progress</Typography>
                <Typography sx={{ mt: 2 }}>$ {numberFormat(Math.round(burnRate.progress))}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ textAlign: 'center' }}>
              <Grid item xs={12} sx={{ alignContent: 'center', mt: 3, pr: 3 }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 18, color: theme.palette.primary.main }}>
                  {' '}
                  Expendable line items{' '}
                </Typography>
                <TableContainer component={Paper} sx={{ maxHeight: `${getWindowHeight() - 325}px`, userSelect: 'none', mt: 2 }}>
                  <Table sx={{ minWidth: 650, background: '#F0F0F0' }} aria-label="summary table" stickyHeader>
                    <TableHead>
                      <TableRow
                        style={{
                          borderTop: '1px solid #eeeeee'
                        }}
                      >
                        <TableCell className={classes.tableCell}>
                          <Typography variant="h5">Department</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <Typography variant="h5">Date</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <Typography variant="h5">Name</Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: 'center'
                          }}
                          className={classes.tableCell}
                        >
                          <Typography variant="h5">Current Spend</Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: 'center'
                          }}
                          className={classes.tableCell}
                        >
                          <Typography variant="h5">New Spend</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {simulationLineItems.map((item, i) => (
                        <TableRow style={{ borderTop: '1px solid #eeeeee' }} key={i}>
                          <TableCell sx={{ borderBottomColor: theme.palette.primary.main }}>
                            <Typography variant="h5">{item.department}</Typography>
                          </TableCell>
                          <TableCell sx={{ borderBottomColor: theme.palette.primary.main }}>
                            <Typography variant="h5">
                              {item.dates.map((date, idx) => (idx === item.dates.length - 1 ? date : `${date}, `))}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ borderBottomColor: theme.palette.primary.main }}>
                            <Typography variant="h5">{item.name}</Typography>
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center', borderBottomColor: theme.palette.primary.main }}>
                            <Typography variant="h5">{`$${numberFormat(Math.round(item.currentBurn))}`}</Typography>
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center', borderBottomColor: theme.palette.primary.main }}>
                            <Typography variant="h5">{`$${numberFormat(Math.round(item.newBurn))}`}</Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                      {/* // totals */}
                      <TableRow style={{ borderTop: '1px solid #eeeeee' }}>
                        <TableCell>
                          <Typography variant="h5">Total</Typography>
                        </TableCell>
                        <TableCell />
                        <TableCell />
                        <TableCell sx={{ textAlign: 'center' }}>
                          <Typography variant="h5">{`$${numberFormat(Math.round(totalBurn))}`}</Typography>
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                          <Typography variant="h5">{`$${numberFormat(Math.round(updatedTotalBurn))}`}</Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <Grid container alignItems="center" justifyContent="center" sx={{ height: '100%', my: 10 }}>
          <Grid item alignSelf="center" justifySelf="center">
            <CircularProgress />
          </Grid>
        </Grid>
      )}
    </Dialog>
  )
}

export default Simulate
