import React, { useRef, useState, useImperativeHandle, forwardRef, useEffect } from 'react'

import DoneIcon from '@material-ui/icons/Done'

import { useDispatch, useSelector } from 'react-redux'
import { setCompanySetupModalScreen, uiSelector } from 'store/ui/ui.slice'

import cssClasses from './infoTab.module.css'
import { Divider, Typography } from '@material-ui/core'

import * as Yup from 'yup'
import { Formik } from 'formik'
import { companySelector } from 'store/company/company.slice'
import { updateCompany } from 'store/company/company.actions'
import { authSelector, clearState } from 'store/auth/auth.slice'
import { SNACKBAR_OPEN } from 'store/actions'

const BusinessTabView = forwardRef((props, ref) => {
  const { isDemoCompany } = props
  const dispatch = useDispatch()
  const childRef = useRef(null)
  const formRef = useRef(null)
  const { companySetupModalScreen } = useSelector(uiSelector)
  const { user } = useSelector(authSelector)
  const {
    isLoading,
    isSuccess,
    updateCompanyError,
    id,
    revenueModel,
    revenueComputationType,
    productList,
    selectedCompany,
    isTriggeredFromDashboard,
    tempRevenueModel,
    tempRevenueComputationType,
    isDemo
  } = useSelector(companySelector)

  const [validation, setValidation] = useState(false)
  const [selectedRevenueModelType, setSelectedRevenueModelType] = useState(revenueModel)

  const initialValues = {
    revenueModel,
    submit: null
  }

  useEffect(() => {
    if (updateCompanyError !== '') {
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: updateCompanyError,
        variant: 'alert',
        alertSeverity: 'error'
      })
    }

    return dispatch(clearState())
  }, [dispatch, updateCompanyError])

  // useEffect(() => {
  //   if (isSuccess) {
  //     if (tempRevenueModel) {
  //       if (tempRevenueModel === 'Pro Services') {
  //         if (tempRevenueComputationType === 'Top Line Billable') dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
  //         if (tempRevenueComputationType === 'Detailed Billable + Products')
  //           dispatch(setCompanySetupModalScreen(companySetupModalScreen + 2))
  //       }
  //       if (tempRevenueModel === 'Commerce / Retail') {
  //         dispatch(setCompanySetupModalScreen(companySetupModalScreen + 4))
  //       }
  //       if (tempRevenueModel === 'SaaS') {
  //         dispatch(setCompanySetupModalScreen(companySetupModalScreen + 5))
  //       }
  //     } else {
  //       if (revenueModel === 'Pro Services') {
  //         if (revenueComputationType === 'Top Line Billable') dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
  //         if (revenueComputationType === 'Detailed Billable + Products') dispatch(setCompanySetupModalScreen(companySetupModalScreen + 2))
  //       }
  //       if (revenueModel === 'Commerce / Retail') {
  //         dispatch(setCompanySetupModalScreen(companySetupModalScreen + 4))
  //       }
  //       if (revenueModel === 'SaaS') {
  //         dispatch(setCompanySetupModalScreen(companySetupModalScreen + 5))
  //       }
  //     }

  //     dispatch(clearState())
  //   }
  // }, [companySetupModalScreen, dispatch, isSuccess, revenueComputationType, revenueModel])

  useImperativeHandle(ref, () => ({
    handleNext() {
      if (formRef.current) formRef.current.handleSubmit()
      // if (selectedRevenueModelType === 'Pro Services') {
      //   dispatch(setCompanySetupModalScreen(6))
      // } else if (selectedRevenueModelType === 'Commerce / Retail') {
      //   dispatch(setCompanySetupModalScreen(7))
      // } else {
      //   dispatch(setCompanySetupModalScreen(8))
      // }
    }
  }))

  const onClickOnOptionCard = event => {
    setSelectedRevenueModelType(event.currentTarget.id)
    formRef.current.setFieldValue('revenueModel', event.currentTarget.id)
    setSelectedRevenueModelType(event.currentTarget.id)
    formRef.current.setFieldValue('revenueModel', event.currentTarget.id)
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '890px',
        height: '884px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        flexShrink: 0,
        gap: '12px',
        justifyContent: 'start',
        paddingLeft: '50px'
      }}
    >
      <div style={{ marginTop: '70px' }}>
        <p className={cssClasses.stepText}>Step 5</p>
        <h1 className={cssClasses.tabHeading}>Now let’s choose which revenue model. </h1>
        <p className={cssClasses.tabDesc}>Tell us some basic information about your company. </p>
        <Divider style={{ color: '#D8D9DB', height: '2px' }} />

        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            revenueModel: Yup.string().required('Revenue model is required')
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
            if (!values.revenueComputationType) {
              setValidation(true)
            } else if (values) {
              setValidation(false)
            }
            if (isDemo) {
              // if (values.revenueModel === 'Top Line') {
              //   dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
              // }
              if (values.revenueModel === 'Pro Services') {
                dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
              }
              if (values.revenueModel === 'Commerce / Retail') {
                dispatch(setCompanySetupModalScreen(companySetupModalScreen + 2))
              }
              if (values.revenueModel === 'SaaS') {
                dispatch(setCompanySetupModalScreen(companySetupModalScreen + 3))
              }
              return
            }

            if (id !== '') {
              const data = {
                id,
                revenueModel: values.revenueModel
              }
              const logArray = []
              if (revenueModel !== values.revenueModel) {
                const businessLogArray = {
                  user: user.id,
                  userName: user.name,
                  company: selectedCompany,
                  date: new Date(),
                  tabChanged: 'Revenue',
                  department: 'N/A',
                  lineItemChanged: `Revenue (Revenue Model Changed)`,
                  originalValue: revenueModel || 'NA',
                  newValue: values.revenueModel
                }
                logArray.push(businessLogArray)
              }

              data.systemLog = logArray

              if (values.revenueModel === 'Pro Services') {
                dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
                data.currentModelScreen = companySetupModalScreen + 1

                // if (values.revenueModel !== revenueModel) {
                //   data.productList = []
                // }
                // if (values.revenueComputationType !== revenueComputationType) {
                //   data.productList = []
                //   data.clientList = []
                // }
                // if (productList.length === 0) {
                //   data.clientList = []
                // }
              }
              if (values.revenueModel === 'Commerce / Retail') {
                dispatch(setCompanySetupModalScreen(companySetupModalScreen + 2))
                data.currentModelScreen = companySetupModalScreen + 2
                data.clientList = []
                // if (values.revenueModel !== revenueModel) {
                //   data.productList = []
                // }
                data.revenueComputationType = ''
              }
              if (values.revenueModel === 'SaaS') {
                dispatch(setCompanySetupModalScreen(companySetupModalScreen + 3))
                data.currentModelScreen = companySetupModalScreen + 3
                // if (values.revenueModel !== revenueModel) {
                //   data.productList = []
                // }
                data.clientList = []
                data.revenueComputationType = ''
              }

              dispatch(updateCompany(data))
            }
            setStatus({ success: true })
            setSubmitting(false)
            // resetForm(initialValues)
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
            <form noValidate onSubmit={handleSubmit}>
              <div className={cssClasses.companyTabSelectionContainer}>
                <button
                  disabled={isDemoCompany}
                  name="revenueModel"
                  onClick={onClickOnOptionCard}
                  id="Pro Services"
                  className={selectedRevenueModelType === 'Pro Services' ? cssClasses.selectedCard : cssClasses.selectionCard}
                  type="button"
                >
                  <div id="Pro Services" className={cssClasses.cardContainer}>
                    <div
                      id="Pro Services"
                      style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}
                    >
                      <Typography className={cssClasses.cardTextOption}>Option 1</Typography>
                      {selectedRevenueModelType === 'Pro Services' && (
                        <div className={cssClasses.selected}>
                          {' '}
                          <DoneIcon sx={{ fontSize: '16px' }} /> Selected
                        </div>
                      )}
                    </div>
                    <h1>Pro Serve</h1>
                    <Typography>
                      Professional Services are specialized solutions and guidance provided by experts or consulting firms to help
                      businesses improve and succeed. This can include advice, problem-solving, training, and ongoing support tailored to
                      specific needs.
                    </Typography>
                  </div>
                </button>
                <button
                  disabled={isDemoCompany}
                  name="revenueModel"
                  onClick={onClickOnOptionCard}
                  id="Commerce / Retail"
                  className={selectedRevenueModelType === 'Commerce / Retail' ? cssClasses.selectedCard : cssClasses.selectionCard}
                  type="button"
                >
                  <div id="Commerce / Retail" className={cssClasses.cardContainer}>
                    <div
                      id="Commerce / Retail"
                      style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}
                    >
                      <Typography className={cssClasses.cardTextOption}>Option 2</Typography>
                      {selectedRevenueModelType === 'Commerce / Retail' && (
                        <div className={cssClasses.selected}>
                          {' '}
                          <DoneIcon sx={{ fontSize: '16px' }} /> Selected
                        </div>
                      )}
                    </div>
                    <h1>Commercial</h1>
                    <Typography>
                      Commercial/Retail refers to businesses engaged in selling products or services directly to consumers or other
                      businesses. Whether it's a local shop, an online store, or a large retail chain, these businesses provide a wide array
                      of goods and services to meet the needs and preferences of customers{' '}
                    </Typography>
                  </div>
                </button>
                <button
                  disabled={isDemoCompany}
                  name="revenueModel"
                  onClick={onClickOnOptionCard}
                  id="SaaS"
                  className={selectedRevenueModelType === 'SaaS' ? cssClasses.selectedCard : cssClasses.selectionCard}
                  type="button"
                >
                  <div id="SaaS" className={cssClasses.cardContainer}>
                    <div id="SaaS" style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                      <Typography className={cssClasses.cardTextOption}>Option 3</Typography>
                      {selectedRevenueModelType === 'SaaS' && (
                        <div className={cssClasses.selected}>
                          {' '}
                          <DoneIcon sx={{ fontSize: '16px' }} /> Selected
                        </div>
                      )}
                    </div>
                    <h1>SaaS</h1>
                    <Typography>
                      SaaS, or Software as a Service, is a cloud-based software delivery model where applications are hosted and maintained
                      by a service provider. SaaS provides a flexible, subscription-based approach, granting businesses access to powerful
                      tools and updates without the burden of complex hardware or software management.{' '}
                    </Typography>
                  </div>
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
})

export default BusinessTabView
