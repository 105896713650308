import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@emotion/react'
import congratsConfetti from 'assets/images/congratsConfetti.png'
// import congratsConfetti from 'assets/images/congratsConfetti.jpeg'
import { toggleCompanySetupModal, uiSelector } from 'store/ui/ui.slice'
import { Box, Grid, Typography } from '@material-ui/core'
import {
  companySelector,
  setCurrentlyActiveCompanyId,
  setIsNewCompanyRegistered,
  setSelectedCompany,
  setIsOpenedFromComDrop
} from 'store/company/company.slice'
import { gridSpacing } from 'store/constant'
import { finishCompanyCreate, getCompaniesByUser, updateCompany } from 'store/company/company.actions'

import styles from './infoTab.module.css'

const CongratulationScreen = forwardRef((props, ref) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { id, isCongratulationTabFinished } = useSelector(companySelector)
  const { companySetupModalVisible, companySetupModalScreen } = useSelector(uiSelector)

  useImperativeHandle(
    ref,
    () => ({
      handleNext() {
        const data = {
          id,
          isCongratulationTabFinished: true,
          currentModelScreen: companySetupModalScreen + 1
        }
        dispatch(setCurrentlyActiveCompanyId(id))
        dispatch(setSelectedCompany(id))
        dispatch(setIsNewCompanyRegistered(true))
        dispatch(updateCompany(data))
        dispatch(toggleCompanySetupModal(false))
        dispatch(getCompaniesByUser())
        dispatch(setIsOpenedFromComDrop(false))
      }
    }),
    [dispatch]
  )

  //

  //

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
      <Grid
        container
        spacing={gridSpacing}
        item
        xs={10}
        sx={{
          pt: 3,
          pb: 3,
          // width: 480,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row'
        }}
      >
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography
            style={{ fontSize: '18px', fontWeight: '800' }}
            sx={{
              // fontFamily: 'Raleway',
              // fontStyle: 'normal',
              fontWeight: 800,
              fontSize: '18px',
              lineHeight: '21px',

              color: '#00213C'
            }}
          >
            {' '}
            Congratulations!
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography
            sx={{
              fontSize: '12.5px',
              fontWeight: '400',
              textAlign: 'left',
              color: '#707070',
              marginTop: '10px',
              marginBottom: '5px'
            }}
          >
            You’re now able to explore the dashboard. Please complete the checklist on the side to get access to all the features here at
            mrgn.
          </Typography>
        </Grid>
        <Grid item>
          <img className={styles.confettiImage} src={congratsConfetti} alt="mrgn" height="420" />
        </Grid>
      </Grid>
    </Box>
  )
})

export default CongratulationScreen
