import React from 'react'
import { Card, Button } from '@material-ui/core'
import LayersTwoToneIcon from '@material-ui/icons/LayersTwoTone'

import { useDispatch, useSelector } from 'react-redux'
import { toggleCompanySetupModal } from 'store/ui/ui.slice'
import { companySelector } from 'store/company/company.slice'
import { styled } from '@material-ui/styles'

const CompanySetupButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.primary[200],
  paddingLeft: 0,
  paddingRight: 0,
  '&:hover': {
    backgroundColor: theme.palette.secondary[200],
    color: theme.palette.primary.main
  }
}))

const StartCompanySetup = () => {
  const dispatch = useDispatch()
  const { incompleteCompanyId, companies, introModal, companySetupModalDismissed, isLoadedCompanies } = useSelector(companySelector)
  if (companies.length > 0 || introModal) {
    return null
  }
  if (companies.length === 0 && isLoadedCompanies) {
    return (
      <CompanySetupButton
        variant="contained"
        startIcon={<LayersTwoToneIcon />}
        fullWidth
        onClick={() => dispatch(toggleCompanySetupModal(true))}
      >
        {incompleteCompanyId !== '' && companies.length === 0 ? 'Continue Company Setup' : 'Start Company Setup'}
      </CompanySetupButton>
    )
  }
  return null
}

export default StartCompanySetup
