import React, { useRef, useState } from 'react'
import { Box, Divider, Drawer, FormControl, FormHelperText, Grid, IconButton, TextareaAutosize, Typography } from '@material-ui/core'
import cssClasses from './infoTab.module.css'

import CloseIcon from '@material-ui/icons/Close'
import FileUploadOutlinedIcon from '@material-ui/icons/FileUploadOutlined'

import UploadIcon from '../../assets/images/icons/UploadIcon.png'

import { Formik } from 'formik'
import * as Yup from 'yup'
import DetailBillableAndProduct from 'views/pages/authentication/CompanyRegistration/DetailBillableAndProducts'
import DetailBillableandProduct from './DetailBillableandProduct'
import { useDispatch, useSelector } from 'react-redux'
import { companySelector } from 'store/company/company.slice'
import TopLineBillableClientsTabView from './TopLineBillableClientsTabView'
import { setEquityEditButtonClicked } from 'store/ui/ui.slice'
import { updateCompany } from 'store/company/company.actions'
import { makeStyles } from '@material-ui/styles'
import dayjs from 'dayjs'
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'

import { InlineWidget, useCalendlyEventListener, PopupWidget } from 'react-calendly'
import NoteToExpertPage from './NoteToExpertPage'
import ScheduleCallPage from './ScheduleCallPage'
import ConfirmCallPage from './ConfirmCallPage'

const useStyles = makeStyles(theme => ({
  disabledButton: {
    backgroundColor: '#E9E9E9',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '800',
    marginTop: '30px',
    width: '320px',
    height: '44px',
    borderRadius: '4px',
    outline: 'none',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    color: '#A3A3A3'
  },
  timeSlot_select: {
    backgroundColor: '#EFE9FE',
    fontWeight: '400',
    fontSize: '16px',
    color: '#131313',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '320px',
    height: '40px',
    border: '1px solid #29A364',
    borderRadius: '4px',
    cursor: 'pointer'
  },
  timeSlot_unSelect: {
    fontWeight: '400',
    fontSize: '16px',
    color: '#131313',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '320px',
    height: '40px',
    border: '1px solid #CFD0D9',
    borderRadius: '4px',
    cursor: 'pointer'
  },
  formNextButton: {
    background: 'var(--secondary-purple-600, #29A364)',
    color: 'var(--neutrals-neutral-white, #fff)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 800,
    marginTop: '30px',
    width: '320px',
    height: '44px',
    borderRadius: '4px',
    outline: 'none',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  }
}))

const TalkToAnExperDrawer = props => {
  const { isTalkToAnExpertClicked, setTalkToAnExpertClicked } = props

  const [currentTabNumber, setCurrentTabNumber] = useState(0)
  const [selectedDate, setSelectedDate] = useState('')
  const [selectedTime, setSelectedTime] = useState('')

  const customStyles = {
    backdrop: {
      fill: 'var(--neutrals-cool-1000, #0C0D0F)',
      opacity: 0.25
    }
  }

  const closeDrawer = () => {
    setTalkToAnExpertClicked(false)
    setCurrentTabNumber(0)
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {value === index && <div>{children}</div>}
      </div>
    )
  }

  return (
    <Drawer
      BackdropProps={{ style: customStyles.backdrop }}
      style={{ zIndex: 1000000 }}
      anchor="right"
      open={isTalkToAnExpertClicked}
      onClose={closeDrawer}
      sx={{
        '& .css-4qyyic-MuiGrid-root': {
          padding: '24px',
          width: '390px'
        }
      }}
    >
      <Grid p={5} width="500px" role="presentation" textAlign="start">
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={closeDrawer}>
            <CloseIcon style={{ color: 'black' }} />
          </IconButton>
        </div>

        <TabPanel value={currentTabNumber} index={0}>
          <NoteToExpertPage setCurrentTabNumber={setCurrentTabNumber} />
        </TabPanel>
        {/* <TabPanel value={currentTabNumber} index={1}>
          <ScheduleCallPage setCurrentTabNumber={setCurrentTabNumber} setSelectedDate={setSelectedDate} setSelectedTime={setSelectedTime} />
        </TabPanel>
        <TabPanel value={currentTabNumber} index={2}>
          <ConfirmCallPage
            setCurrentTabNumber={setCurrentTabNumber}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            setTalkToAnExpertClicked={setTalkToAnExpertClicked}
          />
        </TabPanel> */}
        <TabPanel value={currentTabNumber} index={1}>
          <ConfirmCallPage setCurrentTabNumber={setCurrentTabNumber} setTalkToAnExpertClicked={setTalkToAnExpertClicked} />
        </TabPanel>
      </Grid>
    </Drawer>
  )
}

export default TalkToAnExperDrawer
