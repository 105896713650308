import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setRegistrationSetupModelScreen, uiSelector } from 'store/ui/ui.slice'
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core'

import dayjs from 'dayjs'

import { makeStyles } from '@material-ui/styles'
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import logoDark from 'assets/images/mrgin-blue-logo.png'
import reqDemoImage from 'assets/images/Request_Demo.png'
import cloudRocket from 'assets/images/cloudRocket.png'

import { InlineWidget, useCalendlyEventListener, PopupWidget } from 'react-calendly'

import '../index.css'
import cssClasses from '../../../../ui-component/company-setup-modal/infoTab.module.css'

const useStyles = makeStyles(theme => ({
  disabledButton: {
    backgroundColor: '#E9E9E9',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '800',
    marginTop: '30px',
    width: '320px',
    height: '44px',
    borderRadius: '4px',
    outline: 'none',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    color: '#A3A3A3'
  },
  timeSlot_select: {
    backgroundColor: '#EFE9FE',
    fontWeight: '400',
    fontSize: '16px',
    color: '#131313',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '320px',
    height: '40px',
    border: '1px solid #29A364',
    borderRadius: '4px',
    cursor: 'pointer'
  },
  timeSlot_unSelect: {
    fontWeight: '400',
    fontSize: '16px',
    color: '#131313',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '320px',
    height: '40px',
    border: '1px solid #CFD0D9',
    borderRadius: '4px',
    cursor: 'pointer'
  },
  formNextButton: {
    background: 'var(--secondary-purple-600, #29A364)',
    color: 'var(--neutrals-neutral-white, #fff)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 800,
    marginTop: '30px',
    width: '320px',
    height: '44px',
    borderRadius: '4px',
    outline: 'none',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  }
}))

const RequestDemoPage = () => {
  const [value, setValue] = useState(dayjs('2023-08-14'))
  const classes = useStyles()
  const formRef = useRef()
  const dispatch = useDispatch()
  const [demoDate, setDemoDate] = useState('')
  const [timeSlot, settimeSlot] = useState('')
  const { registrationSetupModelScreen } = useSelector(uiSelector)

  useCalendlyEventListener({
    onEventScheduled: e => console.log(e.data.payload, 'calendly')
  })

  const onClickNextButton = () => {
    dispatch(setRegistrationSetupModelScreen(registrationSetupModelScreen + 1))
  }
  const timeSlotData = [
    '9:00am Eastern Time (ET)',
    '10:00am Eastern Time (ET)',
    '11:00am Eastern Time (ET)',
    '12:00pm Eastern Time (ET)',
    '1:00pm Eastern Time (ET)'
  ]
  return (
    <Grid container>
      <Grid
        item
        xs={5}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'start',
          minHeight: '100vh',
          backgroundColor: '#F2F7F4'
        }}
      >
        <Grid
          item
          xs={9}
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '79px' }}
        >
          <img src={logoDark} alt="MRGN_logo" style={{ marginBottom: '80px' }} />
          <Typography className="left-contaniner-main-h">
            "Finally, a solution that understands the unique challenges of small businesses. Intelligent Business Planning has given us a
            competitive edge."
          </Typography>{' '}
          <Typography className="founder-name">
            - Bob Ross, <span className="founder-of"> Founder of Happy Trees Inc.</span>
          </Typography>
          <img src={reqDemoImage} alt="reqest_demoImage" style={{ height: 'auto', marginTop: '70px', width: '100%' }} />
          {/* <Typography className="information">The information shown is for illustrative purposes only.</Typography> */}
        </Grid>
      </Grid>
      <Grid item xs={7} sx={{ display: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            minHeight: '100vh',
            marginTop: '40px'
          }}
        >
          {/* Request_Demo_page */}
          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              width: '320px'
            }}
          >
            <img src={cloudRocket} alt="Cloud-rockets" style={{ marginBottom: '40px' }} />
            <Typography className="right-container-h" style={{ width: '320px', fontWeight: '600px' }}>
              One small step today for your business, one giant leap towards its future.
            </Typography>
            <Typography className="right-container-desc" sx={{ width: '320px' }}>
              Congratulations! You are just a few steps away from completing your registration.
            </Typography>
            <Typography className="right-container-desc" sx={{ width: '320px' }}>
              Please check your email for a confirmation message and additional information from our mrgn team regarding next steps.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default RequestDemoPage
