import { createAsyncThunk } from '@reduxjs/toolkit'
import { SERVER_URL } from 'utils/API'
import { axiosInstance } from 'utils/API/AxiosConfig'

const getOrganisationDetails = createAsyncThunk('organisation/getOrgDetails', async thunkAPI => {
  try {
    const response = await axiosInstance.get(
      `${SERVER_URL}/admin/getOrgDetails`,
      //   { params: { ...payload } },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const updateOrgDetails = createAsyncThunk('organisation/updateOrgDetails', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(`${SERVER_URL}/admin/updateOrganisation`, { ...payload }, { handlerEnabled: true })

    if (response.status === 201 || response.status === 200) {
      //   window.location.reload()
      return true
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

export { getOrganisationDetails, updateOrgDetails }
