import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import uiSlice, { toggleDebtSetupModal } from 'store/ui/ui.slice'
import { v4 as uuidv4 } from 'uuid'
import {
  createCompany,
  finishCompanyCreate,
  getCompaniesByUser,
  getCompanyByID,
  updateCompany,
  updateCompanyInfo,
  deleteCompanyById,
  createCompanyDebt,
  leaveCompanyById,
  createTask,
  getUserTasks,
  getReassignedTasks,
  updateTaskStatus,
  deleteUserFromCompany,
  getupdateCellChanges,
  getSimulationReport,
  addEquityRaiseData,
  createCompanyFromExcel,
  getSystemLogs,
  updateHiringPlanEmployees,
  getHiringPlanEmployees,
  updateHiringPlanBulkEmployees,
  getHiringPlanBulkEmployees,
  updateHiringPlanContractors,
  getHiringPlanContractors,
  updateHiringPlanBulkContractors,
  getHiringPlanBulkContractors,
  completeProductOnboarding
} from './company.actions'

const initialState = {
  id: '',
  incompleteCompanyId: '',
  currentModelScreen: 0,
  isComplete: false,
  name: '',
  email: '',
  modelType: '',
  industry: '',
  bankBalance: '',
  taxYearEnd: '',
  projectionMonths: '',
  departmentList: [],
  coaList: [],
  meTabInputs: {},
  expensesTabInputs: {},
  revenueTabInputs: {},
  isCoaChanged: false,
  employeeList: [],
  hiringPlanEmployeeList: [],
  hiringPlanBulkEmployeeList: [],
  contractorList: [],
  hiringPlanContractorList: [],
  hiringPlanBulkContractorList: [],
  vendorList: [],
  clientList: [],
  productList: [],
  revenueModel: null,
  revenueComputationType: '',
  badDebtPercent: '',
  autoCalcGrowthRate: '',
  growthRate: '',
  growthFreqType: '',
  startingRevenue: '',
  revenueStartingDate: '',
  isLoading: false,
  isSuccess: false,
  isSuccessCompanyInfo: false,
  isCompanyDeleted: false,
  isCompanyLeaved: false,
  isTaskCreated: false,
  createCompanyError: '',
  updateCompanyError: '',
  isSuccessCompanyCreate: false,
  isSuccessEquityRaiseCreate: false,
  companies: [],
  incompleteCompanies: [],
  selectedCompany: '',
  isLoadingCompanies: false,
  isLoadedCompanies: false,
  getCompaniesError: '',
  getCompanyDetailsError: '',
  introModal: false,
  introModalOverride: false,
  companySetupModalDismissed: false,
  isCompanyDebtSetup: false,
  debtPrincipleAmount: 0,
  debtInterest: 0,
  debtDurationMonths: 12,
  debtReceiptDate: '2021-10-01',
  debtPrincipleRepaymentStart: '2021-10-10',
  debtInterestRepaymentStart: '2021-10-10',
  debtUpFrontFees: 0,
  userTasks: [],
  reassignedTasks: [],
  isStatusUpdated: false,
  isTaskCreateLoading: false,
  isUserDeleted: false,
  isQbImportedData: false,
  qbImportFlag: [],
  adminDepartment: '',
  userTier: '',
  paymentMode: '',
  isLastTabLoaded: false,
  isCompanyLoaded: false,
  revenueUpdationStatus: false,
  revenueUpdationLoading: false,
  // simulation report
  totalBurn: '',
  updatedTotalBurn: '',
  simulationLineItems: [],
  getSimulationReportLoading: false,
  allocationList: '',
  createEquityRaiseError: '',
  excelImportSuccess: false,
  xlUplodeDatas: [],
  // isExcelUploadError: false
  systemLogs: [],
  isDemo: false,
  isEarlyBird: false,
  isFirstTimeLogin: false,
  isWelcomeScreenOpen: false,
  isFirstWizardOpen: false,
  preSelectedPlan: 'starter',
  creationDate: '',
  equityAllocationList: [],
  currentlyActiveCompanyId: '',
  isNewCompanyRegistered: false,
  isTriggeredFromDashboard: false,
  isCongratulationTabFinished: false,
  companyLogoUrl: null,
  isWizardVisible: false,
  isOpenedFromCompDrop: true,
  isLoadingCompany: false,
  receivedNotifyMail: false,
  companyCreationDate: '',
  fremiumStartDate: '',
  upComingPlan: '',
  isNewRegisterCompany: false,
  isProductOnboardingComplete: false
}

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    hideIntroModal: state => {
      state.introModal = false
    },
    showIntroModal: state => {
      state.introModal = true
    },
    setXlUplodeDatas: (state, action) => {
      state.xlUplodeDatas = action.payload
    },
    // setExcelUploadError: state => {
    //   state.isExcelUploadError = true
    // },
    setIntroModalOverride: (state, action) => {
      state.introModalOverride = action.payload
      state.companySetupModalDismissed = action.payload
    },
    clearState: state => {
      state.isLoading = false
      state.isSuccess = false
      state.isSuccessCompanyInfo = false
      state.createCompanyError = ''
      state.updateCompanyError = ''
      state.isSuccessCompanyCreate = false
      state.isLoadingCompanies = false
      state.isLoadedCompanies = false
      state.getCompaniesError = ''
      state.getCompanyDetailsError = ''
      state.isCompanyDeleted = false
      state.isCompanyLeaved = false
      state.isTaskCreated = false
      state.isStatusUpdated = false
      state.isTaskCreateLoading = false
      state.isUserDeleted = false
      // state.isNewCompanyRegistered = false
      // state.isTriggeredFromDashboard = false
      // state.isFirstTimeLogin = false
    },
    clearCompany: state => {
      state.companies = []
      state.incompleteCompanies = []
    },
    clearCurrentModel: state => {
      state.currentModelScreen = 0
    },
    resetDeptFlag: state => {
      state.isCompanyDebtSetup = false
    },
    resetState: state => {
      console.log('reset state called')
      state.id = ''
      state.isComplete = false
      state.currentModelScreen = 0
      state.name = ''
      state.email = ''
      state.modelType = ''
      state.industry = ''
      state.bankBalance = ''
      state.taxYearEnd = ''
      state.projectionMonths = ''
      state.departmentList = []
      state.meTabInputs = {}
      state.expensesTabInputs = {}
      state.revenueTabInputs = {}
      state.coaList = []
      state.isCoaChanged = false
      state.employeeList = []
      state.hiringPlanEmployeeList = []
      state.hiringPlanBulkEmployeeList = []
      state.contractorList = []
      state.hiringPlanContractorList = []
      state.hiringPlanBulkContractorList = []
      state.vendorList = []
      state.clientList = []
      state.productList = []
      state.revenueModel = null
      state.revenueComputationType = ''
      state.badDebtPercent = ''
      state.autoCalcGrowthRate = ''
      state.growthRate = ''
      state.growthFreqType = ''
      state.startingRevenue = ''
      state.revenueStartingDate = ''
      state.isLoading = false
      state.isSuccess = false
      state.isSuccessCompanyInfo = false
      state.createCompanyError = ''
      state.updateCompanyError = ''
      state.isSuccessCompanyCreate = false
      state.isLoadingCompanies = false
      state.isLoadedCompanies = false
      state.getCompaniesError = ''
      state.getCompanyDetailsError = ''
      state.introModal = false
      state.incompleteCompanyId = ''
      state.introModalOverride = false
      state.companySetupModalDismissed = false
      state.isCompanyDeleted = false
      state.isCompanyLeaved = false
      state.isTaskCreated = false
      state.userTasks = []
      state.reassignedTasks = []
      state.isQbImportedData = false
      state.adminDepartment = ''
      state.systemLogs = []
      state.isDemo = false
      state.isEarlyBird = false
      state.preSelectedPlan = 'starter'
      state.creationDate = ''
      state.equityAllocationList = []
      state.isFirstTimeLogin = false
      state.isWelcomeScreenOpen = false
      state.isFirstWizardOpen = false
      // state.isTriggeredFromDashboard = false
      state.isCongratulationTabFinished = false
      state.companyLogoUrl = null
      // state.isNewCompanyRegistered = false
    },
    hardResetState: state => initialState,
    addEmployee: (state, action) => {
      const employeeList = [...state.employeeList]
      const hiringPlanEmployeeList = [...state.hiringPlanEmployeeList]
      const payload = { ...action.payload }
      payload.id = uuidv4()
      employeeList.push(payload)
      state.employeeList = employeeList
      payload.id = uuidv4()
      hiringPlanEmployeeList.push(payload)
      state.hiringPlanEmployeeList = hiringPlanEmployeeList

      return state
    },
    resetCompanyLoadedStatus: state => {
      state.isCompanyLoaded = false
    },
    updateEmployee: (state, action) => {
      const employeeList = [...state.employeeList]
      const payload = { ...action.payload }
      let index
      if (payload.id) {
        index = employeeList.findIndex(item => item.id === payload.id)
        employeeList[index] = payload
      } else if (payload._id) {
        index = employeeList.findIndex(item => item._id === payload._id)
        employeeList[index] = payload
      }
      state.employeeList = employeeList

      return state
    },
    deleteEmployee: (state, action) => {
      const employeeList = [...state.employeeList].filter(item => {
        if (item.id) {
          return item.id !== action.payload
        }
        return item._id !== action.payload
      })
      state.employeeList = employeeList
    },
    addContractor: (state, action) => {
      const contractorList = [...state.contractorList]
      const hiringPlanContractorList = [...state.hiringPlanContractorList]
      const payload = { ...action.payload }
      payload.id = uuidv4()
      contractorList.push(payload)
      state.contractorList = contractorList
      payload.id = uuidv4()
      hiringPlanContractorList.push(payload)
      state.hiringPlanContractorList = hiringPlanContractorList

      return state
    },
    addCompanyDebt: (state, action) => {
      const contractorList = [...state.contractorList]
      const payload = { ...action.payload }
      payload.id = uuidv4()
      contractorList.push(payload)
      state.contractorList = contractorList

      return state
    },
    updateCompanyDebt: (state, action) => {
      const contractorList = [...state.contractorList]
      const payload = { ...action.payload }
      payload.id = uuidv4()
      contractorList.push(payload)
      state.contractorList = contractorList

      return state
    },
    updateContractor: (state, action) => {
      const contractorList = [...state.contractorList]
      const payload = { ...action.payload }
      let index
      if (payload.id) {
        index = contractorList.findIndex(item => item.id === payload.id)
        contractorList[index] = payload
      } else if (payload._id) {
        index = contractorList.findIndex(item => item._id === payload._id)
        contractorList[index] = payload
      }
      state.contractorList = contractorList

      return state
    },
    deleteContractor: (state, action) => {
      const contractorList = [...state.contractorList].filter(item => {
        if (item.id) {
          return item.id !== action.payload
        }
        return item._id !== action.payload
      })
      state.contractorList = contractorList
    },
    addVendor: (state, action) => {
      const vendorList = [...state.vendorList]
      const payload = { ...action.payload }
      payload.id = uuidv4()
      vendorList.push(payload)
      state.vendorList = vendorList

      return state
    },
    updateVendor: (state, action) => {
      const vendorList = [...state.vendorList]
      const payload = { ...action.payload }
      let index
      if (payload.id) {
        index = vendorList.findIndex(item => item.id === payload.id)
        vendorList[index] = payload
      } else if (payload._id) {
        index = vendorList.findIndex(item => item._id === payload._id)
        vendorList[index] = payload
      }
      state.vendorList = vendorList

      return state
    },
    deleteVendor: (state, action) => {
      const vendorList = [...state.vendorList].filter(item => {
        if (item.id) {
          return item.id !== action.payload
        }
        return item._id !== action.payload
      })
      state.vendorList = vendorList
    },
    addClient: (state, action) => {
      const clientList = [...state.clientList]
      const payload = { ...action.payload }
      payload.id = uuidv4()
      clientList.push(payload)
      state.clientList = clientList

      return state
    },
    updateClient: (state, action) => {
      const clientList = [...state.clientList]
      const payload = { ...action.payload }
      let index
      if (payload.id) {
        index = clientList.findIndex(item => item.id === payload.id)
        clientList[index] = payload
      } else if (payload._id) {
        index = clientList.findIndex(item => item._id === payload._id)
        clientList[index] = payload
      }
      state.clientList = clientList

      return state
    },
    deleteClient: (state, action) => {
      const clientList = [...state.clientList].filter(item => {
        if (item.id) {
          return item.id !== action.payload
        }
        return item._id !== action.payload
      })
      state.clientList = clientList
    },
    addProduct: (state, action) => {
      const productList = [...state.productList]
      const payload = { ...action.payload }
      payload.id = uuidv4()
      productList.push(payload)
      state.productList = productList

      return state
    },
    updateProduct: (state, action) => {
      const productList = [...state.productList]
      const payload = { ...action.payload }
      let index
      if (payload.id) {
        index = productList.findIndex(item => item.id === payload.id)
        productList[index] = payload
      } else if (payload._id) {
        index = productList.findIndex(item => item._id === payload._id)
        productList[index] = payload
      }
      state.productList = productList

      return state
    },
    deleteProduct: (state, action) => {
      const productList = [...state.productList].filter(item => {
        if (item.id) {
          return item.id !== action.payload
        }
        return item._id !== action.payload
      })
      state.productList = productList
    },
    setSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload
    },
    setCompanyId: (state, action) => {
      console.log('set company id set')
      state.id = action.payload
    },
    changeLastTabLoaded: (state, action) => {
      state.isLastTabLoaded = action.payload
    },
    revenueUpdationReset: (state, action) => {
      state.revenueUpdationStatus = false
      state.revenueUpdationLoading = false
    },
    setCurrentlyActiveCompanyId: (state, action) => {
      state.currentlyActiveCompanyId = action.payload
    },
    setWelcomeScreenTriggering: (state, action) => {
      state.isWelcomeScreenOpen = action.payload
    },
    setIsNewCompanyRegistered: (state, action) => {
      state.isNewCompanyRegistered = action.payload
    },
    setWizardTriggeringStatus: (state, action) => {
      state.isTriggeredFromDashboard = action.payload
    },
    updateUserTier: (state, action) => {
      state.userTier = action.payload
    },
    setIsWizardVisible: (state, action) => {
      state.isWizardVisible = action.payload
    },
    setIsOpenedFromComDrop: (state, action) => {
      state.isOpenedFromCompDrop = action.payload
    },
    setReceivedNotifyMail: (state, action) => {
      state.receivedNotifyMail = action.payload
    }
  },
  extraReducers: {
    [addEquityRaiseData.fulfilled]: (state, { payload }) => {
      state.isSuccess = payload.success
      state.isSuccessEquityRaiseCreate = true
      state.isLoading = false
    },
    [addEquityRaiseData.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.isSuccessEquityRaiseCreate = false
      state.createEquityRaiseError = payload
    },
    [addEquityRaiseData.pending]: state => {
      state.isLoading = true
    },
    [updateHiringPlanEmployees.fulfilled]: (state, { payload }) => {
      state.isSuccess = payload.success
      // state.isSuccessEquityRaiseCreate = true   might need new state var
      state.isLoading = false
    },
    [updateHiringPlanEmployees.rejected]: (state, { payload }) => {
      state.isLoading = false
      // state.isSuccessEquityRaiseCreate = false
      state.createEquityRaiseError = payload
    },
    [updateHiringPlanEmployees.pending]: state => {
      state.isLoading = true
    },

    [getHiringPlanEmployees.fulfilled]: (state, { payload }) => {
      state.isSuccess = payload.success
      state.hiringPlanEmployeeList = payload
      state.isLoading = false
    },
    [getHiringPlanEmployees.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.createEquityRaiseError = payload
    },
    [getHiringPlanEmployees.pending]: state => {
      state.isLoading = true
    },

    [updateHiringPlanBulkEmployees.fulfilled]: (state, { payload }) => {
      state.isSuccess = payload.success
      state.isLoading = false
    },
    [updateHiringPlanBulkEmployees.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.createEquityRaiseError = payload
    },
    [updateHiringPlanBulkEmployees.pending]: state => {
      state.isLoading = true
    },

    [getHiringPlanBulkEmployees.fulfilled]: (state, { payload }) => {
      state.isSuccess = payload.success
      state.hiringPlanBulkEmployeeList = payload
      state.isLoading = false
    },
    [getHiringPlanBulkEmployees.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.createEquityRaiseError = payload
    },
    [getHiringPlanBulkEmployees.pending]: state => {
      state.isLoading = true
    },
    [updateHiringPlanContractors.fulfilled]: (state, { payload }) => {
      state.isSuccess = payload.success
      // state.isSuccessEquityRaiseCreate = true   might need new state var
      state.isLoading = false
    },
    [updateHiringPlanContractors.rejected]: (state, { payload }) => {
      state.isLoading = false
      // state.isSuccessEquityRaiseCreate = false
      state.createEquityRaiseError = payload
    },
    [updateHiringPlanContractors.pending]: state => {
      state.isLoading = true
    },
    [getHiringPlanContractors.fulfilled]: (state, { payload }) => {
      state.isSuccess = payload.success
      state.hiringPlanContractorList = payload
      state.isLoading = false
    },
    [getHiringPlanContractors.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.createEquityRaiseError = payload
    },
    [getHiringPlanContractors.pending]: state => {
      state.isLoading = true
    },
    [updateHiringPlanBulkContractors.fulfilled]: (state, { payload }) => {
      state.isSuccess = payload.success
      state.isLoading = false
    },
    [updateHiringPlanBulkContractors.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.createEquityRaiseError = payload
    },
    [updateHiringPlanBulkContractors.pending]: state => {
      state.isLoading = true
    },
    [getHiringPlanBulkContractors.fulfilled]: (state, { payload }) => {
      state.isSuccess = payload.success
      state.hiringPlanBulkContractorList = payload
      state.isLoading = false
    },
    [getHiringPlanBulkContractors.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.createEquityRaiseError = payload
    },
    [getHiringPlanBulkContractors.pending]: state => {
      state.isLoading = true
    },

    [createCompanyDebt.fulfilled]: (state, { payload }) => {
      const doc = { ...payload.doc }
      state.isCompanyDebtSetup = true
      state.debtPrincipleAmount = doc.principleAmount
      state.debtInterest = doc.interestRate
      state.debtDurationMonths = doc.durationMonths
      state.debtReceiptDate = doc.loanReceiptDate
      state.debtPrincipleRepaymentStart = doc.principleRepaymentStart
      state.debtInterestRepaymentStart = doc.interestRepaymentStart
      state.debtUpFrontFees = doc.upFrontFees
      state.isLoading = false
      state.isSuccess = true
    },
    [createCompanyDebt.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.isSuccess = false
      state.createCompanyError = payload
    },
    [createCompanyDebt.pending]: state => {
      state.isLoading = true
    },

    [createCompany.fulfilled]: (state, { payload }) => {
      console.log('create company fulfilled')
      if (payload.result.success) {
        state.id = payload.result.record.id || ''
        state.name = payload.result.record.name || ''
        state.email = payload.result.record.email || ''
        state.modelType = payload.result.record.modelType || ''
        state.industry = payload.result.record.industry || ''
        state.bankBalance = payload.result.record.bankBalance || ''
        state.taxYearEnd = moment(payload.result.record.taxYearEnd) || ''
        state.projectionMonths = payload.result.record.projectionMonths || ''
        state.departmentList = payload.result.record.departmentList || []
        state.coaList = payload.result.record.coaList || []
        state.isCoaChanged = payload.result.record.isCoaChanged || false
        state.employeeList = payload.result.record.employeeList || []
        state.contractorList = payload.result.record.contractorList || []
        state.vendorList = payload.result.record.vendorList || []
        state.clientList = payload.result.record.clientList || []
        state.productList = payload.result.record.productList || []
        state.revenueModel = payload.result.record.revenueModel || ''
        state.revenueComputationType = payload.result.record.revenueComputationType || ''
        state.badDebtPercent = payload.result.record.badDebtPercent || ''
        state.autoCalcGrowthRate = payload.result.record.autoCalcGrowthRate || ''
        state.growthRate = payload.result.record.growthRate || ''
        state.growthFreqType = payload.result.record.growthFreqType || ''
        state.startingRevenue = payload.result.record.startingRevenue || ''
        state.revenueStartingDate = payload.result.record.revenueStartingDate || ''
        state.isNewRegisterCompany = payload.result.record.isNewRegisterCompany || false
        state.hiringPlanEmployeeList = []
        state.hiringPlanBulkEmployeeList = []
        state.hiringPlanContractorList = []
        state.hiringPlanBulkContractorList = []
        // state.companyLogoUrl = payload.result.record.companyLogoUrl || null
      }
      state.isLoading = false
      state.isSuccess = true
    },
    [createCompany.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.isSuccess = false
      state.createCompanyError = payload
    },
    [createCompany.pending]: state => {
      state.isLoading = true
    },

    [createCompanyFromExcel.fulfilled]: (state, { payload }) => {
      if (payload.result.success) {
        state.id = payload.result.record.id || ''
        state.name = payload.result.record.name || ''
        state.email = payload.result.record.email || ''
        state.modelType = payload.result.record.modelType || ''
        state.industry = payload.result.record.industry || ''
        state.bankBalance = payload.result.record.bankBalance || ''
        state.taxYearEnd = moment(payload.result.record.taxYearEnd) || ''
        state.projectionMonths = payload.result.record.projectionMonths || ''
        state.departmentList = payload.result.record.departmentList || []
        state.coaList = payload.result.record.coaList || []
        state.isCoaChanged = payload.result.record.isCoaChanged || false
        state.employeeList = payload.result.record.employeeList || []
        state.contractorList = payload.result.record.contractorList || []
        state.vendorList = payload.result.record.vendorList || []
        state.clientList = payload.result.record.clientList || []
        state.productList = payload.result.record.productList || []
        state.revenueModel = payload.result.record.revenueModel || ''
        state.revenueComputationType = payload.result.record.revenueComputationType || ''
        state.badDebtPercent = payload.result.record.badDebtPercent || ''
        state.autoCalcGrowthRate = payload.result.record.autoCalcGrowthRate || ''
        state.growthRate = payload.result.record.growthRate || ''
        state.growthFreqType = payload.result.record.growthFreqType || ''
        state.startingRevenue = payload.result.record.startingRevenue || ''
        state.revenueStartingDate = payload.result.record.revenueStartingdate || ''
        state.hiringPlanEmployeeList = []
        state.hiringPlanBulkEmployeeList = []
        state.hiringPlanContractorList = []
        state.hiringPlanBulkContractorList = []
      }
      state.isLoading = false
      state.excelImportSuccess = true
      state.isSuccess = true
    },
    [createCompanyFromExcel.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.excelImportSuccess = false
      state.createCompanyError = payload
    },
    [createCompanyFromExcel.pending]: state => {
      state.isLoading = true
    },

    [updateCompany.fulfilled]: (state, { payload }) => {
      console.log('update company fulfilled', payload)
      if (payload.result.success) {
        state.id = payload.result.record.id || ''
        state.name = payload.result.record.name || ''
        state.email = payload.result.record.email || ''
        state.modelType = payload.result.record.modelType || ''
        state.industry = payload.result.record.industry || ''
        state.bankBalance = payload.result.record.bankBalance || ''
        state.taxYearEnd = moment(payload.result.record.taxYearEnd) || ''
        state.departmentList = payload.result.record.departmentList || [{ name: '', description: '' }]
        state.coaList = payload.result.record.coaList || []
        state.isCoaChanged = payload.result.record.isCoaChanged || false
        state.employeeList = payload.result.record.employeeList || []
        state.contractorList = payload.result.record.contractorList || []
        state.vendorList = payload.result.record.vendorList || []
        state.clientList = payload.result.record.clientList || []
        state.productList = payload.result.record.productList || []
        state.revenueModel = payload.result.record.revenueModel || ''
        state.revenueComputationType = payload.result.record.revenueComputationType || ''
        state.badDebtPercent = payload.result.record.badDebtPercent || ''
        state.autoCalcGrowthRate = payload.result.record.autoCalcGrowthRate || ''
        state.growthRate = payload.result.record.growthRate || ''
        state.growthFreqType = payload.result.record.growthFreqType || ''
        state.startingRevenue = payload.result.record.startingRevenue || ''
        state.revenueStartingDate = payload.result.record.revenueStartingDate || ''
        state.adminDepartment = payload.result.record.adminDepartment || ''
        state.isCongratulationTabFinished = payload.result.record.isCongratulationTabFinished
        state.isFirstTimeLogin = payload.result.record.isFirstTimeLogin || ''
        state.isWelcomeScreenOpen = payload.result.record.isWelcomeScreenOpen || ''
        state.preSelectedPlan = payload.result.record.preSelectedPlan || ''
        state.isEarlyBird = payload.result.record.isEarlyBird || ''
        state.isFirstWizardOpen = payload.result.record.isFirstWizardOpen || ''
        state.meTabInputs = payload.result.record.meTabInputs || {}
        state.expensesTabInputs = payload.result.record.expensesTabInputs || {}
        state.revenueTabInputs = payload.result.record.revenueTabInputs || {}
        state.hiringPlanEmployeeList = payload.result.record.hiringPlanEmployeeList || []
        state.hiringPlanBulkEmployeeList = payload.result.record.hiringPlanBulkEmployeeList || []
        state.hiringPlanContractorList = payload.result.record.hiringPlanContractorList || []
        state.hiringPlanBulkContractorList = payload.result.record.hiringPlanBulkContractorList || []
      }
      state.isLoading = false
      state.isSuccess = true
    },
    [updateCompany.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.isSuccess = false
      state.updateCompanyError = payload
    },
    [updateCompany.pending]: state => {
      state.isLoading = true
    },

    [updateCompanyInfo.fulfilled]: (state, { payload }) => {
      console.log('update company info fulfilled', payload)
      if (payload.result.success) {
        state.id = payload.result.record.id || ''
        state.name = payload.result.record.name || ''
        state.email = payload.result.record.email || ''
        state.modelType = payload.result.record.modelType || ''
        state.industry = payload.result.record.industry || ''
        state.bankBalance = payload.result.record.bankBalance || ''
        state.taxYearEnd = moment(payload.result.record.taxYearEnd) || ''
        state.departmentList = payload.result.record.departmentList || [{ name: '', description: '' }]
        state.coaList = payload.result.record.coaList || []
        state.isCoaChanged = payload.result.record.isCoaChanged || false
        state.employeeList = payload.result.record.employeeList || []
        state.contractorList = payload.result.record.contractorList || []
        state.vendorList = payload.result.record.vendorList || []
        state.clientList = payload.result.record.clientList || []
        state.productList = payload.result.record.productList || []
        state.revenueModel = payload.result.record.revenueModel || ''
        state.revenueComputationType = payload.result.record.revenueComputationType || ''
        state.badDebtPercent = payload.result.record.badDebtPercent || ''
        state.autoCalcGrowthRate = payload.result.record.autoCalcGrowthRate || ''
        state.growthRate = payload.result.record.growthRate || ''
        state.growthFreqType = payload.result.record.growthFreqType || ''
        state.startingRevenue = payload.result.record.startingRevenue || ''
        state.revenueStartingDate = payload.result.record.revenueStartingDate || ''
        state.companyLogoUrl = payload.result.record.companyLogoUrl || null
      }
      state.isLoading = false
      state.isSuccessCompanyInfo = true
    },
    [updateCompanyInfo.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.isSuccessCompanyInfo = false
      state.updateCompanyError = payload
    },
    [updateCompanyInfo.pending]: state => {
      state.isLoading = true
    },

    [finishCompanyCreate.fulfilled]: (state, { payload }) => {
      state.selectedCompany = payload.success.company
      state.isSuccessCompanyCreate = true
      state.isLoading = false
    },
    [finishCompanyCreate.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.isSuccessCompanyCreate = false
      state.updateCompanyError = payload
    },
    [finishCompanyCreate.pending]: state => {
      state.isLoading = true
    },

    [getCompaniesByUser.fulfilled]: (state, { payload }) => {
      state.isLoadingCompanies = false
      state.isLoadedCompanies = true
      state.incompleteCompanies = []
      const incompleteCompanies = [...payload.result].filter(company => !company.isComplete)
      if (incompleteCompanies.length > 0) {
        state.incompleteCompanies = incompleteCompanies
        state.incompleteCompanyId = incompleteCompanies[0]?.id
      }
      const companies = [...payload.result].filter(company => company.isComplete)
      const companyWithoutDemo = [...payload.result].filter(company => company.isComplete && !company.isDemo)
      state.companies = companies
      const earlyBirdComp = [...payload.result].find(company => (company.isEarlyBird || company.isFirstWizardOpen) && !company.isDemo)
      state.preSelectedPlan = earlyBirdComp?.preSelectedPlan
      state.isEarlyBird = earlyBirdComp?.isEarlyBird
      state.isFirstTimeLogin = earlyBirdComp?.isFirstTimeLogin
      state.creationDate = earlyBirdComp?.creationDate

      state.isFirstWizardOpen = earlyBirdComp?.isFirstWizardOpen

      state.introModal = !state.introModalOverride && companyWithoutDemo.length === 0 && incompleteCompanies.length === 1
      state.introModalOverride = false
    },
    [getCompaniesByUser.rejected]: (state, { payload }) => {
      state.isLoadingCompanies = false
      state.getCompaniesError = payload
      state.introModal = false
      state.companies = []
      state.incompleteCompanies = []
    },
    [getCompaniesByUser.pending]: state => {
      state.isLoadingCompanies = true
    },

    [getCompanyByID.fulfilled]: (state, { payload }) => {
      console.log('get company by id fulfilled')
      state.isCompanyLoaded = true
      state.isLoadingCompany = false
      state.isSuccessCompany = true
      if (payload.result && payload.result.id) {
        state.id = payload.result.id || ''
        state.isComplete = payload.result.isComplete || ''
        state.name = payload.result.name || ''
        state.email = payload.result.email || ''
        state.modelType = payload.result.modelType || ''
        state.industry = payload.result.industry || ''
        state.bankBalance = payload.result.bankBalance || ''
        state.taxYearEnd = moment(payload.result.taxYearEnd) || ''
        state.departmentList = payload.result.departmentList || []
        state.coaList = payload.result.coaList || []
        state.isCoaChanged = payload.result.isCoaChanged || false
        state.employeeList = payload.result.employeeList || []
        state.contractorList = payload.result.contractorList || []
        state.vendorList = payload.result.vendorList || []
        state.clientList = payload.result.clientList || []
        state.productList = payload.result.productList || []
        state.revenueModel = payload.result.revenueModel || ''
        state.revenueComputationType = payload.result.revenueComputationType || ''
        state.badDebtPercent = payload.result.badDebtPercent || ''
        state.autoCalcGrowthRate = payload.result.autoCalcGrowthRate || ''
        state.growthRate = payload.result.growthRate || ''
        state.growthFreqType = payload.result.growthFreqType || ''
        state.startingRevenue = payload.result.startingRevenue || ''
        state.revenueStartingDate = payload.result.revenueStartingDate || ''
        state.currentModelScreen = payload.result.currentModelScreen || ''
        state.isQbImportedData = payload.result.isImported || ''
        state.qbImportFlag = payload.result.importFlag || []
        state.adminDepartment = payload.result.adminDepartment || []
        state.isDemo = payload.result.isDemo || false
        state.equityAllocationList = payload.result.equityAllocationList || []
        state.isCongratulationTabFinished = payload.result.isCongratulationTabFinished
        state.userTier = payload.result.usage_tier
        state.paymentMode = payload.result.paymentMode
        state.isEarlyBird = payload.result.isEarlyBird
        state.companyLogoUrl = payload.result.companyLogoUrl
        state.companyCreationDate = payload.result.creationDate || ''
        state.fremiumStartDate = payload.result.fremiumStartDate || ''
        state.upComingPlan = payload.upComingPlan || ''
        state.isProductOnboardingComplete = payload.isProductOnboardingComplete
      }
    },
    [getCompanyByID.rejected]: (state, { payload }) => {
      state.isLoadingCompany = false
      state.getCompanyDetailsError = payload
    },
    [getCompanyByID.pending]: state => {
      state.isLoadingCompany = true
    },
    [deleteCompanyById.fulfilled]: (state, { payload }) => {
      state.isCompanyDeleted = true
      state.isLoading = false
    },
    [deleteCompanyById.rejected]: (state, { payload }) => {
      state.isCompanyDeleted = false
      state.isLoading = false
    },
    [deleteCompanyById.pending]: state => {
      state.isLoading = true
    },
    [leaveCompanyById.fulfilled]: (state, { payload }) => {
      state.isCompanyLeaved = true
      state.isLoading = false
    },
    [leaveCompanyById.rejected]: (state, { payload }) => {
      state.isCompanyLeaved = false
      state.isLoading = false
    },
    [leaveCompanyById.pending]: state => {
      state.isLoading = true
    },
    [createTask.fulfilled]: (state, { payload }) => {
      state.isTaskCreated = true
      state.isTaskCreateLoading = false
    },
    [createTask.rejected]: (state, { payload }) => {
      state.isTaskCreated = false
      state.isTaskCreateLoading = false
    },
    [createTask.pending]: state => {
      state.isTaskCreateLoading = true
    },
    [getUserTasks.fulfilled]: (state, { payload }) => {
      state.userTasks = payload.obj
      state.isLoading = false
    },
    [getUserTasks.rejected]: (state, { payload }) => {
      state.isTaskCreated = false
      state.isLoading = false
    },
    [getUserTasks.pending]: state => {
      state.isLoading = true
    },
    [getReassignedTasks.fulfilled]: (state, { payload }) => {
      state.reassignedTasks = payload.obj
      state.isLoading = false
    },
    [getReassignedTasks.rejected]: (state, { payload }) => {
      state.isTaskCreated = false
      state.isLoading = false
    },
    [getReassignedTasks.pending]: state => {
      state.isLoading = true
    },
    [updateTaskStatus.fulfilled]: (state, { payload }) => {
      state.isStatusUpdated = true
      state.isLoading = false
    },
    [updateTaskStatus.rejected]: (state, { payload }) => {
      state.isStatusUpdated = false
      state.isLoading = false
    },
    [updateTaskStatus.pending]: state => {
      state.isStatusUpdated = false
      state.isLoading = true
    },
    [deleteUserFromCompany.fulfilled]: (state, { payload }) => {
      state.isUserDeleted = true
      state.isLoading = false
    },
    [deleteUserFromCompany.rejected]: (state, { payload }) => {
      state.isUserDeleted = false
      state.isLoading = false
    },
    [deleteUserFromCompany.pending]: state => {
      state.isUserDeleted = false
      state.isLoading = true
    },

    [getupdateCellChanges.fulfilled]: (state, { payload }) => {
      state.revenueUpdationStatus = true
      state.revenueUpdationLoading = false
    },
    [getupdateCellChanges.rejected]: (state, { payload }) => {
      state.revenueUpdationStatus = true
      state.revenueUpdationLoading = false
    },
    [getupdateCellChanges.pending]: state => {
      state.revenueUpdationLoading = true
    },

    [getSimulationReport.fulfilled]: (state, { payload }) => {
      state.totalBurn = payload.totalBurn
      state.updatedTotalBurn = payload.updatedTotalBurn
      state.simulationLineItems = payload.lineItems
      state.getSimulationReportLoading = false
    },
    [getSimulationReport.rejected]: (state, { payload }) => {},
    [getSimulationReport.pending]: state => {
      state.getSimulationReportLoading = true
    },

    [getSystemLogs.fulfilled]: (state, { payload }) => {
      state.systemLogs = payload
    },
    [getSystemLogs.rejected]: (state, { payload }) => {},
    [getSystemLogs.pending]: state => {},
    [completeProductOnboarding.fulfilled]: (state, { payload }) => {
      state.isProductOnboardingComplete = true
    },
    [completeProductOnboarding.rejected]: (state, { payload }) => {
      state.isProductOnboardingComplete = false
      state.updateCompanyError = payload
    },
    [completeProductOnboarding.pending]: state => {
      state.isLoading = true
    }
  }
})

export const {
  clearState,
  resetState,
  clearCurrentModel,
  hardResetState,
  addDepartment,
  removeDepartment,
  updateDepartment,
  addEmployee,
  updateEmployee,
  deleteEmployee,
  addContractor,
  updateContractor,
  deleteContractor,
  addVendor,
  updateVendor,
  deleteVendor,
  addClient,
  updateClient,
  deleteClient,
  addProduct,
  updateProduct,
  deleteProduct,
  setSelectedCompany,
  setCompanyId,
  hideIntroModal,
  setIntroModalOverride,
  clearCompany,
  resetDeptFlag,
  isQbImportedData,
  changeLastTabLoaded,
  resetCompanyLoadedStatus,
  revenueUpdationReset,
  setXlUplodeDatas,
  // setExcelUploadError,
  showIntroModal,
  setCurrentlyActiveCompanyId,
  setIsNewCompanyRegistered,
  setWizardTriggeringStatus,
  updateUserTier,
  setIsWizardVisible,
  setIsOpenedFromComDrop,
  setReceivedNotifyMail,
  setWelcomeScreenTriggering
} = companySlice.actions
export const companySelector = state => state.company
export default companySlice.reducer
