import React from 'react'
import { TextField } from '@material-ui/core'
import { Field, useField } from 'formik'

const DateTimePickerWrapper = ({ name, startValue, ...otherProps }) => {
  const [field, meta] = useField(name)
  const configDateTimePicker = {
    ...field,
    ...otherProps,
    variant: 'outlined',
    fullWidth: true,
    defaultValue: startValue,
    type: 'date',
    InputLabelProps: {
      shrink: true
    }
  }
  if (meta && meta.error && meta.touched) {
    configDateTimePicker.error = true
    configDateTimePicker.helperText = meta.error
  }
  return <TextField {...configDateTimePicker} />
}

export default DateTimePickerWrapper
