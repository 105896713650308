/* eslint-disable prettier/prettier */
import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from 'react'
import { Box, Button, FormHelperText, Grid, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import EastIcon from '@material-ui/icons/East'
import DateRangeIcon from '@material-ui/icons/DateRange'

import expensesImage from 'assets/images/expenses_image.png'
import mrgnLogo from 'assets/images/mrgin-blue-logo.png'

import { Formik } from 'formik'
import * as Yup from 'yup'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import NumberFormat from 'react-number-format'

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import DoneIcon from '@material-ui/icons/Done'
import cssClasses from 'ui-component/company-setup-modal/infoTab.module.css'

import { useDispatch, useSelector } from 'react-redux'
import { setRegistrationSetupModelScreen, uiSelector } from 'store/ui/ui.slice'
import { companySelector } from 'store/company/company.slice'
import { finishCompanyCreate, getCompaniesByUser, updateCompany } from 'store/company/company.actions'
import { SNACKBAR_OPEN } from 'store/actions'
import { ObjectID } from 'bson'
import _ from 'lodash'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  selectOption: {
    color: '#000',
    fontFamily: 'Raleway',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '125%',
    padding: '8px 16px',
    '&:hover': {
      color: '#29A364',
      background: '#F9F6FF'
    }
    // '&.Mui-selected': { backgroundColor: '#F9F6FF' }
  },
  inputFocusedDate: {
    '& .MuiInputBase-input , .MuiOutlinedInput-root ': { backgroundColor: '#ffff' },
    border: '1px solid  #d8d8d8',
    borderRadius: '4px',
    color: '#29A364',
    height: '42px'
  }
}))

const ExpensesPage = props => {
  const classes = useStyles()
  const { isDemoCompany } = props
  const formRef = useRef(null)
  const inputFieldRef = [useRef(null), useRef(null), useRef(null)]
  const dispatch = useDispatch()
  const { registrationSetupModelScreen } = useSelector(uiSelector)
  const { expensesTabInputs, id, vendorList, updateCompanyError, selectedCompany, incompleteCompanyId } = useSelector(companySelector)
  const [currentIndexNo, setCurrentIndex] = useState(1)
  const [localVendorList, setLocalVendorList] = useState([])
  const [vendorGroupByParent, setVendorGroupByParent] = useState('')

  useEffect(() => {
    if (vendorList?.length > 0) {
      setLocalVendorList(vendorList)
    } else {
      const initialVendorList = [
        {
          _id: new ObjectID(),
          name: 'Advertising & Promotion',
          nestedUnder: '',
          cogs: false,
          hrRelated: false,
          govtTaxed: false
        },
        { _id: new ObjectID(), name: 'Employee Incidentals', nestedUnder: '', cogs: false, hrRelated: false, govtTaxed: false },
        { _id: new ObjectID(), name: 'Events', nestedUnder: '', cogs: false, hrRelated: false, govtTaxed: false },
        {
          _id: new ObjectID(),
          name: 'Facilities',
          nestedUnder: '',
          cogs: false,
          hrRelated: false,
          govtTaxed: false
        },
        { _id: new ObjectID(), name: 'IT Expense', nestedUnder: '', cogs: false, hrRelated: false, govtTaxed: false },
        { _id: new ObjectID(), name: 'Legal', nestedUnder: '', cogs: false, hrRelated: false, govtTaxed: false },

        { _id: new ObjectID(), name: 'Other', nestedUnder: '', cogs: false, hrRelated: false, govtTaxed: false },
        { _id: new ObjectID(), name: 'Returns', nestedUnder: '', cogs: false, hrRelated: false, govtTaxed: false },
        { _id: new ObjectID(), name: 'Warranty', nestedUnder: '', cogs: false, hrRelated: false, govtTaxed: false }
      ]
      const initialVendorNestedlist = [
        { _id: new ObjectID(), name: 'Utilities', nestedUnder: initialVendorList[3]._id, cogs: false, hrRelated: false, govtTaxed: false },
        {
          _id: new ObjectID(),
          name: 'Immigration',
          nestedUnder: initialVendorList[5]._id,
          cogs: false,
          hrRelated: false,
          govtTaxed: false
        },
        { _id: new ObjectID(), name: 'Intellectual Property', nestedUnder: initialVendorList[5]._id, hrRelated: false, govtTaxed: false }
      ]
      setLocalVendorList([...initialVendorList, ...initialVendorNestedlist])
    }
  }, [vendorList])

  useEffect(() => {
    if (localVendorList?.length > 0) {
      const vendorListSorted = _.sortBy(
        [...localVendorList],
        [
          function (o) {
            return o.nestedUnder === 'None'
          }
        ]
      )

      const vendorListGroupBy = _.groupBy(vendorListSorted, 'nestedUnder')
      setVendorGroupByParent(vendorListGroupBy)
    }
  }, [localVendorList])

  useEffect(() => {
    if (updateCompanyError !== '') {
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: updateCompanyError,
        variant: 'alert',
        alertSeverity: 'error'
      })
    }
  }, [dispatch, updateCompanyError])

  useEffect(() => {
    if (inputFieldRef[0].current) {
      inputFieldRef[0]?.current?.focus()
    }
  }, [])
  useEffect(() => {
    inputFieldRef[currentIndexNo - 1]?.current?.focus()
  }, [currentIndexNo])

  const onClickOkButton = fieldName => {
    formRef.current.setFieldTouched(fieldName, true)
    if (inputFieldRef[currentIndexNo - 1]?.current?.value !== '' && !formRef.current.errors[fieldName]) {
      if (currentIndexNo === 3) {
        if (formRef.current) {
          formRef.current?.handleSubmit()
        }
      } else setCurrentIndex(prevState => prevState + 1)
    } else {
      inputFieldRef[currentIndexNo - 1]?.current?.focus()
    }
  }

  const onClickNextButtonHandler = () => {
    // if (inputFieldRef[currentIndexNo - 1].current?.value !== '') {
    //   if (currentIndexNo === 5) {
    if (formRef.current) {
      formRef.current?.handleSubmit()
    }
    //   } else {
    //     setCurrentIndex(prevState => prevState + 1)
    //   }
    // } else {
    //   inputFieldRef[currentIndexNo - 1]?.current?.focus()
    // }
  }

  const handleKeyPress = (event, index) => {
    // if (event.key === 'Enter' && index === 3) {
    //   setCurrentIndex(prevState => prevState + 1)
    // }
    if (event.charCode === 13) {
      event.preventDefault()
      // inputFieldRef[index]?.current?.focus()
      onClickOkButton(event.target.name)
    }
  }

  const onClickBackButton = () => {
    if (currentIndexNo === 1) {
      dispatch(setRegistrationSetupModelScreen(registrationSetupModelScreen - 1))
    }
    setCurrentIndex(prevState => prevState - 1)
  }

  const formExpensesInitialValues = {
    biggestExpenses: expensesTabInputs.biggestExpenses || '',
    // accountName: expensesTabInputs.accountName,
    // nestedUnder: expensesTabInputs.nestedUnder,
    accountName: 'N/A',
    nestedUnder: 'not_nested',
    startingDate: expensesTabInputs.startingDate ? dayjs(expensesTabInputs.startingDate) : '',
    eachMonthExpenses: expensesTabInputs.monthExpenses ? expensesTabInputs.monthExpenses.toLocaleString('en-US') : ''
  }

  const handleAddExpensesTabInputs = (biggestExpenses, accountName, nestedUnder, eachMonthExpenses, startingDate) => {
    // const monthExpenses = eachMonthExpenses.toString().includes(',') ? parseInt(eachMonthExpenses.replace(/,/g, ''), 10) : eachMonthExpenses
    const monthExpenses = parseFloat(eachMonthExpenses.replace(/[$,]/g, ''))

    const payload = {
      biggestExpenses,
      accountName,
      nestedUnder,
      monthExpenses,
      startingDate: dayjs(startingDate).format('YYYY/MM')
    }
    console.log(payload, 'payload')

    const updatedNestedUnder = nestedUnder === 'not_nested' ? '' : nestedUnder

    const paylodExpenses = {
      name: accountName,
      department: 'Sales',
      frequency: 'Monthly',
      startDate: dayjs(startingDate).format('YYYY/MM'),
      endDate: null,
      amount: monthExpenses,
      nestedUnder: updatedNestedUnder
    }

    let vendorPayload
    if (vendorList?.length > 0) {
      vendorPayload = [...vendorList]
    } else {
      vendorPayload = [...localVendorList]
    }
    vendorPayload.push(paylodExpenses)

    const data = {
      id,
      vendorList: vendorPayload,
      expensesTabInputs: payload
    }
    dispatch(updateCompany(data))
  }

  // const initialTouched = {
  //   ...Object.keys(formExpensesInitialValues).reduce((acc, key) => {
  //     acc[key] = false
  //     return acc
  //   }, {}),
  //   biggestExpenses: true
  // }
  const getClassByIndex = fieldIndex => {
    const isPreviousIndex = currentIndexNo - 1 === fieldIndex
    const isNextIndex = currentIndexNo + 1 === fieldIndex

    switch (true) {
      case isPreviousIndex:
        return cssClasses.nonFocusedBoxTop
      case isNextIndex:
        return cssClasses.nonFocusedBoxBottom
      default:
        return cssClasses.box
    }
  }

  return (
    <Grid container>
      <Grid
        item
        // xs={5}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'start',
          minHeight: '100vh',
          backgroundColor: '#FCECFE',
          width: '40%'
        }}
      >
        <Grid
          item
          xs={9}
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '79px' }}
        >
          <img src={mrgnLogo} alt="MRGN_logo" style={{ marginBottom: '80px' }} />
          <Typography className={cssClasses.stepText}>Step 3 of 4</Typography>
          <Typography className={cssClasses.tabName1}>Expenses</Typography>
          <Typography className={cssClasses.tabDescription}>
            You are more than halfway done! Let's gather some business details. To begin, could you identify the most significant
            administrative expense?
          </Typography>
          <img src={expensesImage} alt="expenses_image" style={{ height: 'auto', marginTop: '70px', width: '100%' }} />
          {/* <Typography className={cssClasses.information}>The information shown is for illustrative purposes only.</Typography> */}
        </Grid>
      </Grid>

      <Grid
        item
        // xs={7}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', flexDirection: 'column', width: '60%' }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            minHeight: '85vh',
            maxHeight: '85vh',
            width: '320px'
            // marginTop: '79px'
          }}
        >
          {' '}
          {/* {currentIndexNo <= 3 && ( */}
          {/* <Box sx={{ marginBottom: '5px' }}>
            <Typography className={cssClasses.tabRightHeading}>Expense categories</Typography>
          </Box> */}
          {/* )} */}
          <Formik
            // validateOnChange
            // initialTouched={initialTouched}
            initialValues={formExpensesInitialValues}
            innerRef={formRef}
            enableReinitialize
            validateOnMount
            validationSchema={Yup.object().shape({
              biggestExpenses: Yup.string()
                .required('Biggest administrative expense is required').max(60, 'max 60 characters are allowed'),
              // .matches(/^[A-Za-z ]+$/, 'Only letters are allowed'),
              // accountName: Yup.string()
              //   .required('COA is required')
              //   .matches(/^[A-Za-z ]+$/, 'Only letters are allowed'),
              // nestedUnder: Yup.string(),
              eachMonthExpenses: Yup.string()
                .required('This field is required')
                .matches(/^(\$?\d{1,3}(,\d{3})*(\.\d+)?|\.\d+)$/, 'Enter a valid Amount'),
              startingDate: Yup.date().required('Start date is required').max(new Date(), 'Start date cannot be a future date').typeError("Start date is required")
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting, setFieldError, resetForm }) => {
              if (isDemoCompany) {
                dispatch(setRegistrationSetupModelScreen(registrationSetupModelScreen + 1))
                return
              }

              handleAddExpensesTabInputs(
                values.biggestExpenses,
                values.accountName,
                values.nestedUnder,
                values.eachMonthExpenses,
                values.startingDate
              )

              dispatch(setRegistrationSetupModelScreen(registrationSetupModelScreen + 1))
              setStatus({ success: true })
              setSubmitting(false)
              // resetForm(formMeInitialValues)
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              setFieldTouched,
              isValid
            }) => (
              // {/* <form noValidate onSubmit={handleSubmit} style={{ position: 'absolute', bottom: 130 }}> */}
              <form noValidate onSubmit={handleSubmit} style={{ height: '100%', position: 'relative' }}>
                <div style={{ height: '100px' }}>
                  <Button
                    onClick={onClickBackButton}
                    sx={{
                      // position: 'absolute',
                      // right: 630,
                      // top: 10,
                      borderRadius: '8px',
                      padding: 0,
                      backgroundColor: 'transparent',
                      color: '#131313',
                      fontFamily: 'Montserrat',
                      marginBottom: '32px'
                    }}
                    size="large"
                    variant="text"
                  >
                    {' '}
                    <KeyboardBackspaceIcon sx={{ marginRight: '10px' }} />
                    Back
                  </Button>
                  <Typography className={cssClasses.tabRightHeading}>Expense categories</Typography>
                </div>
                {(currentIndexNo === 1 || currentIndexNo - 1 === 1 || currentIndexNo + 1 === 1) && (
                  <div className={currentIndexNo === 1 ? cssClasses.box : cssClasses.nonFocusedBoxTop}>
                    <label
                      htmlFor="biggest_expenses"
                      className={currentIndexNo === 1 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}
                    >
                      1. My biggest monthly administrative expense{' '}
                    </label>

                    <input
                      ref={ref => {
                        inputFieldRef[0].current = ref
                      }}
                      onKeyPress={event => handleKeyPress(event, 1)}
                      disabled={isDemoCompany}
                      placeholder="Rent"
                      className={currentIndexNo === 1 ? cssClasses.splInput : cssClasses.nonFocusedInputBox}
                      value={values.biggestExpenses}
                      name="biggestExpenses"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      // onChange={e => {
                      //   handleChange(e)
                      //   if (values.biggestExpenses) setFieldTouched('biggestExpenses', true)
                      // }}
                      id="biggest_expenses"
                    />
                    <div style={{ height: '10px' }}>
                      {touched.biggestExpenses && errors.biggestExpenses && (
                        <FormHelperText error> {errors.biggestExpenses} </FormHelperText>
                      )}
                    </div>
                    {currentIndexNo === 1 && (
                      <div className={cssClasses.okButtonContainer}>
                        <button
                          onClick={() => onClickOkButton('biggestExpenses')}
                          className={
                            values.biggestExpenses && !errors.biggestExpenses ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled
                          }
                          type="button"
                        >
                          Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                        </button>
                        <p className={cssClasses.textPress}>
                          press <span className={cssClasses.textEnter}>Enter</span>
                        </p>
                      </div>
                    )}
                  </div>
                )}
                {/* {(currentIndexNo === 2 || currentIndexNo - 1 === 2 || currentIndexNo + 1 === 2) && (
                  <div className={getClassByIndex(2)}>
                    <label htmlFor="COA" className={currentIndexNo === 2 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}>
                      2. Chart Of Accounts{' '}
                    </label>
                    {(currentIndexNo === 2 || currentIndexNo === 3) && (
                      <>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div>
                            <input
                              ref={ref => {
                                inputFieldRef[1].current = ref
                              }}
                              onKeyPress={event => handleKeyPress(event, 2)}
                              disabled={isDemoCompany}
                              placeholder="Type here..."
                              className={
                                currentIndexNo === 2
                                  ? `${cssClasses.focusedInputBox} ${cssClasses.coaAccountInput}`
                                  : cssClasses.nonFocusedInputBox
                              }
                              value={values.accountName}
                              name="accountName"
                              onBlur={handleBlur}
                              onChange={e => {
                                handleChange(e)
                              }}
                              id="COA"
                            />
                            <div style={{ height: '10px' }}>
                              {touched.accountName && errors.accountName && <FormHelperText error> {errors.accountName} </FormHelperText>}
                            </div>
                          </div>
                        </div>
                        {currentIndexNo === 2 && (
                          <div className={cssClasses.okButtonContainer}>
                            <button
                              onClick={() => onClickOkButton('accountName')}
                              className={
                                values.accountName && !errors.accountName ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled
                              }
                              type="button"
                            >
                              Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                            </button>
                            <p className={cssClasses.textPress}>
                              press <span className={cssClasses.textEnter}>Enter</span>
                            </p>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )} */}
                {/* {(currentIndexNo === 3 || currentIndexNo + 1 === 3 || currentIndexNo - 1 === 3) && (
                  <div className={getClassByIndex(3)}>
                    <label htmlFor="nestedUnder" className={currentIndexNo === 3 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}>
                      3. Is this chart of account nested?{' '}
                    </label>
                    {(currentIndexNo === 3 || currentIndexNo === 4) && (
                      <Select
                        sx={{
                          '& .MuiSelect-outlined': {
                            background: '#fff',
                            padding: '3px 14px'
                          }
                        }}
                        displayEmpty
                        disableUnderline
                        variant="standard"
                        onKeyPress={event => handleKeyPress(event, 3)}
                        inputRef={ref => {
                          inputFieldRef[2].current = ref
                        }}
                        className={currentIndexNo === 3 ? cssClasses.selectBox : cssClasses.nonFocusedSelectBox}
                        placeholder="Select"
                        id="nestedUnder"
                        disabled={isDemoCompany}
                        value={values.nestedUnder || 'not_nested'}
                        name="nestedUnder"
                        onBlur={handleBlur}
                        onChange={event => {
                          handleChange(event)
                        }}
                      >
                        <MenuItem className={classes.selectOption} value="not_nested" onKeyDown={event => handleKeyPress(event, 3)}>
                          Not Nested
                        </MenuItem>
                        {localVendorList.map((vendor, i) => {
                          if (!vendor.nestedUnder) {
                            return (
                              <MenuItem
                                className={classes.selectOption}
                                value={vendor._id}
                                key={`vendorListItem${i}`}
                                onKeyDown={event => handleKeyPress(event, 3)}
                              >
                                {vendor.name}
                              </MenuItem>
                            )
                          }
                          return null
                        })}
                      </Select>
                    )}
                    <div style={{ height: '10px' }}>
                      {touched.nestedUnder && errors.nestedUnder && <FormHelperText error> {errors.nestedUnder} </FormHelperText>}
                    </div>
                    {currentIndexNo === 3 && (
                      <div className={cssClasses.okButtonContainer}>
                        <button onClick={() => onClickOkButton('nestedUnder')} className={cssClasses.okButtonEnabled} type="button">
                          Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                        </button>
                        <p className={cssClasses.textPress}>
                          press <span className={cssClasses.textEnter}>Enter</span>
                        </p>
                      </div>
                    )}
                  </div>
                )} */}
                {(currentIndexNo === 2 || currentIndexNo - 1 === 2) && (
                  <div className={getClassByIndex(2)}>
                    <label
                      htmlFor="each_month_expenses"
                      className={currentIndexNo === 2 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}
                    >
                      {`2. This is how much we usually spend each month on ${values.biggestExpenses === undefined ? '' : values.biggestExpenses
                        }`}
                    </label>
                    {(currentIndexNo === 2 || currentIndexNo === 3) && (
                      <div className={currentIndexNo === 2 ? cssClasses.focusedInputDiv : cssClasses.nonFocusedInputDiv}>
                        {' '}
                        {/* <span className={currentIndexNo === 2 ? cssClasses.focusedInputStaticText : cssClasses.nonFocusedInputStaticText}>
                          $
                        </span> */}
                        <NumberFormat
                          prefix='$'
                          getInputRef={ref => {
                            inputFieldRef[1].current = ref
                          }}
                          onKeyPress={event => handleKeyPress(event, 2)}
                          disabled={isDemoCompany}
                          style={{ marginTop: 0, paddingLeft: '20px' }}
                          placeholder="Type $ here..."
                          className={currentIndexNo === 2 ? cssClasses.focusedInputBox : cssClasses.nonFocusedInputBox}
                          value={values.eachMonthExpenses}
                          name="eachMonthExpenses"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          id="each_month_expenses"
                          thousandSeparator=","
                        />
                      </div>
                    )}
                    <div style={{ height: '10px' }}>
                      {touched.eachMonthExpenses && errors.eachMonthExpenses && (
                        <FormHelperText error> {errors.eachMonthExpenses} </FormHelperText>
                      )}
                    </div>
                    {currentIndexNo === 2 && (
                      <div className={cssClasses.okButtonContainer}>
                        <button
                          onClick={() => onClickOkButton('eachMonthExpenses')}
                          className={values.eachMonthExpenses ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                          type="button"
                        >
                          Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                        </button>
                        <p className={cssClasses.textPress}>
                          press <span className={cssClasses.textEnter}>Enter</span>
                        </p>
                      </div>
                    )}
                  </div>
                )}
                {(currentIndexNo === 3 || currentIndexNo - 1 === 3) && (
                  <div className={currentIndexNo === 3 ? cssClasses.box : cssClasses.nonFocusedBoxBottom}>
                    <label htmlFor="started_date" className={currentIndexNo === 3 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}>
                      {`3. We started incurring ${values.biggestExpenses === undefined ? '' : values.biggestExpenses} expenses on`}
                    </label>
                    {/* <LocalizationProvider disabled={isDemoCompany} dateAdapter={AdapterDateFns}> */}
                    {/* <DatePicker */}
                    {/* open={openStartDatePicker && !isDemoCompany}
                      allowSameDateSelection
                      value={values.startingDate}
                      name="startingDate"
                      onBlur={handleBlur}
                      disabled={isDemoCompany}
                      onAccept={() => setOpenStartDatePicker(false)}
                      onClose={() => setOpenStartDatePicker(false)}
                      onChange={value => setFieldValue('startingDate', value)} */}
                    {(currentIndexNo === 3 || currentIndexNo === 4) && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {/* <DateRangeIcon sx={{ marginTop: '5px', marginLeft: '-5px', marginRight: '10px' }} /> */}
                        {/* <input
                          ref={ref => {
                            inputFieldRef[2].current = ref
                          }}
                          onKeyPress={event => handleKeyPress(event, 3)}
                          type="date"
                          value={values.startingDate}
                          id="started_date"
                          placeholder="Select"
                          onBlur={handleBlur}
                          name="startingDate"
                          style={{ border: 'none', backgroundColor: '#ffffff', fontSize: '26px' }}
                          className={currentIndexNo === 3 ? cssClasses.focusedInputBox : cssClasses.nonFocusedInputBox}
                          onChange={event => setFieldValue('startingDate', event.target.value)}
                        /> */}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            disableFuture
                            views={['month', 'year']}
                            inputRef={ref => {
                              inputFieldRef[2].current = ref
                            }}
                            value={values.startingDate}
                            name="startingDate"
                            onBlur={handleBlur}
                            placeholder="Select"
                            slotProps={{
                              textField: {
                                // variant: 'standard',
                                id: 'started_date',
                                size: 'small',
                                error: false,
                                onKeyPress: event => {
                                  handleKeyPress(event, 3)
                                }
                              }
                            }}
                            format="MM/YYYY"
                            sx={{
                              width: '300px',
                              height: '44px',
                              marginTop: '22px'
                            }}
                            onKeyPress={event => {
                              handleKeyPress(event, 3)
                            }}
                            // className={currentIndexNo === 3 ? cssClasses.selectBox : cssClasses.nonFocusedInputBox}
                            className={currentIndexNo === 3 ? classes.inputFocusedDate : classes.inputNonfocusedDate}
                            onChange={value => {
                              setFieldValue('startingDate', value)
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    )}
                    <div style={{ height: '10px' }}>
                      {touched.startingDate && errors.startingDate && <FormHelperText error> {errors.startingDate} </FormHelperText>}
                    </div>
                    {currentIndexNo === 3 && <div style={{ height: '50px', marginTop: '10px' }} />}
                    {/* {currentIndexNo === 5 && (
                      <div className={cssClasses.okButtonContainer}>
                        <button
                          onClick={onClickOkButton}
                          className={values.startingDate ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                          type="button"
                        >
                          Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                        </button>
                        <p className={cssClasses.textPress}>
                          press <span className={cssClasses.textEnter}>Enter</span>
                        </p>
                      </div>
                    )} */}
                    {/* /> */}
                    {/* </LocalizationProvider> */}
                  </div>
                )}
                <button
                  type="button"
                  className={currentIndexNo === 3 ? cssClasses.formNextButton : cssClasses.disabledFormNextButton}
                  onClick={onClickNextButtonHandler}
                >
                  Next
                </button>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ExpensesPage
