import React, { useState } from 'react'
import DetailedBillableProductsTabView from './DetailedBillableProductsTabView'
import DetailedBillableClientsTabView from './DetailedBillableClientsTabView'

const DetailBillableandProduct = props => {
  const { selectedRevenueComputationalType, isEditButtonClicked, editItem, setEditItem, setEditProdItem, editProdItem, setAddedItem } =
    props
  const [isdrawerAddButtonClicked, setdrawerAddButtonClicked] = useState(false)

  return (
    <div style={{ width: '100%' }}>
      {isdrawerAddButtonClicked ? (
        <DetailedBillableClientsTabView
          selectedRevenueComputationalType={selectedRevenueComputationalType}
          isEditButtonClicked={isEditButtonClicked}
          editItem={editItem}
          setEditItem={setEditItem}
          setAddedItem={setAddedItem}
        />
      ) : (
        <DetailedBillableProductsTabView
          setdrawerAddButtonClicked={setdrawerAddButtonClicked}
          isDemoCompany={false}
          selectedRevenueComputationalType={selectedRevenueComputationalType}
          isEditButtonClicked={isEditButtonClicked}
          editItem={editItem}
          setEditItem={setEditItem}
          setEditProdItem={setEditProdItem}
          editProdItem={editProdItem}
          isdrawerAddButtonClicked={isdrawerAddButtonClicked}
        />
      )}
    </div>
  )
}

export default DetailBillableandProduct
