import axios from 'axios'
import { SERVER_URL } from '.'
import cookies from 'utils/cookies'
import { signOut } from 'store/auth/auth.actions'

const timeout = 30000

const defaultConfig = {
  timeout
}

let store

const injectStore = _store => {
  store = _store
}

const axiosInstance = axios.create({
  baseURL: SERVER_URL,
  config: defaultConfig
})

// eslint-disable-next-line no-prototype-builtins
const isHandlerEnabled = (config = {}) => !(config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled)

const requestHandler = async request => {
  if (isHandlerEnabled(request)) {
    const accessToken = cookies.get('access_token')
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers['Content-Type'] = 'application/json'
  }
  return request
}

let isFetchingAccessToken = false
const errorHandler = error => {
  //
  // if (isHandlerEnabled(error.config)) {

  if (error.response && error.response.status === 401 && error.response.data && error.response.data.message === 'Please authenticate') {
    if (!isFetchingAccessToken) {
      isFetchingAccessToken = true
      const { dispatch } = store
      dispatch(signOut())
    }
    const err = 'Token expired. Please login again to continue.'
    return Promise.reject(new Error(err))
  }
  return Promise.reject(error)
}

const successHandler = response => {
  if (isHandlerEnabled(response.config)) {
    // Handle responses Provide Success Message
  }
  return response
}

axiosInstance.interceptors.request.use(requestHandler)
axiosInstance.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
)

export { injectStore, axiosInstance, isHandlerEnabled, requestHandler }
