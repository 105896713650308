import React, { useEffect, useState } from 'react'

// material-ui
import { makeStyles, useTheme } from '@material-ui/styles'
import { Card, CardContent, CardMedia, Grid, Typography, Box } from '@material-ui/core'

// project imports

import { gridSpacing } from 'store/constant'

// assets

import image from 'assets/images/img-build.svg'
import imageBackground from 'assets/images/img-bg-grid.svg'
import imageDarkBackground from 'assets/images/img-bg-grid-dark.svg'
import imageParts from 'assets/images/img-bg-parts.svg'

import { useSelector } from 'react-redux'
import { modelsSelector } from 'store/models/models.slice'
import ReportIcon from '@material-ui/icons/Report'

// style constant
const useStyles = makeStyles({
  errorImg: {
    maxWidth: '100px',
    margin: '0 auto',
    position: 'relative'
  },
  errorContent: {
    maxWidth: '180px',
    margin: '0 auto',
    textAlign: 'center'
  },
  constructionBlock: {
    minHeight: '50vh',
    maxWidth: '350px'
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center'
  },
  imgBuild: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    animation: '5s bounce ease-in-out infinite'
  },
  imgParts: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    animation: '10s blink ease-in-out infinite'
  }
})

// ========================|| UNDER CONSTRUCTION PAGE ||======================== //

const UnderConstruction = () => {
  const theme = useTheme()
  const classes = useStyles()
  const { summary } = useSelector(modelsSelector)
  const [runway, setRunway] = useState(summary.runway)
  const currentMonth = new Date().getMonth() + 1
  const [notificationItem, setNotificationItem] = useState('')

  useEffect(() => {
    if (runway[currentMonth] < 0) {
      setNotificationItem('RUNNING_MINUS')
    } else if (runway[currentMonth] >= 0 && runway[currentMonth + 1] <= 0) {
      setNotificationItem('ONE_MORE_MONTH')
    } else if (runway[currentMonth] >= 0 && runway[currentMonth + 1] >= 0 && runway[currentMonth + 4] <= 0) {
      setNotificationItem('FOUR_MORE_MONTH')
    } else {
      console.log()
    }
  }, [currentMonth, runway])

  const renderRunningMinus = () => (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Box>
        <ReportIcon sx={{ color: 'red', mt: 0.5 }} />
      </Box>
      <Box sx={{ mt: 0.7 }}>
        <Typography sx={{ color: 'red', pl: 1, fontWeight: 800, fontSize: '15px' }}>Financial Warning</Typography>

        <Typography sx={{ pl: 2 }} style={{ fontSize: '12px', color: '#888888' }}>
          Your bank account is running a minus. Please consider raising outside capital.
        </Typography>
      </Box>
    </Box>
  )

  const renderOneMoreMonth = () => (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Box>
        <ReportIcon sx={{ color: 'red', mt: 0.5 }} />
      </Box>
      <Box sx={{ mt: 0.7 }}>
        <Typography sx={{ color: 'red', pl: 1, fontWeight: 800, fontSize: '15px' }}>Financial Warning</Typography>

        <Typography sx={{ pl: 2 }} style={{ fontSize: '12px', color: '#888888' }}>
          You have one more month of runway in your bank account. Please consider raising outside capital.
        </Typography>
      </Box>
    </Box>
  )

  const renderFourMoreMonths = () => (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Box>
        <ReportIcon sx={{ color: 'red', mt: 0.5 }} />
      </Box>
      <Box sx={{ mt: 0.7 }}>
        <Typography sx={{ color: 'red', pl: 1, fontWeight: 800, fontSize: '15px' }}>Financial Warning</Typography>

        <Typography sx={{ pl: 2 }} style={{ fontSize: '12px', color: '#888888' }}>
          You have four more months of runway in your bank account. This is a good time to consider raising outside capital.
        </Typography>
      </Box>
    </Box>
  )

  const renderNotificationModels = () => {
    switch (notificationItem) {
      case 'RUNNING_MINUS':
        return renderRunningMinus()
      case 'ONE_MORE_MONTH':
        return renderOneMoreMonth()
      case 'FOUR_MORE_MONTH':
        return renderFourMoreMonths()
      default:
        return null
    }
  }

  return (
    <Card className={classes.constructionBlock}>
      <CardContent>
        {runway.length === 0 && (
          <Grid container justifyContent="center" spacing={gridSpacing}>
            <Grid item xs={12}>
              <div className={classes.errorImg}>
                <CardMedia
                  component="img"
                  image={theme.palette.mode === 'dark' ? imageDarkBackground : imageBackground}
                  title="Slider5 image"
                />
                <CardMedia component="img" image={imageParts} title="Slider5 image" className={classes.imgParts} />
                <CardMedia component="img" image={image} title="Slider5 image" className={classes.imgBuild} />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.errorContent}>
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={12}>
                    <Typography variant="h1" component="div">
                      Coming Soon
                    </Typography>
                  </Grid>
                  <Grid item xs={12} />
                </Grid>
              </div>
            </Grid>
          </Grid>
        )}

        {runway.length > 0 && <Box>{renderNotificationModels()}</Box>}
      </CardContent>
    </Card>
  )
}

export default UnderConstruction
