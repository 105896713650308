import React from 'react'

// material-ui
import { Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'

// project imports
import NavGroup from './NavGroup'
import menuItem from 'menu-items'
import guestMenuItem from 'menu-items-guest'
import { authSelector } from 'store/auth/auth.slice'

// ===========================|| SIDEBAR MENU LIST ||=========================== //

const MenuList = () => {
  const { isGuestUser } = useSelector(authSelector)
  let navItems
  if (isGuestUser) {
    navItems = guestMenuItem.items.map(item => {
      switch (item.type) {
        case 'group':
          return <NavGroup key={item.id} item={item} />
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          )
      }
    })
  } else {
    navItems = menuItem.items.map(item => {
      switch (item.type) {
        case 'group':
          return <NavGroup key={item.id} item={item} />
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          )
      }
    })
  }

  return navItems
}

export default MenuList
