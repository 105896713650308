import React, { useRef, useState } from 'react'
import { Box, Divider, Drawer, FormControl, FormHelperText, Grid, IconButton, TextareaAutosize, Typography } from '@material-ui/core'
import cssClasses from './infoTab.module.css'

import CloseIcon from '@material-ui/icons/Close'
import FileUploadOutlinedIcon from '@material-ui/icons/FileUploadOutlined'

import UploadIcon from '../../assets/images/icons/UploadIcon.png'

import { Formik } from 'formik'
import * as Yup from 'yup'
import DetailBillableAndProduct from 'views/pages/authentication/CompanyRegistration/DetailBillableAndProducts'
import DetailBillableandProduct from './DetailBillableandProduct'
import { useDispatch, useSelector } from 'react-redux'
import { companySelector } from 'store/company/company.slice'
import TopLineBillableClientsTabView from './TopLineBillableClientsTabView'
import { setEquityEditButtonClicked } from 'store/ui/ui.slice'
import { updateCompany } from 'store/company/company.actions'
import { makeStyles } from '@material-ui/styles'
import dayjs from 'dayjs'
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'

import { InlineWidget, useCalendlyEventListener, PopupWidget } from 'react-calendly'
import NoteToExpertPage from './NoteToExpertPage'

const useStyles = makeStyles(theme => ({
  disabledButton: {
    backgroundColor: '#E9E9E9',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '800',
    marginTop: '30px',
    width: '320px',
    height: '44px',
    borderRadius: '4px',
    outline: 'none',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    color: '#A3A3A3'
  },
  timeSlot_select: {
    backgroundColor: '#EFE9FE',
    fontWeight: '400',
    fontSize: '16px',
    color: '#131313',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '320px',
    height: '40px',
    border: '1px solid #29A364',
    borderRadius: '4px',
    cursor: 'pointer'
  },
  timeSlot_unSelect: {
    fontWeight: '400',
    fontSize: '16px',
    color: '#131313',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '320px',
    height: '40px',
    border: '1px solid #CFD0D9',
    borderRadius: '4px',
    cursor: 'pointer'
  },
  formNextButton: {
    background: 'var(--secondary-purple-600, #29A364)',
    color: 'var(--neutrals-neutral-white, #fff)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 800,
    marginTop: '30px',
    width: '320px',
    height: '44px',
    borderRadius: '4px',
    outline: 'none',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  }
}))

const ScheduleCallPage = props => {
  const { setCurrentTabNumber, setSelectedDate, setSelectedTime } = props
  const classes = useStyles()

  const [showTimeSlot, setShowTimeSlot] = useState(false)
  const [timeSlot, settimeSlot] = useState('')
  const [dateValue, setDateValue] = useState(null)

  const timeSlotData = [
    { text: '9:00am Eastern Time (ET)', value: '9:00am EST' },
    { text: '10:00am Eastern Time (ET)', value: '10:00am EST' },
    { text: '11:00am Eastern Time (ET)', value: '11:00am EST' },
    { text: '12:00pm Eastern Time (ET)', value: '12:00pm EST' },
    { text: '1:00pm Eastern Time (ET)', value: '1:00pm EST' }
  ]

  const onClickNextHandler = () => {
    setCurrentTabNumber(prevState => prevState + 1)
    setSelectedDate(dateValue)
    setSelectedTime(timeSlot)
  }

  return (
    <>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between' }}>
        <h1 className={cssClasses.drawerHeading}>Talk to an expert.</h1>
        <p className={cssClasses.drawerDesc}>
          We're excited to introduce our enhanced mrgn offering, which brings an added dimension to you, our valued customer.
        </p>
        <p className={cssClasses.drawerDesc}>
          This collaboration is a strategic partnership aimed at providing comprehensive support to our users at every stage. As part of the
          mrgn offering, we're committed to delivering an enriched experience for our clients.
        </p>
      </div>

      <div style={{ width: '100%' }}>
        <p className={cssClasses.drawerPageNumber}>2/2</p>
        <p style={{ marginTop: 0 }} className={cssClasses.drawerHeadingSecond}>
          Schedule call with expert
        </p>
        <Divider />
        <p className={cssClasses.drawerUploadDesc}>
          On the day that is convenient for you, we will schedule your demo session. Select the date & time below.
        </p>
        <>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateCalendar', 'DateCalendar']}>
              <DemoItem>
                <DateCalendar
                  disablePast
                  value={dateValue}
                  onChange={newValue => {
                    setDateValue(newValue)
                    settimeSlot('')
                    setShowTimeSlot(true)
                  }}
                  views={['month', 'day']}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
        </>
        <>
          {showTimeSlot && (
            <Box>
              <Grid container direction="column" justifyContent="spaceBetween" alignItems="center" spacing={2}>
                {timeSlotData.map((time, i) => (
                  <Grid item key={i}>
                    <Typography
                      onClick={() => {
                        settimeSlot(time.value)
                      }}
                      className={time.value === timeSlot ? classes.timeSlot_select : classes.timeSlot_unSelect}
                    >
                      {time.text}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </>

        <Divider sx={{ marginTop: '230px', marginBottom: '10px' }} />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <button
            onClick={() => {
              setShowTimeSlot(false)
              settimeSlot('')
              setDateValue(null)
            }}
            className={cssClasses.wizardCancelButton}
            type="button"
          >
            Cancel
          </button>
          <button
            onClick={onClickNextHandler}
            type="button"
            className={!(timeSlot === '') ? cssClasses.wizardSaveButton : cssClasses.disabledButton}
            disabled={dateValue === '' || timeSlot === ''}
          >
            Next
          </button>
        </div>
      </div>
    </>
  )
}

export default ScheduleCallPage
