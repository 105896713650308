import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Box, Button, FormHelperText, Grid, MenuItem, Select, Typography } from '@material-ui/core'
import EastIcon from '@material-ui/icons/East'
import DateRangeIcon from '@material-ui/icons/DateRange'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'

import revenueImage from 'assets/images/revenue_tab_img.png'
import mrgnLogo from 'assets/images/mrgin-blue-logo.png'

import { Formik } from 'formik'
import * as Yup from 'yup'
import { DatePicker, LocalizationProvider } from '@material-ui/lab'
import AdapterDateFns from '@material-ui/lab/AdapterMoment'
import DoneIcon from '@material-ui/icons/Done'

import cssClasses from 'ui-component/company-setup-modal/infoTab.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { setRegistrationSetupModelScreen, toggleCompanySetupModal, uiSelector } from 'store/ui/ui.slice'
import { companySelector, setIsNewCompanyRegistered } from 'store/company/company.slice'
import { updateCompany } from 'store/company/company.actions'

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'

import Saas from './Saas'
import CommerceRetail from './CommerceRetail'
import TopLineBillable from './TopLineBillable'
import DetailBillableAndProduct from './DetailBillableAndProducts'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  selectOption: {
    color: '#000',
    fontFamily: 'Raleway',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '125%',
    padding: '8px 16px',
    '&:hover': {
      color: '#29A364',
      background: '#F9F6FF'
    }
    // '&.Mui-selected': { backgroundColor: '#F9F6FF' }
  }
}))

const RevenuePage = props => {
  const classes = useStyles()
  const { isDemoCompany } = props
  const formRef = useRef(null)
  const dispatch = useDispatch()
  const { registrationSetupModelScreen } = useSelector(uiSelector)
  const { revenueModel, revenueComputationType } = useSelector(companySelector)
  const { id } = useSelector(companySelector)
  const [currentIndexNo, setCurrentIndex] = useState(1)
  const [selected, setSelected] = useState(false)
  const [revenueModelItem, setRevenueModel] = useState('Pro Services')
  const [revenueComputationTypeItem, setRevenueComputationTypeItem] = useState('')
  const revenueFieldRef = [useRef(null), useRef(null)]
  const [selectBusiness, setselectBusiness] = useState(false)
  const [focusOkButton, setFocusOkButton] = useState(false)
  const [businessSells, setBusinessSells] = useState(false)
  const formRevenueInitialValues = {
    revenueModel: revenueModel || 'Pro Services',
    revenueComputationType: revenueComputationType || '',
    submit: null
  }
  const handlerevenueModelChange = event => {
    formRef.current.setFieldValue('revenueModel', event.target.value)
    formRef.current.setFieldValue('revenueComputationType', '')
    setRevenueComputationTypeItem('')
    // setCurrentIndex(2)
    // if (event.target.value === 'Pro Services') setCurrentIndex(2)
    setRevenueModel(event.target.value)
  }
  useEffect(() => {
    if (revenueComputationType) setRevenueComputationTypeItem(revenueComputationType)
    if (revenueModel) setRevenueModel(revenueModel)
  }, [])

  const onClickCollectRevenueButton = event => {
    formRef.current.setFieldValue('revenueComputationType', event.target.value)
    // setCurrentIndex(3)
    setRevenueComputationTypeItem(event.target.value)
  }

  const onClickBackButton = () => {
    if (currentIndexNo === 1) {
      dispatch(setRegistrationSetupModelScreen(registrationSetupModelScreen - 1))
    }
    setCurrentIndex(prevState => prevState - 1)
  }
  useEffect(() => {
    if (revenueFieldRef[0].current) {
      revenueFieldRef[0]?.current?.focus()
    }
  }, [])

  useEffect(() => {
    revenueFieldRef[currentIndexNo - 1]?.current?.focus()
  }, [currentIndexNo])

  const onClickRevenueComputaionTypeNextButton = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit()
      setCurrentIndex(prevState => prevState + 1)
    }
  }

  const onClickOkButton = fieldName => {
    formRef.current.setFieldTouched(fieldName, true)
    if (revenueFieldRef[currentIndexNo - 1]?.current?.value !== '' && !formRef.current.errors[fieldName]) {
      if (currentIndexNo <= 2) setCurrentIndex(prevState => prevState + 1)
      // if (currentIndexNo === 7 || currentIndexNo === 6) {
      //   if (formRef.current) {
      //     formRef.current?.handleSubmit()
      //   }
      // } else {
      //   setCurrentIndex(prevState => prevState + 1)
      // }
    } else {
      revenueFieldRef[currentIndexNo - 1]?.current?.focus()
    }
  }

  const handleAddRevenueTabInputs = (revenueModel, revenueComputationType) => {
    const data = {
      id,
      revenueModel,
      revenueComputationType
    }
    dispatch(updateCompany(data))
  }

  const onClickRevenueModelNextButton = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit()
      setCurrentIndex(prevState => prevState + 1)
    }
  }

  const getClassByIndex = fieldIndex => {
    const isPreviousIndex = currentIndexNo - 1 === fieldIndex
    const isNextIndex = currentIndexNo + 1 === fieldIndex

    switch (true) {
      case isPreviousIndex:
        return cssClasses.nonFocusedBoxTop
      case isNextIndex:
        return cssClasses.nonFocusedBoxBottom
      default:
        return cssClasses.box
    }
  }

  useEffect(() => {
    if (currentIndexNo === 1 && businessSells) document.getElementById('okButton1').focus()
    if (currentIndexNo === 2) document.getElementById('okButton2').focus()
    // if (currentIndexNo === 7) document.getElementById('submitButton').focus()
  }, [focusOkButton])
  // useEffect(() => {
  //   if (revenueFieldRef[0].current) {
  //     revenueFieldRef[0]?.current?.focus()
  //   }
  // }, [])
  // const getFocusOnNextBtn = () => {
  //   document.getElementById('nxtBtn').focus()
  // }

  // useEffect(() => {
  //   getFocusOnNextBtn()
  // }, [selectBusiness])
  const handleClick = (event, index) => {
    if (event.type === 'click' && index === 1) {
      setFocusOkButton(prev => !prev)
      setBusinessSells(true)
    }
    if (event.type === 'click' && index === 2) {
      setFocusOkButton(prev => !prev)
    }
  }
  const handleKeyPress = (event, index) => {
    if (event.key === 'Enter' && index === 1) {
      setCurrentIndex(2)
    }
    if (event.key === 'Enter' && index === 2) {
      setCurrentIndex(3)
    }
  }

  return (
    <Grid container>
      <Grid
        // xs={5}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'start',
          minHeight: '100vh',
          backgroundColor: '#F2F7F4',
          width: '40%'
        }}
      >
        <Grid
          item
          xs={9}
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '79px' }}
        >
          <img src={mrgnLogo} alt="MRGN_logo" style={{ marginBottom: '80px' }} />
          <Typography className={cssClasses.stepText}>Step 4 of 4</Typography>
          <Typography className={cssClasses.tabName1}>Revenue</Typography>
          <Typography className={cssClasses.tabDescription}>
            Foremost, we'd like to understand your revenue streams. Could you provide insights into what products or services your business
            sells?
          </Typography>
          <img src={revenueImage} alt="expenses_image" style={{ height: 'auto', marginTop: '70px', width: '100%' }} />
          {/* <Typography className={cssClasses.information}>The information shown is for illustrative purposes only.</Typography> */}
        </Grid>
      </Grid>

      <Grid
        item
        // xs={7}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', flexDirection: 'column', width: '60%' }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '320px',
            minHeight: '85vh',
            maxHeight: '85vh',
            // marginTop: '79px'
            position: 'relative'
          }}
        >
          {' '}
          <Formik
            initialValues={formRevenueInitialValues}
            innerRef={formRef}
            enableReinitialize
            validationSchema={Yup.object().shape({
              revenueModel: Yup.string().required('This field is required'),
              revenueComputationType: revenueModelItem === 'Pro Services' ? Yup.string().required('This field is required') : Yup.string()
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting, setFieldError, resetForm }) => {
              handleAddRevenueTabInputs(values.revenueModel, values.revenueComputationType)
              setStatus({ success: true })
              setSubmitting(false)
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div style={{ height: '100px' }}>
                  <Button
                    onClick={onClickBackButton}
                    sx={{
                      // position: 'absolute',
                      // right: 630,
                      // top: 10,
                      borderRadius: '8px',
                      padding: 0,
                      backgroundColor: 'transparent',
                      color: '#131313',
                      fontFamily: 'Montserrat',
                      marginBottom: '32px'
                    }}
                    size="large"
                    variant="text"
                  >
                    {' '}
                    <KeyboardBackspaceIcon sx={{ marginRight: '10px' }} />
                    Back
                  </Button>
                  <Typography className={cssClasses.tabRightHeading}>Choose revenue model type</Typography>
                  {/* <Typography className={cssClasses.tabRightDesc}>Tell us about your company revenue. </Typography> */}
                </div>
                {currentIndexNo === 1 && (
                  <div className={currentIndexNo === 1 ? cssClasses.box : cssClasses.nonFocusedBoxTop}>
                    <label htmlFor="revenue_model" className={currentIndexNo === 1 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}>
                      1. Our business sells{' '}
                    </label>{' '}
                    <Select
                      sx={{
                        '& .MuiSelect-outlined': {
                          background: '#fff',
                          padding: '3px 14px'
                        }
                      }}
                      displayEmpty
                      disableUnderline
                      variant="standard"
                      placeholder="Select"
                      inputRef={ref => {
                        revenueFieldRef[0].current = ref
                      }}
                      id="revenue_model"
                      className={currentIndexNo === 1 ? cssClasses.selectBox : cssClasses.nonFocusedSelectBox}
                      value={values.revenueModel}
                      name="revenueModel"
                      onBlur={handleBlur}
                      onChange={event => {
                        // handleChange(event)
                        handlerevenueModelChange(event)
                        // onClickOkButton(event.target.name)
                      }}
                    >
                      <MenuItem disabled hidden value="">
                        Select
                      </MenuItem>
                      <MenuItem
                        className={classes.selectOption}
                        value="Pro Services"
                        onKeyDown={event => handleKeyPress(event, 1)}
                        onClick={event => handleClick(event, 1)}
                      >
                        Professional Services
                      </MenuItem>
                      <MenuItem
                        className={classes.selectOption}
                        value="Commerce / Retail"
                        onKeyDown={event => handleKeyPress(event, 1)}
                        onClick={event => handleClick(event, 1)}
                      >
                        Physical Goods
                      </MenuItem>
                      <MenuItem
                        className={classes.selectOption}
                        value="SaaS"
                        onKeyDown={event => handleKeyPress(event, 1)}
                        onClick={event => handleClick(event, 1)}
                      >
                        Subscription Based Software
                      </MenuItem>
                    </Select>
                    <div style={{ height: '10px' }}>
                      {touched.revenueModel && errors.revenueModel && <FormHelperText error> {errors.revenueModel} </FormHelperText>}
                    </div>
                    {currentIndexNo === 1 && (
                      <div className={cssClasses.okButtonContainer}>
                        <button
                          id="okButton1"
                          onClick={() => onClickOkButton('revenueModel')}
                          className={values.revenueModel ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                          type="button"
                        >
                          Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                        </button>
                        <p className={cssClasses.textPress}>
                          press <span className={cssClasses.textEnter}>Enter</span>
                        </p>
                      </div>
                    )}
                  </div>
                )}
                {currentIndexNo === 1 && !(values.revenueModel === 'Pro Services') && (
                  <button
                    type="button"
                    className={currentIndexNo === 1 ? cssClasses.formNextButton : cssClasses.disabledFormNextButton}
                    onClick={onClickRevenueModelNextButton}
                  >
                    Next
                  </button>
                )}
                {values.revenueModel === 'Pro Services' && (
                  <div>
                    {currentIndexNo === 2 && (
                      <div className={getClassByIndex(2)}>
                        <label
                          htmlFor="revenueComputationType"
                          className={currentIndexNo === 2 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}
                        >
                          2. We collect revenue{' '}
                        </label>
                        {(currentIndexNo === 2 || currentIndexNo === 3) && (
                          <Select
                            sx={{
                              '& .MuiSelect-outlined': {
                                background: '#fff',
                                padding: '3px 14px'
                              }
                            }}
                            displayEmpty
                            disableUnderline
                            variant="standard"
                            inputRef={ref => {
                              revenueFieldRef[1].current = ref
                            }}
                            className={currentIndexNo === 2 ? cssClasses.selectBox : cssClasses.nonFocusedSelectBox}
                            placeholder="Select"
                            id="revenueComputationType"
                            disabled={isDemoCompany}
                            value={values.revenueComputationType}
                            name="revenueComputationType"
                            onBlur={handleBlur}
                            onChange={event => {
                              onClickCollectRevenueButton(event)
                              // onClickOkButton(event.target.name)
                            }}
                          >
                            <MenuItem disabled hidden value="">
                              Select
                            </MenuItem>
                            <MenuItem
                              className={classes.selectOption}
                              value="Top Line Billable"
                              onKeyDown={event => handleKeyPress(event, 2)}
                              onClick={event => handleClick(event, 2)}
                            >
                              By client
                            </MenuItem>
                            <MenuItem disabled className={classes.selectOption} value="by_product">
                              By product (Coming Soon)
                            </MenuItem>
                            <MenuItem
                              className={classes.selectOption}
                              value="Detailed Billable + Products"
                              onKeyDown={event => handleKeyPress(event, 2)}
                              onClick={event => handleClick(event, 2)}
                            >
                              By client & product
                            </MenuItem>
                          </Select>
                        )}
                        <div style={{ height: '10px' }}>
                          {touched.revenueComputationType && errors.revenueComputationType && (
                            <FormHelperText error> {errors.revenueComputationType} </FormHelperText>
                          )}
                        </div>
                        {currentIndexNo === 2 && (
                          <div className={cssClasses.okButtonContainer}>
                            <button
                              id="okButton2"
                              onClick={onClickOkButton}
                              className={values.revenueComputationType ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                              type="button"
                            >
                              Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                            </button>
                            <p className={cssClasses.textPress}>
                              press <span className={cssClasses.textEnter}>Enter</span>
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                    {currentIndexNo <= 2 && values.revenueModel === 'Pro Services' && (
                      <button
                        type="button"
                        className={currentIndexNo === 2 ? cssClasses.formNextButton : cssClasses.disabledFormNextButton}
                      >
                        Next
                      </button>
                    )}
                  </div>
                )}
              </form>
            )}
          </Formik>
          {revenueModelItem === 'SaaS' && <Saas currentIndexNo={currentIndexNo} setCurrentIndex={setCurrentIndex} />}
          {revenueModelItem === 'Commerce / Retail' && (
            <CommerceRetail
              currentIndexNo={currentIndexNo}
              setCurrentIndex={setCurrentIndex}
              revenueModelItem={revenueModelItem}
              revenueComputationTypeItem={revenueComputationTypeItem}
            />
          )}
          {revenueComputationTypeItem === 'Detailed Billable + Products' && (
            <DetailBillableAndProduct
              currentIndexNo={currentIndexNo}
              setCurrentIndex={setCurrentIndex}
              revenueModelItem={revenueModelItem}
              revenueComputationTypeItem={revenueComputationTypeItem}
            />
          )}
          {revenueComputationTypeItem === 'Top Line Billable' && (
            <TopLineBillable
              currentIndexNo={currentIndexNo}
              setCurrentIndex={setCurrentIndex}
              revenueModelItem={revenueModelItem}
              revenueComputationTypeItem={revenueComputationTypeItem}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default RevenuePage
