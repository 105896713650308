import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// material-ui
import { makeStyles, useTheme } from '@material-ui/styles'
import {
  Avatar,
  CardContent,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  List,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography
} from '@material-ui/core'
import ListItemButton from '@material-ui/core/ListItemButton'

// project imports
import MainCard from 'ui-component/cards/MainCard'
import Transitions from 'ui-component/extended/Transitions'

// assets
import { IconLogout, IconSettings, IconUserCheck } from '@tabler/icons'
import User1 from 'assets/images/users/avatar-placeholder.png'

import { authSelector, clearState } from 'store/auth/auth.slice'
import { resetState as resetOrganizationSlice } from 'store/organisation/organisation.slice'
import { resetState as resetModelsSlice } from 'store/models/models.slice'
import { companySelector, hardResetState as resetCompanySlice } from 'store/company/company.slice'
import { uiSelector } from 'store/ui/ui.slice'

import { signOut } from 'store/auth/auth.actions'
import { MENU_OPEN, SNACKBAR_OPEN } from 'store/actions'
import Customization from 'layout/Customization'

import moment from 'moment'

// style const
const useStyles = makeStyles(theme => ({
  navContainer: {
    width: '100%',
    maxWidth: '350px',
    minWidth: '300px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%'
    }
  },
  headerAvatar: {
    cursor: 'pointer',
    ...theme.typography.mediumAvatar,
    margin: '8px 0 8px 8px !important',
    backgroundColor: theme.palette.background.paper,
    border: `solid 1px ${theme.palette.primary.light}`
  },
  profileChip: {
    height: '48px',
    alignItems: 'center',
    borderRadius: '27px',
    transition: 'all .2s ease-in-out',
    border: 'none'
    // backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
    // '&[aria-controls="menu-list-grow"], &:hover': {
    //   borderColor: theme.palette.primary.main,
    //   background: `${theme.palette.secondary.dark} !important`,
    //   color: theme.palette.primary.light,
    //   '& svg': {
    //     stroke: theme.palette.primary.light
    //   }
    // }
  },
  profileLabel: {
    lineHeight: 0,
    padding: '12px'
  },
  listItem: {
    marginTop: '5px'
  },
  cardContent: {
    padding: '16px !important'
  },
  card: {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark[800] : theme.palette.primary.light,
    marginBottom: '16px',
    marginTop: '16px'
  },
  searchControl: {
    width: '100%',
    paddingRight: '8px',
    paddingLeft: '16px',
    marginBottom: '16px',
    marginTop: '16px'
  },
  startAdornment: {
    fontSize: '1rem',
    color: theme.palette.grey[500]
  },
  flex: {
    display: 'flex'
  },
  name: {
    marginLeft: '2px',
    fontWeight: 400
  },
  ScrollHeight: {
    height: '100%',
    maxHeight: 'calc(100vh - 250px)',
    overflowX: 'hidden'
  },
  badgeWarning: {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.warning.dark,
    color: '#fff'
  }
}))

// ===========================|| PROFILE MENU ||=========================== //

const ProfileSection = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const theme = useTheme()

  const customization = useSelector(state => state.customization)

  const [selectedIndex] = React.useState(1)
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const { isEarlyBird, preSelectedPlan, creationDate, userTier, selectedCompany, companyCreationDate, fremiumStartDate } =
    useSelector(companySelector)
  const { paymentSetupModelVisible } = useSelector(uiSelector)

  const dayAfMonth = moment(creationDate).add(14, 'days').calendar()

  let dateDiff = moment(dayAfMonth).diff(moment(), 'days')

  dateDiff = +dateDiff

  const dateIsValid = dateDiff >= 1

  const [isFreemium, setIsFreemium] = useState(false)
  const [fremiumDaysLeft, setFremiumDaysleft] = useState(0)

  useEffect(() => {
    setIsFreemium(false)

    if (fremiumStartDate) {
      const currentDate = new Date()
      const creationDateObj = new Date(fremiumStartDate)
      const timeDiff = Math.abs(creationDateObj.getTime() - currentDate.getTime())
      const daysDifference = Math.ceil(timeDiff / (1000 * 60 * 60 * 24))
      const isWithinThreshold = daysDifference < 60
      if (userTier === 'starter' && isWithinThreshold) {
        setFremiumDaysleft(60 - daysDifference + 1)
        setIsFreemium(true)
      }
    }
  }, [fremiumStartDate, selectedCompany, userTier])

  const handleLogout = async () => {
    // dispatch(refreshTokens())
    dispatch(signOut())
    // dispatch(resetState())
    dispatch(resetCompanySlice())
    dispatch(resetModelsSlice())
    dispatch(resetOrganizationSlice())
    localStorage.removeItem('selectedTab')
    localStorage.removeItem('selectedCompanyTab')
  }

  const handleToggle = () => setOpen(prevOpen => !prevOpen)
  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  const setGreetings = () => {
    const hours = new Date().getHours()
    if (hours < 12) {
      return 'Good morning'
    }
    if (hours < 18) {
      return 'Good Afternoon'
    }
    return 'Good evening'
  }

  const { user, logoutError, isGuestUser } = useSelector(authSelector)
  const { avatarFile } = user

  useEffect(() => {
    if (logoutError !== '') {
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: logoutError,
        variant: 'alert',
        alertSeverity: 'error'
      })
    }

    return dispatch(clearState())
  }, [dispatch, logoutError])
  return (
    <div style={{ width: '100%' }}>
      <Chip
        classes={{ label: classes.profileLabel }}
        className={classes.profileChip}
        // icon={
        //   <Avatar
        //     src={avatarFile || User1}
        //     className={classes.headerAvatar}
        //     ref={anchorRef}
        //     aria-controls={open ? 'menu-list-grow' : undefined}
        //     aria-haspopup="true"
        //     color="inherit"
        //   />
        // }
        label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        style={{ zIndex: 300 }}
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions position="top-right" in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                  <CardContent className={classes.cardContent}>
                    <Grid container direction="column" spacing={0}>
                      <Grid item className={classes.flex}>
                        <Typography variant="h4">{setGreetings()}, </Typography>
                        <Typography component="span" variant="h4" className={classes.name}>
                          {user.name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle2">Project Admin</Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                    {!isGuestUser && (
                      <List component="nav" className={classes.navContainer}>
                        <>
                          <ListItemButton
                            className={classes.listItem}
                            sx={{ borderRadius: `${customization.borderRadius}px` }}
                            selected={selectedIndex === 2}
                            component={Link}
                            to="/profile"
                            onClick={() => {
                              setOpen(false)
                              dispatch({ type: MENU_OPEN, id: '' })
                            }}
                          >
                            <ListItemIcon>
                              <IconUserCheck stroke={1.5} size="1.3rem" />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="body2">User Profile</Typography>} />
                          </ListItemButton>
                          <Customization />
                        </>

                        <ListItemButton
                          className={classes.listItem}
                          sx={{ borderRadius: `${customization.borderRadius}px` }}
                          selected={selectedIndex === 4}
                          onClick={handleLogout}
                        >
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                        </ListItemButton>
                      </List>
                    )}
                  </CardContent>
                  {dateIsValid && isEarlyBird && preSelectedPlan === 'starter' && !paymentSetupModelVisible && (
                    <>
                      <Divider />
                      <List>
                        <ListItemButton className={classes.listItem} sx={{ borderRadius: `${customization.borderRadius}px` }}>
                          <Typography variant="body2">Downgrade in {dateDiff} days</Typography>
                        </ListItemButton>
                      </List>
                    </>
                  )}
                  {isFreemium && (
                    <>
                      <Divider />
                      <List>
                        <ListItemButton className={classes.listItem} sx={{ borderRadius: `${customization.borderRadius}px` }}>
                          <Typography variant="body2">You have {fremiumDaysLeft} days left in your freemium</Typography>
                        </ListItemButton>
                      </List>
                    </>
                  )}
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </div>
  )
}

export default ProfileSection
