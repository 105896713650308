import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import {
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
  Backdrop,
  CircularProgress,
  InputAdornment,
  TextField,
  Button
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { makeStyles } from '@material-ui/styles'
import IndustriesList from 'data/IndustriesList'
import { useNavigate } from 'react-router'
import {
  uiSelector,
  setRegistrationSetupModelScreen,
  updateModalTabVisibility,
  toggleCompanySetupModal,
  setSelectedMenuItem
} from 'store/ui/ui.slice'
import { getCompanyByID, createCompany, updateCompany, getCompaniesByUser, createCompanyFromExcel } from 'store/company/company.actions'
import { clearState, companySelector, resetState, setCurrentlyActiveCompanyId, setXlUplodeDatas } from 'store/company/company.slice'
import { SNACKBAR_OPEN } from 'store/actions'
import { quickbookSelector, resetQuickBookStatus } from 'store/quickBook/quickbook.slice'
import { authSelector, updateSelectedCompany } from 'store/auth/auth.slice'
import cssClasses from 'ui-component/company-setup-modal/infoTab.module.css'

import companyInfoTabImage from 'assets/images/company_info_tab.png'
import downArrowButton from 'assets/images/Vector 132.png'
import upArrowButton from 'assets/images/up_arrow.png'
import mrgnLogo from 'assets/images/mrgin-blue-logo.png'

import DoneIcon from '@material-ui/icons/Done'

const useStyles = makeStyles(theme => ({
  selectOption: {
    color: '#000',
    fontFamily: 'Helvetica',
    fontSize: '16px',
    // marginTop: '10px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '125%',
    padding: '12px 16px',
    '&:hover': {
      color: '#29A364',
      background: '#F9F6FF'
    }
    // '&.Mui-selected': { backgroundColor: '#F9F6FF' }
  }
}))

const CompanyPage = props => {
  const classes = useStyles()
  const { isDemoCompany } = props
  const dispatch = useDispatch()
  const formRef = useRef(null)
  const inputFieldRef = [useRef(null), useRef(null)]
  const { registrationSetupModelScreen } = useSelector(uiSelector)
  const [isFinish, setIsFinish] = useState(false)
  const { createdCompanyId } = useSelector(quickbookSelector)
  const { user } = useSelector(authSelector)
  const [loader, setLoader] = useState(false)
  const [hoverStyles, setHoverStyle] = useState({ display: 'none' })
  const [isIntegrationButtonClicked, setIntegrationButtonClicked] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const {
    incompleteCompanyId,
    isSuccessCompany,
    isSuccess,
    createCompanyError,
    id,
    name,
    email,
    industry,
    bankBalance,
    taxYearEnd,
    revenueModel,
    revenueComputationType,
    selectedCompany,
    currentModelScreen,
    companySetupModalDismissed,
    companies,
    xlUplodeDatas,
    currentlyActiveCompanyId
  } = useSelector(companySelector)

  const [isCurrentIndex, setCurrentIndex] = useState(1)
  const [isIndustrySelected, setIndustrySelected] = useState(false)

  const initialValues = {
    name,
    // email,
    industry,
    // bankBalance,
    // taxYearEnd: getTaxYearEnd(),
    submit: null
  }
  useEffect(() => {
    inputFieldRef[isCurrentIndex - 1]?.current?.focus()
  }, [isCurrentIndex])

  const onClickOkButton = fieldName => {
    formRef.current.setFieldTouched(fieldName, true)
    if (inputFieldRef[isCurrentIndex - 1]?.current.value !== '' && !formRef.current.errors[fieldName]) {
      if (isCurrentIndex === 2) {
        if (formRef.current) {
          formRef.current?.handleSubmit()
        }
      } else {
        setCurrentIndex(prevState => prevState + 1)
      }
    } else {
      inputFieldRef[isCurrentIndex - 1]?.current?.focus()
    }
  }

  const onClickNextButton = () => {
    // if (inputFieldRef[isCurrentIndex - 1]?.current.value !== '') {
    //   if (isCurrentIndex === 2) {
    if (formRef.current) {
      formRef.current?.handleSubmit()
    }
    //   } else {
    //     setCurrentIndex(prevState => prevState + 1)
    //   }
    // } else {
    //   inputFieldRef[isCurrentIndex - 1]?.current?.focus()
    // }
  }

  const getFocusOnNextBtn = () => {
    document.getElementById('nxtBtn').focus()
  }

  useEffect(() => {
    if (isCurrentIndex === 2) getFocusOnNextBtn()
  }, [isIndustrySelected])

  const handleClick = (event, index) => {
    if (event.type === 'click') {
      setIndustrySelected(prev => !prev)
    }
  }
  const handleKeyPress = (event, index) => {
    if (event.key === 'Enter' && index === 3) {
      // document.getElementById('nxtBtn').focus()
      setIndustrySelected(prev => !prev)
      // getFocusOnNextBtn()
    }
    if (event.charCode === 13) {
      event.preventDefault()
      onClickOkButton(event.target.name)
    }
  }

  // const checkCurrentFieldFilled = () => inputFieldRef[isCurrentIndex - 1]?.current?.value === ''

  useEffect(() => {
    if (IndustriesList.indexOf(industry) === -1 && industry !== '') {
      IndustriesList.unshift(industry)
    }
  }, [industry])

  useEffect(() => {
    if (incompleteCompanyId !== '' || id !== '') {
      dispatch(updateSelectedCompany({ companyId: id || incompleteCompanyId }))
    }
  }, [dispatch, id, incompleteCompanyId])

  useEffect(() => {
    if (isSuccessCompany) {
      dispatch(resetQuickBookStatus())
    }
  }, [dispatch, isSuccessCompany])

  useEffect(() => {
    if (createdCompanyId !== '') {
      const data = {
        companyId: createdCompanyId,
        email: user.email
      }
      dispatch(resetQuickBookStatus())
      dispatch(getCompanyByID(data))
    } else if (incompleteCompanyId !== '' || id !== '') {
      if (companies.length === 0) {
        const data = {
          companyId: incompleteCompanyId,
          email: user.email
        }
        dispatch(getCompanyByID(data))
      } else {
        const data = {
          companyId: id,
          email: user.email
        }
        dispatch(getCompanyByID(data))
      }
    }
  }, [companies.length, createdCompanyId, dispatch, id, incompleteCompanyId])

  useEffect(() => {
    if (inputFieldRef[0].current) {
      inputFieldRef[0]?.current?.focus()
    }
  }, [])

  const handleInputChange = event => {
    const tabKeyEvent = new KeyboardEvent('keydown', {
      key: 'Tab',
      keyCode: 9,
      code: 'Tab',
      which: 9,
      shiftKey: event.shiftKey,
      altKey: event.altKey,
      ctrlKey: event.ctrlKey,
      metaKey: event.metaKey,
      bubbles: true,
      cancelable: true
    })

    // Dispatch the Tab key press event
    // inputFieldRef[1]?.current?.dispatchEvent(tabKeyEvent)
  }
  const getClassByIndex = fieldIndex => {
    const isPreviousIndex = isCurrentIndex - 1 === fieldIndex
    const isNextIndex = isCurrentIndex + 1 === fieldIndex

    switch (true) {
      case isPreviousIndex:
        return cssClasses.nonFocusedBoxTop
      case isNextIndex:
        return cssClasses.nonFocusedBoxBottom
      default:
        return cssClasses.box
    }
  }

  return (
    <Grid container>
      <Grid
        // pt={3}
        item
        // xs={5}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'start',
          minHeight: '100vh',
          backgroundColor: '#FAF8F3',
          width: '40%'
        }}
      >
        <Grid
          item
          xs={9}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            marginTop: '79px',
            width: '100%'
          }}
        >
          <img src={mrgnLogo} alt="MRGN_logo" style={{ marginBottom: '80px' }} />
          <Typography className={cssClasses.stepText}>Step 1 of 4</Typography>
          <Typography className={cssClasses.tabName1}>Company</Typography>
          <Typography className={cssClasses.tabDescription}>
            Let's get started with the basics. Can you tell us some information about your business?
          </Typography>
          <img src={companyInfoTabImage} alt="company_info_image" style={{ height: 'auto', marginTop: '70px', width: '100%' }} />
          {/* <Typography className={cssClasses.information}>The information shown is for illustrative purposes only.</Typography> */}
        </Grid>
      </Grid>
      {/* //rightside */}
      <Grid
        item
        // xs={7}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          flexDirection: 'column',
          width: '60%'
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '320px',
            minHeight: '85vh',
            maxHeight: '85vh'
            // marginTop: '79px'
          }}
        >
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Company name is required'),
              industry: Yup.string().required('Industry is required')
              // bankBalance: Yup.string()
              //   .required('Bank balance is required')
              //   .matches(/^\d+(\.\d+)?$/, 'Enter a valid Amount')
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
              if (isDemoCompany) {
                dispatch(setRegistrationSetupModelScreen(registrationSetupModelScreen + 1))
                return
              }
              try {
                const logArray = []

                if (name !== values.name) {
                  const companyChangeLog = {
                    user: user.id,
                    userName: user.name,
                    company: selectedCompany,
                    date: new Date(),
                    tabChanged: 'Company/ Edit Company Details',
                    department: 'N/A',
                    lineItemChanged: `${name} (Company Name Changed)`,
                    originalValue: name,
                    newValue: values.name
                  }
                  logArray.push(companyChangeLog)
                }
                if (industry !== values.industry) {
                  const companyChangeLog = {
                    user: user.id,
                    userName: user.name,
                    company: selectedCompany,
                    date: new Date(),
                    tabChanged: 'Company/ Edit Company Details',
                    department: 'N/A',
                    lineItemChanged: `${name} (Company Industry Changed)`,
                    originalValue: industry,
                    newValue: values.industry
                  }
                  logArray.push(companyChangeLog)
                }

                if (parseInt(bankBalance, 10) !== parseInt(values.bankBalance, 10)) {
                  const companyChangeLog = {
                    user: user.id,
                    userName: user.name,
                    company: selectedCompany,
                    date: new Date(),
                    tabChanged: 'Company/ Edit Company Details',
                    department: 'N/A',
                    lineItemChanged: `${name} (Company Bank Balance Changed)`,
                    originalValue: bankBalance,
                    newValue: values.bankBalance
                  }
                  logArray.push(companyChangeLog)
                }
                const data = {
                  name: values.name,
                  industry: values.industry,
                  // bankBalance: values.bankBalance.toString().includes(',')
                  //   ? parseInt(values.bankBalance.replace(/,/g, ''), 10)
                  //   : values.bankBalance,
                  bankBalance: 0,
                  projectionMonths: 36,
                  systemLog: logArray,
                  isNewRegisterCompany: true
                }

                if (id === '') {
                  dispatch(createCompany(data))
                } else {
                  data.id = id
                  dispatch(updateCompany(data))
                }

                dispatch(setRegistrationSetupModelScreen(registrationSetupModelScreen + 1))

                setStatus({ success: true })
                setSubmitting(false)
              } catch (err) {
                setStatus({ success: false })
                setErrors({ submit: err.message })
                setSubmitting(false)
              }
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, isValid, setTouched }) => (
              <>
                {/* <form noValidate onSubmit={handleSubmit} style={{ position: 'absolute', bottom: 250 }}> */}
                <form noValidate onSubmit={handleSubmit} style={{ height: '100%', position: 'relative' }}>
                  <Typography className={cssClasses.tabRightHeading}>Let&apos;s get down to business!</Typography>
                  <div className={isCurrentIndex === 1 ? cssClasses.box : cssClasses.nonFocusedBoxTop}>
                    <label
                      htmlFor="company_name"
                      className={!(isCurrentIndex === 1) ? cssClasses.noneFocusedLabel : cssClasses.focusedLabel}
                    >
                      1. My company's name is{' '}
                    </label>

                    <input
                      disabled={isDemoCompany}
                      placeholder="Type here..."
                      className={isCurrentIndex === 1 ? cssClasses.focusedInputBox : cssClasses.nonFocusedInputBox}
                      value={values.name}
                      name="name"
                      onBlur={handleBlur}
                      onKeyPress={event => handleKeyPress(event, 1)}
                      onChange={handleChange}
                      id="company_name"
                      ref={ref => {
                        inputFieldRef[0].current = ref
                      }}
                    />

                    <div style={{ height: '10px' }}>
                      {touched.name && errors.name && <FormHelperText error> {errors.name} </FormHelperText>}
                    </div>

                    {isCurrentIndex === 1 && (
                      <div className={cssClasses.okButtonContainer}>
                        <button
                          onClick={() => onClickOkButton('name')}
                          className={values.name ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                          type="button"
                        >
                          Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                        </button>
                        <p className={cssClasses.textPress}>
                          press <span className={cssClasses.textEnter}>Enter</span>
                        </p>
                      </div>
                    )}
                  </div>
                  {isCurrentIndex === 2 && (
                    <div className={isCurrentIndex === 2 ? cssClasses.box : cssClasses.nonFocusedBoxBottom}>
                      <label
                        htmlFor="industry-name"
                        className={!(isCurrentIndex === 2) ? cssClasses.noneFocusedLabel : cssClasses.focusedLabel}
                      >
                        2. Our industry is{' '}
                      </label>
                      {isCurrentIndex === 2 && (
                        <Select
                          sx={{
                            '& .MuiSelect-outlined': {
                              background: '#fff',
                              padding: '3px 14px'
                            }
                          }}
                          displayEmpty
                          inputRef={ref => {
                            inputFieldRef[1].current = ref
                          }}
                          className={cssClasses.selectBox}
                          placeholder="Select"
                          id="industry-name"
                          disabled={isDemoCompany}
                          value={values.industry}
                          name="industry"
                          onBlur={handleBlur}
                          onChange={e => {
                            handleChange(e)
                          }}
                        >
                          <MenuItem disabled className={classes.selectOption} value="" style={{ display: 'none' }}>
                            Select
                          </MenuItem>
                          {IndustriesList.map((industry, i) => (
                            <MenuItem
                              className={classes.selectOption}
                              value={industry}
                              key={`industryOption-${i}`}
                              classes={classes}
                              onKeyDown={event => handleKeyPress(event, 3)}
                              onClick={event => handleClick(event, 3)}
                            >
                              {industry}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                      <div style={{ height: '10px' }}>
                        {touched.industry && errors.industry && <FormHelperText error> {errors.industry} </FormHelperText>}{' '}
                      </div>
                      {isCurrentIndex === 2 && <div style={{ height: '50px', marginTop: '10px' }} />}

                      {/* {isCurrentIndex === 2 && (
                      <div className={cssClasses.okButtonContainer}>
                        <button
                          onClick={onClickOkButton}
                          className={values.industry ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                          type="button"
                        >
                          Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                        </button>
                        <p className={cssClasses.textPress}>
                          press <span className={cssClasses.textEnter}>Enter</span>
                        </p>
                      </div>
                    )} */}
                    </div>
                  )}
                  {/* {isCurrentIndex === 3 && (
                  <div className={isCurrentIndex === 3 ? cssClasses.box : cssClasses.nonFocusedBox}>
                    <label
                      htmlFor="bank_balance"
                      className={!(isCurrentIndex === 3) ? cssClasses.noneFocusedLabel : cssClasses.focusedLabel}
                    >
                      Bank Balance{' '}
                    </label>
                    <div className={isCurrentIndex === 3 ? cssClasses.focusedInputDiv : cssClasses.nonFocusedInputDiv}>
                      {' '}
                      <span className={isCurrentIndex === 3 ? cssClasses.focusedInputStaticText : cssClasses.nonFocusedInputStaticText}>
                        $
                      </span>
                      <input
                        ref={inputFieldRef}
                        onKeyPress={onClickEnterKey}
                        // onKeyPress={event => handleKeyPress(event, 3)}
                        style={{ border: 'none', backgroundColor: '#ffffff', marginTop: 0, paddingLeft: '15px' }}
                        placeholder="Type here..."
                        type="text"
                        className={isCurrentIndex === 3 ? cssClasses.focusedInputBox : cssClasses.nonFocusedInputBox}
                        value={values.bankBalance.toLocaleString()}
                        name="bankBalance"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        id="bank_balance"
                      />
                    </div>

                    {touched.bankBalance && errors.bankBalance && <FormHelperText error> {errors.bankBalance} </FormHelperText>}
                  </div>
                )} */}
                  <button
                    type="button"
                    id="nxtBtn"
                    onClick={onClickNextButton}
                    className={isCurrentIndex === 2 && isValid ? cssClasses.formNextButton : cssClasses.disabledFormNextButton}
                  >
                    Next
                  </button>
                </form>
              </>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CompanyPage
