import React, { lazy } from 'react'
import Loadable from 'ui-component/Loadable'
import MinimalLayout from 'layout/MinimalLayout'
import NavMotion from 'layout/NavMotion'
import GuestGuard from 'utils/route-guard/GuestGuard'
import AuthGuard from 'utils/route-guard/AuthGuard'

const CommonLoader = Loadable(lazy(() => import('views/pages/authentication/commonLoader')))

const GuestRoutes = {
  path: 'loader',
  element: <MinimalLayout />,
  children: [
    {
      path: '/',
      element: (
        <NavMotion>
          <AuthGuard>
            <CommonLoader />
          </AuthGuard>
        </NavMotion>
      )
    }
  ]
}

export default GuestRoutes
