import { createAsyncThunk } from '@reduxjs/toolkit'
import { SERVER_URL } from 'utils/API'
import { axiosInstance } from 'utils/API/AxiosConfig'
import cookies from 'utils/cookies'

const getUserByToken = createAsyncThunk('auth/getUserByToken', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/auth/getUserByToken`,
      { ...payload },
      {
        handlerEnabled: false
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message)
    }

    return thunkAPI.rejectWithValue(error.message)
  }
})

const getUserDepartment = createAsyncThunk('auth/getUserDepartment', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/auth/getUserDepartment`,
      { ...payload },
      {
        handlerEnabled: false
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message)
    }

    return thunkAPI.rejectWithValue(error.message)
  }
})

const googleSignIn = createAsyncThunk('auth/google-signin', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/auth/google-signin`,
      { ...payload },
      {
        handlerEnabled: false
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message)
    }

    return thunkAPI.rejectWithValue(error.message)
  }
})

const jwtSignIn = createAsyncThunk('auth/login', async ({ email, password }, thunkAPI) => {
  try {
    const data = {
      email,
      password
    }

    const response = await axiosInstance.post(`${SERVER_URL}/auth/login`, data, {
      handlerEnabled: false
    })

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message)
    }

    return thunkAPI.rejectWithValue(error.message)
  }
})

const guestUserLogin = createAsyncThunk('auth/guestLogin', async thunkAPI => {
  try {
    const response = await axiosInstance.post(`${SERVER_URL}/auth/guestLogin`, {
      handlerEnabled: false
    })

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message)
    }

    return thunkAPI.rejectWithValue(error.message)
  }
})

const googleSignUp = createAsyncThunk('auth/google-signup', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/auth/google-signup`,
      { ...payload },
      {
        handlerEnabled: false
      }
    )

    if (response.status === 201 || response.status === 200) {
      console.log(response.data, 'from redux')
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message)
    }

    return thunkAPI.rejectWithValue(error.message)
  }
})

const signUp = createAsyncThunk('auth/register', async ({ name, email, password, isEmailVerified, inviteId, product }, thunkAPI) => {
  try {
    const data = {
      name,
      email,
      password,
      isEmailVerified,
      inviteId,
      product
    }

    const response = await axiosInstance.post(`${SERVER_URL}/auth/register`, data, {
      handlerEnabled: false
    })

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    if (e.response) {
      return thunkAPI.rejectWithValue(e.response.data.message)
    }

    return thunkAPI.rejectWithValue(e.message)
  }
})

const signOut = createAsyncThunk('auth/logout', async (payload, thunkAPI) => {
  try {
    const data = {
      refreshToken: cookies.get('refresh_token')
    }

    const response = await axiosInstance.post(`${SERVER_URL}/auth/logout`, data, {
      handlerEnabled: false
    })

    if (response.status === 204) {
      // window.location.replace('http://mrgn.ai')

      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    if (e.response) {
      return thunkAPI.rejectWithValue(e.response.data.message)
    }

    return thunkAPI.rejectWithValue(e.message)
  }
})

const refreshTokens = createAsyncThunk('auth/refresh_tokens', async (payload, thunkAPI) => {
  try {
    const data = {
      refreshToken: cookies.get('refresh_token')
    }

    const response = await axiosInstance.post(`${SERVER_URL}/auth/refresh-tokens`, data, {
      handlerEnabled: false
    })

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    if (e.response) {
      return thunkAPI.rejectWithValue(e.response.data.message)
    }

    return thunkAPI.rejectWithValue(e.message)
  }
})

const createUser = createAsyncThunk('auth/user/create', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/admin/addUserToOrganisation`,
      { ...payload },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    if (e.response) {
      return thunkAPI.rejectWithValue(e.response.data.message)
    }

    return thunkAPI.rejectWithValue(e.message)
  }
})

const updateUser = createAsyncThunk('auth/user/update', async (payload, thunkAPI) => {
  try {
    const { userId } = payload
    const params = {
      params: { userId },
      handlerEnabled: true
    }
    const response = await axiosInstance.patch(`${SERVER_URL}/users/${userId}`, { ...payload }, params)

    if (response.status === 201 || response.status === 200) {
      // window.location.reload()
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const getUserInvites = createAsyncThunk('auth/invites/get', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/admin/getUserInvitesByAdmin`,
      { ...payload },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const getInviteById = createAsyncThunk('auth/getInviteById', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/auth/get-details-by-invitation`,
      { ...payload },
      {
        handlerEnabled: false
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const resetPassword = createAsyncThunk('auth/resetPassword', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/auth/reset-password`,
      { ...payload },
      {
        handlerEnabled: false
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.data)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data.message)
  }
})

const changePassword = createAsyncThunk('auth/changePassword', async (payload, thunkAPI) => {
  const params = {
    handlerEnabled: true
  }

  try {
    const response = await axiosInstance.post(`${SERVER_URL}/auth/change-password`, { ...payload }, params)

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.data)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data.message)
  }
})

const resetWithToken = createAsyncThunk('auth/resetWithToken', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/auth/resetWithToken`,
      { ...payload },
      {
        handlerEnabled: false
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.data.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data.message)
  }
})

const uploadAvatar = createAsyncThunk('auth/uploadAvatar', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/auth/upload-avatar`,
      { ...payload },
      {
        handlerEnabled: false
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.data.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data.message)
  }
})

const getPlatformOnobardingFormStatus = createAsyncThunk('utils/getPlatformOnboardStatus', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.get(`${SERVER_URL}/utils/platform_onboarding_form_status`, { params: { ...payload } })

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const postPlatformOnobardingFormSuccess = createAsyncThunk('utils/platformOnboardFormSubmit', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(`${SERVER_URL}/utils/platform_onboarding_form_success`, { ...payload })

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.data.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data.message)
  }
})

export {
  jwtSignIn,
  signUp,
  signOut,
  refreshTokens,
  createUser,
  updateUser,
  getUserInvites,
  getInviteById,
  googleSignIn,
  googleSignUp,
  getUserByToken,
  resetPassword,
  changePassword,
  resetWithToken,
  uploadAvatar,
  getUserDepartment,
  guestUserLogin,
  getPlatformOnobardingFormStatus,
  postPlatformOnobardingFormSuccess
}
