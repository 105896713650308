import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes, { object } from 'prop-types'

import {
  Select,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  CardHeader,
  Divider,
  IconButton,
  Drawer,
  FormHelperText
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import moment from 'moment'

import excel from 'assets/images/excel.png'
import {
  setCompanySetupModalScreen,
  setExcelImportTabModel,
  toggleCompanySetupModal,
  toggleExcelImportTabModel,
  uiSelector,
  setSelectedMenuItem
} from 'store/ui/ui.slice'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import EditIcon from '@material-ui/icons/Edit'

import { finishCompanyCreate, updateCompany } from 'store/company/company.actions'
import EmployeesTabView from './EmployeesTabView'
import ContractorsTabView from './ContractorsTabView'
import { companySelector, clearState, setCurrentlyActiveCompanyId } from 'store/company/company.slice'
import { authSelector } from 'store/auth/auth.slice'

import { numberFormat } from 'utils/ui'
import { gridSpacing } from 'store/constant'

import dayjs from 'dayjs'
import excelWithA from 'assets/images/excelWithA.png'

import styles from '../../styles.module.css'
import cssClasses from './infoTab.module.css'

import ContractorsTabViewDrawer from './ContractorsTabViewDrawer'
import CloseIcon from '@material-ui/icons/Close'
import EmployeesTabViewDrawer from './EmployeesTabViewDrawer'
import * as Yup from 'yup'
import { Formik } from 'formik'
import EmployeeList from './DrawerListPages/EmployeeList'

const customStyles = {
  backdrop: {
    fill: 'var(--neutrals-cool-1000, #0C0D0F)',
    opacity: 0.25
  }
}

const useStyles = makeStyles(theme => ({
  mainContainer: {
    minWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100%'
  },
  cardContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  myTeamCardContainer: {
    display: 'flex',
    flexDirection: 'column '
  },
  excel: {
    width: '53px',
    height: '31px',
    objectFit: 'contain',
    // marginTop: -25,
    borderRadius: 30,
    cursor: 'pointer'
    // '&:hover': {
    //   background: '#c6e9f5'
    // }
  },
  hAndIconContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '10px',
    justifyContent: 'space-between',
    width: '100%'
  },
  dropdownStyle: {
    width: '100%',
    borderRadius: '8px !important',
    '& .MuiOutlinedInput-input': {
      borderRadius: '8px !important'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px !important'
    }
  }
}))

const MyTeamSideDrawer = forwardRef((props, ref) => {
  const {
    isDemoCompany,
    windowHeight,
    isTriggeredFromDashboard,
    isAddButtonClicked,
    setAddButtonClicked,
    setSelectedTeam,
    selectedTeam,
    setEmpEditButtonClicked,
    empEditButtonClicked,
    setEmpInitialValues,
    empInitialValues,
    teamMemberData,
    initialValues,
    setInitialValues,
    setContEditButtonClicked,
    contEditButtonClicked,
    setTeamMemberData
  } = props

  const { contractorList, employeeList, selectedCompany, id, isSuccess, isComplete, isSuccessCompanyCreate } = useSelector(companySelector)
  const { user } = useSelector(authSelector)
  const { companySetupModalScreen } = useSelector(uiSelector)
  const classes = useStyles()
  const dispatch = useDispatch()
  // const [selectedTeam, setSelectedTeam] = useState('')
  const [name, setName] = useState(null)

  const [editItemEmp, setEditItemEmp] = useState('')
  const [editItemCont, setEditItemCont] = useState('')
  const [isFinish, setIsFinish] = useState(false)
  const [skip, setskip] = useState(false)
  const [isNext, setNext] = useState(false)
  const [notify, setNotify] = useState(false)
  // const tempRef = useRef()

  // const [contEditButtonClicked, setContEditButtonClicked] = useState(false)
  // const [empEditButtonClicked, setEmpEditButtonClicked] = useState(false)
  const [selectedEmpCard, setSelectedEmpCard] = useState('')
  const [selectedContCard, setSelectedContCard] = useState('')
  const [mouseEnterOnCard, setMouseEnterOnCard] = useState('')
  const [isFinishSetup, setIsFinishSetup] = useState(false)
  const myTeamRef = useRef(null)

  const [addedEmployee, setAddedEmployee] = useState([])
  useImperativeHandle(ref, () => ({
    handleNext(flag) {
      // tempRef.current.empHandleNext(flag)
      // setIsFinishSetup(true)
      dispatch(setSelectedMenuItem(''))
      if (isSuccess) {
        const data = {
          companyId: id
        }
        dispatch(finishCompanyCreate(data))
      }
      dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
      dispatch(setCurrentlyActiveCompanyId(id))
    }
  }))

  // useEffect(() => {
  //   if (isSuccessCompanyCreate) {
  //     // dispatch(toggleCompanySetupModal())
  //     dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
  //   }
  // }, [dispatch, isSuccessCompanyCreate])

  // useEffect(() => {
  //   if (isFinishSetup) {
  //     if (isTriggeredFromDashboard) {
  //       dispatch(clearState())

  //     } else {
  //       dispatch(toggleCompanySetupModal())
  //     }
  //   }
  // }, [dispatch, id, isSuccess, isFinishSetup, isTriggeredFromDashboard])

  //
  const commonInitialValues = {
    _id: '',
    name: '',
    purpose: '',
    department: '',
    function: '',
    startDate: null,
    location: '',
    annualCompensation: '',
    submit: null,
    title: '',
    salary: '',
    employeeType: ''
  }
  const formikInitialValues = {
    _id: '',
    name: '',
    purpose: '',
    department: '',
    function: '',
    startDate: null,
    location: '',
    annualCompensation: '',
    submit: null
  }
  // const [initialValues, setInitialValues] = useState(formikInitialValues)

  const formikEmpInitialValues = {
    _id: '',
    name: '',
    department: '',
    title: '',
    startDate: null,
    location: '',
    salary: '',
    submit: null
  }
  // const [empInitialValues, setEmpInitialValues] = useState(formikEmpInitialValues)

  const handleChange = event => {
    setSelectedTeam(event.target.value)
  }
  const handleNameChange = event => {
    setInitialValues(prevValues => ({ ...prevValues, name: event.target.value }))

    // setEmpInitialValues(prevValues => ({
    //   ...prevValues,
    //   name: event.target.value
    // }))
    // setInitialValues(prevValues => ({
    //   ...prevValues,
    //   name: event.target.value
    // }))
  }
  // const handleBlur = event => {
  //   setSelectedTeam(event.target.value)
  // }

  // useEffect(() => {}, [selectedTeam])

  // useImperativeHandle(ref, () => ({
  //   handleNext(flag) {
  //     if (flag) setIsFinish(true)
  //     if (contractorList.length === 0 || (formRef?.current.values.name && contractorList.length > 0)) {
  //       if (contractorList.length === 0 && !formRef?.current.values.name) {
  //         setskip(true)
  //       } else if (formRef?.current.isValid) {
  //         setNotify(true)
  //         setNext(true)
  //       } else {
  //         dispatch(clearState())
  //         if (formRef?.current) formRef?.current.handleSubmit()
  //         setNext(true)
  //       }
  //     } else if (flag) {
  //       dispatch(clearState())
  //       const data = {
  //         companyId: id
  //       }
  //       dispatch(finishCompanyCreate(data))
  //     } else {
  //       setNext(false)
  //       dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
  //       dispatch(clearState())
  //     }
  //   }
  // }))

  const handleDeleteContractor = _id => {
    // if (editItemCont === _id) return
    // if (formRef.current.values._id) {
    //   setNotify(true)
    //   return
    // }
    const logArray = []
    const contractor = [...contractorList].filter(item => item._id === _id)

    const employeeChangeLog = {
      user: user.id,
      userName: user.name,
      company: selectedCompany,
      date: new Date(),
      tabChanged: 'Contractors',
      department: contractor[0].department,
      lineItemChanged: `${contractor[0].name}(Contractor deleted)`,
      originalValue: contractor[0].name,
      newValue: `N/A`
    }
    logArray.push(employeeChangeLog)
    const contractorPayload = [...contractorList].filter(item => item._id !== _id)
    if (id !== '') {
      const data = {
        id,
        contractorList: contractorPayload,
        currentModelScreen: companySetupModalScreen + 1,
        systemLog: logArray
      }
      dispatch(updateCompany(data))
    }
  }

  const editContractor = item => {
    setEditItemCont(item._id)
    const tempObject = { ...item }
    const d = new Date(tempObject.startDate)

    const contractorData = []
    contractorData.push(item)
    setAddedEmployee(contractorData)

    setInitialValues({
      ...item,
      startDate: dayjs(d).format('YYYY-MM-DD'),
      submit: null,
      employeeType: 'contractor'
    })
  }

  const handleDeleteEmployee = _id => {
    console.log('deletefunctionemp')
    console.log(_id, 'id')
    // if (formRef.current.values._id) {
    //   setNotify(true)
    //   return
    // }
    // if (editItemEmp === _id) return
    const logArray = []
    const employee = [...employeeList].filter(item => item._id === _id)

    const employeeChangeLog = {
      user: user.id,
      userName: user.name,
      company: selectedCompany,
      date: new Date(),
      tabChanged: 'Employee',
      department: employee[0].department,
      lineItemChanged: `${employee[0].name}(Employee deleted)`,
      originalValue: employee[0].name,
      newValue: `N/A`
    }
    logArray.push(employeeChangeLog)
    const employeePayload = [...employeeList].filter(item => item._id !== _id)
    if (id !== '') {
      const data = {
        id,
        employeeList: employeePayload,
        currentModelScreen: companySetupModalScreen + 1,
        systemLog: logArray
      }
      dispatch(updateCompany(data))
    }
  }

  const editEmployee = item => {
    setEditItemEmp(item._id)
    const tempObject = { ...item }
    const d = new Date(tempObject.startDate)

    const employeeData = []
    employeeData.push(item)
    setAddedEmployee(employeeData)

    setInitialValues({
      ...item,
      startDate: dayjs(d).format('YYYY-MM-DD'),
      submit: null,
      employeeType: 'employee'
    })
  }
  useEffect(() => {
    if (empEditButtonClicked) {
      editEmployee(teamMemberData)
    }
    if (contEditButtonClicked) {
      editContractor(teamMemberData)
    }
  }, [empEditButtonClicked, contEditButtonClicked])

  const setEmplOrContFrom = () => {
    switch (selectedTeam) {
      case 'employee':
        return (
          <EmployeesTabViewDrawer
            // empInitialValues={empInitialValues}
            initialValues={initialValues}
            setEmpInitialValues={setEmpInitialValues}
            formikEmpInitialValues={formikEmpInitialValues}
            isDemoCompany={isDemoCompany}
            windowHeight={windowHeight}
            editItemEmp={editItemEmp}
            setEditItemEmp={setEditItemEmp}
            setEmpEditButtonClicked={setEmpEditButtonClicked}
            empEditButtonClicked={empEditButtonClicked}
            addedEmployee={addedEmployee}
            setAddedEmployee={setAddedEmployee}
            myTeamRef={myTeamRef}
          />
        )
      case 'contractor':
        return (
          <ContractorsTabViewDrawer
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            isDemoCompany={isDemoCompany}
            windowHeight={windowHeight}
            formikInitialValues={formikInitialValues}
            editItemCont={editItemCont}
            setEditItemCont={setEditItemCont}
            setContEditButtonClicked={setContEditButtonClicked}
            contEditButtonClicked={contEditButtonClicked}
            addedEmployee={addedEmployee}
            setAddedEmployee={setAddedEmployee}
            // ref={tempRef}
            myTeamRef={myTeamRef}
          />
        )
      default:
        return null
    }
  }
  // useEffect(() => { }, [selectedTeam])
  const closeSideDrawer = () => {
    setAddButtonClicked(false)
    setEmpEditButtonClicked(false)
    setContEditButtonClicked(false)
    setInitialValues(commonInitialValues)
    setSelectedTeam('')
    setAddedEmployee([])
  }
  const myTeamInitialValues = {
    name: '',
    employeeType: ''
  }

  return (
    <Drawer
      BackdropProps={{ style: customStyles.backdrop }}
      style={{ zIndex: 1000000 }}
      anchor="right"
      open={isAddButtonClicked || empEditButtonClicked || contEditButtonClicked}
      onClose={() => {
        closeSideDrawer()
      }}
      sx={{
        '& .css-4qyyic-MuiGrid-root': {
          padding: '24px',
          width: '390px'
        }
      }}
    >
      <Grid p={5} width="500px" role="presentation" textAlign="start">
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton
            onClick={() => {
              closeSideDrawer()
            }}
          >
            <CloseIcon style={{ color: 'black' }} />
          </IconButton>
        </div>
        <h1 className={cssClasses.drawerHeading}>Team</h1>
        <p className={cssClasses.drawerDesc}>Let’s add your employees and/or contractors, by adding the required information below</p>
        {addedEmployee.length === 0 ? (
          <div style={{ width: '100%' }}>
            <p className={cssClasses.drawerHeadingSecond}>Team</p>
            <Divider />
            <Formik
              innerRef={myTeamRef}
              validateOnMount
              enableReinitialize
              initialValues={myTeamInitialValues}
              validationSchema={Yup.object().shape({
                name: Yup.string().required('Employee Name is required').max(30, 'Maximum 30 characters are allowed '),
                employeeType: Yup.string().required('employee type is required')
              })}
              onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                console.log()
              }}
            >
              {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setFieldTouched }) =>
                !contEditButtonClicked &&
                !empEditButtonClicked && (
                  <form noValidate onSubmit={handleSubmit}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '24px', margin: '24px 0 48px 0' }}>
                      {' '}
                      {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label htmlFor="employee-fullname" className={cssClasses.drawerInputLabel}>
                        Employee full name <span className={cssClasses.labelStar}>*</span>{' '}
                      </label>
                      <input
                        placeholder="Type here..."
                        value={values.name}
                        name="name"
                        id="employee-fullname"
                        className={cssClasses.drawerInput}
                        onChange={e => {
                          handleChange(e)
                          handleNameChange(e)
                          if (values.name) setFieldTouched('name', true)
                        }}
                        onBlur={handleBlur}
                      />{' '}
                      {touched.name && errors.name && <FormHelperText error> {errors.name} </FormHelperText>}
                    </div> */}
                      <div className={cssClasses.drawerSelectInput}>
                        <label htmlFor="employee_type" className={cssClasses.drawerInputLabel}>
                          Employee type <span className={cssClasses.labelStar}>*</span>{' '}
                        </label>
                        <select
                          placeholder="Select"
                          id="employee_type"
                          name="employeeType"
                          className={cssClasses.drawerInput}
                          style={{ width: '100%' }}
                          onChange={e => {
                            handleChange(e)
                            setSelectedTeam(e.target.value)
                          }}
                          onBlur={handleBlur}
                          value={values.employeeType}
                        >
                          <option disabled value="">
                            Select
                          </option>
                          <option value="employee">Employee</option>
                          <option value="contractor">Contractor</option>
                        </select>{' '}
                        {touched.employeeType && errors.employeeType && <FormHelperText error> {errors.employeeType} </FormHelperText>}
                      </div>
                    </div>
                    {/* <button type="submit" onClick={() => console.log(myTeamRef.current, 'submitforomref')}>
                    SUbmit
                  </button> */}
                    {!values.employeeType && (
                      <div>
                        <Divider sx={{ marginBottom: '10px' }} />

                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <button
                            className={cssClasses.wizardAddButtonDisabled}
                            type="button"
                            // disabled={isSubmitting}
                            onClick={() => {
                              if (myTeamRef.current) myTeamRef.current.handleSubmit()
                            }}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    )}
                  </form>
                )
              }
            </Formik>
            <div> {setEmplOrContFrom()}</div>

            <Divider sx={{ marginTop: '230px', marginBottom: '10px' }} />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button className={cssClasses.wizardCancelButton} type="button">
                Cancel
              </button>
              <button className={cssClasses.disabledButton} type="button">
                Save
              </button>
            </div>
          </div>
        ) : (
          <EmployeeList
            addedEmployee={addedEmployee}
            setAddedEmployee={setAddedEmployee}
            selectedTeam={selectedTeam}
            setSelectedTeam={setSelectedTeam}
            setTeamMemberData={setTeamMemberData}
            setEmpEditButtonClicked={setEmpEditButtonClicked}
            setContEditButtonClicked={setContEditButtonClicked}
            setAddButtonClicked={setAddButtonClicked}
            closeSideDrawer={closeSideDrawer}
            handleDeleteEmployee={handleDeleteEmployee}
            handleDeleteContractor={handleDeleteContractor}
          />
        )}

        {/* <button className={cssClasses.drawerAddButton} type="button">
        Add
      </button> */}
        {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography className={cssClasses.drawerHeadingSecond}>Team member</Typography>
        <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#29A364' }}>+ Add</Typography>
      </div> */}
        {/* <Divider /> */}
        {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography className={cssClasses.drawerSubHeading} style={{ color: '#207E4D' }}>
          Team member
        </Typography>
        <MoreHorizIcon />
      </div> */}
        {/* <Grid container spacing={1}>
        <Grid item xs={6}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Typography className={cssClasses.drawerSubHeading}>Employee name</Typography>
            <Typography className={cssClasses.drawerSubText}>Dean Winchester</Typography>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Typography className={cssClasses.drawerSubHeading}>Employee name</Typography>
            <Typography className={cssClasses.drawerSubText}>Dean Winchester</Typography>
          </div>
        </Grid>
      </Grid> */}
      </Grid>
    </Drawer>
  )
})

MyTeamSideDrawer.propTypes = {
  windowHeight: PropTypes.any,
  isDemoCompany: PropTypes.any
}

export default MyTeamSideDrawer
