import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// material-ui
import { makeStyles } from '@material-ui/styles'
import { useTheme } from '@material-ui/core/styles'
import { Avatar, Chip, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@material-ui/core'
import ListItemButton from '@material-ui/core/ListItemButton'

// project imports
import { MENU_OPEN, SET_MENU } from 'store/actions'

// assets
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

import { useQuery } from 'utils/ui'
import { companySelector } from 'store/company/company.slice'

// style constant
const useStyles = makeStyles(theme => ({
  listIcon: {
    minWidth: '18px',
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  menuIcon: {
    color: theme.palette.common.white
  },
  listCustomIconSub: {
    width: '6px',
    height: '6px',
    color: theme.palette.common.white
  },
  listCustomIconSubActive: {
    width: '8px',
    height: '8px',
    color: theme.palette.primary[200]
  },
  listItem: {
    marginBottom: '20px',
    fontFamily: 'Raleway',
    alignItems: 'center',
    color: 'var(--neutrals-cool-600, #3B424C)',
    '&:hover': {
      // backgroundColor: '#0C2D48 !important',
      color: 'var(--neutrals-cool-600, #3B424C)',
      '& $listCustomIcon': {
        color: theme.palette.primary[200]
      }
    },
    '&.Mui-selected': {
      backgroundColor: 'white !important',
      color: 'var(--secondary-purple-600, #259259)',
      '& $listCustomIcon': {
        color: theme.palette.primary[200]
      }
    }
  },
  cListItem: {
    marginBottom: '5px',
    alignItems: 'center',
    color: theme.palette.common.white,
    '&.Mui-selected': {
      color: theme.palette.primary[200]
    }
  },
  listItemNoBack: {
    marginBottom: '5px',
    backgroundColor: 'transparent !important',
    paddingTop: '8px',
    paddingBottom: '8px',
    alignItems: 'flex-start',
    color: theme.palette.common.white
  },
  cListItemNoBack: {
    marginBottom: '5px',
    backgroundColor: 'transparent !important',
    paddingTop: '8px',
    paddingBottom: '8px',
    alignItems: 'flex-start',
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.primary[200]
    },
    '&.Mui-selected': {
      color: theme.palette.primary[200]
    }
  },
  subMenuCaption: {
    ...theme.typography.subMenuCaption
  }
}))

// ===========================|| SIDEBAR MENU LIST ITEMS ||=========================== //

const NavItem = ({ item, level, handleClick, collapsible }) => {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const customization = useSelector(state => state.customization)
  const matchesSM = useMediaQuery(theme.breakpoints.down('md'))
  const query = useQuery()
  const { companies, selectedCompany, isDemo } = useSelector(companySelector)
  // const [isDemocompany, setIsDemo] = useState('')

  // useEffect(() => {
  //   const tempCompany = [...companies].filter(company => company.id === selectedCompany)
  //   if (tempCompany.length > 0) setIsDemo(tempCompany[0].isDemo)
  // }, [companies, selectedCompany])

  const Icon = item.icon
  const IconSelect = item.iconSelect
  const itemIcon = item.icon ? (
    <Icon fill="currentColor" stroke={1.5} size="1.3rem" className={classes.listCustomIcon} />
  ) : (
    <FiberManualRecordIcon
      className={customization.isOpen.findIndex(id => id === item.id) > -1 ? classes.listCustomIconSubActive : classes.listCustomIconSub}
      fontSize={level > 0 ? 'inherit' : 'default'}
    />
  )
  const itemIconSelect = item.iconSelect ? (
    <IconSelect fill="currentColor" stroke={1.5} size="1.3rem" className={classes.listCustomIcon} />
  ) : (
    <FiberManualRecordIcon
      className={customization.isOpen.findIndex(id => id === item.id) > -1 ? classes.listCustomIconSubActive : classes.listCustomIconSub}
      fontSize={level > 0 ? 'inherit' : 'default'}
    />
  )

  let itemIconClass = !item.icon ? classes.listIcon : classes.menuIcon
  itemIconClass = customization.navType === 'nav-dark' ? [itemIconClass, classes.listCustomIcon].join(' ') : itemIconClass

  let itemTarget = ''
  if (item.target) {
    itemTarget = '_blank'
  }

  let listItemProps = {
    component: React.forwardRef((props, ref) => (
      <Link {...props} to={item.url + (query.get('cid') ? `?cid=${query.get('cid')}` : '')} ref={ref} />
    ))
  }
  if (item.external) {
    listItemProps = { component: 'a', href: item.url }
  }

  // const itemHandler = itemId => {
  //   dispatch({ type: MENU_OPEN, id: itemId })
  //   if (matchesSM) dispatch({ type: SET_MENU, opened: false })
  // }

  // active menu item on page load
  React.useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split('/')
      .findIndex(id => id === item.id)
    if (currentIndex > -1) {
      dispatch({ type: MENU_OPEN, id: item.id })
    }
  }, [])

  const setClasses = () => {
    if (level > 1) {
      if (collapsible) {
        return classes.cListItemNoBack
      }
      return classes.listItemNoBack
    }
    if (collapsible) {
      return classes.cListItem
    }
    return classes.listItem
  }

  return (
    <ListItemButton
      {...listItemProps}
      disabled={isDemo && item.id === 'company' ? true : item.disabled}
      className={setClasses()}
      sx={{ borderRadius: `${customization.borderRadius}px` }}
      selected={customization.isOpen.findIndex(id => id === item.id) > -1}
      onClick={() => {
        if (item.id !== 'help') {
          handleClick(item.id)
        }
      }}
      target={itemTarget}
      style={{ paddingLeft: `${level * 23}px`, alignItems: 'center' }}
      color="black"
    >
      <ListItemIcon style={{ color: 'inherit', justifyContent: 'center' }}>
        {customization.isOpen.findIndex(id => id === item.id) > -1 ? itemIconSelect : itemIcon}
      </ListItemIcon>

      <ListItemText
        primary={
          <Typography
            style={{ fontSize: '16px', fontFamily: 'Raleway' }}
            variant={customization.isOpen.findIndex(id => id === item.id) > -1 ? 'h5' : 'body1'}
            color="inherit"
          >
            {item.title}
          </Typography>
        }
        secondary={
          item.caption && (
            <Typography variant="h4" className={classes.subMenuCaption} display="block" gutterBottom>
              {item.caption}
            </Typography>
          )
        }
      />

      {item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  )
}

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
  handleClick: PropTypes.func,
  collapsible: PropTypes.bool
}

export default NavItem
