import React, { lazy } from 'react'
import Loadable from 'ui-component/Loadable'
import MinimalLayout from 'layout/MinimalLayout'
import NavMotion from 'layout/NavMotion'
import GuestGuard from 'utils/route-guard/GuestGuard'
import { Typography } from '@material-ui/core'

const DemoAuthLoader = Loadable(lazy(() => import('views/pages/authentication/demoAuthLoader')))

const GuestUserRoutes = {
  path: 'demo',
  element: <MinimalLayout />,
  children: [
    {
      path: '/',
      element: (
        <NavMotion>
          <DemoAuthLoader />
        </NavMotion>
      )
    }
  ]
}

export default GuestUserRoutes
