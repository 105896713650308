import React, { useEffect, useState } from 'react'

import { Box, Button, Divider, Drawer, Typography, IconButton, Grid, FormHelperText } from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

import UploadIcon from '../../assets/images/icons/UploadIcon.png'

import * as Yup from 'yup'
import { Formik } from 'formik'

import cssClasses from './infoTab.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { updateCompany } from 'store/company/company.actions'
import { setCompanySetupModalScreen, uiSelector } from 'store/ui/ui.slice'
import { companySelector } from 'store/company/company.slice'
import DepartmentList from './DrawerListPages/DepartmentList'
import ExcelImporTabModel from './excelImportTab'

const customStyles = {
  backdrop: {
    fill: 'var(--neutrals-cool-1000, #0C0D0F)',
    opacity: 0.25
  }
}

const SideDrawer = props => {
  const {
    isAddButtonClicked,
    setAddButtonClicked,
    handleNameChange,
    initialValues,
    formRef,
    handleSaveClick,
    handleCancelClick,
    departments,
    departmentObject,
    setDepartments,
    isEditButtonClicked,
    handleAddNewDepatment,
    handleEditClick,
    isListAddButtonClicked,
    setAddListButtonClicked,
    setEditButtonClicked,
    addedDept,
    setAddedDept,
    setNewName,
    setEdited
  } = props

  const { id, departmentList } = useSelector(companySelector)
  const { companySetupModalScreen } = useSelector(uiSelector)
  const [departmentNameError, setDepartmentNameError] = useState('')

  const dispatch = useDispatch()

  const onClickAddButtonHandler = departmentName => {
    if (isEditButtonClicked) {
      handleSaveClick()
    } else if (departmentName) {
      handleAddNewDepatment(departmentName)
    }
  }
  const deleteDepartment = () => {
    const data = {
      id,
      departmentList: departments,
      currentModelScreen: companySetupModalScreen + 1,
      userDepartment: departmentObject
    }
    dispatch(updateCompany(data))
  }
  return (
    <Drawer
      BackdropProps={{ style: customStyles.backdrop }}
      style={{ zIndex: 1000000 }}
      anchor="right"
      open={isAddButtonClicked}
      onClose={() => {
        setAddButtonClicked(false)
        setAddListButtonClicked(false)
        setAddedDept([])
        setNewName('')
        setEdited(false)
      }}
      sx={{
        '& .css-4qyyic-MuiGrid-root': {
          padding: '24px',
          width: '390px'
        }
      }}
    >
      <Grid p={5} width="500px" role="presentation" textAlign="start">
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton
            onClick={() => {
              setAddButtonClicked(false)
              setAddListButtonClicked(false)
              setAddedDept([])
            }}
          >
            <CloseIcon style={{ color: 'black' }} />
          </IconButton>
        </div>
        <h1 className={cssClasses.drawerHeading}>Departments</h1>
        {addedDept.length === 0 ? (
          <div style={{ width: '100%' }}>
            <p className={cssClasses.drawerDesc}>
              Go ahead and list the departments or functions of your business. You have 2 options to do so: Bulk import or manually add the
              information below.
            </p>
            <p className={cssClasses.drawerHeadingSecond}>Option 1: Bulk Import</p>

            <ExcelImporTabModel excelImportTabNo={1} />
            <Formik
              validateOnMount
              innerRef={formRef}
              enableReinitialize
              initialValues={initialValues}
              validationSchema={Yup.object().shape({
                departmentName: Yup.string().required('Department name is required').max(30, 'Maximum 30 characters allowed')
              })}
              onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                const data = {
                  id,
                  departmentList: departments,
                  currentModelScreen: companySetupModalScreen + 1,
                  userDepartment: departmentObject
                }
                dispatch(updateCompany(data))

                // setAddButtonClicked(false)
                setStatus({ success: true })
                setSubmitting(false)
                setAddListButtonClicked(false)
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
                isValid,
                setFieldTouched
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '24px', marginTop: '48px' }}>
                    <div>
                      <Typography className={cssClasses.drawerHeadingSecond}>Option 2: Manually add</Typography>
                      <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
                      <p className={cssClasses.drawerBoldText}>In the field below, add the appropriate information. </p>
                      <input
                        style={{ margin: '24px 0' }}
                        value={values.departmentName}
                        name="departmentName"
                        placeholder="Utilities"
                        className={cssClasses.wizardInputBox}
                        onChange={event => {
                          handleChange(event)
                          handleNameChange(event)
                          if (values.departmentName) setFieldTouched('departmentName', true)
                        }}
                        onBlur={handleBlur}
                      />
                      {(touched.departmentName && errors.departmentName) || (!values.departmentName && departmentNameError) ? (
                        <FormHelperText error>{errors.departmentName || departmentNameError}</FormHelperText>
                      ) : null}
                    </div>
                  </div>
                  <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button
                      type="button"
                      className={isValid ? cssClasses.wizardAddButton : cssClasses.wizardAddButtonDisabled}
                      onClick={() => {
                        if (!values.departmentName || errors.departmentName) {
                          setDepartmentNameError('Department name is required')
                          return
                        }
                        setDepartmentNameError('')
                        onClickAddButtonHandler(values.departmentName)
                      }}
                    >
                      Add
                    </button>
                  </div>

                  <Divider sx={{ marginTop: '230px', marginBottom: '10px' }} />
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button
                      onClick={() => {
                        handleCancelClick()
                      }}
                      className={cssClasses.wizardCancelButton}
                      type="button"
                    >
                      Cancel
                    </button>
                    <button type="button" className={cssClasses.disabledButton}>
                      Save
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        ) : (
          <div style={{ width: '100%' }}>
            <p className={cssClasses.drawerDesc}>Go ahead and list the departments or functions of your business.</p>
            <DepartmentList
              setAddListButtonClicked={setAddListButtonClicked}
              handleEditClick={handleEditClick}
              setEditButtonClicked={setEditButtonClicked}
              setAddedDept={setAddedDept}
              addedDept={addedDept}
              setAddButtonClicked={setAddButtonClicked}
              departments={departments}
              setDepartments={setDepartments}
              deleteDepartment={deleteDepartment}
              setEdited={setEdited}
            />
          </div>
        )}
      </Grid>
    </Drawer>
  )
}

export default SideDrawer
