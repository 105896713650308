import { createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance } from 'utils/API/AxiosConfig'
import { SERVER_URL } from 'utils/API'

const getQbIntegration = createAsyncThunk('company/QB/Integration', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/data-integration/QBIntegration`,
      { ...payload },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const createBossInsightAccount = createAsyncThunk('company/QB/CreateBossInsightAccount', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/data-integration/CreateBossInsightAccount`,
      { ...payload },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

export { getQbIntegration, createBossInsightAccount }
