import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import styles from '../../styles.module.css'
import PropTypes from 'prop-types'
import {
  Grid,
  Button,
  Box,
  TextField,
  Fab,
  Divider,
  Card,
  CardHeader,
  CardContent,
  Typography,
  IconButton,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  CardMedia
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import UpdateIcon from '@material-ui/icons/Save'
import UpgradeIcon from '@material-ui/icons/Upgrade'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import * as Yup from 'yup'
import { Formik } from 'formik'
import moment from 'moment'
import AdapterDateFns from '@material-ui/lab/AdapterMoment'
import LocalizationProvider from '@material-ui/lab/LocalizationProvider'

import UploadIcon from '../../assets/images/icons/UploadIcon.png'

import { gridSpacing } from 'store/constant'
import ModalSubtitle from './ModalSubtitle'
import cssClasses from './infoTab.module.css'
import NumberFormat from 'react-number-format'
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined'

import {
  uiSelector,
  setCompanySetupModalScreen,
  toggleCompanySetupModal,
  setExcelImportTabModel,
  toggleExcelImportTabModel,
  setSelectedMenuItem
} from 'store/ui/ui.slice'
import { finishCompanyCreate, getCompaniesByUser, getCompanyByID, updateCompany } from 'store/company/company.actions'
import { clearState, resetState, companySelector, setIsWizardVisible } from 'store/company/company.slice'
import { SNACKBAR_OPEN, MENU_OPEN } from 'store/actions'
import { DatePicker, LoadingButton, MobileDatePicker } from '@material-ui/lab'

import { useNavigate } from 'react-router'
import PopupNotify from 'ui-component/tab/Model/PopupNotify'
import { numberFormat } from 'utils/ui'
import FieldsFilled from 'utils/fieldsFilled'
import { makeStyles } from '@material-ui/styles'

// import { DatePicker } from 'antd'
import dayjs from 'dayjs'

import excel from 'assets/images/excel.png'
import arrow from 'assets/images/Arrow.png'

import excelWithA from 'assets/images/excelWithA.png'

import { authSelector } from 'store/auth/auth.slice'
import ExcelImporTabModel from './excelImportTab'

const useStyles = makeStyles(theme => ({
  upgradeBtn: {
    width: 96,
    height: 50,
    fontFamily: 'Helvetica',
    color: '#FFF',
    fontSize: '16px',
    background: '#29A364',
    borderRadius: '8px',
    marginRight: '12px',
    // paddingRight: '18px',
    // paddingLeft: '18px',
    '&:hover': {
      background: '#14284a',
      color: 'white'
    }
  },
  dropdownStyle: {
    borderRadius: '8px !important',
    '& .MuiOutlinedInput-input': {
      borderRadius: '8px !important'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px !important'
    }
  },
  excel: {
    width: '53px',
    height: '31px',
    objectFit: 'contain',
    // marginTop: -25,
    borderRadius: 30,
    cursor: 'pointer'
    // '&:hover': {
    //   background: '#c6e9f5'
    // }
  }
}))

const TopLineBillableClientsTabView = forwardRef((props, ref) => {
  const classes = useStyles()
  const { selectedRevenueComputationalType, isEditButtonClicked, editItem, setEditItem, setAddButtonClicked, setAddedItem, addedItem } =
    props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const formRef = useRef(null)
  const { companySetupModalScreen } = useSelector(uiSelector)
  const { user } = useSelector(authSelector)
  const {
    isLoading,
    isSuccess,
    isSuccessCompanyCreate,
    updateCompanyError,
    id,
    clientList,
    departmentList,
    selectedCompany,
    currentlyActiveCompanyId,
    vendorList,
    incompleteCompanies,
    isDemo
  } = useSelector(companySelector)
  const [isNext, setNext] = useState(false)
  const [notify, setNotify] = useState(false)
  // const [editItem, setEditItem] = useState('')
  const [editButtonClicked, setEditButtonClicked] = useState(false)
  const [selectedCard, setSelectedCard] = useState('')
  const [mouseEnterOnCard, setMouseEnterOnCard] = useState('')

  const isCoaCompleted = vendorList.some(eachItem => eachItem.department)

  // useImperativeHandle(ref, () => ({
  //   handleNext() {
  // if (clientList.length === 0 || (formRef.current.values.name && clientList.length > 0)) {
  //   if (formRef.current.isValid) {
  //     setNotify(true)
  //     setNext(true)
  //   } else {
  //     dispatch(clearState())
  //     if (formRef.current) formRef.current.handleSubmit()
  //     setNext(true)
  //   }
  // } else {
  //   setNext(false)
  //     dispatch(clearState())
  //     const data = {
  //       companyId: id,
  //       isComplete: isCoaCompleted,
  //       fromRevenue: true
  //     }
  //     dispatch(getCompaniesByUser())
  //     dispatch(setSelectedMenuItem(''))
  //     dispatch(setIsWizardVisible(false))
  //     if (isDemo) {
  //       dispatch(toggleCompanySetupModal(false))
  //       dispatch(setCompanySetupModalScreen(0))
  //       dispatch(resetState())
  //       dispatch(getCompaniesByUser())
  //     } else {
  //       dispatch(finishCompanyCreate(data))
  //       navigate(`/budget?cid=${selectedCompany}`)
  //       dispatch({ type: MENU_OPEN, id: 'models' })
  //       // if (isAddRevenueVisible) setAddRevenueVisible(false)
  //     }
  //     // }
  //   }
  // }))

  const formikInitialValues = {
    _id: '',
    name: '',
    growthIncrement: '',
    growthRate: '',
    startDate: '',
    startingRevenue: '',
    submit: null
  }
  const [initialValues, setInitialValues] = useState(formikInitialValues)

  const [openStartDatePicker, setOpenStartDatePicker] = useState(false)
  const [openEndDatePicker, setOpenEndDatePicker] = useState(false)

  const handleAddClient = (name, growthIncrement, growthRate, startDate, startingRevenue) => {
    const payload = {
      name,
      growthIncrement,
      growthRate,
      startDate,
      startingRevenue
    }
    const logArray = []

    const clientChangeLog = {
      user: user._id,
      userName: user.name,
      company: selectedCompany,
      date: new Date(),
      tabChanged: 'Revenue/ Pro Services/ Top Line Billable Client',
      department: 'N/A',
      lineItemChanged: `${payload.name} (New Client added)`,
      originalValue: 'N/A',
      newValue: 'N/A'
    }
    logArray.push(clientChangeLog)

    const clientPayload = [...clientList]
    clientPayload.push(payload)
    if (id !== '') {
      const data = {
        id,
        clientList: clientPayload,
        systemLog: logArray,
        revenueComputationType: selectedRevenueComputationalType
      }
      dispatch(updateCompany(data))
    }
    setInitialValues(formikInitialValues)
  }

  useEffect(() => {
    const tempObject = { ...editItem }
    const d = new Date(tempObject.startDate).toLocaleDateString()
    if (isEditButtonClicked) {
      setInitialValues({
        ...editItem,
        startDate: dayjs(d).format('YYYY-MM-DD'),

        submit: null
      })
    }
  }, [isEditButtonClicked])

  // const editClient = item => {
  //   setEditItem(item._id)
  //   const tempObject = { ...item }
  //   const d = new Date(tempObject.startDate)

  //   setInitialValues({
  //     ...item,
  //     startDate: dayjs(d).format('YYYY/MM/DD'),

  //     submit: null
  //   })
  // }

  const handleDeleteClient = _id => {
    if (editItem === _id) return
    // if (formRef.current.values._id) {
    //   setNotify(true)
    //   return
    // }

    const selectedClient = [...clientList].filter(item => item._id === _id)

    //

    const payload = {
      _id: selectedClient[0]._id,
      name: selectedClient[0].name
    }

    const clientPayload = [...clientList].filter(item => item._id !== _id)

    // const index = clientPayload.findIndex(item => item._id === payload._id)

    const logArray = []

    const clientChangeLog = {
      user: user._id,
      userName: user.name,
      company: selectedCompany,
      date: new Date(),
      tabChanged: 'Revenue/ Pro Services/ Top Line Billable client',
      department: 'N/A',
      lineItemChanged: `${payload.name} (Client deleted)`,
      originalValue: 'N/A',
      newValue: 'N/A'
    }
    logArray.push(clientChangeLog)

    if (id !== '') {
      const data = {
        id,
        clientList: clientPayload,
        systemLog: logArray
      }
      dispatch(updateCompany(data))
    }
  }

  const handleUpdateClient = (_id, name, growthIncrement, growthRate, startDate, startingRevenue) => {
    setEditItem('')
    const payload = {
      _id,
      name,
      growthIncrement,
      growthRate,
      startDate,
      startingRevenue
    }

    const clientListItem = []
    clientListItem.push(payload)
    setAddedItem(clientListItem)

    const clientPayload = [...clientList]
    const index = clientPayload.findIndex(item => item._id === payload._id)

    //  system logs
    const logArray = []

    if (clientPayload[index].name !== payload.name) {
      const vendorChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Top Line Billable Clilent',
        department: 'N/A',
        lineItemChanged: `${clientPayload[index].name} (Name changed)`,
        originalValue: clientPayload[index].name,
        newValue: payload.name
      }
      logArray.push(vendorChangeLog)
    }

    if (clientPayload[index].growthIncrement !== payload.growthIncrement) {
      const vendorChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Top Line Billable Clilent',
        department: 'N/A',
        lineItemChanged: `${clientPayload[index].name} (Growth Increment changed)`,
        originalValue: clientPayload[index].growthIncrement,
        newValue: payload.growthIncrement
      }
      logArray.push(vendorChangeLog)
    }

    if (clientPayload[index].growthRate !== payload.growthRate) {
      const vendorChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Top Line Billable Clilent',
        department: 'N/A',
        lineItemChanged: `${clientPayload[index].name} (Growth Rate changed)`,
        originalValue: clientPayload[index].growthRate,
        newValue: payload.growthRate
      }
      logArray.push(vendorChangeLog)
    }

    if (clientPayload[index].startingRevenue !== payload.startingRevenue) {
      const vendorChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Top Line Billable Clilent',
        department: 'N/A',
        lineItemChanged: `${clientPayload[index].name} (Starting Revenue changed)`,
        originalValue: clientPayload[index].startingRevenue,
        newValue: payload.startingRevenue
      }
      logArray.push(vendorChangeLog)
    }
    if (dayjs(clientPayload[index].startDate).format('YYYY/MM/DD') !== dayjs(payload.startDate).format('YYYY/MM/DD')) {
      const vendorChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Vendors',
        department: 'N/A',
        lineItemChanged: `${clientPayload[index].name} (Start Date changed)`,
        originalValue: dayjs(clientPayload[index].startDate).format('MM/DD/YYYY'),
        newValue: dayjs(payload.startDate).format('MM/DD/YYYY')
      }
      logArray.push(vendorChangeLog)
    }

    // log ends

    clientPayload[index] = payload
    if (id !== '') {
      const data = {
        id,
        clientList: clientPayload,
        systemLog: logArray,
        revenueComputationType: selectedRevenueComputationalType
      }
      dispatch(updateCompany(data))
    }
    setInitialValues(formikInitialValues)
  }

  useEffect(() => {
    if (updateCompanyError !== '') {
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: updateCompanyError,
        variant: 'alert',
        alertSeverity: 'error'
      })
    }

    return dispatch(clearState())
  }, [dispatch, updateCompanyError])

  useEffect(() => {
    if (isSuccess && isNext) {
      dispatch(clearState())
      const data = {
        companyId: id,
        isComplete: isCoaCompleted,
        fromRevenue: true
      }

      dispatch(finishCompanyCreate(data))
    }
  }, [isNext, isSuccess])

  useEffect(() => {
    if (isSuccessCompanyCreate) {
      dispatch(toggleCompanySetupModal(false))
      // dispatch(setCompanySetupModalScreen(0))
      dispatch(resetState())
      const data = {
        companyId: currentlyActiveCompanyId || selectedCompany,
        email: user.email
      }
      dispatch(getCompanyByID(data))
    }
  }, [isSuccessCompanyCreate])

  const handleChangeNumber = e => {
    const value = e.target.value.replace(/\D/g, '')
    formRef.current.setFieldValue('growthRate', value.replace(/\B(?=(\d{3})+(?!\d))/g, ','))
  }

  const handleChangeRevenue = e => {
    const value = e.target.value.replace(/\D/g, '')
    formRef.current.setFieldValue('startingRevenue', value.replace(/\B(?=(\d{3})+(?!\d))/g, ','))
  }

  const onClickCancelButton = () => {
    setEditItem('')
    setEditButtonClicked(false)
    setInitialValues(formikInitialValues)
  }

  return (
    <>
      {' '}
      <div style={{ width: '100%' }}>
        <p style={{ color: '#207E4D' }} className={cssClasses.drawerHeadingSecond}>
          My client
        </p>
        <ExcelImporTabModel excelImportTabNo={6} />
      </div>
      <Formik
        innerRef={formRef}
        validateOnMount
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Client Name is required').max(30, 'Maximum 30 characters allowed'),
          growthIncrement: Yup.string().required('Growth increment is required'),
          growthRate: Yup.string().required('Percentage growth per period is required').max(3, 'Maximum 3 digits allowed'),
          startDate: Yup.string().required('Start month and year is required'),
          // endDate: Yup.date().required('End date is required').typeError('Please select a valid date'),
          startingRevenue: Yup.string()
            .required('Salary is required')
            .matches(/^[\d,]+$/, 'Enter only numbers')
            .max(30, 'Maximum 30 characters allowed')
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
          if (values._id) {
            handleUpdateClient(
              values._id,
              values.name,
              values.growthIncrement,
              values.growthRate,
              new Date(values.startDate).toLocaleDateString('en-US'),
              values.startingRevenue.toString().includes(',')
                ? parseInt(values.startingRevenue.replace(/,/g, ''), 10)
                : values.startingRevenue
            )
          } else {
            handleAddClient(
              values.name,
              values.growthIncrement,
              values.growthRate,
              new Date(values.startDate).toLocaleDateString('en-US'),
              values.startingRevenue.toString().includes(',')
                ? parseInt(values.startingRevenue.replace(/,/g, ''), 10)
                : values.startingRevenue
            )
          }
          setStatus({ success: true })
          setSubmitting(false)
          resetForm(initialValues)
          setEditButtonClicked(false)
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, isValid, setFieldTouched }) => (
          <form noValidate onSubmit={handleSubmit} style={{ width: '100%', marginTop: '48px' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column' }}>
              <label htmlFor="client_name" className={cssClasses.inputLabel}>
                Client name <span className={cssClasses.important}>*</span>{' '}
              </label>

              <input
                placeholder="Client name"
                className={cssClasses.wizardInputBox}
                value={values.name}
                name="name"
                onBlur={handleBlur}
                onChange={e => {
                  handleChange(e)
                  if (values.name) setFieldTouched('name', true)
                }}
                id="client_name"
              />

              {touched.name && errors.name && <FormHelperText error> {errors.name} </FormHelperText>}
            </div>

            <div
              style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', marginTop: '20px' }}
            >
              <label htmlFor="growthIncrement-select-label" className={cssClasses.inputLabel}>
                Growth increment <span className={cssClasses.important}>*</span>{' '}
              </label>

              <select
                placeholder="frequency"
                className={cssClasses.wizardInputBox}
                value={values.growthIncrement}
                name="growthIncrement"
                onBlur={handleBlur}
                onChange={handleChange}
                id="growthIncrement-select-label"
              >
                <option value="" disabled selected hidden>
                  Select
                </option>
                <option value="Monthly">Monthly</option>
                <option value="Quarterly">Quarterly</option>
                <option value="Annually">Annually</option>
              </select>

              {touched.growthIncrement && errors.growthIncrement && <FormHelperText error> {errors.growthIncrement} </FormHelperText>}
            </div>

            <div
              style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', marginTop: '20px' }}
            >
              <label htmlFor="grawth_rate" className={cssClasses.inputLabel}>
                Growth percentage <span className={cssClasses.important}>*</span>{' '}
              </label>
              <div className={cssClasses.containerInputs}>
                <NumberFormat
                  placeholder="Growth percentage"
                  className={cssClasses.transparentInputs}
                  value={values.growthRate}
                  name="growthRate"
                  onBlur={handleBlur}
                  onChange={e => {
                    handleChange(e)
                    if (values.growthRate) setFieldTouched('growthRate', true)
                  }}
                  id="grawth_rate"
                />
                <Typography className={cssClasses.inputPercentage}>%</Typography>
              </div>

              {touched.growthRate && errors.growthRate && <FormHelperText error> {errors.growthRate} </FormHelperText>}
            </div>

            <div
              style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', marginTop: '20px' }}
            >
              <label htmlFor="start_date" className={cssClasses.inputLabel}>
                Start date <span className={cssClasses.important}>*</span>{' '}
              </label>

              <input
                type="date"
                placeholder="Start date"
                className={cssClasses.wizardInputBox}
                value={values.startDate}
                name="startDate"
                onBlur={handleBlur}
                onChange={handleChange}
                id="start_date"
              />

              {touched.startDate && errors.startDate && <FormHelperText error> {errors.startDate} </FormHelperText>}
            </div>

            <div
              style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', marginTop: '20px' }}
            >
              <label htmlFor="salary" className={cssClasses.inputLabel}>
                Amount <span className={cssClasses.important}>*</span>{' '}
              </label>

              <div className={cssClasses.containerInputs}>
                <MonetizationOnOutlinedIcon />
                <NumberFormat
                  className={cssClasses.transparentInputs}
                  placeholder="Amount"
                  value={values.startingRevenue}
                  name="startingRevenue"
                  onBlur={handleBlur}
                  onChange={e => {
                    handleChange(e)
                    if (values.startingRevenue) setFieldTouched('startingRevenue', true)
                  }}
                  id="salary"
                  thousandSeparator=","
                />
              </div>

              {touched.startingRevenue && errors.startingRevenue && <FormHelperText error> {errors.startingRevenue} </FormHelperText>}
            </div>

            <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button className={isValid ? cssClasses.wizardAddButton : cssClasses.wizardAddButtonDisabled} type="submit">
                Add
              </button>
            </div>
            <Divider sx={{ marginTop: '230px', marginBottom: '10px' }} />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button onClick={onClickCancelButton} className={cssClasses.wizardCancelButton} type="button">
                Cancel
              </button>
              <button className={cssClasses.disabledButton} type="button">
                Save
              </button>
            </div>

            {/* <div style={{ width: '100%', marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              className={classes.upgradeBtn}
              disabled={isSubmitting}
              onClick={() => {
                setNext(false)
                setEditButtonClicked(false)
                if (formRef.current) formRef.current.handleSubmit()
              }}
              size="medium"
              color="secondary"
              aria-label="add"
            >
              <Typography> Add</Typography>
            </Button>
          </div> */}
          </form>
        )}
      </Formik>
    </>
  )
})

export default TopLineBillableClientsTabView
