import React, { useEffect, useRef, useState } from 'react'

import { Box, Button, Divider, Drawer, Typography, IconButton, Grid, FormHelperText, Switch } from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined'

import UploadIcon from '../../assets/images/icons/UploadIcon.png'

import cssClasses from './infoTab.module.css'
import NumberFormat from 'react-number-format'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { setCompanySetupModalScreen, uiSelector } from 'store/ui/ui.slice'
import { companySelector } from 'store/company/company.slice'
import { makeStyles } from '@material-ui/styles'
import { authSelector } from 'store/auth/auth.slice'
import { updateCompany } from 'store/company/company.actions'
import moment from 'moment'
import dayjs from 'dayjs'
import ExpensesList from './DrawerListPages/ExpensesList'
import ExcelImporTabModel from './excelImportTab'

const customStyles = {
  backdrop: {
    fill: 'var(--neutrals-cool-1000, #0C0D0F)',
    opacity: 0.25
  }
}
const pxToRem = (px, oneRemPx = 17) => `${px / oneRemPx}rem`
const useStyles = makeStyles(theme => {
  const borderWidth = 2
  const width = pxToRem(44)
  const height = pxToRem(24)
  const size = pxToRem(18)
  const gap = (24 - 18) / 2
  const primaryColor = '#29A364'
  const crossColor = '#0C0D0F'
  return {
    root: {
      width,
      height,
      padding: 0,
      margin: theme.spacing(1),
      overflow: 'unset'
    },
    switchBase: {
      padding: pxToRem(gap),
      '&$checked': {
        color: '#fff',
        transform: `translateX(calc(${width} - ${size} - ${pxToRem(2 * gap)}))`,
        '& + $track': {
          backgroundColor: '#29A364',
          opacity: 1
        },
        '& $thumb': {
          backgroundColor: '#fff',
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none"/><path fill="${encodeURIComponent(
            primaryColor
          )}" d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>')`
        }
      }
    },
    track: {
      borderRadius: 40,
      backgroundColor: '#fff',
      border: 'solid #0C0D0F',
      borderWidth,
      opacity: 0.48
    },
    thumb: {
      width: size,
      height: size,
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path fill="${encodeURIComponent(
        crossColor
      )}" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/><path d="M0 0h24v24H0z" fill="none"/></svg>')`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    },
    checked: {}
  }
})
const SideDrawer = props => {
  const classes = useStyles()
  const {
    isAddButtonClicked,
    setAddButtonClicked,
    localVendorList,
    editButtonClicked,
    setEditButtonClicked,
    editItem,
    setEditItemId,
    formikInitialValues,
    initialValues,
    setInitialValues,
    vendorGroupByParent,
    editVendor
  } = props

  const formRef = useRef(null)
  const dispatch = useDispatch()
  const { companySetupModalScreen } = useSelector(uiSelector)
  const {
    isSuccess,
    updateCompanyError,
    id,
    selectedCompany,
    vendorList,
    departmentList,
    productList,
    revenueComputationType,
    clientList,
    revenueModel,
    isOpenedFromCompDrop
  } = useSelector(companySelector)
  const { user } = useSelector(authSelector)

  const [addedItem, setAddedItem] = useState([])

  const editVendorCancel = () => {
    setInitialValues({
      name: '',
      department: '',
      frequency: '',
      amount: '',
      startDate: '',
      endDate: '',
      nestedUnder: '',
      cogs: false,
      hrRelated: '',
      govtTaxed: false
    })

    // formRef.current.resetForm(initialValues)

    setEditItemId('')
  }

  const handleAddVendorinCheckList = (name, department, frequency, startDate, endDate, amount, nestedUnder, cogs, hrRelated, govtTaxed) => {
    const updatedNestedUnder = nestedUnder === 'Not Nested' ? '' : nestedUnder
    const payload = {
      name,
      department,
      frequency,
      startDate,
      endDate,
      amount,
      nestedUnder: updatedNestedUnder,
      cogs,
      hrRelated: hrRelated === 'true',
      govtTaxed
    }

    const expList = []
    expList.push(payload)
    setAddedItem(prevState => [...prevState, payload])

    const logArray = []
    const employeeChangeLog = {
      user: user.id,
      userName: user.name,
      company: selectedCompany,
      date: new Date(),
      tabChanged: 'Vendors',
      department: payload.department,
      lineItemChanged: `${payload.name}(New Vendors added)`,
      originalValue: `N/A`,
      newValue: payload.name
    }
    logArray.push(employeeChangeLog)
    let vendorPayload
    if (vendorList?.length > 0) {
      vendorPayload = [...vendorList]
    } else {
      vendorPayload = [...localVendorList]
    }
    vendorPayload.push(payload)

    if (id !== '') {
      const data = {
        id,
        vendorList: vendorPayload,
        currentModelScreen: companySetupModalScreen + 1,
        systemLog: logArray
      }

      dispatch(updateCompany(data))
    }
    setInitialValues(formikInitialValues)
  }

  const handleUpdateVendor = (_id, name, department, frequency, startDate, endDate, amount, nestedUnder, cogs, hrRelated, govtTaxed) => {
    setEditItemId(' ')
    const updatedNestedUnder = nestedUnder === 'Not Nested' ? '' : nestedUnder
    const payload = {
      _id,
      name,
      department,
      frequency,
      startDate,
      endDate,
      amount,
      nestedUnder: updatedNestedUnder,
      cogs,
      hrRelated: hrRelated === 'true',
      govtTaxed
    }

    const expList = []
    expList.push(payload)
    setAddedItem(prevList => [...prevList, payload])

    const logArray = []
    const vendorPayload = [...localVendorList]
    const index = vendorPayload.findIndex(item => item._id === payload._id)
    //  system logs
    // if (vendorPayload[index].department !== payload.department) {
    //   const vendorChangeLog = {
    //     user: user.id,
    //     userName: user.name,
    //     company: selectedCompany,
    //     date: new Date(),
    //     tabChanged: 'Vendors',
    //     department: payload.department,
    //     lineItemChanged: `${vendorPayload[index].name} (Department changed)`,
    //     originalValue: vendorPayload[index].department,
    //     newValue: payload.department
    //   }
    //   logArray.push(vendorChangeLog)
    // }
    // if (vendorPayload[index].name !== payload.name) {
    //   const vendorChangeLog = {
    //     user: user.id,
    //     userName: user.name,
    //     company: selectedCompany,
    //     date: new Date(),
    //     tabChanged: 'Vendors',
    //     department: payload.department,
    //     lineItemChanged: `${vendorPayload[index].name} (Account changed)`,
    //     originalValue: vendorPayload[index].name,
    //     newValue: payload.name
    //   }
    //   logArray.push(vendorChangeLog)
    // }
    // if (dayjs(vendorPayload[index].startDate).format('YYYY/MM/DD') !== dayjs(payload.startDate).format('YYYY/MM/DD')) {
    //   const vendorChangeLog = {
    //     user: user.id,
    //     userName: user.name,
    //     company: selectedCompany,
    //     date: new Date(),
    //     tabChanged: 'Vendors',
    //     department: payload.department,
    //     lineItemChanged: `${vendorPayload[index].name} (Start Date changed)`,
    //     originalValue: dayjs(vendorPayload[index].startDate).format('MM/DD/YYYY'),
    //     newValue: dayjs(payload.startDate).format('MM/DD/YYYY')
    //   }
    //   logArray.push(vendorChangeLog)
    // }

    // if (dayjs(vendorPayload[index].endDate).format('YYYY/MM/DD') !== dayjs(payload.endDate).format('YYYY/MM/DD')) {
    //   const vendorChangeLog = {
    //     user: user.id,
    //     userName: user.name,
    //     company: selectedCompany,
    //     date: new Date(),
    //     tabChanged: 'Vendors',
    //     department: payload.department,
    //     lineItemChanged: `${vendorPayload[index].name} (End Date changed)`,
    //     originalValue: dayjs(vendorPayload[index].endDate).format('MM/DD/YYYY'),
    //     newValue: dayjs(payload.endDate).format('MM/DD/YYYY')
    //   }
    //   logArray.push(vendorChangeLog)
    // }

    // if (vendorPayload[index].frequency !== payload.frequency) {
    //   const vendorChangeLog = {
    //     user: user.id,
    //     userName: user.name,
    //     company: selectedCompany,
    //     date: new Date(),
    //     tabChanged: 'Vendors',
    //     department: payload.department,
    //     lineItemChanged: `${vendorPayload[index].name} (Frequency changed)`,
    //     originalValue: vendorPayload[index].frequency,
    //     newValue: payload.frequency
    //   }
    //   logArray.push(vendorChangeLog)
    // }
    // if (vendorPayload[index].amount !== payload.amount) {
    //   const vendorChangeLog = {
    //     user: user.id,
    //     userName: user.name,
    //     company: selectedCompany,
    //     date: new Date(),
    //     tabChanged: 'Vendors',
    //     department: payload.department,
    //     lineItemChanged: `${vendorPayload[index].name} (Amount changed)`,
    //     originalValue: vendorPayload[index].amount,
    //     newValue: payload.amount
    //   }
    //   logArray.push(vendorChangeLog)
    // }

    // log ends

    vendorPayload[index] = payload
    if (id !== '') {
      const data = {
        id,
        vendorList: vendorPayload,
        currentModelScreen: companySetupModalScreen + 1,
        systemLog: logArray
      }
      dispatch(updateCompany(data))
    }
    setInitialValues(formikInitialValues)
  }

  const saveButtonDisabled = true
  const closeDrawer = () => {
    setAddButtonClicked(false)
    setAddedItem([])
    setInitialValues(formikInitialValues)
  }

  return (
    <Drawer
      BackdropProps={{ style: customStyles.backdrop }}
      style={{ zIndex: 1000000 }}
      anchor="right"
      open={isAddButtonClicked}
      onClose={() => {
        closeDrawer()
      }}
      sx={{
        '& .css-4qyyic-MuiGrid-root': {
          padding: '24px',
          width: '390px'
        }
      }}
    >
      <Grid p={5} width="500px" role="presentation" textAlign="start">
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton
            onClick={() => {
              closeDrawer()
            }}
          >
            <CloseIcon style={{ color: 'black' }} />
          </IconButton>
        </div>
        <h1 className={cssClasses.drawerHeading}>Expense Category</h1>
        <p className={cssClasses.drawerDesc}>
          Note that adding additional expense categories is optional but helps facilitate better decision making when using the mrgn
          platform.
        </p>
        {addedItem.length === 0 ? (
          <div style={{ width: '100%' }}>
            <p className={cssClasses.drawerHeadingSecond}>Expense Category</p>
            <ExcelImporTabModel excelImportTabNo={2} />
            <Formik
              validateOnMount
              innerRef={formRef}
              enableReinitialize
              initialValues={initialValues}
              validationSchema={Yup.object().shape({
                name: Yup.string().required('Account is required').max(30, 'Maximum 30 characters allowed'),
                department: Yup.string().required('Department is required'),
                frequency: Yup.string().required('Frequency is required'),
                startDate: Yup.date().required('Start date is required').typeError('Start date is required'),
                endDate: Yup.date().min(Yup.ref('startDate'), 'End date must be later than Start date'),
                amount: Yup.string().required('Amount is required').max(30, 'Maximum 30 characters allowed'),
                nestedUnder: Yup.string(),
                cogs: Yup.boolean(),
                hrRelated: Yup.string().required('HR related is required'),
                govtTaxed: Yup.boolean()
              })}
              onSubmit={async (values, { setStatus, setSubmitting, resetForm }) => {
                // if (isDemoCompany) {
                //   dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
                //   return
                // }

                if (editButtonClicked) {
                  handleUpdateVendor(
                    values._id,
                    values.name,
                    values.department,
                    values.frequency,
                    new Date(moment(values.startDate).format('YYYY-MM-DD')),
                    values.endDate ? new Date(moment(values.endDate).format('YYYY-MM-DD')) : values.endDate,
                    values.amount.toString().includes(',') ? parseInt(values.amount.replace(/,/g, ''), 10) : values.amount,
                    values.nestedUnder,
                    values.cogs,
                    values.hrRelated,
                    values.govtTaxed
                  )
                } else {
                  setInitialValues({
                    name: '',
                    department: '',
                    frequency: '',
                    amount: '',
                    startDate: null,
                    endDate: null,
                    nestedUnder: '',
                    cogs: false,
                    hrRelated: '',
                    govtTaxed: false,
                    submit: null
                  })

                  handleAddVendorinCheckList(
                    values.name,
                    values.department,
                    values.frequency,
                    values.startDate,
                    values.endDate,
                    // values.endDate ? dayjs(values.endDate).format('YYYY/MM/DD') : values.endDate,
                    values.amount.toString().includes(',') ? parseInt(values.amount.replace(/,/g, ''), 10) : values.amount,
                    values.nestedUnder,
                    values.cogs,
                    values.hrRelated,
                    values.govtTaxed
                  )
                }
                setEditButtonClicked(false)
                setStatus({ success: true })
                setSubmitting(false)
                // setCurrentVendorItemEditIndex('')
                // setCurrentVendorChildEditIndex('')
                editVendorCancel()
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
                isValid,
                setFieldTouched
              }) => (
                <form noValidate onSubmit={handleSubmit} style={{ width: '100%', marginTop: '48px' }}>
                  <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column' }}>
                    <label htmlFor="account" className={cssClasses.inputLabel}>
                      Account <span className={cssClasses.important}>*</span>{' '}
                    </label>

                    <input
                      placeholder="Account"
                      className={cssClasses.wizardInputBox}
                      value={values.name}
                      name="name"
                      onBlur={handleBlur}
                      onChange={e => {
                        handleChange(e)
                        if (values.name) setFieldTouched('name', true)
                      }}
                      id="account"
                    />

                    {touched.name && errors.name && <FormHelperText error> {errors.name} </FormHelperText>}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      marginTop: '20px'
                    }}
                  >
                    <label htmlFor="nested_under" className={cssClasses.inputLabel}>
                      Nested under <span className={cssClasses.important}>*</span>{' '}
                    </label>

                    <select
                      placeholder="Nested under"
                      className={cssClasses.wizardInputBox}
                      value={values.nestedUnder}
                      name="nestedUnder"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      id="nested_under"
                    >
                      <option value="not_nested">Not Nested</option>
                      {localVendorList.map((vendor, i) => {
                        if (!vendor.nestedUnder) {
                          return (
                            <option value={vendor._id} key={`vendorListItem${i}`}>
                              {vendor.name}
                            </option>
                          )
                        }
                        return null
                      })}
                    </select>

                    {touched.nestedUnder && errors.nestedUnder && <FormHelperText error> {errors.nestedUnder} </FormHelperText>}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      marginTop: '20px'
                    }}
                  >
                    <label htmlFor="department_name" className={cssClasses.inputLabel}>
                      Department <span className={cssClasses.important}>*</span>{' '}
                    </label>

                    <select
                      placeholder="Department"
                      className={cssClasses.wizardInputBox}
                      value={values.department}
                      name="department"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      id="department_name"
                    >
                      <option value="" disabled selected hidden>
                        Select
                      </option>
                      {departmentList.map((department, i) => {
                        if (department.name) {
                          return (
                            <option value={department.name} key={`departmentListItem${i}`}>
                              {department.name}
                            </option>
                          )
                        }
                        return null
                      })}
                    </select>

                    {touched.department && errors.department && <FormHelperText error> {errors.department} </FormHelperText>}
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      marginTop: '20px'
                    }}
                  >
                    <label htmlFor="department_name" className={cssClasses.inputLabel}>
                      Is this HR Related <span className={cssClasses.important}>*</span>{' '}
                    </label>

                    <select
                      placeholder="HR Related"
                      className={cssClasses.wizardInputBox}
                      value={values.hrRelated}
                      name="hrRelated"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      id="hr_Related"
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>

                    {touched.hrRelated && errors.hrRelated && <FormHelperText error> {errors.hrRelated} </FormHelperText>}
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '20px' }}>
                    <label htmlFor="govtTaxed" className={cssClasses.inputLabel} style={{ marginBottom: 0 }}>
                      <Typography className={cssClasses.toogleButtonText}>
                        {' '}
                        Is this a taxable expense? <span className={cssClasses.important}>*</span>
                      </Typography>{' '}
                    </label>
                    <InfoOutlinedIcon sx={{ fontSize: '18px', marginLeft: '5px' }} />
                    <Switch
                      checked={values.govtTaxed}
                      value={values.govtTaxed}
                      onChange={() => setFieldValue('govtTaxed', !values.govtTaxed)}
                      classes={classes}
                      sx={{ alignSelf: 'end' }}
                    />

                    {/* {touched.department && errors.department && <FormHelperText error> {errors.department} </FormHelperText>} */}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      marginTop: '20px'
                    }}
                  >
                    <label htmlFor="frequency" className={cssClasses.inputLabel}>
                      Frequency <span className={cssClasses.important}>*</span>{' '}
                    </label>

                    <select
                      placeholder="frequency"
                      className={cssClasses.wizardInputBox}
                      value={values.frequency}
                      name="frequency"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      id="frequency"
                    >
                      <option value="" disabled selected hidden>
                        Select
                      </option>
                      <option value="Monthly">Monthly</option>
                      <option value="Quarterly">Quarterly</option>
                      <option value="Annually">Annually</option>
                    </select>

                    {touched.frequency && errors.frequency && <FormHelperText error> {errors.frequency} </FormHelperText>}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      marginTop: '20px'
                    }}
                  >
                    <label htmlFor="start_date" className={cssClasses.inputLabel}>
                      Start date <span className={cssClasses.important}>*</span>{' '}
                    </label>

                    <input
                      type="date"
                      placeholder="Start date"
                      className={cssClasses.wizardInputBox}
                      value={values.startDate}
                      name="startDate"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      id="start_date"
                    />

                    {touched.startDate && errors.startDate && <FormHelperText error> {errors.startDate} </FormHelperText>}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      marginTop: '20px'
                    }}
                  >
                    <label htmlFor="end_date" className={cssClasses.inputLabel}>
                      End date{' '}
                    </label>

                    <input
                      type="date"
                      placeholder="End date"
                      className={cssClasses.wizardInputBox}
                      value={values.endDate}
                      name="endDate"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      id="end_date"
                    />

                    {touched.endDate && errors.endDate && <FormHelperText error> {errors.endDate} </FormHelperText>}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      marginTop: '20px'
                    }}
                  >
                    <label htmlFor="amount" className={cssClasses.inputLabel}>
                      Amount<span className={cssClasses.important}>*</span>{' '}
                    </label>

                    <div className={cssClasses.containerInputs}>
                      <MonetizationOnOutlinedIcon />
                      <NumberFormat
                        className={cssClasses.transparentInputs}
                        placeholder="Amount"
                        value={values.amount}
                        name="amount"
                        onBlur={handleBlur}
                        onChange={e => {
                          handleChange(e)
                          if (values.amount) setFieldTouched('amount', true)
                        }}
                        id="amount"
                        thousandSeparator=","
                      />
                    </div>

                    {touched.amount && errors.amount && <FormHelperText error> {errors.amount} </FormHelperText>}
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '20px' }}>
                    <label
                      htmlFor="govtTaxed"
                      className={cssClasses.inputLabel}
                      style={{ display: 'flex', marginBottom: 0, alignItems: 'center' }}
                    >
                      <Typography className={cssClasses.toogleButtonText}>
                        Is this CoGS / CoR <span className={cssClasses.important}>*</span>{' '}
                      </Typography>
                      <InfoOutlinedIcon sx={{ fontSize: '18px', marginLeft: '5px' }} />
                    </label>

                    <Switch
                      checked={values.cogs}
                      value={values.cogs}
                      onChange={() => setFieldValue('cogs', !values.cogs)}
                      classes={classes}
                      sx={{ alignSelf: 'end' }}
                    />

                    {/* {touched.department && errors.department && <FormHelperText error> {errors.department} </FormHelperText>} */}
                  </div>

                  <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button className={isValid ? cssClasses.wizardAddButton : cssClasses.wizardAddButtonDisabled} type="submit">
                      Add
                    </button>
                  </div>
                  <Divider sx={{ marginTop: '230px', marginBottom: '10px' }} />
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button onClick={editVendorCancel} className={cssClasses.wizardCancelButton} type="button">
                      Cancel
                    </button>
                    <button
                      // onClick={() => {
                      //   setAddButtonClicked(false)
                      //   setAddedItem([])
                      // }}
                      className={cssClasses.disabledButton}
                      type="button"
                    >
                      Save
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        ) : (
          <ExpensesList
            addedItem={addedItem}
            setAddedItem={setAddedItem}
            editVendor={editVendor}
            editVendorCancel={editVendorCancel}
            setAddButtonClicked={setAddButtonClicked}
          />
          // <h1>Typo</h1>
        )}
      </Grid>
    </Drawer>
  )
}

export default SideDrawer
