import React from 'react'
import { TextField } from '@material-ui/core'
import { Field, useField } from 'formik'

const TextFieldWrapper = ({ name, ...otherProps }) => {
  const [field, meta] = useField(name)
  const configTextField = {
    ...field,
    ...otherProps,
    variant: 'outlined',
    fullWidth: true
  }
  if (meta && meta.error && meta.touched) {
    configTextField.error = true
    configTextField.helperText = meta.error
  }
  return <TextField {...configTextField} />
}

export default TextFieldWrapper
