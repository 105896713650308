import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  lowHighSettingsValue: '20',
  burnRate: {
    targetBurn: 0, // chosen in initial modal, shown in footer
    progress: 0, // active progress of editing cells
    // option dropdowns below people/vendors tab
    optionDept: 1, // select all
    optionBreakdown: 0, // monthly breakdown
    optionFrequency: 1, // 1 year frequency
    optionTimeline: 1, // future
    modifiedCells: [],
    // modelPeople: {
    //   headers: [],
    //   subHeaders: [],
    //   empcells: [],
    //   empBenefits: [],
    //   empTaxes: [],
    //   totalPayroll: [],
    //   contractorcells: [],
    //   totalContractor: [],
    //   empGrandTotal: [],
    //   currentBurn: 0
    // }
    modelPeople: {
      headers: [],
      subHeaders: [],
      empcells: [],
      empBenefits: [],
      empTaxes: [],
      totalPayroll: [],
      contractorcells: [],
      totalContractor: [],
      empGrandTotal: [],
      currentBurn: 0
    },
    modelVendors: {
      headers: [],
      subHeaders: [],
      vendorcells: [],
      totalVendors: []
    }
  }
}

export const simulationsSlice = createSlice({
  name: 'simulations',
  initialState,
  reducers: {
    resetState: () => initialState,
    updateLowHighSettingsValue: (state, action) => {
      state.lowHighSettingsValue = action.payload

      return state
    },
    // start of burn rate simulation reducers
    updateTargetBurn: (state, action) => {
      state.burnRate.targetBurn = action.payload

      return state
    },
    updateProgress: (state, action) => {
      state.burnRate.progress = action.payload

      return state
    },
    updateOptionDept: (state, action) => {
      state.burnRate.optionDept = action.payload

      return state
    },
    updateOptionBreakdown: (state, action) => {
      state.burnRate.optionBreakdown = action.payload

      return state
    },
    updateOptionFrequecy: (state, action) => {
      state.burnRate.optionFrequency = action.payload

      return state
    },
    updateOptionTimeline: (state, action) => {
      state.burnRate.optionTimeline = action.payload

      return state
    },
    updateModifiedCells: (state, action) => {
      state.burnRate.modifiedCells = action.payload

      return state
    },
    updateBurnRateModelPeople: (state, action) => {
      state.burnRate.modelPeople = action.payload

      return state
    },
    updateBurnRateModelVendors: (state, action) => {
      state.burnRate.modelVendors = action.payload

      return state
    }
    // end of burn rate simulation reducers
  },
  extraReducers: {}
})

export const {
  resetState,
  updateLowHighSettingsValue,
  updateTargetBurn,
  updateProgress,
  updateOptionDept,
  updateOptionBreakdown,
  updateOptionFrequecy,
  updateOptionTimeline,
  updateModifiedCells,
  updateBurnRateModelPeople,
  updateBurnRateModelVendors
} = simulationsSlice.actions
export const simulationsSelector = state => state.simulations
export default simulationsSlice.reducer
