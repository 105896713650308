import PropTypes from 'prop-types'
import React from 'react'

// material-ui
import { makeStyles, useTheme } from '@material-ui/styles'
import { Box, Divider, Drawer, Grid, useMediaQuery, Button } from '@material-ui/core'
import SupportIcon from '@material-ui/icons/Support'

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar'
import { BrowserView, MobileView } from 'react-device-detect'

// project imports
import MenuList from './MenuList'
import LogoSection from '../LogoSection'
import { drawerWidth } from 'store/constant'
import StartCompanySetup from './StartCompanySetup'
import { setIsUserClickedOnTheWindow } from 'store/ui/ui.slice'
import { useDispatch } from 'react-redux'

import NavGroup from './MenuList/NavGroup'

import footerMenuItems from 'menu-items/Footer-navigation'

import talkToExpertIcon from 'assets/images/talk to expert.png'
import resourcesIcon from 'assets/images/book-open.png'
import NoteToExpertPage from 'ui-component/company-setup-modal/NoteToExpertPage'
import TalkToAnExperDrawer from 'ui-component/company-setup-modal/TalkToAnExpertDrawer'

// style constant
const useStyles = makeStyles(theme => ({
  drawer: {
    // backgroundColor: theme.palette.background.sidebar,
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  drawerPaper: {
    borderRight: '1px solid #CFD0D9',
    padding: 0,
    width: drawerWidth,
    background: theme.palette.background.sidebar,
    color: theme.palette.text.primary
    // [theme.breakpoints.up('md')]: {
    //   top: '30px'
    // }
  },
  ScrollHeight: {
    height: 'calc(100vh - 80px)',
    paddingLeft: '16px',
    // paddingRight: '16px',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 56px)'
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  companySelector: {
    [theme.breakpoints.up('sm')]: { display: 'none' }
  },
  boxContainer: {
    backgroundColor: theme.palette.background.sidebar,
    display: 'flex',
    justifyContent: 'center',
    padding: '16px'
    // marginLeft: 'auto',
    // marginRight: 'auto'
  },
  navButton: {
    fontSize: 25,
    borderRadius: 20,
    cursor: 'pointer',
    '&:hover': {
      background: '#0068c9',
      color: 'white'
    }
  }
}))

// ===========================|| SIDEBAR DRAWER ||=========================== //

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
  const classes = useStyles()
  const theme = useTheme()
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const dispatch = useDispatch()
  const [isTalkToAnExpertClicked, setTalkToAnExpertClicked] = React.useState(false)

  const drawer = (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '32px', marginTop: '10px' }}>
        <div className={classes.boxContainer}>
          <LogoSection />
        </div>
      </Box>
      <BrowserView>
        <PerfectScrollbar component="div" className={classes.ScrollHeight}>
          <div className={classes.companySelector}>
            {/* <StartCompanySetup /> */}
            {/* <SelectCompanyDropdown /> */}
            {/* <LogoSection /> */}
          </div>
          {/* <LogoSection /> */}
          <MenuList />
          <Grid>
            <Divider style={{ background: '#CFD0D9', width: '90%' }} />
            <Divider style={{ border: 'solid 1px #CFD0D9', width: '90%', marginBottom: '10px' }} />
            <Button onClick={() => setTalkToAnExpertClicked(true)} variant="text">
              <SupportIcon />
              <p style={{ marginLeft: '10px' }}>Talk to an expert</p>
            </Button>
            <Button variant="text">
              <a
                href="https://support.mrgn.ai/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}
              >
                <img src={resourcesIcon} alt="Resources" />
                <p style={{ color: 'black', marginLeft: '10px' }}>Resources</p>
              </a>
            </Button>
            <TalkToAnExperDrawer isTalkToAnExpertClicked={isTalkToAnExpertClicked} setTalkToAnExpertClicked={setTalkToAnExpertClicked} />
          </Grid>
        </PerfectScrollbar>
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          <div className={classes.companySelector}>{/* <StartCompanySetup /> */}</div>
          <MenuList />
        </Box>
      </MobileView>
    </>
  )

  const container = window !== undefined ? () => window().document.body : undefined

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Drawer
        container={container}
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        classes={{
          paper: classes.drawerPaper
        }}
        // onClick={() => dispatch(setIsUserClickedOnTheWindow(false))}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </nav>
  )
}

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object
}

export default Sidebar
