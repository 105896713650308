import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import teenyiconsBuilding from '../../assets/images/teenyicons_building-outline.png'
import meIcon from '../../assets/images/la_meh-solid.png'
import expensesIcon from '../../assets/images/solar_dollar-linear.png'
import revenueIcon from '../../assets/images/solar_money-bag-linear.png'
import eMeIcon from '../../assets/images/enabled_me.png'
import eExpensesIcon from '../../assets/images/enabled_expenses.png'
import eRevenueIcon from '../../assets/images/enabled_revenue.png'

const initialState = {
  companySetupModalVisible: false,
  debtSetupModalVisible: false,
  burRateInitialModelVisible: false,
  excelImportTabVisible: false,
  excelTabModelCount: 0,
  insightsInitialModelVisible: false,
  equityRaiseInitialModelVisible: false,
  equityEditButtonClicked: false,
  equityRaiseInitialModelScreen: 0,
  paymentSetupModelVisible: false,
  burnRateModalVisible: false,
  modelsScreen: 0,
  companySetupModalScreen: 0,
  registrationSetupModelScreen: 0,
  equityRaiseTranchCount: 0,
  companyInfoScreen: 0,
  isUserClickedOnTheWindow: false,
  tranchesLists: [
    { id: 'tranch_one', amount: '', date: '' },
    { id: 'tranch_two', amount: '', date: '' },
    { id: 'tranch_three', amount: '', date: '' },
    { id: 'tranch_four', amount: '', date: '' },
    { id: 'tranch_five', amount: '', date: '' },
    { id: '', amount: '', date: '' }
  ],
  departmentLists: [
    { id: 'tranch_one', amount: '', date: '' },
    { id: 'tranch_two', amount: '', date: '' },
    { id: 'tranch_three', amount: '', date: '' },
    { id: 'tranch_four', amount: '', date: '' },
    { id: 'tranch_five', amount: '', date: '' },
    { id: '', amount: '', date: '' }
  ],
  companySetupModalTabOptions: [
    {
      title: 'COMPANY',
      navItems: [
        { label: 'Company', id: 0, visible: true },
        { label: 'Departments', id: 1, visible: true },
        { label: 'COA', id: 2, visible: true }
      ]
    },
    {
      title: 'MODEL',
      navItems: [
        { label: 'Model Type', id: 3, visible: true },
        { label: 'Employees', id: 4, visible: true },
        { label: 'Contractors', id: 5, visible: true },
        { label: 'Vendors/Expenses', id: 6, visible: true },
        { label: 'Revenue', id: 7, visible: true },
        { label: 'Additional Info', id: 8, visible: false },
        { label: 'Clients', id: 9, visible: false },
        { label: 'Products', id: 10, visible: false },
        { label: 'Clients', id: 11, visible: false },
        { label: 'Products', id: 12, visible: false },
        { label: 'Products (SaaS)', id: 13, visible: false }
      ]
    }
  ],
  companySetupModalTabOptionsSection1: [
    { label: 'Welcome', id: 0, visible: true, description: 'Step description' },
    { label: 'Company', id: 1, visible: true, description: 'Step description' },
    { label: 'Departments', id: 2, visible: true, description: 'Step description' },
    { label: 'Expenses', id: 3, visible: true, description: 'Step description' },
    { label: 'Completed', id: 4, visible: true, description: 'Step description' }
  ],
  companySetupModalTabOptionsSection2: [
    { label: 'Company', id: 1, visible: true, description: 'Basic company info' },
    { label: 'Departments', id: 2, visible: true, description: 'List of departments/functions' },
    { label: 'Expenses', id: 3, visible: true, description: 'Categories & amounts' },
    { label: 'Team', id: 4, visible: true, description: 'Employees & contractors' },
    { label: 'Revenue', id: 5, visible: true, description: 'Sales & income' },
    { label: 'Review', id: 9, visible: true, description: 'Confirm & go!' }
  ],
  selectedMenuItemId: ''
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleCompanySetupModal: (state, action) => {
      state.companySetupModalVisible = action.payload
    },
    toggleDebtSetupModal: state => {
      state.debtSetupModalVisible = !state.debtSetupModalVisible
    },
    toggleBurnRateModal: state => {
      state.burnRateModalVisible = !state.burnRateModalVisible
    },
    toggleBurnRateInitialModel: state => {
      state.burRateInitialModelVisible = !state.burRateInitialModelVisible
    },
    toggleExcelImportTabModel: state => {
      state.excelImportTabVisible = !state.excelImportTabVisible
    },
    setExcelImportTabModel: (state, action) => {
      state.excelTabModelCount = action.payload
    },
    setEquityEditButtonClicked: (state, action) => {
      state.equityEditButtonClicked = action.payload
    },

    toggleEquityRaiseInitialModelVisible: state => {
      state.equityRaiseInitialModelVisible = !state.equityRaiseInitialModelVisible
    },
    toggleInsightsInitialModelVisible: state => {
      state.insightsInitialModelVisible = !state.insightsInitialModelVisible
    },
    setEquityRaiseInitialModelScreen: (state, action) => {
      state.equityRaiseInitialModelScreen = action.payload
    },
    setTranchListData: (state, action) => {
      state.tranchesLists = action.payload
    },
    setDepartmentListData: (state, action) => {
      state.departmentLists = action.payload
    },
    setEquityRaiseTranchCount: (state, action) => {
      state.equityRaiseTranchCount = action.payload
    },

    clearEquityRaise: state => {
      state.tranchesLists = [
        { id: 'tranch_one', amount: '', date: '' },
        { id: 'tranch_two', amount: '', date: '' },
        { id: 'tranch_three', amount: '', date: '' },
        { id: 'tranch_four', amount: '', date: '' },
        { id: 'tranch_five', amount: '', date: '' },
        { id: '', amount: '', date: '' }
      ]
    },
    togglePaymentSetupModel: state => {
      state.paymentSetupModelVisible = !state.paymentSetupModelVisible
    },
    setCompanySetupModalScreen: (state, action) => {
      state.companySetupModalScreen = action.payload
    },
    setRegistrationSetupModelScreen: (state, action) => {
      state.registrationSetupModelScreen = action.payload
    },
    setPaymentSetupModalScreen: (state, action) => {
      state.companyInfoScreen = action.payload
    },
    setModelPeopleScreenVisible: (state, action) => {
      state.modelsScreen = action.payload
    },
    clearPaymentState: state => {
      state.companyInfoScreen = 0
    },
    setSelectedMenuItem: (state, action) => {
      state.selectedMenuItemId = action.payload
    },
    setIsUserClickedOnTheWindow: (state, action) => {
      state.isUserClickedOnTheWindow = action.payload
    },

    updateModalTabVisibility: (state, action) => {
      const { payload } = action
      const tabOptions = [...state.companySetupModalTabOptions]
      state.companySetupModalTabOptions = tabOptions
      // const h = _.findIndex(tabOptions[1].navItems, { id: 8 })
      // const additionalInfoTab = tabOptions[1].navItems[h]
      // const i = _.findIndex(tabOptions[1].navItems, { id: 9 })
      // const topLineBillableClientTab = tabOptions[1].navItems[i]
      // const j = _.findIndex(tabOptions[1].navItems, { id: 10 })
      // const detailedBillableProductTab = tabOptions[1].navItems[j]
      // const k = _.findIndex(tabOptions[1].navItems, { id: 11 })
      // const detailedBillableClientTab = tabOptions[1].navItems[k]
      // const l = _.findIndex(tabOptions[1].navItems, { id: 12 })
      // const commerceRetailTab = tabOptions[1].navItems[l]
      // const m = _.findIndex(tabOptions[1].navItems, { id: 13 })
      // const saasProductsTab = tabOptions[1].navItems[m]
      // if (payload.revenueModel === 'Pro Services') {
      //   if (payload.revenueComputationType === 'Detailed Billable + Products') {
      //     detailedBillableProductTab.visible = true
      //     detailedBillableClientTab.visible = true
      //     topLineBillableClientTab.visible = false
      //   } else {
      //     topLineBillableClientTab.visible = true
      //     detailedBillableProductTab.visible = false
      //     detailedBillableClientTab.visible = false
      //   }
      //   additionalInfoTab.visible = false
      //   commerceRetailTab.visible = false
      //   saasProductsTab.visible = false
      //   tabOptions[1].navItems[h] = additionalInfoTab
      //   tabOptions[1].navItems[i] = topLineBillableClientTab
      //   tabOptions[1].navItems[j] = detailedBillableProductTab
      //   tabOptions[1].navItems[k] = detailedBillableClientTab
      //   tabOptions[1].navItems[l] = commerceRetailTab
      //   tabOptions[1].navItems[m] = saasProductsTab
      //   // state.companySetupModalTabOptions = tabOptions
      // }
      // if (payload.revenueModel === 'Commerce / Retail') {
      //   additionalInfoTab.visible = false
      //   commerceRetailTab.visible = true
      //   topLineBillableClientTab.visible = false
      //   detailedBillableProductTab.visible = false
      //   detailedBillableClientTab.visible = false
      //   saasProductsTab.visible = false
      //   tabOptions[1].navItems[h] = additionalInfoTab
      //   tabOptions[1].navItems[i] = topLineBillableClientTab
      //   tabOptions[1].navItems[j] = detailedBillableProductTab
      //   tabOptions[1].navItems[k] = detailedBillableClientTab
      //   tabOptions[1].navItems[l] = commerceRetailTab
      //   tabOptions[1].navItems[m] = saasProductsTab
      //   state.companySetupModalTabOptions = tabOptions
      // }
      // if (payload.revenueModel === 'Top Line') {
      //   additionalInfoTab.visible = true
      //   topLineBillableClientTab.visible = false
      //   commerceRetailTab.visible = false
      //   detailedBillableProductTab.visible = false
      //   detailedBillableClientTab.visible = false
      //   saasProductsTab.visible = false
      //   tabOptions[1].navItems[h] = additionalInfoTab
      //   tabOptions[1].navItems[i] = topLineBillableClientTab
      //   tabOptions[1].navItems[j] = detailedBillableProductTab
      //   tabOptions[1].navItems[k] = detailedBillableClientTab
      //   tabOptions[1].navItems[l] = commerceRetailTab
      //   tabOptions[1].navItems[m] = saasProductsTab
      //   state.companySetupModalTabOptions = tabOptions
      // }
      // if (payload.revenueModel === 'SaaS') {
      //   additionalInfoTab.visible = false
      //   topLineBillableClientTab.visible = false
      //   commerceRetailTab.visible = false
      //   detailedBillableProductTab.visible = false
      //   detailedBillableClientTab.visible = false
      //   saasProductsTab.visible = true
      //   tabOptions[1].navItems[h] = additionalInfoTab
      //   tabOptions[1].navItems[i] = topLineBillableClientTab
      //   tabOptions[1].navItems[j] = detailedBillableProductTab
      //   tabOptions[1].navItems[k] = detailedBillableClientTab
      //   tabOptions[1].navItems[l] = commerceRetailTab
      //   tabOptions[1].navItems[m] = saasProductsTab
      //   state.companySetupModalTabOptions = tabOptions
      // }
    }
  }
})

export const {
  toggleCompanySetupModal,
  toggleDebtSetupModal,
  debtSetupModalVisible,
  setCompanySetupModalScreen,
  setRegistrationSetupModelScreen,
  setPaymentSetupModalScreen,
  setModelPeopleScreenVisible,
  setExcelImportTabModel,
  clearPaymentState,
  updateModalTabVisibility,
  togglePaymentSetupModel,
  toggleBurnRateInitialModel,
  toggleEquityRaiseInitialModelVisible,
  toggleInsightsInitialModelVisible,
  setEquityRaiseTranchCount,
  setEquityRaiseInitialModelScreen,
  setTranchListData,
  setDepartmentListData,
  clearEquityRaise,
  toggleBurnRateModal,
  toggleExcelImportTabModel,
  companySetupModalTabOptionsSection1,
  companySetupModalTabOptionsSection2,
  setEquityEditButtonClicked,
  setSelectedMenuItem,
  setIsUserClickedOnTheWindow
} = uiSlice.actions
export const uiSelector = state => state.ui
export default uiSlice.reducer
