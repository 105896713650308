import PropTypes from 'prop-types'
import React from 'react'
import { useTheme } from '@material-ui/styles'
import { Box, Card, Grid, Typography } from '@material-ui/core'
import Chart from 'react-apexcharts'
import './bar-chart.css' // css overrides for apex charts

const DashboardBarChart = ({ title, chartData, chartSeries }) => {
  const theme = useTheme()

  return (
    <Card style={{ boxShadow: `1px 1px 1rem ${theme.palette.grey[300]}` }} sx={{ height: 1 }}>
      <Box sx={{ p: 3 }}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Typography variant="h4">{title}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Chart {...chartData} series={chartSeries} />
    </Card>
  )
}

DashboardBarChart.propTypes = {
  chartData: PropTypes.object,
  chartSeries: PropTypes.array,
  title: PropTypes.any
}

export default DashboardBarChart
