import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useSelector } from 'react-redux'
import PropTypes, { object } from 'prop-types'

import DeptAllocation from './deptAllocation'

import { Button, Grid, Typography, Box, Stepper, StepLabel, Step } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { companySelector } from 'store/company/company.slice'
import { uiSelector } from 'store/ui/ui.slice'
import { numberFormat } from 'utils/ui'
import TranchesAmount from './tranchesAmount'

const useStyles = makeStyles(theme => ({
  formContainer: {
    minWidth: '90%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  allocationButton: {
    width: '300px'
  },
  toBeAllocatedAmount: {
    fontWeight: 'bold',
    fontSize: '18px'
  }
}))

const departmentList = [
  { id: uuidv4(), name: 'General & Administrative', desc: 'generalAdministrative' },
  { id: uuidv4(), name: 'Finance', desc: 'finance' },
  { id: uuidv4(), name: 'Sales', desc: 'sales' },
  { id: uuidv4(), name: 'Marketing', desc: 'marketing' },
  { id: uuidv4(), name: 'Merchandising', desc: 'merchandising' },
  { id: uuidv4(), name: 'Product Management', desc: 'productManagement' },
  { id: uuidv4(), name: 'Engineering', desc: 'engineering' },
  { id: uuidv4(), name: 'Customer Support', desc: 'customerSupport' }
]

const EquityRaiseTabThree = forwardRef((props, ref) => {
  const { setAllDeptAllocatedList, tabList, setCanNavigateNextScreen, setTranchStepNo, setTobeAllocatedAmount } = props
  const { equityRaiseTranchCount, tranchesLists, equityEditButtonClicked } = useSelector(uiSelector)
  const [activeStep, setActiveStep] = useState(0)
  const [activeStepId, setActiveStepId] = useState(tabList[0].id)
  const { selectedCompany, companies, equityAllocationList } = useSelector(companySelector)
  const [prevDeptData, setPrevDeptData] = useState([])
  const [nextDeptData, setNextDeptData] = useState([
    [
      { id: departmentList[0].id, name: departmentList[0].name, amount: '', percentage: '' },
      { id: departmentList[1].id, name: departmentList[1].name, amount: '', percentage: '' },
      { id: departmentList[2].id, name: departmentList[2].name, amount: '', percentage: '' },
      { id: departmentList[3].id, name: departmentList[3].name, amount: '', percentage: '' },
      { id: departmentList[4].id, name: departmentList[4].name, amount: '', percentage: '' },
      { id: departmentList[5].id, name: departmentList[5].name, amount: '', percentage: '' },
      { id: departmentList[6].id, name: departmentList[6].name, amount: '', percentage: '' },
      { id: departmentList[7].id, name: departmentList[7].name, amount: '', percentage: '' }
    ],
    [
      { id: departmentList[0].id, name: departmentList[0].name, amount: '', percentage: '' },
      { id: departmentList[1].id, name: departmentList[1].name, amount: '', percentage: '' },
      { id: departmentList[2].id, name: departmentList[2].name, amount: '', percentage: '' },
      { id: departmentList[3].id, name: departmentList[3].name, amount: '', percentage: '' },
      { id: departmentList[4].id, name: departmentList[4].name, amount: '', percentage: '' },
      { id: departmentList[5].id, name: departmentList[5].name, amount: '', percentage: '' },
      { id: departmentList[6].id, name: departmentList[6].name, amount: '', percentage: '' },
      { id: departmentList[7].id, name: departmentList[7].name, amount: '', percentage: '' }
    ],
    [
      { id: departmentList[0].id, name: departmentList[0].name, amount: '', percentage: '' },
      { id: departmentList[1].id, name: departmentList[1].name, amount: '', percentage: '' },
      { id: departmentList[2].id, name: departmentList[2].name, amount: '', percentage: '' },
      { id: departmentList[3].id, name: departmentList[3].name, amount: '', percentage: '' },
      { id: departmentList[4].id, name: departmentList[4].name, amount: '', percentage: '' },
      { id: departmentList[5].id, name: departmentList[5].name, amount: '', percentage: '' },
      { id: departmentList[6].id, name: departmentList[6].name, amount: '', percentage: '' },
      { id: departmentList[7].id, name: departmentList[7].name, amount: '', percentage: '' }
    ],
    [
      { id: departmentList[0].id, name: departmentList[0].name, amount: '', percentage: '' },
      { id: departmentList[1].id, name: departmentList[1].name, amount: '', percentage: '' },
      { id: departmentList[2].id, name: departmentList[2].name, amount: '', percentage: '' },
      { id: departmentList[3].id, name: departmentList[3].name, amount: '', percentage: '' },
      { id: departmentList[4].id, name: departmentList[4].name, amount: '', percentage: '' },
      { id: departmentList[5].id, name: departmentList[5].name, amount: '', percentage: '' },
      { id: departmentList[6].id, name: departmentList[6].name, amount: '', percentage: '' },
      { id: departmentList[7].id, name: departmentList[7].name, amount: '', percentage: '' }
    ],
    [
      { id: departmentList[0].id, name: departmentList[0].name, amount: '', percentage: '' },
      { id: departmentList[1].id, name: departmentList[1].name, amount: '', percentage: '' },
      { id: departmentList[2].id, name: departmentList[2].name, amount: '', percentage: '' },
      { id: departmentList[3].id, name: departmentList[3].name, amount: '', percentage: '' },
      { id: departmentList[4].id, name: departmentList[4].name, amount: '', percentage: '' },
      { id: departmentList[5].id, name: departmentList[5].name, amount: '', percentage: '' },
      { id: departmentList[6].id, name: departmentList[6].name, amount: '', percentage: '' },
      { id: departmentList[7].id, name: departmentList[7].name, amount: '', percentage: '' }
    ]
  ])

  const deptAllocatedListx = [
    { id: departmentList[0].id, name: departmentList[0].name, amount: '', percentage: '' },
    { id: departmentList[1].id, name: departmentList[1].name, amount: '', percentage: '' },
    { id: departmentList[2].id, name: departmentList[2].name, amount: '', percentage: '' },
    { id: departmentList[3].id, name: departmentList[3].name, amount: '', percentage: '' },
    { id: departmentList[4].id, name: departmentList[4].name, amount: '', percentage: '' },
    { id: departmentList[5].id, name: departmentList[5].name, amount: '', percentage: '' },
    { id: departmentList[6].id, name: departmentList[6].name, amount: '', percentage: '' },
    { id: departmentList[7].id, name: departmentList[7].name, amount: '', percentage: '' }
  ]

  const [deptAllocatedList, setDeptAllocatedList] = useState([
    { id: departmentList[0].id, name: departmentList[0].name, amount: '', percentage: '' },
    { id: departmentList[1].id, name: departmentList[1].name, amount: '', percentage: '' },
    { id: departmentList[2].id, name: departmentList[2].name, amount: '', percentage: '' },
    { id: departmentList[3].id, name: departmentList[3].name, amount: '', percentage: '' },
    { id: departmentList[4].id, name: departmentList[4].name, amount: '', percentage: '' },
    { id: departmentList[5].id, name: departmentList[5].name, amount: '', percentage: '' },
    { id: departmentList[6].id, name: departmentList[6].name, amount: '', percentage: '' },
    { id: departmentList[7].id, name: departmentList[7].name, amount: '', percentage: '' }
  ])

  const selectedDeptList = equityAllocationList?.find(eachItem => eachItem.tranchName === activeStepId)

  useEffect(() => {
    if (equityEditButtonClicked) {
      setDeptAllocatedList(selectedDeptList?.departmentList.length > 0 ? selectedDeptList?.departmentList : deptAllocatedListx)
    }
  }, [selectedDeptList])

  const classes = useStyles()

  const activeTranchList = tranchesLists.find(eachItem => eachItem.id === activeStepId)

  const activeTranchAmount = activeTranchList.amount

  const totalAmountInEachTranches =
    +deptAllocatedList[0]?.amount +
    +deptAllocatedList[1]?.amount +
    +deptAllocatedList[2]?.amount +
    +deptAllocatedList[3]?.amount +
    +deptAllocatedList[4]?.amount +
    +deptAllocatedList[5]?.amount +
    +deptAllocatedList[6]?.amount +
    +deptAllocatedList[7]?.amount

  const totalPercentageInEachTranches =
    +deptAllocatedList[0]?.percentage +
    +deptAllocatedList[1]?.percentage +
    +deptAllocatedList[2]?.percentage +
    +deptAllocatedList[3]?.percentage +
    +deptAllocatedList[4]?.percentage +
    +deptAllocatedList[5]?.percentage +
    +deptAllocatedList[6]?.percentage +
    +deptAllocatedList[7]?.percentage

  // const arrayTotalAmountInEachTranches = deptAllocatedList.map(eachItem => eachItem.amount)

  // const totalAmountInEachTranches = arrayTotalAmountInEachTranches.reduce((x, y) => x + y)
  const percentageOfAmount = (totalPercentageInEachTranches / 100) * activeTranchAmount

  const toBeAllocated = activeTranchAmount - percentageOfAmount - totalAmountInEachTranches

  useEffect(() => {
    if (toBeAllocated === 0 && equityRaiseTranchCount === activeStep + 1) setCanNavigateNextScreen(true)
    setTobeAllocatedAmount(toBeAllocated)
  }, [toBeAllocated])

  // useEffect(() => {
  //   setDeptAllocatedList([])
  //   setDeptAllocatedList(...equityAllocationList[tranchStep].departmentList)
  // }, [equityEditButtonClicked])

  const onChangeAmountHandler = event => {
    setDeptAllocatedList(prevState =>
      prevState.map(eachItem => {
        if (eachItem.id === event.target.id) {
          return { ...eachItem, amount: Number(event.target.value.replace(/,/g, '')) }
        }
        return eachItem
      })
    )

    if (deptAllocatedList.map(item => item.amount)) {
      setDeptAllocatedList(prevState =>
        prevState.map(eachItem => {
          if (eachItem.id === event.target.id) {
            return { ...eachItem, percentage: '' }
          }
          return eachItem
        })
      )
    }
  }
  const onChangePercentageHandler = event => {
    setDeptAllocatedList(prevState =>
      prevState.map(eachItem => {
        if (eachItem.id === event.target.id) {
          return { ...eachItem, percentage: Number(event.target.value) }
        }
        return eachItem
      })
    )

    if (deptAllocatedList.map(item => item.percentage)) {
      setDeptAllocatedList(prevState =>
        prevState.map(eachItem => {
          if (eachItem.id === event.target.id) {
            return { ...eachItem, amount: '' }
          }
          return eachItem
        })
      )
    }
  }

  useEffect(() => {
    setAllDeptAllocatedList(prevState =>
      prevState.map(eachItem => {
        if (eachItem.tranchId === activeStepId) {
          return { ...eachItem, eachTranchDeptAllocatedList: deptAllocatedList }
        }
        return eachItem
      })
    )
  }, [deptAllocatedList])

  useEffect(() => {
    setTranchStepNo(activeStep)
  }, [activeStep])

  useImperativeHandle(ref, () => ({
    handleNext() {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
      setActiveStepId(tabList[activeStep < equityRaiseTranchCount ? activeStep + 1 : activeStep].id)
      setPrevDeptData(prevState => [...prevState, deptAllocatedList])
      if (!equityEditButtonClicked) {
        setDeptAllocatedList(prevState => prevState.map(eachItem => ({ ...eachItem, amount: '', percentage: '' })))
      }
      // setNextDeptData(prevState => [prevState[activeStep], deptAllocatedList])
      // if (!equityEditButtonClicked && prevDeptData.length === activeStep) {
      //   setDeptAllocatedList(prevState => prevState.map(eachItem => ({ ...eachItem, amount: '', percentage: '' })))
      // }
      // if (!equityEditButtonClicked && nextDeptData.length > 0) {
      //   setDeptAllocatedList(nextDeptData[activeStep])
      // }
    },
    handleBack() {
      setActiveStep(prevActiveStep => prevActiveStep - 1)
      setActiveStepId(tabList[activeStep < equityRaiseTranchCount ? activeStep - 1 : activeStep].id)
      setDeptAllocatedList(prevDeptData[activeStep < equityRaiseTranchCount ? activeStep - 1 : activeStep])
      // setNextDeptData(prevState => [prevState[activeStep], deptAllocatedList])
    }
  }))

  //

  const requireTabList = []

  for (let i = 0; i < equityRaiseTranchCount; i += 1) {
    requireTabList.push(tabList[i])
  }

  const getAlocatedDeptList = () =>
    deptAllocatedList.map(eachData => (
      <DeptAllocation
        key={eachData.id}
        deptDetails={eachData}
        percentageAmount={activeTranchAmount}
        onChangeAmountHandler={onChangeAmountHandler}
        onChangePercentageHandler={onChangePercentageHandler}
        equityRaiseTranchAmount={tranchesLists}
      />
    ))

  const totalAmounts = (() => {
    let totalAmount = 0
    let totalPercent = 0
    deptAllocatedList?.forEach(item => {
      if (item.amount) {
        totalAmount += item.amount
        totalPercent += (item.amount / activeTranchAmount) * 100
      } else if (item.percentage) {
        totalPercent += item.percentage
        totalAmount += (item.percentage / 100) * activeTranchAmount
      }
    })
    return { amount: totalAmount.toFixed(2), percentage: totalPercent.toFixed(2) }
  })()

  return (
    <Grid
      sx={{ overflow: 'hidden', minWidth: '100%', background: '#F0F0F0', mb: 0 }}
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid sx={{ minWidth: '100%' }} item direction="column" justifyContent="center" alignItems="center" container xs={10}>
        <form className={classes.formContainer}>
          <Grid sx={{ minWidth: '100%' }} container item xs={12} direction="column" justifyContent="center" alignItems="center">
            {equityRaiseTranchCount > 1 && (
              <Grid container item xs={12} direction="row" justifyContent="center" alignItems="center">
                <Typography sx={{ color: '#091A36', fontWeight: '600', fontSize: '16px', textAlign: 'center' }}>Allocations</Typography>
              </Grid>
            )}
            <Grid sx={{ minWidth: '100%' }} item xs={12} container direction="row" justifyContent="center" alignItems="center">
              <Grid container item direction="column" justifyContent="center" sx={{ width: '100%' }}>
                <Grid xs={12} container item direction="column" justifyContent="center" alignItems="center">
                  {equityRaiseTranchCount > 1 && (
                    <Grid xs={12} container item direction="row" justifyContent="center" sx={{ minWidth: '100%', pb: 2, pt: 2 }}>
                      <Grid container item direction="row" justifyContent="center" xs={12} sx={{ width: '100%' }}>
                        <Stepper activeStep={activeStep}>
                          {requireTabList.map(eachItem => (
                            <Step key={eachItem.id}>
                              <StepLabel>{eachItem.displayText}</StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </Grid>
                    </Grid>
                  )}
                  <>
                    <Typography variant="h4" sx={{ color: '#091A36', margin: '20px 0px 10px 0px' }}>
                      Amount to be allocated ${numberFormat(activeTranchAmount, 1)}
                    </Typography>
                    {toBeAllocated >= 0 ? null : (
                      <Typography variant="h5" sx={{ color: 'red', marginBottom: '10px' }}>
                        Please enter the value within the allocated amount
                      </Typography>
                    )}
                    <Grid container item justifyContent="center" alignItems="center" sx={{ minWidth: '100%' }}>
                      <Grid
                        container
                        item
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          width: '69%',
                          maxHeight: '290px',
                          overflow: 'auto',
                          scrollbarWidth: 'thin',
                          '&::-webkit-scrollbar': {
                            width: '0.4em'
                          },
                          '&::-webkit-scrollbar-track': {
                            background: '#f1f1f1'
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#888'
                          },
                          '&::-webkit-scrollbar-thumb:hover': {
                            background: '#555'
                          }
                        }}
                      >
                        <ul
                          style={{
                            listStyleType: 'none',
                            paddingLeft: '0px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '50em'
                          }}
                        >
                          {getAlocatedDeptList()}
                        </ul>
                      </Grid>
                      {/* <Typography variant="h5" sx={{ color: '#091A36' }}>
                          Amount to be <br /> allocated {toBeAllocated}
                        </Typography> */}
                    </Grid>
                    <Grid
                      container
                      item
                      direction="row"
                      alignItems="center"
                      xs={12}
                      sx={{ display: 'flex', width: '69%', marginTop: '10px' }}
                    >
                      <Grid item xs={6.5}>
                        <Typography variant="h4" sx={{ color: '#091A36', marginLeft: '25px' }}>
                          Total:
                        </Typography>
                      </Grid>
                      <Grid item xs={2.5}>
                        <Typography variant="h4" sx={{ color: '#091A36' }}>
                          ${numberFormat(Math.round(totalAmounts.amount), 1)}
                        </Typography>
                      </Grid>
                      <Grid item xs={2.5}>
                        <Typography variant="h4" sx={{ color: '#091A36', marginLeft: '10px' }}>
                          {Math.round(totalAmounts.percentage)}%
                        </Typography>
                      </Grid>
                    </Grid>
                    {/* <Grid container item direction="row" justifyContent="center" alignItems="center" xs={10}>
                      <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', pt: 2, width: '80%' }}>
                        {activeStep > 0 && (
                          <Button size="large" color="info" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                            Back
                          </Button>
                        )}
                        <Box sx={{ flex: '1 1 auto' }} />
                        {equityRaiseTranchCount > 1 && (
                          <Button
                            disabled={toBeAllocated !== 0 || equityRaiseTranchCount === activeStep + 1}
                            size="large"
                            color="info"
                            onClick={handleNext}
                          >
                            Next
                          </Button>
                        )}
                      </Grid>
                    </Grid> */}
                  </>
                </Grid>
              </Grid>
              {/* <Grid spacing={3} item container direction="row" justifyContent="center" alignItems="center">
                <Grid container item xs={6}>
                  <TextField sx={{ width: '300px' }} id="Department1" label="Department" variant="outlined" />
                </Grid>
                <Grid container item xs={6}>
                  <TextField sx={{ width: '300px' }} id="Amount1" label="Amount" variant="outlined" />
                </Grid>
              </Grid> */}
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
})

EquityRaiseTabThree.propTypes = {
  setAllDeptAllocatedList: PropTypes.any,
  tabList: PropTypes.any,
  setCanNavigateNextScreen: PropTypes.any,
  setTranchStepNo: PropTypes.any,
  setTobeAllocatedAmount: PropTypes.any
}

export default EquityRaiseTabThree
