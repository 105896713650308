/* eslint-disable prettier/prettier */
import {
  Box,
  Button,
  CardMedia,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
  Autocomplete,
  TextField
} from '@material-ui/core'
import React, { useEffect, forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import meTabImage from 'assets/images/me_tab_image.png'
import employeeIcon from 'assets/images/akar-icons_person.png'
import contractorIcon from 'assets/images/solar_bag-outline.png'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'
import LocationsList from 'data/LocationsList'
import axios from 'axios'

import NearMeIcon from '@material-ui/icons/NearMe'
import EastIcon from '@material-ui/icons/East'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import cssClasses from 'ui-component/company-setup-modal/infoTab.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { setRegistrationSetupModelScreen, uiSelector } from 'store/ui/ui.slice'
import { companySelector } from 'store/company/company.slice'
import { authSelector } from 'store/auth/auth.slice'
import { finishCompanyCreate, updateCompany } from 'store/company/company.actions'

import downArrowButton from 'assets/images/Vector 132.png'
import upArrowButton from 'assets/images/up_arrow.png'
import mrgnLogo from 'assets/images/mrgin-blue-logo.png'
import NumberFormat from 'react-number-format'

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import DoneIcon from '@material-ui/icons/Done'
import PlaceOutlined from '@material-ui/icons/PlaceOutlined'
import { makeStyles } from '@material-ui/styles'
import { updateUser } from 'store/auth/auth.actions'

const useStyles = makeStyles(theme => ({
  selectOption: {
    color: '#000',
    fontFamily: 'Raleway',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '125%',
    padding: '8px 16px',
    '&:hover': {
      color: '#29A364',
      background: '#F9F6FF'
    }
    // '&.Mui-selected': { backgroundColor: '#F9F6FF' }
  },
  inputFocused: {
    '& .MuiInputBase-input': { backgroundColor: '#ffff', fontSize: '16px' },
    '& .MuiInputBase-root': { backgroundColor: '#ffff', paddingTop: '0', paddingBottom: '0' },

    border: '1px solid  #d8d8d8',
    borderRadius: '4px',
    color: '#29A364',
    height: '42px'
  },

  inputNonfocused: {
    '& .MuiInputBase-input': { backgroundColor: '#ffff', color: '#B1B3B7' },
    '& .MuiInputBase-root': { backgroundColor: '#ffff', paddingTop: '0', paddingBottom: '0' },
    border: 'none'
  },
  inputFocusedDate: {
    '& .MuiInputBase-input , .MuiOutlinedInput-root ': { backgroundColor: '#ffff' }, border: '1px solid  #d8d8d8',
    borderRadius: '4px',
    color: '#29A364',
    height: '42px'
  },
  inputNonfocusedDate: { '& .MuiOutlinedInput-notchedOutline': { border: 'none' }, '& .MuiInputBase-input , .MuiOutlinedInput-root': { backgroundColor: '#ffff', color: '#B1B3B7' }, marginTop: '0', '& .MuiInputAdornment-root': { display: 'none' } },

  autoSelectStyle: { backgroundColor: '#fff', marginTop: '22px', width: '300px', height: '44px', border: 'none' },
  NonFocusautoSelectStyle: { backgroundColor: '#fff', width: '300px', height: '44px', border: 'none' }
}))

const MePage = props => {
  const classes = useStyles()
  const { isDemoCompany } = props

  const formRef = useRef(null)
  const dispatch = useDispatch()
  const inputFieldRef = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]

  const { registrationSetupModelScreen } = useSelector(uiSelector)
  const { updateCompanyError, id, departmentList, adminDepartment, selectedCompany, meTabInputs, isSuccess, incompleteCompanyId } =
    useSelector(companySelector)
  const { title, departmentName, startedWorkingOn, countryLocated, stateOrProvince, salary, classifiedAs, whatIDo } = meTabInputs
  const { user } = useSelector(authSelector)
  const [currentIndexNo, setCurrentIndexNo] = useState(1)
  const [departments, setDepartments] = useState([])
  const [department, setDepartment] = useState('')
  // const [departmentError, setDepartmentError] = useState('')
  // const [departmentNameError, setDepartmentNameError] = useState('')
  const [departmentObject, setDepartmentObject] = useState(adminDepartment || [])
  const [classifiedAss, setClassifiedAss] = useState('')
  const [departmentSelected, setDepartmentSelected] = useState(false)
  const [workAs, setWorkAs] = useState(false)
  const [focusOkButton, setFocusOkButton] = useState(false)
  // const [location, setLocation] = useState({ country: '', state: '' })
  const [formInitialValues, setformInitialValues] = useState({
    title: title || '',
    departmentName: departmentName || 'Marketing',
    startedWorkingOn: startedWorkingOn ? dayjs(startedWorkingOn) : '',
    countryLocated: countryLocated || '',
    stateOrProvince: stateOrProvince || '',
    classifiedAs: classifiedAs || '',
    whatIDo: whatIDo || '',
    submit: null,
    annualCompensation: salary?.toLocaleString('en-US') || ''
  })
  const [country, setCountry] = useState('')
  const [state, setState] = useState('')

  const [countryList, setCountryList] = useState([])
  const [stateList, setStateList] = useState([])
  const listOfCountries = countryList.map((country, index) => country.name)
  const listOfstates = stateList.map((state, index) => state.name)
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  }
  const APIkey = 'e9c73eb9b47c479dae72ac48b1fa13a7'

  const formMeInitialValues = {
    title: '',
    departmentName: 'Marketing',
    startedWorkingOn: '',
    countryLocated: '',
    stateOrProvince: '',
    annualCompensation: '',
    classifiedAs: '',
    whatIDo: '',
    submit: null
  }

  const initialDepartmentsList = [
    { id: uuidv4(), name: 'Marketing', description: '' },
    { id: uuidv4(), name: 'Sales', description: '' },
    { id: uuidv4(), name: 'Merchandising', description: '' },
    { id: uuidv4(), name: 'General & Administrative', description: '' },
    { id: uuidv4(), name: 'Finance', description: '' },
    { id: uuidv4(), name: 'Product Management', description: '' },
    { id: uuidv4(), name: 'Engineering', description: '' },
    { id: uuidv4(), name: 'Customer Support', description: '' }
  ]

  const getLocationInfo = async (latitude, longitude) => {
    const url = `https://api.opencagedata.com/geocode/v1/json?q=${latitude},${longitude}&key=${APIkey}`
    await fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.status.code === 200) {
          const locationData = data.results[0].components
          const { country, state } = locationData
          // setLocation({ country, state })
          setCountry(country)
          setState(state)
          if (country && state) {
            if (!countryLocated) formRef.current.setFieldValue('countryLocated', country)
            if (!stateOrProvince) formRef.current.setFieldValue('stateOrProvince', state)
            // setformInitialValues(prevState => ({
            //   ...prevState,
            //   countryLocated: country || '',
            //   stateOrProvince: state || ''
            // }))
          }
        } else {
          console.log('Reverse geolocation request failed.')
        }
      })
      .catch(error => console.error(error))
  }

  function success(pos) {
    const crd = pos.coords
    getLocationInfo(crd.latitude, crd.longitude)
  }



  function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`)
  }
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions.query({ name: 'geolocation' }).then(result => {
        if (result.state === 'granted') {
          // If granted then you can directly call your function here
          navigator.geolocation.getCurrentPosition(success, errors, options)
        } else if (result.state === 'prompt') {
          // If prompt then the user will be asked to give permission
          navigator.geolocation.getCurrentPosition(success, errors, options)
        } else if (result.state === 'denied') {
          // If denied then you have to show instructions to enable location
        }
      })
    } else {
      console.log('Geolocation is not supported by this browser.')
    }
  }, [])

  const COUNTRY_URL = 'https://countriesnow.space/api/v0.1/countries/positions'
  const STATE_URL = `https://countriesnow.space/api/v0.1/countries/states/q?country=${country}`
  const getCountries = async () => {
    const response = await fetch(`${COUNTRY_URL}`).then(response => response.json())
    const { data } = response
    setCountryList(data)
  }
  const getStates = async () => {
    try {
      const response = await fetch(`${STATE_URL}`).then(response => response.json())
      const { data } = response
      setStateList(data.states)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getCountries()
  }, [])
  useEffect(() => {
    if (country) getStates()
  }, [country])

  useEffect(() => {
    if (adminDepartment) {
      adminDepartment.forEach(object => {
        if (object.userId === user.id) {
          setDepartment(object.department)
        }
      })
    }
    if (departmentList.length > 0) {
      setDepartments(departmentList)
    } else {
      setDepartments(initialDepartmentsList)
    }
  }, [departmentList])

  const departmentExist = () => departmentObject.some(el => el.userId === user.id)

  const updateDepartment = dept => {
    // setDepartment(dept)
    // let tempDepartment
    // if (departmentObject) {
    //   tempDepartment = departmentObject.map(object => {
    //     if (object.userId === user.id) {
    //       return { ...object, dept }
    //     }
    //     return object
    //   })
    // }
    // if (!departmentExist()) {
    const tempDepartment = [...departmentObject, { userId: user.id, department: dept, role: 'superAdmin' }]
    // }

    setDepartmentObject(tempDepartment)
  }

  // const getConvertedDate = date => {
  //   if (date) {
  //     return new Date(date).toLocaleDateString('en-US')
  //   }
  //   return new Date().toLocaleDateString('en-US')
  // }

  const onChangeClassifiedas = event => {
    formRef.current.setFieldValue('classifiedAs', event.target.value)
    setClassifiedAss(event.target.value)
    // setCurrentIndexNo(7)
    if (event.target.value === 'employee') {
      setCurrentIndexNo(7)
    } else {
      setCurrentIndexNo(8)
    }
  }

  const handleChangeDeptMent = event => {
    formRef.current.setFieldValue('departmentName', event.target.value)
    updateDepartment(event.target.value)
  }

  const handleAddMeTabInputs = (
    title,
    departmentName,
    startedWorkingOn,
    countryLocated,
    stateOrProvince,
    annualCompensation,
    classifiedAs,
    whatIDo
  ) => {
    // const salary = annualCompensation.toString().includes(',') ? parseInt(annualCompensation.replace(/,/g, ''), 10) : annualCompensation
    const salary = parseFloat(annualCompensation.replace(/[$,]/g, ''))

    const payload = {
      title,
      departmentName,
      startedWorkingOn: dayjs(startedWorkingOn).format('YYYY/MM'),
      countryLocated,
      stateOrProvince,
      salary,
      classifiedAs,
      whatIDo
    }

    console.log(payload, 'payload')

    const employeePayload = {
      name: 'E1',
      department: departmentName,
      title,
      startDate: dayjs(startedWorkingOn).format('YYYY/MM'),
      location: stateOrProvince,
      salary
    }

    const contractorPayload = {
      name: 'C1',
      purpose: title,
      department: departmentName,
      function: whatIDo,
      startDate: dayjs(startedWorkingOn).format('YYYY/MM'),
      location: stateOrProvince,
      annualCompensation: salary
    }

    let data

    if (classifiedAs === 'employee') {
      data = {
        id,
        departmentList: departments,
        userDepartment: departmentObject,
        meTabInputs: payload,
        employeeList: employeePayload
      }
    } else {
      data = {
        id,
        departmentList: departments,
        userDepartment: departmentObject,
        meTabInputs: payload,
        contractorList: contractorPayload
      }
    }

    dispatch(updateCompany(data))

    dispatch(updateUser({ userId: user.id, title: payload.title }))
  }
  // focusToFields
  // if (currentIndexNo === 1 && !inputFieldRef[0]?.current?.value) {
  //   setTimeout(() => {
  //     inputFieldRef[0]?.current?.focus()
  //   })
  // } else
  useEffect(() => {
    inputFieldRef[currentIndexNo - 1]?.current?.focus()
  }, [currentIndexNo])

  useEffect(() => {
    if (country) formRef.current.setFieldTouched('country', true)
  }, [country])

  const onClickOkButton = (fieldName, index) => {
    formRef.current.setFieldTouched(fieldName, true)
    if (inputFieldRef[currentIndexNo - 1]?.current?.value !== '' && !formRef.current.errors[fieldName]) {
      if (currentIndexNo <= 8) {
        if (currentIndexNo === 8) {
          if (formRef.current) {
            formRef.current?.handleSubmit()
          }
        }
        if (country !== 'United States' && country !== 'Canada' && currentIndexNo === 4) setCurrentIndexNo(6)
        else setCurrentIndexNo(prevState => prevState + 1)
      }
    } else {
      inputFieldRef[currentIndexNo - 1]?.current?.focus()
    }
  }

  const onClickNextButton = () => {
    // if (inputFieldRef[currentIndexNo - 1].current?.value !== '') {
    // if (currentIndexNo === 7 || currentIndexNo === 6) {
    if (formRef.current) {
      formRef.current?.handleSubmit()
    }
    // }
    // else {
    //     setCurrentIndexNo(prevState => prevState + 1)
    //   }
    // } else {
    //   inputFieldRef[currentIndexNo - 1]?.current?.focus()
    // }
  }

  const onClickBackButton = () => {
    if (currentIndexNo === 1) {
      dispatch(setRegistrationSetupModelScreen(1))
    } else if (country !== 'United States' && country !== 'Canada' && currentIndexNo === 6) setCurrentIndexNo(4)
    else setCurrentIndexNo(prevState => prevState - 1)
  }
  const getFocusOnNextBtn = () => {
    document.getElementById('submitButton').focus()
  }

  useEffect(() => {
    if (currentIndexNo === 7) getFocusOnNextBtn()
  }, [workAs])
  useEffect(() => {
    if (currentIndexNo === 2) document.getElementById('okButton2').focus()
    if (currentIndexNo === 7) document.getElementById('submitButton').focus()
  }, [focusOkButton])

  const handleKeyPress = (event, index) => {
    if (event.key === 'Enter' && index === 2) {
      // setCurrentIndexNo(3)
      onClickOkButton('departmentName', index)
    }
    if (event.key === 'Enter' && index === 7) {
      if (event.target.innerText === 'Employee') {
        setWorkAs(prev => !prev)
      }
      if (event.target.innerText === 'Contractor') {
        onClickOkButton('classifiedAs', index)
      }
    }
    if (event.charCode === 13) {
      event.preventDefault()
      // if (index === 4 || index === 5) onClickOkButton(event.target.id, index)
      onClickOkButton(event.target.id, index)
    }
  }
  const handleClick = (event, index) => {
    setFocusOkButton(prev => !prev)
  }
  const isButtonDisabled = () => {
    if (currentIndexNo === 7 && (classifiedAss === 'employee' || formRef.current?.values.classifiedAs === 'employee')) {
      return true
    }
    if (
      currentIndexNo === 8 &&
      inputFieldRef[currentIndexNo - 1].current?.value !== '' &&
      (classifiedAss === 'contractor' || formRef.current?.values.classifiedAs === 'contractor')
    ) {
      return true
    }
    return false
  }

  const getClassByIndex = fieldIndex => {
    const isPreviousIndex = currentIndexNo - 1 === fieldIndex || currentIndexNo - 2 === fieldIndex
    const isNextIndex = currentIndexNo + 1 === fieldIndex || currentIndexNo + 2 === fieldIndex

    switch (true) {
      case isPreviousIndex:
        return cssClasses.nonFocusedBoxTop
      case isNextIndex:
        return cssClasses.nonFocusedBoxBottom
      default:
        return cssClasses.box
    }
  }
  // useEffect(() => {
  //   inputFieldRef[0]?.current?.focus()
  //   // if (inputFieldRef[0].current) {
  //   //   inputFieldRef[0]?.current?.focus()
  //   // }
  // }, [])
  console.log(formRef?.current?.values.countryLocated, 'formRef values')

  useEffect(() => {
    if (formRef?.current?.values.countryLocated) setCountry(formRef.current.values.countryLocated)
  }, [])

  console.log(country, 'countr')

  return (
    <Grid container>
      <Grid
        item
        // xs={5}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'start',
          minHeight: '100vh',
          backgroundColor: '#E6FDFC',
          width: '40%'
        }}
      >
        <Grid
          item
          xs={9}
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '79px' }}
        >
          <img src={mrgnLogo} alt="MRGN_logo" style={{ marginBottom: '80px' }} />

          <Typography className={cssClasses.stepText}>Step 2 of 4</Typography>
          <Typography className={cssClasses.tabName1}>About Me</Typography>
          {/* <Typography className={cssClasses.tabDescription}>
            Building on the knowledge of your company we've acquired, we're committed to fine-tuning your experience. You have the ability
            to adjust these details at any point to better suit your preferences. 
          </Typography>
          <Typography sx={{ marginTop: '10px' }} className={cssClasses.tabDescription}>
            Let us know a bit more about you! This will allow us to customize your mrgn experience.
          </Typography> */}
          <Typography className={cssClasses.tabDescription}>
            Awesome. Now can you tell us a bit more about yourself? This will help us customize your mrgn experience.
          </Typography>
          <img src={meTabImage} alt="me_tab_image" style={{ height: 'auto', marginTop: '70px', width: '100%' }} />
          {/* <Typography className="information">The information shown is for illustrative purposes only.</Typography> */}
        </Grid>
      </Grid>

      <Grid
        item
        // xs={7}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', flexDirection: 'column', width: '60%' }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '320px',
            minHeight: '85vh',
            maxHeight: '85vh'
            // marginTop: '75px',
            // position: 'relative'
          }}
        >
          {' '}
          {/* {currentIndexNo <= 3 && ( */}
          {/* <Box sx={{ marginBottom: '20px' }}>
            <Typography className={cssClasses.tabRightHeading}>Who are you ?</Typography>
          </Box> */}
          {/* )} */}
          <Formik
            initialValues={formInitialValues}
            innerRef={formRef}
            enableReinitialize
            validationSchema={Yup.object().shape({
              title: Yup.string().required('Title is required'),
              departmentName: Yup.string().required('Department is required'),
              startedWorkingOn: Yup.date().required('Start date is required').max(new Date(), 'Start date cannot be a future date').typeError("Start date is required"),
              countryLocated: Yup.string().required('Country is required'),
              stateOrProvince: Yup.string().required('State is required'),
              annualCompensation: Yup.string()
                .max(30)
                .required('Annual gross compensation is required')
                .matches(/^(\$?\d{1,3}(,\d{3})*(\.\d+)?|\.\d+)$/, 'Enter a valid Amount'),
              classifiedAs: Yup.string().required('Select an option'),
              whatIDo: classifiedAss === 'contractor' ? Yup.string().required('This field is required') : Yup.string()
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting, setFieldError, resetForm }) => {
              if (isDemoCompany) {
                dispatch(setRegistrationSetupModelScreen(registrationSetupModelScreen + 1))
                return
              }

              handleAddMeTabInputs(
                values.title,
                values.departmentName,
                values.startedWorkingOn,
                values.countryLocated,
                values.stateOrProvince,
                values.annualCompensation,
                values.classifiedAs,
                values.whatIDo
              )

              dispatch(setRegistrationSetupModelScreen(registrationSetupModelScreen + 1))
              setStatus({ success: true })
              setSubmitting(false)
              // resetForm(formMeInitialValues)
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, isValid }) => (
              <>
                {/* <form noValidate onSubmit={handleSubmit} style={{ position: 'absolute', top: ' 50%', transform: 'translateY(-50%)' }}> */}
                <form noValidate onSubmit={handleSubmit} style={{ height: '100%', position: 'relative' }}>
                  <div style={{ height: '100px' }}>
                    <Button
                      onClick={onClickBackButton}
                      sx={{
                        // position: 'absolute',
                        // right: 630,
                        // top: 10,
                        borderRadius: '8px',
                        padding: 0,
                        backgroundColor: 'transparent',
                        color: '#131313',
                        fontFamily: 'Montserrat',
                        marginBottom: '32px'
                      }}
                      size="large"
                      variant="text"
                    >
                      {' '}
                      <KeyboardBackspaceIcon sx={{ marginRight: '10px' }} />
                      Back
                    </Button>
                    <Typography className={cssClasses.tabRightHeading}>Who are you ?</Typography>
                  </div>

                  {(currentIndexNo === 1 || currentIndexNo - 1 === 1) && (
                    <div className={currentIndexNo === 1 ? cssClasses.box : cssClasses.nonFocusedBoxTop}>
                      <label htmlFor="title" className={currentIndexNo === 1 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}>
                        1. My title is{' '}
                      </label>

                      <input
                        ref={ref => {
                          inputFieldRef[0].current = ref
                        }}
                        onKeyPress={event => handleKeyPress(event, 1)}
                        disabled={isDemoCompany}
                        placeholder="CEO"
                        // style={values.title === 'CEO' ? { color: '#858585' } : { backgroundColor: 'white' }}
                        className={currentIndexNo === 1 ? cssClasses.splInput : cssClasses.nonFocusedInputBox}
                        value={values.title}
                        name="title"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        id="title"
                      />
                      <div style={{ height: '10px' }}>
                        {touched.title && errors.title && <FormHelperText error> {errors.title} </FormHelperText>}
                      </div>

                      {currentIndexNo === 1 && (
                        <div className={cssClasses.okButtonContainer}>
                          <button
                            onClick={() => {
                              onClickOkButton('title')
                            }}
                            className={values.title ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                            type="button"
                          >
                            Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                          </button>
                          <p className={cssClasses.textPress}>
                            press <span className={cssClasses.textEnter}>Enter</span>
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                  {(currentIndexNo === 2 || currentIndexNo - 1 === 2) && (
                    <div className={getClassByIndex(2)}>
                      {/* {!(currentIndexNo === 4) && ( */}
                      <label
                        htmlFor="departmentName"
                        className={currentIndexNo === 2 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}
                      >
                        2. My department is{' '}
                      </label>
                      {/* )} */}
                      {(currentIndexNo === 2 || currentIndexNo === 3) && (
                        <Select
                          displayEmpty
                          disableUnderline
                          variant="standard"
                          sx={{
                            '& .MuiSelect-outlined': {
                              background: '#fff',
                              padding: '3px 14px'
                            }
                          }}
                          onKeyPress={event => handleKeyPress(event, 2)}
                          // getInputRef={ref => {
                          //   inputFieldRef[1].current = ref
                          // }}
                          inputRef={ref => {
                            inputFieldRef[1].current = ref
                          }}
                          className={currentIndexNo === 2 ? cssClasses.selectBox : cssClasses.nonFocusedSelectBox}
                          style={values.departmentName === 'Marketing' ? { color: '#858585' } : { backgroundColor: 'white' }}
                          placeholder="Select"
                          id="departmentName"
                          disabled={isDemoCompany}
                          value={values.departmentName}
                          name="departmentName"
                          onBlur={handleBlur}
                          onChange={event => {
                            handleChangeDeptMent(event)
                            // onClickOkButton(event.target.name)
                          }}
                        >
                          {initialDepartmentsList.map((option, i) => (
                            <MenuItem
                              className={classes.selectOption}
                              value={option.name}
                              id={option.id}
                              key={`taxYearEndOption-${i}`}
                              onKeyDown={event => handleKeyPress(event, 2)}
                              onClick={event => handleClick(event, 2)}
                            >
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}{' '}
                      <div style={{ height: '10px' }}>
                        {touched.departmentName && errors.departmentName && (
                          <FormHelperText error> {errors.departmentName} </FormHelperText>
                        )}
                      </div>
                      {currentIndexNo === 2 && (
                        <div className={cssClasses.okButtonContainer}>
                          <button
                            onClick={() => onClickOkButton('departmentName')}
                            className={values.departmentName ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                            type="button"
                            id="okButton2"
                          >
                            Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                          </button>
                          <p className={cssClasses.textPress}>
                            press <span className={cssClasses.textEnter}>Enter</span>
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                  {(currentIndexNo === 3 || currentIndexNo - 1 === 3) && (
                    // <div className={currentIndexNo === 3 ? cssClasses.box : cssClasses.nonFocusedBox}>
                    <div className={getClassByIndex(3)}>
                      {/* {!(currentIndexNo === 5) && ( */}
                      <label
                        style={{ width: '100%' }}
                        htmlFor="startedWorkingOn"
                        className={currentIndexNo === 3 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}
                      >
                        3. I started working here on{' '}
                      </label>
                      {/* )} */}
                      {(currentIndexNo === 3 || currentIndexNo === 4) && (
                        <div style={{ display: 'flex', alignItems: 'start', flexDirection: 'column' }}>
                          {/* <input
                              onKeyPress={event => handleKeyPress(event, 3)}
                            ref={ref => {
                              inputFieldRef[2].current = ref
                            }}
                            type="date"
                            name="startedWorkingOn"
                            value={values.startedWorkingOn}
                            onBlur={handleBlur}
                            id="startedWorkingOn"
                            placeholder="Select"
                            className={currentIndexNo === 3 ? cssClasses.selectBox : cssClasses.nonFocusedInputBox}
                            onChange={event => {
                              handleChange(event)
                            }}
                          /> */}
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              disableFuture
                              views={['month', 'year']}
                              inputRef={ref => {
                                inputFieldRef[2].current = ref
                              }}
                              name="startedWorkingOn"
                              value={values.startedWorkingOn}
                              onBlur={handleBlur}
                              placeholder="Select"
                              slotProps={{
                                textField: {
                                  // variant: 'standard',
                                  id: 'startedWorkingOn',
                                  size: "small",
                                  error: false,
                                  onKeyPress: event => {
                                    handleKeyPress(event, 3)
                                  }

                                }
                              }}
                              format="MM/YYYY"
                              sx={{
                                width: '300px',
                                height: '44px',
                                marginTop: '22px'
                              }}
                              onKeyPress={event => {
                                handleKeyPress(event, 3)
                              }}
                              // className={currentIndexNo === 3 ? cssClasses.selectBox : cssClasses.nonFocusedInputBox}
                              className={currentIndexNo === 3 ? classes.inputFocusedDate : classes.inputNonfocusedDate}
                              onChange={value => {
                                setFieldValue('startedWorkingOn', value)
                              }} />
                          </LocalizationProvider>
                          <div style={{ height: '10px' }}>
                            {touched.startedWorkingOn && errors.startedWorkingOn && (
                              <FormHelperText error> {errors.startedWorkingOn} </FormHelperText>
                            )}
                          </div>

                          {currentIndexNo === 3 && (
                            <div className={cssClasses.okButtonContainer}>
                              <button
                                onClick={() => onClickOkButton('startedWorkingOn')}
                                className={values.startedWorkingOn ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                                type="button"
                              >
                                Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                              </button>
                              <p className={cssClasses.textPress}>
                                press <span className={cssClasses.textEnter}>Enter</span>
                              </p>
                            </div>
                          )}
                        </div>
                      )}

                    </div>
                  )}
                  {(currentIndexNo === 4 || currentIndexNo + 1 === 4 || currentIndexNo - 1 === 4) && (
                    <div className={getClassByIndex(4)}>
                      {/* {!(currentIndexNo === 4) && ( */}
                      <label
                        htmlFor="countryLocated"
                        className={currentIndexNo === 4 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}
                      >
                        4. I’m located in{' '}
                      </label>
                      {/* )} */}
                      {/* {(currentIndexNo === 4 || currentIndexNo === 5) && (
                        <Select
                          IconComponent={currentIndexNo === 4 ? PlaceOutlined : ''}
                          displayEmpty
                          disableUnderline
                          variant="standard"
                          sx={{
                            '& .MuiSelect-outlined': {
                              background: '#fff',
                              padding: '3px 14px'
                            }
                          }}
                          onKeyPress={event => handleKeyPress(event, 4)}
                          ref={ref => {
                            inputFieldRef[3].current = ref
                          }}
                          className={currentIndexNo === 4 ? cssClasses.selectBox : cssClasses.nonFocusedSelectBox}
                          placeholder="Select"
                          id="country"
                          disabled={isDemoCompany}
                          value={values.countryLocated}
                          name="countryLocated"
                          onBlur={handleBlur}
                          onChange={e => {
                            handleChange(e)
                            setCountry(e.target.value)
                            setState('')
                            setFieldValue('stateOrProvince', '')
                          }}
                        >
                          <MenuItem disabled value="">
                            Select Country
                          </MenuItem>
                          <MenuItem className={classes.selectOption} value={location.country}>
                            {location.country}
                          </MenuItem>
                          {location.country === '' && <MenuItem value="United States">United States</MenuItem>}
                          {location.country === '' && <MenuItem value="India">India</MenuItem>}
                          {countryList.map(country => (
                            <MenuItem key={country.name} value={country.name} className={classes.selectOption}>
                              {country.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )} */}
                      {(currentIndexNo === 4 || currentIndexNo === 5 || (currentIndexNo === 6 && country !== 'United States' && country !== 'Canada')) && (
                        <Autocomplete
                          autoHighlight
                          autoComplete
                          popupIcon={currentIndexNo === 4 ? <PlaceOutlined /> : ''}
                          defaultValue=""
                          className={currentIndexNo === 4 ? classes.autoSelectStyle : classes.NonFocusautoSelectStyle}
                          name="countryLocated"
                          id="countryLocated"
                          options={listOfCountries}
                          getOptionLabel={option => option || ''}
                          filterOptions={(options, { inputValue }) =>
                            options.filter(option => option.toLowerCase().startsWith(inputValue.toLowerCase()))
                          }
                          value={values.countryLocated || ''}
                          renderInput={params => (
                            <TextField
                              onKeyPress={event => handleKeyPress(event, 4)}
                              inputRef={ref => {
                                inputFieldRef[3].current = ref
                              }}
                              placeholder="Select Country"
                              className={currentIndexNo === 4 ? classes.inputFocused : classes.inputNonfocused}
                              {...params}
                              sx={{
                                '& fieldset': { border: 'none' }
                              }}
                            />
                          )}
                          onBlur={handleBlur}
                          onChange={(e, value) => {
                            if (value !== null) {
                              setFieldValue('countryLocated', value)
                              setCountry(value)
                              setState('')
                              setFieldValue('stateOrProvince', '')
                            }
                            if (value === null) {
                              setFieldValue('countryLocated', '')
                              setCountry('')
                              setState('')
                              setFieldValue('stateOrProvince', '')
                            }
                            // onClickOkButton(e.target.name)
                          }}
                        />
                      )}

                      <div style={{ height: '10px' }}>
                        {touched.countryLocated && errors.countryLocated && (
                          <FormHelperText error> {errors.countryLocated} </FormHelperText>
                        )}
                      </div>

                      {currentIndexNo === 4 && (
                        <div className={cssClasses.okButtonContainer}>
                          <button
                            onClick={() => {
                              onClickOkButton('countryLocated')
                            }}
                            className={country || values.countryLocated ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                            type="button"
                          >
                            Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                          </button>
                          <p className={cssClasses.textPress}>
                            press <span className={cssClasses.textEnter}>Enter</span>
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                  {(currentIndexNo === 5 || currentIndexNo + 1 === 5 || currentIndexNo - 1 === 5) && (
                    <div className={getClassByIndex(5)}>
                      {/* {!(currentIndexNo === 4) && ( */}
                      <label
                        htmlFor="stateOrProvince"
                        className={currentIndexNo === 5 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}
                      >
                        5. In the State / Province of{' '}
                      </label>
                      {(currentIndexNo === 5 || currentIndexNo === 6) && (
                        <Autocomplete
                          autoHighlight
                          autoComplete
                          popupIcon={currentIndexNo === 5 ? <PlaceOutlined /> : ''}
                          defaultValue=""
                          className={currentIndexNo === 5 ? classes.autoSelectStyle : classes.NonFocusautoSelectStyle}
                          placeholder="Select"
                          id="stateOrProvince"
                          disabled={isDemoCompany}
                          value={values.stateOrProvince}
                          name="stateOrProvince"
                          onBlur={handleBlur}
                          onChange={(e, value) => {
                            if (value !== null) setFieldValue('stateOrProvince', value)
                            if (value === null) setFieldValue('stateOrProvince', '')
                            // onClickOkButton(e.target.name)
                          }}
                          options={listOfstates}
                          getOptionLabel={option => option}
                          filterOptions={(options, { inputValue }) =>
                            options.filter(option => option.toLowerCase().startsWith(inputValue.toLowerCase()))
                          }
                          renderInput={params => (
                            <TextField
                              inputRef={ref => {
                                inputFieldRef[4].current = ref
                              }}
                              onKeyPress={event => handleKeyPress(event, 5)}
                              placeholder={(values.countryLocated === 'United States' || values.countryLocated === 'Canada') ? 'Select State' : 'Select Region'}
                              className={currentIndexNo === 5 ? classes.inputFocused : classes.inputNonfocused}
                              {...params}
                              sx={{
                                '& fieldset': { border: 'none' }
                              }}
                            />
                          )}
                        />
                      )}
                      {/* )} */}

                      <div style={{ height: '10px' }}>
                        {touched.stateOrProvince && errors.stateOrProvince && (
                          <FormHelperText error> {errors.stateOrProvince} </FormHelperText>
                        )}
                      </div>

                      {currentIndexNo === 5 && (
                        <div className={cssClasses.okButtonContainer}>
                          <button
                            onClick={() => onClickOkButton('stateOrProvince')}
                            className={state || values.stateOrProvince ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                            type="button"
                          >
                            Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                          </button>
                          <p className={cssClasses.textPress}>
                            press <span className={cssClasses.textEnter}>Enter</span>
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                  {(currentIndexNo === 6 ||
                    currentIndexNo - 1 === 6
                    // ||(currentIndexNo === 4 && country !== 'United States')
                  ) && (
                      <div style={{ width: '600px' }} className={getClassByIndex(6)}>
                        <label
                          htmlFor="annualCompensation"
                          className={currentIndexNo === 6 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}
                        >
                          {(country !== 'United States' && country !== 'Canada') ? 5 : 6}. My annual gross compensation is:{' '}
                        </label>
                        {(currentIndexNo === 6 || currentIndexNo === 7) && (
                          <div className={currentIndexNo === 6 ? cssClasses.focusedInputDiv : cssClasses.nonFocusedInputDiv}>
                            {' '}
                            {/* <span className={currentIndexNo === 6 ? cssClasses.focusedInputStaticText : cssClasses.nonFocusedInputStaticText}>
                              $
                            </span> */}
                            <NumberFormat
                              prefix='$'
                              getInputRef={ref => {
                                inputFieldRef[5].current = ref
                              }}
                              onKeyPress={event => handleKeyPress(event, 6)}
                              disabled={isDemoCompany}
                              placeholder="Type $ here..."
                              className={currentIndexNo === 6 ? cssClasses.focusedInputBox : cssClasses.nonFocusedInputBox}
                              value={values.annualCompensation}
                              name="annualCompensation"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              id="annualCompensation"
                              style={{ margin: 0, paddingLeft: '20px' }}
                              thousandSeparator=","
                            />
                          </div>
                        )}
                        <div style={{ height: '10px' }}>
                          {touched.annualCompensation && errors.annualCompensation && (
                            <FormHelperText error> {errors.annualCompensation} </FormHelperText>
                          )}
                        </div>
                        {currentIndexNo === 6 && (
                          <div className={cssClasses.okButtonContainer}>
                            <button
                              onClick={() => onClickOkButton('annualCompensation')}
                              className={values.annualCompensation ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                              type="button"
                            >
                              Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                            </button>
                            <p className={cssClasses.textPress}>
                              press <span className={cssClasses.textEnter}>Enter</span>
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  {(currentIndexNo === 7 || currentIndexNo - 1 === 7) && (
                    <div className={getClassByIndex(7)}>
                      {/* {!(currentIndexNo === 4) && ( */}
                      <label
                        htmlFor="classifiedAs"
                        className={currentIndexNo === 7 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}
                      >
                        7. I work as a{' '}
                      </label>
                      {/* )} */}
                      {(currentIndexNo === 7 || currentIndexNo === 8) && (
                        <Select
                          sx={{
                            '& .MuiSelect-outlined': {
                              background: '#fff',
                              padding: '3px 14px'
                            }
                          }}
                          displayEmpty
                          disableUnderline
                          variant="standard"
                          onKeyPress={event => handleKeyPress(event, 7)}
                          inputRef={ref => {
                            inputFieldRef[6].current = ref
                          }}
                          className={currentIndexNo === 7 ? cssClasses.selectBox : cssClasses.nonFocusedSelectBox}
                          placeholder="Select"
                          id="classifiedAs"
                          disabled={isDemoCompany}
                          value={values.classifiedAs || ''}
                          name="classifiedAs"
                          onBlur={handleBlur}
                          onChange={onChangeClassifiedas}
                        >
                          {' '}
                          <MenuItem disabled value="">
                            Select
                          </MenuItem>
                          <MenuItem
                            className={classes.selectOption}
                            value="employee"
                            onKeyDown={event => handleKeyPress(event, 7)}
                            onClick={event => handleClick(event)}
                          >
                            Employee
                          </MenuItem>
                          <MenuItem className={classes.selectOption} value="contractor" onKeyDown={event => handleKeyPress(event, 7)}>
                            Contractor
                          </MenuItem>
                        </Select>
                      )}
                      <div style={{ height: '10px' }}>
                        {touched.classifiedAs && errors.classifiedAs && <FormHelperText error> {errors.classifiedAs} </FormHelperText>}
                      </div>
                      {values.classifiedAs !== 'contractor' && currentIndexNo === 7 && (
                        <div style={{ height: '50px', marginTop: '10px' }} />
                      )}
                      {currentIndexNo === 7 && values.classifiedAs === 'contractor' && !classifiedAss && (
                        <div className={cssClasses.okButtonContainer}>
                          <button
                            onClick={() => onClickOkButton('classifiedAs')}
                            className={values.classifiedAs ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                            type="button"
                          >
                            Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                          </button>
                          <p className={cssClasses.textPress}>
                            press <span className={cssClasses.textEnter}>Enter</span>
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                  {values.classifiedAs === 'contractor' && currentIndexNo === 8 && (
                    <div className={currentIndexNo === 8 ? cssClasses.box : cssClasses.nonFocusedBoxBottom}>
                      <label htmlFor="whatIDo" className={currentIndexNo === 8 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}>
                        {(country !== 'United States' && country !== 'Canada') ? 7 : 8
                        }. In fewer than four words, this is what I do at my company{' '}
                      </label>
                      {currentIndexNo === 8 && (
                        <input
                          ref={ref => {
                            inputFieldRef[7].current = ref
                          }}
                          onKeyPress={event => handleKeyPress(event, 8)}
                          type="text"
                          disabled={isDemoCompany}
                          placeholder="Type here..."
                          className={currentIndexNo === 8 ? cssClasses.focusedInputBox : cssClasses.nonFocusedInputBox}
                          value={values.whatIDo}
                          name="whatIDo"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          id="whatIDo"
                        />
                      )}
                      <div style={{ height: '10px' }}>
                        {touched.whatIDo && errors.whatIDo && <FormHelperText error> {errors.whatIDo} </FormHelperText>}
                      </div>
                      {currentIndexNo === 8 && <div style={{ height: '50px', marginTop: '10px' }} />}
                      {/* {currentIndexNo === 8 && (
                        <div className={cssClasses.okButtonContainer}>
                          <button
                            onClick={onClickOkButton}
                            className={values.whatIDo ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                            type="button"
                          >
                            Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                          </button>
                          <p className={cssClasses.textPress}>
                            press <span className={cssClasses.textEnter}>Enter</span>
                          </p>
                        </div>
                      )} */}
                    </div>
                  )}
                  <button
                    id="submitButton"
                    type="button"
                    className={isButtonDisabled() ? cssClasses.formNextButton : cssClasses.disabledFormNextButton}
                    onClick={onClickNextButton}
                  >
                    Next
                  </button>
                </form>
              </>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MePage
