import { useEffect, useRef, useState } from 'react'

function useClickHandlerWithDelay(delay) {
  const [disabled, setDisabled] = useState(false)
  const clickTimeoutRef = useRef(null)

  useEffect(
    () => () => {
      clearTimeout(clickTimeoutRef.current)
    },
    []
  )

  const handleClick = () => {
    if (!disabled) {
      setDisabled(true)

      // Enable the click handler after the specified delay
      clickTimeoutRef.current = setTimeout(() => {
        setDisabled(false)
      }, delay)
    }
  }

  return [handleClick, disabled]
}

export default useClickHandlerWithDelay
