import React, { useEffect, useRef, useState } from 'react'

import cssClasses from 'ui-component/company-setup-modal/infoTab.module.css'

import { Formik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { setRegistrationSetupModelScreen, uiSelector } from 'store/ui/ui.slice'
import { companySelector } from 'store/company/company.slice'
import { FormHelperText } from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'
import NumberFormat from 'react-number-format'
import { updateCompany } from 'store/company/company.actions'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  inputFocusedDate: {
    '& .MuiInputBase-input , .MuiOutlinedInput-root ': { backgroundColor: '#ffff' },
    border: '1px solid  #d8d8d8',
    borderRadius: '4px',
    color: '#29A364',
    height: '42px'
  },
  inputNonfocusedDate: {
    '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
    '& .MuiInputBase-input , .MuiOutlinedInput-root': { backgroundColor: '#ffff', color: '#B1B3B7' },
    marginTop: '0',
    '& .MuiInputAdornment-root': { display: 'none' }
  }
}))

const CommerceRetail = props => {
  const classes = useStyles()
  const { currentIndexNo, setCurrentIndex, revenueModelItem, revenueComputationTypeItem, isDemoCompany } = props
  const formRefCommerceRetail = useRef(null)
  const inputFieldRefCommer = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]
  const dispatch = useDispatch()
  const { registrationSetupModelScreen } = useSelector(uiSelector)
  const { id, productList, name } = useSelector(companySelector)
  const formRevenueCommerceRetailInitialValues = {
    _id: '',
    name: '',
    unitCOGS: '',
    price: '',
    growthRate: '',
    startingUnits: '',
    startDate: '',
    submit: null
  }
  const [formInitialValues, setformInitialValues] = useState(formRevenueCommerceRetailInitialValues)
  useEffect(() => {
    if (productList.length > 0) {
      const [{ name: productName, growthRate, startDate, startingUnits, unitCOGS, price }] = productList
      setformInitialValues(prev => ({
        ...prev,
        name: productName,
        unitCOGS: unitCOGS.toLocaleString('en-US'),
        price: price.toLocaleString('en-US'),
        startDate: dayjs(startDate),
        growthRate,
        startingUnits
      }))
    }
  }, [])

  // const formRevenueCommerceRetailInitialValues = {
  //   _id: '',
  //   name: '',
  //   unitCOGS: '',
  //   price: '',
  //   // growthIncrement: '',
  //   growthRate: '',
  //   startingUnits: '',
  //   startDate: null,
  //   submit: null
  // }

  const handleAddRevenueCommerceRetailTabInputs = (name, startDate, unitCOGS, price, startingUnits, growthRate) => {
    const payload = {
      name,
      startDate: dayjs(startDate).format('YYYY/MM'),
      unitCOGS: parseFloat(unitCOGS.replace(/[$,]/g, '')),
      price: parseFloat(price.replace(/[$,]/g, '')),
      startingUnits,
      growthIncrement: 'Monthly',
      growthRate
    }

    const productPayload = [...productList]
    productPayload.push(payload)
    if (id !== '') {
      const data = {
        id,
        revenueModel: revenueModelItem,
        revenueComputationType: revenueComputationTypeItem,
        productList: productPayload
      }
      dispatch(updateCompany(data))
    }
  }

  useEffect(() => {
    if (inputFieldRefCommer[0].current) {
      inputFieldRefCommer[0]?.current?.focus()
    }
  }, [])
  useEffect(() => {
    inputFieldRefCommer[currentIndexNo - 2]?.current?.focus()
  }, [currentIndexNo])

  const onClickCommerceRetailNextButton = () => {
    if (inputFieldRefCommer[currentIndexNo - 2].current?.value !== '') {
      if (currentIndexNo === 7) {
        if (formRefCommerceRetail.current) {
          formRefCommerceRetail.current?.handleSubmit()
        }
      } else {
        setCurrentIndex(prevState => prevState + 1)
      }
    } else {
      inputFieldRefCommer[currentIndexNo - 2]?.current?.focus()
    }
  }
  const onClickOkButton = fieldName => {
    formRefCommerceRetail.current.setFieldTouched(fieldName, true)
    if (inputFieldRefCommer[currentIndexNo - 2].current?.value !== '' && !formRefCommerceRetail.current.errors[fieldName]) {
      if (currentIndexNo === 7) {
        if (formRefCommerceRetail.current) {
          formRefCommerceRetail.current?.handleSubmit()
        }
      } else {
        setCurrentIndex(prevState => prevState + 1)
      }
    } else {
      inputFieldRefCommer[currentIndexNo - 2]?.current?.focus()
    }
  }
  const handleKeyPress = (event, index) => {
    if (event.charCode === 13) {
      event.preventDefault()
      // inputFieldRefCommer[index]?.current?.focus()
      onClickOkButton(event.target.name)
    }
  }
  const getClassByIndex = fieldIndex => {
    const isPreviousIndex = currentIndexNo - 1 === fieldIndex
    const isNextIndex = currentIndexNo + 1 === fieldIndex

    switch (true) {
      case isPreviousIndex:
        return cssClasses.nonFocusedBoxTop
      case isNextIndex:
        return cssClasses.nonFocusedBoxBottom
      default:
        return cssClasses.box
    }
  }

  const currentDate = new Date()
  return (
    <Formik
      initialValues={formInitialValues}
      innerRef={formRefCommerceRetail}
      enableReinitialize
      validationSchema={Yup.object().shape({
        name: Yup.string().required(' Product name is required'),
        startDate: Yup.date().required(' Start date is required').typeError('Start date is required'),
        unitCOGS: Yup.string().required('Product cost is required'),
        // .matches(/^\d+(\.\d+)?$/, 'Enter a valid Amount'),
        price: Yup.string().required('Selling price is required'),
        // .matches(/^\d+(\.\d+)?$/, 'Enter a valid Amount'),
        startingUnits: Yup.string()
          .required('Number of units is required')
          .matches(/^\d+(\.\d+)?$/, 'Enter a valid Number'),
        growthRate: Yup.string()
          .required('% Increment in sales is required')
          .matches(/^\d{1,4}(\.\d+)?%?$/, 'Enter a valid Number')
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting, setFieldError, resetForm }) => {
        handleAddRevenueCommerceRetailTabInputs(
          values.name,
          values.startDate,
          values.unitCOGS,
          values.price,
          values.startingUnits,
          values.growthRate
        )
        setStatus({ success: true })
        setSubmitting(false)
        dispatch(setRegistrationSetupModelScreen(registrationSetupModelScreen + 1))
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
        <form noValidate onSubmit={handleSubmit}>
          <div>
            {(currentIndexNo === 2 || currentIndexNo - 1 === 2) && (
              <div className={getClassByIndex(2)}>
                <label htmlFor="product_name" className={currentIndexNo === 2 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}>
                  {`1. One of the products that ${name} sells is`}{' '}
                </label>
                {(currentIndexNo === 2 || currentIndexNo === 3) && (
                  <input
                    ref={ref => {
                      inputFieldRefCommer[0].current = ref
                    }}
                    onKeyPress={event => handleKeyPress(event, 1)}
                    disabled={isDemoCompany}
                    placeholder="Type here..."
                    className={currentIndexNo === 2 ? cssClasses.focusedInputBox : cssClasses.nonFocusedInputBox}
                    value={values.name}
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    id="product_name"
                  />
                )}
                <div style={{ height: '10px' }}>
                  {touched.name && errors.name && <FormHelperText error> {errors.name} </FormHelperText>}{' '}
                </div>{' '}
                {currentIndexNo === 2 && (
                  <div className={cssClasses.okButtonContainer}>
                    <button
                      className={values.name ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                      type="button"
                      onClick={() => onClickOkButton('name')}
                    >
                      Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                    </button>
                    <p className={cssClasses.textPress}>
                      press <span className={cssClasses.textEnter}>Enter</span>
                    </p>
                  </div>
                )}
              </div>
            )}
            {(currentIndexNo === 3 || currentIndexNo - 1 === 3) && (
              <div className={getClassByIndex(3)}>
                <label htmlFor="startDate" className={currentIndexNo === 3 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}>
                  {`2. We started, or will start, selling ${values.name}`}{' '}
                </label>
                {(currentIndexNo === 3 || currentIndexNo === 4) && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <input
                      ref={ref => {
                        inputFieldRefCommer[1].current = ref
                      }}
                      style={{ fontSize: '26px' }}
                      onKeyPress={event => handleKeyPress(event, 2)}
                      type="date"
                      name="startDate"
                      value={values.startDate}
                      id="startDate"
                      placeholder="Select"
                      className={currentIndexNo === 3 ? cssClasses.focusedInputBox : cssClasses.nonFocusedInputBox}
                      onChange={event => setFieldValue('startDate', event.target.value)}
                    /> */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        views={['month', 'year']}
                        inputRef={ref => {
                          inputFieldRefCommer[1].current = ref
                        }}
                        name="startDate"
                        value={values.startDate}
                        onBlur={handleBlur}
                        placeholder="Select"
                        slotProps={{
                          textField: {
                            name: 'startDate',
                            id: 'startDate',
                            size: 'small',
                            error: false,
                            onKeyPress: event => {
                              handleKeyPress(event, 2)
                            }
                          }
                        }}
                        format="MM/YYYY"
                        sx={{
                          width: '300px',
                          height: '44px',
                          marginTop: '22px'
                        }}
                        onKeyPress={event => {
                          handleKeyPress(event, 3)
                        }}
                        // className={currentIndexNo === 3 ? cssClasses.selectBox : cssClasses.nonFocusedInputBox}
                        className={currentIndexNo === 3 ? classes.inputFocusedDate : classes.inputNonfocusedDate}
                        onChange={value => {
                          setFieldValue('startDate', value)
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                )}

                <div style={{ height: '10px' }}>
                  {touched.startDate && errors.startDate && <FormHelperText error> {errors.startDate} </FormHelperText>}
                </div>
                {currentIndexNo === 3 && (
                  <div className={cssClasses.okButtonContainer}>
                    <button
                      className={values.startDate ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                      type="button"
                      onClick={() => onClickOkButton('startDate')}
                    >
                      Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                    </button>
                    <p className={cssClasses.textPress}>
                      press <span className={cssClasses.textEnter}>Enter</span>
                    </p>
                  </div>
                )}
              </div>
            )}

            {(currentIndexNo === 4 || currentIndexNo - 1 === 4) && (
              <div className={getClassByIndex(4)}>
                <label htmlFor="product_cost" className={currentIndexNo === 4 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}>
                  {`3. This is how much it costs us to create a single unit of ${values.name} `}
                </label>
                {(currentIndexNo === 4 || currentIndexNo === 5) && (
                  <div className={currentIndexNo === 4 ? cssClasses.focusedInputDiv : cssClasses.nonFocusedInputDiv}>
                    {' '}
                    {/* <span className={currentIndexNo === 4 ? cssClasses.focusedInputStaticText : cssClasses.nonFocusedInputStaticText}>
                      $
                    </span> */}
                    <NumberFormat
                      prefix="$"
                      getInputRef={ref => {
                        inputFieldRefCommer[2].current = ref
                      }}
                      onKeyPress={event => handleKeyPress(event, 3)}
                      disabled={isDemoCompany}
                      style={{ marginTop: 0, paddingLeft: '15px' }}
                      placeholder="Type $ here..."
                      className={currentIndexNo === 4 ? cssClasses.focusedInputBox : cssClasses.nonFocusedInputBox}
                      value={values.unitCOGS}
                      name="unitCOGS"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      id="product_cost"
                      thousandSeparator=","
                    />
                  </div>
                )}
                <div style={{ height: '10px' }}>
                  {touched.unitCOGS && errors.unitCOGS && <FormHelperText error> {errors.unitCOGS} </FormHelperText>}{' '}
                </div>{' '}
                {currentIndexNo === 4 && (
                  <div className={cssClasses.okButtonContainer}>
                    <button
                      className={values.unitCOGS ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                      type="button"
                      onClick={() => onClickOkButton('unitCOGS')}
                    >
                      Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                    </button>
                    <p className={cssClasses.textPress}>
                      press <span className={cssClasses.textEnter}>Enter</span>
                    </p>
                  </div>
                )}
              </div>
            )}

            {(currentIndexNo === 5 || currentIndexNo - 1 === 5) && (
              <div className={getClassByIndex(5)}>
                <label
                  htmlFor="product_selling_price"
                  className={currentIndexNo === 5 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}
                >
                  {`4. We sell a single unit of ${values.name} for`}{' '}
                </label>
                {(currentIndexNo === 5 || currentIndexNo === 6) && (
                  <div className={currentIndexNo === 5 ? cssClasses.focusedInputDiv : cssClasses.nonFocusedInputDiv}>
                    {' '}
                    {/* <span className={currentIndexNo === 5 ? cssClasses.focusedInputStaticText : cssClasses.nonFocusedInputStaticText}>
                      $
                    </span> */}
                    <NumberFormat
                      prefix="$"
                      getInputRef={ref => {
                        inputFieldRefCommer[3].current = ref
                      }}
                      onKeyPress={event => handleKeyPress(event, 4)}
                      disabled={isDemoCompany}
                      style={{ marginTop: 0, paddingLeft: '15px' }}
                      placeholder="Type $ here..."
                      className={currentIndexNo === 5 ? cssClasses.focusedInputBox : cssClasses.nonFocusedInputBox}
                      value={values.price}
                      name="price"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      id="product_selling_price"
                      thousandSeparator=","
                    />
                  </div>
                )}

                <div style={{ height: '10px' }}>
                  {touched.price && errors.price && <FormHelperText error> {errors.price} </FormHelperText>}{' '}
                </div>
                {currentIndexNo === 5 && (
                  <div className={cssClasses.okButtonContainer}>
                    <button
                      className={values.price ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                      type="button"
                      onClick={() => onClickOkButton('price')}
                    >
                      Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                    </button>
                    <p className={cssClasses.textPress}>
                      press <span className={cssClasses.textEnter}>Enter</span>
                    </p>
                  </div>
                )}
              </div>
            )}

            {(currentIndexNo === 6 || currentIndexNo - 1 === 6) && (
              <div className={getClassByIndex(6)}>
                <label htmlFor="invoice" className={currentIndexNo === 6 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}>
                  {currentDate > new Date(values.startDate)
                    ? `5. Last month, we sold this many units of ${values.name}`
                    : `5. We plan to sell this many units of ${values.name} in our first month on sale`}
                </label>
                {(currentIndexNo === 6 || currentIndexNo === 7) && (
                  <input
                    ref={ref => {
                      inputFieldRefCommer[4].current = ref
                    }}
                    onKeyPress={event => handleKeyPress(event, 5)}
                    disabled={isDemoCompany}
                    placeholder="Type here..."
                    className={currentIndexNo === 6 ? cssClasses.focusedInputBox : cssClasses.nonFocusedInputBox}
                    value={values.startingUnits}
                    name="startingUnits"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    id="invoice"
                  />
                )}
                <div style={{ height: '10px' }}>
                  {touched.startingUnits && errors.startingUnits && <FormHelperText error> {errors.startingUnits} </FormHelperText>}{' '}
                </div>
                {currentIndexNo === 6 && (
                  <div className={cssClasses.okButtonContainer}>
                    <button
                      className={values.startingUnits ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                      type="button"
                      onClick={() => onClickOkButton('startingUnits')}
                    >
                      Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                    </button>
                    <p className={cssClasses.textPress}>
                      press <span className={cssClasses.textEnter}>Enter</span>
                    </p>
                  </div>
                )}
              </div>
            )}

            {(currentIndexNo === 7 || currentIndexNo - 1 === 7) && (
              <div className={currentIndexNo === 7 ? cssClasses.box : cssClasses.nonFocusedBoxBottom}>
                <label htmlFor="growth_rate" className={currentIndexNo === 7 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}>
                  {`6. We generally expect to increase sales of ${values.name} each month by`}{' '}
                </label>
                {(currentIndexNo === 7 || currentIndexNo === 8) && (
                  <div className={currentIndexNo === 7 ? cssClasses.focusedInputDiv : cssClasses.nonFocusedInputDiv}>
                    {/* <span
                      className={currentIndexNo === 7 ? cssClasses.focusedInputStaticTextRight : cssClasses.nonFocusedInputStaticTextRight}
                    >
                      %
                    </span> */}
                    <NumberFormat
                      suffix="%"
                      getInputRef={ref => {
                        inputFieldRefCommer[5].current = ref
                      }}
                      onKeyPress={event => handleKeyPress(event, 6)}
                      disabled={isDemoCompany}
                      style={{ marginTop: 0, paddingRight: '200px' }}
                      placeholder="Type % here..."
                      className={currentIndexNo === 7 ? cssClasses.focusedInputBox : cssClasses.nonFocusedInputBox}
                      value={values.growthRate}
                      name="growthRate"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      id="growth_rate"
                    />
                  </div>
                )}
                <div style={{ height: '10px' }}>
                  {touched.growthRate && errors.growthRate && <FormHelperText error> {errors.growthRate} </FormHelperText>}{' '}
                </div>
                {currentIndexNo === 7 && (
                  <div className={cssClasses.okButtonContainer}>
                    <button
                      className={values.growthRate ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                      type="button"
                      onClick={() => onClickOkButton('growthRate')}
                    >
                      Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                    </button>
                    <p className={cssClasses.textPress}>
                      press <span className={cssClasses.textEnter}>Enter</span>
                    </p>
                  </div>
                )}
              </div>
            )}

            {currentIndexNo > 1 && (
              <button
                type="button"
                className={currentIndexNo === 7 ? cssClasses.formNextButton : cssClasses.disabledFormNextButton}
                onClick={onClickCommerceRetailNextButton}
              >
                Next
              </button>
            )}
          </div>
        </form>
      )}
    </Formik>
  )
}

export default CommerceRetail
