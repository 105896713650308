import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  CardContent,
  Box,
  IconButton,
  Paper,
  TextField,
  Input
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '@material-ui/icons/Close'
import { Formik, Form, useFormik } from 'formik'
import * as Yup from 'yup'
import * as moment from 'moment'
import {
  uiSelector,
  toggleDebtSetupModal,
  togglePaymentSetupModel,
  toggleCompanySetupModal,
  setCompanySetupModalScreen
} from 'store/ui/ui.slice'
import {
  resetState,
  companySelector,
  hideIntroModal,
  setIntroModalOverride,
  clearState,
  resetCompanyLoadedStatus,
  setCompanyId
} from 'store/company/company.slice'
import { modelsSelector } from 'store/models/models.slice'
import { useTheme } from '@emotion/react'

import { createCompanyDebt, createCompany } from 'store/company/company.actions'
import { getModelDebtDataByID } from 'store/models/models.actions'
import PopupNotify from 'ui-component/tab/Model/PopupNotify'

import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { clearpromo, paymentSelector } from 'store/payment/payment.slice'
import CheckoutForm from './CheckoutForm'
import SetupForm from './setupForm'
import { authSelector, setNewUserWPLogin } from 'store/auth/auth.slice'
import { Button } from 'antd'
import { addPromoCode } from 'store/payment/payment.action'
import { LoadingButton } from '@material-ui/lab'
import { SNACKBAR_OPEN } from 'store/actions'

const useStyles = makeStyles(theme => ({
  input: {
    height: 40,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    padding: '10px',
    fontSize: '16px',
    outline: 'none',
    transition: 'border-color 0.1s ease',
    '&:focus': {
      borderColor: '#207E4D'
    }
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  muiDialog: {
    '& .MuiDialog-paper': {
      maxHeight: 'initial',
      height: 'calc(100% - 64px)'
    }
  },
  muiDialogContent: {
    [theme.breakpoints.up('sm')]: {
      overflowY: 'hidden',
      paddingRight: 20
    }
  },
  gridFillHeight: {
    [theme.breakpoints.up('sm')]: {
      height: '100%'
    }
  },
  gridFillHeightOverflowY: {
    [theme.breakpoints.up('sm')]: {
      height: '100%',
      overflowY: 'auto'
    }
  }
}))

const PaymentModel = () => {
  const theme = useTheme()
  const classes = useStyles()
  const [warning, setWarning] = useState(false)

  const dispatch = useDispatch()

  const { paymentSetupModelVisible } = useSelector(uiSelector)
  const { wpNewUser } = useSelector(authSelector)
  const { clientSecret, publishableKey, productPrice, earlyBirdStatus, promoCode, isLoading, promoErrorCode } = useSelector(paymentSelector)
  const { user } = useSelector(authSelector)
  const { selectedCompany, id } = useSelector(companySelector)
  const [ElementOptions, setElementOptions] = useState('')
  const [stripePromise, setStripePromise] = useState('')
  const [promo, setPromo] = useState('')
  const [promoError, setPromoError] = useState(false)

  let selectedCompanyId = null

  if (selectedCompany === '') {
    selectedCompanyId = id
  } else {
    selectedCompanyId = selectedCompany
  }

  useEffect(() => {
    if (promoErrorCode) setPromoError(true)
    else setPromoError(false)
  }, [promoErrorCode])

  useEffect(() => {
    if (publishableKey) {
      setStripePromise(loadStripe(publishableKey))
    }
    if (clientSecret) {
      const appearance = {
        theme: 'stripe'
      }
      setElementOptions({
        clientSecret,
        appearance
      })
    }
  }, [clientSecret, publishableKey])

  const handleInputChange = e => {
    if (e) setPromoError(false)
    setPromo(e.target.value)
  }

  const handleApplyClick = () => {
    if (promo) {
      dispatch(addPromoCode({ promoCode: promo, selectedCompany: selectedCompanyId, userId: user.id }))
      setPromo('')
    } else {
      setPromoError(true)
    }
  }

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        style={{ height: 'auto', maxHeight: 655, margin: 'auto' }}
        open={paymentSetupModelVisible}
        aria-labelledby="max-width-dialog-title"
        sx={{ py: 0 }}
        className={classes.muiDialog}
      >
        <DialogTitle id="max-width-dialog-title">
          Subscription
          <IconButton
            onClick={() => {
              setWarning(true)
            }}
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 24,
              top: 24,
              color: theme => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ py: 0 }} dividers className={classes.muiDialogContent}>
          <Grid container spacing={0} className={classes.gridFillHeight}>
            <Grid item xs={12} sm={12} className={classes.gridFillHeight}>
              {isLoading ? (
                ' '
              ) : (
                <Box sx={{ py: 2, pr: 3 }} className={classes.gridFillHeightOverflowY}>
                  {earlyBirdStatus ? (
                    <Box sx={{ fontWeight: 800, fontSize: 15, mt: 1, mb: 3, textAlign: 'left' }}>
                      * Enjoy 2 months free trial and 50% off on subscription plan for 10 months after trial exclusively for you.
                    </Box>
                  ) : (
                    <Box sx={{ fontWeight: 800, fontSize: 25, mt: 1, mb: 3, textAlign: 'center' }}>${productPrice}</Box>
                  )}
                  <div
                    className={classes.root}
                    style={{
                      paddingBottom: '10px'
                    }}
                  >
                    {!promoCode && (
                      <>
                        <div>
                          <Typography sx={{ fontSize: 14, color: '#30313D' }}>Promo code</Typography>

                          <input value={promo} onChange={handleInputChange} className={classes.input} />
                        </div>
                        <LoadingButton
                          onClick={handleApplyClick}
                          sx={{
                            backgroundColor: '#29A364',
                            color: '#fff',
                            mt: 2,
                            '&:hover': {
                              backgroundColor: '#29A364'
                            }
                          }}
                        >
                          Apply
                        </LoadingButton>
                      </>
                    )}
                    {promoCode && (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          p: 2,
                          ml: 3,
                          borderRadius: 8,
                          backgroundColor: 'white',
                          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)'
                        }}
                      >
                        {promoCode}
                        <Typography variant="h5" sx={{ ml: 2, fontWeight: 'bold', color: '#333' }}>
                          Coupon Applied!
                        </Typography>
                      </Box>
                    )}
                  </div>
                  {promoError && <Typography sx={{ color: 'red', pb: 2 }}>Enter valid promo code</Typography>}
                  {ElementOptions && (
                    <Elements options={ElementOptions} stripe={stripePromise}>
                      {earlyBirdStatus ? <SetupForm /> : <CheckoutForm />}
                    </Elements>
                  )}
                </Box>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <PopupNotify
        message="Are you sure you don’t want to proceed. Feel free to stick around and play with the free version"
        submitBtnText="Continue with free plan"
        dismissBtnText="Continue with paid plan"
        open={warning}
        onSubmit={() => {
          dispatch(togglePaymentSetupModel())
          dispatch(clearpromo())
          setWarning(false)
          if (wpNewUser) {
            dispatch(setCompanyId(selectedCompanyId))
            dispatch(toggleCompanySetupModal(true))
            dispatch(setCompanySetupModalScreen(0))
            dispatch(setNewUserWPLogin({ status: false }))
          }
        }}
        onDismiss={() => {
          setWarning(false)
          dispatch(clearpromo())
        }}
      />
    </>
  )
}

export default PaymentModel
