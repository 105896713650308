import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'

const SaaSFlatRate = () => (
  <Table sx={{ minWidth: '850px' }} aria-label="simple table">
    <TableHead>
      <TableRow>
        <TableCell sx={{ borderBottomColor: '#D6D0CE' }} />
        <TableCell sx={{ borderBottomColor: '#D6D0CE', borderRight: '1px solid #D6D0CE' }} align="center" colSpan={2}>
          <b style={{ color: '#091A36' }}>Jan</b>
        </TableCell>
        <TableCell sx={{ borderBottomColor: '#D6D0CE', borderRight: '1px solid #D6D0CE' }} align="center" colSpan={2}>
          <b style={{ color: '#091A36' }}>Feb</b>
        </TableCell>
        <TableCell sx={{ borderBottomColor: '#D6D0CE' }} align="center" colSpan={2}>
          <b style={{ color: '#091A36' }}>Mar</b>
        </TableCell>
      </TableRow>
    </TableHead>
    <TableHead>
      <TableRow>
        <TableCell sx={{ borderBottomColor: '#D6D0CE' }} />
        <TableCell sx={{ borderBottomColor: '#D6D0CE' }} align="center">
          <b style={{ color: '#091A36' }}>Customers</b>
        </TableCell>
        <TableCell sx={{ borderBottomColor: '#D6D0CE', borderRight: '1px solid #D6D0CE' }} align="center">
          <b style={{ color: '#091A36' }}>Revenue</b>
        </TableCell>
        <TableCell sx={{ borderBottomColor: '#D6D0CE' }} align="center">
          <b style={{ color: '#091A36' }}>Customers</b>
        </TableCell>
        <TableCell sx={{ borderBottomColor: '#D6D0CE', borderRight: '1px solid #D6D0CE' }} align="center">
          <b style={{ color: '#091A36' }}>Revenue</b>
        </TableCell>
        <TableCell sx={{ borderBottomColor: '#D6D0CE' }} align="center">
          <b style={{ color: '#091A36' }}>Customers</b>
        </TableCell>
        <TableCell sx={{ borderBottomColor: '#D6D0CE' }} align="center">
          <b style={{ color: '#091A36' }}>Revenue</b>
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      <TableRow>
        <TableCell component="th" scope="row">
          <b style={{ color: '#091A36' }}>Bundle 1</b>
        </TableCell>
        <TableCell align="center">10</TableCell>
        <TableCell sx={{ borderRight: '1px solid #D6D0CE' }} align="center">
          $1500
        </TableCell>
        <TableCell align="center">5</TableCell>
        <TableCell sx={{ borderRight: '1px solid #D6D0CE' }} align="center">
          $750
        </TableCell>
        <TableCell align="center">20</TableCell>
        <TableCell align="center">$3000</TableCell>
      </TableRow>
    </TableBody>
  </Table>
)

export default SaaSFlatRate
