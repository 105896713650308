import { Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { PopupModal } from 'react-calendly'

const Calendaly = () => {
  const [isCalendlyOpen, setCalendalyOpen] = useState(false)
  return (
    <div className="popup-widget">
      <Typography
        sx={{ fontSize: '16px', fontWeight: '500', color: '#1753DE', fontFamily: 'Raleway', cursor: 'pointer' }}
        onClick={() => setCalendalyOpen(true)}
      >
        Request a demo today
      </Typography>

      <PopupModal
        url="https://calendly.com/mrgn-yoni/30min"
        onModalClose={() => setCalendalyOpen(false)}
        open={isCalendlyOpen}
        rootElement={document.getElementById('root')}
      />
    </div>
  )
}

export default Calendaly
