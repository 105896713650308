import React, { lazy } from 'react'
import Loadable from 'ui-component/Loadable'
import MinimalLayout from 'layout/MinimalLayout'
import NavMotion from 'layout/NavMotion'
import GuestGuard from 'utils/route-guard/GuestGuard'

const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/forgotPassword')))

const ForgotPassword = {
  path: 'forgot-password',
  element: <MinimalLayout />,
  children: [
    {
      path: '/',
      element: (
        <NavMotion>
          <GuestGuard>
            <AuthRegister />
          </GuestGuard>
        </NavMotion>
      )
    }
  ]
}

export default ForgotPassword
