import React, { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setRegistrationSetupModelScreen, uiSelector } from 'store/ui/ui.slice'

import {
  Button,
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core'

import logoGreen from 'assets/images/mrgn-logo-green.svg'
import paymentPageImage from 'assets/images/payment_page_img.png'
import sparkles from 'assets/images/sparkles-sk.png'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'

import { Formik } from 'formik'
import * as Yup from 'yup'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import '../index.css'
import { authSelector } from 'store/auth/auth.slice'
import { clearstate, paymentSelector, setUserSelectedPlan } from 'store/payment/payment.slice'
import { companySelector } from 'store/company/company.slice'
import { getCompanyByID } from 'store/company/company.actions'
import { SNACKBAR_OPEN } from 'store/actions'
import { createCheckOutSession } from 'store/payment/payment.action'

import config from 'config'

const PaymentPage = () => {
  const formRef = useRef()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { registrationSetupModelScreen } = useSelector(uiSelector)
  const { user } = useSelector(authSelector)
  const { plan, sessionError, status, subscriptionStatus, isLoadingPayment } = useSelector(paymentSelector)
  const { selectedCompany, companies, userTier, paymentMode, upComingPlan, id } = useSelector(companySelector)

  const [isMonthOrYearButtonClicked, setMonthOrYearButttonClicked] = useState('monthly')
  const [selectedPlan, setSelectedPlan] = useState('')
  const [product, setProduct] = useState('')
  const [planPeriod, setPlanPeriod] = useState('')

  const formPaymentInitialValues = {}

  const onClickBackButton = () => {
    dispatch(setRegistrationSetupModelScreen(0))
  }

  const onClickNextButton = () => {
    dispatch(createCheckOutSession({ userId: user.id, product: selectedPlan, selectedCompany: id }))
    dispatch(setRegistrationSetupModelScreen(registrationSetupModelScreen + 1))
    // navigate(config.defaultPath, { replace: true })
  }

  useEffect(() => {
    if (id) {
      const data = {
        companyId: id,
        email: user.email
      }

      dispatch(getCompanyByID(data))
    }
  }, [dispatch, id, user])

  useEffect(() => {
    setPlanPeriod(paymentMode)
  }, [paymentMode])

  useEffect(() => {
    if (subscriptionStatus) {
      // dispatch(togglePaymentSetupModel())
      dispatch(clearstate())
    }
    if (sessionError) {
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: sessionError,
        variant: 'alert',
        alertSeverity: 'error'
      })
      dispatch(clearstate())
    }
    if (status) {
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: status,
        variant: 'alert',
        alertSeverity: 'success'
      })
      dispatch(clearstate())
      const data = {
        companyId: id,
        email: user.email
      }
      dispatch(getCompanyByID(data))
    }
    // if (plan) {
    // dispatch(updateUserTier(plan))

    // }
  }, [dispatch, plan, sessionError, status, subscriptionStatus])

  const getButtonText = id => {
    if (id === selectedPlan) {
      return (
        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CheckCircleOutlineIcon sx={{ fontSize: '20px', marginRight: '5px', fontWeight: 800 }} /> Selected{' '}
        </span>
      )
    }
    return 'Select'
  }

  return (
    <Grid container>
      <Grid
        item
        // xs={5}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'start',
          minHeight: '100vh',
          backgroundColor: '#FAF8F3',
          // height: '100%'
          width: '40%'
        }}
      >
        <Grid
          item
          xs={9}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            marginTop: '79px',
            width: '100%'
          }}
        >
          <img src={logoGreen} alt="MRGN_logo" style={{ marginBottom: '80px' }} />
          <Typography className="left-contaniner-main-h" sx={{ fontSize: '32', fontWeight: '600', fontFamily: 'Open Sans' }}>
            "Finally, a solution that understands the unique challenges of small businesses. Intelligent Business Planning has given us a
            competitive edge."
          </Typography>
          <Typography className="founder-name">
            - Bob Ross, <span className="founder-of"> Founder of Happy Trees Inc.</span>
          </Typography>
          <img src={paymentPageImage} alt="registration_image" style={{ height: 'auto', marginTop: '70px', width: '100%' }} />
          {/* <Typography className="information">The information shown is for illustrative purposes only.</Typography> */}
        </Grid>
      </Grid>

      <Grid
        item
        // xs={7}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          flexDirection: 'column',
          width: '60%'
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            minHeight: '90vh',
            marginTop: '79px'
          }}
        >
          <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            {/* <Button
              onClick={onClickBackButton}
              sx={{
                // position: 'absolute',
                // right: 630,
                // top: 10,
                borderRadius: '8px',
                padding: 0,
                backgroundColor: 'transparent',
                color: '#131313',
                fontFamily: 'Montserrat',
                marginBottom: '32px',
                alignSelf: 'start'
              }}
              size="large"
              variant="text"
            >
              {' '}
              <KeyboardBackspaceIcon sx={{ marginRight: '10px' }} />
              Back
            </Button> */}
            <Typography className="right-container-h">Simple pricing for your business</Typography>
            <Typography className="right-container-desc">
              Whether your time-saving automation needs are large or small, we’re here to help you scale.
            </Typography>
            <div className="pricing-button-cont">
              <button
                onClick={e => {
                  setSelectedPlan('')
                  setMonthOrYearButttonClicked('monthly')
                }}
                id="monthly"
                className={isMonthOrYearButtonClicked === 'monthly' ? 'active-button' : 'none-active-select-button'}
                type="button"
              >
                Monthly
              </button>
              <button
                onClick={e => {
                  setMonthOrYearButttonClicked('annualy')
                  setSelectedPlan('')
                }}
                id="annualy"
                className={isMonthOrYearButtonClicked === 'annualy' ? 'active-button' : 'none-active-select-button'}
                type="button"
              >
                Annually{' '}
                <span className={`span-text-save ${isMonthOrYearButtonClicked === 'annualy' ? 'span-active' : ''}`}>(Save 15%)</span>
              </button>
            </div>
          </Grid>
          <Formik
            initialValues={formPaymentInitialValues}
            validationSchema={Yup.object().shape({})}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
              try {
                const data = {
                  email: values.email,
                  password: values.password,
                  termsAndConditions: values.termsAndConditions
                }
              } catch (err) {
                setStatus({ success: false })
                setErrors({ submit: err.message })
              }
              setSubmitting(false)
              resetForm(formPaymentInitialValues)
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
              <form noValidate onSubmit={handleSubmit} style={{ width: '100%' }}>
                {isMonthOrYearButtonClicked === 'monthly' ? (
                  <Grid
                    item
                    xs={12}
                    style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-around', marginTop: '30px' }}
                  >
                    <Grid item xs={3.7} className={selectedPlan === 'starter_monthly' ? 'selected_plan_cont' : 'plan-container'}>
                      <div style={{ width: '95%' }}>
                        <h1 className="plan-name">Starter</h1>
                        <Typography className="plan-amount">
                          $19 <span className="plan-duration">/mo</span>
                        </Typography>
                        <Typography className="offer-month">
                          <span className="striked-text">$29</span> For the first 3 months, you pay $19!
                        </Typography>
                        <hr className="divider" />
                        <Typography className="plan-desc">
                          The starter plan is perfect for those who need MRGNs essential features. We recommend this plan for companies that
                          have &lt;$500k in revenue.
                        </Typography>
                        <hr className="divider" />
                        <ul className="list-container">
                          <li>
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} />1 user
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> Basic Simulations
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> 48 – Hour Response Time
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> 5 Employees
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> $500k Revenue
                          </li>
                        </ul>
                        <button
                          onClick={() => {
                            setSelectedPlan('starter_monthly')
                            dispatch(setUserSelectedPlan('starter_monthly'))
                          }}
                          className={selectedPlan === 'starter_monthly' ? 'select-button-active' : 'select-button-inactive'}
                          type="button"
                        >
                          {getButtonText('starter_monthly')}
                        </button>
                      </div>
                    </Grid>
                    <Grid item xs={3.7} className={selectedPlan === 'business_monthly' ? 'selected_plan_cont' : 'plan-container'}>
                      <div style={{ width: '95%' }}>
                        <h1 className="plan-name">Business</h1>
                        <Typography className="plan-amount">
                          $89 <span className="plan-duration">/mo</span>
                        </Typography>
                        <hr className="divider" />
                        <Typography className="plan-desc">
                          The business plan offers a balanced mix of features for companies that need more than the most basic capabilities.
                          We recommend this plan for companies that have $500k - $3m in revenue.
                        </Typography>
                        <hr className="divider" />
                        <div className="most-popular-cont">
                          <img src={sparkles} alt="sparkles" />
                          <p className="most-popular-text">Most Popular</p>
                          <img src={sparkles} alt="sparkles" />
                        </div>
                        <ul className="list-container">
                          <li>
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} />3 users
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> Advanced Simulations
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> 24 – Hour Response Time
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> 6-30 Employees
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> $500k - 3M Revenue
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> Integrations – Accounting
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> Task Delegation – Limited
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> Milestone Reminders (coming soon)
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> Benchmark Insights (coming soon)
                          </li>
                        </ul>
                        <button
                          onClick={() => {
                            setSelectedPlan('business_monthly')
                            dispatch(setUserSelectedPlan('business_monthly'))
                          }}
                          className={selectedPlan === 'business_monthly' ? 'select-button-active' : 'select-button-inactive'}
                          type="button"
                        >
                          {getButtonText('business_monthly')}
                        </button>
                      </div>
                    </Grid>
                    <Grid item xs={3.7} className={selectedPlan === 'growth_monthly' ? 'selected_plan_cont' : 'plan-container'}>
                      <div style={{ width: '95%' }}>
                        <h1 className="plan-name">Growth</h1>
                        <Typography className="plan-amount">
                          $129 <span className="plan-duration">/mo</span>
                        </Typography>
                        <hr className="divider" />
                        <Typography className="plan-desc">
                          The growth plan is designed for companies who want to take advantage of our most advanced features and premium
                          support. We recommend this plan for companies that have &gt;$3m in revenue.
                        </Typography>
                        <hr className="divider" />
                        <ul style={{ marginTop: '50px' }} className="list-container">
                          <li>
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} />
                            Unlimited users
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} />
                            Advanced Simulations
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> 8 – Hour Response Time
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> Unlimited Employees
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> High-Growth Revenue
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> Integrations – Accounting, CRM &
                            Project Management
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> Task Delegation – Full (coming soon)
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> Milestone Reminders (coming soon)
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> Benchmark Insights (coming soon)
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> Scheduled Reports (coming soon)
                          </li>
                        </ul>
                        <button
                          onClick={() => {
                            setSelectedPlan('growth_monthly')
                            dispatch(setUserSelectedPlan('growth_monthly'))
                          }}
                          className={selectedPlan === 'growth_monthly' ? 'select-button-active' : 'select-button-inactive'}
                          type="button"
                        >
                          {getButtonText('growth_monthly')}
                        </button>
                      </div>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={12}
                    style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-around', marginTop: '30px' }}
                  >
                    <Grid item xs={3.7} className={selectedPlan === 'starter_yearly' ? 'selected_plan_cont' : 'plan-container'}>
                      <div style={{ width: '95%' }}>
                        <h1 className="plan-name">Starter</h1>
                        <Typography className="plan-amount">
                          $295 <span className="plan-duration">/yr</span>
                        </Typography>

                        <hr className="divider" />
                        <Typography className="plan-desc">
                          The starter plan is perfect for those who need MRGNs essential features. We recommend this plan for companies that
                          have &lt;$500k in revenue.
                        </Typography>
                        <hr className="divider" />
                        <ul className="list-container">
                          <li>
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} />1 user
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> Basic Simulations
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> 48 – Hour Response Time
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> 5 Employees
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> $500k Revenue
                          </li>
                        </ul>
                        <button
                          onClick={() => {
                            setSelectedPlan('starter_yearly')
                            dispatch(setUserSelectedPlan('starter_yearly'))
                          }}
                          className={selectedPlan === 'starter_yearly' ? 'select-button-active' : 'select-button-inactive'}
                          type="button"
                        >
                          {getButtonText('starter_yearly')}
                        </button>
                      </div>
                    </Grid>
                    <Grid item xs={3.7} className={selectedPlan === 'business_yearly' ? 'selected_plan_cont' : 'plan-container'}>
                      <div style={{ width: '95%' }}>
                        <h1 className="plan-name">Business</h1>
                        <Typography className="plan-amount">
                          $907 <span className="plan-duration">/yr</span>
                        </Typography>
                        <hr className="divider" />
                        <Typography className="plan-desc">
                          The business plan offers a balanced mix of features for companies that need more than the most basic capabilities.
                          We recommend this plan for companies that have $500k - $3m in revenue.
                        </Typography>
                        <hr className="divider" />
                        <div className="most-popular-cont">
                          <img src={sparkles} alt="sparkles" />
                          <p className="most-popular-text">Most Popular</p>
                          <img src={sparkles} alt="sparkles" />
                        </div>
                        <ul className="list-container">
                          <li>
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} />3 users
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> Advanced Simulations
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> 24 – Hour Response Time
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> 6-30 Employees
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> $500k - 3M Revenue
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> Integrations – Accounting
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> Task Delegation – Limited
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> Milestone Reminders (coming soon)
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> Benchmark Insights (coming soon)
                          </li>
                        </ul>
                        <button
                          onClick={() => {
                            setSelectedPlan('business_yearly')
                            dispatch(setUserSelectedPlan('business_yearly'))
                          }}
                          className={selectedPlan === 'business_yearly' ? 'select-button-active' : 'select-button-inactive'}
                          type="button"
                        >
                          {getButtonText('business_yearly')}
                        </button>
                      </div>
                    </Grid>
                    <Grid item xs={3.7} className={selectedPlan === 'growth_yearly' ? 'selected_plan_cont' : 'plan-container'}>
                      <div style={{ width: '95%' }}>
                        <h1 className="plan-name">Growth</h1>
                        <Typography className="plan-amount">
                          $1315 <span className="plan-duration">/yr</span>
                        </Typography>
                        <hr className="divider" />
                        <Typography className="plan-desc">
                          The growth plan is designed for companies who want to take advantage of our most advanced features and premium
                          support. We recommend this plan for companies that have &gt;$3m in revenue.
                        </Typography>
                        <hr className="divider" />
                        <ul style={{ marginTop: '50px' }} className="list-container">
                          <li>
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} />
                            Unlimited users
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} />
                            Advanced Simulations
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> 8 – Hour Response Time
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> Unlimited Employees
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> High-Growth Revenue
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> Integrations – Accounting, CRM &
                            Project Management
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> Task Delegation – Full (Coming Soon)
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> Milestone Reminders (coming soon)
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> Benchmark Insights (coming soon)
                          </li>
                          <li>
                            {' '}
                            <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '5px' }} /> Scheduled Reports (coming soon)
                          </li>
                        </ul>
                        <button
                          onClick={() => {
                            setSelectedPlan('growth_yearly')
                            dispatch(setUserSelectedPlan('growth_yearly'))
                          }}
                          className={selectedPlan === 'growth_yearly' ? 'select-button-active' : 'select-button-inactive'}
                          type="button"
                        >
                          {getButtonText('growth_yearly')}
                        </button>
                      </div>
                    </Grid>
                  </Grid>
                )}
                <button
                  onClick={onClickNextButton}
                  disabled={selectedPlan === ''}
                  className={selectedPlan !== '' ? 'payment-tab-select-button-active' : 'payment-tab-select-button-not-active'}
                  type="button"
                >
                  Next
                </button>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PaymentPage
