import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import styles from '../../styles.module.css'
import noDataImage from 'assets/images/noDataImage.png'
import cssClasses from './infoTab.module.css'
import PropTypes from 'prop-types'
import {
  Grid,
  Button,
  Box,
  TextField,
  Fab,
  Divider,
  Card,
  CardHeader,
  Typography,
  IconButton,
  FormHelperText,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CardMedia,
  Paper,
  InputBase
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import UpdateIcon from '@material-ui/icons/Save'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import * as Yup from 'yup'
import { Formik } from 'formik'
import moment from 'moment'
import AdapterDateFns from '@material-ui/lab/AdapterMoment'
import LocalizationProvider from '@material-ui/lab/LocalizationProvider'

import { gridSpacing } from 'store/constant'
import ModalSubtitle from './ModalSubtitle'

import {
  uiSelector,
  setCompanySetupModalScreen,
  toggleCompanySetupModal,
  toggleExcelImportTabModel,
  setExcelImportTabModel,
  setSelectedMenuItem
} from 'store/ui/ui.slice'
import { finishCompanyCreate, getCompaniesByUser, getCompanyByID, updateCompany } from 'store/company/company.actions'
import {
  clearState,
  resetState,
  companySelector,
  addProduct,
  deleteProduct,
  updateProduct,
  setIsWizardVisible
} from 'store/company/company.slice'
import { SNACKBAR_OPEN, MENU_OPEN } from 'store/actions'
import { DatePicker, LoadingButton, MobileDatePicker } from '@material-ui/lab'
import NumberFormat from 'react-number-format'
import { useNavigate } from 'react-router'
import PopupNotify from 'ui-component/tab/Model/PopupNotify'
import { numberFormat } from 'utils/ui'
import { makeStyles } from '@material-ui/styles'
// import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import excelWithA from 'assets/images/excelWithA.png'
import excel from 'assets/images/excel.png'
import { authSelector } from 'store/auth/auth.slice'
import SaaSProductsDrawer from './SaaSProductsDrawer'

import SearchIcon from '@material-ui/icons/Search'

import Adjustments from '../../assets/images/icons/adjustments.svg'
import EditPencilIcon from '../../assets/images/icons/EditPencilIcon.svg'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

const useStyles = makeStyles(theme => ({
  upgradeBtn: {
    width: 155,
    marginTop: '15px',
    marginBottom: '15px',
    height: 50,
    fontFamily: 'Verdana',
    color: '#F7F7F8',
    fontSize: '12',
    background: '#091A36',
    borderRadius: '8px',
    marginRight: '12px',
    // paddingRight: '18px',
    // paddingLeft: '18px',
    cursor: 'pointer',
    '&:hover': {
      background: '#14284a',
      color: 'white'
    }
  },
  AddBtn: {
    width: 170,
    marginTop: '15px',
    marginBottom: '15px',
    height: 50,
    fontFamily: 'Verdana',
    color: '#F7F7F8',
    fontSize: '12',
    background: '#091A36',
    borderRadius: '8px',
    marginRight: '10px',
    // paddingRight: '18px',
    // paddingLeft: '18px',
    cursor: 'pointer',
    '&:hover': {
      background: '#14284a',
      color: 'white'
    }
  },
  dropdownStyle: {
    borderRadius: '50px !important',
    '& .MuiOutlinedInput-input': {
      borderRadius: '8px !important'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px !important'
    }
  },
  // dropLabel: {
  //   // fontFamily: 'Roboto sans-serif',
  //   fontSize: '0.4',
  //   // background: 'linear-gradient(180deg, #f0f0f0 50%, #fafafa 50%)',
  //   color: ' #a9a9a9',
  //   padding: '0 0.3rem',
  //   margin: '0 0.5rem',
  //   // transition: '0.1s ease-out',
  //   // transformOrigin: 'left top',
  //   // pointerEvents: 'none',
  //   fontWeight: '5'
  // },
  excel: {
    width: '53px',
    height: '31px',
    objectFit: 'contain',
    // marginTop: -25,
    borderRadius: 30,
    cursor: 'pointer'
    // '&:hover': {
    //   background: '#c6e9f5'
    // }
  }
}))

const SaaSProductsTabView = forwardRef((props, ref) => {
  const classes = useStyles()
  const { title, caption, qbIcon, windowHeight, isDemoCompany, isAddRevenueVisible, setAddRevenueVisible } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const formRef = useRef(null)
  const { user } = useSelector(authSelector)
  const { companySetupModalScreen } = useSelector(uiSelector)
  const {
    isLoading,
    isSuccess,
    isSuccessCompanyCreate,
    updateCompanyError,
    id,
    productList,
    selectedCompany,
    currentlyActiveCompanyId,
    vendorList
  } = useSelector(companySelector)
  const [isNext, setNext] = useState(false)
  const [notify, setNotify] = useState(false)
  const [editItem, setEditItem] = useState('')
  const [generalContClicked, setGeneralContClicked] = useState(true)
  const [custDetailsClicked, setCustDetailsClicked] = useState(false)
  const [saveButtonClicked, setSaveButtonClicked] = useState(false)
  const [isAddButtonClicked, setAddButtonClicked] = useState(false)
  const [editButtonClicked, setEditButtonClicked] = useState(false)
  const [selectedCard, setSelectedCard] = useState('')
  const [mouseEnterOnCard, setMouseEnterOnCard] = useState('')
  const [searchInputs, setSearchInputs] = useState('')

  const isCoaCompleted = vendorList.some(eachItem => eachItem.department)

  useImperativeHandle(ref, () => ({
    handleNext() {
      // if (productList.length === 0 || (formRef.current.values.name && productList.length > 0)) {
      //   if (formRef.current.isValid) {
      //     setNotify(true)
      //     setNext(true)
      //   } else {
      //     dispatch(clearState())
      //     if (formRef.current) formRef.current.handleSubmit()

      //     setNext(true)
      //   }
      // } else {
      setNext(false)
      dispatch(clearState())
      const data = {
        companyId: id
        // fromRevenue: true
      }
      dispatch(setIsWizardVisible(false))
      dispatch(setSelectedMenuItem(''))
      if (isDemoCompany) {
        dispatch(toggleCompanySetupModal(false))
        dispatch(setCompanySetupModalScreen(0))
        dispatch(resetState())
        dispatch(getCompaniesByUser())
      } else {
        // dispatch(toggleCompanySetupModal(false))
        dispatch(finishCompanyCreate(data))
        dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
        // navigate(`/budget?cid=${selectedCompany}`)
        // dispatch({ type: MENU_OPEN, id: 'models' })
        // if (isAddRevenueVisible) setAddRevenueVisible(false)
      }
      // }
    }
  }))

  const handleChangeNumberCor = e => {
    const value = e.target.value.replace(/\D/g, '')
    formRef.current.setFieldValue('cor', value.replace(/\B(?=(\d{3})+(?!\d))/g, ','))
  }

  const handleChangeNumberPrice = e => {
    const value = e.target.value.replace(/\D/g, '')
    formRef.current.setFieldValue('price', value.replace(/\B(?=(\d{3})+(?!\d))/g, ','))
  }

  const handleChangeNumberCustomer = e => {
    const value = e.target.value.replace(/\D/g, '')
    formRef.current.setFieldValue('startingCustomers', value.replace(/\B(?=(\d{3})+(?!\d))/g, ','))
  }

  const handleChangeGrowth = e => {
    const value = e.target.value.replace(/\D/g, '')
    formRef.current.setFieldValue('customerGrowthRate', value.replace(/\B(?=(\d{3})+(?!\d))/g, ','))
  }

  const handleChangeUsage = e => {
    const value = e.target.value.replace(/\D/g, '')
    formRef.current.setFieldValue('startingUsage', value.replace(/\B(?=(\d{3})+(?!\d))/g, ','))
  }
  const handleChangeUsageGrowth = e => {
    const value = e.target.value.replace(/\D/g, '')
    formRef.current.setFieldValue('usageGrowthRate', value.replace(/\B(?=(\d{3})+(?!\d))/g, ','))
  }

  const saveButtonDisabled = values => {
    let isSaveValid = true
    if (values.startDate === null && values.name === '' && values.revenueModelType === '' && values.cor === '' && values.price === '') {
      isSaveValid = false
    } else {
      isSaveValid = true
    }
    return isSaveValid
  }

  const onEnterFilterBox = event => {
    setSearchInputs(event.target.value)
  }

  const filteredProductList = productList.filter(eachItem => {
    if (searchInputs.toLocaleLowerCase() === '') {
      return eachItem
    }
    return eachItem.name.toLocaleLowerCase().includes(searchInputs.toLocaleLowerCase())
  })

  const ProductCard = ({ product }) => (
    <div
      style={{
        width: '237px',
        minHeight: '100px',
        display: 'flex',
        padding: '16px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        flex: '1 0 0',
        border: '1px solid var(--secondary-purple-800, #207E4D)',
        borderRadius: '4px',
        marginRight: '20px',
        marginTop: '20px'
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <p className={cssClasses.cardName}>Product</p>
        <button
          disabled={isDemoCompany}
          style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}
          type="button"
          onClick={() => {
            setEditItem(product)
            setEditButtonClicked(true)
            setAddButtonClicked(true)
          }}
        >
          <img src={EditPencilIcon} alt="edit" />
        </button>
      </div>
      <div style={{ width: '98%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <p className={cssClasses.cardNested}>Product name</p>
        <ExpandLessIcon />
      </div>
      <p className={cssClasses.busineesTypeCardItemName}>{product.name}</p>
    </div>
  )

  return (
    <>
      <div
        style={{
          display: 'flex',
          width: '890px',
          height: '884px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          flexShrink: 0,
          gap: '12px',
          justifyContent: 'start',
          paddingLeft: '50px'
        }}
      >
        {productList.length === 0 ? (
          <div style={{ marginTop: '70px' }}>
            <p className={cssClasses.stepText}>Step 5</p>
            <h1 className={cssClasses.tabHeading}>Now let’s choose which revenue model. </h1>
            <p className={cssClasses.tabDesc}>Tell us some basic information about your company. </p>
            <Divider style={{ color: '#D8D9DB', height: '2px' }} />
            <img style={{ marginTop: '20px' }} src={noDataImage} alt="No Data" />
            <div className={cssClasses.noDataContainer}>
              <h1>No Data</h1>
              <p>
                You haven’t added any data yet to support your revenue model, <br /> select the button below to start.
              </p>
              <button onClick={() => setAddButtonClicked(true)} type="button">
                {' '}
                <span style={{ marginRight: '5px' }}>+</span> Add
              </button>
            </div>
          </div>
        ) : (
          <div style={{ marginTop: '70px' }}>
            <p className={cssClasses.stepText}>Step 5</p>
            <h1 className={cssClasses.tabHeading}>Now let’s choose which revenue model. </h1>
            <p className={cssClasses.tabDesc}>Tell us some basic information about your company. </p>
            <Divider style={{ color: '#D8D9DB', height: '2px' }} />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginTop: '30px' }}>
              <Paper
                component="form"
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  width: '360px',
                  height: '44px',
                  border: '1px solid #3B424C',
                  borderRadius: '4px'
                }}
              >
                <IconButton sx={{ p: '10px' }} aria-label="menu">
                  <SearchIcon />
                </IconButton>
                <InputBase
                  disabled={isDemoCompany}
                  onChange={onEnterFilterBox}
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search by..."
                  inputProps={{ 'aria-label': 'search google maps' }}
                />
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                  <img src={Adjustments} alt="Edit" />
                </IconButton>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              </Paper>
              <button
                disabled={isDemoCompany}
                style={{
                  height: '44px',
                  padding: '12px 16px',
                  borderRadius: '4px',
                  border: 'solid 1px #29A364',
                  color: '#29A364',
                  fontSize: '16px',
                  background: '#F2F7F4',
                  fontWeight: 500,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  marginLeft: '20px'
                }}
                onClick={() => {
                  setAddButtonClicked(true)
                }}
                type="button"
              >
                <span style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                  <AddIcon />
                  <span>Add</span>
                </span>
              </button>
            </div>
            <div style={{ width: '100%', marginTop: '20px', display: 'flex', flexWrap: 'wrap' }}>
              {filteredProductList.map((product, index) => (
                <div style={{ width: '237px', marginRight: '10px' }}>
                  <ProductCard key={index} product={product} />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <SaaSProductsDrawer
        isAddButtonClicked={isAddButtonClicked}
        setAddButtonClicked={setAddButtonClicked}
        editButtonClicked={editButtonClicked}
        setEditButtonClicked={setEditButtonClicked}
        editItem={editItem}
        setEditItem={setEditItem}
      />
    </>
  )
})

export default SaaSProductsTabView
