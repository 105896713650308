import { CircularProgress, Grid } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
// import WelcomePage from './WelecomPage'
import CompanyPage from './CompanyPage'

import ExpensesPage from './ExpensesPage'
import RevenuePage from './RevenuePage'
import PaymentPage from './PaymentPage'
import { useDispatch, useSelector } from 'react-redux'
import { setRegistrationSetupModelScreen, uiSelector } from 'store/ui/ui.slice'
import StripePage from './StripePage'
import LaunchPage from './LaunchPage'
import RequestDemoPage from './RequestDemoPage'
import ConfirmDemoPage from './ConfirmDemoPage'
import MePage from './MePage'
import WelcomePage from './WelecomPage'
import { Widget } from '@typeform/embed-react'
import { authSelector } from 'store/auth/auth.slice'
import { Navigate, useNavigate } from 'react-router'
import { SERVER_URL, TYPEFORM_FORM_ID } from 'utils/API/index'
import { getPlatformOnobardingFormStatus, postPlatformOnobardingFormSuccess } from 'store/auth/auth.actions'
import { SNACKBAR_OPEN } from 'store/actions'
import { finishCompanyCreate, getCompaniesByUser, getCompanyByID, updateCompany } from 'store/company/company.actions'
import { setCompanyId, setCurrentlyActiveCompanyId, setIsNewCompanyRegistered, setSelectedCompany } from 'store/company/company.slice'
import { getDashboardData } from 'store/dashboard/dashboard.actions'

const CompanyRegistration = () => {
  const [loading, setLoading] = useState(false)
  const { registrationSetupModelScreen } = useSelector(uiSelector)
  const { isLoggedIn, isGuestUser, user } = useSelector(authSelector)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const pollingRef = useRef(null) // To store the EP polling interval ID

  const handleCompanyCreated = companyId => {
    const data = {
      id: companyId,
      isCongratulationTabFinished: true
    }
    dispatch(setCurrentlyActiveCompanyId(companyId))
    dispatch(setSelectedCompany(companyId))
    dispatch(setIsNewCompanyRegistered(true))
    dispatch(updateCompany(data))
    dispatch(getCompaniesByUser())
    dispatch(getDashboardData({ companyId }))
    const fData = {
      companyId,
      isComplete: true
    }
    dispatch(finishCompanyCreate(fData))
  }

  useEffect(() => {
    if (!user?.id) navigate('/login', { replace: true })
    if (user.typeformRegistrationStatus === 'complete' && user.company) {
      handleCompanyCreated(user.company)
      window.location.pathname = '/v2/'
    } else {
      dispatch(
        getPlatformOnobardingFormStatus({
          userId: user.id
        })
      )
    }

    // clean up polling interval if set upon unmount
    return () => {
      clearInterval(pollingRef.current)
      pollingRef.current = null
    }
  }, [user])

  useEffect(() => {
    // *** TODO: TEMP FIX ***
    // This will reload the page as soon as the component mounts to load typeform
    window.location.reload()
  }, [])

  const handlePollError = err => {
    setLoading(false)

    // reset poll interval
    clearInterval(pollingRef.current)
    pollingRef.current = null

    dispatch({
      type: SNACKBAR_OPEN,
      open: true,
      message: err ?? 'Platform registration error. Please try again.',
      variant: 'alert',
      alertSeverity: 'error'
    })
  }

  // Try recurring calls to get the status of the Typeform completion after the webhook called the backend and data was processed
  // will return completion status as well as the newly created company id. Used to update redux state to be used in next pages.
  const startPollForFormCompletionStatus = () => {
    let count = 0
    const fetchData = async () => {
      try {
        const response = await fetch(`${SERVER_URL}/utils/platform_onboarding_form_status?userId=${user.id}`)
        const data = await response.json()

        if (data.status === 'complete' && data.company) {
          handleCompanyCreated(data.company)
          clearInterval(pollingRef.current)
          pollingRef.current = null

          setLoading(false)
          window.location.pathname = '/v2/' // navigate to payment page within nuxt app
        }
        if (data.status === 'verification_error') {
          console.log('Typeform webhook signature verification failure')
          handlePollError('Request verification failed. Please contact support.')
        }
      } catch (err) {
        handlePollError(err.message)
      } finally {
        count += 1
        if (count >= 15) handlePollError('Request timeout. Please try again.')
      }
    }

    fetchData()
    pollingRef.current = setInterval(fetchData, 2000) // polling every 2 seconds
  }

  const handleSubmit = formId => {
    // Call BE to set 'sumbitted' status for user.typeformRegistrationStatus
    dispatch(
      postPlatformOnobardingFormSuccess({
        userId: user.id
      })
    )
    setLoading(true) // global loading indicator shows while polling
    startPollForFormCompletionStatus()
  }

  if (loading) {
    return (
      <Grid container alignItems="center" justifyContent="center" sx={{ height: '100vh' }}>
        <Grid item alignSelf="center" justifySelf="center">
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }

  return (
    <div>
      <Widget
        id={TYPEFORM_FORM_ID}
        style={{ width: '100%', height: '100vh' }}
        className="company-register-typeform"
        // hidden fields to pass registration user to TF and then to BE on form completion https://www.typeform.com/developers/embed/hidden-fields/
        hidden={{
          user_id: user.id,
          user_email: user.email
        }}
        // https://www.typeform.com/developers/embed/configuration/#available-options
        onSubmit={({ formId }) => handleSubmit(formId)}
      />
      {/* TODO: Remove these old pages when ready after TF integration complete */}
      {/* <TabPanel value={registrationSetupModelScreen} index={0}>
        <WelcomePage />
      </TabPanel>
      <TabPanel value={registrationSetupModelScreen} index={1}>
        <CompanyPage />
      </TabPanel>
      <TabPanel value={registrationSetupModelScreen} index={2}>
        <MePage />
      </TabPanel>
      <TabPanel value={registrationSetupModelScreen} index={3}>
        <ExpensesPage />
      </TabPanel>
      <TabPanel value={registrationSetupModelScreen} index={4}>
        <RevenuePage />
      </TabPanel>
      <TabPanel value={registrationSetupModelScreen} index={5}>
        <PaymentPage />
      </TabPanel>
      <TabPanel value={registrationSetupModelScreen} index={6}>
        <StripePage />
      </TabPanel> */}
      {/* <TabPanel value={registrationSetupModelScreen} index={4}>
        <LaunchPage />
      </TabPanel> */}
      {/* <TabPanel value={registrationSetupModelScreen} index={1}>
        <RequestDemoPage />
      </TabPanel> */}
      {/* <TabPanel value={registrationSetupModelScreen} index={2}>
        <ConfirmDemoPage />
      </TabPanel> */}
    </div>
  )
}

export default CompanyRegistration
