import React, { lazy } from 'react'
import MinimalLayout from 'layout/MinimalLayout'
import NavMotion from 'layout/NavMotion'
import AuthGuard from 'utils/route-guard/AuthGuard'
import CompanyRegistration from 'views/pages/authentication/CompanyRegistration'

const CompanyRegistrationRoutes = {
  path: 'company-register',
  element: <MinimalLayout />,
  children: [
    {
      path: '/',
      element: (
        <NavMotion>
          <AuthGuard>
            <CompanyRegistration />
          </AuthGuard>
        </NavMotion>
      )
    }
  ]
}

export default CompanyRegistrationRoutes
