import { createSlice } from '@reduxjs/toolkit'
import { getOrganisationDetails, updateOrgDetails } from './organisation.actions'

const initialState = {
  name: '',
  bankBalance: 0,
  industry: '',
  yearEnd: '',
  billingEmail: '',
  isLoading: false,
  isSuccess: false,
  isSuccessOrganisationUpdate: false,
  getOrganisationDetailsError: '',
  updateOrganisationError: ''
}

export const organisationSlice = createSlice({
  name: 'organisationManagement',
  initialState,
  reducers: {
    resetState: () => initialState,
    clearState: state => {
      state.isLoading = false
      state.isSuccess = false
      state.isSuccessOrganisationUpdate = false
      state.getOrganisationDetailsError = false
      state.updateOrganisationError = ''
    }
  },
  extraReducers: {
    [getOrganisationDetails.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.getOrganisationDetailsError = payload
    },
    [getOrganisationDetails.pending]: state => {
      state.isLoading = true
    },
    [getOrganisationDetails.fulfilled]: (state, { payload }) => {
      const result = payload.result.record
      if (result) {
        state.name = result.name || ''
        state.bankBalance = result.bankBalance || ''
        state.industry = result.industry || ''
        state.yearEnd = result.yearEnd || ''
        state.billingEmail = result.billingEmail || ''
      }
      state.isLoading = false
      state.isSuccess = true
    },
    [updateOrgDetails.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.updateOrganisationError = payload
    },
    [updateOrgDetails.pending]: state => {
      state.isLoading = true
    },
    [updateOrgDetails.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.isSuccessOrganisationUpdate = true
    }
  }
})

export const { resetState, clearState } = organisationSlice.actions
export const organisationSelector = state => state.organisation
export default organisationSlice.reducer
