import React, { useEffect, useRef, useState } from 'react'
import { Divider, Drawer, FormHelperText, Grid, IconButton, Box, Typography } from '@material-ui/core'
import cssClasses from './infoTab.module.css'

import CloseIcon from '@material-ui/icons/Close'
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined'
import FileUploadOutlinedIcon from '@material-ui/icons/FileUploadOutlined'

import UploadIcon from '../../assets/images/icons/UploadIcon.png'
import NumberFormat from 'react-number-format'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { authSelector, clearState, resetState } from 'store/auth/auth.slice'
import { companySelector } from 'store/company/company.slice'
import { getCompaniesByUser, getCompanyByID, updateCompany } from 'store/company/company.actions'
import dayjs from 'dayjs'
import moment from 'moment'
import { SNACKBAR_OPEN } from 'store/actions'
import { toggleCompanySetupModal } from 'store/ui/ui.slice'
import ProductList from './DrawerListPages/ProductList'
import SaaSProductList from './DrawerListPages/SaaSProductList'
import ExcelImporTabModel from './excelImportTab'

const SaaSProductsDrawer = props => {
  const { isAddButtonClicked, setAddButtonClicked, editButtonClicked, setEditButtonClicked, setEditItem, editItem } = props
  const formRef = useRef()
  const dispatch = useDispatch()
  const { user } = useSelector(authSelector)
  const [addedProduct, setAddedProduct] = useState([])
  const {
    isLoading,
    isSuccess,
    isSuccessCompanyCreate,
    updateCompanyError,
    id,
    productList,
    selectedCompany,
    currentlyActiveCompanyId,
    vendorList
  } = useSelector(companySelector)

  const customStyles = {
    backdrop: {
      fill: 'var(--neutrals-cool-1000, #0C0D0F)',
      opacity: 0.25
    }
  }

  const formikInitialValues = {
    _id: '',
    name: '',
    revenueModelType: '',
    cor: '',
    price: '',
    startingCustomers: '',
    startDate: '',
    growthIncrement: '',
    customerGrowthRate: '',
    startingUsage: '',
    usageGrowthRate: '',
    submit: null
  }
  const [initialValues, setInitialValues] = useState(formikInitialValues)

  const handleAddProduct = (
    name,
    revenueModelType,
    cor,
    price,
    startingCustomers,
    startDate,
    growthIncrement,
    customerGrowthRate,
    startingUsage,
    usageGrowthRate
  ) => {
    const payload = {
      name,
      revenueModelType,
      cor,
      price,
      startingCustomers,
      startDate,
      growthIncrement,
      customerGrowthRate,
      startingUsage,
      usageGrowthRate
    }

    const saasProdList = []
    saasProdList.push(payload)
    setAddedProduct(saasProdList)

    const logArray = []

    const prodChangeLog = {
      user: user._id,
      userName: user.name,
      company: selectedCompany,
      date: new Date(),
      tabChanged: 'Revenue/ Saas / Product (SaaS)',
      department: 'N/A',
      lineItemChanged: `${payload.name} (New Product added)`,
      originalValue: 'N/A',
      newValue: 'N/A'
    }
    logArray.push(prodChangeLog)

    const productPayload = [...productList]
    productPayload.push(payload)
    if (id !== '') {
      const data = {
        id,
        productList: productPayload,
        systemLog: logArray
      }
      dispatch(updateCompany(data))
    }
    setInitialValues(formikInitialValues)
  }

  useEffect(() => {
    const tempObject = { ...editItem }
    const d = new Date(tempObject.startDate)
    if (editButtonClicked) {
      if (tempObject.revenueModelType === 'Hourly Pro Services' || tempObject.revenueModelType === 'Usage') {
        setInitialValues({
          ...editItem,
          startDate: dayjs(d).format('YYYY-MM-DD'),
          submit: null
        })
      } else {
        setInitialValues({
          ...editItem,
          startDate: dayjs(d).format('YYYY-MM-DD'),
          startingUsage: '',
          usageGrowthRate: '',
          submit: null
        })
      }
    }
  }, [editButtonClicked])

  // const editProduct = item => {
  //   setEditItem(item._id)
  //   const tempObject = { ...item }
  //   const d = new Date(tempObject.startDate)
  //   if (tempObject.revenueModelType === 'Hourly Pro Services' || tempObject.revenueModelType === 'Usage') {
  //     setInitialValues({
  //       ...item,
  //       startDate: dayjs(d).format('YYYY/MM/DD'),
  //       submit: null
  //     })
  //   } else {
  //     setInitialValues({
  //       ...item,
  //       startDate: dayjs(d).format('YYYY/MM/DD'),
  //       startingUsage: '',
  //       usageGrowthRate: '',
  //       submit: null
  //     })
  //   }
  // }

  useEffect(() => {}, [initialValues])

  const handleDeleteProduct = _id => {
    if (editItem === _id) return
    // if (formRef.current.values._id) {
    //   setNotify(true)
    //   return
    // }

    const selectedProd = [...productList].filter(item => item._id === _id)

    const payload = {
      _id: selectedProd[0]._id,
      name: selectedProd[0].name
    }
    const logArray = []

    const productChangeLog = {
      user: user._id,
      userName: user.name,
      company: selectedCompany,
      date: new Date(),
      tabChanged: 'Revenue/ Saas / Product (SaaS)',
      department: 'N/A',
      lineItemChanged: `${payload.name} (Product deleted)`,
      originalValue: 'N/A',
      newValue: 'N/A'
    }
    logArray.push(productChangeLog)

    const productPayload = [...productList].filter(item => item._id !== _id)
    if (id !== '') {
      const data = {
        id,
        productList: productPayload,
        systemLog: logArray
      }
      dispatch(updateCompany(data))
    }
  }

  const handleUpdateProduct = (
    _id,
    name,
    revenueModelType,
    cor,
    price,
    startingCustomers,
    startDate,
    growthIncrement,
    customerGrowthRate,
    startingUsage,
    usageGrowthRate
  ) => {
    setEditItem('')
    const payload = {
      _id,
      name,
      revenueModelType,
      cor,
      price,
      startingCustomers,
      startDate,
      growthIncrement,
      customerGrowthRate,
      startingUsage,
      usageGrowthRate
    }

    const saasProdList = []
    saasProdList.push(payload)
    setAddedProduct(saasProdList)

    const productPayload = [...productList]
    const index = productPayload.findIndex(item => item._id === payload._id)

    //  system logs
    const logArray = []
    if (productPayload[index].name !== payload.name) {
      const vendorChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'SAAS',
        department: 'N/A',
        lineItemChanged: `${productPayload[index].name} (Name changed)`,
        originalValue: productPayload[index].name,
        newValue: payload.name
      }
      logArray.push(vendorChangeLog)
    }

    if (productPayload[index].customerGrowthRate !== payload.customerGrowthRate) {
      const vendorChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'SAAS',
        department: 'N/A',
        lineItemChanged: `${productPayload[index].name} (Customer Growth Rate changed)`,
        originalValue: productPayload[index].customerGrowthRate,
        newValue: payload.customerGrowthRate
      }
      logArray.push(vendorChangeLog)
    }

    if (productPayload[index].growthIncrement !== payload.growthIncrement) {
      const vendorChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'SAAS',
        department: 'N/A',
        lineItemChanged: `${productPayload[index].name} (Growth Increment changed)`,
        originalValue: productPayload[index].growthIncrement,
        newValue: payload.growthIncrement
      }
      logArray.push(vendorChangeLog)
    }

    if (productPayload[index].price !== payload.price) {
      const vendorChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'SAAS',
        department: 'N/A',
        lineItemChanged: `${productPayload[index].name} (Price changed)`,
        originalValue: productPayload[index].price,
        newValue: payload.price
      }
      logArray.push(vendorChangeLog)
    }

    if (productPayload[index].revenueModelType !== payload.revenueModelType) {
      const vendorChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'SAAS',
        department: 'N/A',
        lineItemChanged: `${productPayload[index].name} (Revenue Model Type changed)`,
        originalValue: productPayload[index].revenueModelType,
        newValue: payload.revenueModelType
      }
      logArray.push(vendorChangeLog)
    }

    if (productPayload[index].startingCustomers !== payload.startingCustomers) {
      const vendorChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'SAAS',
        department: 'N/A',
        lineItemChanged: `${productPayload[index].name} (Starting Customers changed)`,
        originalValue: productPayload[index].startingCustomers,
        newValue: payload.startingCustomers
      }
      logArray.push(vendorChangeLog)
    }

    if (productPayload[index].startingUsage !== payload.startingUsage) {
      const vendorChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'SAAS',
        department: 'N/A',
        lineItemChanged: `${productPayload[index].name} (Starting Usage changed)`,
        originalValue: productPayload[index].startingUsage,
        newValue: payload.startingUsage
      }
      logArray.push(vendorChangeLog)
    }

    if (productPayload[index].usageGrowthRate !== payload.usageGrowthRate) {
      const vendorChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'SAAS',
        department: 'N/A',
        lineItemChanged: `${productPayload[index].name} (Usage Growth Rate changed)`,
        originalValue: productPayload[index].usageGrowthRate,
        newValue: payload.usageGrowthRate
      }
      logArray.push(vendorChangeLog)
    }

    if (dayjs(productPayload[index].startDate).format('YYYY/MM/DD') !== dayjs(payload.startDate).format('YYYY/MM/DD')) {
      const employeeChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'SAAS',
        department: 'N/A',
        lineItemChanged: `${productPayload[index].name} (Start Date changed)`,
        originalValue: dayjs(productPayload[index].startDate).format('MM/DD/YYYY'),
        newValue: dayjs(payload.startDate).format('MM/DD/YYYY')
      }
      logArray.push(employeeChangeLog)
    }

    productPayload[index] = payload
    if (id !== '') {
      const data = {
        id,
        productList: productPayload,
        systemLog: logArray
      }
      dispatch(updateCompany(data))
    }
    setInitialValues(formikInitialValues)
  }

  useEffect(() => {
    if (updateCompanyError !== '') {
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: updateCompanyError,
        variant: 'alert',
        alertSeverity: 'error'
      })
    }

    return dispatch(clearState())
  }, [dispatch, updateCompanyError])

  // useEffect(() => {
  //   if (isSuccess && isNext) {
  //     dispatch(clearState())
  //     const data = {
  //       companyId: id,
  //       isComplete: isCoaCompleted,
  //       fromRevenue: true
  //     }
  //     dispatch(finishCompanyCreate(data))
  //   }
  // }, [isNext, isSuccess])

  useEffect(() => {
    if (isSuccessCompanyCreate) {
      dispatch(toggleCompanySetupModal(false))
      // dispatch(setCompanySetupModalScreen(0))
      dispatch(resetState())
      dispatch(getCompaniesByUser())
      const data = {
        companyId: currentlyActiveCompanyId || selectedCompany,
        email: user.email
      }
      dispatch(getCompanyByID(data))
    }
  }, [isSuccessCompanyCreate])

  const onClickOnCancelButton = () => {
    setInitialValues(formikInitialValues)
    setEditButtonClicked(false)
  }

  return (
    <Drawer
      BackdropProps={{ style: customStyles.backdrop }}
      style={{ zIndex: 1000000 }}
      anchor="right"
      open={isAddButtonClicked}
      onClose={() => setAddButtonClicked(false)}
      sx={{
        '& .css-4qyyic-MuiGrid-root': {
          padding: '24px',
          width: '390px'
        }
      }}
    >
      <Grid p={5} width="500px" role="presentation" textAlign="start">
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={() => setAddButtonClicked(false)}>
            <CloseIcon style={{ color: 'black' }} />
          </IconButton>
        </div>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <h1 className={cssClasses.drawerHeading}>Business</h1>
          <FileUploadOutlinedIcon style={{ color: 'black', fontSize: '24px' }} />
        </div>
        <p className={cssClasses.drawerDesc}>
          Let’s add the basic revenue model information about your company, by adding the required information below.
        </p>
        {addedProduct.length === 0 ? (
          <div style={{ width: '100%' }}>
            <Typography className={cssClasses.drawerHeadingSecond} style={{ marginBottom: '11px' }}>
              SaaS
            </Typography>
            <ExcelImporTabModel excelImportTabNo={10} />
            <Formik
              validateOnMount
              innerRef={formRef}
              enableReinitialize
              initialValues={initialValues}
              validationSchema={Yup.object().shape({
                name: Yup.string().required('Product name is required').max(30, 'Maximum 30 characters allowed'),
                revenueModelType: Yup.string().required('Revenue model type is required'),
                cor: Yup.string()
                  .required('CoR/Unit/Period is required')
                  .max(30, 'Maximum 30 characters allowed')
                  .matches(/^[\d,]+$/, 'Enter only numbers'),
                price: Yup.string().required('Price/Unit/Period is required').max(30, 'Maximum 30 characters allowed'),
                startingCustomers: Yup.string()
                  .required('Starting # of customers is required')
                  .max(30, 'Maximum 30 characters allowed')
                  .matches(/^[\d,]+$/, 'Enter only numbers'),
                startDate: Yup.string().required('Starting period are required'),
                // .typeError('Start date is required'),
                growthIncrement: Yup.string().required('Growth increment is required'),
                customerGrowthRate: Yup.string().required('Customer growth rate is required').max(3, 'Maximum 3 digits allowed'),
                startingUsage: Yup.string().when('revenueModelType', {
                  is: revenueModelType => revenueModelType === 'Hourly Pro Services' || revenueModelType === 'Usage',
                  then: Yup.string()
                    .required('Starting Usage/Month/Customer is required')
                    .nullable()
                    .matches(/^\d+(\.\d+)?$/, 'Enter only valid numbers')
                    .max(30, 'Maximum 30 characters allowed')
                }),
                usageGrowthRate: Yup.string().when('revenueModelType', {
                  is: revenueModelType => revenueModelType === 'Hourly Pro Services' || revenueModelType === 'Usage',
                  then: Yup.string().required('Usage growth rate is required').nullable().max(3, 'Maximum 3 digits allowed')
                })
              })}
              onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
                if (values._id) {
                  handleUpdateProduct(
                    values._id,
                    values.name,
                    values.revenueModelType,
                    values.cor.toString().includes(',') ? parseInt(values.cor.replace(/,/g, ''), 10) : values.cor,
                    values.price.toString().includes(',') ? parseInt(values.price.replace(/,/g, ''), 10) : values.price,
                    values.startingCustomers.toString().includes(',')
                      ? parseInt(values.startingCustomers.replace(/,/g, ''), 10)
                      : values.startingCustomers,
                    new Date(moment(values.startDate).format('YYYY-MM-DD')),
                    // new Date(values.startDate).toLocaleDateString('en-US'),
                    values.growthIncrement,
                    values.customerGrowthRate.toString().includes(',')
                      ? parseInt(values.customerGrowthRate.replace(/,/g, ''), 10)
                      : values.customerGrowthRate,
                    // eslint-disable-next-line no-nested-ternary
                    values.revenueModelType === 'Usage' || values.revenueModelType === 'Hourly Pro Services'
                      ? values.startingUsage.toString().includes(',')
                        ? parseInt(values.startingUsage.replace(/,/g, ''), 10)
                        : values.startingUsage
                      : '',
                    // eslint-disable-next-line no-nested-ternary
                    values.revenueModelType === 'Usage' || values.revenueModelType === 'Hourly Pro Services'
                      ? values.usageGrowthRate.toString().includes(',')
                        ? parseInt(values.usageGrowthRate.replace(/,/g, ''), 10)
                        : values.usageGrowthRate
                      : ''
                  )
                } else {
                  handleAddProduct(
                    values.name,
                    values.revenueModelType,
                    values.cor.toString().includes(',') ? parseInt(values.cor.replace(/,/g, ''), 10) : values.cor,
                    values.price.toString().includes(',') ? parseInt(values.price.replace(/,/g, ''), 10) : values.price,
                    values.startingCustomers.toString().includes(',')
                      ? parseInt(values.startingCustomers.replace(/,/g, ''), 10)
                      : values.startingCustomers,
                    values.startDate,
                    values.growthIncrement,
                    values.customerGrowthRate.toString().includes(',')
                      ? parseInt(values.customerGrowthRate.replace(/,/g, ''), 10)
                      : values.customerGrowthRate,
                    // eslint-disable-next-line no-nested-ternary
                    values.revenueModelType === 'Usage' || values.revenueModelType === 'Hourly Pro Services'
                      ? values.startingUsage.toString().includes(',')
                        ? parseInt(values.startingUsage.replace(/,/g, ''), 10)
                        : values.startingUsage
                      : '',
                    // eslint-disable-next-line no-nested-ternary
                    values.revenueModelType === 'Usage' || values.revenueModelType === 'Hourly Pro Services'
                      ? values.usageGrowthRate.toString().includes(',')
                        ? parseInt(values.usageGrowthRate.replace(/,/g, ''), 10)
                        : values.usageGrowthRate
                      : ''
                  )
                }
                setStatus({ success: true })
                setSubmitting(false)
                // resetForm(formikInitialValues)
                setInitialValues(formikInitialValues)
                // setCustDetailsClicked(false)
                // setGeneralContClicked(true)
                // setSaveButtonClicked(false)
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
                isValid,
                setFieldTouched
              }) => (
                <form noValidate onSubmit={handleSubmit} style={{ width: '100%', marginTop: '12px' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label htmlFor="product-name" className={cssClasses.drawerInputLabel}>
                        Product name <span className={cssClasses.labelStar}>*</span>{' '}
                      </label>
                      <input
                        value={values.name}
                        placeholder="Enter"
                        name="name"
                        id="product-name"
                        onBlur={handleBlur}
                        onChange={e => {
                          handleChange(e)
                          if (values.name) setFieldTouched('name', true)
                        }}
                        className={cssClasses.drawerInput}
                      />
                      {touched.name && errors.name && <FormHelperText error>{errors.name}</FormHelperText>}
                    </div>
                    <div className={cssClasses.drawerSelectInput}>
                      <label htmlFor="revenueModelType-select-label" className={cssClasses.drawerInputLabel}>
                        Revenue model <span className={cssClasses.labelStar}>*</span>{' '}
                      </label>
                      <select
                        value={values.revenueModelType}
                        className={cssClasses.drawerInput}
                        style={{ width: '100%' }}
                        placeholder="Select"
                        id="revenueModelType-select-label"
                        name="revenueModelType"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected hidden>
                          Select
                        </option>
                        <option value="Flat">Flat</option>
                        <option value="Usage">Usage</option>
                        <option value="Flat Pro Services">Flat Pro Services</option>
                        <option value="Hourly Pro Services">Hourly Pro Services</option>
                      </select>{' '}
                      {touched.revenueModelType && errors.revenueModelType && (
                        <FormHelperText error> {errors.revenueModelType} </FormHelperText>
                      )}
                    </div>{' '}
                    {values.revenueModelType && (
                      <>
                        {' '}
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <label htmlFor="cor" className={cssClasses.drawerInputLabel}>
                            Cor / Unit / Period <span className={cssClasses.labelStar}>*</span>{' '}
                          </label>
                          <input
                            placeholder="Enter"
                            value={values.cor}
                            name="cor"
                            id="cor"
                            onBlur={handleBlur}
                            onChange={e => {
                              handleChange(e)
                              if (values.cor) setFieldTouched('cor', true)
                            }}
                            className={cssClasses.drawerInput}
                          />{' '}
                          {touched.cor && errors.cor && <FormHelperText error> {errors.cor} </FormHelperText>}
                        </div>{' '}
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <label htmlFor="price" className={cssClasses.drawerInputLabel}>
                            Price / Unit / Period <span className={cssClasses.labelStar}>*</span>{' '}
                          </label>

                          <div className={cssClasses.containerInputs}>
                            <MonetizationOnOutlinedIcon />
                            <NumberFormat
                              className={cssClasses.transparentInputs}
                              placeholder="Enter"
                              value={values.price}
                              name="price"
                              onBlur={handleBlur}
                              onChange={e => {
                                handleChange(e)
                                if (values.price) setFieldTouched('price', true)
                              }}
                              id="price"
                              thousandSeparator=","
                            />{' '}
                          </div>
                          {touched.price && errors.price && <FormHelperText error> {errors.price} </FormHelperText>}
                        </div>{' '}
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <label htmlFor="start_date" className={cssClasses.drawerInputLabel}>
                            Starting period <span className={cssClasses.labelStar}>*</span>{' '}
                          </label>
                          <input
                            placeholder="Start date"
                            value={values.startDate}
                            name="startDate"
                            id="start_date"
                            className={cssClasses.drawerInput}
                            type="date"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />{' '}
                          {touched.startDate && errors.startDate && <FormHelperText error> {errors.startDate} </FormHelperText>}
                        </div>{' '}
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <label htmlFor="starting_customers" className={cssClasses.drawerInputLabel}>
                            Starting # of customers <span className={cssClasses.labelStar}>*</span>{' '}
                          </label>
                          <input
                            placeholder="Starting # of customers"
                            value={values.startingCustomers}
                            name="startingCustomers"
                            id="starting_customers"
                            onBlur={handleBlur}
                            onChange={e => {
                              handleChange(e)
                              if (values.startingCustomers) setFieldTouched('startingCustomers', true)
                            }}
                            className={cssClasses.drawerInput}
                          />{' '}
                          {touched.startingCustomers && errors.startingCustomers && (
                            <FormHelperText error> {errors.startingCustomers} </FormHelperText>
                          )}
                        </div>{' '}
                        <div className={cssClasses.drawerSelectInput}>
                          <label htmlFor="growth_increment" className={cssClasses.drawerInputLabel}>
                            Growth increment <span className={cssClasses.labelStar}>*</span>{' '}
                          </label>
                          <select
                            className={cssClasses.drawerInput}
                            style={{ width: '100%' }}
                            placeholder="Select"
                            id="growth_increment"
                            value={values.growthIncrement}
                            name="growthIncrement"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          >
                            <option value="" disabled selected hidden>
                              Select
                            </option>
                            <option value="Monthly">Monthly</option>
                            <option value="Quarterly">Quarterly</option>
                            <option value="Annually">Annually</option>
                          </select>{' '}
                          {touched.growthIncrement && errors.growthIncrement && (
                            <FormHelperText error> {errors.growthIncrement} </FormHelperText>
                          )}
                        </div>{' '}
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <label htmlFor="customer_growthRate" className={cssClasses.drawerInputLabel}>
                            Customer growth rate <span className={cssClasses.labelStar}>*</span>{' '}
                          </label>{' '}
                          <div className={cssClasses.containerInputs}>
                            <NumberFormat
                              placeholder="Enter"
                              value={values.customerGrowthRate}
                              name="customerGrowthRate"
                              id="customer_growthRate"
                              onBlur={handleBlur}
                              onChange={e => {
                                handleChange(e)
                                if (values.customerGrowthRate) setFieldTouched('customerGrowthRate', true)
                              }}
                              className={cssClasses.transparentInputs}
                            />
                            <Typography className={cssClasses.inputPercentage}>%</Typography>
                          </div>
                          {touched.customerGrowthRate && errors.customerGrowthRate && (
                            <FormHelperText error> {errors.customerGrowthRate} </FormHelperText>
                          )}
                        </div>{' '}
                        {(values.revenueModelType === 'Usage' ||
                          values.revenueModelType === 'Hourly Pro Services' ||
                          values.revenueModelType === 'Flat Pro Services') && (
                          <>
                            {' '}
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <label htmlFor="usage_growth_rate" className={cssClasses.drawerInputLabel}>
                                Usage growth rate <span className={cssClasses.labelStar}>*</span>{' '}
                              </label>{' '}
                              <div className={cssClasses.containerInputs}>
                                <NumberFormat
                                  placeholder="Enter"
                                  value={values.usageGrowthRate}
                                  name="usageGrowthRate"
                                  id="usage_growth_rate"
                                  onBlur={handleBlur}
                                  onChange={e => {
                                    handleChange(e)
                                    if (values.usageGrowthRate) setFieldTouched('usageGrowthRate', true)
                                  }}
                                  className={cssClasses.transparentInputs}
                                />
                                <Typography className={cssClasses.inputPercentage}>%</Typography>
                              </div>
                              {touched.usageGrowthRate && errors.usageGrowthRate && (
                                <FormHelperText error> {errors.usageGrowthRate} </FormHelperText>
                              )}
                            </div>{' '}
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <label htmlFor="starting_usage" className={cssClasses.drawerInputLabel}>
                                Starting usage/month/customer <span className={cssClasses.labelStar}>*</span>{' '}
                              </label>
                              <input
                                placeholder="Enter"
                                value={values.startingUsage}
                                name="startingUsage"
                                id="starting_usage"
                                onBlur={handleBlur}
                                onChange={e => {
                                  handleChange(e)
                                  if (values.startingUsage) setFieldTouched('startingUsage', true)
                                }}
                                className={cssClasses.drawerInput}
                              />{' '}
                              {touched.startingUsage && errors.startingUsage && (
                                <FormHelperText error> {errors.startingUsage} </FormHelperText>
                              )}
                            </div>{' '}
                          </>
                        )}
                      </>
                    )}
                  </Box>

                  <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button className={isValid ? cssClasses.wizardAddButton : cssClasses.wizardAddButtonDisabled} type="submit">
                      Add
                    </button>
                  </div>
                  <Divider sx={{ marginTop: '230px', marginBottom: '10px' }} />
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button onClick={onClickOnCancelButton} className={cssClasses.wizardCancelButton} type="button">
                      Cancel
                    </button>
                    <button className={cssClasses.disabledButton} type="button">
                      Save
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        ) : (
          <SaaSProductList
            addedProduct={addedProduct}
            setAddedProduct={setAddedProduct}
            setEditItem={setEditItem}
            model="SaaS"
            setEditButtonClicked={setEditButtonClicked}
            setAddButtonClicked={setAddButtonClicked}
          />
        )}
      </Grid>
    </Drawer>
  )
}

export default SaaSProductsDrawer
