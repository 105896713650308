import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Dialog, DialogTitle, Typography, IconButton } from '@material-ui/core'
import { useTheme } from '@emotion/react'
import CloseIcon from '@material-ui/icons/Close'
import wizardBar from '../../assets/images/wizardBar.png'

const useStyles = makeStyles(theme => ({
  muiDialog: {
    '& .MuiDialog-paper': {
      width: '806px',
      top: '75',
      left: '372',
      padding: 0,
      border: ' #707070 solid 1px '
    }
  },
  dialogChildren: {
    background: '#F0F0F0'
  },
  modalHeading: {
    fontWeight: '600',
    fontSize: '28px',
    color: '#091A36',
    textAlign: 'center',
    marginTop: '25px'
  }
}))

const BasicModal = ({ isOpen, handleClose, heading, children }) => {
  const theme = useTheme()
  const classes = useStyles()

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} aria-labelledby="max-width-dialog-title" sx={{ py: 0 }} className={classes.muiDialog}>
      <DialogTitle
        id="max-width-dialog-title"
        sx={{
          width: '100%',
          backgroundImage: `url(${wizardBar})`,
          backgroundSize: '260px,10px',
          backgroundRepeat: 'no-repeat',
          padding: '0px 0px 20px 0px',
          imageRendering: 'crisp-edges,  -moz-crisp-edges, -o-crisp-edges, -webkit-optimize-contrast'
        }}
      >
        <Typography className={classes.modalHeading}>{heading}</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 24,
            top: 24,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <div className={classes.dialogChildren}>{children}</div>
    </Dialog>
  )
}

export default BasicModal
