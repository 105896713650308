import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle, useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  Button,
  CardMedia,
  Tooltip,
  Card,
  Divider
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '@material-ui/icons/Close'
import * as Yup from 'yup'

import { uiSelector, toggleExcelImportTabModel, setCompanySetupModalScreen, setExcelImportTabModel } from 'store/ui/ui.slice'
import { resetState, companySelector, clearState, setXlUplodeDatas } from 'store/company/company.slice'

import { modelsSelector } from 'store/models/models.slice'
import { useTheme } from '@emotion/react'
import { IconDownload, IconUpload } from '@tabler/icons'
import departmentTab from 'assets/files/departmentTab.xlsx'
import ExpensesTab from 'assets/files/ExpensesTab.xlsx'
import EmployeeTab from 'assets/files/EmployeeTab.xlsx'
import ContractorsTab from 'assets/files/ContractorsTab.xlsx'
import VendorsTab from 'assets/files/VendorsTab.xlsx'
import RevenueTopLine from 'assets/files/Revenue_pro_services_top_line.xlsx'
import RevenueDetailedProduct from 'assets/files/Revenue_detailed_product.xlsx'
import RevenueDetailedClient from 'assets/files/Revenue_detailed_client.xlsx'
import RevenueCommerceRetail from 'assets/files/Revenue_commerce_retail.xlsx'
import RevenueSaas from 'assets/files/Revenue_Saas.xlsx'
import UploadIcon from '../../assets/images/icons/UploadIcon.svg'

import cssClasses from './infoTab.module.css'

import LocationsList from '../../data/LocationsList'

import { saveAs } from 'file-saver'
import { Label } from '@material-ui/icons'
import { createCompanyFromExcel, updateCompany } from 'store/company/company.actions'
import { SNACKBAR_OPEN } from 'store/actions'
import { bool, boolean, number, string } from 'yup'
import moment from 'moment'
import { ObjectID } from 'bson'
import { Workbook } from 'exceljs'

// import { format } from 'date-fns'

// import excel from 'xlsx'
const XLSX = require('xlsx')

const FileSaver = require('file-saver')

const useStyles = makeStyles(theme => ({
  muiDialog: {
    '& .MuiDialog-paper': {
      // maxHeight: 'initial',
      height: 'calc(100% - 64px)'
    }
  },
  muiDialogContent: {
    [theme.breakpoints.up('sm')]: {
      overflowY: 'hidden',
      paddingRight: 0
    }
  },
  gridFillHeight: {
    [theme.breakpoints.up('sm')]: {
      height: '100%'
    }
  },
  icon: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    borderColor: 'black',
    borderWidth: 2,
    borderStyle: 'solid',
    cursor: 'pointer',
    '&:hover': {
      color: 'black',
      backgroundColor: '#efefef'
    }
  }
}))

const ExcelImporTabModel = props => {
  const { excelImportTabNo } = props
  const theme = useTheme()
  const [isErrorExcel, setErrorInExcel] = useState(false)
  const classes = useStyles()
  const dispatch = useDispatch()
  const { excelImportTabVisible, excelTabModelCount, companySetupModalScreen } = useSelector(uiSelector)
  const { excelImportSuccess, xlUplodeDatas, productList, id, departmentList, employeeList, coaList } = useSelector(companySelector)

  const inputRef = useRef(null)

  const getProducts = ids => {
    const p = []
    productList.forEach(product => {
      if (ids.includes(product._id)) p.push(product.name)
    })
    return p.join(', ')
  }
  const departmentValidationSchema = Yup.object().shape({
    data: Yup.string().required()
  })
  const coavalidationSchema = Yup.object().shape({
    name: Yup.string().required(),
    cogs: Yup.boolean().test('is boolean', 'Please enter either Y or N', value => value === true || value === false),
    govtTaxed: Yup.boolean().test('is boolean', 'Please enter either Y or N', value => value === true || value === false),
    hrRelated: Yup.boolean().test('is boolean', 'Please enter either Y or N', value => value === true || value === false)
    // cogs: Yup.number().test('is boolean', 'Please enter either 1 or 0', value => value === 0 || value === 1),
    // govtTaxed: Yup.number().test('is boolean', 'Please enter either 1 or 0', value => value === 0 || value === 1),
    // hrRelated: Yup.number().test('is boolean', 'Please enter either 1 or 0', value => value === 0 || value === 1)
  })

  const contractorValidationSchema = Yup.object().shape({
    name: Yup.string().required(),
    department: Yup.string().required(),
    function: Yup.string().required(),
    purpose: Yup.string().required(),
    annualCompensation: Yup.number().required(),
    startDate: Yup.date().required(),
    location: Yup.string().required()
    // purposeFun: Yup.string().required(),
    // department: Yup.string().required(),
    // title: Yup.string().required(),
    // startDate: Yup.date().required(),
    // location: Yup.string().required(),
    // annualCompensation: Yup.number().required()
  })

  const employeeValidationSchema = Yup.object().shape({
    name: Yup.string().required(),
    department: Yup.string().required(),
    title: Yup.string().required(),
    startDate: Yup.date().required(),
    location: Yup.string().required(),
    salary: Yup.number().required()
  })

  const vendorvalidationSchema = Yup.object().shape({
    name: Yup.string().required(),
    department: Yup.string().required(),
    frequency: Yup.string().required(),
    startDate: Yup.date(),
    endDate: Yup.date(),
    amount: Yup.number().required()
  })
  const clientListvalidationSchema = Yup.object().shape({
    growthIncrement: Yup.string().required(),
    name: Yup.string().required(),
    startDate: Yup.date().required(),
    growthRate: Yup.number().required(),
    startingRevenue: Yup.number().required()
  })
  const clientListvalidationSchemaDetailedData = Yup.object().shape({
    name: Yup.string().required(),
    growthIncrement: Yup.string().required(),
    growthRate: Yup.number().required(),
    products: Yup.array().min(1).required(),
    startingUnits: Yup.number().required()
  })
  const productListvalidationSchema = Yup.object().shape({
    name: Yup.string().required(),
    price: Yup.number().required(),
    unitCOGS: Yup.number().required()
  })
  const productListvalidationSchemaCommerceOrRetailData = Yup.object().shape({
    cor: Yup.number().required(),
    name: Yup.string().required(),
    growthIncrement: Yup.string().required(),
    growthRate: Yup.number().required(),
    price: Yup.number().required(),
    startDate: Yup.date().required(),
    startingUnits: Yup.number().required()
  })
  const productListvalidationSchemaSaasData = Yup.object().shape({
    name: Yup.string().required(),
    price: Yup.number().required(),
    cor: Yup.number().required(),
    revenueModelType: Yup.string().required(),
    customerGrowthRate: Yup.number().required(),
    startDate: Yup.date().required(),
    growthIncrement: Yup.string().required(),
    startingCustomers: Yup.number().required()
  })

  const handleClientDetailedDownload = () => {
    const heading = 'For professional services companies that forecast product consumption per client'
    const header = ['', 'Client Name', 'Products', 'Growth Increment', '% Growth', 'Starting Units']
    const sampleH = ['Sample', 'ACME Inc.', 'Social Media Package', 'Monthly', '3', '10']

    const workbook = new Workbook()

    const worksheet = workbook.addWorksheet('Revenue - Pro Services Detailed')
    const Product = workbook.addWorksheet('Levels')
    Product.state = 'hidden'
    productList.forEach((prod, index) => {
      Product.insertRow(index + 1, [prod.name])
    })

    worksheet.mergeCells('A1:F1')
    worksheet.getCell('A1').value = heading
    worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell('A1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFFFF00' },
      bgColor: { argb: 'FF0000FF' }
    }
    worksheet.getCell('A1').border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    }
    worksheet.mergeCells('A2:F2')
    worksheet.getCell('A2').value = 'Please assign products to clients in the tinted cells below'
    worksheet.getCell('A2').alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell('A2').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFFFF00' },
      bgColor: { argb: 'FF0000FF' }
    }
    worksheet.getCell('A2').border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    }
    const headerRow = worksheet.addRow(header)
    const sampleRow = worksheet.addRow(sampleH)

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      worksheet.getColumn(number).width = 17
      cell.alignment = { vertical: 'middle', horizontal: 'center' }
      cell.font = {
        bold: true
      }
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'B0DAFC' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })

    sampleRow.eachCell((cell, number) => {
      cell.alignment = { vertical: 'middle', horizontal: 'center' }
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })

    const Actual = ['Actual', '', '', '', '', '']

    for (let i = 1; i < 100; i += 1) {
      const ActualRow = worksheet.addRow(Actual)

      ActualRow.eachCell((cell, number) => {
        cell.alignment = { vertical: 'middle', horizontal: 'center' }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'E5E0EC' },
          bgColor: { argb: 'FF0000FF' }
        }
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      })
    }

    worksheet.getCell('A4').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFFFF00' },
      bgColor: { argb: 'FF0000FF' }
    }

    for (let i = 3; i < 10000; i += 1) {
      worksheet.getCell(`C${i}`).dataValidation = {
        type: 'list',
        allowBlank: false,
        formulae: ['=Levels!$A$1:$A$9999'],
        showErrorMessage: true,
        errorStyle: 'error',
        error: 'Please select a valid value from the list'
      }

      worksheet.getCell(`D${i}`).dataValidation = {
        type: 'list',
        allowBlank: false,
        formulae: ['"Monthly,Quarterly,Annually"'],
        showErrorMessage: true,
        errorStyle: 'error',
        error: 'Please select a valid value from the list'
      }
    }

    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      FileSaver.saveAs(blob, 'Revenue_detailed_client.xlsx')
    })
  }

  const handleFileDownload = () => {
    if (excelImportTabNo === 1) {
      FileSaver.saveAs(departmentTab, 'departmentTab.xlsx', { type: 'text/plain;charset=utf-8' })
    } else if (excelImportTabNo === 2) {
      FileSaver.saveAs(ExpensesTab, 'ExpensesTab.xlsx', { type: 'text/plain;charset=utf-8' })
    } else if (excelImportTabNo === 3) {
      const heading = 'Please list your employees in the tinted cells below'
      const header = ['', 'Employee Name', 'Department', 'Title', 'Start Date', 'Location', 'Current Salary / yearly']
      const sampleH = ['Sample', 'Jack', 'Sales', 'CFO', '03/06/2022', 'Alaska', '1000']

      const workbook = new Workbook()

      const worksheet = workbook.addWorksheet('Employee')
      const department = workbook.addWorksheet('Levels')
      // const contWorkSheet = workbook.addWorksheet('Contractor')

      department.state = 'hidden'
      departmentList.forEach((dept, index) => {
        department.insertRow(index + 1, [dept.name])
      })

      const empLocation = workbook.addWorksheet('LevelsLoc')
      empLocation.state = 'hidden'
      LocationsList.forEach((list, index) => {
        empLocation.insertRow(index, [`${list.abbreviation} - ${list.name}`])
      })

      worksheet.mergeCells('A1:G1')
      worksheet.getCell('A1').value = heading
      worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' }
      worksheet.getCell('A1').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      }
      worksheet.getCell('A1').border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }

      const headerRow = worksheet.addRow(header)
      const sampleRow = worksheet.addRow(sampleH)

      // Cell Style : Fill and Border
      headerRow.eachCell((cell, number) => {
        worksheet.getColumn(number).width = 17
        cell.alignment = { vertical: 'middle', horizontal: 'center' }
        cell.font = {
          bold: true
        }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'B0DAFC' },
          bgColor: { argb: 'FF0000FF' }
        }
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      })

      sampleRow.eachCell((cell, number) => {
        cell.alignment = { vertical: 'middle', horizontal: 'center' }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFF00' },
          bgColor: { argb: 'FF0000FF' }
        }
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      })

      const Actual = ['Actual', '', '', '', '', '', '']

      for (let i = 1; i < 100; i += 1) {
        const ActualRow = worksheet.addRow(Actual)

        ActualRow.eachCell((cell, number) => {
          cell.alignment = { vertical: 'middle', horizontal: 'center' }
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'E5E0EC' },
            bgColor: { argb: 'FF0000FF' }
          }
          cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        })
      }

      const startDatecell = worksheet.getColumn(5)
      startDatecell.eachCell((c, rowNumber) => {
        c.numFmt = '@'
      })

      for (let i = 3; i < 10000; i += 1) {
        worksheet.getCell(`C${i}`).dataValidation = {
          type: 'list',
          allowBlank: false,
          formulae: ['=Levels!$A$1:$A$9999'],
          showErrorMessage: true,
          errorStyle: 'error',
          error: 'Please select a valid value from the list'
        }

        worksheet.getCell(`F${i}`).dataValidation = {
          type: 'list',
          allowBlank: false,
          formulae: ['=LevelsLoc!$A$1:$A$9999'],
          showErrorMessage: true,
          errorStyle: 'error',
          error: 'Please select a valid value from the list'
        }
      }

      // const headingCont = 'Please list your contractors in the tinted cells below'
      // const headerCont = [
      //   '',
      //   'Contractor or Entity Name',
      //   'Purpose/Function',
      //   'Department',
      //   'Title of Function',
      //   'Start Date',
      //   'Location',
      //   'Annualized Compensation'
      // ]
      // const sampleHCont = ['Sample', 'ACME Inc.', 'SEO Consulting', 'Sales', 'SEO Consulting', '03/06/2022', 'AK - Alaska', '8000']

      // contWorkSheet.mergeCells('A1:H1')
      // contWorkSheet.getCell('A1').value = headingCont
      // contWorkSheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' }
      // contWorkSheet.getCell('A1').fill = {
      //   type: 'pattern',
      //   pattern: 'solid',
      //   fgColor: { argb: 'FFFFFF00' },
      //   bgColor: { argb: 'FF0000FF' }
      // }
      // contWorkSheet.getCell('A1').border = {
      //   top: { style: 'thin' },
      //   left: { style: 'thin' },
      //   bottom: { style: 'thin' },
      //   right: { style: 'thin' }
      // }

      // const headerRowCont = contWorkSheet.addRow(headerCont)
      // const sampleRowCont = contWorkSheet.addRow(sampleHCont)

      // Cell Style : Fill and Border

      // headerRowCont.eachCell((cell, number) => {
      //   contWorkSheet.getColumn(number).width = 17
      //   cell.alignment = { vertical: 'middle', horizontal: 'center' }
      //   cell.font = {
      //     bold: true
      //   }
      //   cell.fill = {
      //     type: 'pattern',
      //     pattern: 'solid',
      //     fgColor: { argb: 'B0DAFC' },
      //     bgColor: { argb: 'FF0000FF' }
      //   }
      //   cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      // })

      // sampleRowCont.eachCell((cell, number) => {
      //   cell.alignment = { vertical: 'middle', horizontal: 'center' }
      //   cell.fill = {
      //     type: 'pattern',
      //     pattern: 'solid',
      //     fgColor: { argb: 'FFFFFF00' },
      //     bgColor: { argb: 'FF0000FF' }
      //   }
      //   cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      // })

      // const ActualCont = ['Actual', '', '', '', '', '', '', '']

      // for (let i = 1; i < 100; i += 1) {
      //   const ActualRowCont = contWorkSheet.addRow(ActualCont)

      //   ActualRowCont.eachCell((cell, number) => {
      //     cell.alignment = { vertical: 'middle', horizontal: 'center' }
      //     cell.fill = {
      //       type: 'pattern',
      //       pattern: 'solid',
      //       fgColor: { argb: 'E5E0EC' },
      //       bgColor: { argb: 'FF0000FF' }
      //     }
      //     cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      //   })
      // }

      // const startDatecellCont = contWorkSheet.getColumn(6)
      // startDatecellCont.eachCell((c, rowNumber) => {
      //   c.numFmt = '@'
      // })

      // for (let i = 3; i < 10000; i += 1) {
      //   contWorkSheet.getCell(`D${i}`).dataValidation = {
      //     type: 'list',
      //     allowBlank: false,
      //     formulae: ['=Levels!$A$1:$A$9999'],
      //     showErrorMessage: true,
      //     errorStyle: 'error',
      //     error: 'Please select a valid value from the list'
      //   }

      //   contWorkSheet.getCell(`G${i}`).dataValidation = {
      //     type: 'list',
      //     allowBlank: false,
      //     formulae: ['=LevelsLoc!$A$1:$A$9999'],
      //     showErrorMessage: true,
      //     errorStyle: 'error',
      //     error: 'Please select a valid value from the list'
      //   }
      // }

      workbook.xlsx.writeBuffer().then(data => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        FileSaver.saveAs(blob, 'Employee.xlsx')
      })
    } else if (excelImportTabNo === 4) {
      const heading = 'Please list your contractors in the tinted cells below'
      const header = [
        '',
        'Contractor or Entity Name',
        'Purpose/Function',
        'Department',
        'Title of Function',
        'Start Date',
        'Location',
        'Annualized Compensation'
      ]
      const sampleH = ['Sample', 'ACME Inc.', 'SEO Consulting', 'Sales', 'SEO Consulting', '03/06/2022', 'AK - Alaska', '8000']

      const workbook = new Workbook()

      const worksheet = workbook.addWorksheet('contractors')
      const department = workbook.addWorksheet('Levels')
      department.state = 'hidden'
      departmentList.forEach((dept, index) => {
        department.insertRow(index + 1, [dept.name])
      })
      const contLocation = workbook.addWorksheet('LevelsLoc')
      contLocation.state = 'hidden'
      LocationsList.forEach((list, index) => {
        contLocation.insertRow(index, [`${list.abbreviation} - ${list.name}`])
      })

      worksheet.mergeCells('A1:H1')
      worksheet.getCell('A1').value = heading
      worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' }
      worksheet.getCell('A1').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      }
      worksheet.getCell('A1').border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }

      const headerRowCont = worksheet.addRow(header)
      const sampleRowCont = worksheet.addRow(sampleH)

      // Cell Style : Fill and Border

      headerRowCont.eachCell((cell, number) => {
        worksheet.getColumn(number).width = 17
        cell.alignment = { vertical: 'middle', horizontal: 'center' }
        cell.font = {
          bold: true
        }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'B0DAFC' },
          bgColor: { argb: 'FF0000FF' }
        }
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      })

      sampleRowCont.eachCell((cell, number) => {
        cell.alignment = { vertical: 'middle', horizontal: 'center' }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFF00' },
          bgColor: { argb: 'FF0000FF' }
        }
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      })

      const ActualCont = ['Actual', '', '', '', '', '', '', '']

      for (let i = 1; i < 100; i += 1) {
        const ActualRowCont = worksheet.addRow(ActualCont)

        ActualRowCont.eachCell((cell, number) => {
          cell.alignment = { vertical: 'middle', horizontal: 'center' }
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'E5E0EC' },
            bgColor: { argb: 'FF0000FF' }
          }
          cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        })
      }

      const startDatecellCont = worksheet.getColumn(6)
      startDatecellCont.eachCell((c, rowNumber) => {
        c.numFmt = '@'
      })

      for (let i = 3; i < 10000; i += 1) {
        worksheet.getCell(`D${i}`).dataValidation = {
          type: 'list',
          allowBlank: false,
          formulae: ['=Levels!$A$1:$A$9999'],
          showErrorMessage: true,
          errorStyle: 'error',
          error: 'Please select a valid value from the list'
        }

        worksheet.getCell(`G${i}`).dataValidation = {
          type: 'list',
          allowBlank: false,
          formulae: ['=LevelsLoc!$A$1:$A$9999'],
          showErrorMessage: true,
          errorStyle: 'error',
          error: 'Please select a valid value from the list'
        }
      }

      workbook.xlsx.writeBuffer().then(data => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        FileSaver.saveAs(blob, 'Contractors.xlsx')
      })
    } else if (excelImportTabNo === 5) {
      const heading = 'Vendors/Expenses'
      const header = ['', 'Account (pre-populated)', 'Department', 'Frequency', 'Start Date', 'End Date (optional)', 'Amount']
      const sampleH = ['Sample', 'Utilities', 'Sales', 'Monthly', '03/06/2022', '03/06/2022', '8000']

      const workbook = new Workbook()

      const worksheet = workbook.addWorksheet('Vendors')
      const department = workbook.addWorksheet('Levels')
      const coa = workbook.addWorksheet('Levels2')
      department.state = 'hidden'
      coa.state = 'hidden'
      departmentList.forEach((dept, index) => {
        department.insertRow(index + 1, [dept.name])
      })
      coaList.forEach((dept, index) => {
        coa.insertRow(index + 1, [dept.name])
      })
      worksheet.getColumn('E').numFmt = '# ?/?'
      worksheet.mergeCells('A1:G1')
      worksheet.getCell('A1').value = heading
      worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' }
      worksheet.getCell('A1').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      }
      worksheet.getCell('A1').border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }

      const headerRow = worksheet.addRow(header)
      const sampleRow = worksheet.addRow(sampleH)
      // Cell Style : Fill and Border
      headerRow.eachCell((cell, number) => {
        worksheet.getColumn(number).width = 17
        cell.alignment = { vertical: 'middle', horizontal: 'center' }
        cell.font = {
          bold: true
        }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'B0DAFC' },
          bgColor: { argb: 'FF0000FF' }
        }
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      })

      sampleRow.eachCell((cell, number) => {
        cell.alignment = { vertical: 'middle', horizontal: 'center' }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFF00' },
          bgColor: { argb: 'FF0000FF' }
        }
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      })

      const Actual = ['Actual', '', '', '', '', '', '']

      for (let i = 1; i < 100; i += 1) {
        const ActualRow = worksheet.addRow(Actual)

        ActualRow.eachCell((cell, number) => {
          cell.alignment = { vertical: 'middle', horizontal: 'center' }
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'E5E0EC' },
            bgColor: { argb: 'FF0000FF' }
          }
          cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        })
      }

      const startDatecell = worksheet.getColumn(5)
      startDatecell.eachCell((c, rowNumber) => {
        c.numFmt = '@'
      })

      const endDatecell = worksheet.getColumn(6)
      endDatecell.eachCell((c, rowNumber) => {
        c.numFmt = '@'
      })

      for (let i = 3; i < 10000; i += 1) {
        worksheet.getCell(`C${i}`).dataValidation = {
          type: 'list',
          allowBlank: false,
          formulae: ['=Levels!$A$1:$A$9999'],
          showErrorMessage: true,
          errorStyle: 'error',
          error: 'Please select a valid value from the list'
        }
        worksheet.getCell(`B${i}`).dataValidation = {
          type: 'list',
          allowBlank: false,
          formulae: ['=Levels2!$A$1:$A$9999'],
          showErrorMessage: true,
          errorStyle: 'error',
          error: 'Please select a valid value from the list'
        }
        worksheet.getCell(`D${i}`).dataValidation = {
          type: 'list',
          allowBlank: false,
          formulae: ['"Monthly,Quarterly,Annually"'],
          showErrorMessage: true,
          errorStyle: 'error',
          error: 'Please select a valid value from the list'
        }
      }

      workbook.xlsx.writeBuffer().then(data => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        FileSaver.saveAs(blob, 'Vendors.xlsx')
      })
    } else if (excelImportTabNo === 6) {
      FileSaver.saveAs(RevenueTopLine, 'Revenue_pro_services_top_line.xlsx', { type: 'text/plain;charset=utf-8' })
    } else if (excelImportTabNo === 7) {
      FileSaver.saveAs(RevenueDetailedProduct, 'Revenue_detailed_product.xlsx', { type: 'text/plain;charset=utf-8' })
    } else if (excelImportTabNo === 8) {
      // FileSaver.saveAs(RevenueDetailedClient, 'Revenue_detailed_client.xlsx', { type: 'text/plain;charset=utf-8' })
      handleClientDetailedDownload()
    } else if (excelImportTabNo === 9) {
      FileSaver.saveAs(RevenueCommerceRetail, 'Revenue_commerce_retail.xlsx', { type: 'text/plain;charset=utf-8' })
    } else if (excelImportTabNo === 10) {
      FileSaver.saveAs(RevenueSaas, 'Revenue_Saas.xlsx', { type: 'text/plain;charset=utf-8' })
    }
  }

  const handleChange = event => {
    const fileObj = event.target.files && event.target.files[0]

    const f = event.target.files[0]
    if (!fileObj) {
      return
    }
    const reader = new FileReader()

    reader.onload = function check(e) {
      const departmentLists = []
      const coaLists = []
      const employeeLists = []
      const contractorLists = []
      const vendorLists = []
      const clientList = []
      const productLists = []
      // const revenueComputationType = ''
      // const revenueModel = ''

      const xlUploadedDatas = {
        // departmentLists,
        // coaLists
        // employeeList,
        // contractorList,
        // vendorList,
        // clientList,
        // revenueComputationType,
        // revenueModel,
        // productList
      }
      const data = e.target.result

      const readedData = XLSX.read(data, { type: 'binary' })
      // const wsnameCompanyInfo = readedData.SheetNames[0]
      // const departmentSheet = readedData.SheetNames[1]
      // const coaSheet = readedData.SheetNames[2]
      // const employeeSheet = readedData.SheetNames[3]
      // const contractorsSheet = readedData.SheetNames[4]
      // const vendorsSheet = readedData.SheetNames[5]
      const departmentSheet = readedData.SheetNames[0]
      const coaSheet = readedData.SheetNames[0]
      const employeeSheet = readedData.SheetNames[0]
      const contractorsSheet = readedData.SheetNames[0]
      const vendorsSheet = readedData.SheetNames[0]
      const proServicesTopLineSheet = readedData.SheetNames[0]
      const proServicesDetailedSheetProduct = readedData.SheetNames[0]
      const proServicesDetailedSheetClient = readedData.SheetNames[0]
      const revenueCommerceOrRetailSheet = readedData.SheetNames[0]
      const RevenueSaasSheet = readedData.SheetNames[0]
      // const companyInfo = readedData.Sheets[wsnameCompanyInfo]
      const departmentSheetData = readedData.Sheets[departmentSheet]
      const coaSheetData = readedData.Sheets[coaSheet]
      const employeeSheetData = readedData.Sheets[employeeSheet]
      const contractorSheetData = readedData.Sheets[contractorsSheet]
      const vendorsSheetData = readedData.Sheets[vendorsSheet]
      const proServicesTopLineSheetData = readedData.Sheets[proServicesTopLineSheet]
      const proServicesDetailedSheetProductData = readedData.Sheets[proServicesDetailedSheetProduct]
      const proServicesDetailedSheetClientData = readedData.Sheets[proServicesDetailedSheetClient]
      const revenueCommerceOrRetailSheetData = readedData.Sheets[revenueCommerceOrRetailSheet]
      const RevenueSaasSheetData = readedData.Sheets[RevenueSaasSheet]
      // const companyInfoData = XLSX.utils.sheet_to_json(companyInfo, { header: 1 })
      const departmentData = XLSX.utils.sheet_to_json(departmentSheetData, { header: 1 })
      const coaData = XLSX.utils.sheet_to_json(coaSheetData, { header: 1 })
      const EmployeeData = XLSX.utils.sheet_to_json(employeeSheetData, { header: 1 })
      const contractorData = XLSX.utils.sheet_to_json(contractorSheetData, { header: 1 })
      const vendorData = XLSX.utils.sheet_to_json(vendorsSheetData, { header: 1 })
      const ProServicesTopLineData = XLSX.utils.sheet_to_json(proServicesTopLineSheetData, { header: 1 })
      const proServicesDetailedProductData = XLSX.utils.sheet_to_json(proServicesDetailedSheetProductData, { header: 1 })
      const proServicesDetailedClientData = XLSX.utils.sheet_to_json(proServicesDetailedSheetClientData, { header: 1 })
      const revenueCommerceOrRetailData = XLSX.utils.sheet_to_json(revenueCommerceOrRetailSheetData, { header: 1 })
      const RevenueSaasData = XLSX.utils.sheet_to_json(RevenueSaasSheetData, { header: 1 })

      // Writing to our file
      // reader.writeFile(employeeSheetData, 'test.xlsx')
      // const companyName = companyInfoData[0][1]
      // const companyEmail = companyInfoData[1][1]
      // const industry = companyInfoData[2][1]
      // const bankBalance = companyInfoData[3][1]
      // const taxYearEnd = companyInfoData[4][1] === 1 ? new Date('2022-06-24T12:42:46.720Z') : new Date('2022-12-24T12:42:46.720Z')
      // const department = companyInfoData[5][1]
      // read from a XLS file

      // let isError = false
      if (excelImportTabNo === 1) {
        departmentData.slice(2).forEach(data => {
          if (data[2]) departmentLists.push({ name: data[2] })
        })
        if (departmentLists.length === 0) {
          dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Some required fields are missing',
            variant: 'alert',
            alertSeverity: 'warning'
          })
        }
        const data = {
          id,
          departmentList: departmentLists
        }
        dispatch(updateCompany(data))
        // dispatch(setCompanySetupModalScreen(0))
      } else if (excelImportTabNo === 2) {
        coaData.slice(3).forEach(data => {
          // const tempObject = { name: data[0], nestedUnder: data[1], cogs: data[2], hrRelated: data[3], govtTaxed: data[4] }

          const tempObject = {
            name: data[1],
            nestedUnder: data[2],
            cogs: data[3] === 'Y',
            hrRelated: data[4] === 'Y',
            govtTaxed: data[5] === 'Y'
          }

          try {
            coavalidationSchema.validateSync(tempObject)
            coaLists.push(tempObject)
          } catch (e) {
            // isError = true
          }
        })

        if (coaLists.length === 0) {
          dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Some required fields are missing',
            variant: 'alert',
            alertSeverity: 'warning'
          })
        }
        const data = {
          id,
          vendorList: coaLists
        }
        dispatch(updateCompany(data))
      } else if (excelImportTabNo === 3) {
        EmployeeData.slice(3).forEach(data => {
          const tempObject = {
            name: data[1],
            department: data[2],
            title: data[3],
            startDate: moment(data[4]).format('MM/DD/YYYY'),
            location: data[5],
            salary: +data[6]
          }

          try {
            employeeValidationSchema.validateSync(tempObject)
            employeeLists.push(tempObject)
          } catch (e) {
            // isError = true
          }
        })
        if (employeeLists.length === 0) {
          dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Some required fields are missing',
            variant: 'alert',
            alertSeverity: 'warning'
          })
        }
        const data = {
          id,
          employeeList: employeeLists
        }
        dispatch(updateCompany(data))
      } else if (excelImportTabNo === 4) {
        contractorData.slice(3).forEach(data => {
          const tempObject = {
            name: data[1],
            department: data[3],
            function: data[4],
            purpose: data[2],
            annualCompensation: +data[7],
            startDate: moment(data[5]).format('MM/DD/YYYY'),
            location: data[6]
          }

          try {
            contractorValidationSchema.validateSync(tempObject)
            contractorLists.push(tempObject)
          } catch (e) {
            // isError = true
          }
        })
        if (contractorLists.length === 0) {
          dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Some required fields are missing',
            variant: 'alert',
            alertSeverity: 'warning'
          })
        }
        const data = {
          id,
          contractorList: contractorLists
        }
        dispatch(updateCompany(data))
      } else if (excelImportTabNo === 5) {
        vendorData.slice(3).forEach(data => {
          const tempObject = {
            name: data[1],
            department: data[2],
            frequency: data[3],
            startDate: moment(data[4]).format('MM/DD/YYYY'),
            amount: +data[6],
            endDate: data[5]
          }

          try {
            vendorvalidationSchema.validateSync(tempObject)
            vendorLists.push(tempObject)
          } catch (e) {
            // isError = true
          }
        })
        if (vendorLists.length === 0) {
          dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Some required fields are missing',
            variant: 'alert',
            alertSeverity: 'warning'
          })
        }
        const data = {
          id,
          vendorList: vendorLists
        }

        dispatch(updateCompany(data))
      } else if (excelImportTabNo === 6) {
        ProServicesTopLineData.slice(4).forEach(data => {
          const tempObject = {
            name: data[1],
            growthIncrement: data[2],
            growthRate: data[3],
            startDate: moment(data[4]).format('MM/DD/YYYY'),
            startingRevenue: data[5]
          }
          try {
            clientListvalidationSchema.validateSync(tempObject)
            clientList.push(tempObject)

            // xlUploadedDatas.revenueComputationType = 'Top Line Billable'
            // xlUploadedDatas.revenueModel = 'Pro Services'
          } catch (e) {
            // isError = true
          }
        })
        if (clientList.length === 0) {
          dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Some required fields are missing',
            variant: 'alert',
            alertSeverity: 'warning'
          })
        }
        const data = {
          id,
          clientList
        }

        dispatch(updateCompany(data))
      } else if (excelImportTabNo === 7) {
        proServicesDetailedProductData.slice(4).forEach(data => {
          const tempObjectProduct = {
            name: data[1],
            price: data[3],
            unitCOGS: data[2],
            _id: new ObjectID().toString()
          }

          try {
            productListvalidationSchema.validateSync(tempObjectProduct)

            productLists.push(tempObjectProduct)

            // xlUploadedDatas.revenueComputationType = 'Detailed Billable + Products'
            // xlUploadedDatas.revenueModel = 'Pro Services'
          } catch (e) {
            // isError = true
          }
        })
        if (productLists.length === 0) {
          dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Some required fields are missing',
            variant: 'alert',
            alertSeverity: 'warning'
          })
        }
        const data = {
          id,
          productList: productLists
        }
        dispatch(updateCompany(data))

        // const Id = new ObjectID()

        //     const tempObjectProduct = {
        //     name: data[1],
        //     price: data[3],
        //     unitCOGS: data[2],
        //     _id: Id.toString()
        //   }
        // const tempObjectClient = {
        //   name: data[6],
        //   growthIncrement: data[8],
        //   growthRate: data[9],
        //   products: [tempObjectProduct._id],
        //   startingUnits: data[10]
        // }
      } else if (excelImportTabNo === 8) {
        proServicesDetailedClientData.slice(4).forEach(data => {
          const selectedProductlist = productList.filter(eachItem => eachItem.name === data[2])
          const selectedProductId = selectedProductlist.map(eachItem => eachItem._id)
          const tempObjectClient = {
            name: data[1],
            products: selectedProductId,
            growthIncrement: data[3],
            growthRate: data[4],
            startingUnits: data[5]
          }

          try {
            clientListvalidationSchemaDetailedData.validateSync(tempObjectClient)

            clientList.push(tempObjectClient)

            // xlUploadedDatas.revenueComputationType = 'Detailed Billable + Products'
            // xlUploadedDatas.revenueModel = 'Pro Services'
          } catch (e) {
            // isError = true
          }
        })
        if (productLists.length === 0) {
          dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Some required fields are missing',
            variant: 'alert',
            alertSeverity: 'warning'
          })
        }
        const data = {
          id,
          clientList
        }
        dispatch(updateCompany(data))
      } else if (excelImportTabNo === 9) {
        revenueCommerceOrRetailData.slice(3).forEach(data => {
          const tempObject = {
            cor: data[2],
            name: data[1],
            growthIncrement: data[4],
            growthRate: data[5],
            price: data[3],
            startDate: new Date(data[7]).toLocaleDateString('en-US'),
            startingUnits: data[6]
          }
          try {
            productListvalidationSchemaCommerceOrRetailData.validateSync(tempObject)
            productLists.push(tempObject)

            // xlUploadedDatas.revenueComputationType = 'Detailed Billable + Products'
            // xlUploadedDatas.revenueModel = 'Commerce / Retail'
          } catch (e) {
            // isError = true
          }
        })
        if (productLists.length === 0) {
          dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Some required fields are missing',
            variant: 'alert',
            alertSeverity: 'warning'
          })
        }
        const data = {
          id,
          productList: productLists
        }
        dispatch(updateCompany(data))
      } else if (excelImportTabNo === 10) {
        RevenueSaasData.slice(3).forEach(data => {
          const tempObject = {
            name: data[1],
            price: data[4],
            cor: data[3],
            revenueModelType: data[2],
            customerGrowthRate: data[8],
            startDate: data[6],
            growthIncrement: data[7],
            startingCustomers: data[5]
          }
          try {
            productListvalidationSchemaSaasData.validateSync(tempObject)
            productLists.push(tempObject)

            // xlUploadedDatas.revenueComputationType = 'Detailed Billable + Products'
            // xlUploadedDatas.revenueModel = 'SaaS'
          } catch (e) {
            // isError = true
          }
        })
        if (productLists.length === 0) {
          dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Some required fields are missing',
            variant: 'alert',
            alertSeverity: 'warning'
          })
        }
        const data = {
          id,
          productList: productLists
        }
        dispatch(updateCompany(data))
      }

      // coaData.slice(2).forEach(data => {
      //   // const tempObject = { name: data[0], nestedUnder: data[1], cogs: data[2], hrRelated: data[3], govtTaxed: data[4] }

      //   const tempObject = {
      //     name: data[1],
      //     nestedUnder: data[2],
      //     cogs: data[3] === 'Y',
      //     hrRelated: data[4] === 'Y',
      //     govtTaxed: data[5] === 'Y'
      //   }
      //   try {
      //     coavalidationSchema.validateSync(tempObject)
      //     coaList.push(tempObject)
      //   } catch (e) {
      //
      //     // isError = true
      //   }
      // })

      // EmployeeData.slice(2).forEach(data => {
      //   const tempObject = {
      //     // name: data[0],
      //     // department: data[1],
      //     // title: data[2],
      //     // startDate: data[3],
      //     // location: data[4],
      //     // salary: data[5]
      //     name: data[1],
      //     department: data[2],
      //     title: data[3],
      //     startDate: moment(data[4]).format('MM/DD/YYYY'),
      //     location: data[5],
      //     salary: +data[6]
      //   }

      //   try {
      //     employeeValidationSchema.validateSync(tempObject)
      //     employeeList.push(tempObject)
      //   } catch (e) {
      //
      //     // isError = true
      //   }
      // })

      // contractorData.slice(2).forEach(data => {
      //   const tempObject = {
      //     name: data[1],
      //     deparment: data[3],
      //     function: data[4],
      //     purpose: data[2],
      //     annualCompensation: +data[7],
      //     startDate: moment(data[5]).format('MM/DD/YYYY'),
      //     location: data[6]
      //   }

      //   // const tempObject = {
      //   //   name: data[0],
      //   //   deparment: data[1],
      //   //   function: data[2],
      //   //   purpose: data[3],
      //   //   annualCompensation: data[4],
      //   //   startDate: data[5],
      //   //   location: data[6]
      //   // name: data[1],
      //   // purposeFun: data[2],
      //   // department: data[3],
      //   // title: data[4],
      //   // startDate: data[5],
      //   // location: data[6],
      //   // annualCompensation: data[7]
      //   // }

      //   try {
      //     contractorValidationSchema.validateSync(tempObject)
      //     contractorList.push(tempObject)
      //   } catch (e) {
      //
      //     // isError = true
      //   }
      // })

      // vendorData.slice(2).forEach(data => {
      //   const tempObject = {
      //     name: data[1],
      //     department: data[2],
      //     frequency: data[3],
      //     startDate: moment(data[4]).format('MM/DD/YYYY'),
      //     amount: +data[6],
      //     endDate: data[5]
      //   }

      //   try {
      //     vendorvalidationSchema.validateSync(tempObject)
      //     vendorList.push(tempObject)
      //   } catch (e) {
      //
      //     // isError = true
      //   }
      // })

      // // Revenue Data

      // if (ProServicesTopLineData.slice(3)[0].length > 1) {
      // ProServicesTopLineData.slice(3).forEach(data => {
      //   const tempObject = {
      //     name: data[1],
      //     growthIncrement: data[2],
      //     growthRate: data[3],
      //     startDate: moment(data[4]).format('MM/DD/YYYY'),
      //     startingRevenue: data[5]
      //   }
      //   try {
      //     clientListvalidationSchema.validateSync(tempObject)
      //     clientList.push(tempObject)

      //     xlUploadedDatas.revenueComputationType = 'Top Line Billable'
      //     xlUploadedDatas.revenueModel = 'Pro Services'
      //   } catch (e) {
      //
      //     // isError = true
      //   }
      // })
      // } else if (proServicesDetailedData.slice(3)[0].length > 6) {
      //
      //   proServicesDetailedData.slice(3).forEach(data => {
      //     const tempObjectProduct = {
      //       name: data[1],
      //       price: data[3],
      //       unitCOGS: data[2],
      //       _id: Id.toString()
      //     }
      //     const tempObjectClient = {
      //       name: data[6],
      //       growthIncrement: data[8],
      //       growthRate: data[9],
      //       products: [tempObjectProduct._id],
      //       startingUnits: data[10]
      //     }

      //     try {
      //       productListvalidationSchema.validateSync(tempObjectProduct)

      //       clientListvalidationSchemaDetailedData.validateSync(tempObjectClient)

      //       productList.push(tempObjectProduct)
      //       clientList.push(tempObjectClient)
      //       xlUploadedDatas.revenueComputationType = 'Detailed Billable + Products'
      //       xlUploadedDatas.revenueModel = 'Pro Services'
      //     } catch (e) {
      //
      //       // isError = true
      //     }
      //   })
      // } else if (revenueCommerceOrRetailData.slice(2)[0].length > 1) {
      //   revenueCommerceOrRetailData.slice(2).forEach(data => {
      //     const tempObject = {
      //       cor: data[2],
      //       name: data[1],
      //       growthIncrement: data[4],
      //       growthRate: data[5],
      //       price: data[3],
      //       startDate: moment(data[7]).format('MM/DD/YYYY'),
      //       startingUnits: data[6]
      //     }
      //     try {
      //       productListvalidationSchemaCommerceOrRetailData.validateSync(tempObject)
      //       productList.push(tempObject)

      //       xlUploadedDatas.revenueComputationType = 'Detailed Billable + Products'
      //       xlUploadedDatas.revenueModel = 'Commerce / Retail'
      //     } catch (e) {
      //
      //       // isError = true
      //     }
      //   })

      // } else if (RevenueSaasData.slice(2)[0].length > 1) {
      //   RevenueSaasData.slice(2).forEach(data => {
      //     const tempObject = {
      //       name: data[1],
      //       price: data[4],
      //       cor: data[3],
      //       revenueModelType: data[2],
      //       customerGrowthRate: data[8],
      //       startDate: data[6],
      //       growthIncrement: data[7],
      //       startingCustomers: data[5]
      //     }
      //     try {
      //       productListvalidationSchemaSaasData.validateSync(tempObject)
      //       productList.push(tempObject)

      //       xlUploadedDatas.revenueComputationType = 'Detailed Billable + Products'
      //       xlUploadedDatas.revenueModel = 'SaaS'
      //     } catch (e) {
      //
      //       // isError = true
      //     }
      //   })
      // } else {
      // }

      // if (!companyName || !companyEmail || !industry || !bankBalance) {
      // dispatch({
      //   type: SNACKBAR_OPEN,
      //   open: true,
      //   message: 'Some required fields are missing',
      //   variant: 'alert',
      //   alertSeverity: 'warning'
      // })
      // } else {
      //   dispatch(
      //     createCompanyFromExcel({
      //       name: companyName,
      //       industry,
      //       email: companyEmail,
      //       bankBalance,
      //       taxYearEnd,
      //       projectionMonths: 36,
      //       departmentList,
      //       coaList,
      //       employeeList,
      //       contractorList,
      //       vendorList
      //     })
      //   )
      // }

      //

      // if (isError) {
      //   dispatch({
      //     type: SNACKBAR_OPEN,
      //     open: true,
      //     message: 'Some required fields are missing',
      //     variant: 'alert',
      //     alertSeverity: 'warning'
      //   })
      // }

      //

      // if (!isError) {
      //   dispatch(setXlUplodeDatas(xlUploadedDatas))
      // }

      //

      // dispatch(setXlUplodeDatas(xlUploadedDatas))

      // dispatch(toggleExcelImportTabModel())
      // dispatch(setCompanySetupModalScreen(0))
    }
    reader.readAsBinaryString(f)
  }

  const handleClick = () => {
    inputRef.current.click()
  }

  return (
    <div style={{ width: '100%' }}>
      <Divider />
      {excelImportTabNo === 1 ? (
        <p className={cssClasses.drawerBoldText}>
          Need the template? Download it{' '}
          <button type="button" onClick={() => handleFileDownload()} className={cssClasses.spanHereDep}>
            here.
          </button>
        </p>
      ) : (
        <p className={cssClasses.drawerUploadDesc}>Choose to upload a file or enter the data manually below. </p>
      )}
      <div className={cssClasses.uploadFileCont}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'center' }}>
          {' '}
          <img src={UploadIcon} alt="upload_icon" className={cssClasses.uploadIconImg} />
          <Typography className={cssClasses.pdragAndDrop}>
            Drag & drop files or{' '}
            <button onClick={() => handleClick()} type="button" className={cssClasses.spanBrowser}>
              {' '}
              Browse{' '}
            </button>
            <input ref={inputRef} onChange={handleChange} type="file" style={{ display: 'none' }} />
          </Typography>
          <Typography className={cssClasses.textFormates}>
            Please be aware that this file must be saved as a Microsoft Excel file to upload successfully. Please make sure to uncheck the
            Summary Worksheet when doing so.
          </Typography>
        </div>

        {excelImportTabNo !== 1 && (
          <Typography className={cssClasses.textDownload}>
            {' '}
            Need the template? Download it{' '}
            <button type="button" onClick={() => handleFileDownload()} className={cssClasses.spanHere}>
              here
            </button>
          </Typography>
        )}
      </div>{' '}
      {/* <Dialog
      fullWidth
      maxWidth="sm"
      style={{ height: '370px', margin: 'auto' }}
      open={excelImportTabVisible}
      onClose={(event, reason) => {}}
      aria-labelledby="max-width-dialog-title"
      sx={{ py: 0 }}
      className={classes.muiDialog}
    >
      <DialogTitle id="max-width-dialog-title">
        Import Data
        <IconButton
          aria-label="close"
          onClick={() => {
            dispatch(toggleExcelImportTabModel())
          }}
          sx={{
            position: 'absolute',
            right: 24,
            top: 24,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ py: 0 }} className={classes.muiDialogContent}>
        <Grid container>
          <Grid container spacing={3} style={{ textAlign: 'center', paddingLeft: '15px', paddingRight: '25px' }}>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 'bold' }}>Import Data From Excel Sheet</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography> Download template, fill details and import </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ direction: 'column', alignItems: 'center', justifyContent: 'center', mt: 2 }} justify="center">
            <Grid item style={{ textAlign: 'right' }}>
              <Card
                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
                className={classes.icon}
                onClick={() => handleFileDownload()}
              >
                <Typography>Download Template</Typography>
                <IconDownload />
              </Card>
            </Grid>
            <Grid item>
              <Card
                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
                className={classes.icon}
                onClick={() => handleClick()}
              >
                <Typography>Upload File</Typography>
                <input ref={inputRef} onChange={handleChange} type="file" style={{ display: 'none' }} />
                <IconUpload />
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog> */}
    </div>
  )
}

export default ExcelImporTabModel
