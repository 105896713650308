import React, { useEffect, useRef } from 'react'

import cssClasses from 'ui-component/company-setup-modal/infoTab.module.css'

import { Formik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { setRegistrationSetupModelScreen, uiSelector } from 'store/ui/ui.slice'
import { companySelector } from 'store/company/company.slice'
import { FormHelperText } from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import { makeStyles } from '@material-ui/styles'
import NumberFormat from 'react-number-format'

const useStyles = makeStyles(theme => ({
  inputFocusedDate: {
    '& .MuiInputBase-input , .MuiOutlinedInput-root ': { backgroundColor: '#ffff' },
    border: '1px solid  #d8d8d8',
    borderRadius: '4px',
    color: '#29A364',
    height: '42px'
  },
  inputNonfocusedDate: {
    '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
    '& .MuiInputBase-input , .MuiOutlinedInput-root': { backgroundColor: '#ffff', color: '#B1B3B7' },
    marginTop: '0',
    '& .MuiInputAdornment-root': { display: 'none' }
  }
}))

const Saas = props => {
  const classes = useStyles()
  const { currentIndexNo, setCurrentIndex, isDemoCompany } = props
  const formRefSaaS = useRef(null)
  const inputFieldRef = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]
  const dispatch = useDispatch()
  const { registrationSetupModelScreen } = useSelector(uiSelector)
  const { id } = useSelector(companySelector)
  const CurrentDate = new Date().setHours(0, 0, 0, 0)

  // const formRevenueSaaSInitialValues = {
  //   _id: '',
  //   name: '',
  //   cor: '',
  //   price: '',
  //   startingCustomers: '',
  //   startDate: null,
  //   customerGrowthRate: '',
  //   startingUsage: '',
  //   usageGrowthRate: '',
  //   pricingTier: 'Starter Plan',
  //   submit: null
  // }

  const formRevenueSaaSInitialValues = {
    pricingTier: '',
    name: '',
    startDate: '',
    cor: '',
    price: '',
    price_varies: '',
    growthRate: ''
  }

  useEffect(() => {
    if (inputFieldRef[0].current) {
      inputFieldRef[0]?.current?.focus()
    }
  }, [])
  useEffect(() => {
    inputFieldRef[currentIndexNo - 2]?.current?.focus()
  }, [currentIndexNo])

  const onClickOkButton = fieldName => {
    formRefSaaS.current.setFieldTouched(fieldName, true)
    if (inputFieldRef[currentIndexNo - 2].current?.value !== '' && !formRefSaaS.current.errors[fieldName]) {
      if (currentIndexNo === 8) {
        if (formRefSaaS.current) {
          formRefSaaS.current?.handleSubmit()
        }
      } else {
        setCurrentIndex(prevState => prevState + 1)
      }
    } else {
      inputFieldRef[currentIndexNo - 2]?.current?.focus()
    }
  }

  // const handleAddRevenueSaasTabInputs = () => { }

  const onClickNextButton = () => {
    if (currentIndexNo === 8) {
      if (formRefSaaS.current) {
        formRefSaaS.current?.handleSubmit()
      }
    } else {
      setCurrentIndex(prevState => prevState + 1)
    }
  }
  const onClickTopLIneNextButton = () => {
    if (inputFieldRef[currentIndexNo - 2].current?.value !== '') {
      if (currentIndexNo === 8) {
        if (formRefSaaS.current) {
          formRefSaaS.current?.handleSubmit()
        }
      } else {
        setCurrentIndex(prevState => prevState + 1)
      }
    } else {
      inputFieldRef[currentIndexNo - 2]?.current?.focus()
    }
  }
  const handleKeyPress = (event, index) => {
    if (event.charCode === 13) {
      event.preventDefault()
      onClickOkButton(event.target.name)
    }
  }
  const getClassByIndex = fieldIndex => {
    const isPreviousIndex = currentIndexNo - 1 === fieldIndex
    const isNextIndex = currentIndexNo + 1 === fieldIndex

    switch (true) {
      case isPreviousIndex:
        return cssClasses.nonFocusedBoxTop
      case isNextIndex:
        return cssClasses.nonFocusedBoxBottom
      default:
        return cssClasses.box
    }
  }

  return (
    <Formik
      initialValues={formRevenueSaaSInitialValues}
      innerRef={formRefSaaS}
      enableReinitialize
      validationSchema={Yup.object().shape({
        pricingTier: Yup.string().required(' Pricing Tier is reqired'),
        // name: Yup.string().required(' Product Name is reqired'),
        startDate: Yup.string().required(' Start Date is reqired').typeError('Please enter a valid date'),
        cor: Yup.string()
          .required(' Product Cost is reqired')
          .matches(/^(\$?\d{1,3}(,\d{3})*(\.\d+)?|\.\d+)$/, 'Enter a valid Amount'),
        price: Yup.string()
          .required(' Product Selling Price is reqired')
          .matches(/^(\$?\d{1,3}(,\d{3})*(\.\d+)?|\.\d+)$/, 'Enter a valid Amount'),
        price_varies: Yup.boolean().required('This field is required'),
        growthRate: Yup.string()
          .required('Growth Rate is required')
          .matches(/^(\$?\d{1,3}(,\d{3})*(\.\d+)?|\.\d+)$/, 'Enter a valid number'),
        invoice: Yup.string()
          .required('Number of units is required')
          .matches(/^\d+(\.\d+)?$/, 'Enter a valid number')
        // growthIncrement: Yup.string()
        //   .required('Growth increment is required')
        //   .matches(/^\d+(\.\d+)?$/, 'Enter a valid number')
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting, setFieldError, resetForm }) => {
        // handleAddRevenueSaasTabInputs() TODO: Why is this not finished
        setSubmitting(false)
        dispatch(setRegistrationSetupModelScreen(registrationSetupModelScreen + 1))
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
        <form noValidate onSubmit={handleSubmit}>
          <div>
            {(currentIndexNo === 2 || currentIndexNo - 1 === 2) && (
              <div className={currentIndexNo === 2 ? cssClasses.box : cssClasses.nonFocusedBoxTop}>
                <label htmlFor="pricing_tier" className={currentIndexNo === 2 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}>
                  2. One of our pricing tiers, payment plans or units is called{' '}
                </label>
                {(currentIndexNo === 2 || currentIndexNo === 3) && (
                  <input
                    ref={ref => {
                      inputFieldRef[0].current = ref
                    }}
                    onKeyPress={event => handleKeyPress(event, 1)}
                    // style={values.pricingTier === 'Starter Plan' ? { color: '#858585' } : { backgroundColor: 'white' }}
                    disabled={isDemoCompany}
                    placeholder="Starter Plan"
                    className={currentIndexNo === 2 ? cssClasses.splInput : cssClasses.nonFocusedInputBox}
                    value={values.pricingTier}
                    name="pricingTier"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    id="pricing_tier"
                  />
                )}
                <div style={{ height: '10px' }}>
                  {touched.pricingTier && errors.pricingTier && <FormHelperText error> {errors.pricingTier} </FormHelperText>}
                </div>
                {currentIndexNo === 2 && (
                  <div className={cssClasses.okButtonContainer}>
                    <button
                      onClick={() => onClickOkButton('pricingTier')}
                      className={values.pricingTier ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                      type="button"
                    >
                      Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                    </button>
                    <p className={cssClasses.textPress}>
                      press <span className={cssClasses.textEnter}>Enter</span>
                    </p>
                  </div>
                )}
              </div>
            )}

            {(currentIndexNo === 3 || currentIndexNo - 1 === 3) && (
              <div className={getClassByIndex(3)}>
                <label htmlFor="startDate" className={currentIndexNo === 3 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}>
                  {/* 3. One of the products that company sells is{' '} */}
                  {`3. We started, or will start selling ${values.pricingTier}`}{' '}
                </label>
                {(currentIndexNo === 3 || currentIndexNo === 4) && (
                  // <input
                  //   ref={ref => {
                  //     inputFieldRef[1].current = ref
                  //   }}
                  //   onKeyPress={event => handleKeyPress(event, 2)}
                  //   disabled={isDemoCompany}
                  //   style={{ border: 'none', backgroundColor: '#ffffff' }}
                  //   placeholder="Type here..."
                  //   className={currentIndexNo === 3 ? cssClasses.focusedInputBox : cssClasses.nonFocusedInputBox}
                  //   value={values.name}
                  //   name="name"
                  //   onBlur={handleBlur}
                  //   onChange={handleChange}
                  //   id="product_name"
                  // />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={['month', 'year']}
                      inputRef={ref => {
                        inputFieldRef[1].current = ref
                      }}
                      name="startDate"
                      value={values.startDate}
                      onBlur={handleBlur}
                      placeholder="Select"
                      slotProps={{
                        textField: {
                          name: 'startDate',
                          id: 'startDate',
                          size: 'small',
                          error: false,
                          onKeyPress: event => {
                            handleKeyPress(event, 2)
                          }
                        }
                      }}
                      format="MM/YYYY"
                      sx={{
                        width: '300px',
                        height: '44px',
                        marginTop: '22px'
                      }}
                      onKeyPress={event => {
                        handleKeyPress(event, 2)
                      }}
                      // className={currentIndexNo === 3 ? cssClasses.selectBox : cssClasses.nonFocusedInputBox}
                      className={currentIndexNo === 3 ? classes.inputFocusedDate : classes.inputNonfocusedDate}
                      onChange={value => {
                        setFieldValue('startDate', value)
                      }}
                    />
                  </LocalizationProvider>
                )}
                <div style={{ height: '10px' }}>
                  {touched.name && errors.name && <FormHelperText error> {errors.name} </FormHelperText>}
                  {touched.startDate && errors.startDate && <FormHelperText error> {errors.startDate} </FormHelperText>}
                </div>
                {currentIndexNo === 3 && (
                  <div className={cssClasses.okButtonContainer}>
                    <button
                      onClick={() => onClickOkButton('startDate')}
                      className={values.startDate ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                      type="button"
                    >
                      Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                    </button>
                    <p className={cssClasses.textPress}>
                      press <span className={cssClasses.textEnter}>Enter</span>
                    </p>
                  </div>
                )}
              </div>
            )}

            {(currentIndexNo === 4 || currentIndexNo - 1 === 4) && (
              <div className={getClassByIndex(4)}>
                <label htmlFor="product_cost" className={currentIndexNo === 4 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}>
                  {` 4. This is how much it costs us to create a single unit of ${values.pricingTier} `}
                </label>

                {(currentIndexNo === 4 || currentIndexNo === 5) && (
                  <NumberFormat
                    prefix="$"
                    getInputRef={ref => {
                      inputFieldRef[2].current = ref
                    }}
                    onKeyPress={event => handleKeyPress(event, 3)}
                    disabled={isDemoCompany}
                    style={{ backgroundColor: '#ffffff' }}
                    placeholder="Type $ here..."
                    className={currentIndexNo === 4 ? cssClasses.focusedInputBox : cssClasses.nonFocusedInputBox}
                    value={values.cor}
                    name="cor"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    id="product_cost"
                    thousandSeparator=","
                  />
                )}
                <div style={{ height: '10px' }}>{touched.cor && errors.cor && <FormHelperText error> {errors.cor} </FormHelperText>}</div>
                {currentIndexNo === 4 && (
                  <div className={cssClasses.okButtonContainer}>
                    <button
                      onClick={() => onClickOkButton('cor')}
                      className={values.cor ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                      type="button"
                    >
                      Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                    </button>
                    <p className={cssClasses.textPress}>
                      press <span className={cssClasses.textEnter}>Enter</span>
                    </p>
                  </div>
                )}
              </div>
            )}

            {(currentIndexNo === 5 || currentIndexNo - 1 === 5) && (
              <div className={getClassByIndex(5)}>
                <label
                  htmlFor="product_selling_price"
                  className={currentIndexNo === 5 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}
                >
                  {`5. We sell a single unit of ${values.pricingTier} for`}{' '}
                </label>
                {(currentIndexNo === 5 || currentIndexNo === 6) && (
                  <NumberFormat
                    prefix="$"
                    getInputRef={ref => {
                      inputFieldRef[3].current = ref
                    }}
                    onKeyPress={event => handleKeyPress(event, 4)}
                    disabled={isDemoCompany}
                    style={{ backgroundColor: '#ffffff' }}
                    placeholder="Type $ here..."
                    className={currentIndexNo === 5 ? cssClasses.focusedInputBox : cssClasses.nonFocusedInputBox}
                    value={values.price}
                    name="price"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    id="product_selling_price"
                    thousandSeparator=","
                  />
                )}
                <div style={{ height: '10px' }}>
                  {touched.price && errors.price && <FormHelperText error> {errors.price} </FormHelperText>}
                </div>
                {currentIndexNo === 5 && (
                  <div className={cssClasses.okButtonContainer}>
                    <button
                      onClick={() => onClickOkButton('price')}
                      className={values.price ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                      type="button"
                    >
                      Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                    </button>
                    <p className={cssClasses.textPress}>
                      press <span className={cssClasses.textEnter}>Enter</span>
                    </p>
                  </div>
                )}
              </div>
            )}

            {(currentIndexNo === 6 || currentIndexNo - 1 === 6 || currentIndexNo - 3 === 6) && (
              <div className={getClassByIndex(6)}>
                <label htmlFor="price_varies" className={currentIndexNo === 6 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}>
                  6. Our pricing varies based on usage{' '}
                </label>
                {(currentIndexNo === 6 || currentIndexNo === 7) && (
                  <select
                    ref={ref => {
                      inputFieldRef[4].current = ref
                    }}
                    onKeyPress={event => handleKeyPress(event, 5)}
                    className={
                      currentIndexNo === 6 ? `${cssClasses.selectBox} ${cssClasses.coaAccountInput}` : cssClasses.nonFocusedSelectBox
                    }
                    placeholder="Select"
                    id="price_varies"
                    disabled={isDemoCompany}
                    value={values.price_varies}
                    name="price_varies"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    <option selected value="false">
                      False
                    </option>
                    <option value="true">True</option>
                  </select>
                )}

                <div style={{ height: '10px' }}>
                  {touched.price_varies && errors.price_varies && <FormHelperText error> {errors.price_varies} </FormHelperText>}
                </div>
                {currentIndexNo === 6 && (
                  <div className={cssClasses.okButtonContainer}>
                    <button
                      onClick={() => onClickOkButton('price_varies')}
                      className={values.price_varies ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                      type="button"
                    >
                      Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                    </button>
                    <p className={cssClasses.textPress}>
                      press <span className={cssClasses.textEnter}>Enter</span>
                    </p>
                  </div>
                )}
              </div>
            )}

            {(currentIndexNo === 7 || currentIndexNo - 1 === 7) && (
              <div className={getClassByIndex(7)}>
                <label htmlFor="invoice" className={currentIndexNo === 7 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}>
                  {CurrentDate > new Date(values.startDate).setHours(0, 0, 0, 0)
                    ? `7. Last month, we sold this many units of ${values.pricingTier}`
                    : `7. We plan to sell this many units of ${values.pricingTier} in its first month on sale`}
                </label>
                {(currentIndexNo === 7 || currentIndexNo === 8) && (
                  <input
                    ref={ref => {
                      inputFieldRef[5].current = ref
                    }}
                    onKeyPress={event => handleKeyPress(event, 6)}
                    disabled={isDemoCompany}
                    style={{ backgroundColor: '#ffffff' }}
                    placeholder="Type here..."
                    className={currentIndexNo === 7 ? cssClasses.focusedInputBox : cssClasses.nonFocusedInputBox}
                    value={values.invoice}
                    name="invoice"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    id="invoice"
                  />
                  // <LocalizationProvider dateAdapter={AdapterDayjs}>
                  //   <DatePicker
                  //     views={['month', 'year']}
                  //     inputRef={ref => {
                  //       inputFieldRef[5].current = ref
                  //     }}
                  //     name="startDate"
                  //     value={values.startDate}
                  //     onBlur={handleBlur}
                  //     placeholder="Select"
                  //     slotProps={{
                  //       textField: {
                  //         id: 'startDate',
                  //         size: 'small',
                  //         error: false,
                  //         onKeyPress: event => {
                  //           handleKeyPress(event, 6)
                  //         }
                  //       }
                  //     }}
                  //     format="MM/YYYY"
                  //     sx={{
                  //       width: '300px',
                  //       height: '44px',
                  //       marginTop: '22px'
                  //     }}
                  //     onKeyPress={event => {
                  //       console.log(event, 'event')
                  //       handleKeyPress(event, 6)
                  //     }}
                  //     // className={currentIndexNo === 3 ? cssClasses.selectBox : cssClasses.nonFocusedInputBox}
                  //     className={currentIndexNo === 7 ? classes.inputFocusedDate : classes.inputNonfocusedDate}
                  //     onChange={value => {
                  //       setFieldValue('startDate', value)
                  //     }}
                  //   />
                  // </LocalizationProvider>
                )}
                <div style={{ height: '10px' }}>
                  {touched.invoice && errors.invoice && <FormHelperText error> {errors.invoice} </FormHelperText>}
                </div>{' '}
                {currentIndexNo === 7 && (
                  <div className={cssClasses.okButtonContainer}>
                    <button
                      onClick={() => onClickOkButton('invoice')}
                      className={values.startDate ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                      type="button"
                    >
                      Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                    </button>
                    <p className={cssClasses.textPress}>
                      press <span className={cssClasses.textEnter}>Enter</span>
                    </p>
                  </div>
                )}
              </div>
            )}

            {(currentIndexNo === 8 || currentIndexNo - 1 === 8) && (
              <div className={currentIndexNo === 8 ? cssClasses.box : cssClasses.nonFocusedBoxBottom}>
                <label htmlFor="growth_rate" className={currentIndexNo === 8 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}>
                  {`8. We generally expect to increase sales to ${values.pricingTier} each month by`}{' '}
                </label>
                {(currentIndexNo === 8 || currentIndexNo === 9) && (
                  <NumberFormat
                    getInputRef={ref => {
                      inputFieldRef[6].current = ref
                    }}
                    onKeyPress={event => handleKeyPress(event, 7)}
                    disabled={isDemoCompany}
                    style={{ backgroundColor: '#ffffff' }}
                    placeholder="Type $ here..."
                    className={currentIndexNo === 8 ? cssClasses.focusedInputBox : cssClasses.nonFocusedInputBox}
                    value={values.growthRate}
                    name="growthRate"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    id="growth_rate"
                  />
                )}
                <div style={{ height: '10px' }}>
                  {touched.growthRate && errors.growthRate && <FormHelperText error> {errors.growthRate} </FormHelperText>}
                </div>
                {/* {currentIndexNo === 8 && (
                  <div className={cssClasses.okButtonContainer}>
                    <button
                      onClick={onClickOkButton}
                      className={values.biggestExpenses ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                      type="button"
                    >
                      Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                    </button>
                    <p className={cssClasses.textPress}>
                      press <span className={cssClasses.textEnter}>Enter</span>
                    </p>
                  </div>
                )} */}
              </div>
            )}

            {currentIndexNo > 1 && (
              <button
                type="button"
                className={currentIndexNo === 8 ? cssClasses.formNextButton : cssClasses.disabledFormNextButton}
                onClick={onClickNextButton}
              >
                Next
              </button>
            )}
          </div>
        </form>
      )}
    </Formik>
  )
}

export default Saas
