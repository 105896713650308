// @ts-ignore\
// See: https://blog.risingstack.com/create-react-app-runtime-env-cra/
const [SERVER_URL, REDIRECT_URL, BOSSINSIGHT_REDIRECT_URL, TYPEFORM_FORM_ID] = [
  window.__RUNTIME_CONFIG__.API_SERVER_URL,
  window.__RUNTIME_CONFIG__.REDIRECT_URL,
  window.__RUNTIME_CONFIG__.BOSSINSIGHT_REDIRECT_URL,
  window.__RUNTIME_CONFIG__.TYPEFORM_FORM_ID
]

// eslint-disable-next-line import/prefer-default-export
export { SERVER_URL, REDIRECT_URL, BOSSINSIGHT_REDIRECT_URL, TYPEFORM_FORM_ID }
