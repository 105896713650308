import React, { lazy } from 'react'
import Loadable from 'ui-component/Loadable'
import MinimalLayout from 'layout/MinimalLayout'
import NavMotion from 'layout/NavMotion'
import GuestGuard from 'utils/route-guard/GuestGuard'
import AuthGuard from 'utils/route-guard/AuthGuard'

const Payments = Loadable(lazy(() => import('views/pages/wordpressPayments')))
const PaymentStatusCheck = Loadable(lazy(() => import('views/pages/wordpressPayments/PaymentSatusCheck')))

const WorpressPaymentRoutes = {
  path: '/',
  element: <MinimalLayout />,

  children: [
    {
      path: '/payments',
      element: <Payments />
    },
    {
      path: '/payment-status',
      element: <PaymentStatusCheck />
    }
  ]
}

export default WorpressPaymentRoutes
