import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import styles from '../../styles.module.css'
import PropTypes from 'prop-types'
import {
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
  Backdrop,
  CircularProgress,
  InputAdornment,
  Divider
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Formik } from 'formik'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { gridSpacing } from 'store/constant'
import IndustriesList from 'data/IndustriesList'
import { useNavigate } from 'react-router'
import {
  uiSelector,
  setCompanySetupModalScreen,
  updateModalTabVisibility,
  toggleCompanySetupModal,
  setSelectedMenuItem
} from 'store/ui/ui.slice'
import { getCompanyByID, createCompany, updateCompany, getCompaniesByUser, createCompanyFromExcel } from 'store/company/company.actions'
import {
  clearState,
  companySelector,
  resetState,
  setCurrentlyActiveCompanyId,
  setWelcomeScreenTriggering,
  setXlUplodeDatas
} from 'store/company/company.slice'
import { MENU_OPEN, SNACKBAR_OPEN } from 'store/actions'
import { quickbookSelector, resetQuickBookStatus } from 'store/quickBook/quickbook.slice'
import EditPencilIconDark from 'assets/images/icons/EditPencilIconDark.svg'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import integration2 from 'assets/images/icon2.png'
import integrationGroup from 'assets/images/intgroup1.png'
import intIconFi from 'assets/images/intIconFi.PNG'
import { authSelector, updateSelectedCompany } from 'store/auth/auth.slice'
import { SERVER_URL } from 'utils/API/index'
import cssClasses from './infoTab.module.css'
import IntegrationModel from './IntegrationModel'
import dayjs from 'dayjs'
import _ from 'lodash'

const ReviewTab = forwardRef((props, ref) => {
  const { isDemoCompany } = props
  const dispatch = useDispatch()
  const formRef = useRef(null)
  const { companySetupModalScreen } = useSelector(uiSelector)
  const [isFinish, setIsFinish] = useState(false)
  const { createdCompanyId } = useSelector(quickbookSelector)
  const { user } = useSelector(authSelector)
  const [loader, setLoader] = useState(false)
  const [hoverStyles, setHoverStyle] = useState({ display: 'none' })
  const [isIntegrationButtonClicked, setIntegrationButtonClicked] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [vendorGroupByParent, setVendorGroupByParent] = useState('')
  const navigate = useNavigate()

  const {
    departmentList,
    vendorList,
    contractorList,
    employeeList,
    clientList,
    productList,
    incompleteCompanyId,
    isSuccessCompany,
    isSuccess,
    createCompanyError,
    id,
    name,
    email,
    industry,
    bankBalance,
    taxYearEnd,
    revenueModel,
    revenueComputationType,
    selectedCompany,
    currentModelScreen,
    companySetupModalDismissed,
    companies,
    xlUplodeDatas,
    currentlyActiveCompanyId
  } = useSelector(companySelector)

  useImperativeHandle(ref, () => ({
    handleNext(flag) {
      // if (flag) setIsFinish(true)
      // if (formRef.current) formRef.current.handleSubmit()
      // dispatch(setSelectedMenuItem('department'))
      // dispatch(setCurrentlyActiveCompanyId(id))
      dispatch(toggleCompanySetupModal(false))

      dispatch(setWelcomeScreenTriggering(true))

      // navigate(`/budget?cid=${selectedCompany}`)
      // dispatch({ type: MENU_OPEN, id: 'models' })
    }
  }))

  const getParentName = parentId => {
    const parent = vendorList.find(eachItem => eachItem._id === parentId)
    return parent?.name
  }

  useEffect(() => {
    if (vendorList?.length > 0) {
      const vendorListSorted = _.sortBy(
        [...vendorList],
        [
          function (o) {
            return o.nestedUnder === 'None'
          }
        ]
      )

      const vendorListGroupBy = _.groupBy(vendorListSorted, 'nestedUnder')
      setVendorGroupByParent(vendorListGroupBy)
    }
  }, [vendorList])

  const CompanyTab = () => (
    <Grid item sx={{ width: '100%' }}>
      <div className={cssClasses.reviwContentHead}>
        <Typography className={cssClasses.reviewTabSubHeading}>Company</Typography>
        <button
          onClick={() => dispatch(setCompanySetupModalScreen(1))}
          type="button"
          style={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
            cursor: 'pointer',
            outline: 'none',
            border: 'none',
            background: 'trasparent'
          }}
        >
          <img src={EditPencilIconDark} alt="Edit_icon" />
          <Typography className={cssClasses.reviewEdit}>Edit</Typography>
        </button>
      </div>
      <Divider sx={{ width: '100%', margin: '9px 0 12px 0', color: '#D8D9DB', height: '2px' }} />
      <div className={cssClasses.reviewMainContents}>
        <Grid container className={cssClasses.reviewContentItem}>
          <Grid item xs={6}>
            <Typography className={cssClasses.reviewContentTitel}>Company name</Typography>
          </Grid>
          <Grid item xs={6}>
            <div>
              <Typography className={cssClasses.reviewContent}>{name}</Typography>
            </div>
          </Grid>
        </Grid>

        <Grid container className={cssClasses.reviewContentItem}>
          <Grid item xs={6}>
            <Typography className={cssClasses.reviewContentTitel}>Industry</Typography>
          </Grid>
          <Grid item xs={6}>
            <div>
              <Typography className={cssClasses.reviewContent}>{industry}</Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    </Grid>
  )
  const Departments = () => (
    <Grid item sx={{ width: '100%' }}>
      <div className={cssClasses.reviwContentHead}>
        <Typography className={cssClasses.reviewTabSubHeading}>Departments</Typography>
        <button
          onClick={() => dispatch(setCompanySetupModalScreen(2))}
          type="button"
          style={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
            cursor: 'pointer',
            outline: 'none',
            border: 'none',
            background: 'trasparent'
          }}
        >
          <img src={EditPencilIconDark} alt="Edit_icon" />
          <Typography className={cssClasses.reviewEdit}>Edit</Typography>
        </button>
      </div>
      <Divider sx={{ width: '100%', margin: '9px 0 12px 0' }} />
      <div className={cssClasses.reviewMainContents}>
        {departmentList?.map((item, index) => (
          <Grid container className={cssClasses.reviewContentItem} key={index}>
            <Grid item xs={6}>
              <Typography className={cssClasses.reviewContentTitel}>Department</Typography>
            </Grid>
            <Grid item xs={6}>
              <div>
                <Typography className={cssClasses.reviewContent}>{item.name}</Typography>
              </div>
            </Grid>
          </Grid>
        ))}
      </div>
    </Grid>
  )
  const Expenses = ({ item }) => {
    const [expand, setExpand] = useState(false)
    const start = new Date(item.startDate)
    const end = new Date(item.endDate)
    const startDate = dayjs(start).format('YYYY/MM/DD')
    const endDate = dayjs(end).format('YYYY/MM/DD')
    return (
      <>
        <Grid container className={cssClasses.reviewContentItem}>
          <Grid item xs={6}>
            <Typography className={cssClasses.reviewContentTitel}>Account</Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container direction="row" alignItems="center" columnSpacing={4} justifyContent="flex-start">
              <Grid item xs={5}>
                <Typography className={cssClasses.reviewContent}>{item.name}</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className={cssClasses.reviewContent}>
                  {vendorGroupByParent[item._id]?.length ? `Nested (${vendorGroupByParent[item._id]?.length})` : 'Nested (n/a)'}
                </Typography>
                {/* <Typography className={cssClasses.reviewContent}>Nested(4)</Typography> */}
              </Grid>
              {/* <p className={cssClasses.nestedNumber}>
                    {vendorGroupByParent[item._id]?.length ? vendorGroupByParent[item._id]?.length : 'N/A'}
                  </p>
                  {(expandItemId === eachItem._id || isExpandAllClicked) &&
                    vendorGroupByParent[eachItem._id] &&
                    vendorGroupByParent[eachItem._id]?.map((childItem, index) => (
                      <div key={`nestName${index}`} className={cssClasses.nestedItem}>
                        <span className={cssClasses.nestName}>{childItem.name}</span>
                      </div>
                    ))} */}
              <Grid item xs={2}>
                <div>
                  {!expand ? (
                    <KeyboardArrowUp
                      sx={{ width: '16px', height: '16px' }}
                      onClick={() => setExpand(prev => !prev)}
                      style={{ cursor: 'pointer' }}
                    />
                  ) : (
                    <KeyboardArrowDown
                      sx={{ width: '16px', height: '16px' }}
                      onClick={() => setExpand(prev => !prev)}
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {expand && (
          <Grid container spacing={0.5} direction="column">
            <Grid style={{ marginLeft: '7px' }} item>
              <Typography className={cssClasses.reviewExpnadHeading}>Parent</Typography>
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
            <Grid item>
              <Grid container className={cssClasses.reviewContentItem}>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContentTitel}>Account</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContent}>{item.name}</Typography>
                </Grid>
              </Grid>
              <Grid container className={cssClasses.reviewContentItem}>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContentTitel}>Nested under</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContent}>
                    {getParentName(item?.nestedUnder) ? getParentName(item?.nestedUnder) : 'N/A'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className={cssClasses.reviewContentItem}>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContentTitel}>Department</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContentTitel}>{item.department}</Typography>
                </Grid>
              </Grid>
              <Grid container className={cssClasses.reviewContentItem}>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContentTitel}>Taxed by govt</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContent}>Yes</Typography>
                </Grid>
              </Grid>
              <Grid container className={cssClasses.reviewContentItem}>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContentTitel}>Frequency</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContent}>{item.frequency}</Typography>
                </Grid>
              </Grid>
              <Grid container className={cssClasses.reviewContentItem}>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContentTitel}>Start date</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContent}>{startDate}</Typography>
                </Grid>
              </Grid>
              <Grid container className={cssClasses.reviewContentItem}>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContentTitel}>End date</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContent}>{endDate}</Typography>
                </Grid>
              </Grid>
              <Grid container className={cssClasses.reviewContentItem}>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContentTitel}>Amount</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContent}>{item.amount}</Typography>
                </Grid>
              </Grid>
              <Grid container className={cssClasses.reviewContentItem}>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContentTitel}>CoGS / CoR</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContent}>{item.cogs ? 'Yes' : 'No'}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    )
  }
  const Employee = ({ item }) => {
    const [expand, setExpand] = useState(false)
    const start = new Date(item.startDate)
    const startDate = dayjs(start).format('YYYY/MM/DD')

    return (
      <>
        <Grid container className={cssClasses.reviewContentItem}>
          <Grid item xs={6}>
            <Typography className={cssClasses.reviewContentTitel}>Employee name</Typography>
          </Grid>
          <Grid item xs={6}>
            <div className={cssClasses.reviewContentSecField}>
              <Typography className={cssClasses.reviewContent}>{item.name}</Typography>
              <div>
                {!expand ? (
                  <KeyboardArrowUp
                    sx={{ width: '16px', height: '16px' }}
                    onClick={() => setExpand(prev => !prev)}
                    style={{ cursor: 'pointer' }}
                  />
                ) : (
                  <KeyboardArrowDown
                    sx={{ width: '16px', height: '16px' }}
                    onClick={() => setExpand(prev => !prev)}
                    style={{ cursor: 'pointer' }}
                  />
                )}
              </div>
            </div>
          </Grid>
        </Grid>
        {expand && (
          <Grid container spacing={0.5} direction="column">
            {/* <Grid item>
              <Typography className={cssClasses.reviewExpnadHeading}>Parent</Typography>
            </Grid> */}
            {/* <Grid item>
              <Divider />
            </Grid> */}
            <Grid item>
              <Grid container className={cssClasses.reviewContentItem}>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContentTitel}>Employee type</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContent}>Employee</Typography>
                </Grid>
              </Grid>
              <Grid container className={cssClasses.reviewContentItem}>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContentTitel}>Department</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContent}>{item.department}</Typography>
                </Grid>
              </Grid>
              <Grid container className={cssClasses.reviewContentItem}>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContentTitel}>Title</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContent}>{item.title}</Typography>
                </Grid>
              </Grid>
              <Grid container className={cssClasses.reviewContentItem}>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContentTitel}>Start date</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContent}>{startDate}</Typography>
                </Grid>
              </Grid>
              <Grid container className={cssClasses.reviewContentItem}>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContentTitel}>Location</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContent}>{item.location}</Typography>
                </Grid>
              </Grid>
              <Grid container className={cssClasses.reviewContentItem}>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContentTitel}>Salary</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContent}>{item.salary}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    )
  }
  const Contractor = ({ item }) => {
    const [expand, setExpand] = useState(false)
    const start = new Date(item.startDate)
    const startDate = dayjs(start).format('YYYY/MM/DD')

    return (
      <>
        <Grid container className={cssClasses.reviewContentItem}>
          <Grid item xs={6}>
            <Typography className={cssClasses.reviewContentTitel}>Employee name</Typography>
          </Grid>
          <Grid item xs={6}>
            <div className={cssClasses.reviewContentSecField}>
              <Typography className={cssClasses.reviewContent}>{item.name}</Typography>
              <div>
                {!expand ? (
                  <KeyboardArrowUp
                    sx={{ width: '16px', height: '16px' }}
                    onClick={() => setExpand(prev => !prev)}
                    style={{ cursor: 'pointer' }}
                  />
                ) : (
                  <KeyboardArrowDown
                    sx={{ width: '16px', height: '16px' }}
                    onClick={() => setExpand(prev => !prev)}
                    style={{ cursor: 'pointer' }}
                  />
                )}
              </div>
            </div>
          </Grid>
        </Grid>{' '}
        {expand && (
          <Grid container spacing={0.5} direction="column">
            <Grid item>
              <Typography className={cssClasses.reviewExpnadHeading}>Parent</Typography>
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
            <Grid item>
              <Grid container className={cssClasses.reviewContentItem}>
                <Grid item xs={6}>
                  <Typography>Employee type</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Contractor</Typography>
                </Grid>
              </Grid>
              <Grid container className={cssClasses.reviewContentItem}>
                <Grid item xs={6}>
                  <Typography>Department</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{item.department}</Typography>
                </Grid>
              </Grid>
              <Grid container className={cssClasses.reviewContentItem}>
                <Grid item xs={6}>
                  <Typography>Job title</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{item.purpose}</Typography>
                </Grid>
              </Grid>
              <Grid container className={cssClasses.reviewContentItem}>
                <Grid item xs={6}>
                  <Typography>Start date</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{startDate}</Typography>
                </Grid>
              </Grid>
              <Grid container className={cssClasses.reviewContentItem}>
                <Grid item xs={6}>
                  <Typography>Location</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{item.location}</Typography>
                </Grid>
              </Grid>
              <Grid container className={cssClasses.reviewContentItem}>
                <Grid item xs={6}>
                  <Typography>Annualized compensation</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{item.annualCompensation}</Typography>
                </Grid>
              </Grid>
              <Grid container className={cssClasses.reviewContentItem}>
                <Grid item xs={6}>
                  <Typography>Contractor or entity name</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{item.function}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    )
  }

  const Product = ({ item }) => {
    const [expand, setExpand] = useState(false)
    const start = new Date(item.startDate)
    const startDate = dayjs(start).format('YYYY/MM/DD')

    return (
      <>
        <Grid container className={cssClasses.reviewContentItem}>
          <Grid item xs={6}>
            <Typography className={cssClasses.reviewContentTitel}>Product name</Typography>
          </Grid>
          <Grid item xs={6}>
            <div className={cssClasses.reviewContentSecField}>
              <Typography className={cssClasses.reviewContent}>{item.name}</Typography>
              <div>
                {!expand ? (
                  <KeyboardArrowUp
                    sx={{ width: '16px', height: '16px' }}
                    onClick={() => setExpand(prev => !prev)}
                    style={{ cursor: 'pointer' }}
                  />
                ) : (
                  <KeyboardArrowDown
                    sx={{ width: '16px', height: '16px' }}
                    onClick={() => setExpand(prev => !prev)}
                    style={{ cursor: 'pointer' }}
                  />
                )}
              </div>
            </div>
          </Grid>
        </Grid>{' '}
        {expand && (
          <Grid container spacing={0.5} direction="column">
            <Grid item>
              <Grid container className={cssClasses.reviewContentItem}>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContentTitel}>Product name</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContent}>{item.name}</Typography>
                </Grid>
              </Grid>
              <Grid container className={cssClasses.reviewContentItem}>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContentTitel}>Price</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContent}>{item.price}</Typography>
                </Grid>
              </Grid>
              <Grid container className={cssClasses.reviewContentItem}>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContentTitel}>unitCOGS</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContent}>{item.unitCOGS}</Typography>
                </Grid>
              </Grid>
              <Grid container className={cssClasses.reviewContentItem}>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContentTitel}>cor</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContent}>{item.cor}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    )
  }
  const Client = ({ item }) => {
    const [expand, setExpand] = useState(false)
    const start = new Date(item.startDate)
    const startDate = dayjs(start).format('YYYY/MM/DD')

    return (
      <>
        <Grid container className={cssClasses.reviewContentItem}>
          <Grid item xs={6}>
            <Typography className={cssClasses.reviewContentTitel}>Client name</Typography>
          </Grid>
          <Grid item xs={6}>
            <div className={cssClasses.reviewContentSecField}>
              <Typography className={cssClasses.reviewContent}>{item.name}</Typography>
              <div>
                {!expand ? (
                  <KeyboardArrowUp
                    sx={{ width: '16px', height: '16px' }}
                    onClick={() => setExpand(prev => !prev)}
                    style={{ cursor: 'pointer' }}
                  />
                ) : (
                  <KeyboardArrowDown
                    sx={{ width: '16px', height: '16px' }}
                    onClick={() => setExpand(prev => !prev)}
                    style={{ cursor: 'pointer' }}
                  />
                )}
              </div>
            </div>
          </Grid>
        </Grid>{' '}
        {expand && (
          <Grid container spacing={0.5} direction="column">
            <Grid item>
              <Grid container className={cssClasses.reviewContentItem}>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContentTitel}>Client name</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContent}>{item.name}</Typography>
                </Grid>
              </Grid>
              <Grid container className={cssClasses.reviewContentItem}>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContentTitel}>Growth increment</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContent}>{item.growthIncrement}</Typography>
                </Grid>
              </Grid>
              <Grid container className={cssClasses.reviewContentItem}>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContentTitel}>Growth rate</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContent}>{item.growthRate}</Typography>
                </Grid>
              </Grid>
              <Grid container className={cssClasses.reviewContentItem}>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContentTitel}>Start date</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContent}>{startDate}</Typography>
                </Grid>
              </Grid>
              <Grid container className={cssClasses.reviewContentItem}>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContentTitel}>Starting Revenue</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={cssClasses.reviewContent}>{item.startingRevenue}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '809px',
        height: '884px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        flexShrink: 0,
        justifyContent: 'start',
        paddingLeft: '50px',
        paddingBottom: '50px'
      }}
    >
      <div style={{ marginTop: '70px', width: '100%' }}>
        <p className={cssClasses.stepText}>Step 6</p>
        <h1 className={cssClasses.tabHeading}>Review </h1>
        <p className={cssClasses.tabDesc}>Let’s take a minute to review everything and publish. </p>
        <Grid container spacing={3} sx={{ width: '100%', marginTop: '32px' }}>
          <CompanyTab />
          <Departments />
          <Grid item sx={{ width: '100%' }}>
            <div className={cssClasses.reviwContentHead}>
              <Typography className={cssClasses.reviewTabSubHeading}>Expenses</Typography>
              <button
                onClick={() => dispatch(setCompanySetupModalScreen(3))}
                type="button"
                style={{
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center',
                  cursor: 'pointer',
                  outline: 'none',
                  border: 'none',
                  background: 'trasparent'
                }}
              >
                <img src={EditPencilIconDark} alt="Edit_icon" />
                <Typography className={cssClasses.reviewEdit}>Edit</Typography>
              </button>
            </div>
            <Divider sx={{ width: '100%', margin: '9px 0 12px 0' }} />
            <div className={cssClasses.reviewMainContents}>
              {vendorList?.map((item, index) => (
                <Expenses item={item} />
              ))}
            </div>
          </Grid>

          <Grid item sx={{ width: '100%' }}>
            <div className={cssClasses.reviwContentHead}>
              <Typography className={cssClasses.reviewTabSubHeading}>Team</Typography>
              <button
                onClick={() => dispatch(setCompanySetupModalScreen(4))}
                type="button"
                style={{
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center',
                  cursor: 'pointer',
                  outline: 'none',
                  border: 'none',
                  background: 'trasparent'
                }}
              >
                <img src={EditPencilIconDark} alt="Edit_icon" />
                <Typography className={cssClasses.reviewEdit}>Edit</Typography>
              </button>
            </div>
            <Divider sx={{ width: '100%', margin: '9px 0 12px 0' }} />
            <div className={cssClasses.reviewMainContents}>
              {employeeList?.map((item, index) => (
                <Employee item={item} />
              ))}
              {contractorList?.map((item, index) => (
                <Contractor item={item} />
              ))}
            </div>
          </Grid>

          <Grid item sx={{ width: '100%' }}>
            <div className={cssClasses.reviwContentHead}>
              <Typography className={cssClasses.reviewTabSubHeading}>Business</Typography>
              <button
                onClick={() => dispatch(setCompanySetupModalScreen(5))}
                type="button"
                style={{
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center',
                  cursor: 'pointer',
                  outline: 'none',
                  border: 'none',
                  background: 'trasparent'
                }}
              >
                <img src={EditPencilIconDark} alt="Edit_icon" />
                <Typography className={cssClasses.reviewEdit}>Edit</Typography>
              </button>
            </div>
            <Divider sx={{ width: '100%', margin: '9px 0 12px 0' }} />
            <div className={cssClasses.reviewMainContents}>
              {productList.length > 0 && (
                <>
                  {' '}
                  {productList?.map((item, index) => (
                    <Product item={item} />
                  ))}
                </>
              )}
              {clientList.length > 0 && (
                <>
                  {' '}
                  {clientList?.map((item, index) => (
                    <Client item={item} />
                  ))}
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
})

export default ReviewTab
