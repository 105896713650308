import { createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance } from 'utils/API/AxiosConfig'
import { SERVER_URL } from 'utils/API'

const createCheckOutSession = createAsyncThunk('portal/stripe/Integration', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/subscription/create-checkout-session`,
      { ...payload },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message)
    }

    return thunkAPI.rejectWithValue(error.message)
  }
})
const createPortalSession = createAsyncThunk('portal/stripe/session', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/subscription/create-portal-session`,
      { ...payload },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const createPortalSessionWordPress = createAsyncThunk('portal/stripe/session/wordPress', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/subscription/create-portal-session-wordPress`,
      { ...payload },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const getWordPressPaymentIntent = createAsyncThunk('wordpress/paymnet/session', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/subscription/wordpress-payment-session`,
      { ...payload },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message)
    }

    return thunkAPI.rejectWithValue(error.message)
  }
})

const getUserInvoices = createAsyncThunk('get/invoices', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/subscription/get-user-invoices`,
      { ...payload },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message)
    }

    return thunkAPI.rejectWithValue(error.message)
  }
})

const addPromoCode = createAsyncThunk('add/promo', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/subscription/apply-promo`,
      { ...payload },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response)
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.error)
    }

    return thunkAPI.rejectWithValue(error.message)
  }
})

export {
  createCheckOutSession,
  createPortalSession,
  getWordPressPaymentIntent,
  createPortalSessionWordPress,
  getUserInvoices,
  addPromoCode
}
