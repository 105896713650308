import { Dialog, DialogContent, DialogActions, Button, DialogContentText } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'

const PopupNotify = props => {
  const { open, message, dismissBtnText, submitBtnText, onDismiss, onSubmit } = props

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onDismiss()
        }
      }}
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ px: 3 }}>
        <Button variant="outlined" onClick={onDismiss} autoFocus>
          {dismissBtnText || 'OK'}
        </Button>
        {submitBtnText && (
          <Button variant="outlined" onClick={onSubmit} autoFocus>
            {submitBtnText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

PopupNotify.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  dismissBtnText: PropTypes.string,
  submitBtnText: PropTypes.string,
  onSubmit: PropTypes.func,
  onDismiss: PropTypes.func
}

export default PopupNotify
