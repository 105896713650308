import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import cssClasses from './infoTab.module.css'
import styles from '../../styles.module.css'

import UploadIcon from '../../assets/images/icons/UploadIcon.png'
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined'

import PropTypes from 'prop-types'
import {
  Grid,
  TextField,
  Box,
  Card,
  IconButton,
  Button,
  Fab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  CardHeader,
  Typography,
  CardContent,
  FormHelperText,
  CardMedia
} from '@material-ui/core'
import moment from 'moment'
import AdapterDateFns from '@material-ui/lab/AdapterMoment'
import LocalizationProvider from '@material-ui/lab/LocalizationProvider'
import { DatePicker, LoadingButton, MobileDatePicker } from '@material-ui/lab'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import UpdateIcon from '@material-ui/icons/Save'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Formik } from 'formik'
import NumberFormat from 'react-number-format'

import { gridSpacing } from 'store/constant'
import ModalSubtitle from './ModalSubtitle'
import LocationsList from 'data/LocationsList'
import { useNavigate } from 'react-router'

import {
  uiSelector,
  setCompanySetupModalScreen,
  toggleCompanySetupModal,
  toggleExcelImportTabModel,
  setExcelImportTabModel
} from 'store/ui/ui.slice'
import { updateCompany, getCompaniesByUser, finishCompanyCreate } from 'store/company/company.actions'
import { clearState, companySelector, resetState } from 'store/company/company.slice'
import { SNACKBAR_OPEN } from 'store/actions'
import { numberFormat } from 'utils/ui'
import PopupNotify from 'ui-component/tab/Model/PopupNotify'
import FieldsFilled from 'utils/fieldsFilled'
import { makeStyles } from '@material-ui/styles'
import { propTypes } from 'react-markdown'

// import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import excel from 'assets/images/excel.png'
import { authSelector } from 'store/auth/auth.slice'
import ExcelImporTabModel from './excelImportTab'

const useStyles = makeStyles(theme => ({
  excel: {
    padding: 5,
    maxWidth: 70,
    maxHeight: 100,
    objectFit: 'contain',
    // marginTop: -25,
    borderRadius: 30,
    cursor: 'pointer',
    '&:hover': {
      background: '#c6e9f5'
    }
  },
  upgradeBtn: {
    marginRight: 5,
    marginLeft: 5,
    width: 96,
    height: 50,
    color: '#F7F7F8',
    fontSize: '12.5',
    fontWeight: 800,
    background: '#091A36',
    borderRadius: '8px',
    // marginRight: '12px',
    // paddingRight: '18px',
    // paddingLeft: '18px',
    '&:hover': {
      background: '#14284a',
      color: 'white'
    }
  },
  dropdownStyle: {
    borderRadius: '8px !important',
    '& .MuiOutlinedInput-input': {
      borderRadius: '8px !important'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px !important'
    }
  }
}))

const ContractorsTabViewDrawer = forwardRef((props, ref) => {
  const classes = useStyles()
  // const { title, caption, toolTip, qbIcon, windowHeight, isDemoCompany } = props
  const {
    windowHeight,
    isDemoCompany,
    setInitialValues,
    initialValues,
    formikInitialValues,
    editItemCont,
    setEditItemCont,
    setContEditButtonClicked,
    contEditButtonClicked,
    addedEmployee,
    setAddedEmployee,
    myTeamRef
  } = props

  const dispatch = useDispatch()
  const formRef = useRef()
  const { companySetupModalScreen } = useSelector(uiSelector)
  const { user } = useSelector(authSelector)
  const { isLoading, isSuccess, updateCompanyError, id, contractorList, departmentList, selectedCompany, isSuccessCompanyCreate } =
    useSelector(companySelector)
  const [isNext, setNext] = useState(false)
  const [notify, setNotify] = useState(false)
  const [skip, setskip] = useState(false)
  const [isFinish, setIsFinish] = useState(false)
  const navigate = useNavigate()
  const [islocationFound, setIsLocationFound] = useState(false)
  useEffect(() => {
    const tempLocation = LocationsList.some(location => {
      if (location.name === initialValues.location) {
        setInitialValues({ ...initialValues, location: `${location.abbreviation} - ${location.name}` })
        return true
      }
      if (`${location.abbreviation} - ${location.name}` === initialValues.location) return true

      return false
    })
    setIsLocationFound(tempLocation)
  }, [initialValues])
  // useImperativeHandle(ref, () => ({
  //   empHandleNext(flag) {
  //     if (flag) setIsFinish(true)
  //     if (contractorList.length === 0 || (formRef.current.values.name && contractorList.length > 0)) {
  //       if (contractorList.length === 0 && !formRef.current.values.name) {
  //         setskip(true)
  //       } else if (formRef.current.isValid) {
  //         setNotify(true)
  //         setNext(true)
  //       } else {
  //         dispatch(clearState())
  //         if (formRef.current) formRef.current.handleSubmit()
  //         setNext(true)
  //       }
  //     } else if (flag) {
  //       dispatch(clearState())
  //       const data = {
  //         companyId: id
  //       }
  //       dispatch(finishCompanyCreate(data))
  //     } else {
  //       setNext(false)
  //       // dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
  //       dispatch(clearState())
  //     }
  //   }
  // }))

  // useEffect(() => {
  //   if (isSuccessCompanyCreate && isFinish) {
  //     dispatch(toggleCompanySetupModal())
  //     dispatch(setCompanySetupModalScreen(0))
  //     dispatch(resetState())
  //     dispatch(getCompaniesByUser())
  //     // navigate(`models?cid=${selectedCompany}`)
  //   }
  // }, [dispatch, isFinish, isSuccessCompanyCreate, navigate, selectedCompany])

  // useEffect(() => {
  //   if (isFinish && isSuccess) {
  //     dispatch(clearState())
  //     const data = {
  //       companyId: id
  //     }
  //     dispatch(finishCompanyCreate(data))
  //   }
  // }, [dispatch, id, isFinish, isSuccess])

  // const formikInitialValues = {
  //   _id: '',
  //   name: '',
  //   purpose: '',
  //   department: '',
  //   function: '',
  //   startDate: null,
  //   location: '',
  //   annualCompensation: '',
  //   submit: null
  // }
  // const [initialValues, setInitialValues] = useState(formikInitialValues)
  const [openStartDatePicker, setOpenStartDatePicker] = useState(false)

  // useImperativeHandle(ref, () => {
  //   const editContractor = item => {
  //     setEditItem(item._id)
  //     const tempObject = { ...item }
  //     const d = new Date(tempObject.startDate)

  //     setInitialValues({
  //       ...item,
  //       startDate: dayjs(d).format('YYYY/MM/DD'),
  //       submit: null
  //     })
  //   }
  // })

  const handleAddContractor = (name, purpose, department, func, startDate, location, annualCompensation) => {
    const payload = {
      name,
      purpose,
      department,
      function: func,
      startDate,
      location,
      annualCompensation
    }

    const logArray = []
    const employeeChangeLog = {
      user: user.id,
      userName: user.name,
      company: selectedCompany,
      date: new Date(),
      tabChanged: 'Contractors',
      department: payload.department,
      lineItemChanged: `${payload.name}(New Contractor added)`,
      originalValue: `N/A`,
      newValue: payload.name
    }
    logArray.push(employeeChangeLog)
    const contractorPayload = [...contractorList]
    contractorPayload.push(payload)

    const contList = []
    contList.push(payload)
    setAddedEmployee(contList)

    if (id !== '') {
      const data = {
        id,
        contractorList: contractorPayload,
        currentModelScreen: companySetupModalScreen + 1,
        systemLog: logArray
      }
      dispatch(updateCompany(data))
    }
    setInitialValues(formikInitialValues)
  }

  // const editContractor = item => {
  //   setEditItem(item._id)
  //   const tempObject = { ...item }
  //   const d = new Date(tempObject.startDate)

  //   setInitialValues({
  //     ...item,
  //     startDate: dayjs(d).format('YYYY/MM/DD'),
  //     submit: null
  //   })
  // }

  const handleDeleteContractor = _id => {
    if (editItemCont === _id) return
    // if (formRef.current.values._id) {
    //   setNotify(true)
    //   return
    // }
    const logArray = []
    const contractor = [...contractorList].filter(item => item._id === _id)

    const employeeChangeLog = {
      user: user.id,
      userName: user.name,
      company: selectedCompany,
      date: new Date(),
      tabChanged: 'Contractors',
      department: contractor[0].department,
      lineItemChanged: `${contractor[0].name}(Contractor deleted)`,
      originalValue: contractor[0].name,
      newValue: `N/A`
    }
    logArray.push(employeeChangeLog)
    const contractorPayload = [...contractorList].filter(item => item._id !== _id)
    if (id !== '') {
      const data = {
        id,
        contractorList: contractorPayload,
        currentModelScreen: companySetupModalScreen + 1,
        systemLog: logArray
      }
      dispatch(updateCompany(data))
    }
  }

  const handleUpdateContractor = (_id, name, purpose, department, func, startDate, location, annualCompensation) => {
    setEditItemCont('')
    const payload = {
      _id,
      name,
      purpose,
      department,
      function: func,
      startDate,
      location,
      annualCompensation
    }

    const contList = []
    contList.push(payload)
    setAddedEmployee(contList)

    const contractorPayload = [...contractorList]
    const index = contractorPayload.findIndex(item => item._id === payload._id)

    //  system logs
    const logArray = []
    if (contractorPayload[index].department !== payload.department) {
      const employeeChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Contractors',
        department: payload.department,
        lineItemChanged: `${contractorPayload[index].name} (Department changed)`,
        originalValue: contractorPayload[index].department,
        newValue: payload.department
      }
      logArray.push(employeeChangeLog)
    }
    if (contractorPayload[index].name !== payload.name) {
      const employeeChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Contractors',
        department: payload.department,
        lineItemChanged: `${contractorPayload[index].name} (Name changed)`,
        originalValue: contractorPayload[index].name,
        newValue: payload.name
      }
      logArray.push(employeeChangeLog)
    }

    if (contractorPayload[index].purpose !== payload.purpose) {
      const employeeChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Contractors',
        department: payload.department,
        lineItemChanged: `${contractorPayload[index].name} (Purpose changed)`,
        originalValue: contractorPayload[index].purpose,
        newValue: payload.purpose
      }
      logArray.push(employeeChangeLog)
    }

    if (contractorPayload[index].function !== payload.function) {
      const employeeChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Contractors',
        department: payload.department,
        lineItemChanged: `${contractorPayload[index].name} (Function changed)`,
        originalValue: contractorPayload[index].function,
        newValue: payload.function
      }
      logArray.push(employeeChangeLog)
    }
    if (contractorPayload[index].location !== payload.location) {
      const employeeChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Contractors',
        department: payload.department,
        lineItemChanged: `${contractorPayload[index].name} (Location changed)`,
        originalValue: contractorPayload[index].location,
        newValue: payload.location
      }
      logArray.push(employeeChangeLog)
    }

    if (dayjs(contractorPayload[index].startDate).format('YYYY/MM/DD') !== dayjs(payload.startDate).format('YYYY/MM/DD')) {
      const employeeChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Contractors',
        department: payload.department,
        lineItemChanged: `${contractorPayload[index].name} (Start Date changed)`,
        originalValue: dayjs(contractorPayload[index].startDate).format('MM/DD/YYYY'),
        newValue: dayjs(payload.startDate).format('MM/DD/YYYY')
      }
      logArray.push(employeeChangeLog)
    }

    if (contractorPayload[index].annualCompensation !== payload.annualCompensation) {
      const employeeChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Contractors',
        department: payload.department,
        lineItemChanged: `${contractorPayload[index].name} (Annual Compensation changed)`,
        originalValue: contractorPayload[index].annualCompensation,
        newValue: payload.annualCompensation
      }
      logArray.push(employeeChangeLog)
    }

    // log ends

    contractorPayload[index] = payload
    if (id !== '') {
      const data = {
        id,
        contractorList: contractorPayload,
        currentModelScreen: companySetupModalScreen + 1,
        systemLog: logArray
      }
      dispatch(updateCompany(data))
    }
    setInitialValues(formikInitialValues)
  }

  useEffect(() => {
    if (updateCompanyError !== '') {
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: updateCompanyError,
        variant: 'alert',
        alertSeverity: 'error'
      })
    }

    return dispatch(clearState())
  }, [dispatch, updateCompanyError])

  const handleChangeNumber = e => {
    const value = e.target.value.replace(/\D/g, '')
    formRef.current.setFieldValue('annualCompensation', value.replace(/\B(?=(\d{3})+(?!\d))/g, ','))
  }

  // const isAllDatasEntered = errors => {
  //   if (Object.values(errors)[0] !== undefined) {
  //     return 'Save'
  //   }
  //   return 'Add'
  // }

  // useEffect(() => {
  //   if (isSuccess && isNext) {
  //     dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
  //     dispatch(clearState())
  //     if (isFinish) {
  //       //
  //       dispatch(toggleCompanySetupModal())
  //       dispatch(setCompanySetupModalScreen(0))
  //       dispatch(resetState())
  //       dispatch(getCompaniesByUser())
  //       navigate(`models?cid=${selectedCompany}`)
  //     }
  //   }
  //   return () => {
  //     dispatch(clearState())
  //   }
  // }, [isNext, isSuccess])

  return (
    <>
      {/* <Grid
        container
        sx={{
          minHeight: '100%',
          height: windowHeight - 2,
          overflow: 'auto',
          pl: 1,
          pr: 1,
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar': {
            width: '0.4em'
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555'
          }
        }}
      > */}
      {/* <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', pl: 2 }}> */}
      {/* <Grid style={{ display: 'flex', marginRight: '10px' }}>
            <ModalSubtitle title={title} caption={caption} toolTip={toolTip} qbIcon={qbIcon} />
            {!isDemoCompany && (
              <Box justifyContent="flex-end">
                <CardMedia
                  sx={{ height: '60px', width: '60px', marginRight: '10px' }}
                  component="img"
                  image={excel}
                  className={classes.excel}
                  onClick={() => {
                    dispatch(toggleExcelImportTabModel())
                    dispatch(setExcelImportTabModel(4))
                  }}
                />
              </Box>
            )}
          </Grid> */}
      <Box sx={{ my: 2, mr: 2 }}>
        <Formik
          validateOnMount
          innerRef={formRef}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Name is required').max(30, 'Maximum 30 characters allowed'),
            purpose: Yup.string().required('Purpose/Function is required').max(30, 'Maximum 30 characters allowed'),
            department: Yup.string().required('Department is required'),
            function: Yup.string().trim().required('Title of function is required').max(30, 'Maximum 30 characters allowed'),
            startDate: Yup.string().nullable().required('Start date is required'),
            location: Yup.string().required('Location is required'),
            annualCompensation: Yup.string()
              .required('Annualized Compensation is required')
              .matches(/^[\d,]+$/, 'Enter only numbers')
              .max(20, 'Maximum 30 characters allowed')
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm, setFieldValue }) => {
            if (isDemoCompany) {
              dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
              return
            }

            if (values._id && contractorList.length > 0) {
              handleUpdateContractor(
                values._id,
                values.name,
                values.purpose,
                values.department,
                values.function,
                new Date(moment(values.startDate).format('YYYY-MM-DD')),
                values.location,
                values.annualCompensation.toString().includes(',')
                  ? parseInt(values.annualCompensation.replace(/,/g, ''), 10)
                  : values.annualCompensation
              )
            } else {
              handleAddContractor(
                values.name,
                values.purpose,
                values.department,
                values.function,
                values.startDate,
                values.location,
                values.annualCompensation.toString().includes(',')
                  ? parseInt(values.annualCompensation.replace(/,/g, ''), 10)
                  : values.annualCompensation
              )
            }
            setStatus({ success: true })
            setSubmitting(false)
            setFieldValue('startDate', null, false)
            resetForm(initialValues)
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, isValid, setFieldTouched }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                  <Typography className={cssClasses.drawerInputLabel} style={{ color: '#207E4D', marginTop: '10px' }}>
                    Contractor
                  </Typography>
                </div>{' '}
                <ExcelImporTabModel excelImportTabNo={4} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label htmlFor="employee-fullname" className={cssClasses.drawerInputLabel}>
                    Contractor full name <span className={cssClasses.labelStar}>*</span>{' '}
                  </label>
                  <input
                    placeholder="Type here..."
                    value={values.name}
                    name="name"
                    id="employee-fullname"
                    className={cssClasses.drawerInput}
                    onChange={e => {
                      handleChange(e)
                      // handleNameChange(e)
                      // if (values.name) setFieldTouched('name', true)
                    }}
                    onBlur={handleBlur}
                  />{' '}
                  {touched.name && errors.name && <FormHelperText error> {errors.name} </FormHelperText>}
                </div>
                <div className={cssClasses.drawerSelectInput}>
                  <label htmlFor="function" className={cssClasses.drawerInputLabel}>
                    Contractor or entity name <span className={cssClasses.labelStar}>*</span>{' '}
                  </label>
                  <input
                    className={cssClasses.drawerInput}
                    type="text"
                    style={{ width: '100%' }}
                    placeholder="Select"
                    id="name"
                    value={values?.function || ''}
                    name="function"
                    onBlur={handleBlur}
                    onChange={e => {
                      handleChange(e)
                      if (values.function) setFieldTouched('function', true)
                    }}
                  />
                  {touched.function && errors.function && <FormHelperText error> {errors.function} </FormHelperText>}
                </div>{' '}
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label htmlFor="purpose" className={cssClasses.drawerInputLabel}>
                    Job title <span className={cssClasses.labelStar}>*</span>{' '}
                  </label>
                  <input
                    placeholder="Enter"
                    value={values?.purpose || ''}
                    name="purpose"
                    id="purpose"
                    onBlur={handleBlur}
                    onChange={e => {
                      handleChange(e)
                      if (values.purpose) setFieldTouched('purpose', true)
                    }}
                    className={cssClasses.drawerInput}
                  />{' '}
                  {touched.purpose && errors.purpose && <FormHelperText error> {errors.purpose} </FormHelperText>}
                </div>{' '}
                <div className={cssClasses.drawerSelectInput}>
                  <label htmlFor="department" className={cssClasses.drawerInputLabel}>
                    Department <span className={cssClasses.labelStar}>*</span>{' '}
                  </label>
                  <select
                    className={cssClasses.drawerInput}
                    style={{ width: '100%' }}
                    placeholder="Select"
                    id="department"
                    value={values?.department || ''}
                    name="department"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    <option disabled value="">
                      Select
                    </option>
                    {departmentList.map((option, i) => (
                      <option value={option.name} id={option.id} key={`taxYearEndOption-${i}`}>
                        {option.name}
                      </option>
                    ))}

                    {/* {departmentList.map((department, index) => {
                        if (department.name) {
                          return (
                            <option key={`emp_${index}`} value={department.name}>
                              {department.name}
                            </option>
                          )
                        }
                        return null
                      })} */}
                  </select>{' '}
                  {touched.department && errors.department && <FormHelperText error> {errors.department} </FormHelperText>}
                </div>{' '}
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label htmlFor="start_date" className={cssClasses.drawerInputLabel}>
                    Start date <span className={cssClasses.labelStar}>*</span>{' '}
                  </label>
                  <input
                    placeholder="Enter"
                    value={values?.startDate || null}
                    name="startDate"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    id="start_date"
                    className={cssClasses.drawerInput}
                    type="date"
                  />{' '}
                  {touched.startDate && errors.startDate && <FormHelperText error> {errors.startDate} </FormHelperText>}
                </div>
                <div className={cssClasses.drawerSelectInput}>
                  <label htmlFor="location" className={cssClasses.drawerInputLabel}>
                    Location <span className={cssClasses.labelStar}>*</span>{' '}
                  </label>
                  <select
                    className={cssClasses.drawerInput}
                    style={{ width: '100%' }}
                    placeholder="Select"
                    id="location"
                    value={values?.location || ''}
                    name="location"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    <option disabled selected value="">
                      Select State
                    </option>
                    <option value="Overseas/Remote">Overseas/Remote</option>
                    {LocationsList.map((location, index) => (
                      <option
                        key={`emp_${index}`}
                        value={`${location.abbreviation} - ${location.name}`}
                      >{`${location.abbreviation} - ${location.name}`}</option>
                    ))}
                  </select>{' '}
                  {touched.location && errors.location && <FormHelperText error> {errors.location} </FormHelperText>}
                </div>{' '}
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label htmlFor="annualCompensation" className={cssClasses.drawerInputLabel}>
                    Annualized compensation <span className={cssClasses.labelStar}>*</span>{' '}
                  </label>

                  <div className={cssClasses.containerInputs}>
                    <MonetizationOnOutlinedIcon />
                    <NumberFormat
                      className={cssClasses.transparentInputs}
                      placeholder="Enter"
                      value={values?.annualCompensation || ''}
                      name="annualCompensation"
                      onBlur={handleBlur}
                      onChange={e => {
                        handleChange(e)
                        if (values.annualCompensation) setFieldTouched('annualCompensation', true)
                      }}
                      id="annualCompensation"
                      thousandSeparator=","
                    />{' '}
                  </div>
                  {touched.annualCompensation && errors.annualCompensation && (
                    <FormHelperText error> {errors.annualCompensation} </FormHelperText>
                  )}
                </div>{' '}
              </Box>{' '}
              <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button
                  className={isValid ? cssClasses.wizardAddButton : cssClasses.wizardAddButtonDisabled}
                  type="button"
                  disabled={isSubmitting}
                  onClick={() => {
                    setNext(false)

                    if (formRef.current) formRef.current.handleSubmit()
                    if (myTeamRef.current) myTeamRef.current.handleSubmit()
                  }}
                >
                  Add
                </button>
              </div>
            </form>
          )}
        </Formik>
      </Box>
      {/* </Grid> */}
      {/* <Grid item xs={12} md={4.5} sx={{ p: 1, background: '#FFFFFF', boxShadow: 5 }}> */}
      {/* <Box sx={{ textAlign: 'center', mb: 2 }}>
          <Typography variant="h4">My Contractors</Typography>
        </Box> */}
      {/* <Divider sx={{ mb: 3 }} /> */}
      {/* <Box
        sx={{
          overflow: 'auto',
          maxHeight: windowHeight - 100,
          pl: 1,
          pr: 1,
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar': {
            width: '0.4em'
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555'
          }
        }}
      > */}
      {/* <Grid container spacing={gridSpacing}>
          {contractorList.map((item, index) => (
            <Grid item xs={12} md={12} key={`coaitem-${index}`}>
              <Card variant="outlined" sx={{ background: '#091A36', borderRadius: 3 }}>
                <CardHeader
                  title={
                    <Typography sx={{ color: '#ffffff' }} variant="h5">
                      {item.name}
                    </Typography>
                  }
                  action={
                    <>
                      {!isDemoCompany && (
                        <IconButton size="small" sx={{ color: '#488AC1' }} aria-label="edit" onClick={() => editContractor(item)}>
                          <EditIcon fontSize="small" />
                        </IconButton>
                      )}
                      {!isDemoCompany && (
                        <IconButton
                          disabled={editItem === item._id}
                          size="small"
                          sx={{ color: '#488AC1' }}
                          aria-label="delete"
                          onClick={() => handleDeleteContractor(item._id)}
                        >
                          <DeleteOutlineIcon fontSize="small" />
                        </IconButton>
                      )}
                    </>
                  }
                  sx={{ p: 1.5 }}
                />
                <Divider />
                <CardContent sx={{ p: 1.5, mb: -2, pb: 2 }}>
                  <Grid container key={`childGrid${index}`}>
                    <Grid item xs={12} md={12} key={`coaitemd-${index}`}>
                      <Typography sx={{ color: '#E0E2E6' }} pb={1}>
                        Dept: <strong>{item.department}</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={6} key={`coaitems-${index}`}>
                      <Typography sx={{ color: '#E0E2E6' }} pb={1}>
                        Start date: <strong>{moment(item.startDate).format('MM/DD/YYYY')}</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={6} key={`coaitemt-${index}`}>
                      <Typography sx={{ color: '#E0E2E6' }} pb={1}>
                        Title: <strong>{item.function}</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={6} key={`coaiteml-${index}`}>
                      <Typography sx={{ color: '#E0E2E6' }}>
                        Location: <strong>{item.location}</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={6} key={`coaitems-${index}`}>
                      <Typography sx={{ color: '#E0E2E6' }} pb={1}>
                        Salary: <strong>{`$${numberFormat(item.annualCompensation)}`}</strong>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography sx={{ color: '#E0E2E6' }} pb={1}>
                        Purpose/Function: <strong>{item.purpose}</strong>
                      </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid> */}
      {/* </Box> */}
      {/* </Grid> */}
      {/* </Grid> */}
      <PopupNotify
        message="Are you sure you want to proceed without saving your entry?"
        submitBtnText="Save"
        dismissBtnText="Don't Save"
        open={notify}
        onSubmit={() => {
          if (formRef.current) formRef.current.handleSubmit()
          dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
          setNotify(false)
        }}
        onDismiss={() => {
          setNext(false)
          dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
          dispatch(clearState())
          setNotify(false)
        }}
      />
      <PopupNotify
        message="Are you sure you want to proceed without adding contractors?"
        submitBtnText="Yes"
        dismissBtnText="No"
        open={skip}
        onSubmit={() => {
          setNext(false)
          dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
          dispatch(clearState())
          setskip(false)
        }}
        onDismiss={() => {
          setskip(false)
        }}
      />
    </>
  )
})

ContractorsTabViewDrawer.propTypes = {
  // title: PropTypes.any,
  // caption: PropTypes.any,
  // toolTip: PropTypes.any,
  // qbIcon: PropTypes.any,
  windowHeight: PropTypes.any,
  formikInitialValues: propTypes.any,
  isDemoCompany: propTypes.any,
  setInitialValues: propTypes.any,
  initialValues: propTypes.any
}

export default ContractorsTabViewDrawer
