import { createAsyncThunk } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { SERVER_URL } from 'utils/API'
import { axiosInstance } from 'utils/API/AxiosConfig'
import { toggleDebtSetupModal } from 'store/ui/ui.slice'

const createCompany = createAsyncThunk('company/create', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/company/createCompanyStart`,
      { ...payload },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    if (e.response) {
      return thunkAPI.rejectWithValue(e.response.data.message)
    }

    return thunkAPI.rejectWithValue(e.message)
  }
})

const createCompanyDebt = createAsyncThunk('company/debt-create', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/company/createDebt`,
      { ...payload },
      {
        handlerEnabled: true
      }
    )

    // const dispatch = useDispatch()
    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const updateCompany = createAsyncThunk('company/update', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/company/updateCompany`,
      { ...payload },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    if (e.response) {
      return thunkAPI.rejectWithValue(e.response.data.message)
    }

    return thunkAPI.rejectWithValue(e.message)
  }
})

const updateCompanyInfo = createAsyncThunk('company/info/update', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/company/updateCompany`,
      { ...payload },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    if (e.response) {
      return thunkAPI.rejectWithValue(e.response.data.message)
    }

    return thunkAPI.rejectWithValue(e.message)
  }
})

const finishCompanyCreate = createAsyncThunk('company/create/finish', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.get(
      `${SERVER_URL}/company/finishCompanyCreate`,
      { params: { ...payload } },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const getCompaniesByUser = createAsyncThunk('company/companies/get', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.get(`${SERVER_URL}/company/getCompaniesByUser`, {
      handlerEnabled: true
    })

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const getCompanyByID = createAsyncThunk('company/company/get', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.get(
      `${SERVER_URL}/company/getCompanyByID`,
      { params: { ...payload } },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const deleteCompanyById = createAsyncThunk('company/company/delete', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/company/deleteCompanyById`,
      { ...payload },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const leaveCompanyById = createAsyncThunk('company/company/leave', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/company/leaveCompanyById`,
      { ...payload },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const createTask = createAsyncThunk('company/create/task', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/company/createTask`,
      { ...payload },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const getUserTasks = createAsyncThunk('company/user/task', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/company/getUserTasks`,
      { ...payload },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const getReassignedTasks = createAsyncThunk('company/user/reassigned/task', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/company/getReassignedTasks`,
      { ...payload },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const updateTaskStatus = createAsyncThunk('company/task/update/status', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/company/updateTaskStatus`,
      { ...payload },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const deleteUserFromCompany = createAsyncThunk('company/delete/user/invited', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/company/deleteUserFromCompany`,
      { ...payload },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const getupdateCellChanges = createAsyncThunk('company/cell/update', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/company/getupdateCellChanges`,
      { ...payload },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const getSimulationReport = createAsyncThunk('company/simulation/report', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/company/getSimulationReport`,
      { ...payload },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})
const addEquityRaiseData = createAsyncThunk('equityRaise/report', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/company/addEquityRaiseData`,
      { ...payload },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const updateHiringPlanEmployees = createAsyncThunk('company/updateHiringPlanEmployees', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/company/updateHiringPlanEmployees`,
      { ...payload },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const getHiringPlanEmployees = createAsyncThunk('company/getHiringPlanEmployees', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.get(
      `${SERVER_URL}/company/getHiringPlanEmployees`,
      { params: { ...payload } },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const updateHiringPlanBulkEmployees = createAsyncThunk('company/updateHiringPlanBulkEmployees', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/company/updateHiringPlanBulkEmployees`,
      { ...payload },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const getHiringPlanBulkEmployees = createAsyncThunk('company/getHiringPlanBulkEmployees', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.get(
      `${SERVER_URL}/company/getHiringPlanBulkEmployees`,
      { params: { ...payload } },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const updateHiringPlanContractors = createAsyncThunk('company/updateHiringPlanContractors', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/company/updateHiringPlanContractors`,
      { ...payload },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const getHiringPlanContractors = createAsyncThunk('company/getHiringPlanContractors', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.get(
      `${SERVER_URL}/company/getHiringPlanContractors`,
      { params: { ...payload } },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const updateHiringPlanBulkContractors = createAsyncThunk('company/updateHiringPlanBulkContractors', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/company/updateHiringPlanBulkContractors`,
      { ...payload },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const getHiringPlanBulkContractors = createAsyncThunk('company/getHiringPlanBulkContractors', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.get(
      `${SERVER_URL}/company/getHiringPlanBulkContractors`,
      { params: { ...payload } },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const createCompanyFromExcel = createAsyncThunk('company/create/excel', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/company/createCompanyFromExcel`,
      { ...payload },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const getSystemLogs = createAsyncThunk('company/get/syslogs', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/company/getSyslog`,
      { ...payload },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const completeProductOnboarding = createAsyncThunk('company/completeProductOnboarding', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/company/finishProductOnboarding`,
      { params: { ...payload } },
      {
        handlerEnabled: true
      }
    )
    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

export {
  createCompany,
  createCompanyDebt,
  updateCompany,
  updateCompanyInfo,
  finishCompanyCreate,
  getCompaniesByUser,
  getCompanyByID,
  deleteCompanyById,
  leaveCompanyById,
  createTask,
  getUserTasks,
  getReassignedTasks,
  addEquityRaiseData,
  updateTaskStatus,
  deleteUserFromCompany,
  getupdateCellChanges,
  getSimulationReport,
  createCompanyFromExcel,
  getSystemLogs,
  updateHiringPlanEmployees,
  getHiringPlanEmployees,
  updateHiringPlanBulkEmployees,
  getHiringPlanBulkEmployees,
  updateHiringPlanContractors,
  getHiringPlanContractors,
  updateHiringPlanBulkContractors,
  getHiringPlanBulkContractors,
  completeProductOnboarding
}
