import {
  Box,
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import CircleIcon from '@material-ui/icons/Circle'
import CloseIcon from '@material-ui/icons/Close'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import PanoramaFishEyeIcon from '@material-ui/icons/PanoramaFishEye'
import { LoadingButton } from '@material-ui/lab'
import { StylesProvider, makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SNACKBAR_OPEN } from 'store/actions'
import { updateSelectedCompany, updateSetupModelPreviousStatus } from 'store/auth/auth.slice'
import {
  deleteCompanyById,
  getCompaniesByUser,
  finishCompanyCreate,
  getCompanyByID,
  completeProductOnboarding
} from 'store/company/company.actions'
import {
  changeLastTabLoaded,
  clearState,
  companySelector,
  resetState,
  setIntroModalOverride,
  setWizardTriggeringStatus,
  setIsOpenedFromComDrop
} from 'store/company/company.slice'
import { setCompanySetupModalScreen, setSelectedMenuItem, toggleCompanySetupModal, uiSelector } from 'store/ui/ui.slice'
import PopupNotify from 'ui-component/tab/Model/PopupNotify'
import { useElementSize } from 'usehooks-ts'
import wizardBar from '../../assets/images/wizardBar.png'
import CommerceRetailProductsTabView from './CommerceRetailProductsTabView'
import CongratulationScreen from './CongratulationScreen'
import DepartmentsTabView from './DepartmentsTabView'
import DetailedBillableClientsTabView from './DetailedBillableClientsTabView'
import DetailedBillableProductsTabView from './DetailedBillableProductsTabView'
import InfoTabView from './InfoTabView'
import MyTeamTab from './MyTeamTab'
import RevenueTabView from './RevenueTabView'
import SaaSProductsTabView from './SaaSProductsTabView'
import TopLineBillableClientsTabView from './TopLineBillableClientsTabView'
import WelcomeScreen from './WelcomeScreen'
import useClickHandlerWithDelay from './Debouncing'

import styles from './infoTab.module.css'

import brandMark from 'assets/images/brandmark.svg'
import CompanyTab from './CompanyTab'
import { WidgetsSharp } from '@material-ui/icons'
import CompanyManual from './CompanyManual'
import WizardStepper from './WizardStepper'
import NewExpensesTab from './NewExpensesTab'
import BusinessTabView from './BusinessTabView'
import ProServices from './ProServices'
import AiIntegration from './AiIntegration'
import ReviewTab from './ReviewTab'

import talkToExpertIcon from 'assets/images/talk to expert.png'
import resourcesIcon from 'assets/images/book-open.png'
import TalkToAnExperDrawer from './TalkToAnExpertDrawer'

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content'
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120
  },
  formControlLabel: {
    marginTop: theme.spacing(1)
  },
  companySetupTab: {
    '& .MuiTabs-flexContainer': {
      borderBottom: 'none'
    },
    '& button': {
      color: theme.palette.mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[600],
      minHeight: 'auto',
      minWidth: '100%',
      padding: '12px 16px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      textAlign: 'left',
      justifyContent: 'flex-start'
    },
    '& button.Mui-selected': {
      color: theme.palette.primary.main,
      background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[200]
    },
    '& button > svg': {
      marginBottom: '0px !important',
      marginRight: '10px',
      marginTop: '10px',
      height: '20px',
      width: '20px'
    },
    '& button > div > span': {
      display: 'block'
    },
    '& > div > span': {
      display: 'none'
    }
  },
  cardPanels: {
    borderLeft: '1px solid',
    borderLeftColor: theme.palette.mode === 'dark' ? '#333d5e' : '#eeeeee',
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      overflowY: 'auto'
    }
  },
  muiDialog: {
    '& .MuiDialog-paper': {
      maxHeight: '750px',
      height: 'calc(100% - 44px)',
      width: '35%',
      minWidth: 750
    }
  },
  muiDialogContent: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: 0
    }
  },
  gridFillHeightOverflowY: {
    [theme.breakpoints.up('md')]: {
      height: '100%',
      overflowY: 'hidden'
    }
  },
  navButton: {
    fontSize: 25,
    borderRadius: 20,
    cursor: 'pointer',
    '&:hover': {
      background: '#0068c9',
      color: 'white'
    }
  },
  dialog: {
    backgroundImage: 'url(https://i.imgur.com/HeGEEbu.jpg)'
  }
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <div>{children}</div>}
    </div>
  )
}

TabPanel.propTypes = {
  windowHeight: PropTypes.any,
  children: PropTypes.any,
  index: PropTypes.any,
  value: PropTypes.any
}

const CompanySetupModal = () => {
  const childRef = useRef(null)
  const classes = useStyles()
  const dispatch = useDispatch()
  const [squareRef, { width, height }] = useElementSize()
  const [screen, setScreen] = useState(0)
  const [companySetupMenu, setCompanySetupMenu] = useState([])
  const [stepPercentage, setStepPercentage] = useState(100)
  const { companySetupModalVisible, companySetupModalScreen, companySetupModalTabOptionsSection1, companySetupModalTabOptionsSection2 } =
    useSelector(uiSelector)
  const { isLoading, isCompanyDeleted, isQbImportedData, companies, isDemo, isSuccess, revenueComputationType } =
    useSelector(companySelector)
  const customization = useSelector(state => state.customization)

  const [handleClick, disabled] = useClickHandlerWithDelay(1000) // 1000ms delay

  const [isTalkToAnExpertClicked, setTalkToAnExpertClicked] = useState(false)

  const {
    id,
    departmentList,
    coaList,
    modelType,
    employeeList,
    contractorList,
    vendorList,
    clientList,
    productList,
    revenueModel,
    autoCalcGrowthRate,
    selectedCompany,
    isTriggeredFromDashboard,
    isCongratulationTabFinished,
    isSuccessCompanyCreate
  } = useSelector(companySelector)
  const [notify, setNotify] = useState(false)

  useEffect(() => {
    dispatch(getCompaniesByUser())
  }, [isSuccessCompanyCreate])

  const isFilled = screen => {
    if (screen === 0) return true

    if (screen === 1) {
      if (id) return true
      return false
    }
    if (screen === 2) {
      if (departmentList.length > 0) return true
      return false
    }
    if (screen === 3) {
      // if (vendorList.some(eachItem => eachItem.department)) return true
      if (vendorList.length > 0) return true
      return false
    }
    // if (screen === 4) {
    //   if (isCongratulationTabFinished) return true
    //   return false
    // }
    if (screen === 4) {
      if (employeeList.length > 0 && typeof employeeList[0]._id !== 'undefined') return true
      return false
    }
    // if (screen === 5) {
    //   if (contractorList.length > 0 && typeof contractorList[0]._id !== 'undefined') return true
    //   return false
    // }
    // if (screen === 5) {
    //   if (vendorList.length > 0 && typeof vendorList[0]._id !== 'undefined') return true
    //   return false
    // }
    if (screen === 5) {
      if (revenueModel) return true
      return false
    }
    // if (screen === 8) {
    //   if (autoCalcGrowthRate) return true
    //   return false
    // }
    if (screen === 9) {
      if (productList.length > 0 || clientList.length > 0) return true
      return false
    }
    // if (screen === 10 || screen === 12) {
    //   if (productList.length > 0 && typeof productList[0]._id !== 'undefined') return true
    //   return false
    // }
    // if (screen === 13) {
    //   if (productList.length > 0 && typeof productList[0]._id !== 'undefined') return true
    //   return false
    // }
    return false
  }

  const isEnabled = screen => {
    if (screen === 1) {
      if (id) return true
      return false
    }
    if (screen === 2) {
      if (departmentList.length > 0 || isQbImportedData) return true
      return false
    }
    if (screen === 3) {
      if ((coaList.length > 0 && typeof coaList[0]._id !== 'undefined') || isQbImportedData) return true
      return false
    }
    if (screen === 4) {
      if (modelType) return true
      return false
    }
    if (screen === 5) {
      if (modelType || isQbImportedData) return true
      return false
    }

    if (screen === 6) {
      if (vendorList.length > 0 || isQbImportedData) return true
      return false
    }
    if (screen === 7) {
      if ((vendorList.length > 0 && typeof vendorList[0]._id !== 'undefined') || isQbImportedData) return true
      return false
    }
    if (screen === 8) {
      if (revenueModel) return true
      return false
    }
    if (screen === 9) {
      if (autoCalcGrowthRate || revenueModel) return true
      return false
    }
    if (screen === 10) {
      if (revenueModel) return true
      return false
    }
    if (screen === 11) {
      if (revenueModel && productList.length > 0 && typeof productList[0]._id !== 'undefined') return true
      return false
    }
    if (screen === 12) {
      if (revenueModel) return true
      return false
    }
    if (screen === 13) {
      if (revenueModel) return true
      return false
    }
    return false
  }

  const revenueFirstModel = () => {
    if (revenueComputationType === 'Top Line Billable') {
      return clientList.length > 0
    }
    return clientList.length > 0 && productList.length > 0
  }

  const isRevenueTabCompleted = id => {
    if (revenueModel === 'Pro Services') {
      return revenueFirstModel()
    }
    return productList.length > 0
  }

  useEffect(() => {
    if (isCompanyDeleted) {
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: 'Company Deleted',
        variant: 'alert',
        alertSeverity: 'success'
      })
      dispatch(clearState())
      dispatch(resetState())
      dispatch(setIntroModalOverride(true))
      dispatch(getCompaniesByUser())
    }
  })

  const firstScreenProgress = () => {
    switch (companySetupModalScreen) {
      case 0:
        setStepPercentage(100)
        break
      case 1:
        setStepPercentage(80)
        break
      case 2:
        setStepPercentage(60)
        break
      case 3:
        setStepPercentage(40)
        break
      case 4:
        setStepPercentage(20)
        break
      case 10:
        setStepPercentage(100)
        break
      default:
        setStepPercentage(0)
        break
    }
  }

  const ScreenProgress = () => {
    switch (companySetupModalScreen) {
      case 1:
        setStepPercentage(0)
        break
      case 2:
        setStepPercentage(1)
        break
      case 3:
        setStepPercentage(2)
        break
      case 5:
        setStepPercentage(3)
        break
      // case 6:
      //   setStepPercentage(0)
      //   break
      default:
        setStepPercentage(0)
        break
    }
  }

  useEffect(() => {
    firstScreenProgress()
  }, [companySetupModalScreen])

  // useEffect(() => {
  //   if (isTriggeredFromDashboard) {
  //     secondScreenProgress()
  //   } else {
  //     firstScreenProgress()
  //   }
  // }, [companySetupModalScreen, firstScreenProgress, isTriggeredFromDashboard, secondScreenProgress])

  useEffect(() => {
    setCompanySetupMenu(!isTriggeredFromDashboard ? companySetupModalTabOptionsSection1 : companySetupModalTabOptionsSection2)
    setScreen(!isTriggeredFromDashboard ? 0 : 1)
  }, [companySetupModalScreen, companySetupModalTabOptionsSection1, companySetupModalTabOptionsSection2, isTriggeredFromDashboard])

  const [demo, setDemo] = useState(false)

  useEffect(() => {
    if (isDemo) {
      setDemo(true)
    } else {
      setDemo(false)
    }
  }, [companies, selectedCompany, isDemo])

  const isWizardProcessEnding =
    // companySetupModalScreen === 6 ||
    companySetupModalScreen === 4 ||
    companySetupModalScreen === 7 ||
    companySetupModalScreen === 9 ||
    companySetupModalScreen === 10 ||
    companySetupModalScreen === 11
  // (companySetupModalScreen === 3 && isTriggeredFromDashboard)

  const getNextButtontext = () => {
    // if (isWizardProcessEnding) return 'Done'
    if (companySetupModalScreen === 9) return 'Finalize'

    return 'Continue'
  }

  const findCurrentTabName = () => {
    if (companySetupModalScreen >= 4) {
      return 'Business'
    }
    const item = companySetupModalTabOptionsSection2.find(eachItem => eachItem.id === companySetupModalScreen + 1)
    return item.label
  }

  return (
    <>
      <Dialog
        sx={{ overflow: 'hidden' }}
        fullScreen
        open={companySetupModalVisible}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            dispatch(toggleCompanySetupModal(false))
            dispatch(setCompanySetupModalScreen(1))
            dispatch(resetState())
            dispatch(getCompaniesByUser())
          }
        }}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle
          id="max-width-dialog-title"
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '50px'
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', position: 'absolute', left: '96px', top: 15 }}>
            <img src={brandMark} style={{ width: '65px', marginTop: '5px' }} alt="MRGN_logo" />
            <Typography className={styles.dialogueTitle}>Product onboarding</Typography>
          </div>
          <IconButton
            aria-label="close"
            onClick={() => {
              if (isSuccess) {
                if (isRevenueTabCompleted()) {
                  const data = {
                    companyId: id,
                    isComplete: true
                  }
                  dispatch(finishCompanyCreate(data))
                } else {
                  const data = {
                    companyId: id
                  }
                  dispatch(finishCompanyCreate(data))
                }
                // dispatch(getCompaniesByUser())
              }
              dispatch(updateSetupModelPreviousStatus(false))
              dispatch(toggleCompanySetupModal(false))
              dispatch(clearState())
              dispatch(setIntroModalOverride(true))
              dispatch(setSelectedMenuItem(''))
              dispatch(setWizardTriggeringStatus(false))
              dispatch(updateSelectedCompany({ companyId: '' }))
              dispatch(resetState())
              dispatch(setIsOpenedFromComDrop(false))
              dispatch(getCompanyByID({ companyId: id }))
              dispatch(getCompaniesByUser())
            }}
            sx={{
              position: 'absolute',
              right: 96,
              top: 12,
              color: theme => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          dividers
          ref={squareRef}
          sx={{
            minHeight: height,
            // overflow: 'auto',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
              width: '0.4em'
            },
            '&::-webkit-scrollbar-track': {
              background: '#f1f1f1'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888'
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#555'
            }
          }}
        >
          {/* sx={{ overflowY: 'hidden ' }} */}
          <Grid container item xs={12} sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <Grid item xs={2} sx={{ alignSelf: 'flex-start' }}>
              <Typography className={styles.textProductOnboarding}>
                Product onboarding / <span className={styles.tabName}>{findCurrentTabName()}</span>
              </Typography>

              <Grid sx={{ position: 'relative', top: 70 }} container direction="column" alignItems="flex-start" justifyContent="center">
                <Typography className={styles.wizardStepperText}>Steps</Typography>

                <LinearProgress
                  variant="determinate"
                  value={stepPercentage}
                  sx={{
                    height: '400px',
                    '& span.MuiLinearProgress-bar': {
                      transform: `translateY(-${stepPercentage}%) !important`
                    },
                    width: 2,
                    '& .MuiLinearProgress-bar1Determinate': {
                      backgroundColor: '#29A364'
                    },
                    position: 'absolute',
                    left: 30,
                    top: 100
                  }}
                />

                {companySetupModalTabOptionsSection2.map((tab, j) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '20px'
                      // position: 'absolute'
                    }}
                    key={`createCompanyTabItem-${j}`}
                  >
                    {tab.visible && (
                      <Button
                        // disabled={!isFilled(tab.id)}
                        sx={{
                          borderRadius: `${customization.borderRadius}px`,
                          justifyContent: 'center'
                        }}
                        onClick={() => dispatch(setCompanySetupModalScreen(tab.id))}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            borderRadius: 10,
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <IconButton>
                            {/* <div className={styles.completedCircle}>
                              <span>{j}</span>
                            </div> */}

                            {isFilled(tab.id) ? (
                              <div className={styles.completedCircle}>
                                <span>{j + 1}</span>
                              </div>
                            ) : (
                              // <CircleIcon
                              //   sx={{
                              //     color: '#5ACC8D',
                              //     background: companySetupModalScreen === tab.id ? '#00213C' : '#90BEE9',
                              //     borderRadius: 20
                              //   }}
                              // />
                              <div className={companySetupModalScreen === tab.id ? styles.currentCircle : styles.notCompletedCircle}>
                                <span>{j + 1}</span>
                              </div>
                              // <PanoramaFishEyeIcon
                              //   sx={{
                              //     color: companySetupModalScreen === tab.id ? '#00213C' : '#A5A5A5',
                              //     background: companySetupModalScreen === tab.id ? '#90BEE9' : '#FFFFFF',
                              //     borderRadius: 20
                              //   }}
                              // />
                            )}
                          </IconButton>
                          {isFilled(tab.id) ? (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                marginLeft: '10px'
                              }}
                            >
                              <Typography
                                className={styles.completedText}
                                // sx={{ fontWeight: companySetupModalScreen === tab.id ? 600 : 200, marginLeft: '10px' }}
                              >
                                {tab.label}
                              </Typography>
                              <Typography className={styles.stepDescription}>{tab.description}</Typography>
                            </div>
                          ) : (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                marginLeft: '10px'
                              }}
                            >
                              <Typography
                                className={companySetupModalScreen === tab.id ? styles.activeText : styles.notFinishedText}
                                // sx={{ fontWeight: companySetupModalScreen === tab.id ? 600 : 200, marginLeft: '10px' }}
                              >
                                {tab.label}
                              </Typography>
                              <Typography className={styles.stepDescription}>{tab.description}</Typography>
                            </div>
                          )}
                        </Box>
                      </Button>
                    )}
                  </div>
                ))}
              </Grid>
              {companySetupModalScreen !== 9 && (
                <Grid
                  style={{
                    position: 'relative',
                    top: 250,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    paddingLeft: '15px',
                    width: '100%'
                  }}
                >
                  <Divider style={{ border: 'solid 1px #CFD0D9', width: '90%', marginBottom: '10px' }} />
                  <Button onClick={() => setTalkToAnExpertClicked(true)} variant="text" className={styles.talkToAnExpertButton}>
                    <img src={talkToExpertIcon} alt="Talk to expert" /> <p style={{ marginLeft: '10px' }}>Talk to an expert</p>
                  </Button>
                  <Button
                    onClick={() => window.open('https://support.mrgn.ai/', '_blank')}
                    variant="text"
                    className={styles.talkToAnExpertButton}
                  >
                    <img src={resourcesIcon} alt="Resources" /> <p style={{ marginLeft: '10px' }}>Resources</p>
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid item xs={9}>
              <CardContent
                sx={{
                  minHeight: height,
                  // overflow: 'auto',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  scrollbarWidth: 'thin',
                  '&::-webkit-scrollbar': {
                    width: '0.4em'
                  },
                  '&::-webkit-scrollbar-track': {
                    background: '#f1f1f1'
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#888'
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    background: '#555'
                  }
                }}
              >
                <TabPanel value={companySetupModalScreen} index={0}>
                  <CompanyTab ref={childRef} isDemoCompany={demo} />
                </TabPanel>
                <TabPanel value={companySetupModalScreen} index={1}>
                  <InfoTabView ref={childRef} isDemoCompany={demo} />
                </TabPanel>
                {/* <TabPanel value={companySetupModalScreen} index={2}>
                  <AiIntegration />
                </TabPanel> */}
                <TabPanel value={companySetupModalScreen} index={2}>
                  <DepartmentsTabView windowHeight={height} ref={childRef} isDemoCompany={demo} />
                </TabPanel>
                <TabPanel value={companySetupModalScreen} index={3}>
                  <NewExpensesTab ref={childRef} isDemoCompany={demo} />
                </TabPanel>
                {/* <TabPanel value={companySetupModalScreen} index={4}>
                  <CongratulationScreen windowHeight={height} ref={childRef} />
                </TabPanel> */}
                <TabPanel value={companySetupModalScreen} index={4}>
                  <MyTeamTab
                    ref={childRef}
                    windowHeight={height}
                    isDemoCompany={demo}
                    isTriggeredFromDashboard={isTriggeredFromDashboard}
                  />
                </TabPanel>
                <TabPanel value={companySetupModalScreen} index={5}>
                  <BusinessTabView ref={childRef} isDemoCompany={demo} />
                </TabPanel>
                <TabPanel value={companySetupModalScreen} index={6}>
                  <ProServices ref={childRef} isDemoCompany={demo} />
                </TabPanel>
                {/* <TabPanel value={companySetupModalScreen} index={6}>
                  <TopLineBillableClientsTabView windowHeight={height} ref={childRef} isDemoCompany={demo} />
                </TabPanel> */}
                {/* <TabPanel value={companySetupModalScreen} index={7}>
                  <DetailedBillableProductsTabView windowHeight={height} ref={childRef} isDemoCompany={demo} />
                </TabPanel> */}
                {/* <TabPanel value={companySetupModalScreen} index={8}>
                  <DetailedBillableClientsTabView windowHeight={height} ref={childRef} isDemoCompany={demo} />
                </TabPanel> */}
                <TabPanel value={companySetupModalScreen} index={7}>
                  <CommerceRetailProductsTabView windowHeight={height} ref={childRef} isDemoCompany={demo} />
                </TabPanel>
                <TabPanel value={companySetupModalScreen} index={8}>
                  <SaaSProductsTabView windowHeight={height} ref={childRef} isDemoCompany={demo} />
                </TabPanel>
                <TabPanel value={companySetupModalScreen} index={9}>
                  <ReviewTab ref={childRef} />
                </TabPanel>
                <TabPanel value={companySetupModalScreen} index={10}>
                  <AiIntegration ref={childRef} />
                </TabPanel>
              </CardContent>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: '14px 14px 2px 0px', display: 'flex', alignItems: 'center' }}>
          {companySetupModalScreen !== 0 && (
            <Button
              disabled={disabled}
              sx={{
                borderRadius: '8px',
                marginRight: '5px',
                position: 'absolute',
                left: 96,
                color: '#29a364',
                border: '1px solid #29a364',
                '&:hover': {
                  border: '1px solid #29a364',
                  background: 'none'
                }
              }}
              size="large"
              variant="outlined"
              onClick={() => {
                handleClick()
                if (companySetupModalScreen === 7) {
                  dispatch(setCompanySetupModalScreen(companySetupModalScreen - 2))
                } else if (companySetupModalScreen === 8) {
                  dispatch(setCompanySetupModalScreen(companySetupModalScreen - 3))
                } else if (companySetupModalScreen === 10) {
                  dispatch(setCompanySetupModalScreen(0))
                } else {
                  dispatch(setCompanySetupModalScreen(companySetupModalScreen - 1))
                }

                // if (companySetupModalScreen === 7) {
                //   dispatch(setCompanySetupModalScreen(companySetupModalScreen - 1))
                // } else if (companySetupModalScreen === 8) {
                //   dispatch(setCompanySetupModalScreen(companySetupModalScreen - 2))
                // } else if (companySetupModalScreen === 9) {
                //   dispatch(setCompanySetupModalScreen(companySetupModalScreen - 1))
                // } else if (companySetupModalScreen === 10) {
                //   dispatch(setCompanySetupModalScreen(companySetupModalScreen - 4))
                // } else if (companySetupModalScreen === 11) {
                //   dispatch(setCompanySetupModalScreen(companySetupModalScreen - 5))
                // } else {
                //   if (companySetupModalScreen === 5) {
                //     dispatch(setCompanySetupModalScreen(companySetupModalScreen - 2))
                //   } else {
                //     dispatch(setCompanySetupModalScreen(companySetupModalScreen - 1))
                //   }

                //   if (companySetupModalScreen === 2) {
                //     dispatch(setSelectedMenuItem('company'))
                //   } else if (companySetupModalScreen === 3) {
                //     dispatch(setSelectedMenuItem('department'))
                //   } else if (companySetupModalScreen === 4) {
                //     dispatch(setSelectedMenuItem('revenue'))
                //   }
                // }
              }}
            >
              <KeyboardBackspaceIcon sx={{ marginRight: '10px' }} />
              Back
            </Button>
          )}

          <LoadingButton
            disabled={disabled}
            loading={isLoading}
            size="large"
            variant="contained"
            className={styles.continueButton}
            onClick={() => {
              handleClick()
              if (isWizardProcessEnding) {
                dispatch(updateSetupModelPreviousStatus(false))
                dispatch(updateSelectedCompany({ companyId: '' }))
              }
              if (companySetupModalScreen === 9) {
                // finalize step to complete onboarding and remove the banner at top of page
                dispatch(completeProductOnboarding({ companyId: id }))
              }
              childRef.current.handleNext()
            }}
          >
            {getNextButtontext()}
          </LoadingButton>
        </DialogActions>
        <TalkToAnExperDrawer isTalkToAnExpertClicked={isTalkToAnExpertClicked} setTalkToAnExpertClicked={setTalkToAnExpertClicked} />
      </Dialog>
      <PopupNotify
        message="Are you sure you want to cancel company setup? All data will lost"
        submitBtnText="Yes"
        dismissBtnText="No"
        open={notify}
        onSubmit={() => {
          dispatch(changeLastTabLoaded(false))
          setNotify(false)
          dispatch(deleteCompanyById({ companyId: id }))
          dispatch(toggleCompanySetupModal(false))
          dispatch(updateSetupModelPreviousStatus(false))
          dispatch(updateSelectedCompany({ companyId: '' }))
        }}
        onDismiss={() => {
          setNotify(false)
        }}
      />
    </>
  )
}

export default CompanySetupModal
