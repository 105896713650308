import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// reducer import
import customizationReducer from './customizationReducer'
import snackbarReducer from './snackbarReducer'
import uiReducer from './ui/ui.slice'
import companyReducer from './company/company.slice'
import authReducer from './auth/auth.slice'
import modelsReducer from './models/models.slice'
import simulationsReducer from './simulations/simulations.slice'
import dashboardReducer from './dashboard/dashboard.slice'
import organisationReducer from './organisation/organisation.slice'
import quickbookReducer from './quickBook/quickbook.slice'
import paymentReducer from './payment/payment.slice'

const reducer = combineReducers({
  customization: persistReducer(
    {
      key: 'theme',
      storage,
      keyPrefix: 'mrgn-'
    },
    customizationReducer
  ),
  snackbar: snackbarReducer,
  auth: persistReducer(
    {
      key: 'auth',
      storage,
      keyPrefix: 'mrgn-',
      blacklist: ['userInvites']
    },
    authReducer
  ),
  ui: uiReducer,
  company: companyReducer,
  models: modelsReducer,
  simulations: simulationsReducer,
  dashboard: dashboardReducer,
  organisation: organisationReducer,
  quickbook: quickbookReducer,
  payment: paymentReducer
})

export default reducer
