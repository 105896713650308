import React, { useEffect, useRef, useState } from 'react'
import { Divider, Drawer, FormHelperText, Grid, IconButton, Box, Typography } from '@material-ui/core'
import cssClasses from './infoTab.module.css'

import CloseIcon from '@material-ui/icons/Close'
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined'
import FileUploadOutlinedIcon from '@material-ui/icons/FileUploadOutlined'

import UploadIcon from '../../assets/images/icons/UploadIcon.png'
import NumberFormat from 'react-number-format'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { companySelector } from 'store/company/company.slice'
import { useDispatch, useSelector } from 'react-redux'
import { authSelector, clearState, resetState } from 'store/auth/auth.slice'
import { finishCompanyCreate, getCompaniesByUser, getCompanyByID, updateCompany } from 'store/company/company.actions'
import dayjs from 'dayjs'
import moment from 'moment'
import { SNACKBAR_OPEN } from 'store/actions'
import { toggleCompanySetupModal } from 'store/ui/ui.slice'
import ProductList from './DrawerListPages/ProductList'
import ExcelImporTabModel from './excelImportTab'

const CommerceRetailDrawer = props => {
  const { isAddButtonClicked, setAddButtonClicked, editItem, setEditItem, setEditButtonClicked, editButtonClicked } = props
  const formRef = useRef()
  const dispatch = useDispatch()
  const { user } = useSelector(authSelector)
  const [addedProduct, setAddedProduct] = useState([])
  const {
    isLoading,
    isSuccess,
    isSuccessCompanyCreate,
    updateCompanyError,
    id,
    productList,
    selectedCompany,
    currentlyActiveCompanyId,
    vendorList
  } = useSelector(companySelector)

  const formikInitialValues = {
    _id: '',
    name: '',
    unitCOGS: '',
    price: '',
    growthIncrement: '',
    growthRate: '',
    startingUnits: '',
    startDate: '',
    submit: null
  }
  const [initialValues, setInitialValues] = useState(formikInitialValues)

  const handleAddProduct = (name, unitCOGS, price, growthIncrement, growthRate, startingUnits, startDate) => {
    const payload = {
      name,
      unitCOGS,
      price,
      growthIncrement,
      growthRate,
      startingUnits,
      startDate
    }

    const prodList = []
    prodList.push(payload)
    setAddedProduct(prodList)

    const logArray = []

    const prodChangeLog = {
      user: user._id,
      userName: user.name,
      company: selectedCompany,
      date: new Date(),
      tabChanged: 'Revenue/ Commerce_Retail / Product',
      department: 'N/A',
      lineItemChanged: `${payload.name} (New Product added)`,
      originalValue: 'N/A',
      newValue: 'N/A'
    }
    logArray.push(prodChangeLog)

    const productPayload = [...productList]
    productPayload.push(payload)
    if (id !== '') {
      const data = {
        id,
        productList: productPayload,
        systemLog: logArray
      }
      dispatch(updateCompany(data))
    }
    setInitialValues(formikInitialValues)
  }

  useEffect(() => {
    const tempObject = { ...editItem }
    const d = new Date(tempObject.startDate)
    if (editButtonClicked) {
      setInitialValues({
        ...editItem,
        startDate: dayjs(d).format('YYYY-MM-DD'),
        // startDate: d.toLocaleDateString('en-US'),
        submit: null
      })
    }
  }, [editButtonClicked])

  // const editProduct = item => {
  //   setEditItem(item._id)
  //   const tempObject = { ...item }
  //   const d = new Date(tempObject.startDate)

  //   setInitialValues({
  //     ...item,
  //     startDate: dayjs(d).format('YYYY/MM/DD'),
  //     submit: null
  //   })
  // }

  const handleDeleteProduct = _id => {
    if (_id === editItem) return
    // if (formRef.current.values._id) {
    //   setNotify(true)
    //   return
    // }

    const selectedProd = [...productList].filter(item => item._id === _id)

    const payload = {
      _id: selectedProd[0]._id,
      name: selectedProd[0].name
    }
    const productPayload = [...productList].filter(item => item._id !== _id)
    const logArray = []

    const productChangeLog = {
      user: user._id,
      userName: user.name,
      company: selectedCompany,
      date: new Date(),
      tabChanged: 'Revenue/ Commerce_Retail / Product',
      department: 'N/A',
      lineItemChanged: `${payload.name} (Product deleted)`,
      originalValue: 'N/A',
      newValue: 'N/A'
    }
    logArray.push(productChangeLog)

    if (id !== '') {
      const data = {
        id,
        productList: productPayload,
        systemLog: logArray
      }
      dispatch(updateCompany(data))
    }
  }

  const handleUpdateProduct = (_id, name, unitCOGS, price, growthIncrement, growthRate, startingUnits, startDate) => {
    setEditItem('')
    const payload = {
      _id,
      name,
      unitCOGS,
      price,
      growthIncrement,
      growthRate,
      startingUnits,
      startDate
    }

    const prodList = []
    prodList.push(payload)
    setAddedProduct(prodList)

    const productPayload = [...productList]
    const index = productPayload.findIndex(item => item._id === payload._id)

    //  system logs
    const logArray = []
    if (productPayload[index].name !== payload.name) {
      const vendorChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Commerce / Retail',
        department: 'N/A',
        lineItemChanged: `${productPayload[index].name} (Name changed)`,
        originalValue: productPayload[index].name,
        newValue: payload.name
      }
      logArray.push(vendorChangeLog)
    }

    if (productPayload[index].growthIncrement !== payload.growthIncrement) {
      const vendorChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Commerce / Retail',
        department: 'N/A',
        lineItemChanged: `${productPayload[index].name} (Growth Increment changed)`,
        originalValue: productPayload[index].growthIncrement,
        newValue: payload.growthIncrement
      }
      logArray.push(vendorChangeLog)
    }

    if (productPayload[index].growthRate !== payload.growthRate) {
      const vendorChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Commerce / Retail',
        department: 'N/A',
        lineItemChanged: `${productPayload[index].name} (Growth Rate changed)`,
        originalValue: productPayload[index].growthRate,
        newValue: payload.growthRate
      }
      logArray.push(vendorChangeLog)
    }

    if (productPayload[index].price !== payload.price) {
      const vendorChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Commerce / Retail',
        department: 'N/A',
        lineItemChanged: `${productPayload[index].name} (Price changed)`,
        originalValue: productPayload[index].price,
        newValue: payload.price
      }
      logArray.push(vendorChangeLog)
    }

    if (productPayload[index].startingUnits !== payload.startingUnits) {
      const vendorChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Commerce / Retail',
        department: 'N/A',
        lineItemChanged: `${productPayload[index].name} (Starting Units changed)`,
        originalValue: productPayload[index].startingUnits,
        newValue: payload.startingUnits
      }
      logArray.push(vendorChangeLog)
    }

    if (productPayload[index].unitCOGS !== payload.unitCOGS) {
      const vendorChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Commerce / Retail',
        department: 'N/A',
        lineItemChanged: `${productPayload[index].name} (Unit COGS changed)`,
        originalValue: productPayload[index].unitCOGS,
        newValue: payload.unitCOGS
      }
      logArray.push(vendorChangeLog)
    }

    if (dayjs(productPayload[index].startDate).format('YYYY/MM/DD') !== dayjs(payload.startDate).format('YYYY/MM/DD')) {
      const employeeChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Commerce / Retail',
        department: 'N/A',
        lineItemChanged: `${productPayload[index].name} (Start Date changed)`,
        originalValue: dayjs(productPayload[index].startDate).format('MM/DD/YYYY'),
        newValue: dayjs(payload.startDate).format('MM/DD/YYYY')
      }
      logArray.push(employeeChangeLog)
    }

    productPayload[index] = payload
    if (id !== '') {
      const data = {
        id,
        productList: productPayload,
        systemLog: logArray
      }
      dispatch(updateCompany(data))
    }
    setInitialValues(formikInitialValues)
  }

  useEffect(() => {
    if (updateCompanyError !== '') {
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: updateCompanyError,
        variant: 'alert',
        alertSeverity: 'error'
      })
    }

    return dispatch(clearState())
  }, [dispatch, updateCompanyError])

  // useEffect(() => {
  //   if (isSuccess && isNext) {
  //     dispatch(clearState())
  //     const data = {
  //       companyId: id,
  //       isComplete: isCoaCompleted,
  //       fromRevenue: true
  //     }
  //     dispatch(finishCompanyCreate(data))
  //   }
  // }, [isNext, isSuccess])

  useEffect(() => {
    if (isSuccessCompanyCreate) {
      dispatch(toggleCompanySetupModal(false))
      // dispatch(setCompanySetupModalScreen(0))
      dispatch(resetState())
      dispatch(getCompaniesByUser())
      const data = {
        companyId: currentlyActiveCompanyId || selectedCompany,
        email: user.email
      }
      dispatch(getCompanyByID(data))
    }
  }, [isSuccessCompanyCreate])

  const customStyles = {
    backdrop: {
      fill: 'var(--neutrals-cool-1000, #0C0D0F)',
      opacity: 0.25
    }
  }

  const onClickCancelButton = () => {
    setEditItem('')
    setEditButtonClicked(false)
    setInitialValues(formikInitialValues)
  }

  return (
    <Drawer
      BackdropProps={{ style: customStyles.backdrop }}
      style={{ zIndex: 1000000 }}
      anchor="right"
      open={isAddButtonClicked}
      onClose={() => setAddButtonClicked(false)}
      sx={{
        '& .css-4qyyic-MuiGrid-root': {
          padding: '24px',
          width: '390px'
        }
      }}
    >
      <Grid p={5} width="500px" role="presentation" textAlign="start">
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={() => setAddButtonClicked(false)}>
            <CloseIcon style={{ color: 'black' }} />
          </IconButton>
        </div>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <h1 className={cssClasses.drawerHeading}>Business</h1>
          <FileUploadOutlinedIcon style={{ color: 'black', fontSize: '24px' }} />
        </div>
        <p className={cssClasses.drawerDesc}>
          Let’s add the basic revenue model information about your company, by adding the required information below.
        </p>
        {addedProduct.length === 0 ? (
          <div style={{ width: '100%' }}>
            <Typography className={cssClasses.drawerHeadingSecond} style={{ marginBottom: '11px' }}>
              Commercial / retail
            </Typography>
            <ExcelImporTabModel excelImportTabNo={9} />
            <Formik
              validateOnMount
              innerRef={formRef}
              enableReinitialize
              initialValues={initialValues}
              validationSchema={Yup.object().shape({
                name: Yup.string().required('Name is required').max(30, 'Maximum 30 characters allowed'),
                unitCOGS: Yup.string().required('Cost (CoGS) is required').max(30, 'Maximum 30 characters allowed'),
                price: Yup.string().required('Retail price is required').max(30, 'Maximum 30 characters allowed'),
                growthIncrement: Yup.string().required('Growth increment is required'),
                growthRate: Yup.string().required('Percentage growth per period is required').max(30, 'Maximum 30 characters allowed'),
                startingUnits: Yup.string().required('Starting units are required').max(30, 'Maximum 30 characters allowed'),
                startDate: Yup.string()
                  .required('Start date is required')
                  .test('is-valid-year', 'Year must be a valid 4-digit year', value => {
                    if (value) {
                      const year = new Date(value).getFullYear()
                      return year >= 1000 && year <= 9999
                    }
                    return false
                  })
              })}
              onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
                if (values._id) {
                  handleUpdateProduct(
                    values._id,
                    values.name,
                    values.unitCOGS.toString().includes(',') ? parseInt(values.unitCOGS.replace(/,/g, ''), 10) : values.unitCOGS,
                    values.price.toString().includes(',') ? parseInt(values.price.replace(/,/g, ''), 10) : values.price,
                    values.growthIncrement,
                    values.growthRate.toString().includes(',') ? parseInt(values.growthRate.replace(/,/g, ''), 10) : values.growthRate,
                    values.startingUnits.toString().includes(',')
                      ? parseInt(values.startingUnits.replace(/,/g, ''), 10)
                      : values.startingUnits,
                    new Date(moment(values.startDate).format('MM-DD-YYYY'))
                    // new Date(values.startDate).toLocaleDateString('en-US')
                  )
                } else {
                  handleAddProduct(
                    values.name,
                    values.unitCOGS.toString().includes(',') ? parseInt(values.unitCOGS.replace(/,/g, ''), 10) : values.unitCOGS,
                    values.price.toString().includes(',') ? parseInt(values.price.replace(/,/g, ''), 10) : values.price,
                    values.growthIncrement,
                    values.growthRate.toString().includes(',') ? parseInt(values.growthRate.replace(/,/g, ''), 10) : values.growthRate,
                    values.startingUnits.toString().includes(',')
                      ? parseInt(values.startingUnits.replace(/,/g, ''), 10)
                      : values.startingUnits,
                    values.startDate
                    // new Date(values.startDate).toLocaleDateString('en-US')
                  )
                }
                setStatus({ success: true })
                setSubmitting(false)
                // resetForm(initialValues)
                setInitialValues(formikInitialValues)
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
                setFieldTouched,
                isValid
              }) => (
                <form noValidate onSubmit={handleSubmit} style={{ width: '100%', marginTop: '12px' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label htmlFor="product-name" className={cssClasses.drawerInputLabel}>
                        Product name <span className={cssClasses.labelStar}>*</span>{' '}
                      </label>

                      <input
                        value={values.name}
                        name="name"
                        onBlur={handleBlur}
                        onChange={e => {
                          handleChange(e)
                          if (values.name) setFieldTouched('name', true)
                        }}
                        placeholder="Product name"
                        id="product-name"
                        className={cssClasses.drawerInput}
                      />
                      {touched.name && errors.name && <FormHelperText error> {errors.name} </FormHelperText>}
                    </div>{' '}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label htmlFor="amount" className={cssClasses.drawerInputLabel}>
                        Cost (CoGS) <span className={cssClasses.labelStar}>*</span>{' '}
                      </label>

                      <div className={cssClasses.containerInputs}>
                        <MonetizationOnOutlinedIcon />
                        <NumberFormat
                          className={cssClasses.transparentInputs}
                          placeholder="Cost (CoGS)"
                          value={values.unitCOGS}
                          name="unitCOGS"
                          onBlur={handleBlur}
                          onChange={e => {
                            handleChange(e)
                            if (values.unitCOGS) setFieldTouched('unitCOGS', true)
                          }}
                          id="amount"
                          thousandSeparator=","
                        />
                      </div>
                      {touched.unitCOGS && errors.unitCOGS && <FormHelperText error> {errors.unitCOGS} </FormHelperText>}
                    </div>{' '}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label htmlFor="retail_price" className={cssClasses.drawerInputLabel}>
                        Retail price <span className={cssClasses.labelStar}>*</span>{' '}
                      </label>

                      <div className={cssClasses.containerInputs}>
                        <MonetizationOnOutlinedIcon />
                        <NumberFormat
                          className={cssClasses.transparentInputs}
                          placeholder="Retail price"
                          value={values.price}
                          name="price"
                          onBlur={handleBlur}
                          onChange={e => {
                            handleChange(e)
                            if (values.price) setFieldTouched('price', true)
                          }}
                          id="retail_price"
                          thousandSeparator=","
                        />
                      </div>
                      {touched.price && errors.price && <FormHelperText error> {errors.price} </FormHelperText>}
                    </div>{' '}
                    <div className={cssClasses.drawerSelectInput}>
                      <label htmlFor="growth_increment" className={cssClasses.drawerInputLabel}>
                        Growth increment <span className={cssClasses.labelStar}>*</span>{' '}
                      </label>
                      <select
                        className={cssClasses.drawerInput}
                        style={{ width: '100%' }}
                        placeholder="Growth increment"
                        id="growth_increment"
                        value={values.growthIncrement}
                        name="growthIncrement"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected hidden>
                          Select
                        </option>
                        <option value="Monthly">Monthly</option>
                        <option value="Quarterly">Quarterly</option>
                        <option value="Annually">Annually</option>
                      </select>
                      {touched.growthIncrement && errors.growthIncrement && (
                        <FormHelperText error> {errors.growthIncrement} </FormHelperText>
                      )}
                    </div>{' '}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label htmlFor="growth_percentage" className={cssClasses.drawerInputLabel}>
                        Growth percentage <span className={cssClasses.labelStar}>*</span>{' '}
                      </label>
                      <div className={cssClasses.containerInputs}>
                        <NumberFormat
                          placeholder="Growth percentage"
                          id="growth_percentage"
                          className={cssClasses.transparentInputs}
                          value={values.growthRate}
                          name="growthRate"
                          onBlur={handleBlur}
                          onChange={e => {
                            handleChange(e)
                            if (values.growthRate) setFieldTouched('growthRate', true)
                          }}
                        />{' '}
                        <Typography className={cssClasses.inputPercentage}>%</Typography>
                      </div>
                      {touched.growthRate && errors.growthRate && <FormHelperText error> {errors.growthRate} </FormHelperText>}
                    </div>{' '}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label htmlFor="starting_units" className={cssClasses.drawerInputLabel}>
                        Starting units <span className={cssClasses.labelStar}>*</span>{' '}
                      </label>

                      <div className={cssClasses.containerInputs}>
                        <MonetizationOnOutlinedIcon />
                        <NumberFormat
                          className={cssClasses.transparentInputs}
                          placeholder="Starting units"
                          value={values.startingUnits}
                          name="startingUnits"
                          onBlur={handleBlur}
                          onChange={e => {
                            handleChange(e)
                            if (values.startingUnits) setFieldTouched('startingUnits', true)
                          }}
                          id="starting_units"
                          thousandSeparator=","
                        />
                      </div>
                      {touched.startingUnits && errors.startingUnits && <FormHelperText error> {errors.startingUnits} </FormHelperText>}
                    </div>{' '}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label htmlFor="start_date" className={cssClasses.drawerInputLabel}>
                        Start date <span className={cssClasses.labelStar}>*</span>{' '}
                      </label>
                      <input
                        placeholder="Start date (MM-DD-YYYY)"
                        value={values.startDate}
                        name="startDate"
                        id="start_date"
                        className={cssClasses.drawerInput}
                        type="date"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {touched.startDate && errors.startDate && <FormHelperText error>{errors.startDate}</FormHelperText>}
                    </div>{' '}
                  </Box>

                  <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button
                      // disabled={!isValid || isSubmitting}
                      className={isValid ? cssClasses.wizardAddButton : cssClasses.wizardAddButtonDisabled}
                      // className={!isValid || isSubmitting ? cssClasses.addButtonDisabled : cssClasses.wizardAddButton}
                      type="submit"
                    >
                      Add
                    </button>
                  </div>
                  <Divider sx={{ marginTop: '230px', marginBottom: '10px' }} />
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button onClick={onClickCancelButton} className={cssClasses.wizardCancelButton} type="button">
                      Cancel
                    </button>
                    <button className={cssClasses.disabledButton} type="button">
                      Save
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        ) : (
          <ProductList
            addedProduct={addedProduct}
            setAddedProduct={setAddedProduct}
            setEditItem={setEditItem}
            model="Commercial / retail"
            setEditButtonClicked={setEditButtonClicked}
            setAddButtonClicked={setAddButtonClicked}
          />
        )}
      </Grid>
    </Drawer>
  )
}

export default CommerceRetailDrawer
