import React, { useState } from 'react'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import { Grid } from '@material-ui/core'
import { gridSpacing } from 'store/constant'
import { LoadingButton } from '@material-ui/lab'

const SetupForm = () => {
  const stripe = useStripe()
  const elements = useElements()
  const [isLoading, setIsLoading] = useState(false)

  const [errorMessage, setErrorMessage] = useState(null)

  const handleSubmit = async event => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }
    setIsLoading(true)
    const { error } = await stripe.confirmSetup({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/loader`
      }
    })

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
    setIsLoading(false)
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <Grid container spacing={gridSpacing} sx={{ mt: 2 }} justifyContent="center">
        <LoadingButton
          size="large"
          variant="contained"
          disabled={!stripe || !elements}
          loading={isLoading}
          id="submit"
          type="submit"
          sx={{ textAlign: 'center' }}
        >
          Subscribe Now
        </LoadingButton>
      </Grid>
      <Grid container spacing={gridSpacing} sx={{ mt: 2 }} justifyContent="center">
        {' '}
        {errorMessage && <div id="payment-message">{errorMessage}</div>}
      </Grid>
    </form>
  )
}

export default SetupForm
