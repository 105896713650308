import React from 'react'
import { Box, IconButton, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { HtmlTooltip } from 'utils/ui'
import IconHelp from '@material-ui/icons/HelpOutline'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

const ModalSubtitle = props => {
  const { title, caption, toolTip, qbIcon } = props

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
          pl: title !== 'Time to dig a little deeper' ? 7 : 16,
          pr: title !== 'Time to dig a little deeper' ? 7 : 16
        }}
      >
        {title && (
          <Typography variant="h3" color="primary" sx={{ pb: 1, fontFamily: 'Adobe Clean', fontWeight: 800 }}>
            {title}
          </Typography>
        )}

        {caption && (
          <>
            <Typography
              sx={{ pb: 1, fontFamily: 'Adobe Clean', fontWeight: 200, color: '#7C8492', fontSize: 16 }}
              variant="body2"
              color="text.secondary"
            >
              {caption}{' '}
            </Typography>
            <Typography sx={{ pb: 1, fontFamily: 'Adobe Clean', fontWeight: 900 }}>
              {toolTip && toolTip.text && (
                <a
                  // sx={{ pb: 1, fontFamily: 'Adobe Clean', fontWeight: 900 }}
                  style={{ display: 'inline-block', textDecoration: 'none', color: '#145D99', fontStyle: 'Italic' }}
                  href={toolTip.link}
                  rel="noreferrer"
                  target="_blank"
                >
                  Learn More
                </a>
              )}
            </Typography>
          </>
        )}
      </Box>
    </>
  )
}

ModalSubtitle.propTypes = {
  title: PropTypes.any,
  caption: PropTypes.any,
  toolTip: PropTypes.any,
  qbIcon: PropTypes.any
}

export default ModalSubtitle
