import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { useTheme } from '@emotion/react'

import { resetState, companySelector, clearState } from 'store/company/company.slice'
import { modelsSelector } from 'store/models/models.slice'
import { clearEquityRaise, setEquityRaiseInitialModelScreen, setEquityRaiseTranchCount, uiSelector } from 'store/ui/ui.slice'

const useStyles = makeStyles(theme => ({
  // muiDialog: {
  //   '& .MuiDialog-paper': {
  //     height: '503px',
  //     width: '706px'
  //   }
  // },
  // muiDialogContent: {
  //   [theme.breakpoints.up('sm')]: {
  //     overflowY: 'hidden',
  //     paddingRight: 0
  //   }
  // },
  // gridFillHeight: {
  //   [theme.breakpoints.up('sm')]: {
  //     height: '100%'
  //   }
  // },
  tranchesLabel: {
    fontSize: '16px',
    color: 'black'
  }
}))

const EquityRaiseTabOne = () => {
  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()

  const onchangeTrancheshandler = event => {
    dispatch(setEquityRaiseTranchCount(event.target.value))
  }

  const { equityRaiseTranchCount } = useSelector(uiSelector)

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={3}
      style={{
        textAlign: 'center',
        paddingLeft: '15px',
        paddingTop: '10px',
        paddingRight: '25px',
        paddingBottom: 0,
        background: '#F0F0F0',
        height: '100%',
        overflow: 'hidden'
      }}
    >
      <Grid container direction="column" justifyContent="center" alignItems="center" item xs={12} sx={{ height: '100%' }}>
        <Typography sx={{ fontWeight: '400', mt: 2, color: '#091A36', textAlign: 'center', fontSize: '16px' }}>
          Simulate your equity raise by letting us know how many tranches (payments) you’ll be receiving from investors and how you’d like
          to distribute the proceeds of your fundraise throughout your budget.
        </Typography>
      </Grid>
      <Grid container direction="column" justifyContent="center" alignItems="center" item xs={12}>
        <Typography sx={{ fontWeight: '600', mt: 2, fontSize: '16px', color: '#091A36' }}>
          How many tranches (or payments) will the fundraise be broken into?
        </Typography>
      </Grid>
      <Grid container direction="column" justifyContent="center" alignItems="center" item md="auto">
        <FormControl sx={{ width: '150px', mb: 2 }}>
          <InputLabel className={classes.tranchesLabel} id="input_tranches_no">
            Tranches
          </InputLabel>
          <Select labelId="input_tranches_no" value={equityRaiseTranchCount} label="Tranches" onChange={onchangeTrancheshandler}>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {/* <Grid container justifyContent="end" alignItems="center" item xs={12} style={{ background: 'black' }}>
        {equityRaiseTranchCount > 0 ? (
          <Button
            onClick={e => {
              dispatch(setEquityRaiseInitialModelScreen(1))
              dispatch(clearEquityRaise())
            }}
            style={{}}
            size="large"
            variant="contained"
            sx={{ mt: 2 }}
          >
            Next
          </Button>
        ) : (
          <Button style={{ cursor: 'not-allowed', background: 'grey' }} size="large" variant="contained" sx={{ mt: 2 }}>
            Next
          </Button>
        )}
      </Grid> */}
    </Grid>
  )
}

export default EquityRaiseTabOne
