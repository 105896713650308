import React, { forwardRef, useImperativeHandle, useRef } from 'react'

import IndustriesList from 'data/IndustriesList'

import { Formik } from 'formik'
import * as Yup from 'yup'
import { setCompanySetupModalScreen, setRegistrationSetupModelScreen, uiSelector } from 'store/ui/ui.slice'
import { useDispatch, useSelector } from 'react-redux'
import { createCompany, updateCompany } from 'store/company/company.actions'
import { companySelector } from 'store/company/company.slice'
import { FormHelperText } from '@material-ui/core'

import cssClasses from './infoTab.module.css'

const CompanyManual = forwardRef((props, ref) => {
  const { isDemoCompany } = props
  const formRef = useRef(null)
  const dispatch = useDispatch()
  const { companySetupModalScreen, registrationSetupModelScreen } = useSelector(uiSelector)
  const { id } = useSelector(companySelector)

  const initialValues = {
    name: '',
    industry: '',
    submit: null
  }

  return (
    <div>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Company name is required'),
          industry: Yup.string().required('Industry is required')
          // bankBalance: Yup.string()
          //   .required('Bank balance is required')
          //   .matches(/^\d+(\.\d+)?$/, 'Enter a valid Amount')
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          if (isDemoCompany) {
            dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
            return
          }
          try {
            const data = {
              name: values.name,
              industry: values.industry,
              // bankBalance: values.bankBalance.toString().includes(',')
              //   ? parseInt(values.bankBalance.replace(/,/g, ''), 10)
              //   : values.bankBalance,
              bankBalance: 0,
              projectionMonths: 36
              // systemLog: logArray
            }

            if (id === '') {
              dispatch(createCompany(data))
            } else {
              data.id = id
              dispatch(updateCompany(data))
            }

            dispatch(setRegistrationSetupModelScreen(registrationSetupModelScreen + 1))

            setStatus({ success: true })
            setSubmitting(false)
          } catch (err) {
            setStatus({ success: false })
            setErrors({ submit: err.message })
            setSubmitting(false)
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '80%', marginTop: '30px' }}>
              <div>
                <label className={cssClasses.inputLabel} htmlFor="company_name">
                  Company name <span className={cssClasses.important}>*</span>
                  <input
                    className={cssClasses.inputField}
                    disabled={isDemoCompany}
                    placeholder="Type here"
                    value={values.name}
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    id="company_name"
                  />
                </label>

                {touched.name && errors.name && <FormHelperText error> {errors.name} </FormHelperText>}
              </div>
              <div>
                <label className={cssClasses.inputLabel} htmlFor="industry-name">
                  Industry <span className={cssClasses.important}>*</span>
                  <select
                    className={cssClasses.inputField}
                    placeholder="Select"
                    id="industry-name"
                    disabled={isDemoCompany}
                    value={values.industry}
                    name="industry"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    <option disabled value="">
                      Select
                    </option>
                    {IndustriesList.map((industry, i) => (
                      <option value={industry} key={`industryOption-${i}`}>
                        {industry}
                      </option>
                    ))}
                  </select>
                </label>
                {touched.industry && errors.industry && <FormHelperText error> {errors.industry} </FormHelperText>}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
})

export default CompanyManual
