import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Button,
  Paper,
  TableContainer,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Box
} from '@material-ui/core'
import * as Yup from 'yup'
import { Formik } from 'formik'

import { createTheme } from '@material-ui/core/styles'

import { useDispatch, useSelector } from 'react-redux'

import { gridSpacing } from 'store/constant'
import ModalSubtitle from './ModalSubtitle'

import { uiSelector, setCompanySetupModalScreen, updateModalTabVisibility, setSelectedMenuItem } from 'store/ui/ui.slice'
import { updateCompany } from 'store/company/company.actions'
import { clearState, companySelector } from 'store/company/company.slice'
import { SNACKBAR_OPEN } from 'store/actions'

import TopLine from 'ui-component/table/preview/TopLine'
import TopLineBillable from 'ui-component/table/preview/TopLineBillable'
import DetailedBillableProducts from 'ui-component/table/preview/DetailedBillableProducts'
import CommerceRetailProducts from 'ui-component/table/preview/CommerceRetailProducts'
import SaaSFlatRate from 'ui-component/table/preview/SaaSFlatRate'

import { makeStyles } from '@material-ui/styles'
import { authSelector } from 'store/auth/auth.slice'

const useStyles = makeStyles(theme => ({
  buttonTop: {
    background: '#ffffff',
    width: 150,
    margin: 10,
    height: 60,
    fontWeight: 600,
    border: 'solid 1px #D8D8D8',
    borderRadius: 10,
    '&:hover': {
      backgroundColor: '#ffffff'
    }
  },
  buttonTopSelected: {
    background: '#091A36',
    width: 150,
    margin: 10,
    height: 60,
    fontWeight: 600,
    border: 'solid 1px #D8D8D8',
    borderRadius: 10,
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#091A36'
    }
  },
  dropdownStyle: {
    borderRadius: '50px !important',
    '& .MuiOutlinedInput-input': {
      borderRadius: '8px !important'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px !important'
    }
  },
  table: {
    '& .MuiTableCell-root': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)'
    }
  }
}))
const RevenueTabView = forwardRef((props, ref) => {
  const { isDemoCompany } = props

  const classes = useStyles()
  const dispatch = useDispatch()
  const formRef = useRef()
  const customization = useSelector(state => state.customization)
  const { companySetupModalScreen } = useSelector(uiSelector)
  const { user } = useSelector(authSelector)
  const {
    isLoading,
    isSuccess,
    updateCompanyError,
    id,
    revenueModel,
    revenueComputationType,
    productList,
    selectedCompany,
    isTriggeredFromDashboard,
    tempRevenueModel,
    tempRevenueComputationType
  } = useSelector(companySelector)

  const [validation, setValidation] = useState(false)

  const theme = createTheme({
    palette: {
      primary: {
        main: '#e0e0e0'
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000'
      }
    }
  })
  const initialValues = {
    revenueModel: tempRevenueModel || revenueModel || 'Pro Services',
    revenueComputationType: tempRevenueComputationType || revenueComputationType,
    submit: null
  }

  useImperativeHandle(ref, () => ({
    handleNext() {
      if (formRef.current) formRef.current.handleSubmit()
    }
  }))

  useEffect(() => {
    if (updateCompanyError !== '') {
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: updateCompanyError,
        variant: 'alert',
        alertSeverity: 'error'
      })
    }

    return dispatch(clearState())
  }, [dispatch, updateCompanyError])

  useEffect(() => {
    if (isSuccess) {
      if (tempRevenueModel) {
        if (tempRevenueModel === 'Pro Services') {
          if (tempRevenueComputationType === 'Top Line Billable') dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
          if (tempRevenueComputationType === 'Detailed Billable + Products')
            dispatch(setCompanySetupModalScreen(companySetupModalScreen + 2))
        }
        if (tempRevenueModel === 'Commerce / Retail') {
          dispatch(setCompanySetupModalScreen(companySetupModalScreen + 4))
        }
        if (tempRevenueModel === 'SaaS') {
          dispatch(setCompanySetupModalScreen(companySetupModalScreen + 5))
        }
      } else {
        if (revenueModel === 'Pro Services') {
          if (revenueComputationType === 'Top Line Billable') dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
          if (revenueComputationType === 'Detailed Billable + Products') dispatch(setCompanySetupModalScreen(companySetupModalScreen + 2))
        }
        if (revenueModel === 'Commerce / Retail') {
          dispatch(setCompanySetupModalScreen(companySetupModalScreen + 4))
        }
        if (revenueModel === 'SaaS') {
          dispatch(setCompanySetupModalScreen(companySetupModalScreen + 5))
        }
      }

      dispatch(clearState())
    }
  }, [companySetupModalScreen, dispatch, isSuccess, revenueComputationType, revenueModel])

  const updatePreviewData = (revenueModel, revenueComputationType) => {
    dispatch(updateModalTabVisibility({ revenueModel, revenueComputationType }))
  }

  return (
    <Box name="outerBox" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          revenueModel: Yup.string().required('Revenue model is required')
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
          if (!values.revenueComputationType) {
            setValidation(true)
          } else if (values) {
            setValidation(false)
          }
          if (isDemoCompany) {
            // if (values.revenueModel === 'Top Line') {
            //   dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
            // }
            if (values.revenueModel === 'Pro Services') {
              if (values.revenueComputationType === 'Top Line Billable') {
                dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
              } else {
                dispatch(setCompanySetupModalScreen(companySetupModalScreen + 2))
              }
            }
            if (values.revenueModel === 'Commerce / Retail') {
              dispatch(setCompanySetupModalScreen(companySetupModalScreen + 4))
            }
            if (values.revenueModel === 'SaaS') {
              dispatch(setCompanySetupModalScreen(companySetupModalScreen + 5))
            }
            return
          }

          if (id !== '') {
            const data = {
              id,
              revenueModel: values.revenueModel,
              revenueComputationType: values.revenueComputationType
            }
            const logArray = []
            if (revenueModel !== values.revenueModel) {
              const employeeChangeLog = {
                user: user.id,
                userName: user.name,
                company: selectedCompany,
                date: new Date(),
                tabChanged: 'Revenue',
                department: 'N/A',
                lineItemChanged: `Revenue (Revenue Model Changed)`,
                originalValue: revenueModel || 'NA',
                newValue: values.revenueModel
              }
              logArray.push(employeeChangeLog)
            }

            if (revenueComputationType !== values.revenueComputationType) {
              const employeeChangeLog = {
                user: user.id,
                userName: user.name,
                company: selectedCompany,
                date: new Date(),
                tabChanged: 'Revenue',
                department: 'N/A',
                lineItemChanged: `Revenue (Revenue Computation Type Changed)`,
                originalValue: revenueComputationType || 'NA',
                newValue: values.revenueComputationType
              }
              logArray.push(employeeChangeLog)
            }
            data.systemLog = logArray

            if (values.revenueModel === 'Pro Services') {
              if (values.revenueComputationType === 'Top Line Billable') {
                dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
                data.currentModelScreen = companySetupModalScreen + 1
              } else {
                dispatch(setCompanySetupModalScreen(companySetupModalScreen + 2))
                data.currentModelScreen = companySetupModalScreen + 2
              }
              if (values.revenueModel !== revenueModel) {
                data.productList = []
              }
              if (values.revenueComputationType !== revenueComputationType) {
                data.productList = []
                data.clientList = []
              }
              // if (productList.length === 0) {
              //   data.clientList = []
              // }
            }
            if (values.revenueModel === 'Commerce / Retail') {
              dispatch(setCompanySetupModalScreen(companySetupModalScreen + 4))
              data.currentModelScreen = companySetupModalScreen + 4
              data.clientList = []
              if (values.revenueModel !== revenueModel) {
                data.productList = []
              }
              data.revenueComputationType = ''
            }
            if (values.revenueModel === 'SaaS') {
              dispatch(setCompanySetupModalScreen(companySetupModalScreen + 5))
              data.currentModelScreen = companySetupModalScreen + 5
              if (values.revenueModel !== revenueModel) {
                data.productList = []
              }
              data.clientList = []
              data.revenueComputationType = ''
            }

            dispatch(updateCompany(data))
          }
          setStatus({ success: true })
          setSubmitting(false)
          // resetForm(initialValues)
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container sx={{ display: 'flex', flexDirection: 'column' }} spacing={gridSpacing}>
              <Grid item sx={{ marginBottom: '20px', marginLeft: '10px' }}>
                <Grid item sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    sx={{
                      fontSize: '17px',
                      fontWeight: 600,
                      textAlign: 'left',
                      color: '#00213C',
                      marginBottom: '10px'
                    }}
                  >
                    Choose Revenue Model Type
                  </Typography>
                  <Typography
                    sx={{
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '12.5px',
                      color: '#707070'
                    }}
                  >
                    Tell us some basic information about your company
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth sx={{ minWidth: '325px' }}>
                  <InputLabel style={{ color: '#a9a9a9', fontWeight: '400', fontSize: '14px' }} id="revenue-model-select-label">
                    Revenue Model Type
                  </InputLabel>
                  <Select
                    disabled={isDemoCompany}
                    sx={{ colour: '#707070' }}
                    className={classes.dropdownStyle}
                    labelId="revenue-model-select-label"
                    label="Revenue Model Type"
                    value={values.revenueModel}
                    name="revenueModel"
                    onBlur={handleBlur}
                    onChange={e => {
                      setFieldValue('revenueModel', e.target.value)
                    }}
                  >
                    {/* <MenuItem value="Top Line">Top Line Billable</MenuItem> */}
                    <MenuItem value="Pro Services">Pro Services</MenuItem>
                    <MenuItem value="Commerce / Retail">Commerce / Retail</MenuItem>
                    <MenuItem value="SaaS">SaaS</MenuItem>
                  </Select>
                  {touched.revenueModel && errors.revenueModel && <FormHelperText error> {errors.revenueModel} </FormHelperText>}
                </FormControl>
              </Grid>
              {values.revenueModel === 'Pro Services' && (
                <Grid item xs={12} md={12}>
                  <FormControl disabled={isDemoCompany} sx={{ width: 325 }} fullWidth>
                    <InputLabel
                      style={{ color: '#a9a9a9', fontWeight: '400', fontSize: '14px' }}
                      id="revenue-computation-type-select-label"
                    >
                      Revenue Computation Type
                    </InputLabel>
                    <Select
                      className={classes.dropdownStyle}
                      labelId="revenue-computation-type-select-label"
                      label="Revenue Computation Type"
                      value={values.revenueComputationType}
                      name="revenueComputationType"
                      onBlur={handleBlur}
                      onChange={e => {
                        setValidation(false)
                        setFieldValue('revenueComputationType', e.target.value)
                        // updatePreviewData(values.revenueModel, e.target.value)
                      }}
                    >
                      <MenuItem value="Top Line Billable">Top Line Billable</MenuItem>,
                      <MenuItem value="Detailed Billable + Products">Detailed Billable + Products</MenuItem>
                    </Select>
                    {validation ? <span style={{ color: 'red', fontSize: '12px' }}>Revenue Computation Type is required</span> : null}
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </form>
        )}
      </Formik>
    </Box>
  )
})

RevenueTabView.propTypes = {
  isDemoCompany: PropTypes.any
}

export default RevenueTabView
