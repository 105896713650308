import React, { lazy } from 'react'
import Loadable from 'ui-component/Loadable'
import MinimalLayout from 'layout/MinimalLayout'
import NavMotion from 'layout/NavMotion'
import GuestGuard from 'utils/route-guard/GuestGuard'
import LaunchPage from 'views/pages/authentication/CompanyRegistration/LaunchPage'

const RequestDemoPage = Loadable(lazy(() => import('views/pages/authentication/RequestDemo')))

const RequestDemoPageRoutes = {
  path: 'request-demo',
  element: <MinimalLayout />,
  children: [
    {
      path: '/',
      element: (
        <NavMotion>
          {/* <GuestGuard> */}
          <LaunchPage />
          {/* </GuestGuard> */}
        </NavMotion>
      )
    }
  ]
}

export default RequestDemoPageRoutes
