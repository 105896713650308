import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  OutlinedInput,
  InputAdornment,
  FormControl,
  Grid,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputLabel,
  Select,
  MenuItem,
  Card,
  Divider,
  LinearProgress,
  Typography,
  DialogTitle,
  IconButton
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import { makeStyles, useTheme } from '@material-ui/styles'
import CloseIcon from '@material-ui/icons/Close'

import { useDispatch, useSelector } from 'react-redux'
import { toggleInsightsInitialModelVisible, uiSelector } from 'store/ui/ui.slice'
import { gridSpacing } from 'store/constant'
import DashboardBarChart from 'ui-component/chart/DashboardBarChart'
import { LineChartData2 } from 'data/LineChartData'
import { StyledTableRow, getWindowHeight, numberFormat } from 'utils/ui'
import { modelsSelector } from 'store/models/models.slice'
import Chart from 'react-apexcharts'
import InsightsLineChart from 'ui-component/chart/InsightsLineChart'

const useStyles = makeStyles(theme => ({
  muiDialog: {
    '& .MuiDialog-paper': {
      minHeight: '700px',
      width: '1100px',
      top: '75',
      left: '372',
      padding: 0,
      border: ' #707070 solid 1px '
    }
  },
  muiDialogContent: {
    [theme.breakpoints.up('sm')]: {
      width: '100%'
    },
    background: '#F0F0F0'
  },
  gridFillHeight: {
    [theme.breakpoints.up('sm')]: {
      height: '100%'
    }
  },
  closeButton: {
    color: '#091A36',
    marginRight: '17px',
    marginTop: '12px',
    marginBottom: '0px',
    float: 'right',
    fontSize: '40px',
    cursor: 'pointer'
  },
  modalHeading: {
    fontWeight: '600',
    fontSize: '28px',
    color: '#091A36',
    textAlign: 'center',
    marginBottom: '25px'
  }
}))

const InsightsPopup = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const theme = useTheme()
  const { insightsInitialModelVisible } = useSelector(uiSelector)
  const { getModelSummaryDataError, summary, isLoading } = useSelector(modelsSelector)
  const [tableDividerCells, setTableDividerCells] = useState([])
  const [selectedSimulationView, setSelectedSimulationView] = useState(1)
  const { netIncome, grossMargin, grossProfit, cashOnHand, runway, revenue, expenses } = useSelector(state => state.dashboard)
  const [datas, setData] = useState([10, 20, 30, 42, 51, 82, 31, 59, 61, 73, 91, 58])
  const [chartData, setChartData] = useState({
    series: [
      {
        name: 'Net Income',
        data: [9, 10, 11, 10, 7, 8, 14]
      },
      {
        name: 'Total Revenue',
        data: [7, 8, 8, 8, 9, 8, 13]
      },
      {
        name: 'Gross Profit',
        data: [5, 6, 7, 7, 8, 8, 12]
      },
      {
        name: 'Net Profit',
        data: [3, 2, 4, 4, 5, 8, 7, 10]
      }
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          show: false
        }
      },
      colors: ['#77B6EA', '#31d609', '#e30b0b', 'grey'],
      dataLabels: {
        enabled: true
      },
      stroke: {
        curve: 'smooth'
      },
      title: {
        text: 'AI Powered insights based on your Historic Data',
        align: 'left'
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      markers: {
        size: 1
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']
      },
      yaxis: {
        min: 5,
        max: 20
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5
      }
    }
  })

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={insightsInitialModelVisible}
      aria-labelledby="max-width-dialog-title"
      sx={{ py: 0 }}
      className={classes.muiDialog}
    >
      <DialogTitle id="max-width-dialog-title">
        <IconButton
          aria-label="close"
          onClick={() => {
            dispatch(toggleInsightsInitialModelVisible())
          }}
          sx={{
            position: 'absolute',
            right: 5,
            top: 5,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ py: 0 }}>
        <Grid container sx={{ pt: 3 }}>
          <Grid item xs={12} sx={{ height: 300 }}>
            <Chart {...chartData} series={chartData.series} height="100%" width="100%" />
          </Grid>

          <Box>
            {isLoading && <LinearProgress color="primary" />}
            <Divider sx={{ mt: 1, mb: -2 }} />
            <TableContainer component={Paper}>
              <Grid sx={{ display: 'flex' }}>
                <Grid
                  sx={{
                    background: 'grey',
                    color: 'white',
                    width: '30em',
                    height: '27px',
                    textAlignLast: 'center',
                    marginLeft: '142px',
                    marginTop: '33px',
                    padding: '5px'
                  }}
                >
                  <Typography>Historic</Typography>
                </Grid>
                <Grid
                  sx={{
                    background: 'grey',
                    color: 'white',
                    width: '30em',
                    height: '27px',
                    textAlignLast: 'center',
                    marginLeft: '25px',
                    marginTop: '33px',
                    padding: '5px'
                  }}
                >
                  <Typography>Predicted</Typography>
                </Grid>
              </Grid>
              <Table sx={{ minWidth: 650 }} aria-label="summary table" stickyHeader>
                <TableHead>
                  {summary.subHeaders.length > 0 && (
                    <TableRow>
                      <TableCell />
                      {summary.subHeaders.map((cell, i) => (
                        <TableCell align="center" key={`summarySubHeader-${i}`}>
                          {cell}
                        </TableCell>
                      ))}
                    </TableRow>
                  )}
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <TableCell sx={{ borderBottom: 'none' }}>
                      <Typography variant="h5">Net Income</Typography>
                    </TableCell>
                    {summary.revenue.map((cell, i) => (
                      <TableCell sx={{ padding: '0px', borderBottom: 'none' }} align="center" key={`summaryRevenueCell-${i}`}>
                        {numberFormat(Math.round(cell), selectedSimulationView)}
                      </TableCell>
                    ))}
                  </StyledTableRow>
                  <StyledTableRow>
                    <TableCell sx={{ borderBottom: 'none' }}>
                      <Typography variant="h5" sx={{ fontWeight: '700 !important' }}>
                        Total Revenue
                      </Typography>
                    </TableCell>
                    {summary.expense.map((cell, i) => (
                      <TableCell sx={{ padding: '0px', borderBottom: 'none' }} align="center" key={`summaryExpensesCell-${i}`}>
                        {numberFormat(Math.round(cell), selectedSimulationView)}
                      </TableCell>
                    ))}
                  </StyledTableRow>

                  <StyledTableRow>
                    <TableCell sx={{ borderBottom: 'none' }}>
                      <Typography variant="h5">Gross Profit</Typography>
                    </TableCell>
                    {summary.expense.map((cell, i) => (
                      <TableCell sx={{ padding: '0px', borderBottom: 'none' }} align="center" key={`summaryExpensesCell-${i}`}>
                        {numberFormat(Math.round(cell), selectedSimulationView)}
                      </TableCell>
                    ))}
                  </StyledTableRow>
                  <StyledTableRow>
                    <TableCell sx={{ borderBottom: 'none' }}>
                      <Typography variant="h5" sx={{ color: theme.palette.primary.main, fontWeight: '700 !important' }}>
                        Net Profit
                      </Typography>
                    </TableCell>
                    {summary.netIncome.map((cell, i) => (
                      <TableCell sx={{ padding: '0px', borderBottom: 'none' }} align="center" key={`summaryEBITDACell-${i}`}>
                        {numberFormat(Math.round(cell), selectedSimulationView)}
                      </TableCell>
                    ))}
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default InsightsPopup
