import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import styles from '../../styles.module.css'
import PropTypes from 'prop-types'
import {
  Grid,
  Button,
  Box,
  TextField,
  Fab,
  Divider,
  Card,
  CardHeader,
  Typography,
  IconButton,
  FormHelperText,
  CardContent,
  CardMedia
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import UpdateIcon from '@material-ui/icons/Save'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import * as Yup from 'yup'
import { Formik } from 'formik'
import moment from 'moment'
import AdapterDateFns from '@material-ui/lab/AdapterMoment'
import LocalizationProvider from '@material-ui/lab/LocalizationProvider'

import { gridSpacing } from 'store/constant'
import ModalSubtitle from './ModalSubtitle'

import {
  uiSelector,
  setCompanySetupModalScreen,
  toggleCompanySetupModal,
  setExcelImportTabModel,
  toggleExcelImportTabModel
} from 'store/ui/ui.slice'

import { finishCompanyCreate, getCompaniesByUser, updateCompany } from 'store/company/company.actions'
import { clearState, resetState, companySelector, addProduct, deleteProduct, updateProduct } from 'store/company/company.slice'
import { SNACKBAR_OPEN } from 'store/actions'
import { DatePicker, LoadingButton, MobileDatePicker } from '@material-ui/lab'
import NumberFormat from 'react-number-format'
import { useNavigate } from 'react-router'
import PopupNotify from 'ui-component/tab/Model/PopupNotify'

import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined'
import FieldsFilled from 'utils/fieldsFilled'
import { makeStyles } from '@material-ui/styles'

import excel from 'assets/images/excel.png'
import excelWithA from 'assets/images/excelWithA.png'
import { authSelector } from 'store/auth/auth.slice'
import UploadIcon from '../../assets/images/icons/UploadIcon.png'

import cssClasses from './infoTab.module.css'
import ExcelImporTabModel from './excelImportTab'

const useStyles = makeStyles(theme => ({
  upgradeBtn: {
    width: 96,
    height: 50,
    fontFamily: 'Verdana',
    color: '#F7F7F8',
    fontSize: '12',
    background: '#091A36',
    borderRadius: '8px',
    marginRight: '12px',
    paddingRight: '18px',
    paddingLeft: '18px',
    '&:hover': {
      background: '#14284a',
      color: 'white'
    }
  },
  dropdownStyle: {
    borderRadius: '50px !important',
    '& .MuiOutlinedInput-input': {
      borderRadius: '50px !important'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '50px !important'
    }
  },
  excel: {
    width: '53px',
    height: '31px',
    objectFit: 'contain',
    // marginTop: -25,
    borderRadius: 30,
    cursor: 'pointer'
    // '&:hover': {
    //   background: '#c6e9f5'
    // }
  }
}))

const DetailedBillableProductsTabView = props => {
  const {
    selectedRevenueComputationalType,
    isEditButtonClicked,
    editItem,
    setEditItem,
    setdrawerAddButtonClicked,
    isDemoCompany,
    editProdItem,
    setEditProdItem,
    isdrawerAddButtonClicked
  } = props

  const classes = useStyles()
  // const { title, caption, qbIcon, windowHeight, isDemoCompany } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const formRef = useRef(null)
  const { companySetupModalScreen } = useSelector(uiSelector)
  const { user } = useSelector(authSelector)
  const { isLoading, isSuccess, isSuccessCompanyCreate, updateCompanyError, id, productList, selectedCompany, revenueComputationType } =
    useSelector(companySelector)
  const [isNext, setNext] = useState(false)
  const [notify, setNotify] = useState(false)
  const [visible, setVisible] = useState(10)
  const [loadMoreBtn, setLoadMoreBtn] = useState(false)
  // const [editItem, setEditItem] = useState('')
  const [editButtonClicked, setEditButtonClicked] = useState(false)
  const [selectedCard, setSelectedCard] = useState('')
  const [mouseEnterOnCard, setMouseEnterOnCard] = useState('')

  // useImperativeHandle(ref, () => ({
  //   handleNext() {
  //     if (productList.length === 0 || (formRef.current.values.name && productList.length > 0)) {
  //       if (formRef.current.isValid) {
  //         setNotify(true)
  //         setNext(true)
  //       } else {
  //         dispatch(clearState())
  //         if (formRef.current) formRef.current.handleSubmit()
  //         setNext(true)
  //       }
  //     } else {
  //       setNext(false)
  //       dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
  //       dispatch(clearState())
  //     }
  //   }
  // }))

  const formikInitialValues = {
    _id: '',
    name: '',
    unitCOGS: '',
    price: '',
    submit: null
  }
  const [initialValues, setInitialValues] = useState(formikInitialValues)
  const [openStartDatePicker, setOpenStartDatePicker] = useState(false)

  const handleAddProduct = (name, unitCOGS, price) => {
    const payload = {
      name,
      unitCOGS,
      price
    }
    const logArray = []
    // const productListItem = []
    // productListItem.push(payload)
    // setAddedItem(productListItem)

    const prodChangeLog = {
      user: user._id,
      userName: user.name,
      company: selectedCompany,
      date: new Date(),
      tabChanged: 'Revenue/ Pro Services/ Detailed Billable Product',
      department: 'N/A',
      lineItemChanged: `${payload.name} (New Product added)`,
      originalValue: 'N/A',
      newValue: 'N/A'
    }
    logArray.push(prodChangeLog)

    const productPayload = [...productList]
    productPayload.push(payload)
    if (id !== '') {
      const data = {
        id,
        productList: productPayload,
        systemLog: logArray
      }
      dispatch(updateCompany(data))
    }
    setInitialValues(formikInitialValues)
  }

  useEffect(() => {
    if (isEditButtonClicked) {
      setInitialValues({
        ...editProdItem,
        submit: null
      })
    }
  }, [isEditButtonClicked])

  // const editProduct = item => {
  //   setEditItem(item._id)
  //   setInitialValues({
  //     ...item,
  //     submit: null
  //   })
  // }

  const handleDeleteProduct = _id => {
    if (editItem === _id) return
    // if (formRef.current.values._id) {
    //   setNotify(true)
    //   return
    // }

    const selectedProd = [...productList].filter(item => item._id === _id)

    const payload = {
      _id: selectedProd[0]._id,
      name: selectedProd[0].name
    }
    const productPayload = [...productList].filter(item => item._id !== _id)
    const logArray = []

    const productChangeLog = {
      user: user._id,
      userName: user.name,
      company: selectedCompany,
      date: new Date(),
      tabChanged: 'Revenue/ Pro Services/ Detailed Billable Product',
      department: 'N/A',
      lineItemChanged: `${payload.name} (Product deleted)`,
      originalValue: 'N/A',
      newValue: 'N/A'
    }
    logArray.push(productChangeLog)

    if (id !== '') {
      const data = {
        id,
        productList: productPayload,
        systemLog: logArray
      }
      dispatch(updateCompany(data))
    }
  }

  const handleUpdateProduct = (_id, name, unitCOGS, price) => {
    setEditItem('')
    const payload = {
      _id,
      name,
      unitCOGS,
      price
    }
    const productPayload = [...productList]
    const index = productPayload.findIndex(item => item._id === payload._id)

    //  system logs
    const logArray = []

    if (productPayload[index].name !== payload.name) {
      const vendorChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Detailed Billable Product',
        department: 'N/A',
        lineItemChanged: `${productPayload[index].name} (Name changed)`,
        originalValue: productPayload[index].name,
        newValue: payload.name
      }
      logArray.push(vendorChangeLog)
    }

    if (productPayload[index].cor !== payload.cor) {
      const vendorChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Detailed Billable Product',
        department: 'N/A',
        lineItemChanged: `${productPayload[index].name} (COR changed)`,
        originalValue: productPayload[index].cor,
        newValue: payload.cor
      }
      logArray.push(vendorChangeLog)
    }

    if (productPayload[index].price !== payload.price) {
      const vendorChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Detailed Billable Product',
        department: 'N/A',
        lineItemChanged: `${productPayload[index].name} (Price changed)`,
        originalValue: productPayload[index].price,
        newValue: payload.price
      }
      logArray.push(vendorChangeLog)
    }

    if (productPayload[index].unitCOGS !== payload.unitCOGS) {
      const vendorChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Detailed Billable Product',
        department: 'N/A',
        lineItemChanged: `${productPayload[index].name} (UnitCOGS changed)`,
        originalValue: productPayload[index].unitCOGS,
        newValue: payload.unitCOGS
      }
      logArray.push(vendorChangeLog)
    }
    productPayload[index] = payload
    if (id !== '') {
      const data = {
        id,
        productList: productPayload,
        systemLog: logArray
      }
      dispatch(updateCompany(data))
    }
    setInitialValues(formikInitialValues)
  }
  useEffect(() => {
    if (productList.length > 10) {
      setLoadMoreBtn(false)
    }
  }, [productList])

  const handleLoadMore = () => {
    setVisible(visible + 10)
  }
  useEffect(() => {
    if (updateCompanyError !== '') {
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: updateCompanyError,
        variant: 'alert',
        alertSeverity: 'error'
      })
    }

    return dispatch(clearState())
  }, [dispatch, updateCompanyError])

  useEffect(() => {
    if (isSuccess && isNext) {
      dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
      dispatch(clearState())
    }
    return () => {
      dispatch(clearState())
    }
  }, [isNext, isSuccess])

  // useEffect(() => {
  //   if (isSuccess && isNext) {
  //     dispatch(clearState())
  //     const data = {
  //       companyId: id
  //     }
  //     dispatch(finishCompanyCreate(data))
  //   }
  // }, [isNext, isSuccess])

  // useEffect(() => {
  //   if (isSuccessCompanyCreate) {
  //     dispatch(toggleCompanySetupModal())
  //     dispatch(setCompanySetupModalScreen(0))
  //     dispatch(resetState())
  //     dispatch(getCompaniesByUser())
  //     navigate(`models?cid=${selectedCompany}`)
  //   }
  // }, [isSuccessCompanyCreate])

  const handleChangeNumberCog = e => {
    const value = e.target.value.replace(/\D/g, '')
    formRef.current.setFieldValue('unitCOGS', value.replace(/\B(?=(\d{3})+(?!\d))/g, ','))
  }

  const handleChangeNumberPrice = e => {
    const value = e.target.value.replace(/\D/g, '')
    formRef.current.setFieldValue('price', value.replace(/\B(?=(\d{3})+(?!\d))/g, ','))
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        <p style={{ color: '#207E4D' }} className={cssClasses.drawerHeadingSecond}>
          My product
        </p>
        <ExcelImporTabModel excelImportTabNo={7} />
      </div>
      <Formik
        validateOnMount
        innerRef={formRef}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Name is required').max(30, 'Maximum 30 characters allowed'),
          unitCOGS: Yup.string().required('Cost (CoGS) is required').max(30, 'Maximum 30 characters allowed'),
          price: Yup.string().required('Retail price is required').max(30, 'Maximum 30 characters allowed')
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
          if (isDemoCompany) {
            dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
            return
          }
          if (values._id) {
            handleUpdateProduct(
              values._id,
              values.name,
              values.unitCOGS.toString().includes(',') ? parseInt(values.unitCOGS.replace(/,/g, ''), 10) : values.unitCOGS,
              values.price.toString().includes(',') ? parseInt(values.price.replace(/,/g, ''), 10) : values.price
            )
          } else {
            handleAddProduct(
              values.name,
              values.unitCOGS.toString().includes(',') ? parseInt(values.unitCOGS.replace(/,/g, ''), 10) : values.unitCOGS,
              values.price.toString().includes(',') ? parseInt(values.price.replace(/,/g, ''), 10) : values.price
            )
          }

          const data = {
            id,
            revenueComputationType: selectedRevenueComputationalType
          }
          if (selectedRevenueComputationalType !== revenueComputationType) {
            // data.productList = []
            data.clientList = []
          }
          dispatch(updateCompany(data))

          setStatus({ success: true })
          setSubmitting(false)
          resetForm(initialValues)
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, isValid, setFieldTouched }) => (
          <form style={{ width: '100%', marginTop: '48px' }} noValidate onSubmit={handleSubmit}>
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column' }}>
              <label htmlFor="product_name" className={cssClasses.inputLabel}>
                Product name <span className={cssClasses.important}>*</span>{' '}
              </label>

              <input
                placeholder="Product name"
                className={cssClasses.wizardInputBox}
                value={values.name}
                name="name"
                onBlur={handleBlur}
                onChange={e => {
                  handleChange(e)
                  if (values.name) setFieldTouched('name', true)
                }}
                id="product_name"
              />

              {touched.name && errors.name && <FormHelperText error> {errors.name} </FormHelperText>}
            </div>

            <div
              style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', marginTop: '20px' }}
            >
              <label htmlFor="unitCOGS" className={cssClasses.inputLabel}>
                Cost (CoGS)<span className={cssClasses.important}>*</span>{' '}
              </label>

              <div className={cssClasses.containerInputs}>
                <MonetizationOnOutlinedIcon />
                <NumberFormat
                  className={cssClasses.transparentInputs}
                  placeholder="Cost (CoGS)"
                  value={values.unitCOGS}
                  name="unitCOGS"
                  onBlur={handleBlur}
                  onChange={e => {
                    handleChange(e)
                    if (values.unitCOGS) setFieldTouched('unitCOGS', true)
                  }}
                  id="unitCOGS"
                  thousandSeparator=","
                />
              </div>

              {touched.unitCOGS && errors.unitCOGS && <FormHelperText error> {errors.unitCOGS} </FormHelperText>}
            </div>

            <div
              style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', marginTop: '20px' }}
            >
              <label htmlFor="retail_price" className={cssClasses.inputLabel}>
                Retail price<span className={cssClasses.important}>*</span>{' '}
              </label>

              <div className={cssClasses.containerInputs}>
                <MonetizationOnOutlinedIcon />
                <NumberFormat
                  className={cssClasses.transparentInputs}
                  placeholder="Retail price"
                  value={values.price}
                  name="price"
                  onBlur={handleBlur}
                  onChange={e => {
                    handleChange(e)
                    if (values.price) setFieldTouched('price', true)
                  }}
                  id="retail_price"
                  thousandSeparator=","
                />
              </div>

              {touched.price && errors.price && <FormHelperText error> {errors.price} </FormHelperText>}
            </div>

            <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button className={isValid ? cssClasses.wizardAddButton : cssClasses.wizardAddButtonDisabled} type="submit">
                Add
              </button>
            </div>
            <Divider sx={{ marginTop: '230px', marginBottom: '10px' }} />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button className={cssClasses.wizardCancelButton} type="button">
                Cancel
              </button>
              <button
                // disabled={!isdrawerAddButtonClicked}
                onClick={() => setdrawerAddButtonClicked(true)}
                className={cssClasses.wizardSaveButton}
                type="button"
              >
                Save
              </button>
            </div>

            {/* <Button
                            className={classes.upgradeBtn}
                            disabled={isSubmitting}
                            onClick={() => {
                              setNext(false)
                              setEditButtonClicked(false)
                              if (formRef.current) formRef.current.handleSubmit()
                            }}
                            size="medium"
                            color="secondary"
                            aria-label="add"
                          >
                            <Typography>{editButtonClicked ? 'Save' : 'Add'}</Typography>
                            {FieldsFilled(values) ? <UpdateIcon /> : <AddIcon />}
                          </Button> */}
          </form>
        )}
      </Formik>
    </>
  )
}

// DetailedBillableProductsTabView.propTypes = {
//   title: PropTypes.any,
//   caption: PropTypes.any,
//   qbIcon: PropTypes.any,
//   windowHeight: PropTypes.any
// }

export default DetailedBillableProductsTabView
