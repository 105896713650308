import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'

import { useSelector } from 'react-redux'

// material-ui
import { makeStyles } from '@material-ui/styles'
import {
  Avatar,
  Box,
  ButtonBase,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Grid
} from '@material-ui/core'
import { authSelector } from 'store/auth/auth.slice'

// project imports
import LogoSection from '../LogoSection'
import SearchSection from './SearchSection'
// import LocalizationSection from './LocalizationSection'
import MobileSection from './MobileSection'
import ProfileSection from './ProfileSection'
import NotificationSection from './NotificationSection'
import StartCompanySetup from '../Sidebar/StartCompanySetup'
import SelectCompanyDropdown from './SelectCompanyDropdown'
import CompanySetupCheckList from './CompanySetupCheckList'

// assets
import { IconMenu2 } from '@tabler/icons'
import { companySelector } from 'store/company/company.slice'
import { uiSelector } from 'store/ui/ui.slice'
import moment from 'moment'
import User1 from 'assets/images/users/avatar-placeholder.png'
import mobileUsers from 'assets/images/mobileUsers.png'
import { Today } from '@material-ui/icons'

// style constant
const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1
  },
  headerAvatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    transition: 'all .2s ease-in-out',
    background: theme.palette.mode === 'light' ? theme.palette.dark.main : theme.palette.secondary.light,
    color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.light,
    // background: '#FFFDF9',
    '&:hover': {
      background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
      color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
    }
  },
  boxContainer: {
    width: '175px',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  },
  companySelector: {
    width: '228px',
    marginLeft: '60px',
    [theme.breakpoints.down('sm')]: { display: 'none' }
  },
  companySelectorWImage: {
    width: '228px',
    marginLeft: '20px',
    [theme.breakpoints.down('sm')]: { display: 'none' }
  },
  headerHeadings: {
    color: 'var(--neutrals-cool-1000, #0C0D0F)',
    fontFamily: 'Raleway',
    fontSize: '40px',
    fontWeight: 600,
    [theme.breakpoints.down('lg')]: {
      fontSize: '22px'
    }
  },
  headerDesc: {
    color: 'var(--neutrals-cool-600, #3B424C)',
    fontFamily: 'Raleway',
    fontSize: '20px',
    fontWeight: 500,
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px'
    }
  },
  headerDate: {
    color: 'var(--neutrals-cool-500, #626870)',
    fontFamily: 'Raleway',
    fontSize: '12px',
    fontWeight: 500
  },
  profileImage: {
    borderRadius: '50%',
    width: '48px',
    height: '48px',
    marginRight: '10px',
    objectFit: 'cover'
  },
  profileName: {
    color: 'var(--neutrals-cool-1000, #0C0D0F)',
    fontFamily: 'Raleway',
    fontSize: '14px',
    fontWeight: 500
  },
  profileTitle: {
    color: 'var(--neutrals-cool-1000, #0C0D0F)',
    fontFamily: 'Helvetica',
    fontSize: '12px',
    fontWeight: 500,
    marginTop: '-10px'
  },
  headerTextCont: {
    [theme.breakpoints.down('lg')]: {
      marginLeft: '50px',
      marginRight: '50px'
    }
  }
}))

const MobileWarningModal = ({ open, handleClose }) => (
  <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
    <DialogContent>
      <img src={mobileUsers} alt="Notification" style={{ width: '100%', height: 'auto' }} />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary" autoFocus>
        Dismiss
      </Button>
    </DialogActions>
  </Dialog>
)

// ===========================|| MAIN NAVBAR / HEADER ||=========================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const classes = useStyles()
  const anchorRef = React.useRef(null)
  const [isMobile, setIsMobile] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    const { userAgent } = navigator
    const mobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(userAgent)
    setIsMobile(mobileDevice)

    if (mobileDevice) {
      setModalOpen(true)
    }
  }, [])

  const handleClose = () => {
    setModalOpen(false)
  }

  const { user, logoutError, isGuestUser } = useSelector(authSelector)
  const { avatarFile } = user

  const { isEarlyBird, preSelectedPlan, creationDate, companyLogoUrl, meTabInputs, companies } = useSelector(companySelector)
  const { paymentSetupModelVisible } = useSelector(uiSelector)

  const dayAfMonth = moment(creationDate).add(30, 'days').calendar()

  const dateDiff = moment(dayAfMonth).diff(moment(), 'days')

  const getCompanyLogoUrl = () => {
    let isCompanyLogoUrl
    if (companyLogoUrl === null) {
      isCompanyLogoUrl = false
    } else if (companyLogoUrl === undefined) {
      isCompanyLogoUrl = false
    } else {
      isCompanyLogoUrl = true
    }
    return isCompanyLogoUrl
  }

  const options = { year: 'numeric', month: 'long', day: 'numeric' }

  const getCurrentDate = () => new Date().toLocaleDateString('en-US', options)

  return (
    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <MobileWarningModal open={modalOpen} handleClose={handleClose} />
      <Grid item xs={6} className={classes.headerTextCont}>
        <Typography className={classes.headerHeadings}>Hello, {user?.name?.split(' ')[0]}.</Typography>
        <Typography className={classes.headerDesc}>What business need can we help you optimize for today?</Typography>
        <Typography className={classes.headerDate}>{getCurrentDate()}</Typography>
      </Grid>
      {/* logo & toggler button */}
      {/* <div className={classes.boxContainer}> */}
      {/* <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Tooltip arrow title="Main menu">
            <Avatar variant="rounded" className={classes.headerAvatar} onClick={handleLeftDrawerToggle} color="inherit" sx={{ mt: 1 }}>
              <IconMenu2 stroke={3.5} size="1.5rem" sx={{ background: 'none' }} />
            </Avatar>
          </Tooltip>
        </ButtonBase> */}

      {/* <Box component="span" sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1, justifyContent: 'center' }}>
          <LogoSection />
        </Box> */}
      {/* </div> */}
      {/* <LogoSection /> */}
      {/* {!isGuestUser && <SelectCompanyDropdown />} */}
      {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {getCompanyLogoUrl() && (
          <Box
            sx={{
              marginLeft: '60px',
              // height: '50px',
              // width: '120px',
              // backgroundColor: '#ffffff',
              // borderRadius: '15px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              style={{ marginTop: '1px', height: '45px', width: '45px', borderRadius: '50px' }}
              src={companyLogoUrl}
              alt="company_logo"
            />
          </Box>
        )}
        <Box className={getCompanyLogoUrl() ? classes.companySelectorWImage : classes.companySelector}>
          {!isGuestUser && <SelectCompanyDropdown />}
        </Box>
      </div> */}

      {/* header search */}
      {/* <div className={classes.grow} /> */}
      {/* <SearchSection theme="light" /> */}

      {/* live customization & localization */}
      {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <LocalizationSection />
      </Box> */}

      {/* notification & profile */}
      <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', position: 'relative', right: 50 }}>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            borderRight: '1px solid #CFD0D9',
            marginRight: '20px',
            height: '50px',
            paddingRight: '20px'
          }}
        >
          {!isGuestUser && <NotificationSection />}
          <ProfileSection />
        </Grid>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            borderRight: '1px solid #CFD0D9',
            justifyContent: 'flex-start'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', paddingRight: '20px', height: '50px' }}>
            <Avatar
              src={avatarFile || User1}
              className={classes.profileImage}
              // ref={anchorRef}
              // aria-controls={open ? 'menu-list-grow' : undefined}
              // aria-haspopup="true"
              // comment (just for commit)
              color="inherit"
            />
            <div>
              <p className={classes.profileName}>{user.name}</p>
              <p className={classes.profileTitle}>{user.title}</p>
            </div>
          </div>
        </Grid>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginLeft: '20px'
          }}
        >
          {!isGuestUser && <SelectCompanyDropdown />}
        </Grid>
      </Grid>
      {/* <Box>
        <CompanySetupCheckList />
      </Box> */}
      {/* mobile header */}
      <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        <MobileSection />
      </Box>
    </Grid>
  )
}

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
}

export default Header
