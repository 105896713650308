import React, { useRef, useState, useEffect } from 'react'

import logoDark from 'assets/images/mrgin-blue-logo.png'
import cloudRocket from 'assets/images/cloudRocket.png'
import Calender from '@material-ui/icons/CalendarTodayOutlined'
import ClockIcon from '@material-ui/icons/AccessTimeOutlined'

import cssClasses from './infoTab.module.css'
import { Divider } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { authSelector } from 'store/auth/auth.slice'

const ConfirmCallPage = props => {
  const { setCurrentTabNumber, selectedDate, selectedTime, setTalkToAnExpertClicked } = props

  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }

  const convertedDate = new Date(selectedDate).toLocaleDateString('en-Us', options)

  const { user } = useSelector(authSelector)

  return (
    <div
      style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '30px' }}
    >
      <img src={cloudRocket} alt="confimed_img" style={{ height: '115px' }} />
      <div style={{ width: '100%', marginTop: '20px', paddingRight: '11px' }}>
        <h1 className={cssClasses.drawerHeading}>Talk to an expert confirmed!</h1>
        <p className={cssClasses.drawerDesc}>
          We wanted to confirm that the Escalon team has been notified of your request and is dedicated to providing you with comprehensive
          support for all aspects of your business needs.
        </p>
        <p className={cssClasses.drawerDesc}>
          Rest assured that our team is already hard at work to address your inquiry promptly. You can expect to hear back from us within
          the next 24 hours
        </p>
        <p className={cssClasses.drawerDesc}>
          We are here to support your business's success and look forward to helping you achieve your goals.
        </p>
      </div>
      {/* <div style={{ width: '100%' }}>
        <p className={cssClasses.confirmPageHeadings}>Demo info</p>
        <Divider />
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Calender sx={{ marginRight: '5px', fill: '#333333' }} />
            <p className={cssClasses.demoInfoText}>Date</p>
          </div>
          <p className={cssClasses.demoInfoTextp}>{convertedDate}</p>
        </div>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ClockIcon style={{ marginRight: '5px', fill: '#333333' }} />
            <p className={cssClasses.demoInfoText}>Time</p>
          </div>
          <p className={cssClasses.demoInfoTextp}>{selectedTime}</p>
        </div>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ClockIcon style={{ marginRight: '5px', fill: '#333333' }} />
            <p className={cssClasses.demoInfoText}>Duration</p>
          </div>
          <p className={cssClasses.demoInfoTextp}>60 Minute meeting</p>
        </div>
      </div>

      <div style={{ width: '100%' }}>
        <p className={cssClasses.confirmPageHeadings}>Customer Info</p>
        <Divider />
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <p className={cssClasses.demoInfoText}>First Name</p>
          <p className={cssClasses.demoInfoTextp}>{user.name.split(' ')[0]}</p>
        </div>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <p className={cssClasses.demoInfoText}>Last Name</p>
          <p className={cssClasses.demoInfoTextp}>{user.name.split(' ')[1]}</p>
        </div>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <p className={cssClasses.demoInfoText}>Email</p>
          <p className={cssClasses.demoInfoTextp}>{user.email}</p>
        </div>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <p className={cssClasses.demoInfoText}>Phone</p>
          <p className={cssClasses.demoInfoTextp}>+1-201-867-5309</p>
        </div>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <p className={cssClasses.demoInfoText}>Company Name</p>
          <p className={cssClasses.demoInfoTextp}>Stark Industries</p>
        </div>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <p className={cssClasses.demoInfoText}>Role</p>
          <p className={cssClasses.demoInfoTextp}>CFO</p>
        </div>
      </div> */}
      <Divider sx={{ width: '100%', marginTop: '148px', marginBottom: '10px' }} />
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <button
          onClick={() => {
            setTalkToAnExpertClicked(false)
            setCurrentTabNumber(0)
          }}
          type="button"
          className={cssClasses.wizardSaveButton}
        >
          Close
        </button>
      </div>
    </div>
  )
}

export default ConfirmCallPage
