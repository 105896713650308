import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// project imports
import useAuth from 'hooks/useAuth'
import { authSelector } from 'store/auth/auth.slice'
import { useQuery } from 'utils/ui'
import { hardResetState as resetCompanyState } from 'store/company/company.slice'
import { resetState as resetDashboardState } from 'store/dashboard/dashboard.slice'
import { guestUserLogin } from 'store/auth/auth.actions'

// ===========================|| AUTH GUARD ||=========================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
  const { isLoggedIn } = useSelector(authSelector)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [called, setcalled] = useState(0)

  useEffect(() => {
    if (window.location.hostname === 'demo.mrgn.ai' && !isLoggedIn) {
      navigate('/demo', { replace: true })
    } else if (!isLoggedIn) {
      navigate('/guest', { replace: true })
    }
  }, [called, dispatch, isLoggedIn, navigate])

  return children
}

AuthGuard.propTypes = {
  children: PropTypes.node
}

export default AuthGuard
