import React from 'react'
import { IconDashboard, IconBorderAll, Icon3dCubeSphere, IconStack, IconHelp, IconBuildingStore } from '@tabler/icons'
import { Grid, Typography } from '@material-ui/core'
import {
  DashBoardMenuIcon,
  ModelsMenuIcon,
  SimulationsMenuIcon,
  TasksMenuIcon,
  CompanyMenuIcon,
  HelpMenuIcon,
  DashBoardMenuIconSelect,
  ModelsMenuIconSelect,
  SimulationsMenuIconSelect,
  TasksMenuIconSelect,
  CompanyMenuIconSelect
} from './Icons'
// ===========================|| MAIN NAVIGATION ITEMS ||=========================== //

const other = {
  id: 'main-navigation',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard',
      icon: DashBoardMenuIcon,
      iconSelect: DashBoardMenuIconSelect,
      breadcrumbs: false
    },
    {
      id: 'models',
      title: 'Budget',
      type: 'item',
      url: '/budget',
      icon: ModelsMenuIcon,
      iconSelect: ModelsMenuIconSelect,
      breadcrumbs: false
    },
    {
      id: 'simulations',
      type: 'collapse',
      url: '/simulations',
      icon: SimulationsMenuIcon,
      iconSelect: SimulationsMenuIconSelect,
      breadcrumbs: false,
      children: [
        {
          id: 'simulation-low-mid-high',
          title: (
            <ul style={{ flexDirection: 'column', paddingLeft: 0 }}>
              <Grid container>
                <Grid sx={{ listStyleType: 'circle' }}>Low</Grid>
                <Grid sx={{ listStyleType: 'circle' }}>
                  <li style={{ listStyleType: 'circle', marginLeft: 22 }}>Mid</li>
                </Grid>
                <Grid sx={{ listStyleType: 'circle' }}>
                  <li style={{ listStyleType: 'circle', marginLeft: 22 }}>High</li>
                </Grid>
              </Grid>
            </ul>
          ),
          type: 'item',
          url: '/simulations/low-mid-high',
          breadcrumbs: false
        },
        {
          id: 'simulation-debt-financing',
          title: 'Debt Financing',
          type: 'item',
          url: '/simulations/debt-financing',
          breadcrumbs: false
        },
        {
          id: 'simulation-2',
          title: 'Burn Rate ',
          type: 'item',
          url: '/simulations/burn-rate-reduction',
          breadcrumbs: false
          // disabled: true,
          // chip: {
          //   label: <h5 style={{ color: 'white' }}>Soon</h5>
          // }
        }
      ]
    },
    {
      id: 'simulation',
      title: 'Simulations',
      type: 'item',
      url: '/simulation',
      icon: SimulationsMenuIcon,
      iconSelect: SimulationsMenuIconSelect,
      breadcrumbs: false
    },
    {
      id: 'tasks',
      title: 'Tasks',
      type: 'item',
      url: '/tasks',
      icon: TasksMenuIcon,
      iconSelect: TasksMenuIconSelect,
      breadcrumbs: false,
      disabled: true,
      chip: {
        label: <Typography style={{ color: 'gray' }}>Coming Soon</Typography>
      }
    },
    {
      id: 'company',
      title: 'Company',
      type: 'item',
      url: '/company',
      icon: CompanyMenuIcon,
      iconSelect: CompanyMenuIconSelect,
      breadcrumbs: false
    }
    // {
    //   id: 'help',
    //   title: 'Help',
    //   type: 'item',
    //   url: 'https://support.mrgn.ai/',
    //   icon: HelpMenuIcon,
    //   breadcrumbs: false,
    //   external: true,
    //   target: true
    // }
  ]
}

export default other
