import React from 'react'

import cssClasses from '../infoTab.module.css'
import { Button, Divider, Grid, IconButton } from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import dayjs from 'dayjs'

const ProductList = props => {
  const { addedProduct, setAddedProduct, setEditItem, model, setEditButtonClicked, setAddButtonClicked } = props

  return (
    <div style={{ width: '100%' }}>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p className={cssClasses.drawerListHeading}>{model}</p>
        <Button
          onClick={() => {
            setAddedProduct([])
            setEditButtonClicked(false)
          }}
          className={cssClasses.transparentAddButton}
          variant="text"
        >
          {' '}
          <span style={{ marginRight: '10px' }}>+</span>Add
        </Button>
      </div>
      <Divider style={{ marginTop: '10px', color: 'rgba(9, 165, 127, 0.15)' }} />
      <ul style={{ width: '100%', padding: 0, margin: 0, listStyle: 'none' }}>
        {addedProduct?.map((eachItem, index) => (
          <li key={index} style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
            {/* <Grid item xs={12}> */}
            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <h1 className={cssClasses.listCardItemh}>Product</h1>
              <IconButton
                onClick={() => {
                  // setSelectedTeam(selectedTeam)
                  setEditItem(eachItem)
                  setEditButtonClicked(true)
                  setAddedProduct([])
                }}
              >
                <MoreHorizIcon />
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <h1 className={cssClasses.listCardh}>Product name</h1>
              <p className={cssClasses.listCardp}>{eachItem.name}</p>
            </Grid>
            <Grid item xs={6}>
              <h1 className={cssClasses.listCardh}>Cost (CoGS)</h1>
              <p className={cssClasses.listCardp}>{eachItem.unitCOGS}</p>
            </Grid>
            <Grid item xs={6}>
              <h1 className={cssClasses.listCardh}>Retail price</h1>
              <p className={cssClasses.listCardp}>{eachItem.price}</p>
            </Grid>
            <Grid item xs={6}>
              <h1 className={cssClasses.listCardh}>Growth increment</h1>
              <p className={cssClasses.listCardp}>{eachItem.growthIncrement}</p>
            </Grid>
            <Grid item xs={6}>
              <h1 className={cssClasses.listCardh}>Growth percentage</h1>
              <p className={cssClasses.listCardp}>{eachItem.growthRate}</p>
            </Grid>
            <Grid item xs={6}>
              <h1 className={cssClasses.listCardh}>Starting units</h1>
              <p className={cssClasses.listCardp}>{eachItem.startingUnits}</p>
            </Grid>
            <Grid item xs={6}>
              <h1 className={cssClasses.listCardh}>Start date</h1>
              <p className={cssClasses.listCardp}>{dayjs(eachItem.startDate).format('DD.MM.YY')}</p>
            </Grid>
            {/* </Grid> */}
          </li>
        ))}
      </ul>
      <Divider sx={{ marginTop: '230px', marginBottom: '10px' }} />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button
          // onClick={() => handleCancelClick()}
          className={cssClasses.wizardCancelButton}
          type="button"
        >
          Cancel
        </button>
        <button
          onClick={() => {
            setAddedProduct([])
            setAddButtonClicked(false)
          }}
          type="button"
          className={cssClasses.wizardSaveButton}
        >
          Save
        </button>
      </div>
    </div>
  )
}

export default ProductList
