import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import styles from '../../styles.module.css'
import PropTypes from 'prop-types'
import {
  Grid,
  Button,
  Box,
  TextField,
  Fab,
  Divider,
  Card,
  CardHeader,
  CardContent,
  Typography,
  IconButton,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Chip,
  CardMedia
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import UpdateIcon from '@material-ui/icons/Save'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import * as Yup from 'yup'
import { Formik } from 'formik'
import moment from 'moment'
import AdapterDateFns from '@material-ui/lab/AdapterMoment'
import LocalizationProvider from '@material-ui/lab/LocalizationProvider'

import { gridSpacing } from 'store/constant'
import ModalSubtitle from './ModalSubtitle'

import UploadIcon from '../../assets/images/icons/UploadIcon.png'

import cssClasses from './infoTab.module.css'

import {
  uiSelector,
  setCompanySetupModalScreen,
  toggleCompanySetupModal,
  setExcelImportTabModel,
  toggleExcelImportTabModel,
  setSelectedMenuItem
} from 'store/ui/ui.slice'
import { finishCompanyCreate, getCompaniesByUser, getCompanyByID, updateCompany } from 'store/company/company.actions'
import { clearState, resetState, companySelector, setIsWizardVisible } from 'store/company/company.slice'
import { SNACKBAR_OPEN, MENU_OPEN } from 'store/actions'
import { DatePicker, LoadingButton, MobileDatePicker } from '@material-ui/lab'
import NumberFormat from 'react-number-format'
import { useNavigate } from 'react-router'
import PopupNotify from 'ui-component/tab/Model/PopupNotify'
import { numberFormat } from 'utils/ui'
import FieldsFilled from 'utils/fieldsFilled'
import { makeStyles } from '@material-ui/styles'

import excelWithA from 'assets/images/excelWithA.png'

import excel from 'assets/images/excel.png'
import { authSelector } from 'store/auth/auth.slice'
import ExcelImporTabModel from './excelImportTab'

const useStyles = makeStyles(theme => ({
  upgradeBtn: {
    width: 96,
    height: 50,
    fontFamily: 'Verdana',
    color: '#F7F7F8',
    fontSize: '12',
    background: '#091A36',
    borderRadius: '8px',
    marginRight: '12px',
    paddingRight: '18px',
    paddingLeft: '18px',
    '&:hover': {
      background: '#14284a',
      color: 'white'
    }
  },
  dropdownStyle: {
    borderRadius: '8px !important',
    '& .MuiOutlinedInput-input': {
      borderRadius: '8px !important'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px !important'
    }
  },
  excel: {
    width: '53px',
    height: '31px',
    objectFit: 'contain',
    // marginTop: -25,
    borderRadius: 30,
    cursor: 'pointer'
    // '&:hover': {
    //   background: '#c6e9f5'
    // }
  }
}))

const DetailedBillableClientsTabView = forwardRef((props, ref) => {
  const { selectedRevenueComputationalType, isEditButtonClicked, editItem, setEditItem, setAddedItem } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const formRef = useRef(null)
  const { companySetupModalScreen } = useSelector(uiSelector)
  const { user } = useSelector(authSelector)
  const {
    isLoading,
    isSuccess,
    isSuccessCompanyCreate,
    updateCompanyError,
    id,
    clientList,
    productList,
    selectedCompany,
    currentlyActiveCompanyId,
    vendorList
  } = useSelector(companySelector)
  const [isNext, setNext] = useState(false)
  const [notify, setNotify] = useState(false)
  const [editButtonClicked, setEditButtonClicked] = useState(false)
  const [selectedCard, setSelectedCard] = useState('')
  const [mouseEnterOnCard, setMouseEnterOnCard] = useState('')

  const isCoaCompleted = vendorList.some(eachItem => eachItem.department)

  // useImperativeHandle(ref, () => ({
  //   handleNext() {
  //     if (clientList.length === 0 || (formRef.current.values.name && clientList.length > 0)) {
  //       if (formRef.current.isValid) {
  //         setNotify(true)
  //         setNext(true)
  //         dispatch(setSelectedMenuItem(''))
  //       } else {
  //         dispatch(clearState())
  //         if (formRef.current) formRef.current.handleSubmit()
  //         dispatch(setSelectedMenuItem(''))
  //         setNext(true)
  //       }
  //     } else {
  //       setNext(false)
  //       dispatch(clearState())
  //       const data = {
  //         companyId: id,
  //         fromRevenue: true
  //       }
  //       dispatch(setIsWizardVisible(false))
  //       dispatch(setSelectedMenuItem(''))
  //       if (isDemoCompany) {
  //         dispatch(toggleCompanySetupModal(false))
  //         dispatch(setCompanySetupModalScreen(0))
  //         dispatch(resetState())
  //         dispatch(getCompaniesByUser())
  //         dispatch(setSelectedMenuItem(''))
  //       } else {
  //         dispatch(finishCompanyCreate(data))
  //         dispatch(setSelectedMenuItem(''))
  //         navigate(`/budget?cid=${selectedCompany}`)
  //         dispatch({ type: MENU_OPEN, id: 'models' })
  //         if (isAddRevenueVisible) setAddRevenueVisible(false)
  //       }
  //     }
  //   }
  // }))

  const formikInitialValues = {
    _id: '',
    name: '',
    products: [],
    growthIncrement: '',
    startDate: null,
    growthRate: '',
    startingUnits: '',
    submit: null
  }
  const [initialValues, setInitialValues] = useState(formikInitialValues)
  const [openStartDatePicker, setOpenStartDatePicker] = useState(false)
  const [openEndDatePicker, setOpenEndDatePicker] = useState(false)
  const [loadMoreBtn, setLoadMoreBtn] = useState(false)
  const [visible, setVisible] = useState(10)
  // const [editItem, setEditItem] = useState('')

  const handleAddClient = (name, products, growthIncrement, growthRate, startingUnits, startDate) => {
    const payload = {
      name,
      products,
      growthIncrement,
      growthRate,
      startDate,
      startingUnits
    }
    const logArray = []

    const clientListItem = []
    clientListItem.push(payload)
    setAddedItem(clientListItem)

    const clientChangeLog = {
      user: user._id,
      userName: user.name,
      company: selectedCompany,
      date: new Date(),
      tabChanged: 'Revenue/ Pro Services/ Detailed Billable Client',
      department: 'N/A',
      lineItemChanged: `${payload.name} (New Client added)`,
      originalValue: 'N/A',
      newValue: 'N/A'
    }
    logArray.push(clientChangeLog)

    const clientPayload = [...clientList]
    clientPayload.push(payload)
    if (id !== '') {
      const data = {
        id,
        clientList: clientPayload,
        systemLog: logArray
      }
      dispatch(updateCompany(data))
    }
    setInitialValues(formikInitialValues)
  }

  useEffect(() => {
    setInitialValues({
      ...editItem,
      submit: null
    })
  }, [isEditButtonClicked])

  const handleDeleteClient = _id => {
    if (editItem === _id) return
    // if (formRef.current.values._id) {
    //   setNotify(true)
    //   return
    // }
    const selectedClient = [...clientList].filter(item => item._id === _id)

    const payload = {
      _id: selectedClient[0]._id,
      name: selectedClient[0].name
    }
    const clientPayload = [...clientList].filter(item => item._id !== _id)

    const logArray = []

    const clientChangeLog = {
      user: user._id,
      userName: user.name,
      company: selectedCompany,
      date: new Date(),
      tabChanged: 'Revenue/ Pro Services/ Detailed Billable Client',
      department: 'N/A',
      lineItemChanged: `${payload.name} (Client deleted)`,
      originalValue: 'N/A',
      newValue: 'N/A'
    }
    logArray.push(clientChangeLog)

    if (id !== '') {
      const data = {
        id,
        clientList: clientPayload,
        systemLog: logArray
      }
      dispatch(updateCompany(data))
    }
  }

  const handleUpdateClient = (_id, name, products, growthIncrement, growthRate, startingUnits, startDate) => {
    setEditItem('')
    const payload = {
      _id,
      name,
      products,
      growthIncrement,
      growthRate,
      startDate,
      startingUnits
    }
    const clientPayload = [...clientList]
    const index = clientPayload.findIndex(item => item._id === payload._id)

    //  system logs
    const logArray = []

    if (clientPayload[index].name !== payload.name) {
      const vendorChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Detailed Billable Client',
        department: 'N/A',
        lineItemChanged: `${clientPayload[index].name} (Name changed)`,
        originalValue: clientPayload[index].name,
        newValue: payload.name
      }
      logArray.push(vendorChangeLog)
    }

    if (clientPayload[index].products.join() !== payload.products.join()) {
      const vendorChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Detailed Billable Client',
        department: 'N/A',
        lineItemChanged: `${clientPayload[index].name} (Product changed)`,
        originalValue: JSON.stringify(clientPayload[index].products),
        newValue: JSON.stringify(payload.products)
      }
      logArray.push(vendorChangeLog)
    }

    if (clientPayload[index].growthIncrement !== payload.growthIncrement) {
      const vendorChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Detailed Billable Client',
        department: 'N/A',
        lineItemChanged: `${clientPayload[index].name} (Growth Increment changed)`,
        originalValue: clientPayload[index].growthIncrement,
        newValue: payload.growthIncrement
      }
      logArray.push(vendorChangeLog)
    }

    if (clientPayload[index].growthRate !== payload.growthRate) {
      const vendorChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Detailed Billable Client',
        department: 'N/A',
        lineItemChanged: `${clientPayload[index].name} (Growth Rate changed)`,
        originalValue: clientPayload[index].growthRate,
        newValue: payload.growthRate
      }
      logArray.push(vendorChangeLog)
    }

    if (clientPayload[index].startingUnits !== payload.startingUnits) {
      const vendorChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Detailed Billable Client',
        department: 'N/A',
        lineItemChanged: `${clientPayload[index].name} (Starting Units changed)`,
        originalValue: clientPayload[index].startingUnits,
        newValue: payload.startingUnits
      }
      logArray.push(vendorChangeLog)
    }

    clientPayload[index] = payload
    if (id !== '') {
      const data = {
        id,
        clientList: clientPayload,
        systemLog: logArray
      }
      dispatch(updateCompany(data))
    }
    setInitialValues(formikInitialValues)
  }
  useEffect(() => {
    if (clientList.length > 10) {
      setLoadMoreBtn(false)
    }
  }, [clientList])

  const handleLoadMore = () => {
    setVisible(visible + 10)
  }
  useEffect(() => {
    if (updateCompanyError !== '') {
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: updateCompanyError,
        variant: 'alert',
        alertSeverity: 'error'
      })
    }

    return dispatch(clearState())
  }, [dispatch, updateCompanyError])

  useEffect(() => {
    if (isSuccess && isNext) {
      dispatch(clearState())
      const data = {
        companyId: id,
        isComplete: isCoaCompleted,
        fromRevenue: true
      }
      dispatch(finishCompanyCreate(data))
    }
  }, [isNext, isSuccess])

  useEffect(() => {
    if (isSuccessCompanyCreate) {
      dispatch(toggleCompanySetupModal(false))
      // dispatch(setCompanySetupModalScreen(0))
      dispatch(resetState())
      dispatch(getCompaniesByUser())
      const data = {
        companyId: currentlyActiveCompanyId || selectedCompany,
        email: user.email
      }
      dispatch(getCompanyByID(data))
    }
  }, [isSuccessCompanyCreate])

  const getProducts = ids => {
    const p = []
    productList.forEach(product => {
      if (ids.includes(product._id)) p.push(product.name)
    })
    return p.join(', ')
  }
  const handleChangeNumberGrowth = e => {
    const value = e.target.value.replace(/\D/g, '')
    formRef.current.setFieldValue('growthRate', value.replace(/\B(?=(\d{3})+(?!\d))/g, ','))
  }

  const handleChangeNumberUnits = e => {
    const value = e.target.value.replace(/\D/g, '')
    formRef.current.setFieldValue('startingUnits', value.replace(/\B(?=(\d{3})+(?!\d))/g, ','))
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        <p style={{ color: '#207E4D' }} className={cssClasses.drawerHeadingSecond}>
          My client
        </p>
        <ExcelImporTabModel excelImportTabNo={8} />
      </div>
      <Formik
        validateOnMount
        innerRef={formRef}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Name is required').max(30, 'Maximum 30 characters allowed'),
          products: Yup.string().max(30, 'Maximum 30 characters allowed'),
          growthIncrement: Yup.string().required('Growth increment is required'),
          startDate: Yup.string().nullable().required('Start date is required'),
          growthRate: Yup.string().required('Percentage growth per period is required'),
          startingUnits: Yup.string().required('Starting units is required').max(30, 'Maximum 30 characters allowed')
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
          if (values._id) {
            handleUpdateClient(
              values._id,
              values.name,
              values.products,
              values.growthIncrement,
              values.growthRate.toString().includes(',') ? parseInt(values.growthRate.replace(/,/g, ''), 10) : values.growthRate,
              values.startingUnits.toString().includes(',') ? parseInt(values.startingUnits.replace(/,/g, ''), 10) : values.startingUnits,
              new Date(moment(values.startDate).format('YYYY-MM-DD'))
            )
          } else {
            handleAddClient(
              values.name,
              values.products,
              values.growthIncrement,
              values.growthRate.toString().includes(',') ? parseInt(values.growthRate.replace(/,/g, ''), 10) : values.growthRate,
              values.startingUnits.toString().includes(',') ? parseInt(values.startingUnits.replace(/,/g, ''), 10) : values.startingUnits,
              values.startDate
            )
          }
          setStatus({ success: true })
          setSubmitting(false)
          resetForm(initialValues)
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
          <form style={{ width: '100%', marginTop: '48px' }} noValidate onSubmit={handleSubmit}>
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column' }}>
              <label htmlFor="client_name" className={cssClasses.inputLabel}>
                Client name <span className={cssClasses.important}>*</span>{' '}
              </label>

              <input
                placeholder="Client name"
                className={cssClasses.wizardInputBox}
                value={values.name}
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                id="client_name"
              />

              {touched.name && errors.name && <FormHelperText error> {errors.name} </FormHelperText>}
            </div>

            <div
              style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', marginTop: '20px' }}
            >
              <label htmlFor="products-select-label" className={cssClasses.inputLabel}>
                Products <span className={cssClasses.important}>*</span>{' '}
              </label>

              <select
                placeholder="frequency"
                className={cssClasses.wizardInputBox}
                value={values.products}
                name="products"
                onBlur={handleBlur}
                onChange={handleChange}
                id="products-select-label"
              >
                {' '}
                <option value="" disabled selected hidden>
                  Select
                </option>
                {productList.map((product, index) => (
                  <option key={`client_${index}`} value={product._id}>
                    {product.name}
                  </option>
                ))}
              </select>

              {touched.products && errors.products && <FormHelperText error> {errors.products} </FormHelperText>}
            </div>

            <div
              style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', marginTop: '20px' }}
            >
              <label htmlFor="growthIncrement-select-label" className={cssClasses.inputLabel}>
                Growth increment <span className={cssClasses.important}>*</span>{' '}
              </label>

              <select
                placeholder="frequency"
                className={cssClasses.wizardInputBox}
                value={values.growthIncrement}
                name="growthIncrement"
                onBlur={handleBlur}
                onChange={handleChange}
                id="growthIncrement-select-label"
              >
                {' '}
                <option value="" disabled selected hidden>
                  Select
                </option>
                <option value="Monthly">Monthly</option>
                <option value="Quarterly">Quarterly</option>
                <option value="Annually">Annually</option>
              </select>

              {touched.growthIncrement && errors.growthIncrement && <FormHelperText error> {errors.growthIncrement} </FormHelperText>}
            </div>

            <div
              style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', marginTop: '20px' }}
            >
              <label htmlFor="start_date" className={cssClasses.inputLabel}>
                Start date <span className={cssClasses.important}>*</span>{' '}
              </label>

              <input
                type="date"
                placeholder="Start date"
                className={cssClasses.wizardInputBox}
                value={values.startDate}
                name="startDate"
                onBlur={handleBlur}
                onChange={handleChange}
                id="start_date"
              />

              {touched.startDate && errors.startDate && <FormHelperText error> {errors.startDate} </FormHelperText>}
            </div>

            <div
              style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', marginTop: '20px' }}
            >
              <label htmlFor="grawth_rate" className={cssClasses.inputLabel}>
                Growth percentage <span className={cssClasses.important}>*</span>{' '}
              </label>

              <input
                placeholder="Growth percentage"
                className={cssClasses.wizardInputBox}
                value={values.growthRate}
                name="growthRate"
                onBlur={handleBlur}
                onChange={handleChange}
                id="grawth_rate"
              />

              {touched.growthRate && errors.growthRate && <FormHelperText error> {errors.growthRate} </FormHelperText>}
            </div>

            <div
              style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', marginTop: '20px' }}
            >
              <label htmlFor="starting_units" className={cssClasses.inputLabel}>
                Starting Units <span className={cssClasses.important}>*</span>{' '}
              </label>

              <input
                placeholder="Starting Units"
                className={cssClasses.wizardInputBox}
                value={values.startingUnits}
                name="startingUnits"
                onBlur={handleBlur}
                onChange={handleChange}
                id="starting_units"
              />

              {touched.startingUnits && errors.startingUnits && <FormHelperText error> {errors.startingUnits} </FormHelperText>}
            </div>

            <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button className={cssClasses.wizardAddButton} type="submit">
                Add
              </button>
            </div>
            <Divider sx={{ marginTop: '230px', marginBottom: '10px' }} />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button className={cssClasses.wizardCancelButton} type="button">
                Cancel
              </button>
              <button className={cssClasses.disabledButton} type="button">
                Save
              </button>
            </div>

            {/* <Grid item justifyContent="center" md={12} sx={{ textAlign: 'center' }}>
                  <Button
                    className={classes.upgradeBtn}
                    disabled={isSubmitting}
                    onClick={() => {
                      setNext(false)
                      setEditButtonClicked(false)
                      if (formRef.current) formRef.current.handleSubmit()
                    }}
                    size="medium"
                    color="secondary"
                    aria-label="add"
                  >
                    <Typography>{editButtonClicked ? 'Save' : 'Add'}</Typography>
                  </Button>
                </Grid> */}
          </form>
        )}
      </Formik>
    </>
  )
})

export default DetailedBillableClientsTabView
