import React from 'react'
import { Box, Typography } from '@material-ui/core'
import logWhite from 'assets/images/mrgn-logo-s-white.png'
import arrowRight from 'assets/images/arrow-right.png'
import SetupDashboard from './SetupDashboard'
import './SetupDashboard.css'
import { companySelector, setIsNewCompanyRegistered, setIsWizardVisible, setIsOpenedFromComDrop } from 'store/company/company.slice'
import { useDispatch, useSelector } from 'react-redux'
import { setIsUserClickedOnTheWindow } from 'store/ui/ui.slice'
import { useLocation } from 'react-router'

const CompanySetupCheckList = () => {
  const dispatch = useDispatch()
  // const [isWizardVisible, setIsWizardVisible] = useState(false)
  const { isNewCompanyRegistered, isWizardVisible } = useSelector(companySelector)
  const location = useLocation()

  // const setWizardVisibility = status => {
  //   setIsWizardVisible(status)
  // }

  const noWizardPaths = [
    '/simulation',
    '/low-mid-high',
    '/people-simulation',
    '/debt-financing',
    '/burn-rate-reduction',
    '/equity-raise',
    '/hiring-plans'
  ]

  return (
    <>
      {!noWizardPaths.includes(location.pathname) && (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {isNewCompanyRegistered && (
            <Box
              className={isNewCompanyRegistered ? 'WizardPopUpButtonPointingArrow' : ''}
              sx={{
                height: 80,
                zIndex: 100,
                padding: '4px',
                display: 'flex',
                right: 130,
                position: 'absolute',
                top: 150,
                flexDirection: 'row',
                alignItems: 'center',
                justifyVContent: 'center'
              }}
            >
              <img width={120} src={arrowRight} alt="alertArrow" />
            </Box>
          )}
          <Box
            className={isNewCompanyRegistered ? 'WizardPopUpButton' : ''}
            onClick={() => {
              dispatch(setIsNewCompanyRegistered(false))
              dispatch(setIsWizardVisible(true))
              dispatch(setIsUserClickedOnTheWindow(false))
              dispatch(setIsOpenedFromComDrop(false))
            }}
            sx={{
              width: 80,
              height: 80,
              zIndex: 100,
              padding: '4px',
              display: 'flex',
              right: 0,
              position: 'absolute',
              boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
              top: 150,
              flexDirection: 'row',
              borderRadius: '10px',
              alignItems: 'center',
              justifyVContent: 'center',
              backgroundColor: '#29A364',
              cursor: 'pointer'
            }}
          >
            <Typography sx={{ fontSize: '20px', color: '#fff', fontWeight: '100' }}>&lt;</Typography>
            <img style={{ marginLeft: 7, width: 50, height: 50 }} src={logWhite} alt="mrgn" />
          </Box>
          {isWizardVisible && <SetupDashboard />}
        </Box>
      )}
    </>
  )
}

export default CompanySetupCheckList
