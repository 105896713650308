import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  CardContent,
  Box,
  IconButton,
  Paper,
  TextField,
  Input
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '@material-ui/icons/Close'
import { Formik, Form, useFormik } from 'formik'
import * as Yup from 'yup'
import * as moment from 'moment'
import {
  uiSelector,
  toggleDebtSetupModal,
  togglePaymentSetupModel,
  toggleCompanySetupModal,
  setCompanySetupModalScreen
} from 'store/ui/ui.slice'
import {
  resetState,
  companySelector,
  hideIntroModal,
  setIntroModalOverride,
  clearState,
  resetCompanyLoadedStatus,
  setCompanyId
} from 'store/company/company.slice'
import { modelsSelector } from 'store/models/models.slice'
import { useTheme } from '@emotion/react'

import { createCompanyDebt, createCompany } from 'store/company/company.actions'
import { getModelDebtDataByID } from 'store/models/models.actions'
import PopupNotify from 'ui-component/tab/Model/PopupNotify'

import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { clearpromo, paymentSelector } from 'store/payment/payment.slice'

import { authSelector, setNewUserWPLogin } from 'store/auth/auth.slice'
import { Button } from 'antd'
import { addPromoCode } from 'store/payment/payment.action'
import { LoadingButton } from '@material-ui/lab'
import { SNACKBAR_OPEN } from 'store/actions'
import CheckoutForm from './StripeCheckoutForm'

const useStyles = makeStyles(theme => ({
  input: {
    width: '248px',
    height: 40,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    padding: '10px',
    fontSize: '16px',
    outline: 'none',
    transition: 'border-color 0.1s ease',
    '&:focus': {
      borderColor: '#207E4D'
    }
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  muiDialog: {
    '& .MuiDialog-paper': {
      maxHeight: 'initial',
      height: 'calc(100% - 64px)'
    }
  },
  muiDialogContent: {
    [theme.breakpoints.up('sm')]: {
      overflowY: 'hidden',
      paddingRight: 20
    }
  },
  gridFillHeight: {
    [theme.breakpoints.up('sm')]: {
      height: '100%'
    }
  },
  gridFillHeightOverflowY: {
    [theme.breakpoints.up('sm')]: {
      height: '100%',
      overflowY: 'auto'
    }
  }
}))

const StripPaymentModel = () => {
  const theme = useTheme()
  const classes = useStyles()
  const [warning, setWarning] = useState(false)

  const dispatch = useDispatch()

  const { paymentSetupModelVisible } = useSelector(uiSelector)
  const { wpNewUser } = useSelector(authSelector)
  const { clientSecret, publishableKey, productPrice, earlyBirdStatus, promoCode, isLoading, promoErrorCode, userSelectedPlan, status } =
    useSelector(paymentSelector)
  const { user } = useSelector(authSelector)
  const { selectedCompany, id } = useSelector(companySelector)
  const [ElementOptions, setElementOptions] = useState('')
  const [stripePromise, setStripePromise] = useState('')
  const [promo, setPromo] = useState('')
  const [promoError, setPromoError] = useState(false)

  console.log(promoErrorCode, 'promoErrorCode')

  // useEffect(() => {
  //   if (userSelectedPlan === 'growth_monthly') {
  //     setPromo('growth100')
  //   } else if (userSelectedPlan === 'business_monthly') {
  //     setPromo('business100')
  //   } else if (userSelectedPlan === 'starter_monthly') {
  //     setPromo('starter100')
  //   } else {
  //     setPromo('')
  //   }
  // }, [userSelectedPlan])

  let selectedCompanyId = null

  if (selectedCompany === '') {
    selectedCompanyId = id
  } else {
    selectedCompanyId = selectedCompany
  }

  useEffect(() => {
    if (promoErrorCode) setPromoError(true)
    else setPromoError(false)
  }, [promoErrorCode])

  useEffect(() => {
    if (publishableKey) {
      setStripePromise(loadStripe(publishableKey))
    }
    if (clientSecret) {
      const appearance = {
        theme: 'stripe'
      }
      setElementOptions({
        clientSecret,
        appearance
      })
    }
  }, [clientSecret, publishableKey])

  const handleInputChange = e => {
    if (e) setPromoError(false)
    setPromo(e.target.value)
  }

  const handleApplyClick = () => {
    if (promo) {
      dispatch(addPromoCode({ promoCode: promo, selectedCompany: selectedCompanyId, userId: user.id }))
      setPromo('')
    } else {
      setPromoError(true)
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
      <div
        className={classes.root}
        style={{
          paddingBottom: '10px',
          marginBottom: '20px'
        }}
      >
        {!promoCode && (
          <>
            <div>
              <Typography sx={{ fontSize: 14, color: '#30313D' }}>Promo code</Typography>

              <input value={promo} onChange={handleInputChange} className={classes.input} />
            </div>
            <LoadingButton
              onClick={handleApplyClick}
              sx={{
                backgroundColor: '#29A364',
                color: '#fff',
                mt: 2,
                '&:hover': {
                  backgroundColor: '#29A364'
                }
              }}
            >
              Apply
            </LoadingButton>
          </>
        )}
        {promoCode && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              p: 2,
              ml: 3,
              borderRadius: 8,
              backgroundColor: 'white',
              boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)'
            }}
          >
            {promoCode}
            <Typography variant="h5" sx={{ ml: 2, fontWeight: 'bold', color: '#333' }}>
              Coupon Applied!
            </Typography>
          </Box>
        )}
      </div>
      {promoError && <Typography sx={{ color: 'red', pb: 2 }}>Enter valid promo code</Typography>}
      {ElementOptions && (
        <Elements options={ElementOptions} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  )
}

export default StripPaymentModel
