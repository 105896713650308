import React, { useEffect, useState } from 'react'
import { FormControl, Select, MenuItem, Divider, Grid, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import EditIcon from '@material-ui/icons/Edit'
import AddCircleIcon from '@material-ui/icons/AddCircleOutlineOutlined'
import VisibilityIcon from '@material-ui/icons/Visibility'
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { getCompaniesByUser, getCompanyByID } from 'store/company/company.actions'
import {
  changeLastTabLoaded,
  clearState,
  companySelector,
  resetCompanyLoadedStatus,
  resetState,
  setCompanyId,
  setCurrentlyActiveCompanyId,
  setIsNewCompanyRegistered,
  setSelectedCompany,
  setWizardTriggeringStatus,
  setIsOpenedFromComDrop,
  setReceivedNotifyMail
} from 'store/company/company.slice'
import { MENU_OPEN, SNACKBAR_OPEN } from 'store/actions'

import { useQuery } from 'utils/ui'

import { uiSelector, setCompanySetupModalScreen, toggleCompanySetupModal, setPaymentSetupModalScreen } from 'store/ui/ui.slice'
import _ from 'lodash'
import { authSelector, updateSetupModelPreviousStatus } from 'store/auth/auth.slice'
import { getModelSummaryDataByID } from 'store/models/models.actions'

// style constant
const useStyles = makeStyles(theme => ({
  dropdownStyle: {
    fontFamily: 'Raleway',
    fontSize: '16px',
    color: 'var(--neutrals-warm-600, #666)',
    fontWeight: 500,
    '& .MuiOutlinedInput-input': {
      borderRadius: '4px !important',
      border: '1px solid #CFD0D9',
      background: 'transparent',
      padding: '10px 10px',
      // styles border when dropdown is active and expanded
      '&[aria-expanded=true]': {
        border: '1.75px solid var(--secondary-purple-600, #259259)',
        borderBottom: 'none'
      }
    },
    background: 'transparent',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      background: 'transparent'
    }
  },
  companyDropDown: {
    width: '200px',
    [theme.breakpoints.down('lg')]: {
      width: '100px'
    }
  }
}))

const SelectCompanyDropdown = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const query = useQuery()

  const [companyArray, setCompanyArray] = useState([])
  const [demoCompany, setDemoCompany] = useState([])
  const [disabledCompanyList, setDisabledCompanyList] = useState([])
  const { user, CompanySetupModelPreviousState, selectedCompanyId, isGuestUser, isLoggedIn } = useSelector(authSelector)
  const { companySetupModalVisible } = useSelector(uiSelector)
  const {
    isLoadedCompanies,
    getCompaniesError,
    companies,
    selectedCompany,
    isLoadingCompany,
    incompleteCompanies,
    id,
    incompleteCompanyId
  } = useSelector(companySelector)

  const isMailNotify = query.get('isMailNotify')
  const isCompanyIdInUrl = query.get('cid')

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getCompaniesByUser())
    }
  }, [dispatch, isLoggedIn])

  useEffect(() => {
    if (selectedCompany || incompleteCompanyId) {
      const data = {
        companyId: selectedCompany || incompleteCompanyId,
        email: user.email
      }
      dispatch(getCompanyByID(data))
    }
  }, [dispatch, incompleteCompanyId, selectedCompany, user.email])

  useEffect(() => {
    if (isMailNotify) {
      dispatch(setSelectedCompany(isCompanyIdInUrl))
      navigate(`${location.pathname}?cid=${isCompanyIdInUrl}`)
      dispatch(setPaymentSetupModalScreen(3))
      dispatch({ type: MENU_OPEN, id: 'company' })
      dispatch(setReceivedNotifyMail(true))
      return
    }

    const tempDisabledCompanyList = []

    const tempCompanies = [...companies, ...incompleteCompanies]

    if (tempCompanies.length > 0 && !selectedCompany) {
      const userId = user.id
      tempCompanies.forEach(company => {
        if (!company.isDemo) {
          if (company.usage_tier === 'starter' && company.adminDepartment.length > 1) {
            const invitedUser = company.adminDepartment.find(invite => invite.userId === userId)
            if (invitedUser.role !== 'superAdmin') {
              tempDisabledCompanyList.push(company.id)
            }
          }
          if (company.usage_tier === 'business' && company.adminDepartment.length > 3) {
            const superAdminUsers = company.adminDepartment.filter(user => user.role === 'superAdmin')
            const otherUsers = company.adminDepartment.filter(user => user.role !== 'superAdmin')
            otherUsers.sort((a, b) => {
              const dateA = new Date(parseInt(a._id.substring(0, 8), 16) * 1000)
              const dateB = new Date(parseInt(b._id.substring(0, 8), 16) * 1000)
              return dateA.getTime() - dateB.getTime()
            })
            const userList = superAdminUsers.concat(otherUsers)

            const userIndex = userList.findIndex(obj => obj.userId === userId)
            const userInviteObject = userList.find(obj => obj.userId === userId)

            if (userInviteObject.role !== 'superAdmin') {
              if (userIndex > 2) tempDisabledCompanyList.push(company.id)
            }
          }
        }
      })
      setDisabledCompanyList(tempDisabledCompanyList)

      const previousCompany = localStorage.getItem('selectedCompany')
      const companyList = [...companies, ...incompleteCompanies]

      const filteredArray = companyList.filter(obj => !disabledCompanyList.includes(obj.id))

      const randomIndex = Math.floor(Math.random() * filteredArray.length)
      const randomObject = filteredArray[randomIndex]

      if (!selectedCompany || disabledCompanyList.includes(selectedCompany)) {
        if (previousCompany && !disabledCompanyList.includes(previousCompany) && companyList.some(obj => obj.id === previousCompany)) {
          dispatch(setSelectedCompany(previousCompany))
          navigate(`${location.pathname}?cid=${previousCompany}`)
        } else {
          dispatch(setSelectedCompany(randomObject.id))
          // delaying navigation to help GTM capture the initial URL with event params
          setTimeout(() => {
            navigate(`${location.pathname}?cid=${randomObject.id}`)
          }, 2000)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies, incompleteCompanies, isMailNotify])

  useEffect(() => {
    if (selectedCompany && !id && selectedCompany !== id) {
      const payload = {
        companyId: selectedCompany,
        email: user.email
      }
      dispatch(getCompanyByID(payload))
      dispatch(clearState())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany])

  useEffect(() => {
    if (CompanySetupModelPreviousState && !companySetupModalVisible && !isGuestUser) {
      dispatch(resetCompanyLoadedStatus())
      dispatch(setCompanyId(selectedCompanyId))
      dispatch(toggleCompanySetupModal(true))
      dispatch(setCompanySetupModalScreen(0))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CompanySetupModelPreviousState])

  useEffect(() => {
    if (isLoadedCompanies && companies?.length > 0) {
      const tempCompany = [...companies]

      tempCompany.forEach((comp, index) => {
        if (comp.isDemo) {
          const demoComp = tempCompany.splice(index, 1)
          setDemoCompany(demoComp)
        }
      })
      setCompanyArray(tempCompany)

      dispatch(clearState())
    }
  }, [companies, dispatch, isLoadedCompanies])

  useEffect(() => {
    if (getCompaniesError !== '') {
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: getCompaniesError,
        variant: 'alert',
        alertSeverity: 'error'
      })
    }

    dispatch(clearState())
  }, [dispatch, getCompaniesError])

  const handleChange = event => {
    if (event.target.value === 'Add New') {
      dispatch(setCompanyId(''))
      dispatch(resetState())
      dispatch(setSelectedCompany(''))
      dispatch(setCompanySetupModalScreen(0))
      dispatch(toggleCompanySetupModal(true))
      dispatch(changeLastTabLoaded(false))
      // dispatch(setCurrentlyActiveCompanyId(event.target.value))
      dispatch(setIsNewCompanyRegistered(false))
      dispatch(setWizardTriggeringStatus(false))
      dispatch(setIsOpenedFromComDrop(true))
    } else if (incompleteCompanies?.filter(company => company.id === event.target.value).length) {
      localStorage.setItem('selectedCompany', event.target.value)
      dispatch(setSelectedCompany(event.target.value))
      dispatch(getCompanyByID({ companyId: event.target.value }))
      dispatch(setCurrentlyActiveCompanyId(event.target.value))
      dispatch(setIsNewCompanyRegistered(false))
      // dispatch(getCompaniesByUser())
    } else {
      localStorage.setItem('selectedCompany', event.target.value)
      dispatch(getCompanyByID({ companyId: event.target.value }))
      dispatch(setSelectedCompany(event.target.value))
      navigate(`${location.pathname}?cid=${event.target.value}`)
      dispatch(setCurrentlyActiveCompanyId(event.target.value))
      dispatch(setIsNewCompanyRegistered(false))
      // dispatch(getCompaniesByUser())
    }
  }

  const renderLabel = value => {
    const company = companies.find(x => x.id === value)
      ? companies.find(x => x.id === value)
      : incompleteCompanies.find(x => x.id === value)
    if (_.has(company, 'name')) return company.isDemo ? `${company.name} (Demo)` : company.name
    return ''
  }

  const [loading, setLoading] = useState(false)
  const [selectedValue, setSelectedValue] = useState('')

  const handleButtonClick = () => {
    setLoading(true)

    // Simulating an asynchronous operation
    setTimeout(() => {
      setLoading(false)
      setSelectedValue('')
    }, 2000)
  }

  useEffect(() => {
    if (selectedCompany) {
      const data = {
        companyId: selectedCompany,
        frequency: 1,
        breakdown: 0,
        timeline: 1
      }
      dispatch(getModelSummaryDataByID(data))
    }
  }, [selectedCompany])

  const inCompleteCompaniesWithoutNewRegister = incompleteCompanies.filter(eachItem => eachItem?.isNewRegisterCompany === false)

  const newRegisterOnlyOneCompany = companyArray.filter(
    eachItem => eachItem?.isNewCompanyRegistered === true && eachItem.isComplete === true
  )

  return (
    <FormControl className={classes.companyDropDown}>
      <Select
        value={selectedCompany}
        onChange={handleChange}
        renderValue={value => renderLabel(value)}
        className={classes.dropdownStyle}
        startAdornment={!selectedCompany && <CircularProgress size={20} />}
        MenuProps={{
          // for styling the nested menu items
          sx: {
            '&& .Mui-selected': {
              color: 'var(--secondary-purple-600, #259259)',
              background: 'transparent !important'
            },
            '& .MuiMenuItem-root:hover': {
              backgroundColor: '#F2F7F4',
              borderRadius: '4px'
            },
            '&& .MuiMenu-list': {
              borderRadius: '4px',
              border: '1px solid var(--secondary-purple-600, #259259)',
              borderTop: 'none',
              paddingTop: '5px',
              paddingBottom: '0px',
              color: '#0C0D0F !important'
            }
          }
        }}
      >
        {newRegisterOnlyOneCompany.map(company => (
          <MenuItem disabled={disabledCompanyList.includes(company.id)} id={company.id} value={company.id} key={`company-${company.id}`}>
            <Grid container>
              <Grid item sm>
                {company.name}
              </Grid>
              <Grid item sm="auto">
                {!disabledCompanyList.includes(company.id) && (
                  <EditIcon
                    fontSize="small"
                    color="primary"
                    onClick={() => {
                      dispatch(resetCompanyLoadedStatus())
                      dispatch(setCompanyId(company.id))
                      dispatch(toggleCompanySetupModal(true))
                      dispatch(setCompanySetupModalScreen(0))
                      dispatch(changeLastTabLoaded(false))
                      dispatch(updateSetupModelPreviousStatus(true))
                      dispatch(setWizardTriggeringStatus(true))
                      dispatch(setIsOpenedFromComDrop(true))
                    }}
                    sx={{ cursor: 'pointer', ml: 1 }}
                  />
                )}
              </Grid>
            </Grid>
          </MenuItem>
        ))}

        {companyArray.map(company => (
          <MenuItem disabled={disabledCompanyList.includes(company.id)} id={company.id} value={company.id} key={`company-${company.id}`}>
            <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
              <Grid item sm>
                {company.name}
              </Grid>
              <Grid item sm="auto" sx={{ display: 'flex', alignItems: 'center' }}>
                {!disabledCompanyList.includes(company.id) && (
                  <EditIcon
                    fontSize="small"
                    color="primary"
                    onClick={() => {
                      dispatch(resetCompanyLoadedStatus())
                      dispatch(setCompanyId(company.id))
                      dispatch(toggleCompanySetupModal(true))
                      dispatch(setCompanySetupModalScreen(0))
                      dispatch(changeLastTabLoaded(false))
                      dispatch(updateSetupModelPreviousStatus(true))
                      dispatch(setWizardTriggeringStatus(true))
                      dispatch(setIsOpenedFromComDrop(true))
                    }}
                    sx={{ cursor: 'pointer', ml: 1 }}
                  />
                )}
              </Grid>
            </Grid>
          </MenuItem>
        ))}

        {inCompleteCompaniesWithoutNewRegister.map(company => (
          <MenuItem disabled={disabledCompanyList.includes(company.id)} id={company.id} value={company.id} key={`company-${company.id}`}>
            <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
              <Grid item sm style={{ fontWeight: '500', verticalAlign: 'middle' }}>
                {company.name}
              </Grid>
              <Grid item sm="auto" sx={{ display: 'flex', alignItems: 'center' }}>
                {!disabledCompanyList.includes(company.id) && (
                  <KeyboardReturnIcon
                    fontSize="small"
                    color="primary"
                    onClick={() => {
                      dispatch(resetCompanyLoadedStatus())
                      dispatch(setCompanyId(company.id))
                      dispatch(toggleCompanySetupModal(true))
                      dispatch(setCompanySetupModalScreen(1))
                      dispatch(changeLastTabLoaded(false))
                      dispatch(updateSetupModelPreviousStatus(true))
                      dispatch(setWizardTriggeringStatus(false))
                      dispatch(setIsOpenedFromComDrop(true))
                    }}
                    sx={{ cursor: 'pointer', ml: 1 }}
                  />
                )}
              </Grid>
            </Grid>
          </MenuItem>
        ))}
        {demoCompany.map(company => (
          <MenuItem id={company.id} value={company.id} key={`company-${company.id}`}>
            <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
              <Grid item sm style={{ fontWeight: '500' }}>
                {`${company.name} (Demo)`}
              </Grid>
              <Grid item sm="auto" sx={{ display: 'flex', alignItems: 'center' }}>
                <VisibilityIcon
                  fontSize="small"
                  color="primary"
                  onClick={() => {
                    dispatch(resetCompanyLoadedStatus())
                    dispatch(setCompanyId(company.id))
                    dispatch(toggleCompanySetupModal(true))
                    dispatch(setCompanySetupModalScreen(0))
                    dispatch(changeLastTabLoaded(false))
                    dispatch(updateSetupModelPreviousStatus(true))
                    dispatch(setIsOpenedFromComDrop(false))
                  }}
                  sx={{ cursor: 'pointer', ml: 1 }}
                />
              </Grid>
            </Grid>
          </MenuItem>
        ))}
        <Divider sx={{ margin: '0px !important' }} />
        <MenuItem disabled={isLoadingCompany} value="Add New">
          <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
            <Grid item sm="auto" sx={{ display: 'flex', alignItems: 'center', marginRight: '5px' }}>
              <AddCircleIcon fontSize="small" color="primary" />
            </Grid>
            <Grid item sm sx={{ color: '#0C0D0F' }}>
              Add Company
            </Grid>
          </Grid>
        </MenuItem>
      </Select>
    </FormControl>
  )
}

export default SelectCompanyDropdown
