import { createSlice } from '@reduxjs/toolkit'
import {
  jwtSignIn,
  signUp,
  signOut,
  refreshTokens,
  createUser,
  updateUser,
  getUserInvites,
  getInviteById,
  googleSignIn,
  googleSignUp,
  getUserByToken,
  resetPassword,
  changePassword,
  resetWithToken,
  uploadAvatar,
  getUserDepartment,
  guestUserLogin,
  getPlatformOnobardingFormStatus,
  postPlatformOnobardingFormSuccess
} from './auth.actions'
import cookies from 'utils/cookies'

const initialState = {
  isLoggedIn: false,
  logout: false,
  loadingSignUp: false,
  loadingSignIn: false,
  loadingSignOut: false,
  loadingRefreshToken: false,
  loadingChangePassword: false,
  loginError: '',
  resetSuccess: '',
  resetEmailSend: '',
  signupError: '',
  logoutError: '',
  isImageUploading: false,
  isImageUploaded: false,
  refreshTokenError: '',
  user: {
    id: '',
    avatarFile: '',
    email: '',
    name: '',
    department: '',
    title: '',
    role: '',
    isSsoUser: false,
    userTier: '',
    typeformRegistrationStatus: '',
    company: ''
  },
  invite: {
    id: '',
    name: '',
    email: '',
    organisation: ''
  },
  loadingCreateUser: false,
  isSuccessCreateUser: false,
  isSuccessUpdateUser: false,
  isSuccessChangePassword: false,
  createUserError: '',
  loadingUpdateUser: false,
  updateUserError: '',
  changePasswordError: '',
  isLoading: false,
  isSuccess: false,
  userInvites: [],
  userDepartments: [],
  getUserInvitesError: '',
  tokenNotFound: false,
  selectedCompanyId: '',
  CompanySetupModelPreviousState: false,
  isGuestUser: false,
  userProduct: '',
  wpNewUser: false,
  wpNewUserPaid: false,
  isTokenFound: false,
  isFetchingRegistrationStatus: false,
  isRegistrationStatusFetched: false
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearState: state => {
      state.loadingSignUp = false
      state.loadingSignIn = false
      state.loadingSignOut = false
      state.loadingRefreshToken = false
      state.loadingChangePassword = false
      state.loginError = ''
      state.resetSuccess = ''
      state.resetEmailSend = ''
      state.signupError = ''
      state.logoutError = ''
      state.refreshTokenError = ''
      state.loadingCreateUser = false
      state.isSuccessCreateUser = false
      state.createUserError = ''
      state.loadingUpdateUser = false
      state.isSuccessUpdateUser = false
      state.isSuccessChangePassword = false
      state.updateUserError = ''
      state.changePasswordError = ''
      state.isLoading = false
      state.isSuccess = false
      state.getUserInvitesError = ''
      state.isImageUploading = false
      state.isFetchingRegistrationStatus = false
      state.product = ''
      state.wpNewUser = ''
      state.invite = {
        id: '',
        name: '',
        email: '',
        organisation: '',
        role: '',
        isSsoUser: false
      }
    },
    setNewUserWPPaid: (state, action) => {
      if (action.payload) state.wpNewUserPaid = action.payload.status
    },
    resetTokenStatus: (state, action) => {
      state.isTokenFound = false
    },
    setNewUserWPLogin: (state, action) => {
      if (action.payload) state.wpNewUser = action.payload.status
    },
    resetUserProduct: (state, action) => {
      state.userProduct = ''
    },
    resetImageStatus: state => {
      state.isImageUploaded = false
    },
    resetState: () => initialState,
    updateSelectedCompany: (state, action) => {
      state.selectedCompanyId = action.payload.companyId
    },
    updateSetupModelPreviousStatus: (state, action) => {
      state.CompanySetupModelPreviousState = action.payload
    },
    updatePermission: (state, action) => {
      const { payload } = action
      const userInvitesList = [...state.userInvites]
      const userInviteItem = userInvitesList.find(user => user._id === payload.id)
      userInviteItem.permission = payload.value
      state.userInvites = userInvitesList

      return state
    },
    setIsGuestUser: state => {
      state.isGuestUser = true
    }
  },
  extraReducers: {
    [jwtSignIn.fulfilled]: (state, { payload }) => {
      state.loadingSignIn = false
      state.user = payload.user
      state.isLoggedIn = true

      // state.user.userTier = payload.user_tier || ''
      cookies.set('access_token', payload.tokens.access.token)
      cookies.set('refresh_token', payload.tokens.refresh.token)
    },
    [jwtSignIn.rejected]: (state, { payload }) => {
      state.loadingSignIn = false
      state.user = {}
      state.loginError = payload
    },
    [jwtSignIn.pending]: state => {
      state.loadingSignIn = true
    },

    [guestUserLogin.fulfilled]: (state, { payload }) => {
      state.loadingSignIn = false
      state.user = payload.user
      state.isLoggedIn = true

      state.isGuestUser = true
      // state.user.userTier = payload.user_tier || ''
      cookies.set('access_token', payload.tokens.access.token)
      cookies.set('refresh_token', payload.tokens.refresh.token)
    },
    [guestUserLogin.rejected]: (state, { payload }) => {
      state.loadingSignIn = false
      state.user = {}
      state.loginError = payload
    },
    [guestUserLogin.pending]: state => {
      state.loadingSignIn = true
    },

    [getUserByToken.fulfilled]: (state, { payload }) => {
      state.loadingSignIn = false
      state.user = payload.user
      state.isLoggedIn = true
      // state.user.userTier = payload.user_tier || ''
      state.isTokenFound = true

      cookies.set('access_token', payload.accessToken)
      cookies.set('refresh_token', payload.token.token)
    },
    [getUserByToken.rejected]: (state, { payload }) => {
      state.tokenNotFound = true

      state.loadingSignIn = false
      state.user = {}
      state.loginError = payload
    },
    [getUserByToken.pending]: state => {
      state.loadingSignIn = true
    },

    [googleSignIn.fulfilled]: (state, { payload }) => {
      state.loadingSignIn = false
      state.user = payload.user
      state.isLoggedIn = true
      // state.user.userTier = 'growth'
      // state.user.userTier = payload.user_tier || ''
      cookies.set('access_token', payload.tokens.access.token)
      cookies.set('refresh_token', payload.tokens.refresh.token)
    },
    [googleSignIn.rejected]: (state, { payload }) => {
      state.loadingSignIn = false
      state.user = {}
      state.loginError = payload
    },
    [googleSignIn.pending]: state => {
      state.loadingSignIn = true
    },

    [signUp.fulfilled]: (state, { payload }) => {
      state.loadingSignUp = false
      state.user = payload.user
      state.isLoggedIn = true
      // state.user.userTier = 'growth'
      // state.user.userTier = payload.user_tier || ''
      state.userProduct = payload.product || ''
      cookies.set('access_token', payload.tokens.access.token)
      cookies.set('refresh_token', payload.tokens.refresh.token)
      state.isTokenFound = true
    },
    [signUp.rejected]: (state, { payload }) => {
      state.loadingSignUp = false
      state.user = {}
      state.signupError = payload
    },
    [signUp.pending]: state => {
      state.loadingSignUp = true
    },

    [googleSignUp.fulfilled]: (state, { payload }) => {
      state.loadingSignUp = false
      state.user = payload.user
      state.isLoggedIn = true
      // state.user.userTier = 'growth'
      // state.user.userTier = payload.user_tier || ''
      cookies.set('access_token', payload.tokens.access.token)
      cookies.set('refresh_token', payload.tokens.refresh.token)
    },
    [googleSignUp.rejected]: (state, { payload }) => {
      state.loadingSignUp = false
      state.user = {}
      state.signupError = payload
    },
    [googleSignUp.pending]: state => {
      state.loadingSignUp = true
    },

    [signOut.fulfilled]: (state, { payload }) => {
      cookies.remove('access_token')
      cookies.remove('refresh_token')
      localStorage.removeItem('selectedCompany')

      return { ...initialState, logout: true }
    },
    [signOut.rejected]: (state, { payload }) => {
      // state.loadingSignOut = false
      // state.logoutError = payload || ''

      cookies.remove('access_token')
      cookies.remove('refresh_token')
      localStorage.removeItem('selectedCompany')

      return { ...initialState, logout: true }
    },
    [signOut.pending]: state => {
      state.loadingSignOut = true
    },

    [refreshTokens.fulfilled]: (state, { payload }) => {
      state.loadingRefreshToken = false
      cookies.set('access_token', payload.access.token)
      cookies.set('refresh_token', payload.refresh.token)
    },
    [refreshTokens.rejected]: (state, { payload }) => {
      state.loadingRefreshToken = false
      state.refreshTokenError = payload || ''
    },
    [refreshTokens.pending]: state => {
      state.loadingRefreshToken = true
    },

    [createUser.fulfilled]: (state, { payload }) => {
      state.loadingCreateUser = false
      state.isSuccessCreateUser = true
    },
    [createUser.rejected]: (state, { payload }) => {
      state.loadingCreateUser = false
      state.createUserError = payload || ''
    },
    [createUser.pending]: state => {
      state.loadingCreateUser = true
    },

    [updateUser.fulfilled]: (state, { payload }) => {
      state.isLoading = false

      state.isSuccessUpdateUser = true
      state.user.email = payload.email
      state.user.name = payload.name
      state.user.title = payload.title
    },
    [updateUser.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.updateUserError = payload || ''
    },
    [updateUser.pending]: state => {
      state.isLoading = true
    },

    [getUserInvites.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      if (payload.result.success) {
        state.userInvites = [...payload.result.records].map(user => ({
          ...user,
          permission: 'Standard'
        }))
        state.userDepartments = payload.result.adminDepartment
        state.isSuccess = true
      } else {
        state.userInvites = []
      }
    },
    [getUserInvites.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.getUserInvitesError = payload
      state.userInvites = []
    },
    [getUserInvites.pending]: state => {
      state.isLoading = true
    },

    [getInviteById.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.invite = { ...payload.record }
      state.isSuccess = true
    },
    [getInviteById.rejected]: (state, { payload }) => {
      state.isLoading = false
    },
    [getInviteById.pending]: state => {
      state.isLoading = true
    },

    [resetPassword.fulfilled]: (state, { payload }) => {
      state.resetEmailSend = payload.data
    },
    [resetPassword.rejected]: (state, { payload }) => {
      state.loadingSignIn = false
      state.user = {}
      state.loginError = payload
    },
    [resetPassword.pending]: state => {
      state.loadingSignIn = true
    },

    [changePassword.fulfilled]: (state, { payload }) => {
      state.isSuccessChangePassword = true
      state.loadingChangePassword = false
    },
    [changePassword.rejected]: (state, { payload }) => {
      state.loadingChangePassword = false
      state.changePasswordError = payload
    },
    [changePassword.pending]: state => {
      state.loadingChangePassword = true
    },

    [resetWithToken.fulfilled]: (state, { payload }) => {
      state.resetSuccess = payload
    },
    [resetWithToken.rejected]: (state, { payload }) => {
      state.loadingSignIn = false
      state.user = {}
      state.loginError = payload
    },
    [resetWithToken.pending]: state => {
      state.loadingSignIn = true
    },

    [uploadAvatar.fulfilled]: (state, { payload }) => {
      state.user.avatarFile = payload.result
      state.isImageUploading = false
      state.isImageUploaded = true
    },
    [uploadAvatar.rejected]: (state, { payload }) => {
      state.isImageUploading = false
    },
    [uploadAvatar.pending]: state => {
      state.isImageUploading = true
    },

    [getUserDepartment.fulfilled]: (state, { payload }) => {
      state.user.department = payload.department
    },
    [getUserDepartment.rejected]: (state, { payload }) => {},
    [getUserDepartment.pending]: state => {},
    [getPlatformOnobardingFormStatus.fulfilled]: (state, { payload }) => {
      state.user.typeformRegistrationStatus = payload.status
      state.user.company = payload.company
      state.isFetchingRegistrationStatus = false
      state.isRegistrationStatusFetched = true
    },
    [getPlatformOnobardingFormStatus.rejected]: (state, { payload }) => {
      state.isFetchingRegistrationStatus = false
    },
    [getPlatformOnobardingFormStatus.pending]: state => {
      state.isFetchingRegistrationStatus = true
    }
  }
})

export const {
  clearState,
  resetState,
  updatePermission,
  resetImageStatus,
  updateSelectedCompany,
  updateSetupModelPreviousStatus,
  setIsGuestUser,
  resetUserProduct,
  setNewUserWPLogin,
  setNewUserWPPaid,
  resetTokenStatus
} = authSlice.actions
export const authSelector = state => state.auth
export default authSlice.reducer
