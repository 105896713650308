import { createAsyncThunk } from '@reduxjs/toolkit'
import { SERVER_URL } from 'utils/API'
import { axiosInstance } from 'utils/API/AxiosConfig'

const getModelPeopleDataByID = createAsyncThunk('company/model/people/get', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.get(
      `${SERVER_URL}/company/getModelPeopleDataByID`,
      { params: { ...payload } },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const getModelPeopleDataByIDWithVersion = createAsyncThunk('company/model/people/get/version', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.get(
      `${SERVER_URL}/company/getModelPeopleDataByIDWithVersion`,
      { params: { ...payload } },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const getModelVendorDataByIDWithVersion = createAsyncThunk('company/model/vendor/get/version', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.get(
      `${SERVER_URL}/company/getModelVendorDataByIDWithVersion`,
      { params: { ...payload } },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const getModelVendorDataByID = createAsyncThunk('company/model/vendors/get', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.get(
      `${SERVER_URL}/company/getModelVendorDataByID`,
      { params: { ...payload } },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const getModelRevenueDataByID = createAsyncThunk('company/model/revenue/get', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.get(
      `${SERVER_URL}/company/getModelRevenueDataByID`,
      { params: { ...payload } },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const getModelSummaryDataByID = createAsyncThunk('company/model/summary/get', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.get(
      `${SERVER_URL}/company/getModelSummaryDataByID`,
      { params: { ...payload } },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const getEquityRaiseSummaryDataByID = createAsyncThunk('company/model/summary/get', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.get(
      `${SERVER_URL}/company/getEquityRaiseSummaryDataByID`,
      { params: { ...payload } },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const getModelDebtDataByID = createAsyncThunk('company/model/debt/get', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.get(
      `${SERVER_URL}/company/getModelDebtDataByID`,
      { params: { ...payload } },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const getModelPeopleDataUpdate = createAsyncThunk('company/model/people/data/update', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/company/getModelPeopleDataUpdate`,
      { params: { ...payload } },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

const getModelDataUpdateCells = createAsyncThunk('company/model/people/data/update/cells', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.post(
      `${SERVER_URL}/company/getModelDataUpdateCells`,
      { params: { ...payload } },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})

export {
  getModelDebtDataByID,
  getModelPeopleDataByID,
  getModelVendorDataByID,
  getModelRevenueDataByID,
  getModelSummaryDataByID,
  getEquityRaiseSummaryDataByID,
  getModelPeopleDataUpdate,
  getModelPeopleDataByIDWithVersion,
  getModelVendorDataByIDWithVersion,
  getModelDataUpdateCells
}
