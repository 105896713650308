import React, { useState, useReducer, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { v4 as uuidv4 } from 'uuid'

import TranchesAmount from './tranchesAmount'

import { makeStyles, withStyles } from '@material-ui/styles'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { useTheme } from '@emotion/react'

import { setTranchListData, setEquityRaiseInitialModelScreen, uiSelector } from 'store/ui/ui.slice'
import { resetState, companySelector, clearState } from 'store/company/company.slice'
import { modelsSelector } from 'store/models/models.slice'

const useStyles = makeStyles(theme => ({
  // muiDialog: {
  //   '& .MuiDialog-paper': {
  //     maxHeight: 'initial',
  //     height: 'calc(100% - 64px)'
  //   }
  // },
  // muiDialogContent: {
  //   [theme.breakpoints.up('sm')]: {
  //     overflowY: 'hidden',
  //     paddingRight: 0
  //   }
  // },
  // gridFillHeight: {
  //   [theme.breakpoints.up('sm')]: {
  //     height: '100%'
  //   }
  // },
  mainContainer: {
    textAlign: 'center',
    minWidth: '100%',
    background: '#F0F0F0'
  },
  DescTwoTranches: {
    fontWeight: '500',
    fontSize: '16px',
    color: '#091A36',
    marginTop: '20px',
    textAlign: 'center',
    marginBottom: '20px'
  },
  formContainer: {
    minWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

const tabList = [
  { id: 'tranch_one', displayText: '1ST', number: 1, desc: 'tranchOne' },
  { id: 'tranch_two', displayText: '2ND', number: 2, desc: 'tranchTwo' },
  { id: 'tranch_three', displayText: '3RD', number: 3, desc: 'tranchThree' },
  { id: 'tranch_four', displayText: '4TH', number: 4, desc: 'tranchFour' },
  { id: 'tranch_five', displayText: '5TH', number: 5, desc: 'tranchFive' }
]

const EquityRaiseTabTwo = ({ tranchesDataList, setDataInTranches, setCanNavigateNextScreen }) => {
  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { equityRaiseTranchCount, equityEditButtonClicked } = useSelector(uiSelector)
  const { equityAllocationList } = useSelector(companySelector)
  const [currentTranch, setCurrentTranch] = useState('')

  // const [tranchesDataList, setDataInTranches] = useState([
  //   { id: 'tranch_one', amount: '', date: '' },
  //   { id: 'tranch_two', amount: '', date: '' },
  //   { id: 'tranch_three', amount: '', date: '' },
  //   { id: 'tranch_four', amount: '', date: '' },
  //   { id: 'tranch_five', amount: '', date: '' },
  //   { id: '', amount: '', date: '' }
  // ])

  // dispatch(setTranchListData(tranchesDataList))
  const isValidList = []
  for (let i = 0; i < equityRaiseTranchCount; i += 1) {
    isValidList.push(tranchesDataList[i])
  }
  const isValidDate = isValidList.every(eachItem => eachItem.date !== '')

  const requireTabListItem = []

  for (let i = 0; i < equityRaiseTranchCount; i += 1) {
    requireTabListItem.push(tranchesDataList[i])
  }

  // const isValidLists = []
  // for (let i = 0; i < equityRaiseTranchCount; i += 1) {
  //   isValidLists.push(tranchesLists[i])
  // }

  // const isDateValid = isValidLists.every(eachItem => eachItem.date !== '')

  useEffect(() => {
    if (isValidDate) {
      setCanNavigateNextScreen(true)
    }
  }, [isValidDate])

  const onEnterEachAmountHandler = event => {
    setDataInTranches(prevState =>
      prevState.map(eachItem => {
        if (eachItem.id === event.target.id) {
          return { ...eachItem, amount: +parseInt(event.target.value.replace(/,/g, ''), 10) || 0 }
        }
        return eachItem
      })
    )
  }

  const onEnterEachDateHandler = event => {
    setDataInTranches(prevState =>
      prevState.map(eachItem => {
        if (eachItem.id === event.target.id) {
          return { ...eachItem, date: event.target.value }
        }
        return eachItem
      })
    )
  }

  // useEffect(() => {
  //   if (equityEditButtonClicked) {
  //     setDataInTranches(prevState =>
  //       prevState.map((eachItem, index) => ({
  //         ...eachItem,
  //         date: equityAllocationList[index].startDate,
  //         id: equityAllocationList[index].tranchName,
  //         amount: equityAllocationList[index].amount
  //       }))
  //     )
  //   }
  // }, [equityAllocationList])

  // const onClickNextPageHandler = event => {
  //   dispatch(setEquityRaiseInitialModelScreen(2))
  // }

  const getNoOfTranches = () =>
    requireTabListItem.map(eachItem => (
      <TranchesAmount
        key={eachItem.id}
        tabDetails={eachItem}
        equityRaiseTranchCount={equityRaiseTranchCount}
        onEnterEachAmountHandler={onEnterEachAmountHandler}
        onEnterEachDateHandler={onEnterEachDateHandler}
        setCurrentTranch={setCurrentTranch}
      />
    ))

  return (
    <Grid className={classes.mainContainer} direction="row" justifyContent="center" alignItems="center" container spacing={3}>
      <Grid style={{ minWidth: '100%' }} item container direction="column" justifyContent="center" alignItems="center">
        <Grid container justifyContent="center" alignItems="center" item xs={12}>
          <Typography className={classes.DescTwoTranches}>Tranches</Typography>
        </Grid>
        <form className={classes.formContainer}>
          <Grid style={{ marginBottom: '20px' }} item xs={12} container direction="column" justifyContent="center" alignItems="center">
            <Grid spacing={3} item container direction="column" justifyContent="center" alignItems="center">
              {getNoOfTranches()}
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
}

export default EquityRaiseTabTwo
