import React, { lazy } from 'react'

import MainLayout from 'layout/MainLayout'
import Loadable from 'ui-component/Loadable'
import AuthGuard from 'utils/route-guard/AuthGuard'

const Dashboard = Loadable(lazy(() => import('views/dashboard')))
const Models = Loadable(lazy(() => import('views/models')))
const Simulations = Loadable(lazy(() => import('views/simulations')))
const CompensationChanges = Loadable(lazy(() => import('views/CompensationChanges')))
const Incoming = Loadable(lazy(() => import('views/incoming')))
const Tasks = Loadable(lazy(() => import('views/tasks')))
const Simulation = Loadable(lazy(() => import('views/simulation')))
const Company = Loadable(lazy(() => import('views/company')))
const Profile = Loadable(lazy(() => import('views/profile')))
const Help = Loadable(lazy(() => import('views/help')))
const DebtFinancing = Loadable(lazy(() => import('views/debt-financing')))
const BurnRate = Loadable(lazy(() => import('views/burn-rate')))
const EquityRaise = Loadable(lazy(() => import('views/equity-raise')))
const HiringPlans = Loadable(lazy(() => import('views/hiring-plans')))
const QBIntehration = Loadable(lazy(() => import('views/pages/authentication/Qbintegration')))

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/dashboard',
      element: <Dashboard />
    },
    {
      path: '/budget',
      element: <Models />
    },
    // {
    //   path: '/simulations',
    //   element: <Simulations />
    // },
    {
      path: '/low-mid-high',
      element: <Simulations />
    },
    {
      path: '/people-simulation',
      element: <CompensationChanges />
    },
    {
      path: '/debt-financing',
      element: <DebtFinancing />
    },
    {
      path: '/burn-rate-reduction',
      element: <BurnRate />
    },
    {
      path: '/equity-raise',
      element: <EquityRaise />
    },
    {
      path: '/hiring-plans',
      element: <HiringPlans />
    },
    {
      path: '/Simulation',
      element: <Simulation />
    },
    {
      path: '/tasks',
      element: <Tasks />
    },
    {
      path: '/company',
      element: <Company />
    },
    {
      path: '/profile',
      element: <Profile />
    },
    {
      path: '/help',
      element: <Help />
    }
  ]
}

export default MainRoutes
