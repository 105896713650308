import { createSlice } from '@reduxjs/toolkit'
import { listSum, numberFormat } from 'utils/ui'
import { getDashboardData } from './dashboard.actions'

const initialState = {
  isLoading: false,
  isSuccess: false,
  netIncome: [],
  grossMargin: 0,
  grossProfit: [],
  cashOnHand: 0,
  runway: 0,
  revenue: [],
  expenses: [],
  latestCogs: 0,
  expenseDist: {},
  customerCac: 0,
  latestMom: 0,
  headcount: []
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    resetState: () => initialState
  },
  extraReducers: {
    [getDashboardData.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.getModelRevenueDataError = payload
    },
    [getDashboardData.pending]: state => {
      state.isLoading = true
    },

    [getDashboardData.fulfilled]: (state, { payload }) => {
      state.netIncome = payload.netIncome
      state.grossMargin = payload.grossMargin || 0
      state.grossProfit = payload.grossProfit
      state.cashOnHand = payload.cashOnHand
      state.runway = payload.runway
      state.revenue = payload.revenue
      state.expenses = payload.expenses
      state.latestCogs = payload.latestCogs
      state.expenseDist = payload.expenseDist
      state.customerCac = payload.customerCac
      state.latestMoM = payload.latestMoM || 0
      state.headcount = payload.headcount
      state.isLoading = false
      state.isSuccess = true
    }
  }
})

export const { resetState } = dashboardSlice.actions
export const dashboardSelector = state => state.dashboard
export default dashboardSlice.reducer
