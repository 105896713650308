import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import {
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
  Backdrop,
  CircularProgress,
  InputAdornment,
  TextField,
  Button
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import {
  uiSelector,
  setRegistrationSetupModelScreen,
  updateModalTabVisibility,
  toggleCompanySetupModal,
  setSelectedMenuItem
} from 'store/ui/ui.slice'

import cssClasses from 'ui-component/company-setup-modal/infoTab.module.css'

import companyInfoTabImage from 'assets/images/company_info_tab.png'
import mrgnLogo from 'assets/images/mrgin-blue-logo.png'

const WelcomePage = props => {
  const { isDemoCompany } = props
  const dispatch = useDispatch()

  const { registrationSetupModelScreen } = useSelector(uiSelector)

  const onClickNextButton = () => {
    dispatch(setRegistrationSetupModelScreen(registrationSetupModelScreen + 1))
  }

  return (
    <Grid container>
      <Grid
        // pt={3}
        item
        // xs={5}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'start',
          minHeight: '100vh',
          backgroundColor: '#FAF8F3',
          width: '40%'
        }}
      >
        <Grid
          item
          xs={9}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            marginTop: '79px',
            width: '100%'
          }}
        >
          <img src={mrgnLogo} alt="mrgn_logo" style={{ marginBottom: '80px' }} />
          <Typography className="left-contaniner-main-h" sx={{ fontSize: '32px' }}>
            "Finally, a solution that understands the unique challenges of small businesses. Intelligent Business Planning has given us a
            competitive edge."
          </Typography>
          <Typography className="founder-name">
            - Bob Ross, <span className="founder-of"> Founder of Happy Trees Inc.</span>
          </Typography>
          <img src={companyInfoTabImage} alt="company_info_image" style={{ height: 'auto', marginTop: '70px', width: '100%' }} />
          {/* <Typography className={cssClasses.information}>The information shown is for illustrative purposes only.</Typography> */}
        </Grid>
      </Grid>
      <Grid
        item
        // xs={7}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          flexDirection: 'column',
          width: '60%'
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '320px',
            minHeight: '85vh',
            maxHeight: '85vh'
            // marginTop: '79px'
          }}
        >
          <div style={{ position: 'relative', height: '100%' }}>
            <Typography className={cssClasses.tabRightHeading}>
              Welcome to mrgn; let’s take a minute to get to know one another.{' '}
            </Typography>
            <Typography
              sx={{
                color: '#0C0D0F',
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '125%',
                marginTop: '80px'
              }}
            >
              Don’t worry, we’ll connect your financial data after this brief intro.
            </Typography>
            <button type="button" onClick={onClickNextButton} className={cssClasses.formNextButton}>
              Begin
            </button>
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default WelcomePage
