import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'

// material-ui
import { makeStyles, useTheme } from '@material-ui/styles'
import { AppBar, Box, CssBaseline, Grid, Toolbar, Typography, useMediaQuery } from '@material-ui/core'

// third-party
import clsx from 'clsx'

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs'
import Header from './Header'
import Sidebar from './Sidebar'
import navigation from 'menu-items'
// import navigation from 'menu-items-guest'

import { drawerWidth } from 'store/constant'
import { SET_MENU } from 'store/actions'

// assets
import { IconChevronRight } from '@tabler/icons'
import CompanySetupModal from 'ui-component/company-setup-modal'
import DebtSetupModal from 'ui-component/debt-financing-modal'
import Customization from 'layout/Customization'
import IntroModal from 'ui-component/modal/IntroModal'
import PaymentModel from 'ui-component/payment/element'
import BurnRateInialModel from 'ui-component/burn-rate'
import EquityRaiseInialModel from 'ui-component/equity-raise'
import OnFreeTierModel from './OnFreeTierModel'
import Simulate from 'ui-component/burn-rate/simulate'
import ExcelImporTabModel from 'ui-component/company-setup-modal/excelImportTab'
import InsightsPopup from 'views/models/InsightsPopup'
import CompanySetupCheckList from './Header/CompanySetupCheckList'
import { toggleCompanySetupModal, uiSelector } from 'store/ui/ui.slice'
import { companySelector } from 'store/company/company.slice'
import ModalCongratulationScreen from 'ui-component/company-setup-modal/ModalCongratulationScreen'
import LogoSection from './LogoSection'
import { Divider } from 'antd'

// style constant
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    backgroundColor: theme.palette.background.header
  },
  appBarWidth: {
    transition: theme.transitions.create('width'),
    backgroundColor: theme.palette.background.header
  },
  content: {
    marginTop: '120px',
    ...theme.typography.mainContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    padding: '16px',
    [theme.breakpoints.up('md')]: {
      // padding: '32px',
      marginLeft: -(drawerWidth - 80),
      width: `100%`
    },
    [theme.breakpoints.down('md')]: {
      // marginLeft: '20px',
      width: `100%`
      // padding: '16px'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px',
      width: `calc(100% - ${drawerWidth}px)`,
      // padding: '16px',
      marginRight: '10px'
    }
  },
  contentShift: {
    marginTop: '10px',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px'
    }
  },
  terms: {
    color: ' #3B424C',
    fontFamily: 'Helvetica',
    fontSize: '12px',
    fontWeight: '400'
  },
  termsSpan: {
    color: '#29A364',
    fontFamily: 'Helvetica',
    fontSize: '12px',
    fontWeight: '400'
  },
  onboardingLink: {
    color: '#F3CD04',
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}))

// ===========================|| MAIN LAYOUT ||=========================== //

const MainLayout = () => {
  const classes = useStyles()
  const theme = useTheme()
  const location = useLocation()
  const navigate = useNavigate()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const { companySetupModalVisible, companyInfoScreen } = useSelector(uiSelector)
  const { introModal, incompleteCompanyId, isWelcomeScreenOpen, isProductOnboardingComplete } = useSelector(companySelector)
  // Handle left drawer
  const leftDrawerOpened = useSelector(state => state.customization.opened)
  const dispatch = useDispatch()
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened })
  }

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/dashboard')
    }
  }, [location.pathname])
  React.useEffect(() => {
    dispatch({ type: SET_MENU, opened: !matchDownMd })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd])

  // useEffect(() => {
  //   if (introModal && !companySetupModalVisible) {
  //     dispatch(toggleCompanySetupModal())
  //   }
  // }, [])

  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    if (location.pathname === '/company' && companyInfoScreen === 3) {
      setIsVisible(false)
    } else {
      setIsVisible(true)
    }
  }, [companyInfoScreen, location.pathname])

  return (
    <div>
      {/* Finish onboarding banner */}
      {!isProductOnboardingComplete && (
        <div
          style={{
            backgroundColor: 'rgb(25, 80, 57)',
            height: '50px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '16px',
            fontWeight: '600',
            paddingLeft: `${drawerWidth}px`
          }}
        >
          <Box className={classes.onboardingLink} onClick={() => dispatch(toggleCompanySetupModal(true))}>
            Complete Product Onboarding
          </Box>
        </div>
      )}
      <Grid container sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <div style={{ minHeight: '100vh', width: '20%' }}>
          {/* drawer */}
          <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />
        </div>
        <div style={{ minHeight: '100vh', width: '80%' }}>
          <CssBaseline />
          {/* header */}
          {/* <AppBar
          enableColorOnDark
          position="fixed"
          color="inherit"
          elevation={0}
          className={leftDrawerOpened ? classes.appBarWidth : classes.appBar}
          sx={{ display: 'flex', alignItems: 'flex-end' }}
        > */}
          <Toolbar>
            <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
            {/* <CompanySetupCheckList /> */}
            {/* <SetupDashboard /> */}
          </Toolbar>
          {/* </AppBar> */}

          <main
            className={clsx([
              classes.content,
              {
                [classes.contentShift]: leftDrawerOpened
              }
            ])}
          >
            {/* breadcrumb */}
            <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />

            {/* {isVisible && <OnFreeTierModel />} */}
            <Outlet />
            <CompanySetupModal />
            <ModalCongratulationScreen isWelcomeScreenOpen={isWelcomeScreenOpen} />
            {/* <IntroModal /> */}
            <DebtSetupModal />
            <BurnRateInialModel />
            <EquityRaiseInialModel />
            <InsightsPopup />
            <Simulate />
            <PaymentModel />
            {/* <ExcelImporTabModel /> */}
          </main>
          <div>
            <CssBaseline />
            <h1 className={classes.terms}>
              For more information, see our{' '}
              <span className={classes.termsSpan}>
                {' '}
                <a style={{ textDecoration: 'none' }} href="https://mrgn.ai/terms-of-service/" target="_blank" rel="noreferrer">
                  {' '}
                  Terms of Use,{' '}
                </a>
              </span>{' '}
              and{' '}
              <span className={classes.termsSpan}>
                <a style={{ textDecoration: 'none' }} href="https://mrgn.ai/terms-of-service/" target="_blank" rel="noreferrer">
                  Privacy Policy{' '}
                </a>
              </span>
              .
            </h1>
          </div>
        </div>
      </Grid>
    </div>
  )
}

export default MainLayout
