import React, { useRef, useState, useImperativeHandle, forwardRef } from 'react'

import cssClasses from './infoTab.module.css'
import { Divider, Grid, IconButton, InputBase, Paper, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { setCompanySetupModalScreen, setSelectedMenuItem, toggleCompanySetupModal, uiSelector } from 'store/ui/ui.slice'
import noDataImage from 'assets/images/noDataImage.png'
import ProServicesSideDrawer from './ProServicesSideDrawer'
import { companySelector, setIsWizardVisible } from 'store/company/company.slice'
import SearchIcon from '@material-ui/icons/Search'
import Adjustments from '../../assets/images/icons/adjustments.svg'
import AddIcon from '@material-ui/icons/Add'
import EditPencilIcon from '../../assets/images/icons/EditPencilIcon.svg'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { clearState, resetState } from 'store/auth/auth.slice'
import { finishCompanyCreate, getCompaniesByUser } from 'store/company/company.actions'
import { MENU_OPEN } from 'store/actions'
import { useNavigate } from 'react-router'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import dayjs from 'dayjs'

const ProServices = forwardRef((props, ref) => {
  const { isDemoCompany } = props
  const dispatch = useDispatch()
  const { companySetupModalScreen } = useSelector(uiSelector)
  const [isAddButtonClicked, setAddButtonClicked] = useState(false)
  const [searchInputs, setSearchInputs] = useState('')
  const [editItem, setEditItem] = useState({})
  const [isEditButtonClicked, setEditButtonClicked] = useState(false)
  const [editProdItem, setEditProdItem] = useState({})
  const [expandItemId, setIsExpandItemId] = useState('')
  const navigate = useNavigate()

  const {
    isLoading,
    isSuccess,
    isSuccessCompanyCreate,
    updateCompanyError,
    id,
    clientList,
    departmentList,
    selectedCompany,
    currentlyActiveCompanyId,
    vendorList,
    incompleteCompanies,
    productList,
    isDemo
  } = useSelector(companySelector)

  useImperativeHandle(ref, () => ({
    handleNext() {
      // if (clientList.length === 0 || (formRef.current.values.name && clientList.length > 0)) {
      //   if (formRef.current.isValid) {
      //     setNotify(true)
      //     setNext(true)
      //   } else {
      //     dispatch(clearState())
      //     if (formRef.current) formRef.current.handleSubmit()
      //     setNext(true)
      //   }
      // } else {
      // setNext(false)
      dispatch(clearState())
      const data = {
        companyId: id,
        isComplete: true
        // fromRevenue: true
      }
      dispatch(getCompaniesByUser())
      dispatch(setSelectedMenuItem(''))
      dispatch(setIsWizardVisible(false))
      if (isDemo) {
        dispatch(toggleCompanySetupModal(false))
        dispatch(setCompanySetupModalScreen(0))
        dispatch(resetState())
        // dispatch(getCompaniesByUser())
      } else {
        // dispatch(toggleCompanySetupModal(false))
        dispatch(finishCompanyCreate(data))
        dispatch(setCompanySetupModalScreen(companySetupModalScreen + 3))
        // navigate(`/budget?cid=${selectedCompany}`)
        // dispatch({ type: MENU_OPEN, id: 'models' })

        // if (isAddRevenueVisible) setAddRevenueVisible(false)
      }
      // }
    }
  }))

  const onEnterFilterBox = event => {
    setSearchInputs(event.target.value)
  }

  const filteredClientList = clientList.filter(eachItem => {
    if (searchInputs.toLocaleLowerCase() === '') {
      return eachItem
    }
    return eachItem.name.toLocaleLowerCase().includes(searchInputs.toLocaleLowerCase())
  })

  const filteredProductList = productList.filter(eachItem => {
    if (searchInputs.toLocaleLowerCase() === '') {
      return eachItem
    }
    return eachItem.name.toLocaleLowerCase().includes(searchInputs.toLocaleLowerCase())
  })

  const ClientCard = ({ client }) => {
    const [expand, setExpand] = useState(false)
    const d = new Date(client.startDate)
    const startedDate = dayjs(d).format('YYYY/MM/DD')
    return (
      <div style={{ minWidth: '290px' }} className={cssClasses.teamCard}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <p className={cssClasses.cardName}>Client</p>
          <button
            disabled={isDemoCompany}
            style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}
            type="button"
            onClick={() => {
              setEditItem(client)
              setEditButtonClicked(true)
              setAddButtonClicked(true)
            }}
          >
            <img src={EditPencilIcon} alt="edit" />
          </button>
        </div>
        <div style={{ width: '98%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <p className={cssClasses.cardNested}>Client name</p>
          <IconButton onClick={() => setExpand(prev => !prev)}>
            {expand ? (
              <KeyboardArrowUp
                sx={{ width: '18px', height: '18px' }}
                // onClick={() => setExpand(prev => !prev)}
                style={{ cursor: 'pointer' }}
              />
            ) : (
              <KeyboardArrowDown
                sx={{ width: '18px', height: '18px' }}
                // onClick={() => setExpand(prev => !prev)}
                style={{ cursor: 'pointer' }}
              />
            )}
          </IconButton>
        </div>
        <p className={cssClasses.busineesTypeCardItemName}>{client.name}</p>
        {expand && (
          <Grid container>
            <Grid item xs={6} mt={1}>
              <div>
                <Typography className={cssClasses.cardAccount}>Growth increment </Typography>
                <Typography className={cssClasses.cardAccountName}>{client.growthIncrement}</Typography>
              </div>
            </Grid>
            <Grid item xs={6} mt={1}>
              <div>
                <Typography className={cssClasses.cardAccount}>Growth percentage</Typography>
                <Typography className={cssClasses.cardAccountName}>{client.growthRate}</Typography>
              </div>
            </Grid>
            <Grid item xs={6} mt={1}>
              <div>
                <Typography className={cssClasses.cardAccount}>Start date </Typography>
                <Typography className={cssClasses.cardAccountName}>{startedDate}</Typography>
              </div>
            </Grid>
            <Grid item xs={6} mt={1}>
              <div>
                <Typography className={cssClasses.cardAccount}>Salary</Typography>
                <Typography className={cssClasses.cardAccountName}>{client.startingRevenue}</Typography>
              </div>
            </Grid>
          </Grid>
        )}
      </div>
    )
  }

  const ProductCard = ({ product }) => {
    const [prodExpand, setProdExpand] = useState(false)

    return (
      <div style={{ minWidth: '290px' }} className={cssClasses.teamCard}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <p className={cssClasses.cardName}>Product</p>
          <button
            disabled={isDemoCompany}
            style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}
            type="button"
            onClick={() => {
              setEditProdItem(product)
              setEditButtonClicked(true)
              setAddButtonClicked(true)
            }}
          >
            <img src={EditPencilIcon} alt="edit" />
          </button>
        </div>
        <div style={{ width: '98%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <p className={cssClasses.cardNested}>Product name</p>
          <IconButton onClick={() => setProdExpand(prev => !prev)}>
            {prodExpand ? (
              <KeyboardArrowUp
                sx={{ width: '18px', height: '18px' }}
                // onClick={() => setExpand(prev => !prev)}
                style={{ cursor: 'pointer' }}
              />
            ) : (
              <KeyboardArrowDown
                sx={{ width: '18px', height: '18px' }}
                // onClick={() => setExpand(prev => !prev)}
                style={{ cursor: 'pointer' }}
              />
            )}
          </IconButton>
        </div>
        <p className={cssClasses.busineesTypeCardItemName}>{product.name}</p>
        {prodExpand && (
          <Grid container>
            <Grid item xs={6} mt={1}>
              <div>
                <Typography className={cssClasses.cardAccount}>Cost (CoGS) </Typography>
                <Typography className={cssClasses.cardAccountName}>{product.unitCOGS}</Typography>
              </div>
            </Grid>
            <Grid item xs={6} mt={1}>
              <div>
                <Typography className={cssClasses.cardAccount}>Retail price</Typography>
                <Typography className={cssClasses.cardAccountName}>{product.price}</Typography>
              </div>
            </Grid>
          </Grid>
        )}
      </div>
    )
  }

  return (
    <div style={{ width: '800px' }}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          alignItems: 'flex-start',
          // flexShrink: 0,
          // gap: '12px',
          justifyContent: 'start',
          paddingLeft: '50px',
          flexWrap: 'wrap'
        }}
      >
        {' '}
        {clientList.length === 0 ? (
          <div style={{ marginTop: '70px', width: '100%' }}>
            <p className={cssClasses.stepText}>Step 5</p>
            <h1 className={cssClasses.tabHeading}>Now let’s choose which revenue model. </h1>
            <p className={cssClasses.tabDesc}>Tell us some basic information about your company. </p>
            <Divider style={{ color: '#D8D9DB', height: '2px' }} />
            <img style={{ marginTop: '20px' }} src={noDataImage} alt="No Data" />
            <div className={cssClasses.noDataContainer}>
              <h1>No Data</h1>
              <p>
                You haven’t added any data yet to support your revenue model, <br /> select the button below to start.
              </p>
              <button onClick={() => setAddButtonClicked(true)} type="button">
                {' '}
                <span style={{ marginRight: '5px' }}>+</span> Add
              </button>
            </div>
          </div>
        ) : (
          <div style={{ width: '100%', marginTop: '70px', flexWrap: 'wrap' }}>
            <p className={cssClasses.stepText}>Step 5</p>
            <h1 className={cssClasses.tabHeading}>Let’s get down to business. </h1>
            <p className={cssClasses.tabDesc}>Select below how you’d like to being to tell your story. </p>
            <Divider style={{ color: '#D8D9DB', height: '2px' }} />{' '}
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginTop: '30px' }}>
              <Paper
                component="form"
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  width: '360px',
                  height: '44px',
                  border: '1px solid #3B424C',
                  borderRadius: '4px'
                }}
              >
                <IconButton sx={{ p: '10px' }} aria-label="menu">
                  <SearchIcon />
                </IconButton>
                <InputBase
                  disabled={isDemoCompany}
                  onChange={onEnterFilterBox}
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search by..."
                  inputProps={{ 'aria-label': 'search google maps' }}
                />
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                  <img src={Adjustments} alt="Edit" />
                </IconButton>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              </Paper>
              <button
                disabled={isDemoCompany}
                style={{
                  height: '44px',
                  padding: '12px 16px',
                  borderRadius: '4px',
                  border: 'solid 1px #29A364',
                  color: '#29A364',
                  fontSize: '16px',
                  background: '#F2F7F4',
                  fontWeight: 500,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  marginLeft: '20px'
                }}
                onClick={() => {
                  setAddButtonClicked(true)
                }}
                type="button"
              >
                <span style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                  <AddIcon />
                  <span>Add</span>
                </span>
              </button>
            </div>
            <div style={{ width: '100%', marginTop: '20px', display: 'flex', flexWrap: 'wrap' }}>
              {filteredClientList.map((client, index) => (
                <div style={{ width: '237px', marginRight: '60px', marginTop: '10px' }}>
                  <ClientCard key={index} client={client} />
                </div>
              ))}
              {filteredProductList.map((product, index) => (
                <div style={{ width: '237px', marginRight: '60px', marginTop: '10px' }}>
                  <ProductCard key={index} product={product} />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <ProServicesSideDrawer
        isAddButtonClicked={isAddButtonClicked}
        setAddButtonClicked={setAddButtonClicked}
        isEditButtonClicked={isEditButtonClicked}
        setEditButtonClicked={setEditButtonClicked}
        editItem={editItem}
        setEditItem={setEditItem}
        setEditProdItem={setEditProdItem}
        editProdItem={editProdItem}
      />
    </div>
  )
})

export default ProServices
