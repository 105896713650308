import React from 'react'

import { Button, Divider, Grid, IconButton } from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { useSelector } from 'react-redux'
import { companySelector } from 'store/company/company.slice'

import cssClasses from '../infoTab.module.css'
import dayjs from 'dayjs'

const ExpensesList = props => {
  const { addedItem, setAddedItem, editVendor, editVendorCancel, setAddButtonClicked } = props
  const { vendorList } = useSelector(companySelector)
  const getParentName = parentId => 'test'
  // const parent = vendorList.find(eachItem => eachItem._id === parentId)
  // return parent?.name

  const getDetailsParent = (nestedUnder, index) => {
    const parent = vendorList.find(eachItem => eachItem._id === nestedUnder)

    return (
      <>
        <li key={`parent${index}`} style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
          {/* <Grid item xs={12}> */}
          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {/* <h1 className={cssClasses.listCardItemh}>Parent</h1> */}
            {/* <IconButton
              onClick={() => {
                editVendor(parent)

                setAddedItem([])
              }}
            >
              <MoreHorizIcon />
            </IconButton> */}
          </Grid>
          <Grid item xs={6}>
            <h1 className={cssClasses.listCardh}>Account</h1>
            <p className={cssClasses.listCardp}>{parent?.name}</p>
          </Grid>
          <Grid item xs={6}>
            <h1 className={cssClasses.listCardh}>Nested under</h1>
            <p className={cssClasses.listCardp}>N/A</p>
          </Grid>
          <Grid item xs={6}>
            <h1 className={cssClasses.listCardh}>Department</h1>
            <p className={cssClasses.listCardp}>{parent?.department ? parent.department : 'N/A'}</p>
          </Grid>
          <Grid item xs={6}>
            <h1 className={cssClasses.listCardh}>HR Related</h1>
            <p className={cssClasses.listCardp}>{parent?.hrRelated ? 'Yes' : 'No'}</p>
          </Grid>
          <Grid item xs={6}>
            <h1 className={cssClasses.listCardh}>Taxed by govt</h1>
            <p className={cssClasses.listCardp}>{parent?.govtTaxed ? 'Yes' : 'No'}</p>
          </Grid>
          <Grid item xs={6}>
            <h1 className={cssClasses.listCardh}>Frequency</h1>
            <p className={cssClasses.listCardp}>{parent?.frequency ? parent.frequency : 'N/A'}</p>
          </Grid>
          <Grid item xs={6}>
            <h1 className={cssClasses.listCardh}>Start date</h1>
            <p className={cssClasses.listCardp}>{parent?.startDate ? dayjs(parent.startDate).format('DD.MM.YY') : 'N/A'}</p>
          </Grid>
          <Grid item xs={6}>
            {/* {console.log(parent.endDate, 'parent?.endDate')} */}
            <h1 className={cssClasses.listCardh}>End date</h1>
            <p className={cssClasses.listCardp}>{parent?.endDate ? dayjs(parent.endDate).format('DD.MM.YY') : 'N/A'}</p>
          </Grid>
          <Grid item xs={6}>
            <h1 className={cssClasses.listCardh}>Amount</h1>
            <p className={cssClasses.listCardp}>{parent?.amount ? parent.amount : 'N/A'}</p>
          </Grid>
          <Grid item xs={6}>
            <h1 className={cssClasses.listCardh}>CoGS / CoR</h1>
            <p className={cssClasses.listCardp}>{parent?.cogs ? 'Yes' : 'No'}</p>
          </Grid>
          {/* </Grid> */}
        </li>
        <Divider style={{ marginTop: '10px', color: 'rgba(9, 165, 127, 0.15)' }} />
      </>
    )
  }

  const getDetailsChild = (eachItem, index) => (
    <li key={`child${index}`} style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
      {/* <Grid item xs={12}> */}
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h1 className={cssClasses.listCardItemh}>Nested #1</h1>
        <IconButton
          onClick={() => {
            // setSelectedTeam(selectedTeam)
            editVendor(eachItem)
            // setEmpEditButtonClicked(true)
            setAddedItem([])
          }}
        >
          <MoreHorizIcon />
        </IconButton>
      </Grid>
      <Grid item xs={6}>
        <h1 className={cssClasses.listCardh}>Account</h1>
        <p className={cssClasses.listCardp}>{eachItem.name}</p>
      </Grid>
      <Grid item xs={6}>
        <h1 className={cssClasses.listCardh}>Nested under</h1>
        <p className={cssClasses.listCardp}>{getParentName(eachItem.nestedUnder)}</p>
      </Grid>
      <Grid item xs={6}>
        <h1 className={cssClasses.listCardh}>Department</h1>
        <p className={cssClasses.listCardp}>{eachItem.department}</p>
      </Grid>
      <Grid item xs={6}>
        <h1 className={cssClasses.listCardh}>HR Related</h1>
        <p className={cssClasses.listCardp}>{eachItem.hrRelated ? 'Yes' : 'No'}</p>
      </Grid>
      <Grid item xs={6}>
        <h1 className={cssClasses.listCardh}>Taxed by govt</h1>
        <p className={cssClasses.listCardp}>{eachItem.govtTaxed ? 'Yes' : 'No'}</p>
      </Grid>
      <Grid item xs={6}>
        <h1 className={cssClasses.listCardh}>Frequency</h1>
        <p className={cssClasses.listCardp}>{eachItem.frequency}</p>
      </Grid>
      <Grid item xs={6}>
        <h1 className={cssClasses.listCardh}>Start date</h1>
        <p className={cssClasses.listCardp}>{dayjs(eachItem.startDate).format('DD.MM.YY')}</p>
      </Grid>
      <Grid item xs={6}>
        <h1 className={cssClasses.listCardh}>End date</h1>
        <p className={cssClasses.listCardp}>{eachItem.endDate ? dayjs(eachItem.endDate).format('DD.MM.YY') : 'N/A'}</p>
      </Grid>
      <Grid item xs={6}>
        <h1 className={cssClasses.listCardh}>Amount</h1>
        <p className={cssClasses.listCardp}>{eachItem.amount}</p>
      </Grid>
      <Grid item xs={6}>
        <h1 className={cssClasses.listCardh}>CoGS / CoR</h1>
        <p className={cssClasses.listCardp}>{eachItem.cogs ? 'Yes' : 'No'}</p>
      </Grid>
      {/* </Grid> */}
    </li>
  )

  const getActualDate = date => new Date(date).toLocaleDateString('en-US')

  return (
    <div style={{ width: '100%' }}>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p className={cssClasses.drawerListHeading}>Expense Category</p>
        <Button onClick={() => setAddedItem([])} className={cssClasses.transparentAddButton} variant="text">
          {' '}
          <span style={{ marginRight: '10px' }}>+</span>Add
        </Button>
      </div>
      <Divider style={{ marginTop: '10px', color: 'rgba(9, 165, 127, 0.15)' }} />
      <ul style={{ width: '100%', padding: 0, margin: 0, listStyle: 'none' }}>
        {addedItem?.map((eachItem, index) => (
          <>
            {eachItem.nestedUnder !== '' && getDetailsParent(eachItem.nestedUnder, index)}
            {eachItem.nestedUnder !== '' && getDetailsChild(eachItem, index)}
            {eachItem.nestedUnder === '' && (
              <li key={index} style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  {/* <h1 className={cssClasses.listCardItemh}>Parent</h1> */}
                  <IconButton
                    onClick={() => {
                      editVendor(eachItem)
                      setAddedItem([])
                    }}
                  >
                    <MoreHorizIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={6}>
                  <h1 className={cssClasses.listCardh}>Account</h1>
                  <p className={cssClasses.listCardp}>{eachItem.name}</p>
                </Grid>
                <Grid item xs={6}>
                  <h1 className={cssClasses.listCardh}>Nested under</h1>
                  <p className={cssClasses.listCardp}>N/A</p>
                </Grid>
                <Grid item xs={6}>
                  <h1 className={cssClasses.listCardh}>Department</h1>
                  <p className={cssClasses.listCardp}>{eachItem.department}</p>
                </Grid>
                <Grid item xs={6}>
                  <h1 className={cssClasses.listCardh}>HR Related</h1>
                  <p className={cssClasses.listCardp}>{eachItem.hrRelated ? 'Yes' : 'No'}</p>
                </Grid>
                <Grid item xs={6}>
                  <h1 className={cssClasses.listCardh}>Taxed by govt</h1>
                  <p className={cssClasses.listCardp}>{eachItem.govtTaxed ? 'Yes' : 'No'}</p>
                </Grid>
                <Grid item xs={6}>
                  <h1 className={cssClasses.listCardh}>Frequency</h1>
                  <p className={cssClasses.listCardp}>{eachItem.frequency}</p>
                </Grid>
                <Grid item xs={6}>
                  <h1 className={cssClasses.listCardh}>Start date</h1>
                  <p className={cssClasses.listCardp}>{getActualDate(eachItem.startDate)}</p>
                </Grid>
                <Grid item xs={6}>
                  <h1 className={cssClasses.listCardh}>End date</h1>
                  <p className={cssClasses.listCardp}>{eachItem.endDate ? getActualDate(eachItem.endDate) : 'N/A'}</p>
                </Grid>
                <Grid item xs={6}>
                  <h1 className={cssClasses.listCardh}>Amount</h1>
                  <p className={cssClasses.listCardp}>{eachItem.amount}</p>
                </Grid>
                <Grid item xs={6}>
                  <h1 className={cssClasses.listCardh}>CoGS / CoR</h1>
                  <p className={cssClasses.listCardp}>{eachItem.cogs ? 'Yes' : 'No'}</p>
                </Grid>
              </li>
            )}
          </>
        ))}
      </ul>
      <Divider sx={{ marginTop: '230px', marginBottom: '10px' }} />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button
          onClick={() => {
            setAddedItem([])
          }}
          className={cssClasses.wizardCancelButton}
          type="button"
        >
          Cancel
        </button>
        <button
          onClick={() => {
            setAddButtonClicked(false)
            setAddedItem([])
          }}
          type="button"
          className={cssClasses.wizardSaveButton}
        >
          Save
        </button>
      </div>
    </div>
  )
}

export default ExpensesList
