import { createSlice } from '@reduxjs/toolkit'
import {
  createCheckOutSession,
  createPortalSession,
  getWordPressPaymentIntent,
  createPortalSessionWordPress,
  getUserInvoices,
  addPromoCode
} from './payment.action'

const initialState = {
  plan: '',
  status: '',
  subscriptionId: '',
  clientSecret: '',
  earlyBirdStatus: false,
  publishableKey: '',
  sessionError: '',
  error: '',
  paymentSatus: '',
  subscriptionStatus: '',
  isLoadingPayment: false,
  productPrice: 0,
  currentCompany: '',
  invoiceList: [],
  isLoading: false,
  promoCode: '',
  promoErrorCode: false,
  userSelectedPlan: ''
}

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    resetState: () => initialState,
    resetcurrentCompanypaid: state => {
      state.currentCompany = ''
    },
    clearstate: state => {
      state.sessionError = ''
      state.status = ''
      state.paymentSatus = ''
      state.subscriptionStatus = false
    },
    clearpromo: state => {
      state.promoCode = ''
      state.promoErrorCode = false
    },
    setUserSelectedPlan: (state, action) => {
      state.userSelectedPlan = action.payload
    }
  },
  extraReducers: {
    [createCheckOutSession.fulfilled]: (state, { payload }) => {
      state.subscriptionId = payload.subscriptionId || ''
      state.clientSecret = payload.clientSecret || ''
      state.earlyBirdStatus = payload.earlyBird || ''
      state.productPrice = payload.productPrice || 0
      state.publishableKey = payload.publishableKey || ''
      state.plan = payload.plan || ''
      state.status = payload.status || ''
      state.subscriptionStatus = payload.subscriptionStatus || ''
      state.isLoadingPayment = false
    },
    [createCheckOutSession.rejected]: (state, { payload }) => {
      state.sessionError = payload
      state.isLoadingPayment = false
    },
    [createCheckOutSession.pending]: state => {
      state.isLoadingPayment = true
    },

    [getWordPressPaymentIntent.fulfilled]: (state, { payload }) => {
      state.subscriptionId = payload.subscriptionId || ''
      state.clientSecret = payload.clientSecret || ''
      state.publishableKey = payload.publishableKey || ''
      state.plan = payload.plan || ''
      state.status = payload.status || ''
      state.subscriptionStatus = payload.subscriptionStatus || ''
      state.isLoadingPayment = false
    },
    [getWordPressPaymentIntent.rejected]: (state, { payload }) => {
      state.sessionError = payload
      state.isLoadingPayment = false
    },
    [getWordPressPaymentIntent.pending]: state => {
      state.isLoadingPayment = true
    },

    [createPortalSession.fulfilled]: (state, { payload }) => {
      state.paymentStatus = payload.status
      state.plan = payload.plan
      state.currentCompany = payload.currentCompany
    },
    [createPortalSession.rejected]: (state, { payload }) => {
      state.error = payload
    },
    [createPortalSession.pending]: state => {},

    [createPortalSessionWordPress.fulfilled]: (state, { payload }) => {
      state.paymentStatus = payload.status
      state.plan = payload.plan
    },
    [createPortalSessionWordPress.rejected]: (state, { payload }) => {
      state.error = payload
    },
    [createPortalSessionWordPress.pending]: state => {},

    [getUserInvoices.fulfilled]: (state, { payload }) => {
      state.invoiceList = payload.invoices
      state.isLoading = false
    },
    [getUserInvoices.pending]: state => {
      state.isLoading = true
    },
    [getUserInvoices.rejected]: state => {
      state.isLoading = false
    },

    [addPromoCode.fulfilled]: (state, { payload }) => {
      state.promoCode = payload.promoCode
      state.isLoading = false

      state.subscriptionId = payload.subscriptionId || ''
      state.clientSecret = payload.clientSecret || ''
      state.earlyBirdStatus = payload.earlyBird || ''
      state.productPrice = payload.productPrice || 0
      state.publishableKey = payload.publishableKey || ''
      state.promoErrorCode = false
    },
    [addPromoCode.pending]: state => {
      state.isLoading = true
      state.promoErrorCode = false
    },
    [addPromoCode.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.promoErrorCode = true
    }
  }
})

export const { resetState, clearstate, resetcurrentCompanypaid, clearpromo, setUserSelectedPlan } = paymentSlice.actions
export const paymentSelector = state => state.payment
export default paymentSlice.reducer
