import { getQbIntegration, createBossInsightAccount } from './quickbook.action'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  quickBookError: '',
  QbCompanies: [],
  isLoading: false,
  createdCompanyId: '',
  isImportSuccess: false,
  adminEmail: '',
  accountDomain: '',
  embeddToken: '',
  isTokenFound: false
}

export const quickbookSlice = createSlice({
  name: 'quickbook',
  initialState,
  reducers: {
    clearState: state => {
      state.isLoading = false
      state.isImportSuccess = false
      state.quickBookError = ''
    },
    resetQuickBookStatus: state => {
      state.createdCompanyId = ''
    },
    resetToken: state => {
      state.isTokenFound = false
    }
  },
  extraReducers: {
    [getQbIntegration.fulfilled]: (state, { payload }) => {
      state.quickBookAuthStatus = true
      if (payload.success === true && payload.error === null) {
        state.isLoading = false
        state.createdCompanyId = payload.companyId
        state.isImportSuccess = true
      } else {
        state.quickBookError = payload.error
      }
    },
    [getQbIntegration.rejected]: (state, { payload }) => {
      state.quickBookAuthStatus = true
      state.quickBookError = 'Some Error occured'
      state.isImportSuccess = false
    },
    [getQbIntegration.pending]: state => {
      state.isImportSuccess = false
    },

    [createBossInsightAccount.fulfilled]: (state, { payload }) => {
      state.adminEmail = payload.adminEmail
      state.accountDomain = payload.accountDomain
      state.embeddToken = payload.embeddToken
      state.isTokenFound = true
    },
    [createBossInsightAccount.rejected]: (state, { payload }) => {
      state.isTokenFound = false
    },
    [createBossInsightAccount.pending]: state => {}
  }
})

export const { clearState, resetQuickBookStatus, resetToken } = quickbookSlice.actions
export const quickbookSelector = state => state.quickbook
export default quickbookSlice.reducer
