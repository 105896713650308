import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react'
import cssClasses from './infoTab.module.css'

import DoneIcon from '@material-ui/icons/Done'
import { useDispatch, useSelector } from 'react-redux'
import { setCompanySetupModalScreen, uiSelector } from 'store/ui/ui.slice'
import CompanyManual from './CompanyManual'
import { Divider, FormHelperText } from '@material-ui/core'
import InfoTabView from './InfoTabView'
import { Formik } from 'formik'
import * as Yup from 'yup'
import AiIntegration from './AiIntegration'
// import TalkToAnExperDrawer from './TalkToAnExpertDrawer'

const CompanyTab = forwardRef((props, ref) => {
  const { isDemoCompany } = props
  const dispatch = useDispatch()
  const formRef = useRef(null)
  const childRef = useRef(null)
  const { companySetupModalScreen } = useSelector(uiSelector)
  const [isContinueButtonClicked, setContinueButtonClicked] = useState(false)
  const [isAiInterationSelected, setAiIntegrationSelected] = useState(false)
  const [selectedOption, setSelectedOption] = useState('')

  const initialValues = {
    companyRegistrationType: ''
  }

  useImperativeHandle(ref, () => ({
    handleNext() {
      // if (selectedOption === 'manual_data') {
      if (formRef.current) formRef.current.handleSubmit()
      // } else if (selectedOption === 'accounting_integration') {
      // dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
      // }
    }
  }))

  const onClickOnOptionCard = event => {
    formRef.current.setFieldValue('companyRegistrationType', event.target.id)
    setSelectedOption(event.target.id)
  }

  return (
    <>
      {/* {isAiInterationSelected ? (
        <AiIntegration />
      ) : ( */}
      <div
        style={{
          display: 'flex',
          width: '809px',
          height: '884px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          flexShrink: 0,
          gap: '12px',
          justifyContent: 'start',
          paddingLeft: '50px'
        }}
      >
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            companyRegistrationType: Yup.string().required('Please select one option')
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            if (values.companyRegistrationType === 'manual_data') {
              dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
            } else if (values.companyRegistrationType === 'accounting_integration') {
              dispatch(setCompanySetupModalScreen(10))
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
            <form noValidate onSubmit={handleSubmit}>
              <div style={{ marginTop: '70px' }}>
                <p className={cssClasses.stepText}>Step 1</p>
                <h1 className={cssClasses.tabHeading}>Let's get down to business. </h1>
                <p className={cssClasses.tabDesc}>Select below how you'd like to begin to tell your story.</p>
                <Divider style={{ color: '#D8D9DB', height: '2px' }} />
                <div className={cssClasses.companyTabSelectionContainer}>
                  <button
                    name="companyRegistrationType"
                    onClick={event => {
                      onClickOnOptionCard(event)
                      setAiIntegrationSelected(true)
                    }}
                    id="accounting_integration"
                    className={selectedOption === 'accounting_integration' ? cssClasses.selectedCard : cssClasses.selectionCard}
                    type="button"
                  >
                    <div id="accounting_integration" className={cssClasses.cardContainer}>
                      <div
                        id="accounting_integration"
                        style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}
                      >
                        <p className={cssClasses.cardTextOption}>Option 1</p>
                        {selectedOption === 'accounting_integration' && (
                          <div className={cssClasses.selected}>
                            {' '}
                            <DoneIcon sx={{ fontSize: '16px' }} /> Selected
                          </div>
                        )}
                      </div>
                      <h1>Accounting integration</h1>
                      <p>Accounting integration from quickbooks, xero or freshbooks accounting systems. </p>
                    </div>
                  </button>
                  {/* <button
                onClick={onClickOnOptionCard}
                name="companyRegistrationType"
                id="template"
                className={selectedOption === 'template' ? cssClasses.selectedCard : cssClasses.selectionCard}
                type="button"
              >
                <div id="template" className={cssClasses.cardContainer}>
                  <div id="template" style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                    <p className={cssClasses.cardTextOption}>Option 2</p>
                    {selectedOption === 'template' && (
                      <div className={cssClasses.selected}>
                        {' '}
                        <DoneIcon sx={{ fontSize: '16px' }} /> Selected
                      </div>
                    )}
                  </div>
                  <h1>Download template</h1>
                  <p>We’ll provide you with an excel template, that you can upload.</p>
                </div>
              </button> */}
                  <button
                    onClick={event => {
                      onClickOnOptionCard(event)
                      setAiIntegrationSelected(false)
                    }}
                    name="companyRegistrationType"
                    id="manual_data"
                    className={selectedOption === 'manual_data' ? cssClasses.selectedCard : cssClasses.selectionCard}
                    type="button"
                  >
                    <div id="manual_data" className={cssClasses.cardContainer}>
                      <div
                        id="manual_data"
                        style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}
                      >
                        <p className={cssClasses.cardTextOption}>Option 2</p>
                        {selectedOption === 'manual_data' && (
                          <div className={cssClasses.selected}>
                            {' '}
                            <DoneIcon sx={{ fontSize: '16px' }} /> Selected
                          </div>
                        )}
                      </div>
                      <h1>Manual data</h1>
                      <p>Enter your information the traditional way, by manually inputting the data.</p>
                    </div>
                  </button>
                </div>
              </div>
              {errors.companyRegistrationType && (
                <FormHelperText sx={{ fontSize: '18px', fontFamily: 'Raleway' }} error>
                  {' '}
                  {errors.companyRegistrationType}{' '}
                </FormHelperText>
              )}
            </form>
          )}
        </Formik>
      </div>
      {/* )} */}
      {/* <TalkToAnExperDrawer isTalkToAnExpertClicked={isTalkToAnExpertClicked} setTalkToAnExpertClicked={setTalkToAnExpertClicked} /> */}
    </>
  )
})

export default CompanyTab
