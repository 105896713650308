import React from 'react'
import PropTypes from 'prop-types'
import { Box, Paper } from '@material-ui/core'

const TabPanel = props => {
  const { children, value, index, uid = 'mrgn', ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`${uid}-tabpanel-${index}`} aria-labelledby={`${uid}-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ px: 0, py: 2 }}>
          <Paper>{children}</Paper>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  uid: PropTypes.string
}

const a11yProps = (index, uid = 'mrgn') => ({
  id: `${uid}-tab-${index}`,
  'aria-controls': `${uid}-tabpanel-${index}`
})

export { TabPanel, a11yProps }
