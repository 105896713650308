import React, { useState } from 'react'

import { Grid, Box, Stepper, Step, StepLabel, StepContent, Button, Paper, Typography } from '@material-ui/core'

import { uiSelector } from 'store/ui/ui.slice'
import { useSelector } from 'react-redux'

import cssClasses from './infoTab.module.css'
import { makeStyles } from '@material-ui/styles'

const WizardStepper = props => {
  const { companySetupModalScreen, companySetupModalTabOptionsSection2 } = useSelector(uiSelector)

  const useStyles = makeStyles(() => ({
    root: {
      '& .Mui-active': { color: '#29A364', fontSize: '25px' },
      '& .Mui-completed': { color: '#260C64', fontSize: '25px' },
      '& .Mui-disabled .MuiStepIcon-root': { color: '#EFE9FE', fontSize: '25px' },
      '& .MuiStepLabel-label': { fontSize: '18px', fontFamily: 'Raleway', fontWeight: 600 }
    }
  }))

  const styles = useStyles()

  return (
    <Grid item xs={12}>
      <Typography className={cssClasses.wizardStepperText}>Steps</Typography>
      <Box sx={{ maxWidth: 400 }}>
        <Stepper className={styles.root} activeStep={companySetupModalScreen} orientation="vertical">
          {companySetupModalTabOptionsSection2.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent>
                <Typography sx={{ fontFamily: 'Raleway', fontSize: '12px', fontWeight: 500, color: '#626870' }}>
                  {step.description}
                </Typography>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Grid>
  )
}

export default WizardStepper
