import React, { useState } from 'react'
import { Grid, Input, InputAdornment, TextField } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { uiSelector } from 'store/ui/ui.slice'
import { companySelector } from 'store/company/company.slice'

const DeptAllocation = props => {
  const { deptDetails, onChangeAmountHandler, onChangePercentageHandler, percentageAmount, equityRaiseTranchAmount } = props
  const { id, name, amount, percentage } = deptDetails

  const { equityEditButtonClicked } = useSelector(uiSelector)
  const { selectedCompany, companies, equityAllocationList } = useSelector(companySelector)

  const onChangeAmount = event => {
    onChangeAmountHandler(event)
  }
  const onChangePercentage = event => {
    onChangePercentageHandler(event)
  }

  const formatValues = e => e.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return (
    <li style={{ marginBottom: '20px', flexDirection: 'row', justifyContent: 'center' }}>
      <Grid container item xs={12} direction="row" justifyContent="center" alignItems="center">
        <Grid container item xs={6}>
          <TextField
            InputProps={{
              readOnly: true
            }}
            label="Department"
            id={id}
            defaultValue={name}
          />
        </Grid>
        <Grid style={{ flexWrap: 'nowrap' }} container item xs={6}>
          <TextField
            sx={{ width: '120px' }}
            id={id}
            onChange={onChangeAmount}
            value={formatValues(amount ? amount.toString() : (percentage / 100) * percentageAmount)}
            // value={equityEditButtonClicked ? selectedDeptList?.amount : (selectedDeptList?.percentage / 100) * percentageAmount}
            // value={equityEditButtonClicked ? selectedDeptList?.amount : amount.toString()}
            // value={amount.toString()}
            label="Amount"
            style={{ marginLeft: '15px' }}
            InputProps={{
              startAdornment: <span>$</span>,
              inputProps: {
                min: 1,
                type: 'string',
                allowNegative: 'false'
              }
            }}
          />
          <TextField
            sx={{ width: '120px' }}
            id={id}
            onChange={onChangePercentage}
            value={percentage ? percentage.toString() : (amount / percentageAmount) * 100}
            // value={equityEditButtonClicked ? selectedDeptList?.percentage : (selectedDeptList?.amount / percentageAmount) * 100}
            // value={equityEditButtonClicked ? selectedDeptList?.percentage : null}
            // value={percentage}
            label="Percentage"
            style={{ marginLeft: '15px' }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputProps: {
                min: 1,
                // type: 'number',
                allownegative: 'false'
              }
            }}
          />
        </Grid>
      </Grid>
    </li>
  )
}

export default DeptAllocation
