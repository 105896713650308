// @ts-nocheck
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import styles from '../../styles.module.css'
import PropTypes from 'prop-types'
import {
  Grid,
  TextField,
  Box,
  InputAdornment,
  IconButton,
  Button,
  Fab,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Card,
  Divider,
  CardMedia,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  InputBase
} from '@material-ui/core'
import { LoadingButton } from '@material-ui/lab'
import { useDispatch, useSelector } from 'react-redux'

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { Cancel, CancelOutlined, Save } from '@material-ui/icons'

import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import SearchIcon from '@material-ui/icons/Search'
import UpdateIcon from '@material-ui/icons/Save'
import MenuIcon from '@material-ui/icons/Menu'
import { gridSpacing } from 'store/constant'
import ModalSubtitle from './ModalSubtitle'
import { useNavigate } from 'react-router'
import EditPencilIcon from '../../assets/images/icons/EditPencilIcon.svg'
import Adjustments from '../../assets/images/icons/adjustments.svg'
import UploadIcon from '../../assets/images/icons/UploadIcon.png'
import * as Yup from 'yup'
import { Formik, FieldArray, ErrorMessage } from 'formik'
import { v4 as uuidv4 } from 'uuid'
import cssClasses from './infoTab.module.css'
import {
  uiSelector,
  setCompanySetupModalScreen,
  toggleCompanySetupModal,
  toggleExcelImportTabModel,
  setExcelImportTabModel,
  setSelectedMenuItem
} from 'store/ui/ui.slice'
import { updateCompany, getCompaniesByUser } from 'store/company/company.actions'
import { clearState, companySelector, resetState } from 'store/company/company.slice'
import { SNACKBAR_OPEN } from 'store/actions'
import { authSelector } from 'store/auth/auth.slice'
import { makeStyles } from '@material-ui/styles'
import excel from 'assets/images/excel.png'
import excelWithA from 'assets/images/excelWithA.png'
import SideDrawer from './DepartmentsSideDrawer'

const useStyles = makeStyles(theme => ({
  container: {
    border: 'solid 1px',
    padding: '15px 1px 15px 10px',
    borderRadius: '10px',
    alignItems: 'center',
    marginTop: '10px',
    background: '#0C203B',
    color: 'white'
  },
  excel: {
    width: '53px',
    height: '31px',
    objectFit: 'contain',
    // marginTop: -25,
    borderRadius: 30,
    cursor: 'pointer'
    // '&:hover': {
    //   background: '#c6e9f5'
    // }
  },
  upgradeBtn: {
    width: '50px',
    height: '50px',
    fontFamily: 'Verdana',
    color: '#F7F7F8',
    fontSize: '25px',
    fontWeight: '500',
    background: '#091A36',
    borderRadius: '8px',
    marginLeft: '10px',
    marginBottom: '5px',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    paddingTop: '10px',
    paddingBottom: '10px',
    // marginRight: '12px',
    // paddingRight: '18px',
    // paddingLeft: '18px',
    // marginTop: '20px',
    '&:hover': {
      background: '#14284a',
      color: 'white'
    }
  },
  dropdownStyle: {
    borderRadius: '8px !important',
    '& .MuiOutlinedInput-input': {
      borderRadius: '8px !important'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px !important'
    }
  }
}))

const DepartmentsTabView = forwardRef((props, ref) => {
  const classes = useStyles()
  const { title, caption, toolTip, qbIcon, windowHeight, isDemoCompany } = props
  const dispatch = useDispatch()
  const formRef = useRef()
  const { companySetupModalScreen } = useSelector(uiSelector)
  const { isLoading, companies, isSuccess, updateCompanyError, id, departmentList, adminDepartment, selectedCompany } =
    useSelector(companySelector)
  const { user } = useSelector(authSelector)
  const [isFinish, setIsFinish] = useState(false)
  const navigate = useNavigate()
  const [editIndex, setEditIndex] = useState(-1)
  const [newName, setNewName] = useState('')
  const [openDialog, setOpenDialog] = useState(false)
  const [mouseEnterOnCard, setMouseEnterOnCard] = useState('')
  const [edited, setEdited] = useState(false)

  useEffect(() => {
    if (updateCompanyError !== '') {
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: updateCompanyError,
        variant: 'alert',
        alertSeverity: 'error'
      })
    }

    return dispatch(clearState())
  }, [dispatch, updateCompanyError])

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearState())
      if (isFinish) {
        dispatch(toggleCompanySetupModal(false))
        dispatch(setCompanySetupModalScreen(0))
        dispatch(resetState())
        dispatch(getCompaniesByUser())
        // navigate(`models?cid=${selectedCompany}`)
      }
    }
  }, [companySetupModalScreen, dispatch, isFinish, isSuccess, navigate, selectedCompany])

  const initialDepartmentsList = [
    { id: uuidv4(), name: 'Marketing', description: '' },
    { id: uuidv4(), name: 'Sales', description: '' },
    { id: uuidv4(), name: 'Merchandising', description: '' },
    { id: uuidv4(), name: 'General & Administrative', description: '' },
    { id: uuidv4(), name: 'Finance', description: '' },
    { id: uuidv4(), name: 'Product Management', description: '' },
    { id: uuidv4(), name: 'Engineering', description: '' },
    { id: uuidv4(), name: 'Customer Support', description: '' }
  ]

  const [departments, setDepartments] = useState([])
  const [department, setDepartment] = useState('')
  const [departmentError, setDepartmentError] = useState('')
  const [departmentNameError, setDepartmentNameError] = useState('')
  const [departmentObject, setDepartmentObject] = useState(adminDepartment || [])
  const [isAddButtonClicked, setAddButtonClicked] = useState(false)
  const [searchInputs, setSearchInputs] = useState('')
  const [isEditButtonClicked, setEditButtonClicked] = useState(false)
  const [isListAddButtonClicked, setAddListButtonClicked] = useState(false)
  const [addedDept, setAddedDept] = useState([])

  useEffect(() => {
    if (adminDepartment) {
      adminDepartment.forEach(object => {
        if (object.userId === user.id) {
          setDepartment(object.department)
        }
      })
    }
    if (departmentList.length > 0) {
      setDepartments(departmentList)
    } else {
      setDepartments(initialDepartmentsList)
    }
  }, [departmentList])

  const departmentExist = () => departmentObject.some(el => el.userId === user.id)

  const updateDepartment = department => {
    setDepartment(department)
    let tempDepartment
    if (departmentObject) {
      tempDepartment = departmentObject.map(object => {
        if (object.userId === user.id) {
          return { ...object, department }
        }
        return object
      })
    }
    if (!departmentExist()) {
      tempDepartment = [...departmentObject, { userId: user.id, department, role: 'superAdmin' }]
    }

    setDepartmentObject(tempDepartment)
  }

  const initialValues = {
    departmentName: newName
  }

  const handleEditClick = dept => {
    setAddedDept([...departments])
    const deptId = dept.id ? dept.id : dept._id
    setNewName(dept.name)
    setEditIndex(deptId)
    setAddButtonClicked(true)
  }

  const handleNameChange = event => {
    setNewName(event.target.value)
  }
  useEffect(() => {
    if (edited) {
      setAddedDept([...departments])
    }
  }, [departments])

  const handleSaveClick = () => {
    setEditButtonClicked(false)
    setDepartments(prevState => [
      ...prevState.map(item => {
        if ((item.id || item._id) === editIndex) {
          const updatedItem = {
            ...item,
            name: newName
          }
          return updatedItem
        }
        return item
      })
    ])

    const deptList = []
    deptList.push(newName)
    setAddedDept([...departments])
    setNewName('')
    setOpenDialog(false)
    setEdited(true)
  }

  const handleCancelClick = () => {
    setEditIndex(-1)
    setNewName('')
  }

  const onEnterFilterBox = event => {
    setSearchInputs(event.target.value)
  }

  const handleAddNewDepatment = departmentName => {
    const deptList = []
    deptList.push(departmentName)
    setAddedDept([...departments, { name: departmentName }])

    setDepartments([...departments, { name: departmentName }])
    setNewName('')
  }

  const filteredDepartmentData = departments.filter(eachItem => {
    if (searchInputs.toLocaleLowerCase() === '') {
      return eachItem
    }
    return eachItem.name.toLocaleLowerCase().includes(searchInputs.toLocaleLowerCase())
  })

  useImperativeHandle(ref, () => ({
    handleNext(flag) {
      // if (flag) setIsFinish(true)
      // if (formRef.current) formRef.current.handleSubmit()
      // dispatch(setSelectedMenuItem('expenses'))
      const data = {
        id,
        departmentList: departments,
        currentModelScreen: companySetupModalScreen + 1,
        userDepartment: departmentObject
      }
      dispatch(updateCompany(data))
      dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
    }
  }))

  const DepartmentCard = ({ department }) => (
    <div
      style={{
        width: '237px',
        minHeight: '92px',
        display: 'flex',
        padding: '16px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        flex: '1 0 0',
        border: '1px solid var(--secondary-purple-800, #207E4D)',
        borderRadius: '4px',
        gap: '13px'
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <Typography
          style={{
            color: 'var(--secondary-purple-800, #207E4D)',
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: 'Raleway',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500
          }}
        >
          Department
        </Typography>
        <button
          disabled={isDemoCompany}
          style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}
          type="button"
          onClick={() => {
            handleEditClick(department)
            setEditButtonClicked(true)
            setAddListButtonClicked(true)
          }}
        >
          <img src={EditPencilIcon} alt="edit" />
        </button>
      </div>
      <Typography
        sx={{
          color: 'var(--neutrals-cool-600, #3B424C)',
          fontFeatureSettings: "'clig' off, 'liga' off",
          fontFamily: 'Raleway',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '125%',
          wordWrap: 'break-word',
          maxWidth: '100%'
        }}
      >
        {department.name}
      </Typography>
    </div>
  )

  return (
    <div
      style={{
        display: 'flex',
        width: '809px',
        height: '884px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        flexShrink: 0,
        gap: '12px',
        justifyContent: 'start',
        paddingLeft: '50px'
      }}
    >
      <div style={{ marginTop: '70px' }}>
        <p className={cssClasses.stepText}>Step 2</p>
        <h1 className={cssClasses.tabHeading}>Departments </h1>
        <p className={cssClasses.tabDesc}>
          Time to dig a little deeper. Go ahead and list the departments or functions of your business. We’ve already added some of the
          standard ones below, feel free to edit the list as you see fit.{' '}
        </p>
        <Divider sx={{ width: '100%', marginTop: '28px' }} />
        {/* <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Name is required'),
            industry: Yup.string().required('Industry is required')
            // bankBalance: Yup.string().required('Bank balance is required')
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {}}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, handleOnInput }) => (
            <form noValidate onSubmit={handleSubmit}> */}
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', margin: '26px 0' }}>
          {' '}
          <Paper
            component="form"
            sx={{
              p: '2px 4px',
              display: 'flex',
              alignItems: 'center',
              width: '360px',
              height: '44px',
              border: '1px solid #3B424C',
              borderRadius: '4px'
            }}
          >
            <IconButton sx={{ p: '10px' }} aria-label="menu">
              <SearchIcon />
            </IconButton>
            <InputBase
              disabled={isDemoCompany}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search by..."
              inputProps={{ 'aria-label': 'search google maps' }}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
              <img src={Adjustments} alt="Edit" />
            </IconButton>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          </Paper>
          <button
            disabled={isDemoCompany}
            style={{
              height: '44px',
              padding: '12px 16px',
              borderRadius: '4px',
              border: 'solid 1px #29A364',
              color: '#29A364',
              fontSize: '16px',
              background: '#F2F7F4',
              fontWeight: 500,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer'
            }}
            type="button"
            onClick={() => {
              setAddButtonClicked(true)
              // dispatch(setExcelImportTabModel(1))
            }}
          >
            <span style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <AddIcon />
              <span>Add</span>
            </span>
          </button>
        </div>
        <Grid container spacing={2} mt={3}>
          {filteredDepartmentData.map((department, index) => (
            <Grid item xs={4}>
              <DepartmentCard department={department} />
            </Grid>
          ))}
        </Grid>
        {/* </form>
          )}
        </Formik> */}
      </div>

      <SideDrawer
        isAddButtonClicked={isAddButtonClicked}
        setAddButtonClicked={setAddButtonClicked}
        handleNameChange={handleNameChange}
        initialValues={initialValues}
        formRef={formRef}
        handleSaveClick={handleSaveClick}
        handleCancelClick={handleCancelClick}
        departments={departments}
        departmentObject={departmentObject}
        setDepartments={setDepartments}
        isEditButtonClicked={isEditButtonClicked}
        handleAddNewDepatment={handleAddNewDepatment}
        handleEditClick={handleEditClick}
        isListAddButtonClicked={isListAddButtonClicked}
        setAddListButtonClicked={setAddListButtonClicked}
        setEditButtonClicked={setEditButtonClicked}
        addedDept={addedDept}
        setAddedDept={setAddedDept}
        setNewName={setNewName}
        setEdited={setEdited}
      />
    </div>
  )
})

DepartmentsTabView.propTypes = {
  title: PropTypes.any,
  caption: PropTypes.any,
  toolTip: PropTypes.any,
  qbIcon: PropTypes.any,
  windowHeight: PropTypes.any,
  isDemoCompany: PropTypes.any
}

export default DepartmentsTabView
