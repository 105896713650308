import React, { forwardRef, useState, useEffect, useImperativeHandle } from 'react'

import { ObjectID } from 'bson'
import _ from 'lodash'

import { Button, Dialog, DialogContent, Divider, Grid, IconButton, InputBase, Paper } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/Add'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Adjustments from '../../assets/images/icons/adjustments.svg'
import EditPencilIcon from '../../assets/images/icons/EditPencilIcon.svg'

import cssClasses from './infoTab.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { companySelector } from 'store/company/company.slice'
import SideDrawer from './ExpensesSideDrawer'
import { setCompanySetupModalScreen, uiSelector } from 'store/ui/ui.slice'
import dayjs from 'dayjs'

const NewExpensesTab = forwardRef((props, ref) => {
  const { isDemoCompany } = props
  const {
    isSuccess,
    updateCompanyError,
    id,
    selectedCompany,
    vendorList,
    departmentList,
    productList,
    revenueComputationType,
    clientList,
    revenueModel,
    isOpenedFromCompDrop
  } = useSelector(companySelector)
  const { companySetupModalScreen } = useSelector(uiSelector)
  const dispatch = useDispatch()
  const [localVendorList, setLocalVendorList] = useState([])
  const [vendorGroupByParent, setVendorGroupByParent] = useState('')
  const [isAddButtonClicked, setAddButtonClicked] = useState(false)
  const [editButtonClicked, setEditButtonClicked] = useState(false)
  const [editItem, setEditItemId] = useState('')
  const [expandItemId, setIsExpandItemId] = useState('')
  const [isExpandAllClicked, setExpandAll] = useState(false)
  useImperativeHandle(ref, () => ({
    handleNext() {
      dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
    }
  }))

  useEffect(() => {
    if (vendorList?.length > 0) {
      setLocalVendorList(vendorList)
    } else {
      const initialVendorList = [
        {
          _id: new ObjectID(),
          name: 'Utilities',
          department: '',
          frequency: '',
          amount: '',
          startDate: '',
          endDate: '',
          nestedUnder: '',
          cogs: false,
          hrRelated: false,
          govtTaxed: false
        },
        {
          _id: new ObjectID(),
          name: 'Insurance',
          department: '',
          frequency: '',
          amount: '',
          startDate: '',
          endDate: '',
          nestedUnder: '',
          cogs: false,
          hrRelated: false,
          govtTaxed: false
        },
        {
          _id: new ObjectID(),
          name: 'Returns',
          department: '',
          frequency: '',
          amount: '',
          startDate: '',
          endDate: '',
          nestedUnder: '',
          cogs: false,
          hrRelated: false,
          govtTaxed: false
        },
        {
          _id: new ObjectID(),
          name: 'Advertising & Promotion',
          department: '',
          frequency: '',
          amount: '',
          startDate: '',
          endDate: '',
          nestedUnder: '',
          cogs: false,
          hrRelated: false,
          govtTaxed: false
        },
        {
          _id: new ObjectID(),
          name: 'Rent',
          nestedUnder: '',
          department: '',
          frequency: '',
          amount: '',
          startDate: '',
          endDate: '',
          cogs: false,
          hrRelated: false,
          govtTaxed: false
        },
        {
          _id: new ObjectID(),
          name: 'Legal',
          department: '',
          frequency: '',
          amount: '',
          startDate: '',
          endDate: '',
          nestedUnder: '',
          cogs: false,
          hrRelated: false,
          govtTaxed: false
        },

        {
          _id: new ObjectID(),
          name: 'Equipment',
          department: '',
          frequency: '',
          amount: '',
          startDate: '',
          endDate: '',
          nestedUnder: '',
          cogs: false,
          hrRelated: false,
          govtTaxed: false
        },
        {
          _id: new ObjectID(),
          name: 'Networking',
          department: '',
          frequency: '',
          amount: '',
          startDate: '',
          endDate: '',
          nestedUnder: '',
          cogs: false,
          hrRelated: false,
          govtTaxed: false
        },
        {
          _id: new ObjectID(),
          name: 'Production Materials',
          department: '',
          frequency: '',
          amount: '',
          startDate: '',
          endDate: '',
          nestedUnder: '',
          cogs: false,
          hrRelated: false,
          govtTaxed: false
        },
        {
          _id: new ObjectID(),
          name: 'Facilities',
          department: '',
          frequency: '',
          amount: '',
          startDate: '',
          endDate: '',
          nestedUnder: '',
          cogs: false,
          hrRelated: false,
          govtTaxed: false
        },
        {
          _id: new ObjectID(),
          name: 'Directors & Officers',
          department: '',
          frequency: '',
          amount: '',
          startDate: '',
          endDate: '',
          nestedUnder: '',
          cogs: false,
          hrRelated: false,
          govtTaxed: false
        },
        {
          _id: new ObjectID(),
          name: 'General Liability',
          department: '',
          frequency: '',
          amount: '',
          startDate: '',
          endDate: '',
          nestedUnder: '',
          cogs: false,
          hrRelated: false,
          govtTaxed: false
        }
      ]
      // const initialVendorNestedlist = [
      //   { _id: new ObjectID(), name: 'Utilities', nestedUnder: initialVendorList[3]._id, cogs: false, hrRelated: false, govtTaxed: false },
      //   {
      //     _id: new ObjectID(),
      //     name: 'Immigration',
      //     nestedUnder: initialVendorList[5]._id,
      //     cogs: false,
      //     hrRelated: false,
      //     govtTaxed: false
      //   },
      //   { _id: new ObjectID(), name: 'Intellectual Property', nestedUnder: initialVendorList[5]._id, hrRelated: false, govtTaxed: false }
      // ]
      setLocalVendorList([...initialVendorList])
    }
  }, [vendorList])

  useEffect(() => {
    if (localVendorList?.length > 0) {
      const vendorListSorted = _.sortBy(
        [...localVendorList],
        [
          function (o) {
            return o.nestedUnder === 'None'
          }
        ]
      )

      const vendorListGroupBy = _.groupBy(vendorListSorted, 'nestedUnder')
      setVendorGroupByParent(vendorListGroupBy)
    }
  }, [localVendorList])

  const formikInitialValues = {
    _id: '',
    name: '',
    department: '',
    frequency: '',
    amount: '',
    startDate: '',
    endDate: '',
    nestedUnder: '',
    cogs: false,
    hrRelated: '',
    govtTaxed: false,
    submit: null
  }
  const [initialValues, setInitialValues] = useState(formikInitialValues)

  const editVendor = item => {
    setEditItemId(item._id)
    const tempObject = { ...item }
    const d = new Date(tempObject.startDate)
    const e = new Date(tempObject.endDate)
    setInitialValues({
      _id: tempObject._id ? tempObject._id : '',
      name: tempObject.name ? tempObject.name : '',
      department: tempObject.department ? tempObject.department : '',
      frequency: tempObject.frequency ? tempObject.frequency : '',
      amount: tempObject.amount ? tempObject.amount : '',
      // startDate: tempObject.startDate ? dayjs(d).format('YYYY/MM/DD') : null,
      // endDate: tempObject.endDate ? dayjs(e).format('YYYY/MM/DD') : null,
      startDate: tempObject.startDate ? dayjs(d).format('YYYY-MM-DD') : null,
      endDate: tempObject.endDate ? dayjs(e).format('YYYY-MM-DD') : null,
      nestedUnder: tempObject.nestedUnder ? tempObject.nestedUnder : 'Not Nested',
      cogs: tempObject.cogs ? tempObject.cogs : false,
      hrRelated: tempObject.hrRelated,
      govtTaxed: tempObject.govtTaxed ? tempObject.govtTaxed : false,
      submit: null
    })
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '809px',
        height: '884px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        flexShrink: 0,
        gap: '12px',
        justifyContent: 'start',
        paddingLeft: '50px'
      }}
    >
      <div style={{ marginTop: '70px' }}>
        <p className={cssClasses.stepText}>Step3</p>
        <h1 className={cssClasses.tabHeading}>Expenses</h1>
        <p className={cssClasses.tabDesc}>
          Now that we understand your departments, let's detail your expenses. For example: your tax accountant, distributor, inventory,
          etc.
        </p>
        <Divider style={{ color: '#D8D9DB', height: '2px' }} />
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', margin: '26px 0' }}>
          {' '}
          <Paper
            component="form"
            sx={{
              p: '2px 4px',
              display: 'flex',
              alignItems: 'center',
              width: '360px',
              height: '44px',
              border: '1px solid #3B424C',
              borderRadius: '4px'
            }}
          >
            <IconButton sx={{ p: '10px' }} aria-label="menu">
              <SearchIcon />
            </IconButton>
            <InputBase
              disabled={isDemoCompany}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search by..."
              inputProps={{ 'aria-label': 'search google maps' }}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
              <img src={Adjustments} alt="Edit" />
            </IconButton>
            {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
          </Paper>
          <button
            disabled={isDemoCompany}
            onClick={() => {
              setAddButtonClicked(true)
            }}
            style={{
              height: '44px',
              padding: '12px 16px',
              borderRadius: '4px',
              border: 'solid 1px #29A364',
              color: '#29A364',
              fontSize: '16px',
              background: '#F2F7F4',
              fontWeight: 500,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: '18px',
              cursor: 'pointer'
            }}
            type="button"
          >
            <span style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <AddIcon />
              <span>Add</span>
            </span>
          </button>
        </div>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Button onClick={() => setExpandAll(true)} variant="text" className={cssClasses.textExpandAll}>
            Expand all
          </Button>
          <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
          <Button onClick={() => setExpandAll(false)} variant="text" className={cssClasses.textCollapseAll}>
            Collapse all
          </Button>
        </div>
        <Grid item xs={12} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
          {localVendorList.map(
            (eachItem, index) =>
              (eachItem.nestedUnder === '' || !eachItem.nestedUnder) && (
                <div key={eachItem._id} className={cssClasses.expensesCard}>
                  <div style={{ width: '95%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <p className={cssClasses.cardName}>Expense Category</p>
                    <IconButton
                      disabled={isDemoCompany}
                      onClick={() => {
                        editVendor(eachItem)
                        setEditButtonClicked(true)
                        setAddButtonClicked(true)
                      }}
                    >
                      <img src={EditPencilIcon} alt="edit" />
                    </IconButton>
                  </div>
                  <span className={cssClasses.cardAccount}>Account</span>
                  <h1 className={cssClasses.cardAccountName}>{eachItem.name}</h1>
                  <div style={{ width: '95%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <p className={cssClasses.cardNested}>Nested</p>
                    {vendorGroupByParent[eachItem._id]?.length > 0 && (
                      <IconButton onClick={() => setIsExpandItemId(prevstate => (prevstate === eachItem._id ? '' : eachItem._id))}>
                        {expandItemId === eachItem._id && vendorGroupByParent[eachItem._id]?.length ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </IconButton>
                    )}
                  </div>
                  <p className={cssClasses.nestedNumber}>
                    {vendorGroupByParent[eachItem._id]?.length ? vendorGroupByParent[eachItem._id]?.length : 'N/A'}
                  </p>
                  {(expandItemId === eachItem._id || isExpandAllClicked) &&
                    vendorGroupByParent[eachItem._id] &&
                    vendorGroupByParent[eachItem._id]?.map((childItem, index) => (
                      <div key={`nestName${index}`} className={cssClasses.nestedItem}>
                        <span className={cssClasses.nestName}>{childItem.name}</span>
                      </div>
                    ))}
                </div>
              )
          )}
        </Grid>
      </div>
      <SideDrawer
        isAddButtonClicked={isAddButtonClicked}
        setAddButtonClicked={setAddButtonClicked}
        localVendorList={localVendorList}
        editButtonClicked={editButtonClicked}
        setEditButtonClicked={setEditButtonClicked}
        editItem={editItem}
        setEditItemId={setEditItemId}
        formikInitialValues={formikInitialValues}
        initialValues={initialValues}
        setInitialValues={setInitialValues}
        vendorGroupByParent={vendorGroupByParent}
        editVendor={editVendor}
      />
    </div>
  )
})

export default NewExpensesTab
