import { useRoutes } from 'react-router-dom'

import MainRoutes from './MainRoutes'
import LoginRoutes from './LoginRoutes'
import AuthenticationRoutes from './AuthenticationRoutes'
import ForgotPassword from './ForgotPassword'
import RegisterByInvite from './RegisterByInvite'
import ResetPassword from './ResetPassword'
import GuestRoutes from './GuestRoutes'
import QBIntehration from './QBRoutes'
import CommonLoader from './CommonLoaderRoutes'
import WorpressPaymentRoutes from './WordpressPaymentRoutes'
import GuestUserRoutes from './GuestUserRoutes'
import DemoAuthLoader from './DemoEnvironement'
import NewRegistrationRoutes from './NewRegistrationPage'
import CompanyRegistrationRoutes from './CompanyRegistrationRoutes'
import RequestDemoPageRoutes from './RequestDemoPageRoutes'
import TestLoaderRoutes from './TestLoader'

export default function ThemeRoutes() {
  return useRoutes([
    LoginRoutes,
    AuthenticationRoutes,
    MainRoutes,
    RegisterByInvite,
    ForgotPassword,
    ResetPassword,
    GuestRoutes,
    QBIntehration,
    CommonLoader,
    WorpressPaymentRoutes,
    GuestUserRoutes,
    DemoAuthLoader,
    NewRegistrationRoutes,
    CompanyRegistrationRoutes,
    RequestDemoPageRoutes,
    TestLoaderRoutes
  ])
}
