import PropTypes from 'prop-types'
import React, { createRef, useEffect, useMemo, useRef } from 'react'

// material-ui
import { makeStyles, useTheme } from '@material-ui/styles'
import { Divider, List, Typography, useMediaQuery } from '@material-ui/core'

// project imports
import NavItem from '../NavItem'
import NavCollapse from '../NavCollapse'
import { useDispatch } from 'react-redux'
import { MENU_OPEN, SET_MENU } from 'store/actions'
import { useLocation } from 'react-router'

// style constant
const useStyles = makeStyles(theme => ({
  menuCaption: {
    ...theme.typography.menuCaption
  },
  subMenuCaption: {
    ...theme.typography.subMenuCaption
  },
  menuList: {
    marginTop: '15px'
  }
}))

// ===========================|| SIDEBAR MENU LIST GROUP ||=========================== //

const NavGroup = ({ item }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const matchesSM = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles()
  const navCollapseRefs = useMemo(() => item.children.map(() => createRef()), [item.children.join(',')])
  const location = useLocation()
  useEffect(() => {
    if (location.pathname.includes('simulations')) navCollapseRefs[2].current.open('simulations')
  }, [location])

  const itemHandler = itemId => {
    navCollapseRefs[2].current.close()
    dispatch({ type: MENU_OPEN, id: itemId })
    if (matchesSM) dispatch({ type: SET_MENU, opened: false })
  }

  // menu list collapse & items
  const items = item.children.map((menu, i) => {
    switch (menu.type) {
      case 'collapse':
        return <NavCollapse key={menu.id} menu={menu} level={1} ref={navCollapseRefs[i]} />
      case 'item':
        return <NavItem key={menu.id} item={menu} level={1} handleClick={id => itemHandler(id)} />
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        )
    }
  })

  return (
    <div className={classes.menuList}>
      <List
        subheader={
          item.title && (
            <Typography variant="caption" className={classes.menuCaption} display="block" gutterBottom>
              {item.title}
              {item.caption && (
                <Typography variant="caption" className={classes.subMenuCaption} display="block" gutterBottom>
                  {item.caption}
                </Typography>
              )}
            </Typography>
          )
        }
      >
        {items}
      </List>
    </div>
  )
}

NavGroup.propTypes = {
  item: PropTypes.object
}

export default NavGroup
