import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { Formik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { setCompanySetupModalScreen, uiSelector } from 'store/ui/ui.slice'
import { Divider, Grid, CircularProgress } from '@material-ui/core'

import quickBook from 'assets/images/Intuit Quickbooks 1.png'
import freshbooks from 'assets/images/FreshBooks_logo_(2020) 1.png'
import xero from 'assets/images/Xero Logo.png'

import whitequickbook from 'assets/images/Quickbooks_white.png'
import whitefreshbook from 'assets/images/FreshBooks_white.png'

import cssClasses from './infoTab.module.css'

import { authSelector } from 'store/auth/auth.slice'
import { quickbookSelector, resetToken } from 'store/quickBook/quickbook.slice'
import { createBossInsightAccount } from 'store/quickBook/quickbook.action'
import { BOSSINSIGHT_REDIRECT_URL } from 'utils/API/index'

const AiIntegration = forwardRef((props, ref) => {
  const { demo } = props
  const formRef = useRef(null)
  const dispatch = useDispatch()
  const { companySetupModalScreen } = useSelector(uiSelector)
  const { accountDomain, embeddToken, isTokenFound } = useSelector(quickbookSelector)
  const { user } = useSelector(authSelector)

  const [loading, setLoading] = useState(false)
  const [vendor, setvendor] = useState('')

  useImperativeHandle(ref, () => ({
    handleNext() {
      if (formRef.current) formRef.current.handleSubmit()
    }
  }))

  const initialValues = {
    integarationModel: ''
  }

  useEffect(() => {
    if (isTokenFound) {
      setLoading(false)

      window.location.replace(
        `https://${accountDomain}/dock/pre-auth.php?redirect=${BOSSINSIGHT_REDIRECT_URL}&domain=${accountDomain}&service=${vendor}&embed=${embeddToken}`
      )
      dispatch(resetToken())
    }
  }, [isTokenFound])

  if (loading) {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ height: '100%', background: 'gray', position: 'absolute', left: '0px', zIndex: '22', opacity: '0.4' }}
      >
        <Grid item alignSelf="center" justifySelf="center">
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }

  const onClickIntegrationItem = event => {
    formRef.current.setFieldValue('integarationModel', event.target.id)
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '900px',
        height: '884px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        flexShrink: 0,
        gap: '12px',
        justifyContent: 'start',
        paddingLeft: '50px'
      }}
    >
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          integarationModel: Yup.string().required('Please select one option')
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          setLoading(true)
          dispatch(createBossInsightAccount({ user: user.id }))
        }}
      >
        {({ errors, handleBlur, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
          <form noValidate onSubmit={handleSubmit} style={{ width: '100%' }}>
            <div style={{ width: '100%', marginTop: '70px' }}>
              <p className={cssClasses.stepText}>Step 1</p>
              <h1 className={cssClasses.tabHeading}>Link your accounts </h1>
              <p className={cssClasses.tabDesc}>Below select your account service you want to connect. </p>
              {/* <Divider style={{ color: '#D8D9DB', height: '2px' }} /> */}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  width: '100%',
                  marginTop: '40px',
                  marginBottom: '40px'
                }}
              >
                <button
                  id="quickbooks"
                  onClick={event => {
                    onClickIntegrationItem(event)
                    setvendor('quickbooks')
                  }}
                  type="button"
                  className={vendor === 'quickbooks' ? cssClasses.selectedIntegrationButton : cssClasses.integrationButton}
                >
                  <img id="quickbooks" src={vendor === 'quickbooks' ? whitequickbook : quickBook} alt="quickbook_img" />
                  <img id="quickbooks" src={whitequickbook} alt="quickbook_img" />
                </button>
                <button
                  id="xero"
                  onClick={event => {
                    onClickIntegrationItem(event)
                    setvendor('xero')
                  }}
                  className={vendor === 'xero' ? cssClasses.selectedIntegrationButton : cssClasses.integrationButton}
                  type="button"
                >
                  <img id="xero" src={xero} alt="xero_img" />
                  <img id="xero" src={xero} alt="xero_img" />
                </button>
                <button
                  id="freshbooks"
                  onClick={event => {
                    onClickIntegrationItem(event)
                    setvendor('freshbooks')
                  }}
                  className={vendor === 'freshbooks' ? cssClasses.selectedIntegrationButton : cssClasses.integrationButton}
                  type="button"
                >
                  <img id="freshbooks" src={vendor === 'freshbooks' ? whitefreshbook : freshbooks} alt="freshbooks_img" />
                  <img id="freshbooks" src={whitefreshbook} alt="freshbooks_img" />
                </button>
              </div>
              <p className={cssClasses.tabDesc} style={{ paddingRight: '61px' }}>
                You will be asked to connect your account and authorize mrgn Financial Institution to access your financial data. By linking
                an account you are agreeing to the{' '}
                <span className={cssClasses.termsAndCond}>
                  <a href="https://mrgn.ai/terms-of-service/" target="_blank" rel="noreferrer">
                    {' '}
                    Terms and Conditions{' '}
                  </a>
                </span>
              </p>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
})

export default AiIntegration
