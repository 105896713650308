import React from 'react'
import { useNavigate } from 'react-router-dom'

// material-ui
import { ButtonBase } from '@material-ui/core'

// project imports
import config from 'config'
import Logo from 'ui-component/Logo'
import { useDispatch } from 'react-redux'
import { MENU_OPEN } from 'store/actions'

// ===========================|| MAIN LOGO ||=========================== //

const LogoSection = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  return (
    <ButtonBase
      disableRipple
      onClick={() => {
        dispatch({ type: MENU_OPEN, id: 'dashboard' })
        navigate(config.defaultPath)
      }}
    >
      <Logo />
    </ButtonBase>
  )
}

export default LogoSection
