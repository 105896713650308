import React from 'react'
import { TableRow, Tooltip, tooltipClasses } from '@material-ui/core'
import { styled } from '@material-ui/styles'
import { useLocation } from 'react-router'
import _ from 'lodash'

const getWindowWidth = () => {
  const { innerWidth: width } = window
  return width
}

const getWindowHeight = () => {
  const { innerHeight: height } = window
  return height
}

const computeValue = (type, percent, value) => {
  const percentValue = parseFloat(percent)
  const oldValue = parseFloat(value)
  if (type === 'negative') {
    return (oldValue - (percentValue / 100) * oldValue).toFixed(2)
  }
  return (oldValue + (percentValue / 100) * oldValue).toFixed(2)
}

const getDiffString = type => {
  if (type === 'negative') {
    return 'DOWN'
  }
  return 'UP'
}

const useQuery = () => new URLSearchParams(useLocation().search)

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip arrow placement="top" {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#5A95BF'
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#5A95BF',
      minWidth: '250px',
      color: theme.palette.common.white,
      maxWidth: 800,
      fontSize: theme.typography.pxToRem(12),
      borderRadius: '10px',
      padding: 0
    },
    [`& .MuiTypography-root`]: {
      fontFamily: "'Raleway',sans-serif",
      fontWeight: '500 !important'
    },
    [`& strong`]: {
      fontWeight: '900 !important'
    },
    [`& .${tooltipClasses.tooltip} a`]: {
      color: theme.palette.primary[200]
    }
  })
)

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // '&:nth-of-type(odd)': {
  //   backgroundColor: theme.palette.action.hover
  // },
  // '&:hover': {
  //   backgroundColor: theme.palette.primary.light
  // }
}))

const numberFormat = (x, y) => {
  if (!y || y === 1) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return (x / y).toString().replace(/\B(!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
}

const listSum = list => list.reduce((a, b) => a + b, 0)

const getCompanyNameById = (companies, value) => {
  const company = companies.find(x => x.id === value)
  if (_.has(company, 'name')) return company.name
  return ''
}

export {
  getWindowWidth,
  getWindowHeight,
  computeValue,
  getDiffString,
  useQuery,
  HtmlTooltip,
  StyledTableRow,
  numberFormat,
  listSum,
  getCompanyNameById
}
