import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState, lazy } from 'react'

import PropTypes from 'prop-types'
import {
  Grid,
  TextField,
  Box,
  Card,
  IconButton,
  Button,
  Fab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  CardHeader,
  Typography,
  CardContent,
  FormHelperText,
  CardMedia
} from '@material-ui/core'
import styles from '../../styles.module.css'
import moment from 'moment'
import AdapterDateFns from '@material-ui/lab/AdapterMoment'
import LocalizationProvider from '@material-ui/lab/LocalizationProvider'

import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import UpdateIcon from '@material-ui/icons/Save'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Formik } from 'formik'
import NumberFormat from 'react-number-format'
import { useNavigate } from 'react-router'

import { gridSpacing } from 'store/constant'
import ModalSubtitle from './ModalSubtitle'
import LocationsList from 'data/LocationsList'

import {
  uiSelector,
  setCompanySetupModalScreen,
  toggleCompanySetupModal,
  setPaymentSetupModalScreen,
  toggleExcelImportTabModel,
  setExcelImportTabModel
} from 'store/ui/ui.slice'
import { updateCompany, getCompaniesByUser, finishCompanyCreate } from 'store/company/company.actions'
import { clearState, companySelector, resetState } from 'store/company/company.slice'
import { MENU_OPEN, SNACKBAR_OPEN } from 'store/actions'
import { numberFormat } from 'utils/ui'
import PopupNotify from 'ui-component/tab/Model/PopupNotify'
import FieldsFilled from 'utils/fieldsFilled'
import { authSelector, updateSetupModelPreviousStatus } from 'store/auth/auth.slice'
import { makeStyles } from '@material-ui/styles'

// import { DatePicker, Space } from 'antd'
import dayjs from 'dayjs'
import excel from 'assets/images/excel.png'
import { DatePicker } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
  excel: {
    padding: 5,
    maxWidth: 65,
    maxHeight: 100,
    objectFit: 'contain',
    // marginTop: -25,
    borderRadius: 30,
    cursor: 'pointer',
    '&:hover': {
      background: '#c6e9f5'
    }
  },
  upgradeBtn: {
    marginRight: 5,
    marginLeft: 5,
    marginTop: '10px',
    width: 96,
    height: 50,
    color: '#F7F7F8',
    fontSize: '12.5',
    fontWeight: 800,
    background: '#091A36',
    borderRadius: '8px',
    '&:hover': {
      background: '#14284a',
      color: 'white'
    }
  },
  dropdownStyle: {
    borderRadius: '8px !important',
    '& .MuiOutlinedInput-input': {
      borderRadius: '8px !important'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px !important'
    }
  }
}))

const EmployeesTabView = forwardRef((props, ref) => {
  const classes = useStyles()
  // const { title, caption, toolTip, qbIcon, windowHeight, isDemoCompany } = props
  const {
    isDemoCompany,
    windowHeight,
    formikEmpInitialValues,
    empInitialValues,
    setEmpInitialValues,
    editItemEmp,
    setEditItemEmp,
    empEditButtonClicked,
    setEmpEditButtonClicked
  } = props
  const dispatch = useDispatch()
  const formRef = useRef(null)
  const childRef = useRef()
  const { companySetupModalScreen } = useSelector(uiSelector)
  const { userTier, updateCompanyError, id, employeeList, departmentList, selectedCompany } = useSelector(companySelector)
  const { user } = useSelector(authSelector)

  const [isNext, setNext] = useState(false)
  const [notify, setNotify] = useState(false)
  const [skip, setskip] = useState(false)
  const [isFinish, setIsFinish] = useState(false)
  const navigate = useNavigate()
  const [disable, setDisable] = useState(false)
  const [popupOpen, setPopupOpen] = useState(false)
  const [islocationFound, setIsLocationFound] = useState(false)

  useEffect(() => {
    const tempLocation = LocationsList.some(location => {
      if (location.name === empInitialValues.location) {
        setEmpInitialValues({ ...empInitialValues, location: `${location.abbreviation} - ${location.name}` })
        return true
      }
      if (`${location.abbreviation} - ${location.name}` === empInitialValues.location) return true

      return false
    })
    setIsLocationFound(tempLocation)
  }, [empInitialValues])

  const handleClick = () => {
    if (
      (userTier === 'starter' && employeeList.length < 5) ||
      (userTier === 'business' && employeeList.length < 30) ||
      userTier === 'growth'
    ) {
      setDisable(false)
    } else {
      setDisable(true)
    }
  }

  useEffect(() => {
    handleClick()
  }, [employeeList])

  // useImperativeHandle(ref, () => ({
  //   empHandleNext(flag) {
  //     if (flag) setIsFinish(true)
  //     if (employeeList.length === 0 || (formRef.current.values.name && employeeList.length > 0)) {
  //       if (employeeList.length === 0 && !formRef.current.values.name) {
  //         setskip(true)
  //       } else if (formRef.current.isValid) {
  //         setNotify(true)
  //         setNext(true)
  //       } else {
  //         dispatch(clearState())
  //         if (formRef.current) formRef.current.handleSubmit()
  //         setNext(true)
  //       }
  //     } else if (flag) {
  //       dispatch(clearState())
  //       const data = {
  //         companyId: id
  //       }
  //       dispatch(finishCompanyCreate(data))
  //     } else {
  //       setNext(false)
  //       // dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
  //       dispatch(clearState())
  //     }
  //   }
  // }))

  // useEffect(() => {
  //   if (isSuccessCompanyCreate && isFinish) {
  //     dispatch(toggleCompanySetupModal())
  //     dispatch(setCompanySetupModalScreen(0))
  //     dispatch(resetState())
  //     dispatch(getCompaniesByUser())
  //   }
  // }, [dispatch, isFinish, isSuccessCompanyCreate, navigate, selectedCompany])

  // useEffect(() => {
  //   if (isFinish && isSuccess) {
  //     dispatch(clearState())
  //     const data = {
  //       companyId: id
  //     }
  //     dispatch(finishCompanyCreate(data))
  //   }
  // }, [dispatch, id, isFinish, isSuccess])

  // const formikInitialValues = {
  //   _id: '',
  //   name: '',
  //   department: '',
  //   title: '',
  //   startDate: null,
  //   location: '',
  //   salary: '',
  //   submit: null
  // }
  // const [initialValues, setInitialValues] = useState(formikInitialValues)

  const [openStartDatePicker, setOpenStartDatePicker] = useState(false)

  const handleAddEmployee = (name, department, title, startDate, location, salary) => {
    const payload = {
      name,
      department,
      title,
      startDate,
      location,
      salary
    }
    const logArray = []
    const employeeChangeLog = {
      user: user.id,
      userName: user.name,
      company: selectedCompany,
      date: new Date(),
      tabChanged: 'Employee',
      department: payload.department,
      lineItemChanged: `${payload.name}(New Employee added)`,
      originalValue: `N/A`,
      newValue: payload.name
    }
    logArray.push(employeeChangeLog)

    const employeePayload = [...employeeList]
    employeePayload.push(payload)
    if (id !== '') {
      const data = {
        id,
        employeeList: employeePayload,
        currentModelScreen: companySetupModalScreen + 1,
        systemLog: logArray
      }
      dispatch(updateCompany(data))
    }
    setEmpInitialValues(formikEmpInitialValues)
  }

  // const editEmployee = item => {
  //   setEditItem(item._id)
  //   const tempObject = { ...item }
  //   const d = new Date(tempObject.startDate)

  //   setEmpInitialValues({
  //     ...item,
  //     startDate: dayjs(d).format('YYYY/MM/DD'),
  //     submit: null
  //   })
  // }

  const handleDeleteEmployee = _id => {
    // if (formRef.current.values._id) {
    //   setNotify(true)
    //   return
    // }
    if (editItemEmp === _id) return
    const logArray = []
    const employee = [...employeeList].filter(item => item._id === _id)

    const employeeChangeLog = {
      user: user.id,
      userName: user.name,
      company: selectedCompany,
      date: new Date(),
      tabChanged: 'Employee',
      department: employee[0].department,
      lineItemChanged: `${employee[0].name}(Employee deleted)`,
      originalValue: employee[0].name,
      newValue: `N/A`
    }
    logArray.push(employeeChangeLog)
    const employeePayload = [...employeeList].filter(item => item._id !== _id)
    if (id !== '') {
      const data = {
        id,
        employeeList: employeePayload,
        currentModelScreen: companySetupModalScreen + 1,
        systemLog: logArray
      }
      dispatch(updateCompany(data))
    }
  }

  const handleUpdateEmployee = (_id, name, department, title, startDate, location, salary) => {
    setEditItemEmp('')
    const payload = {
      _id,
      name,
      department,
      title,
      startDate,
      location,
      salary
    }
    const employeePayload = [...employeeList]
    const index = employeePayload.findIndex(item => item._id === payload._id)
    //  system logs
    const logArray = []
    if (employeePayload[index].department !== payload.department) {
      const employeeChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Employee',
        department: payload.department,
        lineItemChanged: `${employeePayload[index].name} (Department changed)`,
        originalValue: employeePayload[index].department,
        newValue: payload.department
      }
      logArray.push(employeeChangeLog)
    }
    if (employeePayload[index].name !== payload.name) {
      const employeeChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Employee',
        department: payload.department,
        lineItemChanged: `${employeePayload[index].name} (Name changed)`,
        originalValue: employeePayload[index].name,
        newValue: payload.name
      }
      logArray.push(employeeChangeLog)
    }

    if (employeePayload[index].title !== payload.title) {
      const employeeChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Employee',
        department: payload.department,
        lineItemChanged: `${employeePayload[index].name} (Title changed)`,
        originalValue: employeePayload[index].title,
        newValue: payload.title
      }
      logArray.push(employeeChangeLog)
    }
    if (employeePayload[index].location !== payload.location) {
      const employeeChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Employee',
        department: payload.department,
        lineItemChanged: `${employeePayload[index].name} (Location changed)`,
        originalValue: employeePayload[index].location,
        newValue: payload.location
      }
      logArray.push(employeeChangeLog)
    }

    if (dayjs(employeePayload[index].startDate).format('YYYY/MM/DD') !== dayjs(payload.startDate).format('YYYY/MM/DD')) {
      const employeeChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Employee',
        department: payload.department,
        lineItemChanged: `${employeePayload[index].name} (Start Date changed)`,
        originalValue: dayjs(employeePayload[index].startDate).format('MM/DD/YYYY'),
        newValue: dayjs(payload.startDate).format('MM/DD/YYYY')
      }
      logArray.push(employeeChangeLog)
    }

    if (employeePayload[index].salary !== payload.salary) {
      const employeeChangeLog = {
        user: user.id,
        userName: user.name,
        company: selectedCompany,
        date: new Date(),
        tabChanged: 'Employee',
        department: payload.department,
        lineItemChanged: `${employeePayload[index].name} (Salary changed)`,
        originalValue: employeePayload[index].salary,
        newValue: payload.salary
      }
      logArray.push(employeeChangeLog)
    }

    // log ends

    employeePayload[index] = payload
    if (id !== '') {
      const data = {
        id,
        employeeList: employeePayload,
        currentModelScreen: companySetupModalScreen + 1,
        systemLog: logArray
      }

      dispatch(updateCompany(data))
    }
    setEmpInitialValues(formikEmpInitialValues)
  }

  useEffect(() => {
    if (updateCompanyError !== '') {
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: updateCompanyError,
        variant: 'alert',
        alertSeverity: 'error'
      })
    }

    return dispatch(clearState())
  }, [dispatch, updateCompanyError])

  const handleChangeNumber = e => {
    const value = e.target.value.replace(/\D/g, '')
    formRef.current.setFieldValue('salary', value.replace(/\B(?=(\d{3})+(?!\d))/g, ','))
  }

  // const isAllDatasEntered = errors => {
  //   if (Object.values(errors)[0] !== undefined) {
  //     return 'Save'
  //   }
  //   return 'Add'
  // }

  return (
    <>
      {/* <Grid
        container
        sx={{
          minHeight: '100%',
          height: windowHeight - 2,
          overflow: 'auto',
          pl: 1,
          pr: 1,
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar': {
            width: '0.4em'
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555'
          }
        }}
      > */}
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', pl: 2 }}>
        {/* <Grid style={{ display: 'flex', marginRight: '10px' }}>
          <ModalSubtitle title={title} caption={caption} toolTip={toolTip} qbIcon={qbIcon} />
          {!isDemoCompany && (
            <Box justifyContent="flex-end">
              <CardMedia
                sx={{ height: '60px', width: '60px', marginRight: '10px' }}
                component="img"
                image={excel}
                className={classes.excel}
                onClick={() => {
                  dispatch(toggleExcelImportTabModel())
                  dispatch(setExcelImportTabModel(3))
                }}
              />
            </Box>
          )}
        </Grid> */}
        <Box sx={{ my: 2, mr: 2 }}>
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={empInitialValues}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Name is required'),
              department: Yup.string().required('Department is required'),
              title: Yup.string().trim().required('Title is required'),
              startDate: Yup.string().nullable().required('Start date is required'),
              location: Yup.string().required('Location is required'),
              salary: Yup.string().required('Salary is required')
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm, setFieldValue }) => {
              if (isDemoCompany) {
                dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
                return
              }

              if (values._id && employeeList.length > 0) {
                handleUpdateEmployee(
                  values._id,
                  values.name,
                  values.department,
                  values.title,
                  new Date(moment(values.startDate).format('YYYY-MM-DD')),

                  values.location,
                  values.salary.toString().includes(',') ? parseInt(values.salary.replace(/,/g, ''), 10) : values.salary
                )
              } else {
                handleAddEmployee(
                  values.name,
                  values.department,
                  values.title,
                  values.startDate,
                  values.location,
                  values.salary.toString().includes(',') ? parseInt(values.salary.replace(/,/g, ''), 10) : values.salary
                )
              }
              setStatus({ success: true })
              setSubmitting(false)
              setFieldValue('startDate', null, false)
              resetForm(empInitialValues)
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={1} alignItems="flex-start">
                  <Grid item xs={12} md={12}>
                    <Grid container spacing={1.5}>
                      <Grid item xs={12} md={6}>
                        <div className={styles.inputBoxContainer}>
                          <div className={styles.materialTextfield}>
                            <input
                              disabled={isDemoCompany}
                              className={isDemoCompany ? styles.materialInputBoxDemo : styles.materialInputBox}
                              placeholder=" "
                              value={values.name}
                              name="name"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                            <Typography className={styles.meteraillabel}>Employee Name</Typography>
                            {touched.name && errors.name && <FormHelperText error> {errors.name} </FormHelperText>}
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl disabled={isDemoCompany} fullWidth>
                          <InputLabel style={{ color: '#a9a9a9', fontWeight: '400', fontSize: '14px' }} id="department-select-label">
                            Department
                          </InputLabel>
                          <Select
                            className={classes.dropdownStyle}
                            labelId="department-select-label"
                            label="Department"
                            value={values.department}
                            name="department"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          >
                            {departmentList.map((department, index) => {
                              if (department.name) {
                                return (
                                  <MenuItem key={`emp_${index}`} value={department.name}>
                                    {department.name}
                                  </MenuItem>
                                )
                              }
                              return null
                            })}
                          </Select>
                        </FormControl>
                        {touched.department && errors.department && <FormHelperText error> {errors.department} </FormHelperText>}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className={styles.inputBoxContainer}>
                          <div className={styles.materialTextfield}>
                            <input
                              disabled={isDemoCompany}
                              className={isDemoCompany ? styles.materialInputBoxDemo : styles.materialInputBox}
                              placeholder=" "
                              value={values.title}
                              name="title"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                            <Typography className={styles.meteraillabel}>Title</Typography>

                            {touched.title && errors.title && <FormHelperText error> {errors.title} </FormHelperText>}
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            disabled={isDemoCompany}
                            open={openStartDatePicker && !isDemoCompany}
                            allowSameDateSelection
                            label="Start Date"
                            value={values.startDate}
                            name="startDate"
                            onBlur={handleBlur}
                            onChange={value => setFieldValue('startDate', value)}
                            onAccept={() => setOpenStartDatePicker(false)}
                            onClose={() => setOpenStartDatePicker(false)}
                            renderInput={params => (
                              <TextField
                                onClick={() => setOpenStartDatePicker(true)}
                                sx={{
                                  '.css-yfyucj-MuiInputBase-input-MuiOutlinedInput-input': {
                                    borderRadius: '8px'
                                  },
                                  ' .css-trmfuj-MuiOutlinedInput-notchedOutline': {
                                    borderRadius: '8px'
                                  },
                                  '.css-1qbcilp-MuiInputBase-root-MuiOutlinedInput-root': {
                                    borderRadius: '8px'
                                  }
                                }}
                                fullWidth
                                {...params}
                              />
                            )}
                            inputFormat="MM/DD/YYYY"
                          />
                        </LocalizationProvider>

                        {touched.startDate && errors.startDate && <FormHelperText error> {errors.startDate} </FormHelperText>}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl disabled={isDemoCompany} fullWidth>
                          <InputLabel style={{ color: '#a9a9a9', fontWeight: '400', fontSize: '14px' }} id="location-select-label">
                            Location
                          </InputLabel>
                          <Select
                            className={classes.dropdownStyle}
                            labelId="location-select-label"
                            label="Location"
                            value={values.location}
                            name="location"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          >
                            <MenuItem value="Overseas/Remote">Overseas/Remote</MenuItem>
                            {!islocationFound && <MenuItem value={values.location}>{values.location}</MenuItem>}
                            {LocationsList.map((location, index) => (
                              <MenuItem
                                key={`emp_${index}`}
                                // value={location.abbreviation}
                                value={`${location.abbreviation} - ${location.name}`}
                              >{`${location.abbreviation} - ${location.name}`}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {touched.location && errors.location && <FormHelperText error> {errors.location} </FormHelperText>}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className={styles.inputBoxContainer}>
                          <div className={styles.materialTextfield}>
                            <span style={{ position: 'absolute', top: '15px', left: '3px' }}>$</span>
                            <input
                              disabled={isDemoCompany}
                              className={isDemoCompany ? styles.materialInputBoxDemo : styles.materialInputBox}
                              value={values.salary}
                              name="salary"
                              // customInput={TextField}
                              type="text"
                              onBlur={handleBlur}
                              onChange={handleChangeNumber}
                            />

                            <Typography className={styles.meteraillabel}>Salary</Typography>
                            {touched.salary && errors.salary && <FormHelperText error> {errors.salary} </FormHelperText>}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  {!isDemoCompany && (
                    <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
                      <Button
                        className={classes.upgradeBtn}
                        disabled={isSubmitting}
                        onClick={() => {
                          setNext(false)
                          setEmpEditButtonClicked(false)
                          if (formRef.current) formRef.current.handleSubmit()
                          handleClick()
                        }}
                        size="medium"
                        color="secondary"
                        aria-label="add"
                      >
                        <Typography>{empEditButtonClicked ? 'Save' : 'Add'}</Typography>
                      </Button>

                      <Button
                        className={classes.upgradeBtn}
                        onClick={() => {
                          setEmpEditButtonClicked(false)
                          setEditItemEmp('')
                          setEmpInitialValues(formikEmpInitialValues)
                          formRef.current.resetForm(formikEmpInitialValues)
                        }}
                        size="medium"
                        color="secondary"
                        aria-label="add"
                      >
                        <Typography>Cancel</Typography>
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </form>
            )}
          </Formik>
        </Box>
        {disable ? (
          <Grid
            item
            xs={10}
            md="auto"
            style={{
              background: 'grey',
              width: '100%',
              height: '16em',
              marginTop: '-16em',
              opacity: '0.7',
              marginLeft: '-11px',
              textAlign: 'center',
              borderRadius: '7px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Grid>
              <Typography
                onClick={() => {
                  dispatch(setPaymentSetupModalScreen(3))
                  navigate('/company')
                  dispatch({ type: MENU_OPEN, id: 'company' })
                  dispatch(toggleCompanySetupModal(false))
                  dispatch(getCompaniesByUser())
                  dispatch(updateSetupModelPreviousStatus(false))
                }}
                style={{ cursor: 'pointer', color: 'blue', fontSize: '19px', fontWeight: '500' }}
              >
                Click here{' '}
              </Typography>
              <Typography sx={{ ml: 0.5 }} style={{ fontSize: '19px', color: 'white' }}>
                to upgrade your subscription tier in order to <br /> add more employees
              </Typography>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      {/* <Grid item xs={12} md={4.5} sx={{ padding: 1, background: '#FFFFFF', boxShadow: 5 }}>
        <Box sx={{ textAlign: 'center', mb: 2 }}>
          <Typography variant="h4">My Employees</Typography>
        </Box>
        <Divider sx={{ mb: 3 }} />
        <Box
          sx={{
            overflow: 'auto',
            maxHeight: windowHeight - 100,
            pl: 1,
            pr: 1,
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
              width: '0.4em'
            },
            '&::-webkit-scrollbar-track': {
              background: '#f1f1f1'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888'
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#555'
            }
          }}
        >
          <Grid container spacing={gridSpacing}>
            {employeeList.map((item, index) => (
              <Grid item xs={12} md={12} key={`coaitem-${index}`}>
                <Card variant="outlined" sx={{ background: '#091A36', borderRadius: 3 }}>
                  <CardHeader
                    title={
                      <Typography sx={{ color: '#ffffff' }} variant="h5">
                        {item.name}
                      </Typography>
                    }
                    action={
                      <>
                        {!isDemoCompany && (
                          <IconButton size="small" sx={{ color: '#488AC1' }} aria-label="edit" onClick={() => editEmployee(item)}>
                            <EditIcon fontSize="small" />
                          </IconButton>
                        )}
                        {!isDemoCompany && (
                          <IconButton
                            disabled={editItem === item._id}
                            size="small"
                            sx={{ color: '#488AC1' }}
                            aria-label="delete"
                            onClick={() => handleDeleteEmployee(item._id)}
                          >
                            <DeleteOutlineIcon fontSize="small" />
                          </IconButton>
                        )}
                      </>
                    }
                    sx={{ p: 1.5 }}
                  />
                  <Divider />
                  <CardContent sx={{ p: 1.5, pb: 0, mb: -2 }}>
                    <Grid container key={`childGrid${index}`}>
                      <Grid item xs={12} md={12} key={`coaitemd-${index}`}>
                        <Typography sx={{ color: '#E0E2E6' }} pb={1}>
                          Dept: <strong>{item.department}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={6} key={`coaitemsf-${index}`}>
                        <Typography sx={{ color: '#E0E2E6' }} mr={1} pb={1}>
                          Start Date: <strong>{moment(item.startDate).format('MM/DD/YYYY')}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={6} key={`coaitemt-${index}`}>
                        <Typography sx={{ color: '#E0E2E6' }} mr={1} pb={1}>
                          Title: <strong>{item.title}</strong>
                        </Typography>
                      </Grid>

                      <Grid item xs={6} md={6} key={`coaiteml-${index}`}>
                        <Typography sx={{ color: '#E0E2E6' }} pb={1}>
                          Location: <strong>{item.location}</strong>
                          {
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={6} key={`coaitems-${index}`}>
                        <Typography sx={{ color: '#E0E2E6' }}>
                          {
                          Salary: <strong>{`$${numberFormat(item.salary === null ? 0 : item.salary)}`}</strong>
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid> */}
      {/* </Grid> */}
      <PopupNotify
        message="Are you sure you want to proceed without saving your entry?"
        submitBtnText="Save"
        dismissBtnText="Don't Save"
        open={notify}
        onSubmit={() => {
          if (formRef.current) formRef.current.handleSubmit()
          dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
          setNotify(false)
        }}
        onDismiss={() => {
          setNext(false)
          dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
          dispatch(clearState())
          setNotify(false)
        }}
      />
      <PopupNotify
        message="Are you sure you want to proceed without adding employees?"
        submitBtnText="Yes"
        dismissBtnText="No"
        open={skip}
        onSubmit={() => {
          setNext(false)
          dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
          dispatch(clearState())
          setskip(false)
        }}
        onDismiss={() => {
          setskip(false)
        }}
      />
      {user.role === 'superAdmin' ? (
        <PopupNotify
          message="Please upgrade your subscription in order to add more employees"
          submitBtnText="Show Plans"
          dismissBtnText="Cancel"
          open={popupOpen}
          onSubmit={() => {
            setPopupOpen(false)
            dispatch(setPaymentSetupModalScreen(3))
            navigate('/company', { id: 'company' })
            dispatch(toggleCompanySetupModal(false))
          }}
          onDismiss={() => {
            setPopupOpen(false)
          }}
        />
      ) : (
        <PopupNotify
          message="Please upgrade your subscription in order to add more employees, Contact account Administrator"
          // submitBtnText="Show Plans"
          dismissBtnText="Cancel"
          open={popupOpen}
          // onSubmit={() => {
          //   setPopupOpen(false)
          //   dispatch(setPaymentSetupModalScreen(3))
          //   navigate('/company', { id: 'company' })
          //   dispatch(toggleCompanySetupModal())
          // }}
          onDismiss={() => {
            setPopupOpen(false)
          }}
        />
      )}
    </>
  )
})

EmployeesTabView.propTypes = {
  // title: PropTypes.any,
  // caption: PropTypes.any,
  // toolTip: PropTypes.any,
  // qbIcon: PropTypes.any,
  windowHeight: PropTypes.any,
  isDemoCompany: PropTypes.any,
  formikEmpInitialValues: PropTypes.any,
  empInitialValues: PropTypes.any,
  setEmpInitialValues: PropTypes.any
}

export default EmployeesTabView
