import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { Button, Divider, Drawer, IconButton, Menu, MenuItem, Typography } from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import ModeEditOutlineOutlined from '@material-ui/icons/ModeEditOutlineOutlined'
import DeleteOutlineOutlined from '@material-ui/icons/DeleteOutlineOutlined'
import ErrorOutline from '@material-ui/icons/ErrorOutline'

import { companySelector } from 'store/company/company.slice'

import cssClasses from '../infoTab.module.css'

const DepartmentList = props => {
  const {
    setAddListButtonClicked,
    handleEditClick,
    setEditButtonClicked,
    setAddedDept,
    addedDept,
    setAddButtonClicked,
    setDepartments,
    departments,
    deleteDepartment,
    setEdited
  } = props
  const { departmentList } = useSelector(companySelector)
  const [anchorEl, setAnchorEl] = useState(null)
  const [deleteClicked, setDeleteClicked] = useState(false)
  const [depId, setDepId] = useState(null)
  const [deleteId, setDeleteId] = useState(null)
  const [showWarning, setShowWarning] = useState(false)

  const open = Boolean(anchorEl)
  const handleClick = event => {
    setDepId(event.currentTarget.id)
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setDepId(null)
    setAnchorEl(null)
  }
  const handleDelete = deletedId => {
    setEdited(true)
    const d = [...departments]
    setDepartments(d.filter(item => item._id !== deletedId))
  }
  console.log(depId, 'depid')

  return (
    <div style={{ width: '100%' }}>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p className={cssClasses.drawerListHeading}>Departments or Functions</p>
        <Button
          onClick={() => {
            setAddListButtonClicked(true)
            setAddedDept([])
          }}
          className={cssClasses.transparentAddButton}
          variant="text"
        >
          {' '}
          <span style={{ marginRight: '10px' }}>+</span>Add
        </Button>
      </div>
      <Divider style={{ marginTop: '10px', color: 'rgba(9, 165, 127, 0.15)' }} />
      {showWarning && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '4px',
            border: '1px solid #CF2626',
            background: '#FEF6F6',
            padding: '16px'
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'flex-start', gap: '8px' }}>
            <ErrorOutline sx={{ fontSize: 'medium', color: '#CF2626', marginTop: '2px' }} />
            <Typography style={{ fontSize: '16px', fontWeight: '400', lineHeight: '125%', fontStyle: 'normal' }}>
              Deleting this department will remove it from your company. Please confirm deletion.
            </Typography>
          </div>
          <Button
            sx={{ alignSelf: 'end', color: '#CF2626', fontWeight: '500', fontSize: '14px' }}
            onClick={() => {
              setDeleteClicked(true)
              handleDelete(deleteId)
              setShowWarning(false)
            }}
          >
            Delete
          </Button>
        </div>
      )}
      <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
        {addedDept.map((eachItem, index) => (
          <li key={eachItem._id}>
            <div className={cssClasses.listContainer}>
              <p>{eachItem.name}</p>
              {/* <IconButton
                onClick={() => {
                  setAddListButtonClicked(true)
                  handleEditClick(eachItem)
                  setEditButtonClicked(true)
                  setAddedDept([])
                }}
              >
                <MoreHorizIcon />
              </IconButton> */}
              <IconButton onClick={handleClick} id={eachItem._id}>
                <MoreHorizIcon />
              </IconButton>
              <Menu
                sx={{ zIndex: '1500000' }}
                id="basic-menu"
                // id="basic-menu"
                anchorEl={anchorEl}
                // open={open}
                open={depId === eachItem._id}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button'
                }}
              >
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null)
                    setShowWarning(true)
                    setAddListButtonClicked(true)
                    handleEditClick(eachItem)
                    setEditButtonClicked(true)
                    setAddedDept([])
                    setDepId(null)
                  }}
                >
                  <ModeEditOutlineOutlined /> Edit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setShowWarning(true)
                    setAnchorEl(null)
                    setDeleteId(eachItem._id)
                    setDepId(null)
                  }}
                >
                  <DeleteOutlineOutlined /> Delete
                </MenuItem>
              </Menu>
            </div>
          </li>
        ))}
      </ul>
      <Divider sx={{ marginTop: '230px', marginBottom: '10px' }} />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button
          // onClick={() => handleCancelClick()}
          className={cssClasses.wizardCancelButton}
          type="button"
        >
          Cancel
        </button>
        <button
          onClick={() => {
            setAddedDept([])
            setAddButtonClicked(false)
            if (deleteClicked) deleteDepartment()
          }}
          type="button"
          className={cssClasses.wizardSaveButton}
        >
          Save
        </button>
      </div>
    </div>
  )
}

export default DepartmentList
