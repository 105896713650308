import React from 'react'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography } from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'

import { useDispatch } from 'react-redux'
import { setWelcomeScreenTriggering } from 'store/company/company.slice'
import welcomScreenImage from 'assets/images/Illustration.png'

import cssClasses from './infoTab.module.css'

const ModalCongratulationScreen = props => {
  const { isWelcomeScreenOpen } = props
  const dispatch = useDispatch()

  const onClickCloseButton = () => {
    dispatch(setWelcomeScreenTriggering(false))
  }

  return (
    <Dialog maxWidth="md" open={isWelcomeScreenOpen} sx={{ '& .MuiPaper-elevation': { maxWidth: '800px', maxHeight: '727px' } }}>
      <DialogTitle>
        <IconButton style={{ position: 'absolute', right: 10, top: 10 }} onClick={onClickCloseButton}>
          <CloseIcon sx={{ color: 'black' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', paddingTop: '30px' }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '50px 27px 21px'
          }}
        >
          <img style={{ height: '142px' }} src={welcomScreenImage} alt="welocome_image" />
          <Typography className={cssClasses.congratulationTabH}>
            Congratulations! You're now set to unleash the power <br /> of the mrgn Platform!
          </Typography>
          <Typography className={cssClasses.congratulationTabP}>
            We applaud your achievement! By successfully completing the onboarding process onto the mrgn Platform, you've taken a
            significant step towards unlocking the full potential of predictive intelligence for your small business. Your commitment to
            setting up your company, configuring departments, integrating expenses, managing your team, and selecting your business type
            showcases your dedication to informed decision-making and growth.
          </Typography>
          <Typography style={{ marginTop: '20px' }} className={cssClasses.congratulationTabP}>
            With mrgn's seamless integration of market data and operational insights, you're empowered to navigate your business journey
            with newfound clarity and strategic acumen. Here's to a future filled with data-driven success – welcome to a world where
            intelligence meets innovation!
          </Typography>
        </div>
        <Divider sx={{ width: '100%' }} />
      </DialogContent>
      <DialogActions>
        <button type="button" className={cssClasses.congratsCloseButton} onClick={onClickCloseButton}>
          Close
        </button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalCongratulationScreen
