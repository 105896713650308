// import { createStore } from 'redux'
import { persistStore } from 'redux-persist'
import reducer from './reducer'
import { configureStore } from '@reduxjs/toolkit'

// ===========================|| REDUX - MAIN STORE ||=========================== //

// const store = createStore(reducer)
const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: false
    })

    return middlewares
  }
})

const persister = persistStore(store)

export { store, persister }
