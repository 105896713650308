import { Grid, Input, InputAdornment, TextField } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AdapterDateFns from '@material-ui/lab/AdapterMoment'
import { DatePicker } from 'antd'
import { uiSelector } from 'store/ui/ui.slice'

import { getCompanyByID } from 'store/company/company.actions'
import { companySelector } from 'store/company/company.slice'
import { authSelector } from 'store/auth/auth.slice'
import moment from 'moment'

const TranchesAmount = props => {
  const { tabDetails, equityRaiseTranchCount, onEnterEachAmountHandler, onEnterEachDateHandler, setCurrentTranch } = props
  const { id, amount, date } = tabDetails
  const dispatch = useDispatch()

  setCurrentTranch(id)

  const { user } = useSelector(authSelector)
  const { selectedCompany, companies, equityAllocationList } = useSelector(companySelector)

  const { equityEditButtonClicked, tranchesLists } = useSelector(uiSelector)

  useEffect(() => {
    const payload = {
      companyId: selectedCompany,
      email: user.email
    }
    dispatch(getCompanyByID(payload))
  }, [equityEditButtonClicked, dispatch])

  const onEnterAmount = event => {
    onEnterEachAmountHandler(event)
  }

  const onEnterDate = event => {
    onEnterEachDateHandler(event)
  }
  const formatValues = e => e.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return (
    <Grid style={{ display: '-webkit-inline-box' }} item container xs={12} direction="column" justifyContent="center" alignItems="center">
      <Grid container item xs={6}>
        <TextField
          placeholder="Enter an Amount"
          onChange={onEnterAmount}
          id={id}
          value={formatValues(amount)}
          // value={tranchValue}
          // value={selectedTranchamount}
          sx={{ mr: 2, width: '220px' }}
          label="Amount raised"
          InputProps={{
            startAdornment: <span>$</span>,
            inputProps: {
              min: 1,
              type: 'string',
              allowNegative: 'false'
            }
          }}
        />
      </Grid>

      <Grid container item xs={6}>
        {/* <DatePicker
          id={id}
          value={date ? date.toString() : null}
          onChange={onEnterDate}
          picker="month"
          getPopupContainer={triggerNode => triggerNode.parentNode}
        /> */}
        <TextField
          placeholder="mm/dd/yyyy"
          sx={{ ml: 2, width: '220px' }}
          id={id}
          value={date}
          // value={equityEditButtonClicked ? moment(new Date(selectedTranch.startDate)).format('yyyy-MM-dd') : null}
          // value={date ? date.toString() : null}
          onChange={onEnterDate}
          label="Expected receipt date"
          InputProps={{
            inputProps: {
              type: 'date',
              max: '9999-12-31'
            }
          }}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    </Grid>
  )
}

export default TranchesAmount
