import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'
import {
  getModelDebtDataByID,
  getModelPeopleDataByID,
  getModelRevenueDataByID,
  getModelSummaryDataByID,
  getEquityRaiseSummaryDataByID,
  getModelVendorDataByID,
  getModelPeopleDataUpdate,
  getModelPeopleDataByIDWithVersion,
  getModelVendorDataByIDWithVersion,
  getModelDataUpdateCells
} from './models.actions'

const initialState = {
  summary: {
    headers: [],
    subHeaders: [],
    revenue: [],
    expense: [],
    netIncome: [],
    cogs: [],
    equityDeptData: [],
    cac: [],
    acv: [],
    arr: [],
    ltv: [],
    ltvcacRatio: [],
    churn: [],
    costPerLead: [],
    ruleOf40: [],
    cogscors: [],
    retention: [],
    mom: [],
    burnrate: [],
    cashOnHand: [],
    runway: []
  },
  debt: {
    headers: [],
    subHeaders: [],
    revenue: [],
    expense: [],
    netIncome: [],
    upFrontFees: [],
    principleRepayment: [],
    interestRepayment: [],
    payTotal: [],
    loanReceipt: [],
    cashOnHand: [],
    principleAmount: 0,
    interestRate: 0,
    durationMonth: 0,
    loanReceiptDate: '',
    principleRepaymentDate: '',
    intersetRepaymentdate: '',
    upFrontFeesAmount: ''
  },
  people: {
    headers: [],
    subHeaders: [],
    empcells: [],
    empBenefits: [],
    empTaxes: [],
    totalPayroll: [],
    contractorcells: [],
    totalContractor: [],
    empGrandTotal: [],
    currentBurn: 0
  },
  vendors: {
    headers: [],
    subHeaders: [],
    vendorcells: [],
    productcells: [],
    totalVendors: [],
    currentBurn: 0
  },
  revenue: {
    headers: [],
    subHeaders: [],
    revenuecells: [],
    clientcells: [],
    productcells: [],
    total: [],
    revenueModel: '',
    revenueComputationType: ''

    // yearHeaders: [],
    // monthHeaders: [],
    // quarterHeaders: [],
    // clientmonthcells: [],
    // clientQtrcells: [],
    // clientYrcells: [],
    // productmonthcells: [],
    // productQtrcells: [],
    // productYrcells: [],
    // totalMonthly: [],
    // totalYearly: [],
    // totalQtrly: []
  },
  versions: [],
  isLoading: false,
  isSuccess: false,
  getModelSummaryDataError: '',
  getModelDebtDataError: '',
  getModelPeopleDataError: '',
  getModelVendorDataError: '',
  getModelRevenueDataError: '',
  editedcellUpdationStatus: false
}

export const modelsSlice = createSlice({
  name: 'models',
  initialState,
  reducers: {
    clearState: state => {
      state.isLoading = false
      state.isSuccess = false
      state.getModelSummaryDataError = ''
      state.getModelPeopleDataError = ''
      state.getModelVendorDataError = ''
      state.getModelRevenueDataError = ''
    },
    resetState: () => initialState,
    resetEditedcellStatus: state => {
      state.editedcellUpdationStatus = false
    }
  },
  extraReducers: {
    [getModelPeopleDataByID.fulfilled]: (state, { payload }) => {
      state.people = {
        ...payload,
        empcells: [...payload.empcells].map(emp => ({
          ...emp,
          datacells: [...emp.datacells].map(cell => ({
            ...cell,
            selected: false,
            currentValue: cell.currentValue || cell.currentValue === 0 ? cell.currentValue : cell,
            count: cell.count ? cell.count : 0
          }))
        })),
        contractorcells: [...payload.contractorcells].map(ctr => ({
          ...ctr,
          datacells: [...ctr.datacells].map(cell => ({
            ...cell,
            selected: false,
            currentValue: cell.currentValue || cell.currentValue === 0 ? cell.currentValue : cell,
            count: cell.count ? cell.count : 0
          }))
        }))
      }
      state.isLoading = false
      state.isSuccess = true
    },
    [getModelPeopleDataByID.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.getModelPeopleDataError = payload
    },
    [getModelPeopleDataByID.pending]: state => {
      state.isLoading = true
    },

    [getModelPeopleDataByIDWithVersion.fulfilled]: (state, { payload }) => {
      state.people = {
        ...payload,
        empcells: [...payload.empcells].map(emp => ({
          ...emp,
          datacells: [...emp.datacells].map(cell => ({
            ...cell,
            selected: false,
            currentValue: cell.currentValue || cell.currentValue === 0 ? cell.currentValue : cell,
            count: cell.count ? cell.count : 0
          }))
        })),
        contractorcells: [...payload.contractorcells].map(ctr => ({
          ...ctr,
          datacells: [...ctr.datacells].map(cell => ({
            ...cell,
            selected: false,
            currentValue: cell.currentValue || cell.currentValue === 0 ? cell.currentValue : cell,
            count: cell.count ? cell.count : 0
          }))
        }))
      }
      state.isLoading = false
      state.isSuccess = true
      state.versions = payload.versions
    },
    [getModelPeopleDataByIDWithVersion.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.getModelPeopleDataError = payload
    },
    [getModelPeopleDataByIDWithVersion.pending]: state => {
      state.isLoading = true
    },

    [getModelVendorDataByID.fulfilled]: (state, { payload }) => {
      state.vendors = {
        ...payload,
        vendorcells: [...payload.vendorcells].map(vendor => ({
          ...vendor,
          datacells: [...vendor.datacells].map(cell => ({
            ...cell,
            selected: false,
            currentValue: cell.currentValue || cell.currentValue === 0 ? cell.currentValue : cell,
            count: cell.count ? cell.count : 0
          }))
        }))
      }
      state.isLoading = false
      state.isSuccess = true
    },
    [getModelVendorDataByIDWithVersion.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.getModelVendorDataError = payload
    },
    [getModelVendorDataByIDWithVersion.pending]: state => {
      state.isLoading = true
    },

    [getModelVendorDataByIDWithVersion.fulfilled]: (state, { payload }) => {
      state.vendors = {
        ...payload,
        vendorcells: [...payload.vendorcells].map(vendor => ({
          ...vendor,
          datacells: [...vendor.datacells].map(cell => ({
            ...cell,
            selected: false,
            currentValue: cell.currentValue || cell.currentValue === 0 ? cell.currentValue : cell,
            count: cell.count ? cell.count : 0
          }))
        }))
      }
      state.isLoading = false
      state.isSuccess = true
      state.versions = payload.versions
    },
    [getModelVendorDataByID.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.getModelVendorDataError = payload
    },
    [getModelVendorDataByID.pending]: state => {
      state.isLoading = true
    },

    [getModelRevenueDataByID.fulfilled]: (state, { payload }) => {
      state.revenue = {
        ...payload
      }
      // state.revenue = {
      //   ...payload,
      //   clientmonthcells: [...payload.clientmonthcells].map(month => ({
      //     ...month,
      //     monthcells: [...month.monthcells].map(cell => ({
      //       ...cell,
      //       selected: false
      //     }))
      //   }))
      // }
      state.isLoading = false
      state.isSuccess = true
    },
    [getModelRevenueDataByID.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.getModelRevenueDataError = payload
    },
    [getModelRevenueDataByID.pending]: state => {
      state.isLoading = true
    },

    [getModelSummaryDataByID.fulfilled]: (state, { payload }) => {
      state.summary = payload
      state.isLoading = false
      state.isSuccess = true
    },
    [getModelSummaryDataByID.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.getModelSummaryDataError = payload
    },
    [getModelSummaryDataByID.pending]: state => {
      state.isLoading = true
    },
    [getEquityRaiseSummaryDataByID.fulfilled]: (state, { payload }) => {
      state.summary = payload
      state.isLoading = false
      state.isSuccess = true
    },
    [getEquityRaiseSummaryDataByID.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.getModelSummaryDataError = payload
    },
    [getEquityRaiseSummaryDataByID.pending]: state => {
      state.isLoading = true
    },
    [getModelDebtDataByID.fulfilled]: (state, { payload }) => {
      state.debt = payload
      state.isLoading = false
      state.isSuccess = true
    },
    [getModelDebtDataByID.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.getModelDebtDataError = payload
    },
    [getModelDebtDataByID.pending]: state => {
      state.isLoading = true
    },
    [getModelPeopleDataUpdate.fulfilled]: (state, { payload }) => {
      state.debt = payload
      state.isLoading = false
      state.isSuccess = true
    },
    [getModelPeopleDataUpdate.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.getModelDebtDataError = payload
    },
    [getModelPeopleDataUpdate.pending]: state => {
      state.isLoading = true
    },
    [getModelDataUpdateCells.fulfilled]: (state, { payload }) => {
      state.editedcellUpdationStatus = true
    },
    [getModelDataUpdateCells.rejected]: (state, { payload }) => {
      state.editedcellUpdationStatus = false
    },
    [getModelDataUpdateCells.pending]: state => {}
  }
})

export const { clearState, resetState, resetEditedcellStatus } = modelsSlice.actions
export const modelsSelector = state => state.models
export default modelsSlice.reducer
