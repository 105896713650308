import guestNavigation from '../menu-items-guest/guest-navigation'
import mainNavigation from './main-navigation'
import { authSelector } from 'store/auth/auth.slice'

// ===========================|| MENU ITEMS ||=========================== //

const menuItems = {
  items: [mainNavigation]
}

export default menuItems
