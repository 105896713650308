import React, { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import { DialogContent, DialogActions, Grid, Button } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import {
  uiSelector,
  toggleEquityRaiseInitialModelVisible,
  setEquityRaiseInitialModelScreen,
  clearEquityRaise,
  setTranchListData,
  setEquityEditButtonClicked
} from 'store/ui/ui.slice'
import { resetState, companySelector, clearState } from 'store/company/company.slice'
import { addEquityRaiseData } from 'store/company/company.actions'
import { getEquityRaiseSummaryDataByID } from 'store/models/models.actions'
import { modelsSelector } from 'store/models/models.slice'
import { useTheme } from '@emotion/react'
import { TabPanel } from 'ui-component/tab'
import EquityRaiseTabOne from './equityRaiseTabOne'
import EquityRaiseTabTwo from './equityRaiseTabTwo'
import EquityRaiseTabThree from './equityRaiseTabThree'
import BasicModal from '../modal/BasicModal'

const useStyles = makeStyles(theme => ({
  muiDialog: {
    '& .MuiDialog-paper': {
      maxHeight: '503px',
      overflow: 'hidden',
      width: '806px',
      top: '75',
      left: '372',
      padding: 0,
      border: ' #707070 solid 1px '
    }
  },
  muiDialogContent: {
    [theme.breakpoints.up('sm')]: {
      width: '100%'
    },
    background: '#F0F0F0'
  },
  gridFillHeight: {
    [theme.breakpoints.up('sm')]: {
      height: '100%'
    }
  },
  closeButton: {
    color: '#091A36',
    marginRight: '17px',
    marginTop: '12px',
    marginBottom: '0px',
    float: 'right',
    fontSize: '40px',
    cursor: 'pointer'
  },
  modalHeading: {
    fontWeight: '600',
    fontSize: '28px',
    color: '#091A36',
    textAlign: 'center',
    marginBottom: '25px'
  }
}))

const EquityRaiseInialModel = () => {
  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { equityRaiseTranchCount, equityRaiseInitialModelScreen, equityRaiseInitialModelVisible, tranchesLists, equityEditButtonClicked } =
    useSelector(uiSelector)
  const { selectedCompany, isSuccess, companies, equityAllocationList } = useSelector(companySelector)
  const [canNavigateNextScreen, setCanNavigateNextScreen] = useState(false)
  const [filterBreakdown, setFilterBreakdown] = useState(0)
  const [filterFrequency, setFilterFrequency] = useState(1)
  const [filterTimeline, setFilterTimeline] = useState(1)
  const childRef = useRef()
  const [tranchStep, setTranchStepNo] = useState(0)
  const [toBeAllocatedAmountTranch, setTobeAllocatedAmount] = useState('')

  // const selectedCompEqRaiseData = companies.find(eachComp => eachComp.id === selectedCompany)

  // const equityAllocationListData = selectedCompEqRaiseData?.equityAllocationList

  const [tranchesDataList, setDataInTranches] = useState([
    { id: 'tranch_one', amount: '', date: '' },
    { id: 'tranch_two', amount: '', date: '' },
    { id: 'tranch_three', amount: '', date: '' },
    { id: 'tranch_four', amount: '', date: '' },
    { id: 'tranch_five', amount: '', date: '' },
    { id: '', amount: '', date: '' }
  ])

  const tabList = [
    { id: 'tranch_one', displayText: 'First tranch', number: 1, tranchName: 'Tranch One' },
    { id: 'tranch_two', displayText: 'Second tranch', number: 2, tranchName: 'Tranch Two' },
    { id: 'tranch_three', displayText: 'Third tranch', number: 3, tranchName: 'Tranch Three' },
    { id: 'tranch_four', displayText: 'Fourth tranch', number: 4, tranchName: 'Tranch Four' },
    { id: 'tranch_five', displayText: 'Fifth tranch', number: 5, tranchName: 'Tranch Five' },
    { id: '', displayText: '', number: '', tranchName: '' }
  ]

  const [AllDeptAllocatedList, setAllDeptAllocatedList] = useState([
    {
      tranchId: tabList[0].id,
      totalAmount: tranchesLists[0].amount,
      date: tranchesLists[0].date,
      tranchName: tabList[0].tranchName,
      eachTranchDeptAllocatedList: []
    },
    {
      tranchId: tabList[1].id,
      totalAmount: tranchesLists[1].amount,
      date: tranchesLists[1].date,
      tranchName: tabList[1].tranchName,
      eachTranchDeptAllocatedList: []
    },
    {
      tranchId: tabList[2].id,
      totalAmount: tranchesLists[2].amount,
      date: tranchesLists[2].date,
      tranchName: tabList[2].tranchName,
      eachTranchDeptAllocatedList: []
    },
    {
      tranchId: tabList[3].id,
      totalAmount: tranchesLists[3].amount,
      date: tranchesLists[3].date,
      tranchName: tabList[3].tranchName,
      eachTranchDeptAllocatedList: []
    },
    {
      tranchId: tabList[4].id,
      totalAmount: tranchesLists[4].amount,
      date: tranchesLists[4].date,
      tranchName: tabList[4].tranchName,
      eachTranchDeptAllocatedList: []
    }
  ])

  const deptData = AllDeptAllocatedList.map(item => item.eachTranchDeptAllocatedList)
  //

  return (
    <BasicModal
      isOpen={equityRaiseInitialModelVisible}
      heading="Equity Raise"
      handleClose={() => {
        dispatch(toggleEquityRaiseInitialModelVisible())
        dispatch(setEquityRaiseInitialModelScreen(0))
        dispatch(setEquityEditButtonClicked(false))
      }}
    >
      <DialogContent
        sx={{
          py: 0,
          overflow: 'hidden',
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar': {
            width: '0.4em'
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555'
          }
        }}
        className={classes.muiDialogContent}
      >
        <Grid sx={{ overflow: 'hidden' }} container justifyContent="center" alignItems="center">
          <TabPanel value={equityRaiseInitialModelScreen} index={0} boxStyles={{ py: 0 }}>
            <EquityRaiseTabOne />
          </TabPanel>
          <TabPanel value={equityRaiseInitialModelScreen} index={1}>
            <EquityRaiseTabTwo
              setDataInTranches={setDataInTranches}
              tranchesDataList={tranchesDataList}
              setCanNavigateNextScreen={setCanNavigateNextScreen}
              // equityAllocationListData={equityAllocationListData}
              equityEditButtonClicked={equityEditButtonClicked}
            />
          </TabPanel>
          <TabPanel value={equityRaiseInitialModelScreen} index={2}>
            <EquityRaiseTabThree
              setAllDeptAllocatedList={setAllDeptAllocatedList}
              tabList={tabList}
              setCanNavigateNextScreen={setCanNavigateNextScreen}
              setTranchStepNo={setTranchStepNo}
              setTobeAllocatedAmount={setTobeAllocatedAmount}
              // equityAllocationListData={equityAllocationListData}
              ref={childRef}
              tranchStep={tranchStep}
              deptData={deptData}
            />
          </TabPanel>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ pr: 5, py: 2, background: 'white' }}>
        {/* <<<<<< step 1 >>>>>> */}
        {equityRaiseInitialModelScreen === 0 && (
          <Button
            onClick={e => {
              if (equityRaiseTranchCount > 0) {
                dispatch(setEquityRaiseInitialModelScreen(1))
                dispatch(clearEquityRaise())
              }
            }}
            size="large"
            variant="contained"
            disabled={equityRaiseTranchCount < 1}
          >
            Next
          </Button>
        )}
        {/* <<<<< step 2 >>>>> */}
        {equityRaiseInitialModelScreen === 1 && (
          <div>
            <Button onClick={e => dispatch(setEquityRaiseInitialModelScreen(0))} size="large" variant="outlined" sx={{ mr: 2 }}>
              Back
            </Button>
            <Button
              disabled={!canNavigateNextScreen}
              onClick={() => {
                if (canNavigateNextScreen) {
                  dispatch(setEquityRaiseInitialModelScreen(2))
                  dispatch(setTranchListData(tranchesDataList))
                  setCanNavigateNextScreen(false)
                }
              }}
              size="large"
              variant="contained"
            >
              Next
            </Button>
          </div>
        )}
        {/* <<<<< step 3 >>>>> */}
        {equityRaiseInitialModelScreen === 2 && (
          <div>
            {tranchStep === 0 ? (
              <Button onClick={e => dispatch(setEquityRaiseInitialModelScreen(1))} size="large" variant="outlined" sx={{ mr: 2 }}>
                Back
              </Button>
            ) : (
              <Button
                onClick={() => {
                  childRef.current.handleBack()
                }}
                size="large"
                variant="outlined"
                sx={{ mr: 2 }}
              >
                Back
              </Button>
            )}

            {!(tranchStep + 1 === equityRaiseTranchCount) ? (
              <Button
                disabled={toBeAllocatedAmountTranch !== 0}
                size="large"
                variant="contained"
                onClick={() => {
                  childRef.current.handleNext()
                }}
              >
                Next
              </Button>
            ) : (
              <Button
                disabled={toBeAllocatedAmountTranch !== 0}
                size="large"
                variant="contained"
                onClick={e => {
                  const data = {
                    selectedCompany,
                    tranchesLists,
                    deptData
                  }

                  dispatch(addEquityRaiseData(data))
                  if (isSuccess) {
                    const data = {
                      companyId: selectedCompany,
                      frequency: filterFrequency,
                      breakdown: filterBreakdown,
                      timeline: filterTimeline
                    }
                    dispatch(getEquityRaiseSummaryDataByID(data))
                  }
                  dispatch(toggleEquityRaiseInitialModelVisible())
                }}
              >
                Submit
              </Button>
            )}
          </div>
        )}
      </DialogActions>
    </BasicModal>
  )
}

export default EquityRaiseInialModel
