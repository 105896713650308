import { createAsyncThunk } from '@reduxjs/toolkit'
import { SERVER_URL } from 'utils/API'
import { axiosInstance } from 'utils/API/AxiosConfig'

const getDashboardData = createAsyncThunk('company/getdashboarddata', async (payload, thunkAPI) => {
  try {
    const response = await axiosInstance.get(
      `${SERVER_URL}/company/getDashboardData`,
      { params: { ...payload } },
      {
        handlerEnabled: true
      }
    )

    if (response.status === 201 || response.status === 200) {
      return response.data
    }
    return thunkAPI.rejectWithValue(response.message)
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message)
  }
})
export { getDashboardData }
