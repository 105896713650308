import { Box, Grid, LinearProgress, Typography } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import DoneIcon from '@material-ui/icons/Done'
import { makeStyles } from '@material-ui/styles'
import logDark from 'assets/images/mrgn-logo-s-dark.png'

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authSelector, updateSetupModelPreviousStatus } from 'store/auth/auth.slice'
import { getCompanyByID } from 'store/company/company.actions'
import {
  changeLastTabLoaded,
  companySelector,
  resetCompanyLoadedStatus,
  setCompanyId,
  setWizardTriggeringStatus,
  setIsWizardVisible
} from 'store/company/company.slice'
import { setCompanySetupModalScreen, setSelectedMenuItem, toggleCompanySetupModal, uiSelector } from 'store/ui/ui.slice'

const useStyles = makeStyles({
  root: {
    background: '#F5F8F9',
    height: 20,
    borderRadius: 8,
    '& .MuiLinearProgress-bar': {
      background: 'linear-gradient(to right, #cc95c0, #dbd4b4, #7aa1d2)'
    }
  },
  typography: {
    fontSize: 12,
    fontWeight: 300,
    color: '#797979'
  }
})

const SetupDashboard = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const {
    selectedCompany,
    id,
    departmentList,
    revenueModel,
    currentlyActiveCompanyId,
    employeeList,
    contractorList,
    vendorList,
    clientList,
    productList,
    revenueComputationType
  } = useSelector(companySelector)
  const { user } = useSelector(authSelector)
  const { selectedMenuItemId } = useSelector(uiSelector)

  const [isMenuVisible, setIsMenuVisible] = useState(true)
  const [companyMenuList, setCompanyMenuList] = useState([
    { name: 'Company', id: 'company', modelScreen: 1, isVisible: true, timeLeft: 1 },
    { name: 'Department', id: 'department', modelScreen: 2, isVisible: true, timeLeft: 8 },
    { name: 'Expenses', id: 'expenses', modelScreen: 3, isVisible: true, timeLeft: 8 },
    { name: 'Team', id: 'team', modelScreen: 5, isVisible: true, timeLeft: 8 },
    { name: 'Business Type', id: 'revenue', modelScreen: 6, isVisible: true, timeLeft: 8 }
  ])

  useEffect(() => {
    const data = {
      companyId: currentlyActiveCompanyId || selectedCompany,
      email: user.email
    }
    dispatch(getCompanyByID(data))
  }, [dispatch, currentlyActiveCompanyId, selectedCompany, user.email])

  const revenueFirstModel = () => {
    if (revenueComputationType === 'Top Line Billable') {
      return clientList.length > 0
    }
    return clientList.length > 0 && productList.length > 0
  }

  const isRevenueTabCompleted = () => {
    if (revenueModel === 'Pro Services') {
      return revenueFirstModel()
    }
    return productList.length > 0
  }

  const getStepsCompletedCount = type => {
    let completedStepsCount = 0
    if (id) completedStepsCount += 1
    if (departmentList.length > 0) completedStepsCount += 1
    if (vendorList.some(eachItem => eachItem.department)) completedStepsCount += 1
    if (employeeList.length > 0 || contractorList.length > 0) completedStepsCount += 1
    if (revenueModel && isRevenueTabCompleted()) completedStepsCount += 1
    return type === 'value' ? completedStepsCount : (completedStepsCount / 5) * 100
  }

  const isStepCompleted = step => {
    switch (step) {
      case 'company':
        if (id) return true

        return false
      case 'department':
        if (departmentList.length > 0) return true

        return false
      case 'expenses':
        if (vendorList.some(eachItem => eachItem.department)) {
          return true
        }

        return false
      case 'team':
        if (employeeList.length > 0 || contractorList.length > 0) return true

        return false
      case 'revenue':
        return isRevenueTabCompleted(step)
      default:
        return false
    }
  }

  const updateModalTabVisibility = () => {
    const tempCompanyMenuList = [...companyMenuList]
    tempCompanyMenuList.map(menuList => {
      if (isStepCompleted(menuList.id)) menuList.isVisible = !isMenuVisible
      return menuList
    })
    setCompanyMenuList(tempCompanyMenuList)
    setIsMenuVisible(!isMenuVisible)
  }

  const showCompanyWizard = screen => {
    dispatch(setWizardTriggeringStatus(true))
    dispatch(resetCompanyLoadedStatus())
    dispatch(setCompanyId(currentlyActiveCompanyId || selectedCompany))
    dispatch(toggleCompanySetupModal(true))
    dispatch(setCompanySetupModalScreen(screen))
    dispatch(changeLastTabLoaded(false))
    dispatch(updateSetupModelPreviousStatus(true))
  }

  return (
    <>
      <Box
        sx={{
          right: 0,
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          top: 83,
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            width: 300,
            // height: '90%',
            background: '#fff',
            borderRadius: 4,
            zIndex: 101,
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
            padding: 2,
            paddingBottom: 1,
            // overflow: 'auto'
            minHeight: '200px'
          }}
        >
          <Grid container>
            <Grid item xs={10}>
              <img style={{ marginLeft: 7, width: 50, height: 50 }} src={logDark} alt="mrgn" />
            </Grid>
            <Grid item xs={2} sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
              <Box
                sx={{
                  '&:hover': {
                    background: '#0068C9',
                    borderRadius: 10,
                    color: '#fff',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    padding: 1
                  }
                }}
              >
                <ArrowForwardIosIcon onClick={() => dispatch(setIsWizardVisible(false))} sx={{ fontSize: 'medium' }} />
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography sx={{ fontSize: 18, color: '#00213C', fontWeight: 600 }}>COMPLETE TO START</Typography>
            </Grid>
            <Grid item xs={12} sx={{ mt: 1 }}>
              <Typography>{getStepsCompletedCount('value')} of 5 completed </Typography>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <LinearProgress classes={{ root: classes.root }} variant="determinate" value={(getStepsCompletedCount('value') / 5) * 100} />
            </Grid>
            <Grid item xs={12} sx={{ mt: 1, color: '#00213C', mb: 1 }}>
              <Typography classes={{ root: classes.typography }}> Progress: {getStepsCompletedCount('percentage')} % </Typography>
            </Grid>
            {companyMenuList.map(
              (menuList, index) =>
                menuList.isVisible && (
                  <Grid
                    item
                    xs={12}
                    key={index + menuList.id}
                    sx={{ display: 'flex', flexDirection: 'row', mt: 2, alignItems: 'center', justifyContent: 'center' }}
                  >
                    <Grid container sx={{ display: 'flex', flexDirection: 'column', minHeight: '35px' }}>
                      <Grid item>
                        <Grid
                          container
                          xs={12}
                          sx={{
                            cursor: 'pointer',
                            '&:hover': { color: '#68CDFE' },
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                          onClick={() => {
                            showCompanyWizard(menuList.modelScreen)
                            dispatch(setSelectedMenuItem(menuList.id))
                          }}
                        >
                          <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {selectedMenuItemId === menuList.id ? (
                              <ArrowForwardIosIcon sx={{ color: '#B0B0B0', fontSize: 'medium', transform: 'rotate(180deg)' }} />
                            ) : (
                              <ArrowForwardIosIcon sx={{ color: '#B0B0B0', fontSize: 'medium' }} />
                            )}
                          </Grid>
                          <Grid item xs={isStepCompleted(menuList.id) ? 9 : 11}>
                            <Typography
                              sx={{
                                fontSize: '18px',
                                color: isStepCompleted(menuList.id) ? '#A5A5A5' : '#707070',
                                alignContent: 'center',
                                fontWeight: isStepCompleted(menuList.id) ? 600 : 600
                                // '&:hover': { ml: 1, transition: 'margin 700ms' }
                              }}
                            >
                              {menuList.name}
                            </Typography>
                          </Grid>

                          {isStepCompleted(menuList.id) && (
                            <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <DoneIcon sx={{ color: '#0F9D58' }} />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item>
                        {!isStepCompleted(menuList.id) && (
                          <Typography
                            sx={{
                              fontSize: 14,
                              fontWeight: 300,
                              ml: 3
                            }}
                          >
                            {' '}
                            {menuList.timeLeft === 1 ? `About ${menuList.timeLeft} minute` : `About ${menuList.timeLeft} minutes`}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )
            )}
          </Grid>
        </Box>
        <Box
          onClick={() => updateModalTabVisibility()}
          sx={{
            mt: 2,
            width: 250,
            height: 50,
            background: '#fff',
            borderRadius: 2,
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
            padding: 2,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            '&:hover': { background: '#1E1E1E', color: '#fff' }
          }}
        >
          <ArrowForwardIosIcon sx={{ fontSize: 'medium', transform: 'rotate(-90deg)', mr: 1 }} />
          <Typography>Hide completed tasks ({getStepsCompletedCount('value')})</Typography>
        </Box>
      </Box>
    </>
  )
}

export default SetupDashboard
