import React, { useRef, useImperativeHandle, forwardRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, Box } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@emotion/react'
import monitor from 'assets/images/monitor1.png'
import rocketImage from 'assets/images/rocketImage.png'
import cloudImage from 'assets/images/cloudImage.png'
import { Typography } from 'antd'
import { setCompanySetupModalScreen, uiSelector } from 'store/ui/ui.slice'
import { propTypes } from 'react-markdown'
import { gridSpacing } from 'store/constant'
import cssClasses from './infoTab.module.css'
import { updateCompany } from 'store/company/company.actions'
import { companySelector } from 'store/company/company.slice'

const WelcomeScreen = forwardRef((props, ref) => {
  const dispatch = useDispatch()
  const { windowHeight, windowWidth } = props
  const { companySetupModalScreen } = useSelector(uiSelector)
  const { id } = useSelector(companySelector)

  useImperativeHandle(ref, () => ({
    handleNext() {
      dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))

      if (id !== '') {
        const data = {
          id,
          currentModelScreen: companySetupModalScreen + 1
        }
        dispatch(updateCompany(data))
      }
    }
  }))

  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        maxHeight: windowHeight - 2000,
        minHeight: windowHeight - 2000,
        minWidth: windowWidth,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      spacing={gridSpacing}
    >
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Typography
          style={{
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '29px',
            lineHeight: 'normal',
            color: 'var(--light-basic-primary, #242731)',
            fontFamily: 'Raleway'
          }}
        >
          Welcome to mrgn
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ width: 600 }}>
          <Typography
            style={{
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '22px',
              color: 'var(--light-basic-secondary, #575F6E)',
              textAlign: 'center',
              fontFamily: 'Raleway'
            }}
          >
            You’re about to explore new frontiers of financial modeling. Exciting huh? First we’ll collect a bit of information about your
            company, then crunch some numbers for you.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 0, height: (windowHeight * 50) / 100 }}>
        {/* <div className={cssClasses.monitorContainer}> */}
        <img src={monitor} alt="mrgn" className={cssClasses.monitor} />
        {/* <div className={cssClasses.rocketCont}> */}
        <img src={rocketImage} className={cssClasses.rocket} alt="Rocket" />
        <img src={cloudImage} className={cssClasses.rocketCloud} alt="Cloud" />
        {/* </div> */}
      </Grid>
    </Grid>
  )
})
WelcomeScreen.propTypes = {
  windowHeight: propTypes.any,
  windowWidth: propTypes.any
}
export default WelcomeScreen
