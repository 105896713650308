import React, { useEffect, useRef, useState } from 'react'
import cssClasses from 'ui-component/company-setup-modal/infoTab.module.css'

import { Formik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { setRegistrationSetupModelScreen, uiSelector } from 'store/ui/ui.slice'
import { companySelector } from 'store/company/company.slice'
import { FormHelperText } from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'
import NumberFormat from 'react-number-format'
import { finishCompanyCreate, updateCompany } from 'store/company/company.actions'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  inputFocusedDate: {
    '& .MuiInputBase-input , .MuiOutlinedInput-root ': { backgroundColor: '#ffff' },
    border: '1px solid  #d8d8d8',
    borderRadius: '4px',
    color: '#29A364',
    height: '42px'
  },
  inputNonfocusedDate: {
    '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
    '& .MuiInputBase-input , .MuiOutlinedInput-root': { backgroundColor: '#ffff', color: '#B1B3B7' },
    marginTop: '0',
    '& .MuiInputAdornment-root': { display: 'none' }
  }
}))

const TopLineBillable = props => {
  const classes = useStyles()
  const { currentIndexNo, setCurrentIndex, revenueModelItem, revenueComputationTypeItem, isDemoCompany } = props
  const formRefTopLine = useRef(null)
  const inputFieldRef = [useRef(null), useRef(null), useRef(null), useRef(null)]
  const dispatch = useDispatch()
  const { registrationSetupModelScreen } = useSelector(uiSelector)
  const { id, clientList } = useSelector(companySelector)
  const formRevenueTopLineInitialValues = {
    _id: '',
    name: '',
    growthRate: '',
    startDate: '',
    startingRevenue: '',
    submit: null
  }
  const [formInitialValues, setformInitialValues] = useState(formRevenueTopLineInitialValues)
  // const formRevenueTopLineInitialValues = {
  //   _id: '',
  //   name: '',
  //   growthRate:'',
  //   startDate: startDate ? dayjs(startDate) : '',
  //   startingRevenue: startingRevenue?.toLocaleString('en-US') || '',
  //   submit: null
  // }

  // if (clientList.length > 0) {
  //   const [{ name, startDate, startingRevenue, growthRate }] = clientList
  //   console.log(name, startDate, startingRevenue, growthRate, 'clientlistvaluesdeset')
  //   setformInitialValues(prev => ({ ...prev, name, startDate, startingRevenue, growthRate }))
  // }

  useEffect(() => {
    if (clientList.length > 0) {
      const [{ name, startDate, startingRevenue, growthRate }] = clientList
      setformInitialValues(prev => ({
        ...prev,
        name,
        startDate: dayjs(startDate),
        startingRevenue: startingRevenue.toLocaleString('en-US'),
        growthRate: growthRate.toLocaleString('en-US')
      }))
    }
  }, [])

  const handleAddRevenueTopLineTabInputs = (name, growthRate, startDate, startingRevenue) => {
    const payload = {
      name,
      growthRate: parseFloat(growthRate.replace(/[$,]/g, '')),
      // growthRate: growthRate.toString().includes('%') ? parseFloat(growthRate.replace(/%/g, '')) : growthRate,
      startDate: dayjs(startDate).format('YYYY/MM'),
      startingRevenue: parseFloat(startingRevenue.replace(/[$,]/g, '')),
      growthIncrement: 'Monthly'
    }

    const clientPayload = [...clientList]
    clientPayload.push(payload)
    if (id !== '') {
      const data = {
        id,
        revenueModel: revenueModelItem,
        revenueComputationType: revenueComputationTypeItem,
        clientList: clientPayload
      }
      dispatch(updateCompany(data))
    }
  }

  useEffect(() => {
    if (inputFieldRef[0].current) {
      inputFieldRef[0]?.current?.focus()
    }
  }, [])
  useEffect(() => {
    inputFieldRef[currentIndexNo - 3]?.current?.focus()
  }, [currentIndexNo])

  const onClickTopLIneNextButton = () => {
    if (inputFieldRef[currentIndexNo - 3].current?.value !== '') {
      if (currentIndexNo === 6) {
        if (formRefTopLine.current) {
          formRefTopLine.current?.handleSubmit()
        }
      } else {
        setCurrentIndex(prevState => prevState + 1)
      }
    } else {
      inputFieldRef[currentIndexNo - 3]?.current?.focus()
    }
  }
  const onClickOkButton = fieldName => {
    formRefTopLine.current.setFieldTouched(fieldName, true)
    if (inputFieldRef[currentIndexNo - 3].current?.value !== '' && !formRefTopLine.current.errors[fieldName]) {
      if (currentIndexNo === 6) {
        if (formRefTopLine.current) {
          formRefTopLine.current?.handleSubmit()
        }
      } else {
        setCurrentIndex(prevState => prevState + 1)
      }
    } else {
      inputFieldRef[currentIndexNo - 3]?.current?.focus()
    }
  }
  const handleKeyPress = (event, index) => {
    if (event.charCode === 13) {
      event.preventDefault()
      // inputFieldRef[index]?.current?.focus()
      onClickOkButton(event.target.name)
    }
  }

  // const onClickTopLIneNextButton = () => {
  //   if (currentIndexNo === 6) {
  //     if (formRefTopLine.current) {
  //       formRefTopLine.current?.handleSubmit()
  //     }
  //   } else {
  //     setCurrentIndex(prevState => prevState + 1)
  //   }
  // }
  const CurrentDate = new Date().setHours(0, 0, 0, 0)
  const getClassByIndex = fieldIndex => {
    const isPreviousIndex = currentIndexNo - 1 === fieldIndex
    const isNextIndex = currentIndexNo + 1 === fieldIndex

    switch (true) {
      case isPreviousIndex:
        return cssClasses.nonFocusedBoxTop
      case isNextIndex:
        return cssClasses.nonFocusedBoxBottom
      default:
        return cssClasses.box
    }
  }

  return (
    <Formik
      initialValues={formInitialValues}
      innerRef={formRefTopLine}
      enableReinitialize
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Name is required'),
        growthRate: Yup.string().required('Percentage growth per period is required').max(30, 'Maximum 30 numbers are allowed'),
        // .matches(/^\d{1,4}(\.\d+)?%?$/, 'Enter a valid number'),
        startDate: Yup.date().required('Start month and year is required').typeError('Please enter a valid date'),
        startingRevenue: Yup.string().required('Starting revenue is required')
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting, setFieldError, resetForm }) => {
        handleAddRevenueTopLineTabInputs(
          values.name,
          values.growthRate,
          values.startDate,
          // values.startingRevenue.toString().includes(',') ? parseInt(values.startingRevenue.replace(/,/g, ''), 10) : values.startingRevenue
          values.startingRevenue
        )
        setStatus({ success: true })
        setSubmitting(false)
        dispatch(setRegistrationSetupModelScreen(registrationSetupModelScreen + 1))
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
        <form noValidate onSubmit={handleSubmit}>
          <div>
            {(currentIndexNo === 3 || currentIndexNo - 1 === 3) && (
              <div className={getClassByIndex(3)}>
                <label htmlFor="client_name" className={currentIndexNo === 3 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}>
                  3. One of my clients is named{' '}
                </label>
                {(currentIndexNo === 3 || currentIndexNo === 4) && (
                  <input
                    ref={ref => {
                      inputFieldRef[0].current = ref
                    }}
                    onKeyPress={event => handleKeyPress(event, 1)}
                    disabled={isDemoCompany}
                    style={{ paddingLeft: '20px' }}
                    placeholder="Type here..."
                    className={currentIndexNo === 3 ? cssClasses.focusedInputBox : cssClasses.nonFocusedInputBox}
                    value={values.name}
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    id="client_name"
                  />
                )}
                <div style={{ height: '10px' }}>
                  {touched.name && errors.name && <FormHelperText error> {errors.name} </FormHelperText>}
                </div>
                {currentIndexNo === 3 && (
                  <div className={cssClasses.okButtonContainer}>
                    <button
                      className={values.name ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                      type="button"
                      onClick={() => onClickOkButton('name')}
                    >
                      Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                    </button>
                    <p className={cssClasses.textPress}>
                      press <span className={cssClasses.textEnter}>Enter</span>
                    </p>
                  </div>
                )}
              </div>
            )}

            {(currentIndexNo === 4 || currentIndexNo - 1 === 4) && (
              <div className={getClassByIndex(4)}>
                <label htmlFor="startDate" className={currentIndexNo === 4 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}>
                  {`4. We started, or will start, selling to ${values.name === undefined ? '' : values.name} on `}
                </label>

                {(currentIndexNo === 4 || currentIndexNo === 5) && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <input
                      ref={ref => {
                        inputFieldRef[1].current = ref
                      }}
                      style={{ fontSize: '26px' }}
                      onKeyPress={event => handleKeyPress(event, 2)}
                      type="date"
                      value={values.startDate}
                      id="starting_date"
                      name="startDate"
                      placeholder="Select"
                      onBlur={handleBlur}
                      className={currentIndexNo === 4 ? cssClasses.focusedInputBox : cssClasses.nonFocusedInputBox}
                      onChange={event => setFieldValue('startDate', event.target.value)}
                    /> */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        views={['month', 'year']}
                        inputRef={ref => {
                          inputFieldRef[1].current = ref
                        }}
                        name="startDate"
                        value={values.startDate}
                        onBlur={handleBlur}
                        placeholder="Select"
                        slotProps={{
                          textField: {
                            name: 'startDate',
                            id: 'startDate',
                            size: 'small',
                            error: false,
                            onKeyPress: event => {
                              handleKeyPress(event, 2)
                            }
                          }
                        }}
                        format="MM/YYYY"
                        sx={{
                          width: '300px',
                          height: '44px',
                          marginTop: '22px'
                        }}
                        onKeyPress={event => {
                          handleKeyPress(event, 3)
                        }}
                        // className={currentIndexNo === 3 ? cssClasses.selectBox : cssClasses.nonFocusedInputBox}
                        className={currentIndexNo === 4 ? classes.inputFocusedDate : classes.inputNonfocusedDate}
                        onChange={value => {
                          setFieldValue('startDate', value)
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                )}
                <div style={{ height: '10px' }}>
                  {touched.startDate && errors.startDate && <FormHelperText error> {errors.startDate} </FormHelperText>}
                </div>

                {currentIndexNo === 4 && (
                  <div className={cssClasses.okButtonContainer}>
                    <button
                      className={values.startDate ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                      type="button"
                      onClick={() => onClickOkButton('startDate')}
                    >
                      Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                    </button>
                    <p className={cssClasses.textPress}>
                      press <span className={cssClasses.textEnter}>Enter</span>
                    </p>
                  </div>
                )}
              </div>
            )}

            {(currentIndexNo === 5 || currentIndexNo - 1 === 5) && (
              <div className={getClassByIndex(5)}>
                <label htmlFor="invoiced" className={currentIndexNo === 5 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}>
                  {CurrentDate > new Date(values.startDate).setHours(0, 0, 0, 0)
                    ? `5. Last month, we invoiced ${values.name} this much`
                    : `5. We plan to invoice ${values.name} this much in our first month of servicing them`}
                </label>
                {(currentIndexNo === 5 || currentIndexNo === 6) && (
                  <div className={currentIndexNo === 5 ? cssClasses.focusedInputDiv : cssClasses.nonFocusedInputDiv}>
                    {' '}
                    {/* <span className={currentIndexNo === 5 ? cssClasses.focusedInputStaticText : cssClasses.nonFocusedInputStaticText}>
                      $
                    </span> */}
                    <NumberFormat
                      prefix="$"
                      getInputRef={ref => {
                        inputFieldRef[2].current = ref
                      }}
                      onKeyPress={event => handleKeyPress(event, 3)}
                      disabled={isDemoCompany}
                      style={{ marginTop: '0', paddingLeft: '20px' }}
                      placeholder="Type $ here..."
                      className={currentIndexNo === 5 ? cssClasses.focusedInputBox : cssClasses.nonFocusedInputBox}
                      value={values.startingRevenue}
                      name="startingRevenue"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      id="invoiced"
                      thousandSeparator=","
                    />
                  </div>
                )}

                <div style={{ height: '10px' }}>
                  {touched.startingRevenue && errors.startingRevenue && <FormHelperText error> {errors.startingRevenue} </FormHelperText>}
                </div>
                {currentIndexNo === 5 && (
                  <div className={cssClasses.okButtonContainer}>
                    <button
                      className={values.startingRevenue ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                      type="button"
                      onClick={() => onClickOkButton('startingRevenue')}
                    >
                      Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                    </button>
                    <p className={cssClasses.textPress}>
                      press <span className={cssClasses.textEnter}>Enter</span>
                    </p>
                  </div>
                )}
              </div>
            )}

            {(currentIndexNo === 6 || currentIndexNo - 1 === 6) && (
              <div className={currentIndexNo === 6 ? cssClasses.box : cssClasses.nonFocusedBoxBottom}>
                <label htmlFor="growth_rate" className={currentIndexNo === 6 ? cssClasses.focusedLabel : cssClasses.noneFocusedLabel}>
                  {`6. We generally expect to increase sales to ${values.name} each month by `}{' '}
                </label>{' '}
                {(currentIndexNo === 6 || currentIndexNo === 7) && (
                  <div
                    // style={{ width: '120px', marginTop: '10px' }}
                    className={currentIndexNo === 6 ? cssClasses.focusedInputDiv : cssClasses.nonFocusedInputDiv}
                  >
                    {/* <div className={currentIndexNo === 6 ? cssClasses.focusedInputDiv : cssClasses.nonFocusedInputDiv}> */}
                    {/* <span
                      className={currentIndexNo === 6 ? cssClasses.focusedInputStaticTextRight : cssClasses.nonFocusedInputStaticTextRight}
                    >
                      %
                    </span> */}
                    <NumberFormat
                      // suffix="%"
                      prefix="$"
                      getInputRef={ref => {
                        inputFieldRef[3].current = ref
                      }}
                      onKeyPress={event => handleKeyPress(event, 4)}
                      disabled={isDemoCompany}
                      style={{ marginTop: 0 }}
                      placeholder="Type $ here..."
                      className={currentIndexNo === 6 ? cssClasses.focusedInputBox : cssClasses.nonFocusedInputBox}
                      value={values.growthRate}
                      name="growthRate"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      id="growth_rate"
                      thousandSeparator=","
                    />
                    {/* </div> */}
                  </div>
                )}
                <div style={{ height: '10px' }}>
                  {touched.growthRate && errors.growthRate && <FormHelperText error> {errors.growthRate} </FormHelperText>}
                </div>
                {currentIndexNo === 6 && (
                  <div className={cssClasses.okButtonContainer}>
                    <button
                      className={values.growthRate ? cssClasses.okButtonEnabled : cssClasses.okButtonDisabled}
                      type="button"
                      onClick={() => onClickOkButton('growthRate')}
                    >
                      Ok <DoneIcon style={{ fontSize: '14px', marginLeft: '5px' }} />{' '}
                    </button>
                    <p className={cssClasses.textPress}>
                      press <span className={cssClasses.textEnter}>Enter</span>
                    </p>
                  </div>
                )}
              </div>
            )}

            {currentIndexNo > 2 && (
              <button
                type="button"
                className={currentIndexNo === 6 ? cssClasses.formNextButton : cssClasses.disabledFormNextButton}
                onClick={onClickTopLIneNextButton}
              >
                Next
              </button>
            )}
          </div>
        </form>
      )}
    </Formik>
  )
}

export default TopLineBillable
