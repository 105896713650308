import React, { useState, useEffect } from 'react'

import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@material-ui/core'

import { makeStyles } from '@material-ui/styles'
import { useDispatch, useSelector } from 'react-redux'
import { setPaymentSetupModalScreen } from 'store/ui/ui.slice'
import { getCompaniesByUser } from 'store/company/company.actions'
import { MENU_OPEN } from 'store/actions'
import { useNavigate } from 'react-router'
import { companySelector } from 'store/company/company.slice'

const useStyles = makeStyles({
  muiDialog: {
    '& .MuiDialog-paper': {
      height: '200px',
      width: '35%',
      overflow: 'hidden'
    }
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  dialogText: {
    color: 'black',
    fontSize: '18px',
    fontWeight: 400
  },
  dialogContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

const OnFreeTierModel = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isModelOpen, setIsModelOpen] = useState(false)

  const { userTier } = useSelector(companySelector)

  const handleSubmit = () => {
    dispatch(setPaymentSetupModalScreen(3))
    navigate('/company')
    dispatch({ type: MENU_OPEN, id: 'company' })
    dispatch(getCompaniesByUser())
  }

  useEffect(() => {
    if (userTier === 'free_tier') {
      setIsModelOpen(true)
    } else {
      setIsModelOpen(false)
    }
  }, [userTier])

  return (
    <Dialog maxWidth="sm" className={classes.muiDialog} open={isModelOpen} aria-labelledby="freeTier_Dialog" sx={{ py: 0 }}>
      <DialogContent className={classes.dialogContent}>
        <Typography className={classes.dialogText}>
          “Please select a{' '}
          <span>
            <button
              onClick={handleSubmit}
              type="button"
              style={{
                border: 'none',
                backgroundColor: 'transparent',
                cursor: 'pointer',
                outline: 'none',
                fontSize: '18px',
                fontWeight: 600
              }}
            >
              payment tier
            </button>
          </span>{' '}
          in order to access all of the wonderful features on mrgn!”
        </Typography>
      </DialogContent>
      {/* <DialogActions sx={{ p: '14px 14px 2px 0px' }}>
        <Grid container sx={{ mt: 1 }} justifyContent="flex-end">
          <Button type="submit" size="large" variant="contained" onClick={handleSubmit}>
            Upgrade
          </Button>
        </Grid>
      </DialogActions> */}
    </Dialog>
  )
}

export default OnFreeTierModel
