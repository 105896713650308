import React, { useRef, useState } from 'react'
import {
  Box,
  Divider,
  Drawer,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextareaAutosize,
  Typography
} from '@material-ui/core'
import cssClasses from './infoTab.module.css'

import UploadIcon from '../../assets/images/icons/UploadIcon.png'

import { Formik } from 'formik'
import * as Yup from 'yup'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  selectOption: {
    color: '#000',
    fontFamily: 'Raleway',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '125%',
    padding: '8px 16px',
    '&:hover': {
      color: '#29A364',
      background: '#F9F6FF'
    }
    // '&.Mui-selected': { backgroundColor: '#F9F6FF' }
  }
}))

const NoteToExpertPage = props => {
  const classes = useStyles()
  const { setCurrentTabNumber } = props
  const formRef = useRef(null)

  const initialState = {
    topic: '',
    message: ''
  }

  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          paddingRight: '3px'
        }}
      >
        <h1 className={cssClasses.drawerHeading}>Talk to an expert.</h1>
        <p className={cssClasses.drawerDesc}>
          Escalon has proudly become the nation's foremost provider of essential business services. With a track record of helping over
          5,000 growing companies streamline their back-office operations, we understand the unique challenges you're facing.
        </p>
        <p className={cssClasses.drawerDesc}>
          All that to say, Escalon here to assist if you are looking for support with accounting, financial optimization, or any other
          aspect of your business
        </p>
      </div>
      <div style={{ width: '100%' }}>
        <p className={cssClasses.drawerPageNumber}>1/2</p>
        <Typography style={{ marginTop: 0, marginBottom: '11px' }} className={cssClasses.drawerHeadingSecond}>
          Note to expert
        </Typography>
        <Divider />

        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={initialState}
          validationSchema={Yup.object().shape({
            topic: Yup.string().required('Please select a topic'),
            message: Yup.string().required('Please enter the message')
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            setCurrentTabNumber(prevState => prevState + 1)
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
            resetForm,
            isValid,
            dirty
          }) => (
            <form noValidate onSubmit={handleSubmit} style={{ width: '100%', marginTop: '24px' }}>
              <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column' }}>
                <label htmlFor="topic" className={cssClasses.inputLabel}>
                  Select topic <span className={cssClasses.important}>*</span>{' '}
                </label>
                <Select
                  displayEmpty
                  disableUnderline
                  variant="standard"
                  sx={{
                    '& .MuiSelect-outlined': {
                      background: '#fff',
                      padding: '3px 14px'
                    }
                  }}
                  MenuProps={{ style: { zIndex: 1100000 } }}
                  placeholder="Topic"
                  className={cssClasses.wizardInputBox}
                  value={values.topic}
                  name="topic"
                  onBlur={handleBlur}
                  onChange={event => {
                    handleChange(event)
                    // setSelectedRevenueComputationalType(event.target.value)
                  }}
                  id="topic"
                >
                  <MenuItem disabled value="" style={{ display: 'none' }}>
                    Select
                  </MenuItem>
                  <MenuItem value="Insurance" className={classes.selectOption}>
                    Insurance
                  </MenuItem>
                  <MenuItem className={classes.selectOption} value="Finance">
                    Finance
                  </MenuItem>
                  <MenuItem className={classes.selectOption} value="Accounting">
                    Accounting
                  </MenuItem>
                  <MenuItem className={classes.selectOption} value="Taxes">
                    Taxes
                  </MenuItem>
                  <MenuItem className={classes.selectOption} value="Recruiting">
                    Recruiting
                  </MenuItem>
                  <MenuItem className={classes.selectOption} value="HR">
                    HR
                  </MenuItem>
                  <MenuItem className={classes.selectOption} value="Payroll">
                    Payroll
                  </MenuItem>
                  <MenuItem className={classes.selectOption} value="Benefits">
                    Benefits
                  </MenuItem>
                </Select>

                {touched.topic && errors.topic && <FormHelperText error> {errors.topic} </FormHelperText>}
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  marginTop: '20px'
                }}
              >
                <label htmlFor="message" className={cssClasses.inputLabel}>
                  Message <span className={cssClasses.important}>*</span>{' '}
                </label>
                <textarea
                  style={{ height: '248px' }}
                  placeholder="Write your note here"
                  className={cssClasses.wizardInputBox}
                  value={values.message}
                  name="message"
                  onBlur={handleBlur}
                  onChange={event => {
                    handleChange(event)
                    // setSelectedRevenueComputationalType(event.target.value)
                  }}
                  id="message"
                />

                {touched.message && errors.message && <FormHelperText error> {errors.message} </FormHelperText>}
              </div>
              {/* <div>
                <Typography className={cssClasses.drawerHeadingSecond}>File upload (optional)</Typography>
                <p className={cssClasses.drawerUploadDesc}>
                  If you have a file or screen shot you wish to reference during your conversation with the expert, upload thatfile below.{' '}
                </p>
                <div className={cssClasses.uploadFileCont}>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'center' }}>
                    {' '}
                    <img src={UploadIcon} alt="upload_icon" className={cssClasses.uploadIconImg} />
                    <Typography className={cssClasses.pdragAndDrop}>
                      Drag & drop files or <span className={cssClasses.spanBrowser}> Browse </span>
                    </Typography>
                    <Typography className={cssClasses.textFormates}>Supported formats: .xls, .xlsb, .xlsm, .xlsx</Typography>
                  </div>
                </div>
              </div> */}
              <Divider sx={{ marginTop: '79px', marginBottom: '10px' }} />
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button
                  onClick={() => {
                    formRef.current.resetForm(initialState)
                  }}
                  className={cssClasses.wizardCancelButton}
                  type="button"
                >
                  Cancel
                </button>
                <button type="submit" className={isValid && dirty ? cssClasses.wizardSaveButton : cssClasses.disabledButton}>
                  Next
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  )
}

export default NoteToExpertPage
