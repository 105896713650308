import React, { useEffect, useState } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { Dialog, DialogContent, DialogTitle, Grid, Typography, CardContent, Box, IconButton } from '@material-ui/core'

import { useDispatch, useSelector } from 'react-redux'
import { gridSpacing } from 'store/constant'
import { LoadingButton } from '@material-ui/lab'
import PopupNotify from 'ui-component/tab/Model/PopupNotify'
import { setCompanySetupModalScreen, toggleCompanySetupModal, togglePaymentSetupModel } from 'store/ui/ui.slice'
import { authSelector, setNewUserWPLogin, setNewUserWPPaid } from 'store/auth/auth.slice'
import { companySelector, setCompanyId } from 'store/company/company.slice'

export default function CheckoutForm() {
  const stripe = useStripe()
  const elements = useElements()

  const [message, setMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [warning, setWarning] = useState(false)
  const { wpNewUser } = useSelector(authSelector)
  const { selectedCompany, id } = useSelector(companySelector)
  const dispatch = useDispatch()

  let selectedCompanyId = null

  if (selectedCompany === '') {
    selectedCompanyId = id
  } else {
    selectedCompanyId = selectedCompany
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)
    if (wpNewUser) dispatch(setNewUserWPPaid({ status: true }))

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/loader?selected-company=${selectedCompanyId}`
      }
    })

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message)
    } else {
      setMessage(error.message)
    }

    setIsLoading(false)
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <Grid container spacing={gridSpacing} sx={{ mt: 2 }} justifyContent="center">
        <Grid item>
          <LoadingButton
            size="large"
            variant="contained"
            loading={isLoading}
            id="cancel"
            type="button"
            sx={{ textAlign: 'center' }}
            onClick={() => {
              setWarning(true)
            }}
          >
            Cancel
          </LoadingButton>
        </Grid>
        <Grid item>
          <LoadingButton
            size="large"
            variant="contained"
            disabled={!stripe || !elements}
            loading={isLoading}
            id="submit"
            type="submit"
            sx={{ textAlign: 'center' }}
          >
            Pay Now
          </LoadingButton>
        </Grid>
      </Grid>
      <Grid container spacing={gridSpacing} sx={{ mt: 2 }} justifyContent="center">
        {' '}
        {message && <div id="payment-message">{message}</div>}
      </Grid>
      <PopupNotify
        message="Are you sure you don’t want to proceed. Feel free to stick around and play with the free version"
        submitBtnText="Continue with free plan"
        dismissBtnText="Continue with paid plan"
        open={warning}
        onSubmit={() => {
          dispatch(togglePaymentSetupModel())

          setWarning(false)
          if (wpNewUser) {
            dispatch(setCompanyId(selectedCompanyId))
            dispatch(toggleCompanySetupModal(true))
            dispatch(setCompanySetupModalScreen(0))
            dispatch(setNewUserWPLogin({ status: false }))
          }
        }}
        onDismiss={() => {
          setWarning(false)
        }}
      />
    </form>
  )
}
