import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes, { object } from 'prop-types'

import {
  Select,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  CardHeader,
  Divider,
  IconButton,
  Paper,
  InputBase
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import moment from 'moment'

import excel from 'assets/images/excel.png'
import {
  setCompanySetupModalScreen,
  setExcelImportTabModel,
  toggleCompanySetupModal,
  toggleExcelImportTabModel,
  uiSelector,
  setSelectedMenuItem
} from 'store/ui/ui.slice'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import EditIcon from '@material-ui/icons/Edit'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import AddIcon from '@material-ui/icons/Add'
import SearchIcon from '@material-ui/icons/Search'
import EditPencilIcon from '../../assets/images/icons/EditPencilIcon.svg'
import Adjustments from '../../assets/images/icons/adjustments.svg'

import { finishCompanyCreate, updateCompany } from 'store/company/company.actions'
import EmployeesTabView from './EmployeesTabView'
import ContractorsTabView from './ContractorsTabView'
import { companySelector, clearState, setCurrentlyActiveCompanyId } from 'store/company/company.slice'
import { authSelector } from 'store/auth/auth.slice'

import { numberFormat } from 'utils/ui'
import { gridSpacing } from 'store/constant'

import dayjs from 'dayjs'
import excelWithA from 'assets/images/excelWithA.png'

import styles from '../../styles.module.css'
import cssClasses from './infoTab.module.css'
import MyTeamSideDrawer from './MyTeamSideDrawer'

const useStyles = makeStyles(theme => ({
  mainContainer: {
    minWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100%'
  },
  cardContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  myTeamCardContainer: {
    display: 'flex',
    flexDirection: 'column '
  },
  excel: {
    width: '53px',
    height: '31px',
    objectFit: 'contain',
    // marginTop: -25,
    borderRadius: 30,
    cursor: 'pointer'
    // '&:hover': {
    //   background: '#c6e9f5'
    // }
  },
  hAndIconContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '10px',
    justifyContent: 'space-between',
    width: '100%'
  },
  dropdownStyle: {
    width: '100%',
    borderRadius: '8px !important',
    '& .MuiOutlinedInput-input': {
      borderRadius: '8px !important'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px !important'
    }
  }
}))

const MyTeamTab = forwardRef((props, ref) => {
  const { isDemoCompany, windowHeight, isTriggeredFromDashboard } = props
  const { contractorList, employeeList, selectedCompany, id, isSuccess, isComplete, isSuccessCompanyCreate } = useSelector(companySelector)
  const { user } = useSelector(authSelector)
  const { companySetupModalScreen } = useSelector(uiSelector)
  const classes = useStyles()
  const dispatch = useDispatch()
  const [selectedTeam, setSelectedTeam] = useState('')
  const [editItemEmp, setEditItemEmp] = useState('')
  const [editItemCont, setEditItemCont] = useState('')
  const [isFinish, setIsFinish] = useState(false)
  const [skip, setskip] = useState(false)
  const [isNext, setNext] = useState(false)
  const [notify, setNotify] = useState(false)
  // const tempRef = useRef()

  const [isAddButtonClicked, setAddButtonClicked] = useState(false)
  const [contEditButtonClicked, setContEditButtonClicked] = useState(false)
  const [empEditButtonClicked, setEmpEditButtonClicked] = useState(false)
  const [teamMemberData, setTeamMemberData] = useState('')
  const [selectedEmpCard, setSelectedEmpCard] = useState('')
  const [selectedContCard, setSelectedContCard] = useState('')
  const [mouseEnterOnCard, setMouseEnterOnCard] = useState('')
  const [isFinishSetup, setIsFinishSetup] = useState(false)

  useImperativeHandle(ref, () => ({
    handleNext(flag) {
      // tempRef.current.empHandleNext(flag)
      // setIsFinishSetup(true)
      // dispatch(setSelectedMenuItem(''))
      // if (isSuccess) {
      //   const data = {
      //     companyId: id
      //   }
      //   dispatch(finishCompanyCreate(data))
      // }
      dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
      // dispatch(setCurrentlyActiveCompanyId(id))
    }
  }))

  // useEffect(() => {
  //   if (isSuccessCompanyCreate) {
  //     // dispatch(toggleCompanySetupModal())
  //     dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
  //   }
  // }, [dispatch, isSuccessCompanyCreate])

  // useEffect(() => {
  //   if (isFinishSetup) {
  //     if (isTriggeredFromDashboard) {
  //       dispatch(clearState())

  //     } else {
  //       dispatch(toggleCompanySetupModal())
  //     }
  //   }
  // }, [dispatch, id, isSuccess, isFinishSetup, isTriggeredFromDashboard])

  //
  const commonInitialValues = {
    _id: '',
    name: '',
    purpose: '',
    department: '',
    function: '',
    startDate: null,
    location: '',
    annualCompensation: '',
    submit: null,
    title: '',
    salary: '',
    employeeType: ''
  }
  const formikInitialValues = {
    _id: '',
    name: '',
    purpose: '',
    department: '',
    function: '',
    startDate: null,
    location: '',
    annualCompensation: '',
    submit: null
  }
  // const [initialValues, setInitialValues] = useState(formikInitialValues)
  const [initialValues, setInitialValues] = useState(commonInitialValues)

  const formikEmpInitialValues = {
    _id: '',
    name: '',
    department: '',
    title: '',
    startDate: null,
    location: '',
    salary: '',
    submit: null
  }
  const [empInitialValues, setEmpInitialValues] = useState(formikEmpInitialValues)

  const handleChange = event => {
    setSelectedTeam(event.target.value)
  }

  // useEffect(() => { }, [selectedTeam])

  // useImperativeHandle(ref, () => ({
  //   handleNext(flag) {
  //     if (flag) setIsFinish(true)
  //     if (contractorList.length === 0 || (formRef?.current.values.name && contractorList.length > 0)) {
  //       if (contractorList.length === 0 && !formRef?.current.values.name) {
  //         setskip(true)
  //       } else if (formRef?.current.isValid) {
  //         setNotify(true)
  //         setNext(true)
  //       } else {
  //         dispatch(clearState())
  //         if (formRef?.current) formRef?.current.handleSubmit()
  //         setNext(true)
  //       }
  //     } else if (flag) {
  //       dispatch(clearState())
  //       const data = {
  //         companyId: id
  //       }
  //       dispatch(finishCompanyCreate(data))
  //     } else {
  //       setNext(false)
  //       dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
  //       dispatch(clearState())
  //     }
  //   }
  // }))

  const handleDeleteContractor = _id => {
    if (editItemCont === _id) return
    // if (formRef.current.values._id) {
    //   setNotify(true)
    //   return
    // }
    const logArray = []
    const contractor = [...contractorList].filter(item => item._id === _id)

    const employeeChangeLog = {
      user: user.id,
      userName: user.name,
      company: selectedCompany,
      date: new Date(),
      tabChanged: 'Contractors',
      department: contractor[0].department,
      lineItemChanged: `${contractor[0].name}(Contractor deleted)`,
      originalValue: contractor[0].name,
      newValue: `N/A`
    }
    logArray.push(employeeChangeLog)
    const contractorPayload = [...contractorList].filter(item => item._id !== _id)
    if (id !== '') {
      const data = {
        id,
        contractorList: contractorPayload,
        currentModelScreen: companySetupModalScreen + 1,
        systemLog: logArray
      }
      dispatch(updateCompany(data))
    }
  }

  const editContractor = item => {
    setEditItemCont(item._id)
    const tempObject = { ...item }
    const d = new Date(tempObject.startDate)

    setInitialValues({
      ...item,
      startDate: dayjs(d).format('YYYY/MM/DD'),
      submit: null
    })
  }

  const handleDeleteEmployee = _id => {
    // if (formRef.current.values._id) {
    //   setNotify(true)
    //   return
    // }
    if (editItemEmp === _id) return
    const logArray = []
    const employee = [...employeeList].filter(item => item._id === _id)

    const employeeChangeLog = {
      user: user.id,
      userName: user.name,
      company: selectedCompany,
      date: new Date(),
      tabChanged: 'Employee',
      department: employee[0].department,
      lineItemChanged: `${employee[0].name}(Employee deleted)`,
      originalValue: employee[0].name,
      newValue: `N/A`
    }
    logArray.push(employeeChangeLog)
    const employeePayload = [...employeeList].filter(item => item._id !== _id)
    if (id !== '') {
      const data = {
        id,
        employeeList: employeePayload,
        currentModelScreen: companySetupModalScreen + 1,
        systemLog: logArray
      }
      dispatch(updateCompany(data))
    }
  }

  const editEmployee = item => {
    setEditItemEmp(item._id)
    const tempObject = { ...item }
    const d = new Date(tempObject.startDate)

    setEmpInitialValues({
      ...item,
      startDate: dayjs(d).format('YYYY/MM/DD'),
      submit: null
    })
  }

  // const setEmplOrContFrom = () => {
  //   switch (selectedTeam) {
  //     case 'employee':
  //       return (
  //         <EmployeesTabView
  //           empInitialValues={empInitialValues}
  //           setEmpInitialValues={setEmpInitialValues}
  //           formikEmpInitialValues={formikEmpInitialValues}
  //           isDemoCompany={isDemoCompany}
  //           windowHeight={windowHeight}
  //           editItemEmp={editItemEmp}
  //           setEditItemEmp={setEditItemEmp}
  //           setEmpEditButtonClicked={setEmpEditButtonClicked}
  //           empEditButtonClicked={empEditButtonClicked}
  //           // ref={tempRef}
  //         />
  //       )
  //     case 'contractor':
  //       return (
  //         <ContractorsTabView
  //           initialValues={initialValues}
  //           setInitialValues={setInitialValues}
  //           isDemoCompany={isDemoCompany}
  //           windowHeight={windowHeight}
  //           formikInitialValues={formikInitialValues}
  //           editItemCont={editItemCont}
  //           setEditItemCont={setEditItemCont}
  //           setContEditButtonClicked={setContEditButtonClicked}
  //           contEditButtonClicked={contEditButtonClicked}
  //           // ref={tempRef}
  //         />
  //       )
  //     default:
  //       return null
  //   }
  // }

  const EmployeeCard = ({ employee }) => {
    const [expand, setExpand] = useState(false)
    const d = new Date(employee.startDate)
    const startedDate = d.toLocaleDateString('en-US', {
      timeZone: 'UTC',
      month: 'numeric',
      day: 'numeric',
      year: 'numeric'
    })
    return (
      <div className={cssClasses.teamCard}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <Typography className={cssClasses.cardName}>Team member</Typography>
          <div>
            <input
              disabled={isDemoCompany}
              type="image"
              alt="edit_Icon"
              src={EditPencilIcon}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setSelectedTeam('employee')
                setTeamMemberData(employee)
                setEmpEditButtonClicked(true)
              }}
            />
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            {' '}
            <Typography className={cssClasses.cardAccount}>Employee name</Typography>
            {expand ? (
              <KeyboardArrowUp
                sx={{ width: '16px', height: '16px' }}
                onClick={() => setExpand(prev => !prev)}
                style={{ cursor: 'pointer' }}
              />
            ) : (
              <KeyboardArrowDown
                sx={{ width: '16px', height: '16px' }}
                onClick={() => setExpand(prev => !prev)}
                style={{ cursor: 'pointer' }}
              />
            )}
          </div>
          <Typography className={cssClasses.cardAccountName}>{employee.name}</Typography>
        </div>{' '}
        {expand && (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div>
                <Typography className={cssClasses.cardAccount}>Employee type</Typography>
                <Typography className={cssClasses.cardAccountName}>Employee</Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <Typography className={cssClasses.cardAccount}>Department</Typography>
                <Typography className={cssClasses.cardAccountName}>{employee.department}</Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <Typography className={cssClasses.cardAccount}>Title</Typography>
                <Typography className={cssClasses.cardAccountName}>{employee.title}</Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <Typography className={cssClasses.cardAccount}>Start date</Typography>
                <Typography className={cssClasses.cardAccountName}>{startedDate}</Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <Typography className={cssClasses.cardAccount}>Location</Typography>
                <Typography className={cssClasses.cardAccountName}>{employee.location}</Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <Typography className={cssClasses.cardAccount}>Salary</Typography>
                <Typography className={cssClasses.cardAccountName}>{employee.salary.toLocaleString('en-US')}</Typography>
              </div>
            </Grid>
          </Grid>
        )}
      </div>
    )
  }
  const ContractorCard = ({ employee }) => {
    const [expand, setExpand] = useState(false)
    const d = new Date(employee.startDate)
    const startedDate = dayjs(d).format('YYYY/MM/DD')
    return (
      <div className={cssClasses.teamCard}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <Typography className={cssClasses.cardName}>Team member</Typography>
          <div>
            <input
              disabled={isDemoCompany}
              type="image"
              alt="edit_Icon"
              src={EditPencilIcon}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setSelectedTeam('contractor')
                setTeamMemberData(employee)
                setContEditButtonClicked(true)
              }}
            />
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            {' '}
            <Typography className={cssClasses.cardAccount}>Employee name</Typography>
            {expand ? (
              <KeyboardArrowUp
                sx={{ width: '16px', height: '16px' }}
                onClick={() => setExpand(prev => !prev)}
                style={{ cursor: 'pointer' }}
              />
            ) : (
              <KeyboardArrowDown
                sx={{ width: '16px', height: '16px' }}
                onClick={() => setExpand(prev => !prev)}
                style={{ cursor: 'pointer' }}
              />
            )}
          </div>
          <Typography className={cssClasses.cardAccountName}>{employee.name}</Typography>
        </div>{' '}
        {expand && (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div>
                <Typography className={cssClasses.cardAccount}>Employee type</Typography>
                <Typography className={cssClasses.cardAccountName}>Contractor</Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <Typography className={cssClasses.cardAccount}>Department</Typography>
                <Typography className={cssClasses.cardAccountName}>{employee.department}</Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <Typography className={cssClasses.cardAccount}>Job title</Typography>
                <Typography className={cssClasses.cardAccountName}>{employee.purpose}</Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <Typography className={cssClasses.cardAccount}>Start date</Typography>
                <Typography className={cssClasses.cardAccountName}>{startedDate}</Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <Typography className={cssClasses.cardAccount}>Location</Typography>
                <Typography className={cssClasses.cardAccountName}>{employee.location}</Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <Typography className={cssClasses.cardAccount}>Annualized compensation</Typography>
                <Typography className={cssClasses.cardAccountName}>{employee.annualCompensation.toLocaleString('en-US')}</Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <Typography className={cssClasses.cardAccount}>Contractor or entity name</Typography>
                <Typography className={cssClasses.cardAccountName}>{employee.function}</Typography>
              </div>
            </Grid>
          </Grid>
        )}
      </div>
    )
  }
  return (
    <div
      style={{
        display: 'flex',
        width: '780px',
        height: '884px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        flexShrink: 0,
        gap: '12px',
        justifyContent: 'start',
        paddingLeft: '50px'
      }}
    >
      <div style={{ marginTop: '70px' }}>
        <p className={cssClasses.stepText}>Step 4</p>
        <h1 className={cssClasses.tabHeading}>Team </h1>
        <p className={cssClasses.tabDesc}>
          Let’s add your employees and/or contractors, by adding the required information below. This includes full-time, part-time w2
          employees, and as well independent contractors. These individual are currently or will be providing services to your business,
          even if they reside overseas, and aren’t considered 1099s by the IRS.{' '}
        </p>
        <Divider sx={{ width: '100%', marginTop: '28px' }} />
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', margin: '26px 0' }}>
          {' '}
          <Paper
            component="form"
            sx={{
              p: '2px 4px',
              display: 'flex',
              alignItems: 'center',
              width: '360px',
              height: '44px',
              border: '1px solid #3B424C',
              borderRadius: '4px'
            }}
          >
            <IconButton sx={{ p: '10px' }} aria-label="menu">
              <SearchIcon />
            </IconButton>
            <InputBase
              disabled={isDemoCompany}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search by..."
              inputProps={{ 'aria-label': 'search google maps' }}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
              <img src={Adjustments} alt="Edit" />
            </IconButton>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          </Paper>
          <button
            disabled={isDemoCompany}
            style={{
              height: '44px',
              padding: '12px 16px',
              borderRadius: '4px',
              border: 'solid 1px #29A364',
              color: '#29A364',
              fontSize: '16px',
              background: '#F2F7F4',
              fontWeight: 500,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer'
            }}
            type="button"
            onClick={() => {
              setAddButtonClicked(true)
            }}
          >
            <span style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <AddIcon />
              <span>Add</span>
            </span>
          </button>
        </div>
        <Grid container spacing={2}>
          {employeeList.map((employee, index) => (
            <Grid item xs={4}>
              <EmployeeCard employee={employee} />
            </Grid>
          ))}{' '}
          {contractorList.map((employee, index) => (
            <Grid item xs={4}>
              <ContractorCard employee={employee} />
            </Grid>
          ))}
        </Grid>
      </div>{' '}
      <MyTeamSideDrawer
        isAddButtonClicked={isAddButtonClicked}
        setAddButtonClicked={setAddButtonClicked}
        isDemoCompany={isDemoCompany}
        setSelectedTeam={setSelectedTeam}
        selectedTeam={selectedTeam}
        editEmployee={editEmployee}
        setEmpEditButtonClicked={setEmpEditButtonClicked}
        empInitialValues={empInitialValues}
        setEmpInitialValues={setEmpInitialValues}
        empEditButtonClicked={empEditButtonClicked}
        teamMemberData={teamMemberData}
        initialValues={initialValues}
        setInitialValues={setInitialValues}
        formikInitialValues={formikInitialValues}
        setContEditButtonClicked={setContEditButtonClicked}
        contEditButtonClicked={contEditButtonClicked}
        setTeamMemberData={setTeamMemberData}
      />
    </div>
  )
})

MyTeamTab.propTypes = {
  windowHeight: PropTypes.any,
  isDemoCompany: PropTypes.any
}

export default MyTeamTab
