import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { setCompanySetupModalScreen, setRegistrationSetupModelScreen, uiSelector } from 'store/ui/ui.slice'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import config from 'config'

import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core'

import logoGreen from 'assets/images/mrgn-logo-green.svg'
import paymentPageImage from 'assets/images/payment_page_img.png'
import sparkles from 'assets/images/sparkles.png'

import { Formik } from 'formik'
import * as Yup from 'yup'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import '../index.css'
import CheckoutForm from 'ui-component/payment/CheckoutForm'
import { paymentSelector } from 'store/payment/payment.slice'
import { companySelector } from 'store/company/company.slice'
import { authSelector } from 'store/auth/auth.slice'
import { addPromoCode } from 'store/payment/payment.action'
import PaymentModel from 'ui-component/payment/element'
import StripPaymentModel from './StripPaymentModel'

const StripePage = () => {
  const formRef = useRef()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { companySetupModalScreen, registrationSetupModelScreen } = useSelector(uiSelector)

  const formPaymentInitialValues = {}

  const { clientSecret, publishableKey, productPrice, earlyBirdStatus, promoCode, isLoading, promoErrorCode } = useSelector(paymentSelector)
  const { user } = useSelector(authSelector)
  const { selectedCompany, id } = useSelector(companySelector)
  const [ElementOptions, setElementOptions] = useState('')
  const [stripePromise, setStripePromise] = useState('')
  const [promo, setPromo] = useState('')
  const [promoError, setPromoError] = useState(false)

  useEffect(() => {
    if (promoErrorCode) setPromoError(true)
    else setPromoError(false)
  }, [promoErrorCode])

  useEffect(() => {
    if (publishableKey) {
      setStripePromise(loadStripe(publishableKey))
    }
    if (clientSecret) {
      const appearance = {
        theme: 'stripe'
      }
      setElementOptions({
        clientSecret,
        appearance
      })
    }
  }, [clientSecret, publishableKey])

  const onClickBackButton = () => {
    dispatch(setRegistrationSetupModelScreen(registrationSetupModelScreen - 1))
  }

  return (
    <Grid container alignItems="flex-start">
      <Grid
        item
        xs={5}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          backgroundColor: '#FAF8F3'
        }}
      >
        <Grid
          sx={{
            width: '80%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            minHeight: '100vh',
            backgroundColor: '#FAF8F3'
          }}
        >
          <img src={logoGreen} alt="mrgn_logo" style={{ position: 'absolute', top: '100px' }} />
          <Button className="back-button" onClick={onClickBackButton} variant="text">
            <ArrowBackIcon sx={{ marginRight: '5px' }} /> <span>Back</span>
          </Button>
          <Typography className="payMRGNText">Pay mrgn</Typography>
          <Typography className="plan-amount">$ {productPrice}</Typography>
          <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', width: '70%' }}>
              <div style={{ width: '100%' }}>
                <Typography className="text-business">Business</Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography className="text-qty">
                    Qty <span className="qty">1</span>
                  </Typography>
                  <KeyboardArrowDownIcon />
                </div>
              </div>
              <Typography className="text-business">${productPrice}</Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', width: '70%' }}>
              <div style={{ width: '100%', display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <p className="text-business">Subtotal</p>
                  <p className="text-business">${productPrice}</p>
                </div>
                <Divider style={{ color: 'black', width: '50%' }} />
                {/* <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <p className="text-qty">Sales tax (6.5%)</p>
                  <p className="text-qty">${productPrice}</p>
                </div>
                <Divider style={{ color: 'black', width: '50%' }} /> */}
                <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <p className="text-business">Total due</p>
                  <p className="text-business">${productPrice}</p>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={7} sx={{ display: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', width: '100%' }}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '90vh',
            marginTop: '40px',
            width: '100%'
          }}
        >
          <StripPaymentModel />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default StripePage
