import React, { useState, useEffect } from 'react'
import ModeEditOutlineOutlined from '@material-ui/icons/ModeEditOutlineOutlined'
import DeleteOutlineOutlined from '@material-ui/icons/DeleteOutlineOutlined'
import ErrorOutline from '@material-ui/icons/ErrorOutline'

import cssClasses from '../infoTab.module.css'
import { Box, Button, Divider, Grid, IconButton, Menu, MenuItem, Typography } from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import dayjs from 'dayjs'

const EmployeeList = props => {
  const {
    addedEmployee,
    setAddedEmployee,
    selectedTeam,
    setSelectedTeam,
    setTeamMemberData,
    setEmpEditButtonClicked,
    setAddButtonClicked,
    closeSideDrawer,
    handleDeleteEmployee,
    handleDeleteContractor
  } = props
  const [deleteClicked, setDeleteClicked] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [employeeId, setEmployeeId] = useState('')
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleDelete = () => {
    setDeleteClicked(false)
    if (addedEmployee[0]?.function) handleDeleteContractor(employeeId)
    else handleDeleteEmployee(employeeId)
    setDeleted(true)
  }
  // console.log(addedEmployee[0]._id, 'added employee')
  // console.log(addedEmployee[0], 'added employee')
  useEffect(() => {
    setEmployeeId(addedEmployee[0]?._id)
  }, [addedEmployee])
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p className={cssClasses.drawerListHeading}>Team member</p>
        <Button onClick={() => setAddedEmployee([])} className={cssClasses.transparentAddButton} variant="text">
          {' '}
          <span style={{ marginRight: '10px' }}>+</span>Add
        </Button>
      </div>
      <Divider style={{ marginTop: '10px', color: 'rgba(9, 165, 127, 0.15)' }} />
      {deleteClicked && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '4px',
            border: '1px solid #CF2626',
            background: '#FEF6F6',
            padding: '16px'
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'flex-start', gap: '8px' }}>
            <ErrorOutline sx={{ fontSize: 'medium', color: '#CF2626', marginTop: '2px' }} />
            <Typography style={{ fontSize: '16px', fontWeight: '400', lineHeight: '125%', fontStyle: 'normal' }}>
              Deleting this team member will remove them from your company. Please confirm deletion.
            </Typography>
          </div>
          <Button sx={{ alignSelf: 'end', color: '#CF2626', fontWeight: '500', fontSize: '14px' }} onClick={handleDelete}>
            Delete
          </Button>
        </div>
      )}
      {!deleted && (
        <ul style={{ width: '100%', padding: 0, margin: 0, listStyle: 'none' }}>
          {addedEmployee.map((eachItem, index) => (
            <li key={index} style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
              {/* <Grid item xs={12}> */}
              <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h1 className={cssClasses.listCardItemh}>Team member</h1>
                {/* <IconButton
                onClick={() => {
                  setSelectedTeam(selectedTeam)
                  setTeamMemberData(eachItem)
                  setEmpEditButtonClicked(true)
                  setAddedEmployee([])
                }}
              >
                <MoreHorizIcon />
              </IconButton> */}
                <IconButton onClick={handleClick}>
                  <MoreHorizIcon />
                </IconButton>
                <Menu
                  sx={{ zIndex: '1500000' }}
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button'
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setSelectedTeam(selectedTeam)
                      setTeamMemberData(eachItem)
                      // setEmpEditButtonClicked(true)
                      setAddedEmployee([])
                      setAnchorEl(null)
                    }}
                  >
                    <ModeEditOutlineOutlined /> Edit
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setDeleteClicked(true)
                      setAnchorEl(null)
                    }}
                  >
                    <DeleteOutlineOutlined /> Delete
                  </MenuItem>
                </Menu>
              </Grid>
              <Grid item xs={6}>
                <h1 className={cssClasses.listCardh}>Employee name</h1>
                <p className={cssClasses.listCardp}>{eachItem.name}</p>
              </Grid>
              <Grid item xs={6}>
                <h1 className={cssClasses.listCardh}>Employee type</h1>
                <p className={cssClasses.listCardp}>{selectedTeam.charAt(0).toUpperCase() + selectedTeam.slice(1)}</p>
              </Grid>
              <Grid item xs={6}>
                <h1 className={cssClasses.listCardh}>Department</h1>
                <p className={cssClasses.listCardp}>{eachItem.department}</p>
              </Grid>
              <Grid item xs={6}>
                <h1 className={cssClasses.listCardh}>Job title</h1>
                <p className={cssClasses.listCardp}>{eachItem.title ? eachItem.title : eachItem.purpose}</p>
              </Grid>
              <Grid item xs={6}>
                <h1 className={cssClasses.listCardh}>Start date</h1>
                <p className={cssClasses.listCardp}>{dayjs(eachItem.startDate).format('DD.MM.YY')}</p>
              </Grid>
              <Grid item xs={6}>
                <h1 className={cssClasses.listCardh}>Location</h1>
                <p className={cssClasses.listCardp}>{eachItem.location}</p>
              </Grid>
              <Grid item xs={6}>
                <h1 className={cssClasses.listCardh}>Salary</h1>
                <p className={cssClasses.listCardp}>{eachItem.salary ? eachItem.salary : eachItem.annualCompensation}</p>
              </Grid>
              {/* </Grid> */}
            </li>
          ))}
        </ul>
      )}
      <Divider sx={{ width: '100%', marginTop: '80%', marginBottom: '20px' }} />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {/* <Divider sx={{ marginTop: '230px', marginBottom: '10px' }} /> */}
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <button
            // onClick={() => handleCancelClick()}
            onClick={() => {
              closeSideDrawer()
              setAddedEmployee([])
            }}
            className={cssClasses.wizardCancelButton}
            type="button"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              setAddedEmployee([])
              setAddButtonClicked(false)
              closeSideDrawer()
            }}
            type="button"
            className={cssClasses.wizardSaveButton}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}

export default EmployeeList
