import React, { lazy } from 'react'
import Loadable from 'ui-component/Loadable'
import MinimalLayout from 'layout/MinimalLayout'
import NavMotion from 'layout/NavMotion'
import GuestGuard from 'utils/route-guard/GuestGuard'
import { Typography } from '@material-ui/core'

const AuthLoader = Loadable(lazy(() => import('views/pages/authentication/authLoader')))

const GuestRoutes = {
  path: 'guest',
  element: <MinimalLayout />,
  children: [
    {
      path: '/',
      element: (
        <NavMotion>
          <GuestGuard>
            <AuthLoader />
          </GuestGuard>
        </NavMotion>
      )
    }
  ]
}

export default GuestRoutes
