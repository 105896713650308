import React from 'react'

// material-ui
import { useTheme } from '@material-ui/styles'

import logoGreen from 'assets/images/mrgn-logo-green.svg'
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ===========================|| LOGO SVG ||=========================== //

const Logo = () => {
  const theme = useTheme()

  return <img style={{ height: '32px' }} src={theme.palette.mode === 'dark' ? logoGreen : logoGreen} alt="mrgn" />
}

export default Logo
