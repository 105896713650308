import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  Button,
  TextField,
  DialogActions,
  InputAdornment,
  Input
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '@material-ui/icons/Close'

import { uiSelector, toggleBurnRateInitialModel } from 'store/ui/ui.slice'
import { resetState, companySelector, clearState } from 'store/company/company.slice'
import { simulationsSelector, updateTargetBurn } from 'store/simulations/simulations.slice'
import { modelsSelector } from 'store/models/models.slice'
import { useTheme } from '@emotion/react'
import wizardBar from '../../assets/images/wizardBar.png'
import { numberFormat } from 'utils/ui'

const useStyles = makeStyles(theme => ({
  muiDialog: {
    '& .MuiDialog-paper': {
      maxHeight: 'initial'
    }
  },
  muiDialogContent: {
    [theme.breakpoints.up('sm')]: {
      overflowY: 'hidden',
      paddingRight: 0
    }
  },
  gridFillHeight: {
    [theme.breakpoints.up('sm')]: {
      height: '100%'
    }
  }
}))

const BurnRateInialModel = () => {
  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { burRateInitialModelVisible } = useSelector(uiSelector)
  const { burnRate } = useSelector(simulationsSelector)

  const handleClose = () => {
    dispatch(toggleBurnRateInitialModel())
  }

  const handleChangeNumber = e => {
    const value = e.target.value.replace(/\D/g, '')
    dispatch(updateTargetBurn(value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')))
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={burRateInitialModelVisible}
      aria-labelledby="max-width-dialog-title"
      sx={{ py: 0 }}
      className={classes.muiDialog}
    >
      <DialogTitle
        id="max-width-dialog-title"
        sx={{
          width: '100%',
          backgroundImage: `url(${wizardBar})`,
          backgroundSize: '260px,10px',
          backgroundRepeat: 'no-repeat',
          mt: -2,
          padding: '35px 0px 20px 0px',
          imageRendering: 'crisp-edges,  -moz-crisp-edges, -o-crisp-edges, -webkit-optimize-contrast'
        }}
      >
        <Grid container>
          <Grid item sx={{ margin: 'auto' }}>
            Burn Rate Reduction
          </Grid>
        </Grid>
        <IconButton
          aria-label="close"
          onClick={() => handleClose()}
          sx={{
            position: 'absolute',
            right: 24,
            top: 24,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 0, m: 0, background: '#F0F0F0' }} className={classes.muiDialogContent}>
        <Grid container>
          <Grid container spacing={3} style={{ textAlign: 'center', padding: '25px 25px 20px 15px' }}>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 'bold' }}>Welcome to your Expense Fluctuation simulator!</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>Simply choose the cells, rows, or columns you&apos;d like to change and enter their new values.</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>mrgn will track your progress and provide you with a summary of action items that will get you to</Typography>
              <Typography>this new expense rate.</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>What is your target burn rate (optional)?</Typography>
              <TextField
                id="outlined-number"
                placeholder="Target burn rate"
                type="text"
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                sx={{ mt: 1, mb: 2 }}
                value={burnRate.targetBurn === 0 ? undefined : burnRate.targetBurn}
                onChange={handleChangeNumber}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <Grid container sx={{ background: '#FFFFFF', mb: -2, p: 2 }}>
        <Button variant="contained" sx={{ mt: 2, mb: 2, margin: 'auto' }} onClick={e => handleClose()}>
          Go!
        </Button>
      </Grid>
    </Dialog>
  )
}

export default BurnRateInialModel
