import React, { useRef, useState } from 'react'
import { Divider, Drawer, FormHelperText, Grid, IconButton } from '@material-ui/core'
import cssClasses from './infoTab.module.css'

import CloseIcon from '@material-ui/icons/Close'
import FileUploadOutlinedIcon from '@material-ui/icons/FileUploadOutlined'

import UploadIcon from '../../assets/images/icons/UploadIcon.png'

import { Formik } from 'formik'
import * as Yup from 'yup'
import DetailBillableAndProduct from 'views/pages/authentication/CompanyRegistration/DetailBillableAndProducts'
import DetailBillableandProduct from './DetailBillableandProduct'
import { useDispatch, useSelector } from 'react-redux'
import { companySelector } from 'store/company/company.slice'
import TopLineBillableClientsTabView from './TopLineBillableClientsTabView'
import { setEquityEditButtonClicked } from 'store/ui/ui.slice'
import { updateCompany } from 'store/company/company.actions'
import ClientList from './DrawerListPages/ClientList'

const ProServicesSideDrawer = props => {
  const {
    isAddButtonClicked,
    setAddButtonClicked,
    isEditButtonClicked,
    editItem,
    setEditItem,
    setEditProdItem,
    editProdItem,
    setEditButtonClicked
  } = props
  const proServicesFormRef = useRef()
  const dispatch = useDispatch()
  const [selectedRevenueComputationalType, setSelectedRevenueComputationalType] = useState('')
  const {
    isLoading,
    isSuccess,
    isSuccessCompanyCreate,
    updateCompanyError,
    id,
    clientList,
    departmentList,
    selectedCompany,
    currentlyActiveCompanyId,
    vendorList,
    incompleteCompanies,
    revenueComputationType
  } = useSelector(companySelector)

  const [addedItem, setAddedItem] = useState([])

  const customStyles = {
    backdrop: {
      fill: 'var(--neutrals-cool-1000, #0C0D0F)',
      opacity: 0.25
    }
  }

  const proServicesInitialValues = {
    revenueComputationType: '',
    submit: null
  }
  const closeDrawer = () => {
    setAddButtonClicked(false)
    setSelectedRevenueComputationalType('Top Line Billable')
    setEquityEditButtonClicked(false)
    setEditButtonClicked(false)
  }

  return (
    <Drawer
      BackdropProps={{ style: customStyles.backdrop }}
      style={{ zIndex: 1000000 }}
      anchor="right"
      open={isAddButtonClicked}
      onClose={closeDrawer}
      sx={{
        '& .css-4qyyic-MuiGrid-root': {
          padding: '24px',
          width: '390px'
        }
      }}
    >
      <Grid p={5} width="500px" role="presentation" textAlign="start">
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={closeDrawer}>
            <CloseIcon style={{ color: 'black' }} />
          </IconButton>
        </div>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <h1 className={cssClasses.drawerHeading}>Business</h1>
          <FileUploadOutlinedIcon style={{ color: 'black', fontSize: '24px' }} />
        </div>
        <p className={cssClasses.drawerDesc}>
          Let’s add the basic revenue model information about your company, by adding the required information below.
        </p>
        {addedItem.length === 0 ? (
          <div style={{ width: '100%' }}>
            <p className={cssClasses.drawerHeadingSecond}>Pro services</p>
            <Divider />
            <Formik
              innerRef={proServicesFormRef}
              enableReinitialize
              initialValues={proServicesInitialValues}
              validationSchema={Yup.object().shape({
                revenueComputationType: Yup.string().required('Revenue computation type is required')
              })}
              onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                // if (isDemoCompany) {
                //   dispatch(setCompanySetupModalScreen(companySetupModalScreen + 1))
                //   return
                // }
              }}
            >
              {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                <form noValidate onSubmit={handleSubmit} style={{ width: '100%', marginTop: '24px' }}>
                  <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column' }}>
                    <label htmlFor="revenue_comp_type" className={cssClasses.inputLabel}>
                      Revenue computation type <span className={cssClasses.important}>*</span>{' '}
                    </label>
                    <select
                      placeholder="Revenue computation type"
                      className={cssClasses.wizardInputBox}
                      value={values.revenueComputationType}
                      name="revenueComputationType"
                      onBlur={handleBlur}
                      onChange={event => {
                        handleChange(event)
                        setSelectedRevenueComputationalType(event.target.value)
                      }}
                      id="revenue_comp_type"
                    >
                      {' '}
                      <option disabled selected hidden value="">
                        Select
                      </option>
                      <option value="Top Line Billable">Top Line Billable</option>,
                      <option value="Detailed Billable + Products">Detailed Billable + Products</option>
                    </select>

                    {touched.revenueComputationType && errors.revenueComputationType && (
                      <FormHelperText error> {errors.revenueComputationType} </FormHelperText>
                    )}
                  </div>
                </form>
              )}
            </Formik>
            {selectedRevenueComputationalType &&
              (selectedRevenueComputationalType === 'Top Line Billable' ? (
                <TopLineBillableClientsTabView
                  selectedRevenueComputationalType={selectedRevenueComputationalType}
                  isEditButtonClicked={isEditButtonClicked}
                  editItem={editItem}
                  setEditItem={setEditItem}
                  setAddButtonClicked={setAddButtonClicked}
                  setAddedItem={setAddedItem}
                  addedItem={addedItem}
                />
              ) : (
                <DetailBillableandProduct
                  selectedRevenueComputationalType={selectedRevenueComputationalType}
                  isEditButtonClicked={isEditButtonClicked}
                  editItem={editItem}
                  setEditItem={setEditItem}
                  setEditProdItem={setEditProdItem}
                  editProdItem={editProdItem}
                  setAddedItem={setAddedItem}
                  addedItem={addedItem}
                />
              ))}
          </div>
        ) : (
          <ClientList
            addedItem={addedItem}
            setAddedItem={setAddedItem}
            selectedRevenueComputationalType={selectedRevenueComputationalType}
            setEditItem={setEditItem}
            setAddButtonClicked={setAddButtonClicked}
          />
        )}
      </Grid>
    </Drawer>
  )
}

export default ProServicesSideDrawer
